import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconContainer } from "./SearchInout.style";
import { InputProps, TextInput } from "../textInput/TextInput";

export function SearchInput(props: InputProps) {
  const { placeholder = "Search" } = props;
  return (
    <TextInput
      {...props}
      isSearchInput
      isHasIcon
      icon={
        <IconContainer>
          <FontAwesomeIcon icon={["far", "magnifying-glass"]}></FontAwesomeIcon>
        </IconContainer>
      }
      placeholder={placeholder}
    />
  );
}
