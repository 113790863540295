import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

import { ActionWrapper, Container } from "./ProjectList.style";

import { Button } from "../../atoms/button/Button";
import { HeaderCell } from "../../atoms/headerCell/HeaderCell";
import { TextCell } from "../../atoms/textCell/TextCell";
import { Column } from "../../molecules/column/Column";
import { Row } from "../../molecules/row/Row";
import { DataTable } from "../../organisms/dataTable/DataTable";
import { Heading } from "../../organisms/heading/Heading";
import { Section } from "../../organisms/section/Section";
import { Badge, BadgeColorStyle } from "../../atoms/badge/Badge";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  deleteProject,
  fetchProjectList,
  projectSelector,
  resetProjectDetail,
  setCurrentProject,
} from "../../store/features/project";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { formatFullDateTime } from "../../utils/format";
import qs from "qs";
import { ConfirmModal } from "../../organisms/confirmModal/ConfirmModal";
import { IProject } from "../../types";
import { onClickRow } from "../../utils/dataTable";
import { addToast } from "../../store/features/toast";
import { Skeleton } from "../../atoms/loading/skeleton/Skeleton";

const publishedColor: { color: BadgeColorStyle; key: string }[] = [
  { color: "warning", key: "scheduled" },
  { color: "green", key: "published" },
  { color: "grey", key: "draft" },
];

export function ProjectList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    projects,
    pagination,
    currentProject,
    deleteProjectState,
    loadProjectListState,
  } = useAppSelector(projectSelector);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);

  async function onHandleDeleteProject() {
    await dispatch(deleteProject(currentProject?.id));
    dispatch(resetProjectDetail());
    loadAllProjectData();
    setIsShowDeleteModal(false);
  }

  useEffect(() => {
    const status = deleteProjectState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully deleted" }));
    }
    if (status === "error")
      dispatch(addToast({ status, title: "Delete failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProjectState.status, dispatch, location.pathname]);

  function onCloseDeleteModal() {
    setIsShowDeleteModal(false);
    dispatch(resetProjectDetail());
  }

  function onOpenDeleteModal(project: IProject) {
    dispatch(setCurrentProject(project));
    setIsShowDeleteModal(true);
  }

  async function loadAllProjectData() {
    dispatch(
      fetchProjectList({
        q: searchParams.get("q"),
        page: searchParams.get("page"),
      })
    );
  }

  async function reset() {
    // await dispatch(deleteTempNearby({ nearbys: newNearbys }));
    // dispatch(resetNearby());
  }
  useEffect(() => {
    loadAllProjectData();

    return () => {
      reset();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, dispatch]);

  return (
    <Container>
      <Heading
        title="Project"
        actionComponent={
          <Link to={"/project/create"}>
            <Button leftIcon={<FontAwesomeIcon icon={["fas", "plus"]} />}>
              Create Project
            </Button>
          </Link>
        }
      />
      <ConfirmModal
        primaryButtonTitle="Delete"
        onClose={onCloseDeleteModal}
        title="Delete project"
        opened={isShowDeleteModal}
        onClickPrimaryButton={() => onHandleDeleteProject()}
      >
        <div>
          Are you sure you want to delete <b>‘{currentProject?.name || ""}’</b>?
        </div>
        <div style={{ marginTop: "16px" }}>
          Once you confirm, this project will be permanently deleted and also
          removed from all contents that use it.
        </div>
      </ConfirmModal>
      <Section>
        <DataTable
          onSearchData={(value) => {
            searchParams.set("q", value);
            setSearchParams(searchParams);
          }}
          searchPlaceHolder="Search by project name"
          currentPage={parseInt(searchParams.get("page") as string, 10) || 1}
          totalPages={pagination.total && pagination.lastPage}
          onChangePage={(page) =>
            navigate({
              pathname: "/project",
              search: qs.stringify(
                {
                  q: searchParams.get("q") || undefined,
                  page,
                },
                { addQueryPrefix: true, skipNulls: true }
              ),
            })
          }
          header={
            <Row>
              <Column>
                <HeaderCell title="Name" />
              </Column>
              <Column maxWidth="120px">
                <HeaderCell title="Publish Status" />
              </Column>
              <Column maxWidth="90px">
                <HeaderCell title="Visibility" />
              </Column>
              <Column maxWidth="160px">
                <HeaderCell title="Updated At" />
              </Column>
              <Column maxWidth="100px" children={<></>} />
            </Row>
          }
        >
          {loadProjectListState.status === "success" && projects ? (
            projects.map((data) => (
              <Row
                key={data.id}
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  onClickRow(event, () => navigate(`/project/${data.id}`))
                }
              >
                <Column>
                  <TextCell primaryText={data?.name || ""} />
                </Column>
                <Column maxWidth="120px">
                  <Badge
                    color={
                      _.find(publishedColor, { key: data.publishStatus })
                        ?.color || "grey"
                    }
                  >
                    {data.publishStatus?.charAt(0).toUpperCase() +
                      data.publishStatus?.slice(1)}
                  </Badge>
                </Column>
                <Column maxWidth="90px">
                  <TextCell
                    text={
                      data.visibilityStatus?.charAt(0).toUpperCase() +
                      data.visibilityStatus?.slice(1)
                    }
                  />
                </Column>
                <Column maxWidth="160px">
                  <TextCell
                    text={formatFullDateTime(new Date(data.updatedAt))}
                  />
                </Column>
                <Column maxWidth="100px">
                  <ActionWrapper>
                    <Button
                      buttonStyle="text-link-grey"
                      onClick={() => onOpenDeleteModal(data)}
                      fontSize="sm"
                    >
                      Delete
                    </Button>
                    <Link to={`/project/${data.id}`}>
                      <Button buttonStyle="text-link-primary" fontSize="sm">
                        Edit
                      </Button>
                    </Link>
                  </ActionWrapper>
                </Column>
              </Row>
            ))
          ) : (
            <Skeleton height={"250px"} radius={0} />
          )}
        </DataTable>
      </Section>
    </Container>
  );
}
