import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Button } from "../../../atoms/button/Button";
import { ImageInput } from "../../../atoms/imageInput/ImageInput";
import { Select } from "../../../atoms/select/Select";
import { TextInput } from "../../../atoms/textInput/TextInput";
import { ContentDivider } from "../../../molecules/contentDivider/ContentDivider";
import { unitArea } from "../../../types";
import { ISubtype, initFloorPlan } from "../../../types/unitType";
import { onCastIUploadDataToEmbeddedImageData } from "../../../utils/castType";
import { onHandleChangeEditableData } from "../../../utils/dataService";
import { validateUsageArea } from "../../../utils/validation";
import { ConfirmModal } from "../../confirmModal/ConfirmModal";
import { Modal } from "../Modal";
import {
  ModalWrapper,
  AreaInputWrapper,
  FloorPlanWrapper,
  ButtonGroup,
  FloorPlanContextWrapper,
  ActionGroupWrapper,
  Sepline,
} from "./ModalUnitVariant.style";

interface ModalUnitVariantProps {
  subtype: ISubtype;
  opened: boolean;
  isUpdate?: boolean;
  onOpen: () => void;
  onClose: () => void;
  onClickAdd?: (data: ISubtype) => void;
  onClickUpdate?: (data: ISubtype) => void;
  setSubtypeData: (data: ISubtype) => void;
}

export function ModalUnitVariant(props: ModalUnitVariantProps) {
  const {
    subtype,
    opened,
    onClose,
    onOpen,
    isUpdate,
    setSubtypeData,
    onClickAdd = () => {},
    onClickUpdate = () => {},
  } = props;

  const [unitVariantTitleError, setUnitVariantTitleError] =
    useState<string>("");
  const [selectedFloorPlanIndex, setSelectedFloorPlanIndex] =
    useState<number | null>(null);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [usageArea, setUsageArea] = useState<string>("");

  function onHandleChangeSubtype(path: string, value: any) {
    setSubtypeData(onHandleChangeEditableData(subtype, path, value));
  }

  function onHandleChangeFloorPlans(path: string, value: any, index: number) {
    const cloned = _.cloneDeep(subtype.floorPlans);
    const clonedFloorPlan = _.cloneDeep(cloned[index]);
    cloned[index] = onHandleChangeEditableData(clonedFloorPlan, path, value);
    setSubtypeData(onHandleChangeEditableData(subtype, "floorPlans", cloned));
  }

  function onHandleAddFloorPlan() {
    setSubtypeData(
      onHandleChangeEditableData(subtype, "floorPlans", [
        ...subtype.floorPlans,
        initFloorPlan,
      ])
    );
  }

  function onDeleteFloorPlan(index: number) {
    const clonedFloorPlans = _.cloneDeep(subtype.floorPlans);
    _.pullAt(clonedFloorPlans, index);
    setSubtypeData(
      onHandleChangeEditableData(subtype, "floorPlans", clonedFloorPlans)
    );
    setSelectedFloorPlanIndex(null);
  }

  function onMoveUpFloorPlan(index: number) {
    const clonedData = _.cloneDeep(subtype.floorPlans);
    clonedData.splice(index - 1, 0, clonedData[index]);
    clonedData.splice(index + 1, 1);
    setSubtypeData(
      onHandleChangeEditableData(subtype, "floorPlans", clonedData)
    );
  }

  function onMoveDownFloorPlan(index: number) {
    const clonedData = _.cloneDeep(subtype.floorPlans);
    const targetData = clonedData[index];
    clonedData.splice(index, 1);
    clonedData.splice(index + 1, 0, targetData);
    setSubtypeData(
      onHandleChangeEditableData(subtype, "floorPlans", clonedData)
    );
  }

  function formatUsageArea(value: string) {
    const minMaxValue = value.split("-");
    const minValue = parseFloat(_.get(minMaxValue, "[0]", ""));
    const maxValue = parseFloat(_.get(minMaxValue, "[1]", ""));
    return {
      minValue: minValue,
      maxValue: maxValue || 0,
    };
  }

  useEffect(() => {
    if (subtype?.usageArea) {
      const area = subtype?.usageArea;
      const formatUsageArea =
        area?.minValue > 0
          ? `${area?.minValue}${area?.maxValue && "-" + area?.maxValue}`
          : "";

      setUsageArea(formatUsageArea);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtype?.usageArea?.maxValue, subtype?.usageArea?.minValue]);

  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
        setUsageArea("");
        setUnitVariantTitleError("");
      }}
      button={
        <Button
          buttonStyle="outline-light"
          leftIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
          onClick={() => {
            onOpen();
            setUnitVariantTitleError("");
            setUsageArea("");
          }}
        >
          Add
        </Button>
      }
      onClickSecondaryButton={() => {
        onClose();
        setUnitVariantTitleError("");
        setUsageArea("");
      }}
      onClickPrimaryButton={() => {
        if (isUpdate) {
          onClickUpdate({
            ...subtype,
            usageArea: { ...subtype?.usageArea, ...formatUsageArea(usageArea) },
          });
        } else {
          onClickAdd({
            ...subtype,
            usageArea: { ...subtype?.usageArea, ...formatUsageArea(usageArea) },
          });
        }
        setUsageArea("");
        setUnitVariantTitleError("");
      }}
      isDisablePrimaryButton={!Boolean(subtype?.title)}
      primaryButtonTitle={isUpdate ? "Update" : "Add"}
      secondaryButtonTitle={"Cancel"}
      size={700}
      title={isUpdate ? "Update Unit Variant" : "Add Unit Variant"}
    >
      <>
        {selectedFloorPlanIndex !== null ? (
          <ConfirmModal
            onClose={() => {
              setIsShowDeleteModal(false);
              setSelectedFloorPlanIndex(null);
            }}
            title="Delete Floor Plan"
            opened={isShowDeleteModal}
            onClickPrimaryButton={() =>
              onDeleteFloorPlan(selectedFloorPlanIndex)
            }
          >
            <div>
              Are you sure you want to delete this floor plan
              {/* <b>‘{subtype.floorPlans[selectedFloorPlanIndex]?.description || ""}’</b> variant? */}
            </div>
            <div style={{ marginTop: "16px" }}>
              Once you confirm, this floor plan will be permanently deleted from
              this unit variant.
            </div>
          </ConfirmModal>
        ) : (
          <></>
        )}
        <ModalWrapper>
          <div style={{ maxWidth: 520 }}>
            <TextInput
              label={"Name"}
              placeholder={"Unit variant name"}
              value={subtype?.title || ""}
              onChange={(e) => {
                onHandleChangeSubtype("title", e.target.value);
                setUnitVariantTitleError("");
              }}
              onBlur={() => {
                if (!Boolean(subtype?.title.length)) {
                  setUnitVariantTitleError("Please enter a name.");
                }
              }}
              error={unitVariantTitleError}
            />
          </div>
          <AreaInputWrapper>
            <TextInput
              // type="number"
              inputWidth="200px"
              placeholder="e.g. 23.5 or 479-508"
              label={"Usage area"}
              value={usageArea}
              onChange={
                (e) => {
                  if (
                    validateUsageArea(e.target.value) ||
                    !Boolean(e.target.value.length)
                  ) {
                    setUsageArea(e.target.value);
                  }
                }
                // onHandleChangeSubtype("usageArea.minValue", e.target.value)
              }
              autoComplete={"off"}
            />
            <div style={{ width: "128px" }}>
              <Select
                value={subtype?.usageArea?.unit || "Sq.m."}
                data={unitArea}
                onChange={(e) => onHandleChangeSubtype("usageArea.unit", e)}
                dropdownPosition="bottom"
              />
            </div>
          </AreaInputWrapper>
          {/* <TextInput
            type="number"
            placeholder="Max value"
            inputWidth="200px"
            label={"Usage area"}
            value={subtype?.usageArea?.maxValue || ""}
            onChange={(e) =>
              onHandleChangeSubtype("usageArea.maxValue", e.target.value)
            }
            autoComplete={"off"}
          /> */}
          <TextInput
            type={"number"}
            inputWidth="200px"
            label={"Floor amount"}
            value={subtype.floorsCount || ""}
            onChange={(e) =>
              onHandleChangeSubtype(
                "floorsCount",
                Math.abs(parseFloat(e.target.value))
              )
            }
            autoComplete={"off"}
          />
        </ModalWrapper>
        <ModalWrapper>
          <ContentDivider title="Floor Plan" />
          {subtype.floorPlans.map((item, index) => (
            <FloorPlanWrapper key={`${item.index}-${index}`}>
              <ImageInput
                ratio={16 / 9}
                onClickChooseImage={(img) =>
                  onHandleChangeFloorPlans(
                    "image",
                    onCastIUploadDataToEmbeddedImageData(img, null),
                    index
                  )
                }
                data={{
                  imgUrl: item.image?.originalUrl || "",
                  imgAlt: item.image?.name || "",
                }}
              />
              <FloorPlanContextWrapper>
                <ActionGroupWrapper>
                  <Button
                    buttonStyle="text-link-danger"
                    fontSize="sm"
                    onClick={() => {
                      setIsShowDeleteModal(true);
                      setSelectedFloorPlanIndex(index);
                    }}
                    // disabled={!Boolean(index - 1 >= 0)}
                  >
                    Delete
                  </Button>
                  <Sepline />
                  <ButtonGroup>
                    <Button
                      buttonStyle="secondary-grey"
                      borderradius={"8px 0px 0px 8px"}
                      onClick={() => onMoveUpFloorPlan(index)}
                      width="28px"
                      height="28px"
                      padding="0"
                      disabled={!Boolean(index - 1 >= 0)}
                    >
                      <FontAwesomeIcon icon={["far", "arrow-up"]} />
                    </Button>
                    <Button
                      buttonStyle="secondary-grey"
                      borderradius={"0px 8px 8px 0px"}
                      onClick={() => onMoveDownFloorPlan(index)}
                      width="28px"
                      height="28px"
                      padding="0"
                      disabled={!Boolean(index + 1 < subtype.floorPlans.length)}
                    >
                      <FontAwesomeIcon icon={["far", "arrow-down"]} />
                    </Button>
                  </ButtonGroup>
                </ActionGroupWrapper>
                <TextInput
                  label="Description"
                  value={item?.description || ""}
                  onChange={(e) =>
                    onHandleChangeFloorPlans(
                      "description",
                      e.target.value,
                      index
                    )
                  }
                />
              </FloorPlanContextWrapper>
            </FloorPlanWrapper>
          ))}
          <Button
            buttonStyle="text-link-grey"
            onClick={() => onHandleAddFloorPlan()}
            leftIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
          >
            Add floor plan
          </Button>
        </ModalWrapper>
      </>
    </Modal>
  );
}
