import { PostListLayout } from "../../../templates/postListLayout/PostListLayout";

export function PromotionList() {
  return (
    <PostListLayout
      pageHeader="Promotion"
      pathname="/promotion"
      categoryKey="promotion"
      isPromotion
    />
  );
}
