import { String } from "lodash";
import qs from "qs";
import { axiosContentAPI } from "../../axios";
import { QueryParams } from "../../types";
import { PostQueryParams } from "../../types/post";

export const loadPostList = (filter: PostQueryParams) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosContentAPI.get(`/posts${query}`);
};

export const loadPostById = (id: string) => {
  return axiosContentAPI.get(`/posts/${id}`);
};

export const updatePostByIdAPI = ({
  id,
  payload,
}: {
  id: string;
  payload: { [key: string]: any };
}) => {
  return axiosContentAPI.put(`/posts/${id}`, payload);
};

export const deletePostByIdAPI = (id: String) => {
  return axiosContentAPI.delete(`/posts/${id}`);
};

export const createPostAPI = ({
  payload,
}: {
  payload: { [key: string]: any };
}) => {
  return axiosContentAPI.post(`/posts`, payload);
};

export const loadPostOptions = (filter: QueryParams) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosContentAPI.get(`/terms?type=post-option&limit=all${query}`);

};
