import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import qs from "qs";
import { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Badge } from "../../atoms/badge/Badge";
import { Button } from "../../atoms/button/Button";
import { HeaderCell } from "../../atoms/headerCell/HeaderCell";
import { Skeleton } from "../../atoms/loading/skeleton/Skeleton";
import { TextCell } from "../../atoms/textCell/TextCell";
import { Column } from "../../molecules/column/Column";
import { Row } from "../../molecules/row/Row";
import { DataTable } from "../../organisms/dataTable/DataTable";
import { Heading } from "../../organisms/heading/Heading";
import { Section } from "../../organisms/section/Section";
import { fetchUserList, userSelector } from "../../store/features/user";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { onClickRow } from "../../utils/dataTable";
import {
  Container,
  Text,
  StatusText,
  BadgeWrapper,
  IconWrapper,
} from "./UserList.style";

export function UserList() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pagination, users, loadUserListState } = useAppSelector(userSelector);
  const filter = {
    q: searchParams.get("q"),
    page: searchParams.get("page"),
  };

  useEffect(() => {
    dispatch(fetchUserList({ ...filter }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, dispatch]);

  return (
    <Container>
      <Heading
        title="User Management"
        actionComponent={
          <Link to={"/user-management/create"}>
            <Button leftIcon={<FontAwesomeIcon icon={["fas", "plus"]} />}>
              Create User
            </Button>
          </Link>
        }
      />
      <Section>
        <DataTable
          additionalInfoElement={
            <Text>
              {`${searchParams.get("q") ? `Result:` : `Total`} ${pagination.total} ${pagination.total === 1 ? "account" : "accounts"}`}
            </Text>
          }
          searchPlaceHolder={"Search by username, name, or email"}
          onSearchData={(value) => {
            searchParams.set("q", value);
            setSearchParams(searchParams);
          }}
          currentPage={parseInt(searchParams.get("page") as string, 10) || 1}
          totalPages={pagination.lastPage || 1}
          onChangePage={(page) =>
            navigate({
              pathname: "/user-management",
              search: qs.stringify(
                {
                  q: searchParams.get("q") || undefined,
                  page,
                },
                { addQueryPrefix: true, skipNulls: true }
              ),
            })
          }
          header={
            <Row>
              <Column>
                <HeaderCell title="Username" />
              </Column>
              <Column>
                <HeaderCell title="Name" />
              </Column>
              <Column>
                <HeaderCell title="Email" />
              </Column>
              <Column maxWidth="120px">
                <HeaderCell title="Role" />
              </Column>
              <Column maxWidth="120px">
                <HeaderCell title="Status" />
              </Column>
              <Column maxWidth="88px" children={<></>} />
            </Row>
          }
        >
          <>
            {loadUserListState.status === "success" && users ? (
              users.map((item, index) => (
                <Row
                  key={`${item.id}-${index + 1}`}
                  onClick={(e) => {
                    onClickRow(e, () => {
                      navigate(`/user-management/${item.id}`);
                    });
                  }}
                >
                  <Column>
                    <TextCell primaryText={item.username} />
                  </Column>
                  <Column>
                    <TextCell text={item.name} />
                  </Column>
                  <Column>
                    <TextCell text={item.email} />
                  </Column>
                  <Column maxWidth="120px">
                    <Badge
                      color="grey"
                      leftSection={
                        item.role.id === "admin" && (
                          <IconWrapper>
                            <FontAwesomeIcon icon={["far", "gem"]} />
                          </IconWrapper>
                        )
                      }
                    >
                      <BadgeWrapper>{item.role.name}</BadgeWrapper>
                    </Badge>
                  </Column>
                  <Column maxWidth="120px">
                    <StatusText isActive={Boolean(item.status === "active")}>
                      {item.status.charAt(0).toUpperCase() +
                        item.status.slice(1)}
                    </StatusText>
                  </Column>
                  <Column maxWidth="88px" horizontalAlign="right">
                    <Button
                      buttonStyle="text-link-primary"
                      onClick={() => {
                        navigate(`/user-management/${item.id}`);
                      }}
                      fontSize="sm"
                    >
                      Edit
                    </Button>
                  </Column>
                </Row>
              ))
            ) : (
              <Skeleton height={150} radius={0} />
            )}
          </>
        </DataTable>
      </Section>
    </Container>
  );
}
