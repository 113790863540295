import { createReducer } from "@reduxjs/toolkit";
import { ErrorTypes, ITerm } from "../../../types";
import { fetchAllZone } from "./actions";

interface InitialStateProps {
  zones: ITerm[];
  loadZoneState: {
    error: ErrorTypes | null;
    status: string;
  };
}

const initialState: InitialStateProps = {
  zones: [],
  loadZoneState: {
    error: null,
    status: "",
  },
};

export const zoneReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAllZone.pending, (state) => {
      state.loadZoneState.error = null;
      state.loadZoneState.status = "loading";
    })
    .addCase(fetchAllZone.fulfilled, (state, { payload }) => {
      state.zones = payload?.data;
      state.loadZoneState.error = null;
      state.loadZoneState.status = "success";
    })
    .addCase(fetchAllZone.rejected, (state, { payload }) => {
      state.zones = [];
      state.loadZoneState.error = payload as ErrorTypes;
      state.loadZoneState.status = "error";
    })
});
