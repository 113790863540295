import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

import { BadgeWrapper, ActionWrapper } from "./BrandList.style";

import { Button } from "../../atoms/button/Button";
import { HeaderCell } from "../../atoms/headerCell/HeaderCell";
import { TextCell } from "../../atoms/textCell/TextCell";
import { Column } from "../../molecules/column/Column";
import { Row } from "../../molecules/row/Row";
import { DataTable } from "../../organisms/dataTable/DataTable";
import { Section } from "../../organisms/section/Section";
import { Badge } from "../../atoms/badge/Badge";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  brandSelector,
  deletedBrandById,
  fetchBrands,
  resetBrandDetailState,
  setCurrentBrand,
} from "../../store/features/brand";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { formatFullDateTime, formatIsoLanguage } from "../../utils/format";
import { IBrand } from "../../types";
import qs from "qs";
import { onClickRow } from "../../utils/dataTable";
import { ConfirmModal } from "../../organisms/confirmModal/ConfirmModal";
import { addToast } from "../../store/features/toast";
import { Skeleton } from "../../atoms/loading/skeleton/Skeleton";
import { deletePage } from "../../store/features/page";
import { Heading } from "../../organisms/heading/Heading";
import { Container } from "../projectList/ProjectList.style";

export function BrandList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {
    brands,
    currentBrand,
    pagination,
    deleteBrandState,
    loadBrandListState,
  } = useAppSelector(brandSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);

  //onClick handler
  async function onHandleDeleteBrand() {
    await dispatch(deletedBrandById(currentBrand?.id));
    await dispatch(deletePage(`resource:${currentBrand?.id}`));
    dispatch(
      fetchBrands({ q: searchParams.get("q"), page: searchParams.get("page") })
    );
    onCloseDeleteModal();
  }

  // function onHandleEditBrand(brand: IBrand) {
  //   dispatch(setCurrentBrand(brand));
  //   setSelectedBrand(brand);
  //   setIsSidePanelActive(true);
  // }

  function onCloseDeleteModal() {
    setIsShowDeleteModal(false);
    dispatch(resetBrandDetailState());
  }

  function onOpenDeleteModal(brand: IBrand) {
    dispatch(setCurrentBrand(brand));
    setIsShowDeleteModal(true);
  }

  // handle create react element
  function onHandleGenerateBadges(data: string) {
    if (data) {
      return (
        <Badge color={"grey"}>
          <>{formatIsoLanguage(data)}</>
        </Badge>
      );
    }
    return <>—</>;
  }

  // load data
  function loadAllBrandData() {
    dispatch(
      fetchBrands({
        q: searchParams.get("q"),
        page: searchParams.get("page"),
      })
    );
  }

  useEffect(() => {
    loadAllBrandData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, dispatch]);

  useEffect(() => {
    const status = deleteBrandState.status;
    if (status === "success")
      dispatch(addToast({ status, title: "Successfully deleted" }));
    if (status === "error")
      dispatch(addToast({ status, title: "Delete failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteBrandState.status, dispatch, location.pathname]);

  return (
    <>
      <Container>
        <Heading
          title="Brand"
          actionComponent={
            <Button
              onClick={() => navigate("/brand/create")}
              leftIcon={<FontAwesomeIcon icon={["fas", "plus"]} />}
            >
              Create Brand
            </Button>
          }
        />

        <ConfirmModal
          onClose={onCloseDeleteModal}
          title="Delete Brand"
          opened={isShowDeleteModal}
          onClickPrimaryButton={() => onHandleDeleteBrand()}
        >
          <div>
            Are you sure you want to delete <b>‘{currentBrand?.name || ""}’</b>?
          </div>
          <div style={{ marginTop: "16px" }}>
            Once you confirm, this brand will be permanently deleted and also
            removed from all contents that use it.
          </div>
        </ConfirmModal>
        <Section>
          <DataTable
            onSearchData={(value) => {
              searchParams.set("q", value);
              setSearchParams(searchParams);
            }}
            currentPage={parseInt(searchParams.get("page") as string, 10) || 1}
            totalPages={pagination.total && pagination.lastPage}
            onChangePage={(page) =>
              navigate({
                pathname: "/brand",
                search: qs.stringify(
                  {
                    q: searchParams.get("q") || undefined,
                    page,
                  },
                  { addQueryPrefix: true, skipNulls: true }
                ),
              })
            }
            header={
              <Row>
                <Column>
                  <HeaderCell title="Name" />
                </Column>
                <Column maxWidth="260px">
                  <HeaderCell title="Language" />
                </Column>
                <Column maxWidth="160px">
                  <HeaderCell title="Updated At" />
                </Column>
                <Column maxWidth="100px" children={<></>} />
              </Row>
            }
          >
            {loadBrandListState.status === "success" && brands ? (
              brands.map((data, index) => (
                <Row
                  key={data.id + index + 1}
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    onClickRow(event, () => navigate(`/brand/${data.id}`))
                  }
                >
                  <Column>
                    <TextCell primaryText={data.name || ""} />
                  </Column>
                  <Column maxWidth="260px">
                    <BadgeWrapper>
                      {onHandleGenerateBadges(data.languageCode || "")}
                    </BadgeWrapper>
                  </Column>
                  <Column maxWidth="160px">
                    <TextCell
                      text={formatFullDateTime(new Date(data.updatedAt))}
                    />
                  </Column>
                  <Column maxWidth="100px">
                    <ActionWrapper>
                      <Button
                        buttonStyle="text-link-grey"
                        onClick={() => onOpenDeleteModal(data)}
                        fontSize="sm"
                      >
                        Delete
                      </Button>
                      <Button
                        buttonStyle="text-link-primary"
                        onClick={() => {
                          // onHandleEditBrand(data)
                          navigate(`/brand/${data.id}`);
                        }}
                        fontSize="sm"
                      >
                        Edit
                      </Button>
                    </ActionWrapper>
                  </Column>
                </Row>
              ))
            ) : (
              <Skeleton height={"250px"} radius={0} />
            )}
          </DataTable>
        </Section>
      </Container>
    </>
  );
}
