import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

export const EditIcon = styled.button<{
  iconWidth?: string;
  iconHeight?: string;
}>`
  /* position: absolute;
  top: 4px;
  right: 4px; */
  width: ${(props) => (props.iconWidth ? props.iconWidth : "32px")};
  height: ${(props) => (props.iconHeight ? props.iconHeight : "32px")};
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--primary600);
  background-color: var(--primary100);
  cursor: pointer;
`;

export const EditButtonWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  svg {
    pointer-events: none;
  }

  z-index: 1;
`;

export const MenuContainer = styled.div<{ isActive?: boolean }>`
  position: absolute;
  top: 50px;
  right: 4px;
  overflow: hidden;

  opacity: 0;
  pointer-events: none;

  ${(props) =>
    props.isActive &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}
`;
