import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ButtonContainer, PreviewLayoutButton, PreviewLayoutButtonContainer, ResetDraftButton,
  ResultMessage,
  SaveDraftContainer,
  Toolbar,
} from './PreviewAreaHeader.style';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setIsEnableSave, setPreviewLayout, setSectionError, updateSection } from '../../../store/features/editor';
import {
  publishRevision,
  setIsDraftSaved,
  setShouldSaveDraft,
  updateDraftRevision,
} from '../../../store/features/page';
import { ModalDelete } from '../../organisms/modalDelete/ModalDelete';
import { Modal } from '../../atoms/modal/Modal';
import { ErrorMessage } from '../../organisms/messages/ErrorMessage';
import { deleteDraft } from '../../../services/api/page-builder';
import { Button } from '../../../atoms/button/Button';

export const PreviewAreaHeader = () => {
  const [isShowResetModal, setIsShowResetModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { currentPage, saveDraftState, publishState, shouldSaveDraft } = useAppSelector((state) => state.page);
  const {
    error,
    currentEdit,
    dataSources,
    isEnableSave,
    saveSectionState,
    previewLayout,
  } = useAppSelector((state) => state.editor);

  const handleSaveOrder = async () => {
    if (!currentPage) {
      return;
    }

    const { id: pageId, sections = [] } = currentPage;
    const sectionIds = sections.map((d) => d.id);
    dispatch(updateDraftRevision({ pageId, payload: { sectionIds } }));
  };

  const handleSaveSection = async () => {
    if (!currentEdit) {
      return;
    }

    const updatedComponent = { ...currentEdit.component };
    const isSavingChildComponent = currentEdit.selectedChildIndex !== null;

    if (currentEdit.type === 'data') {
      // ถ้ากำลังเซฟ Child Component ตัว dataSource จะอยู่ใน object ของ child อยู่แล้ว
      updatedComponent.dataSources = isSavingChildComponent ? [] : dataSources;
    } else if (currentEdit.type === 'data2') {
      // ถ้ากำลังเซฟ Child Component ตัว dataSource จะอยู่ใน object ของ child อยู่แล้ว
      updatedComponent.dataSources2 = isSavingChildComponent ? [] : dataSources;
    }

    const sectionId = updatedComponent.id as string;
    dispatch(updateSection({ sectionId, payload: updatedComponent }));
  };

  const onPublish = async () => {
    dispatch(setIsEnableSave(false));
    dispatch(publishRevision(currentPage.id));
  };

  const onSaveDraft = async () => {
    if (!currentEdit?.type) {
      // Click save draft button outside appearance / data editing page
      dispatch(setIsDraftSaved(true));
      return;
    }

    if (currentEdit.type === 'order') {
      await handleSaveOrder();
    } else {
      await handleSaveSection();
    }
  };

  const onConfirmDeleteDraft = async () => {
    setIsShowResetModal(false);
    try {
      await deleteDraft(currentPage.id);
      window.location.reload();
    } catch (deleteError) {
      // eslint-disable-next-line no-console
      console.error(deleteError);
    }
  };

  useEffect(() => {
    if (shouldSaveDraft) {
      onSaveDraft()
        .then(() => {
          dispatch(setShouldSaveDraft(false));
          dispatch(setIsDraftSaved(true));

          const timer = setTimeout(() => {
            dispatch(setIsDraftSaved(false));
            clearTimeout(timer);
          }, 600);
        });
    }
  }, [shouldSaveDraft]);

  useEffect(() => {
    if (publishState.isPublished) {
      const timer = setTimeout(() => {
        window.location.reload();
        clearTimeout(timer);
      }, 300);
    }
  }, [publishState.isPublished]);

  return (
    <header>
      { error && (
        <Modal
          title="มีข้อผิดพลาดเกิดขึ้น"
          isOpen={Boolean(error)}
          onClose={() => dispatch(setSectionError(null))}>
          <ErrorMessage
            title="ไม่สามารถบันทึกการแก้ไขได้ กรุณาลองใหม่อีกครั้ง"
            description={error.status ? `Error Code: ${error.status}` : ''} />
        </Modal>
      ) }
      <ModalDelete
        title="รีเซ็ตการเปลี่ยนแปลง"
        description="คุณยืนยันที่จะรีเซ็ตหน้าเว็บฉบับร่างที่คุณได้แก้ไขใช่หรือไม่"
        isOpen={isShowResetModal}
        onClose={() => setIsShowResetModal(false)}
        onConfirm={onConfirmDeleteDraft} />
      <Toolbar isShow={Boolean(currentPage.id)}>
        <SaveDraftContainer isShow={currentPage.hasDraft}>
          <PreviewLayoutButtonContainer>
            <PreviewLayoutButton
              title="ดูตัวอย่างแบบ Desktop"
              type="button"
              isActive={previewLayout === 'desktop'}
              onClick={() => dispatch(setPreviewLayout('desktop'))}>
              <FontAwesomeIcon icon={['fas', 'desktop']} />
            </PreviewLayoutButton>
            <PreviewLayoutButton
              title="ดูตัวอย่างแบบมือถือ"
              type="button"
              isActive={previewLayout === 'mobile'}
              onClick={() => dispatch(setPreviewLayout('mobile'))}>
              <FontAwesomeIcon icon={['fas', 'mobile-alt']} />
            </PreviewLayoutButton>
          </PreviewLayoutButtonContainer>
          {/* <span>กำลังแสดงหน้าเว็บฉบับร่าง</span> */}
          {/* <ResetDraftButton
            type="button"
            onClick={() => setIsShowResetModal(true)}>
            รีเซ็ตฉบับร่าง
          </ResetDraftButton> */}
        </SaveDraftContainer>
        <ButtonContainer>
          { (saveDraftState.isDraftSaved || saveSectionState.isSuccess) && (
            <ResultMessage>
              <FontAwesomeIcon icon={['fas', 'check']} /> บันทึกฉบับร่างแล้ว
            </ResultMessage>
          ) }
          { publishState.isPublished && (
            <ResultMessage>
              <FontAwesomeIcon icon={['fas', 'check']} /> บันทึกและเผยแพร่แล้ว
            </ResultMessage>
          ) }
          {/* <Button
            buttonStyle="secondary"
            disabled={!isEnableSave || saveSectionState.isSaving || publishState.isPublishing}
            onClick={onSaveDraft}>
            บันทึกฉบับร่าง
          </Button> */}
          <Button
            buttonStyle="primary"
            disabled={!isEnableSave || publishState.isPublishing || publishState.isPublished}
            onClick={onPublish}>
            บันทึก
          </Button>
        </ButtonContainer>
      </Toolbar>
    </header>
  );
};
