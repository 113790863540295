import styled from '@emotion/styled'

export const SideFormPageContent = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
`

export const SideFormPageSectionContent = styled.div`
  padding: 24px;

  > *:not(:last-child) {
    margin-bottom: 48px;
  }
`