import styled from "@emotion/styled/macro";

export const TabsContainer = styled.div`
  background-color: var(--grey100);
  border-radius: 5px;
  margin: 0;
  padding: 2px;
  border: none;
  outline: none;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Option = styled.div`
  position: relative;
  cursor: pointer;

  :first-of-type {
    grid-column: 1;
    grid-row: 1;
    box-shadow: none;

    label::before {
      opacity: 0;
    }

    label::after {
      opacity: 0;
    }
  }
`;

export const Label = styled.label<{ isActive: boolean }>`
  position: relative;
  display: block;
  text-align: center;
  padding: var(--space-xs);
  background: rgba(255,255,255,0);
  font-size: 14px;
  font-weight: ${(props) => (props.isActive ? '600' : '500')};
  color: ${(props) => (props.isActive ? 'var(--primary800)' : 'var(--grey900)')};
  cursor: ${(props) => (props.isActive ? 'default' : 'pointer')};
  pointer-events: ${(props) => (props.isActive ? 'none' : 'all')};

  span {
    display: block;
    position: relative;
    z-index: 2;
    transition: color 200ms ease;
    white-space: nowrap;
  }

  :hover {
    color: var(--primary800);
  }
  
  ::before, ::after {
    content: '';
    width: 1px;
    background: rgba(142,142,147,.15);
    position: absolute;
    top: 14%;
    bottom: 14%;
    border-radius: 10px;
    will-change: background;
    -webkit-transition: background .2s ease;
    transition: background .2s ease;
  }
  
  ::before {
    left: 0;
    transform: translateX(-.5px);
  }

  ::after {
    right: 0;
    transform: translateX(.5px);
  }
`;

export const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  opacity: 0;
`;

export const Selected = styled.span`
  background: #FFF;
  border: .5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03), 0 3px 1px 0 rgba(0, 0, 0, 0.01);
  border-radius: 7px;
  grid-column: 1;
  grid-row: 1;
  z-index: 2;
  transition: transform 200ms ease;
`;
