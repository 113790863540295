import { IEmbeddedMedia } from "./image";
import { price } from "./project";

export interface IUpdateBrandPayload {
  brandId: string;
  data: IBrandEditable;
}

export type ThemeType = {
  brandColors: string[];
  backgroundColors: string[];
  mainColor: string;
};

export type KeyFeatureType = {
  title: string;
  description: string;
  image: IEmbeddedMedia | null;
};

export interface IBrand {
  id: string;
  languageCode: string;
  name: string;
  description: string;
  seoTitle: string;
  seoDescription: string;
  ogTitle: string;
  ogDescription?: string;
  keyFeatures: KeyFeatureType[];
  pageId: string;
  slugCode: string;
  price: price | null;
  productTypeId: string;
  productType: { [key: string]: any };
  logo: IEmbeddedMedia | null;
  thumbnails: IEmbeddedMedia[];
  banners: IEmbeddedMedia[];
  galleries: IEmbeddedMedia[];
  openGraphImage: IEmbeddedMedia | null;
  theme: ThemeType | null;
  publishStatus: string;
  visibilityStatus: string;
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
  altName: string;
}

export interface IBrandEditable
  extends Omit<
    IBrand,
    "id" | "createdAt" | "updatedAt" | "publishedAt" | "productType"
  > {}

export type BrandQueryParams = {
  q?: string;
  limit?: string;
  'product-type'?: string;
  lang: string;
};

export const initialThemeData = {
  brandColors: [],
  backgroundColors: [],
  mainColor: "",
};

export const initKeyFeature = {
  title: "",
  description: "",
  image: null,
};

export const initBrand = {
  languageCode: "th",
  name: "",
  description: "",
  seoTitle: "",
  seoDescription: "",
  ogTitle: "",
  ogDescription: "",
  keyFeatures: [],
  productTypeId: "",
  openGraphImage: null,
  price: {
    minValue: 0,
    maxValue: 0,
    unit: "ล้าน",
    currency: "บาท",
  },
  pageId: "",
  slugCode: "",
  logo: null,
  thumbnails: [],
  banners: [],
  publishStatus: "draft",
  visibilityStatus: "public",
  theme: initialThemeData,
  galleries: [],
  altName: ""
};
