import styled from "@emotion/styled/macro";

export const Container = styled.div`
  /* height: 100vh;
  max-height: 100vh; */
  display: flex;
  flex-direction: column;
  padding: 0px 32px 32px;
  overflow: auto;
  max-height: 100vh;
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: flex-end;
`;
