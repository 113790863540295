import { ContentVariation } from "./contentVariation";
import { IEmbeddedMedia } from "./image";

export type ProductTypeUsedIn = "project" | "unit-type"
export interface IProductType {
  id: string;
  contentVariations: ContentVariation[];
  availableLanguages: string[];
  code: string;
  openGraphImage: IEmbeddedMedia | null;
  banners: IEmbeddedMedia[];
  usedIn: ProductTypeUsedIn;
  createdAt: string;
  updatedAt: string;
}

export interface IProductTypeEditable
  extends Omit<
    IProductType,
    | "id"
    | "contentVariations"
    | "availableLanguages"
    | "createdAt"
    | "updatedAt"
  > {
  contentVariation: ContentVariation;
}

export type UpdateProductTypeParam = {
  productTypeId: string;
  data: IProductTypeEditable;
};

export const initProductType = {
  contentVariation: {
    languageCode: "th",
    name: "",
    description: "",
    seoTitle: "",
    seoDescription: "",
    ogTitle: "",
    ogDescription: "",
  },
  usedIn: "project" as ProductTypeUsedIn,
  code: "",
  openGraphImage: null,
  banners: [],
};
