import { IEmbeddedMedia } from "./image";

export interface IUser {
  id: string;
  username: string;
  email: string;
  name: string;
  roleId: string;
  displayImage: IEmbeddedMedia | null;
  role: Role;
  status: string;
  suspendedAt?: string | null;
  createdAt?: string;
  updatedAt?: string;
}

export interface IEditableUser
  extends Omit<
    IUser,
    "id" | "role" | "createdAt" | "updatedAt" | "suspendedAt" | "displayImage"
  > {}

export type Role = {
  id: string;
  name: string;
};

export type UpdateUserParam = {
  id: string;
  data: IEditableUser;
};

export type UpdateStatusParam = {
  id: string;
  data: { status: string };
};

export const initUser = {
  username: "",
  name: "",
  email: "",
  password: "",
  roleId: "reporter",
  status: "active",
};
