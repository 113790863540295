import { createReducer } from "@reduxjs/toolkit";
import { IUnitType, unitTypeInitialState } from "../../../types/unitType";
import { formatErrorObject } from "../../../utils/errorFormatter";
import { getStateObject } from "../../../utils/stateManagement";
import {
  fetchUnitTypeList,
  resetUnitTypeListData,
  resetUnitTypeDetailData,
  updateUnitTypeById,
  deleteUnitTypeById,
  createUnitType,
  fetchUnitTypeDetail,
  setCurrentUnitType,
  fetchFunctionTypeList,
  setDuplicateUnitTypeData,
  setEditUnitTypeData,
  fetchAllUnitTypeList,
} from "./actions";

const unitTypeListInitialState = {
  unitTypeList: [],
  allUnitTypeList: [],
  functionTypeList: [],
  loadUnitTypeListState: getStateObject({ type: "idle" }),
  loadAllUnitTypeListState: getStateObject({ type: "idle" }),
  loadFunctionTypeListState: getStateObject({ type: "idle" }),
  pagination: {
    from: 0,
    to: 0,
    lastPage: 0,
    perPage: 0,
    total: 0,
    currentPage: 0,
  },
};

const unitTypeDetailInitialState = {
  currentUnitType: null,
  editUnitType: null,
  loadUnitTypeDetailState: getStateObject({ type: "idle" }),
  updateUnitTypeState: getStateObject({ type: "idle" }),
  createUnitTypeState: getStateObject({ type: "idle" }),
  deleteUnitTypeState: getStateObject({ type: "idle" }),
};

const initialState: unitTypeInitialState = {
  ...unitTypeDetailInitialState,
  ...unitTypeListInitialState,  
  duplicateUnitType: null,
};

export const unitTypeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUnitTypeList.pending, (state) => {
      state.loadUnitTypeListState = getStateObject({ type: "loading" });
    })
    .addCase(fetchUnitTypeList.fulfilled, (state, { payload }) => {
      state.unitTypeList = payload?.data;
      state.loadUnitTypeListState.error = null;
      state.loadUnitTypeListState.status = "success";
      state.pagination = {
        from: payload?.from,
        to: payload?.to,
        lastPage: payload?.lastPage,
        perPage: payload?.perPage,
        total: payload?.total,
        currentPage: payload?.currentPage,
      };
    })
    .addCase(fetchUnitTypeList.rejected, (state, { payload }) => {
      state.unitTypeList = [];
      state.loadUnitTypeListState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Unit Type List"),
      });
    })
    .addCase(fetchAllUnitTypeList.pending, (state) => {
      state.loadAllUnitTypeListState = getStateObject({ type: "loading" });
    })
    .addCase(fetchAllUnitTypeList.fulfilled, (state, { payload }) => {
      state.allUnitTypeList = payload?.data;
      state.loadAllUnitTypeListState.error = null;
      state.loadAllUnitTypeListState.status = "success";
    })
    .addCase(fetchAllUnitTypeList.rejected, (state, { payload }) => {
      state.allUnitTypeList = [];
      state.loadAllUnitTypeListState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "All Unit Type List"),
      });
    })
    .addCase(updateUnitTypeById.pending, (state) => {
      state.updateUnitTypeState = getStateObject({ type: "loading" });
    })
    .addCase(updateUnitTypeById.fulfilled, (state, { payload }) => {
      state.currentUnitType = payload;
      state.updateUnitTypeState = getStateObject({ type: "success" });
    })
    .addCase(updateUnitTypeById.rejected, (state, { payload }) => {
      state.updateUnitTypeState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Update Unit Type"),
      });
    })
    .addCase(deleteUnitTypeById.pending, (state) => {
      state.deleteUnitTypeState = getStateObject({ type: "loading" });
    })
    .addCase(deleteUnitTypeById.fulfilled, (state) => {
      state.deleteUnitTypeState = getStateObject({ type: "success" });
    })
    .addCase(deleteUnitTypeById.rejected, (state, { payload }) => {
      state.deleteUnitTypeState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Delete Unit Type"),
      });
    })
    .addCase(fetchUnitTypeDetail.pending, (state) => {
      state.loadUnitTypeDetailState = getStateObject({ type: "loading" });
    })
    .addCase(fetchUnitTypeDetail.fulfilled, (state, { payload }) => {
      state.currentUnitType = payload;
      state.loadUnitTypeDetailState = getStateObject({ type: "success" });
    })
    .addCase(fetchUnitTypeDetail.rejected, (state, { payload }) => {
      state.currentUnitType = {} as IUnitType;
      state.loadUnitTypeDetailState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Load Unit Type By Id"),
      });
    })
    .addCase(setCurrentUnitType, (state, { payload }) => {
      state.currentUnitType = payload;
    })
    .addCase(createUnitType.pending, (state) => {
      state.createUnitTypeState = getStateObject({ type: "loading" });
    })
    .addCase(createUnitType.fulfilled, (state, { payload }) => {
      state.currentUnitType = payload;
      state.createUnitTypeState = getStateObject({ type: "success" });
    })
    .addCase(createUnitType.rejected, (state, { payload }) => {
      state.createUnitTypeState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Create Unit Type"),
      });
    })
    .addCase(fetchFunctionTypeList.pending, (state) => {
      state.loadFunctionTypeListState = getStateObject({ type: "loading" });
    })
    .addCase(fetchFunctionTypeList.fulfilled, (state, { payload }) => {
      state.functionTypeList = payload?.data;
      state.loadFunctionTypeListState = getStateObject({ type: "success" });
    })
    .addCase(fetchFunctionTypeList.rejected, (state, { payload }) => {
      state.loadFunctionTypeListState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Function Type List"),
      });
    })
    .addCase(setDuplicateUnitTypeData, (state, {payload}) => {
      state.duplicateUnitType = payload
    })
    .addCase(setEditUnitTypeData, (state, {payload}) => {
      state.editUnitType = payload
    })
    .addCase(resetUnitTypeListData, (state) => {
      Object.assign(state, unitTypeListInitialState);
    })
    .addCase(resetUnitTypeDetailData, (state) => {
      Object.assign(state, unitTypeDetailInitialState);
    });
});
