import {
  ColorInput as CoreColorInput,
  ColorInputProps as CoreColorInputProps,
  createStyles,
} from "@mantine/core";
import { useState } from "react";
import { validateColor } from "../../utils/validation";

import { ColorPreview, EmptyState, InfoText } from "./ColorInput.style";

const useStyles = createStyles(
  (
    theme,
    {
      error,
      withPreview,
      icon,
    }: {
      error?: React.ReactNode;
      icon?: React.ReactNode;
      withPreview?: boolean;
    }
  ) => {
    let iconStyle;
    if (icon && withPreview) {
      iconStyle = {
        fontSize: 18,
        width: 48,
        fontWeight: 700,
        color: "var(--grey500)",
      };
    } else {
      iconStyle = {
        width: 48,
      };
    }
    return {
      root: {
        width: "100%",
      },
      label: {
        fontSize: theme.fontSizes.lg,
        marginBottom: theme.spacing.xs,
        color: "var(--grey700)",
      },
      required: {
        color: "var(--error500)",
      },
      input: {
        minHeight: 44,
        height: 44,
        paddingTop: 8,
        paddingBottom: 8,
        fontSize: theme.fontSizes.lg,
        width: "100%",
        border: error ? "1px solid var(--error500)" : "1px solid #D0D5DD",
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
        borderRadius: 8,
        paddingLeft: icon
          ? "54px !important"
          : withPreview
          ? "60px !important"
          : undefined,
        // @ts-ignore
        color: theme.colors.grey[10],
        transition: error ? "border-color 100ms ease 0s" : undefined,

        ":disabled": {
          color: "var(--grey500)",
          backgroundColor: "var(--grey50)",
          cursor: "text",
          opacity: "unset",
        },

        ":focus": {
          border: error
            ? "1px solid var(--error300)"
            : "1px solid var(--primary300)",
          boxShadow: error
            ? "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--error100)"
            : "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--primary100)",
        },
      },

      error: {
        fontSize: 14,
        fontWeight: 400,
        color: "var(--error500)",
        marginTop: 6,
      },

      icon: {
        ...iconStyle,
      },
    };
  }
);

interface ColorInputProps extends CoreColorInputProps {
  /** Not include # */
  limitInput?: number;
  isAutoCheckLimitCharacter?: boolean;
  hintText?: string;
  isErrorOccurInsideComponent?: (status: boolean) => void;
}

export function ColorInput(props: ColorInputProps) {
  const {
    error,
    hintText = "Color code must be 6 digit hex string",
    isAutoCheckLimitCharacter,
    isErrorOccurInsideComponent,
    withPreview = true,
    withEyeDropper = false,
    placeholder = "e.g. #000000",
    value,
    onChange,
    limitInput = 6,
    onBlur,
    icon,
    fixOnBlur = false,
    ...rest
  } = props;
  const [inputError, serInputError] = useState("");
  const { classes } = useStyles({
    error: error || inputError,
    withPreview,
    icon,
  });

  function onHandleChange(event: string) {
    if (isAutoCheckLimitCharacter) {
      serInputError("");
      isErrorOccurInsideComponent && isErrorOccurInsideComponent(false);
    }

    if (onChange) {
      if (event.slice(1).length <= limitInput) {
        return onChange(
          event ? (event?.charAt(0) === "#" ? event : "#" + event) : ""
        );
      } else {
        return;
      }
    }
  }

  return (
    <div>
      <CoreColorInput
        {...rest}
        value={value}
        placeholder={placeholder}
        withEyeDropper={withEyeDropper}
        withPreview={withPreview}
        classNames={classes}
        onChange={(event) => onHandleChange(event)}
        autoComplete={'off'}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          }

          if (isAutoCheckLimitCharacter) {
            if (value && value?.slice(1).length < limitInput) {
              serInputError("Must have 6 character");
              isErrorOccurInsideComponent && isErrorOccurInsideComponent(true);
            }
          }
        }}
        fixOnBlur={fixOnBlur}
        error={error || inputError}
        icon={
          icon ? (
            icon
          ) : withPreview ? (
            <ColorPreview bgValue={value} error={error || inputError}>
              {!Boolean(value) || (value && !validateColor(value)) ? (
                <EmptyState></EmptyState>
              ) : (
                <></>
              )}
            </ColorPreview>
          ) : undefined
        }
      />
      {hintText && <InfoText>{hintText}</InfoText>}
    </div>
  );
}
