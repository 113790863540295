import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import qs from "qs";
import { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Badge } from "../../atoms/badge/Badge";
import { Button } from "../../atoms/button/Button";
import { HeaderCell } from "../../atoms/headerCell/HeaderCell";
import { Skeleton } from "../../atoms/loading/skeleton/Skeleton";
import { TextCell } from "../../atoms/textCell/TextCell";
import { Column } from "../../molecules/column/Column";
import { Row } from "../../molecules/row/Row";
import { DataTable } from "../../organisms/dataTable/DataTable";
import { Section } from "../../organisms/section/Section";
import { addToast } from "../../store/features/toast";
import {
  fetchUnitTypeList,
  resetUnitTypeDetailData,
  setCurrentUnitType,
  unitTypeSelector,
  updateUnitTypeById,
} from "../../store/features/unitType";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ListPageWithLanguageSidebar } from "../../templates/listPageWithLanguageSidebar/ListPageWithLanguageSidebar";
import { lang } from "../../types";
import { IUnitType } from "../../types/unitType";
import { onHandleSingleDataContentVariations } from "../../utils/dataService";
import { onClickRow } from "../../utils/dataTable";
import { formatFullDateTime } from "../../utils/format";
// import { mappingSubtypeContentVariationsToContentVariation } from "../../utils/mapData";

export function UnitTypeList() {
  const {
    unitTypeList,
    pagination,
    currentUnitType,
    updateUnitTypeState,
    deleteUnitTypeState,
    loadUnitTypeListState,
  } = useAppSelector(unitTypeSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const languageCode = lang;

  const [searchParams, setSearchParams] = useSearchParams({});
  const [isSidePanelActive, setIsSidePanelActive] = useState<boolean>(false);
  const [selectedUnitType, setSelectedUnitType] = useState<IUnitType>();
  const [selectedTab, setSelectedTab] = useState<string>("all");

  function onHandleUpdateUnitType(data?: IUnitType) {
    if (data) {
      const contentVariation = data.contentVariations.find(
        (item) => item.languageCode === languageCode
      );
      if (contentVariation) {
        dispatch(
          updateUnitTypeById({
            unitTypeId: data.id,
            data: {
              ..._.omit(data, "contentVariations"),
              isTemplate: data.forProjectId ? false : true,
              contentVariation: contentVariation,
            },
          })
        );
      }
    }
  }

  async function loadData() {
    await dispatch(
      fetchUnitTypeList({
        q: searchParams.get("q"),
        page: searchParams.get("page"),
        type: selectedTab,
      })
    );
  }

  useEffect(() => {
    loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, dispatch, updateUnitTypeState, selectedTab]);

  function onCloseSidebar() {
    setIsSidePanelActive(false);
    dispatch(resetUnitTypeDetailData());
  }

  useEffect(() => {
    const status = deleteUnitTypeState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully deleted" }));
      navigate("/unit-type");
    }
    if (status === "error")
      dispatch(addToast({ status, title: "Delete failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUnitTypeState.status, dispatch, location.pathname]);

  return (
    <ListPageWithLanguageSidebar
      sideBarTitle={
        currentUnitType
          ? onHandleSingleDataContentVariations(
              currentUnitType?.contentVariations,
              "name",
              languageCode
            )
          : ""
      }
      pageTitle="Unit Type"
      headerActionComponent={
        <Link to={"/unit-type/create"}>
          <Button leftIcon={<FontAwesomeIcon icon={["fas", "plus"]} />}>
            Create Unit Type
          </Button>
        </Link>
      }
      onUpdateEditData={(editData) =>
        setSelectedUnitType(editData as IUnitType)
      }
      editData={selectedUnitType as IUnitType}
      isSidePanelActive={isSidePanelActive}
      onClickCancel={onCloseSidebar}
      onClickSave={() => onHandleUpdateUnitType(selectedUnitType)}
      saveState={updateUnitTypeState}
    >
      <Section>
        <DataTable
          onSearchData={(value) => {
            searchParams.set("q", value);
            setSearchParams(searchParams);
          }}
          onClickFilterButton={() => {}}
          tabList={[
            {
              label: "All",
              value: "all",
            },
            {
              label: "Template",
              value: "template",
            },
            {
              label: "Specific Project",
              value: "project",
            },
          ]}
          selectedTab={selectedTab || "all"}
          onChangeTab={(e) => {
            setSelectedTab(e);
            searchParams.set("page", "1");
            setSearchParams(searchParams);
          }}
          currentPage={parseInt(searchParams.get("page") as string, 10) || 1}
          totalPages={pagination.lastPage}
          onChangePage={(page) =>
            navigate({
              pathname: "/unit-type",
              search: qs.stringify(
                {
                  q: searchParams.get("q") || undefined,
                  page,
                },
                { addQueryPrefix: true, skipNulls: true }
              ),
            })
          }
          header={
            <Row>
              <Column>
                <HeaderCell title="Name" />
              </Column>
              <Column>
                <HeaderCell title="House Collection" />
              </Column>
              <Column>
                <HeaderCell title="Type" />
              </Column>
              <Column>
                <HeaderCell title="Updated At" />
              </Column>
              <Column maxWidth="50px" />
            </Row>
          }
        >
          <>
            {loadUnitTypeListState.status === "success" ? (
              Boolean(unitTypeList && unitTypeList.length) &&
              unitTypeList.map((data, index) => (
                <Row
                  key={data.id + index + 1}
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    onClickRow(event, () => navigate(`/unit-type/${data.id}`))
                  }
                  height={"78px"}
                >
                  <Column>
                    <TextCell
                      primaryTextLimitLine={1}
                      textLimitLine={1}
                      primaryText={
                        _.find(data.contentVariations, {
                          languageCode: languageCode,
                        })?.name
                      }
                      text={
                        _.find(data.contentVariations, {
                          languageCode: languageCode,
                        })?.description
                      }
                    />
                  </Column>
                  <Column>
                    <TextCell
                      text={
                        _.find(data?.houseCollection?.contentVariations, {
                          languageCode: languageCode,
                        })?.name || "-"
                      }
                    />
                  </Column>
                  <Column>
                    <Badge color={!data.isTemplate ? "warning" : "grey"}>
                      {!data.isTemplate ? "Specific Project" : "Template"}
                    </Badge>
                  </Column>
                  <Column>
                    <TextCell
                      text={formatFullDateTime(new Date(data.updatedAt || ""))}
                    />
                  </Column>
                  <Column maxWidth="50px" horizontalAlign="right">
                    <Button
                      buttonStyle="text-link-primary"
                      onClick={() => {
                        dispatch(setCurrentUnitType(data));
                        setIsSidePanelActive(true);
                        setSelectedUnitType(data);
                      }}
                      fontSize="sm"
                    >
                      Edit
                    </Button>
                  </Column>
                </Row>
              ))
            ) : (
              <Skeleton height={"250px"} radius={0} />
            )}
          </>
        </DataTable>
      </Section>
    </ListPageWithLanguageSidebar>
  );
}
