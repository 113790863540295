import { createAction } from "@reduxjs/toolkit";

export const addToast = createAction(
  "toast/addToast",
  (payload: any) => {
    return {
      payload,
    };
  }
);

export const clearAllToast = createAction("toast/clearAllToast");