export const mapImageInputProjectPage = [
  {
    label: "Logo",
    key: "logo",
    ratio: 16 / 9,
    device: null,
  },
  {
    label: "Map",
    key: "map",
    ratio: 16 / 9,
    device: null,
    usedFor: null,
  },
  {
    label: "Banner (Desktop)",
    key: "banners",
    ratio: 16 / 9,
    imgSize: "1440 x 500",
    device: "desktop",
    usedFor: null,
  },
  {
    label: "Banner (Mobile)",
    key: "banners",
    ratio: 16 / 9,
    imgSize: "640 x 560",
    device: "mobile",
    usedFor: null,
  },
  {
    label: "Banner (Thank you email)",
    key: "banners",
    ratio: 16 / 9,
    imgSize: "640 x 235",
    device: null,
    usedFor: "thank-you-email",
  },
  {
    label: "Thumbnail (Desktop)",
    key: "thumbnails",
    ratio: 16 / 9,
    imgSize: "590 x 580",
    device: "desktop",
    usedFor: null,
  },
  {
    label: "Thumbnail (Mobile)",
    key: "thumbnails",
    ratio: 16 / 9,
    imgSize: "590 x 580",
    device: "mobile",
    usedFor: null,
  },
];

export const mapImageInputFacility = [
  {
    label: "Image (Desktop)",
    key: "images",
    ratio: 16 / 9,
    imgSize: "960 x 515",
    device: "desktop",
    usedFor: null,
  },
  {
    label: "Image (Mobile)",
    key: "images",
    ratio: 16 / 9,
    imgSize: "960 x 515",
    device: "mobile",
    usedFor: null,
  },
];

export const mapImageInputBrandPage = [
  {
    label: "Logo",
    key: "logo",
    ratio: 16 / 9,
    size: "48%",
    usedFor: null,
  },
  {
    label: "Banner (Desktop)",
    key: "banners",
    ratio: 16 / 9,
    imgSize: "1440 x 500",
    device: "desktop",
    usedFor: null,
  },
  {
    label: "Banner (Mobile)",
    key: "banners",
    ratio: 16 / 9,
    imgSize: "640 x 320",
    device: "mobile",
    usedFor: null,
  },
  {
    label: "Banner (Thank you email)",
    key: "banners",
    ratio: 16 / 9,
    imgSize: "640 x 235",
    device: null,
    usedFor: "thank-you-email",
  },
  {
    label: "Thumbnail (Desktop)",
    key: "thumbnails",
    ratio: 16 / 9,
    imgSize: "640 x 400",
    device: "desktop",
    usedFor: null,
  },
  {
    label: "Thumbnail (Mobile)",
    key: "thumbnails",
    ratio: 16 / 9,
    imgSize: "640 x 400",
    device: "mobile",
    usedFor: null,
  },
];

export const mapImageInputProductTypePage = [
  {
    label: "Banner (Desktop)",
    key: "banners",
    ratio: 16 / 9,
    imgSize: "1440 x 500",
    device: "desktop",
    usedFor: null,
  },
  {
    label: "Banner (Mobile)",
    key: "banners",
    ratio: 16 / 9,
    imgSize: "640 x 320",
    device: "mobile",
    usedFor: null,
  },
  {
    label: "Banner (Thank you email)",
    key: "banners",
    ratio: 16 / 9,
    imgSize: "640 x 235",
    device: null,
    usedFor: "thank-you-email",
  },
];

export const mapImageInputUnitTypePage = [
  {
    label: "Banner (Desktop)",
    key: "banners",
    ratio: 16 / 9,
    imgSize: "1440 x 720",
    device: "desktop",
    usedFor: null,
  },
  {
    label: "Banner (Mobile)",
    key: "banners",
    ratio: 16 / 9,
    imgSize: "640 x 560",
    device: "mobile",
    usedFor: null,
  },
  {
    label: "Thumbnail",
    key: "thumbnail",
    ratio: 16 / 9,
    imgSize: "640 x 450",
    device: null,
    usedFor: null,
    size: "48%",
  },
];

export const mapImageInputPostPage = [
  {
    label: "Banner (Desktop)",
    key: "banners",
    ratio: 16 / 9,
    device: "desktop",
    usedFor: null,
  },
  {
    label: "Banner (Mobile)",
    key: "banners",
    ratio: 16 / 9,
    device: "mobile",
    usedFor: null,
  },
  {
    label: "Thumbnail (Desktop)",
    key: "thumbnails",
    ratio: 16 / 9,
    device: "desktop",
    usedFor: null,
  },
  {
    label: "Thumbnail (Mobile)",
    key: "thumbnails",
    ratio: 16 / 9,
    device: "mobile",
    usedFor: null,
  },
];
