import styled from "@emotion/styled/macro"
import { keyframes } from '@emotion/react';

import { Header } from '../../molecules/baseSidebar/BaseSidebar.style';
import { PreviewLayoutType } from '../../../types';
import { SelectButton } from '../../atoms/select/Select.style';
import { maxLayout } from '../../../utils/breakpoints';

export const ContainerAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-120px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const ExpandAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-120px);
  }
  100% {
    opacity: 1;
    transform: translateX(330px);
  }
`;

export const ExpandAnimationMobile = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-120px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  position: fixed;
  flex: 0 0 330px;
  min-width: 330px;
  display: flex;
  z-index: 21;

  ${Header} {
    padding: var(--space-md) var(--space-sm);
  }

  animation: ${ContainerAnimation} 350ms cubic-bezier(0.57, 0, 0, 1) forwards;
`;

export const Overlay = styled.div`
  background: var(--grey1000);
  left: 330px;
  width: 100vw;
  height: 100vh;
  top: 0;
  position: absolute;
  opacity: 0.2;
  z-index: 19;
`;

export const Expand = styled.div<{ previewLayout: PreviewLayoutType }>`
  background: var(--grey100);
  width: ${(props) => (props.previewLayout === 'desktop' ? '600px' : '380px')};
  height: 100vh;
  position: absolute;
  padding: var(--space-md);
  padding-right: 0;
  z-index: 20;
  animation: ${ExpandAnimation} 350ms cubic-bezier(0.57, 0, 0, 1) forwards;

  @media ${maxLayout.md} {
    width: 100vw;
    animation: ${ExpandAnimationMobile} 350ms cubic-bezier(0.57, 0, 0, 1) forwards;
  }
`;

export const ExpandBackButton = styled.button`
  display: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0 var(--space-xs);
  font-size: 20px;
  position: relative;
  left: -8px;
  color: var(--grey800);

  @media ${maxLayout.md} {
    display: inline-block;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 4px;
  color: var(--grey1000);
`;

export const Description = styled.div`
  font-size: 16px;
  color: var(--grey600);
  padding-right: var(--space-sm);
`;

export const ComponentOptionWrapper = styled.div`
  padding-left: var(--space-sm);
  padding-right: var(--space-xs);
`;

export const ComponentOptionTitle = styled.div`
  font-size: 16px;
  color: var(--grey600);
  padding: var(--space-xs) 0px;
`;

export const ComponentOption = styled.button<{ isSelected: boolean }>`
  cursor: pointer;
  border-radius: var(--border-radius);
  border: none;
  background: ${(props) => (props.isSelected ? 'var(--grey200)' : 'transparent')};
  font-size: 18px;
  color: var(--grey1000);
  padding: var(--space-xs) 0px var(--space-xs) var(--space-sm);
  width: 100%;
  text-align: start;

  :hover {
    background: var(--grey100);
  }
`;

export const OptionsWrapper = styled.div`
  height: 95%;
  overflow-y: scroll;
  padding-right: 24px;
  margin-top: 16px;
  padding-bottom: 32px;

  > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const OptionTitle = styled.div`
  font-size: 16px;
  margin-bottom: 4px;
`;

export const Image = styled.img`
  width: 100%;
  border-radius: var(--border-radius);
  border: 2px solid transparent;
  transition: border 150ms ease-out;
`;

export const Option = styled.div<{ active?: boolean }>`
  cursor: pointer;

  ${OptionTitle} {
    color: ${(props) => (props.active ? 'var(--primary800)' : 'var(--grey1000)')};
  }

  ${Image} {
    ${(props) => (props.active ? 'border: 2px solid var(--primary800)' : '')};
  }

  :hover {
    ${Image} {
      border: 2px solid var(--primary800);
    }
  }
`;

export const PositionSelectWrapper = styled.div`
  button {
    margin-top: var(--space-sm);
  }
`;

export const SelectWrapper = styled.div`
  margin-top: var(--space-md);

  ${SelectButton} {
    background-color: #FFF;
  }
`;

export const PositionSelectLabel = styled.label`
`;
