import {
  createAction,
  createAsyncThunk as nativeCreateAsyncThunk,
} from "@reduxjs/toolkit";
import createAsyncThunk from "../../middleware/customCreateThunk";
import {
  loadAllBrand,
  deleteBrandById,
  loadBrandById,
  updateBrandById,
  createBrand,
} from "../../../services/api";
import { IBrand, IBrandEditable, SelectItemProps } from "../../../types";
import { formatSelectItems } from "../../../utils/dataService";

export const fetchBrands = createAsyncThunk({
  api: loadAllBrand,
  EVENT_NAME: "brands/loadAllBrand",
});

export const fetchBrandById = createAsyncThunk({
  api: loadBrandById,
  EVENT_NAME: "brands/loadBrandById",
});

export const updatedBrandById = createAsyncThunk({
  api: updateBrandById,
  EVENT_NAME: "brands/updatedBrandById",
});

export const deletedBrandById = createAsyncThunk({
  api: deleteBrandById,
  EVENT_NAME: "brands/deletedBrandById",
});

export const createdBrand = createAsyncThunk({
  api: createBrand,
  EVENT_NAME: "brands/created",
});

export const fetchBrandSelectItems = nativeCreateAsyncThunk(
  "brand/selectItem",
  async (payload: { languageCode: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = await loadAllBrand({ limit: "all", lang: payload.languageCode });
      const data = response?.data;
      if (data.data) {
        let selectItems: SelectItemProps[] = [];
        const filterdBrands = formatSelectItems(
          data.data,
          "name",
          payload.languageCode
        );
        // eslint-disable-next-line array-callback-return
        filterdBrands.map((item) => {
          if (!selectItems.includes(item)) {
            selectItems.push(item);
          }
        });
        dispatch(setBrandSelectItems(selectItems));
      }
      return response.data;
      // eslint-disable-next-line
    } catch (error: any) {
      const status = error?.response?.status || 404;
      const data = error?.response?.data;
      return rejectWithValue({ status, data });
    }
  }
);

export const setBrandSelectItems = createAction<SelectItemProps[]>(
  "brand/setSelectItem"
);
export const setCurrentBrand = createAction<IBrand>("brand/setCurrentBrand");

export const setTempBrand = createAction<IBrandEditable>("brand/setTempBrand");
export const resetTempBrand = createAction("brand/resetTempBrand");

export const resetCurrentBrand = createAction("brand/resetCurrent");
export const resetBrand = createAction("brand/reset");
export const resetBrandListState = createAction("brand/resetListState");
export const resetBrandDetailState = createAction("brand/resetDetailState");
