import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MediaItemProps } from "../MediaItem";
import {
  Container,
  ContentWrapper,
  FileIconWrapper,
  SelectIconWrapper,
  Text,
} from "./MediaItemC.style";

export function MediaItemC(props: MediaItemProps) {
  const { data, isSelected, onClickMedia } = props;
  return (
    <Container isActive={isSelected} onClick={onClickMedia}>
      <ContentWrapper>
        <FileIconWrapper>
          <FontAwesomeIcon icon={["far", "file"]} />
        </FileIconWrapper>
        <Text>{data?.alt || ""}</Text>
      </ContentWrapper>
      <SelectIconWrapper isActive={isSelected}>
        <FontAwesomeIcon icon={["far", "check"]} />
      </SelectIconWrapper>
    </Container>
  );
}
