import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, MenuWrapper, LabelText } from "./DropdownMenu.style";

type DropdownMenuData = {
  icon?: IconProp;
  label: string;
  onClick?: () => void;
};

export interface DropdownMenuProps {
  data: DropdownMenuData[];
  maxWidth?: string;
  padding?: string;
}

export function DropdownMenu(props: DropdownMenuProps) {
  const { data, maxWidth, padding } = props;
  return (
    <Container width={maxWidth}>
      {data.map((item, index) => (
        <MenuWrapper
          padding={padding}
          key={`${item.label}-${index + 1}`}
          onClick={item.onClick}
          id={"dropdown-menu"}
        >
          {item.icon && <FontAwesomeIcon icon={item.icon} />}
          <LabelText>{item.label}</LabelText>
        </MenuWrapper>
      ))}
    </Container>
  );
}
