import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const ColumnContainer = styled.div<{ height?:string, width?: string, maxWidth?: string, horizontalALign?: "left" | "center" | "right" }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => props.horizontalALign === 'right' ? 'flex-end' : props.horizontalALign === 'center' ? 'center' : 'flex-start'};
  ${(props) => {
    if(props.width) {
      return css`
        width: ${props.width};
      `
    }
    return css`
      flex: 1;
    `
  }};
  max-width: ${(props) => props.maxWidth ? props.maxWidth : '100%'};
  height: ${(props) => props.height};
  margin-left: 24px;

  &:last-child {
    margin-right: 24px;
  }
`