/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { Container, Separator, FormGroup, Content, Line, InputWithUnit, CancelButton, SubmitButtonContainer } from './SectionManageDataSource.style';
import { Tabs } from '../../atoms/tabs/Tabs';
import { Input } from '../../atoms/input/Input';
import {
  setCurrentEditChildComponent,
  setDataSources, setEditingDataSourceIndex,
  setIsEnableSave,
} from '../../../store/features/editor';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  ChildComponentProps,
  DataSourceParameterProps, DataSourceParameterValueProps,
  DataSourceProps,
  JSONComponentDataSourceProps,
  OptionProps,
  SuggestionProps,
} from '../../../types';
import { SuggestionSelector } from './SuggestionSelector';
import { setShouldSaveDraft } from '../../../store/features/page';
import { Toggle } from '../../atoms/toggle/Toggle';
import { Button } from '../../../atoms/button/Button';
import { Option, SelectWithSearch } from '../../molecules/selectWithSearch/SelectWithSearch';
import { Select } from '../../../atoms/select/Select';

interface SectionManageDataSourceProps {
  isEmptyData?: boolean;
  dataToEdit?: DataSourceProps;
  defaultDataSourceObject?: SuggestionProps; // สำหรับแสดง Label ให้ช่อง Content ID ในเคสที่แก้ไข Data Source
  indexToEdit?: number;
  onCancelEdit?: () => void;
}

export const SectionManageDataSource = (props: SectionManageDataSourceProps) => {
  const { isEmptyData = true, dataToEdit, onCancelEdit, indexToEdit, defaultDataSourceObject } = props;
  const isEditMode = Boolean(dataToEdit);

  const dispatch = useAppDispatch();
  const { currentEdit, dataSources, dataSourceAutoList, dataSourceIdList } = useAppSelector((state) => state.editor);
  const { currentPage } = useAppSelector((state) => state.page);

  const [method, setMethod] = useState<'id' | 'auto'>('auto');
  const [selectedDataSource, setSelectedDataSource] = useState<DataSourceProps | null>(null);
  const [dataParameters, setDataParameters] = useState<Array<DataSourceParameterProps>>([]);

  const tabItems: Array<OptionProps> = [
    { title: 'ดึงคอนเทนต์อัตโนมัติ', value: 'auto' },
    { title: 'ค้นหาด้วยชื่อคอนเทนต์', value: 'id' },
  ];

  const getSelectorValue = (parameterName: string, parameterValue: DataSourceParameterValueProps): DataSourceParameterValueProps | null => {
    if (parameterValue) {
      return parameterValue;
    }

    // if (parameterName === 'coverImgType') {
    //   const imgType = currentEdit?.componentSpec?.componentSettings?.imgType;
    //   if (imgType === 'vertical') {
    //     return {
    //       label: 'รูปปกแนวตั้ง',
    //       value: 'vertical'
    //     };
    //   }
    //   if (imgType === 'square') {
    //     return {
    //       label: 'รูปปกจัตุรัส',
    //       value: 'square'
    //     };
    //   }
    //   return {
    //     label: 'รูปปกแนวนอน',
    //     value: 'horizontal'
    //   };
    // }

    // if (parameterName === 'order') {
    //   return {
    //     label: 'ตอนล่าสุด',
    //     value: 'desc'
    //   };
    // }

    return null;
  };

  /**
   * getNewDataSources
   * @param currentDataSource Data Source ตัวปัจจุบันที่กำลังจะเพิ่ม หรือแก้ไข
   * @param ds dataSources หรือ dataSources2
   * @return ds ที่เพิ่ม Data Source ใหม่ หรือแก้ไข Data Source แล้ว
   */
  const getNewDataSources = (currentDataSource: JSONComponentDataSourceProps, ds:Array<JSONComponentDataSourceProps> = dataSources) => {
    if (indexToEdit !== undefined) {
      // เคสแก้ไข Data Source
      return ds.map((data, index) => (
        index === indexToEdit ? currentDataSource : data
      ));
    }

    // เคสเพิ่ม Data Source
    return [...ds, currentDataSource];
  };

  const getChildWithAddedData = (currentDataSource: JSONComponentDataSourceProps): ChildComponentProps | null => {
    if (!currentEdit?.childComponent) {
      return null;
    }

    const ds = currentEdit.childComponent.dataSources;
    const ds2 = currentEdit.childComponent.dataSources2;

    return {
      ...currentEdit.childComponent,
      ...(currentEdit.type === 'data') && { dataSources: ds ? getNewDataSources(currentDataSource, ds) : [currentDataSource] },
      ...(currentEdit.type === 'data2') && { dataSources2: ds2 ? getNewDataSources(currentDataSource, ds2) : [currentDataSource] },
    };
  };

  const updateDataSources = (currentDataSource: JSONComponentDataSourceProps) => {
    if (currentEdit.selectedChildIndex !== null) {
      // ถ้ากำลังแก้ Child Component เช่น กรุ๊ปคอลัมน์พิเศษ ให้เซฟค่าที่ Child Component
      const newChild = getChildWithAddedData(currentDataSource);
      if (newChild) {
        dispatch(setCurrentEditChildComponent(newChild));
      }
    } else {
      dispatch(setDataSources(getNewDataSources(currentDataSource)));
    }
  };

  const onSubmit = () => {
    if (!selectedDataSource) {
      return;
    }

    let newDataSource: JSONComponentDataSourceProps = {
      sourceType: selectedDataSource.sourceType,
      query: {
        languageCode: currentPage?.languageCode || 'th'
      }
    }

    if (method === 'id') {
      const contentIdObject = dataParameters[0];
      // const coverImageObject = dataParameters[1];
      // const orderObject = dataParameters[2];

      newDataSource = {
        ...newDataSource,
        id: contentIdObject.value ? contentIdObject.value.toString() : '',
      };

      // if (coverImageObject) {
      //   newDataSource.query = {
      //     ...newDataSource.query,
      //   };
      // }

      // if (orderObject) {
      //   newDataSource.query = {
      //     ...newDataSource.query,
      //     order: orderObject.value as string,
      //   };
      // }

      updateDataSources(newDataSource);
    }

    if (method === 'auto') {
      const newQuery = dataParameters.reduce((output: {[key: string]: DataSourceParameterValueProps}, parameter: DataSourceParameterProps) => {
        if (typeof parameter.value === 'object') {
          output[parameter.name] = parameter.value.value;
        } else {
          output[parameter.name] = parameter.type === 'number' ? parseInt(parameter.value as string, 10) : parameter.value;
        }
        return output;
      }, {})
      newDataSource = {
        ...newDataSource,
        query: {
          ...newDataSource.query,
          ...newQuery
        },
      };

      updateDataSources(newDataSource);
    }

    dispatch(setIsEnableSave(true));
    dispatch(setShouldSaveDraft(true));
    dispatch(setEditingDataSourceIndex(-1)); // Reset state if the user was editing data source.

    // Reset selected values
    setDataParameters(dataParameters.map((data) => ({
      ...data,
      value: data.name === 'limit' ? data.value : '',
    })));
    setSelectedDataSource(null);
  };

  const onSelectDataSource = (selectedOption: DataSourceProps | null) => {
    setSelectedDataSource(selectedOption);
    if (!selectedOption) {
      return;
    }

    let parameters = selectedOption.parameters || [];

    const componentType = currentEdit.component?.componentType;
    let prefillLimit = '4';

    if (dataToEdit) {
      prefillLimit = dataToEdit.query?.limit !== undefined ? `${dataToEdit.query.limit}` : '4';
    } else if (componentType) {
      if (componentType === 'ContentCardList') {
        prefillLimit = '10';
      }
      if (componentType === 'ContentCardVerticalList') {
        prefillLimit = '12';
      }
      if (componentType === 'GalleryWithInformation') {
        prefillLimit = '3';
      }
      if (componentType === 'Highlight' && currentEdit.type === 'data') {
        prefillLimit = '3';
      }
      if (componentType === 'TopicList') {
        prefillLimit = '5';
      }
      if (componentType === 'AdvanceRail') {
        prefillLimit = '11';
      }
    }

    if (parameters?.length) {
      parameters = parameters.map((param: DataSourceParameterProps) => {
        // @ts-expect-error Refer to any
        let value = dataToEdit ? dataToEdit[param.name] : undefined;

        if (param.name === 'limit') {
          value = prefillLimit;
        } else if (!value && dataToEdit?.query) {
          // @ts-expect-error Refer to any
          value = dataToEdit.query[param.name];
        }

        return {
          ...param,
          value
        };
      });
    }
    setDataParameters(parameters);
  };

  const onClickTab = (selectedTab: string | number) => {
    setMethod(selectedTab as 'id' | 'auto');
    onSelectDataSource(null);
  };

  const getOptionObject = (dataSource: DataSourceProps) => ({
    label: dataSource.displayName as string,
    value: dataSource.sourceType as string,
  });

  const onSelectDataSourceMenuOpen = () => {
    const sidebar = document.getElementById('sidebar-content');
    if (sidebar) {
      (sidebar as HTMLDivElement).style.overflowY = 'hidden';
      setTimeout(() => {
        (sidebar as HTMLDivElement).scrollTo({
          top: 500000,
          behavior: 'smooth',
        });
      }, 50);
    }
  };

  const onSelectDataSourceMenuClose = () => {
    const sidebar = document.getElementById('sidebar-content');
    if (sidebar) {
      (sidebar as HTMLDivElement).style.overflowY = 'auto';
    }
  };

  useEffect(() => {
    if (dataToEdit) {
      const { sourceType } = dataToEdit;
      if (dataToEdit.isFakeId) {
        // Auto mode
        const found = dataSourceAutoList.find((item) => item.sourceType === sourceType);
        onSelectDataSource(found || dataSourceAutoList[0]);
      } else {
        setMethod('id');
        const found = dataSourceIdList.find((item) => item.sourceType === sourceType);
        onSelectDataSource(found || dataSourceIdList[0]);
      }
    } else {
      onSelectDataSource(null);
    }
  }, []);

  return (
    <Container isEditMode={isEditMode}>
      { !isEmptyData && !isEditMode && (
        <Separator>
          <Line />
        </Separator>
      ) }
      <Content>
        <Tabs
          onSelect={(option) => onClickTab(option.value)}
          options={tabItems}
          selectedTab={tabItems.find((item) => item.value === method) || tabItems[0]} />
        <FormGroup>
          <label>แหล่งข้อมูล</label>
          { method === 'id' && Boolean(dataSourceIdList.length) && (
            <Select
              placeholder="เลือกหรือพิมพ์คำค้นหาที่นี่"
              searchable
              // onMenuOpen={onSelectDataSourceMenuOpen}
              // onMenuClose={onSelectDataSourceMenuClose}
              // menuHeight={250}
              data={dataSourceIdList.map(getOptionObject)}
              value={selectedDataSource ? getOptionObject(selectedDataSource).value.toString() : ''}
              onChange={(sourceType) => {
                const found = dataSourceIdList.find((item) => (item.sourceType === sourceType));
                onSelectDataSource(found || null);
              }} />
          )}
          { method === 'auto' && Boolean(dataSourceAutoList.length) && (
            <Select
              placeholder="เลือกหรือพิมพ์คำค้นหาที่นี่"
              searchable
              // onMenuOpen={onSelectDataSourceMenuOpen}
              // onMenuClose={onSelectDataSourceMenuClose}
              data={dataSourceAutoList.map(getOptionObject)}
              value={selectedDataSource ? getOptionObject(selectedDataSource).value.toString() : null}
              onChange={(sourceType) => {
                const found = dataSourceAutoList.find((item) => (item.sourceType === sourceType));
                onSelectDataSource(found || null);
              }} />
          )}
        </FormGroup>
        { selectedDataSource && dataParameters.map((parameter, parameterIndex) => (
          <FormGroup key={`${parameter.name}-${parameter.label}`}>
            <label>
              { (parameter.label === 'Content ID' || parameter.type === 'boolean') ? '' : parameter.label }
            </label>
            {parameter.hasDynamicOptions ? (
              <SuggestionSelector
                method={method}
                sourceType={selectedDataSource.sourceType}
                label={parameter.label}
                parameterName={parameter.name}
                value={getSelectorValue(parameter.name, parameter.value)}
                defaultValue={defaultDataSourceObject}
                inputSuggestions={parameter.suggestions}
                onSelect={(value: SuggestionProps) => {
                  const newData = [...dataParameters];
                  newData[parameterIndex] = {
                    ...newData[parameterIndex],
                    value: value,
                  };
                  setDataParameters(newData);
                }} />
            ) : parameter.type === 'number' ? (
              <InputWithUnit>
                <Input
                  value={parameter.value as string}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      onSubmit();
                    }
                  }}
                  type={parameter.type}
                  onChange={(e) => {
                    const newData = [...dataParameters];
                    newData[parameterIndex] = {
                      ...newData[parameterIndex],
                      value: e.target.value,
                    };
                    setDataParameters(newData);
                  }} />
                ชิ้น
              </InputWithUnit>
            ) : parameter.type === 'boolean' ? (
              <Toggle
                label={parameter.label}
                value={parameter.value as boolean}
                onChange={(booleanValue) => {
                  const newData = [...dataParameters];
                  newData[parameterIndex] = {
                    ...newData[parameterIndex],
                    value: booleanValue,
                  };
                  setDataParameters(newData);
                }} />
            ) : (
              <Input
                value={parameter.value as string}
                placeholder={parameter.name === 'tag' ? 'กรอกชื่อแท็ก เช่น ข่าวต่างประเทศ' : ''}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    onSubmit();
                  }
                }}
                type={parameter.type}
                onChange={(e) => {
                  const newData = [...dataParameters];
                  newData[parameterIndex] = {
                    ...newData[parameterIndex],
                    value: e.target.value,
                  };
                  setDataParameters(newData);
                }} />
            ) }
          </FormGroup>
        )) }
        <FormGroup>
          <SubmitButtonContainer>
            <Button onClick={onSubmit} fullWidth={true} disabled={!selectedDataSource}>
              {isEditMode ? 'บันทึกการแก้ไข' : 'เพิ่มคอนเทนต์'}
            </Button>
          </SubmitButtonContainer>
          {isEditMode && (
            <CancelButton onClick={onCancelEdit}>
              ยกเลิกการแก้ไข
            </CancelButton>
          )}
        </FormGroup>
      </Content>
    </Container>
  );
};
