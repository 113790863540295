import React from "react";
import {
  TextCellContainer,
  TextCellText,
  TextCellPrimaryText,
  IconContainer,
} from "./TextCell.style";

interface TextCellProps {
  icon?: React.ReactElement;
  primaryText?: string;
  text?: string;
  primaryTextLimitLine?: number;
  textLimitLine?: number;
}

export function TextCell(props: TextCellProps) {
  const {
    icon,
    primaryText = "",
    text = "",
    textLimitLine = 2,
    primaryTextLimitLine = 2,
  } = props;
  return (
    <TextCellContainer>
      {icon && <IconContainer>{icon}</IconContainer>}
      <div>
        <TextCellPrimaryText limitLine={primaryTextLimitLine}>
          {primaryText}
        </TextCellPrimaryText>
        <TextCellText limitLine={textLimitLine}>{text}</TextCellText>
      </div>
    </TextCellContainer>
  );
}
