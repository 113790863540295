import styled from "@emotion/styled/macro";

export const BaseErrorMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;

  width: 100%;
  text-align: center;
  border-radius: var(--border-radius-default);

  padding-top: var(--space-xxl);
  padding-right: var(--space-md);
  padding-bottom: var(--space-xxl);
  padding-left: var(--space-md);

  svg, .fa-3x {
    color: var(--grey700);
  }
`;

export const ErrorTitle = styled.h2`
  color: var(--grey700);
  text-transform: capitalize;
  font-size: 16px;
  margin-top: var(--space-md);
  margin-bottom: var(--space-xs);
`;

export const ErrorDescription = styled.p`
  color: var(--grey700);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: var(--space-l);
  white-space: break-spaces;
  word-break: break-all;
`;

export const ButtonContainer = styled.div`
  svg {
    margin-right: var(--space-xs);
  }
`;

export const ButtonText = styled.div`
`;
