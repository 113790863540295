import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, forwardRef, useEffect, useState } from "react";
import { Button } from "../../../atoms/button/Button";
import { HeaderCell } from "../../../atoms/headerCell/HeaderCell";
import { Select } from "../../../atoms/select/Select";
import { TextArea } from "../../../atoms/textArea/TextArea";
import { TextCell } from "../../../atoms/textCell/TextCell";
import { TextInput } from "../../../atoms/textInput/TextInput";
import { Column } from "../../../molecules/column/Column";
import { ContentDivider } from "../../../molecules/contentDivider/ContentDivider";
import { Modal } from "../../modal/Modal";
import { Row } from "../../../molecules/row/Row";
import {
  FacilityWrapper,
  ActionWrapper,
  MediaInputWrapper,
} from "../../../pages/projectDetail/ProjectDetail.style";
import { IMediaResponse } from "../../../types";
import { DataTable } from "../../dataTable/DataTable";
import { Section } from "../Section";

import { facilitySelector } from "../../../store/features/facility";
import { IFacility, initFacility } from "../../../types/facility";
import _ from "lodash";
import { useAppSelector } from "../../../store/hooks";
import { mapImageInputFacility } from "../../../types/mapMediaSection";
import { ImageInput } from "../../../atoms/imageInput/ImageInput";
import {
  onHandleChangeArrayImage,
  onHandleChangeEditableData,
} from "../../../utils/dataService";
import { ConfirmModal } from "../../confirmModal/ConfirmModal";
import { onClickRow } from "../../../utils/dataTable";
import {
  ButtonGroup,
  IconWrapper,
  OptionItemGroup,
  SelectOptionText,
} from "./SectionFacility.style";
import { Image } from "@mantine/core";

interface SectionFacilityProps {
  facilitiesData: IFacility[];
  onChangeDataAndSave: (value: any) => void;
  onChangeData: (value: any) => void;
}

export function SectionFacility(props: SectionFacilityProps) {
  const { facilitiesData, onChangeDataAndSave } = props;

  const { facilityTypes } = useAppSelector(facilitySelector);

  // Facility
  const [tempFacilitiesData, setTempFacilitiesData] = useState<IFacility[]>([]);
  const [editableFacilityData, setEditableFacilityData] =
    useState<IFacility>(initFacility);

  const [selectedFacility, setSelectedFacility] = useState<number | null>(null);

  // modal state
  const [facilityModalOpened, setFacilityModalOpened] =
    useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isReorder, setIsReorder] = useState<boolean>(false);

  function resetState() {
    setSelectedFacility(null);
    setEditableFacilityData(initFacility);
  }

  async function onHandleCreateFacility(data: IFacility) {
    onChangeDataAndSave([...facilitiesData, data]);
    setFacilityModalOpened(false);
    setEditableFacilityData(initFacility);
  }

  async function onHandleUpdateFacility(index: number, data: IFacility) {
    const clonedFacilities = _.cloneDeep(facilitiesData);
    clonedFacilities[index] = data;

    onChangeDataAndSave(clonedFacilities);
    resetState();
    setFacilityModalOpened(false);
  }

  async function onHandleDeleteFacility(index: number) {
    const clonedData = _.cloneDeep(facilitiesData);
    _.pullAt(clonedData, [index]);

    onChangeDataAndSave(clonedData);
    setSelectedFacility(null);
    setIsShowDeleteModal(false);
  }

  async function onHandleEditFacility(facility: IFacility, index: number) {
    setSelectedFacility(index);
    setEditableFacilityData(facility);
    setFacilityModalOpened(true);
  }

  function onHandleChangeImages(
    img: IMediaResponse | null,
    device: string | null,
    key: string
  ) {
    if (editableFacilityData) {
      const newData = onHandleChangeArrayImage(
        editableFacilityData,
        img,
        device,
        key
      );
      setEditableFacilityData(newData);
    }
  }

  function onHandleChangeEditableFacilityData(value: any, path: string) {
    setEditableFacilityData(
      onHandleChangeEditableData(editableFacilityData, path, value)
    );
  }

  function onMoveUpFacility(index: number) {
    const clonedData = _.cloneDeep(tempFacilitiesData);
    clonedData.splice(index - 1, 0, clonedData[index]);
    clonedData.splice(index + 1, 1);
    // onHandleChangeProjectData(clonedData);
    // onChangeData(clonedData);
    setTempFacilitiesData(clonedData);
  }

  function onMoveDownFacility(index: number) {
    const clonedData = _.cloneDeep(tempFacilitiesData);
    const targetData = clonedData[index];
    clonedData.splice(index, 1);
    clonedData.splice(index + 1, 0, targetData);
    // onHandleChangeProjectData(clonedData);
    // onChangeData(clonedData);
    setTempFacilitiesData(clonedData);
  }

  interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
    imgUrl: string;
    value: string;
    label: string;
  }

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ value, label, imgUrl, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <OptionItemGroup>
          <IconWrapper>
            <Image src={imgUrl} alt={label} />
          </IconWrapper>

          <div>
            <SelectOptionText>{label}</SelectOptionText>
          </div>
        </OptionItemGroup>
      </div>
    )
  );

  useEffect(() => {
    setTempFacilitiesData(facilitiesData);
  }, [facilitiesData]);

  return (
    <>
      {/* Remove facility */}
      {selectedFacility !== null ? (
        <ConfirmModal
          onClose={() => {
            setIsShowDeleteModal(false);
            setSelectedFacility(null);
          }}
          title="Delete Facility"
          opened={isShowDeleteModal}
          onClickPrimaryButton={() => onHandleDeleteFacility(selectedFacility)}
        >
          <div>
            Are you sure you want to delete{" "}
            <b>
              ‘
              {facilitiesData[selectedFacility].title ||
                _.get(
                  _.find(facilityTypes, {
                    id: facilitiesData[selectedFacility].facilityTypeId,
                  }),
                  "name",
                  ""
                )}
              ’
            </b>
            ?
          </div>
          <div style={{ marginTop: "16px" }}>
            Once you confirm, this facility will be permanently deleted from
            this project.
          </div>
        </ConfirmModal>
      ) : (
        <></>
      )}
      <Section
        title="Facility List"
        headerActionComponent={
          <Modal
            button={
              <ButtonGroup gap={"12px"}>
                {isReorder ? (
                  <>
                    <Button
                      buttonStyle="outline-light"
                      onClick={() => {
                        setIsReorder(false);
                        setTempFacilitiesData(facilitiesData);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      buttonStyle="primary"
                      onClick={() => {
                        setIsReorder(false);
                        onChangeDataAndSave(tempFacilitiesData);
                      }}
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      buttonStyle="outline-light"
                      onClick={() => {
                        setIsReorder(true);
                      }}
                      leftIcon={
                        <FontAwesomeIcon
                          icon={["far", "arrow-up-arrow-down"]}
                        />
                      }
                      disabled={facilitiesData.length < 2}
                    >
                      Reorder
                    </Button>
                    <Button
                      onClick={() => {
                        setFacilityModalOpened(true);
                        setEditableFacilityData({
                          ...initFacility,
                          facilityTypeId: _.get(facilityTypes, "[0].id", ""),
                        });
                      }}
                      leftIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
                      buttonStyle={"outline-light"}
                    >
                      Add
                    </Button>
                  </>
                )}
              </ButtonGroup>
            }
            secondaryButtonTitle="Cancel"
            onClickSecondaryButton={() => {
              setFacilityModalOpened(false);
              resetState();
            }}
            onClickPrimaryButton={() => {
              if (selectedFacility !== null) {
                onHandleUpdateFacility(selectedFacility, editableFacilityData);
              } else {
                onHandleCreateFacility(editableFacilityData);
              }
            }}
            primaryButtonTitle={selectedFacility !== null ? "Update" : "Add"}
            onClose={() => {
              setFacilityModalOpened(false);
              resetState();
            }}
            opened={facilityModalOpened}
            title={selectedFacility !== null ? "Edit Facility" : "Add Facility"}
            size={720}
          >
            <>
              <FacilityWrapper>
                <Select
                  itemComponent={SelectItem}
                  icon={
                    <IconWrapper>
                      <Image
                        src={
                          facilityTypes.find(
                            (item) =>
                              item.id === editableFacilityData.facilityTypeId
                          )?.icon?.originalUrl || ""
                        }
                        alt={"facility-icon"}
                      />
                    </IconWrapper>
                  }
                  label="Facility type"
                  data={facilityTypes.map((item) => {
                    return {
                      value: item.id,
                      label: item.name,
                      imgUrl: item?.icon?.originalUrl || "",
                    };
                  })}
                  value={editableFacilityData.facilityTypeId}
                  onChange={(e) =>
                    onHandleChangeEditableFacilityData(e, "facilityTypeId")
                  }
                />
                <TextInput
                  label={"Title"}
                  value={editableFacilityData?.title || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onHandleChangeEditableFacilityData(e.target.value, "title")
                  }
                />
                {/* <TextInput
                  label={"Subtitle"}
                  value={editableFacilityData?.subtitle || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onHandleChangeEditableFacilityData(
                      e.target.value,
                      "subtitle"
                    )
                  }
                /> */}
                <TextArea
                  label={"Description"}
                  value={editableFacilityData?.description || ""}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    onHandleChangeEditableFacilityData(
                      e.target.value,
                      "description"
                    )
                  }
                  height={150}
                />
              </FacilityWrapper>
              <div>
                <ContentDivider title="Image" />
                <div style={{ marginTop: 24 }}>
                  <MediaInputWrapper>
                    {mapImageInputFacility.map((item, index) => (
                      <ImageInput
                        {...item}
                        key={`${item.label}-${index + 1}`}
                        onClickChooseImage={(img) =>
                          onHandleChangeImages(img, item.device, "images")
                        }
                        previousImageData={
                          _.find(editableFacilityData.images, {
                            deviceType: item.device,
                          }) || null
                        }
                        data={{
                          imgUrl: _.get(
                            _.find(editableFacilityData.images, {
                              deviceType: item.device,
                            }),
                            "originalUrl",
                            ""
                          ),
                          imgAlt: _.get(
                            _.find(editableFacilityData.images, {
                              deviceType: item.device,
                            }),
                            "name",
                            ""
                          ),
                        }}
                      />
                    ))}
                  </MediaInputWrapper>
                </div>
              </div>
            </>
          </Modal>
        }
      >
        <>
          {Boolean(tempFacilitiesData.length) && (
            <DataTable
              header={
                <Row>
                  <Column>
                    <HeaderCell title="Facility Name" />
                  </Column>
                  <Column maxWidth="133px" children={<></>} />
                </Row>
              }
            >
              {tempFacilitiesData &&
                tempFacilitiesData.map((item, index) => (
                  <Row
                    key={`${item?.title}-${index + 500}`}
                    onClick={(e) =>
                      onClickRow(e, () => {
                        onHandleEditFacility(item, index);
                      })
                    }
                  >
                    <Column>
                      <TextCell
                        icon={
                          <IconWrapper>
                            <Image
                              src={
                                facilityTypes.find(
                                  (data) => data.id === item.facilityTypeId
                                )?.icon?.originalUrl || ""
                              }
                              alt={"facility-icon"}
                            />
                          </IconWrapper>
                        }
                        primaryText={
                          item.title ||
                          _.get(
                            _.find(facilityTypes, {
                              id: item.facilityTypeId,
                            }),
                            "name",
                            ""
                          )
                        }
                        text={_.get(
                          _.find(facilityTypes, {
                            id: item.facilityTypeId,
                          }),
                          "name",
                          ""
                        )}
                      />
                    </Column>
                    <Column maxWidth="133px" horizontalAlign="right">
                      <ActionWrapper>
                        {isReorder ? (
                          <ButtonGroup>
                            <Button
                              buttonStyle="secondary-grey"
                              borderradius={"8px 0px 0px 8px"}
                              onClick={() => onMoveUpFacility(index)}
                              width="28px"
                              height="28px"
                              padding="0"
                              disabled={!Boolean(index - 1 >= 0)}
                            >
                              <FontAwesomeIcon icon={["far", "arrow-up"]} />
                            </Button>
                            <Button
                              buttonStyle="secondary-grey"
                              borderradius={"0px 8px 8px 0px"}
                              onClick={() => onMoveDownFacility(index)}
                              width="28px"
                              height="28px"
                              padding="0"
                              disabled={
                                !Boolean(index + 1 < facilitiesData.length)
                              }
                            >
                              <FontAwesomeIcon icon={["far", "arrow-down"]} />
                            </Button>
                          </ButtonGroup>
                        ) : (
                          <>
                            <Button
                              buttonStyle="text-link-grey"
                              onClick={() => {
                                setSelectedFacility(index);
                                setIsShowDeleteModal(true);
                              }}
                              fontSize="sm"
                            >
                              Delete
                            </Button>
                            <Button
                              buttonStyle="text-link-primary"
                              onClick={() => onHandleEditFacility(item, index)}
                              fontSize="sm"
                            >
                              Edit
                            </Button>
                          </>
                        )}
                      </ActionWrapper>
                    </Column>
                  </Row>
                ))}
            </DataTable>
          )}
        </>
      </Section>
    </>
  );
}
