import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createdProductType,
  productTypeSelector,
} from "../../store/features/productType";
import { addToast } from "../../store/features/toast";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ManageProductType } from "../../templates/manageProductType/ManageProductType";
import { IProductTypeEditable } from "../../types";

export function CreateProjectType() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { createProductTypeState } = useAppSelector(productTypeSelector);

  function onHandleCreateProductType(data: IProductTypeEditable) {
    dispatch(createdProductType({ ...data, 'used-in': "project" }));
  }

  useEffect(() => {
    const status = createProductTypeState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully created" }));
      navigate(`/project-type`);
    }
    if (status === "error")
      dispatch(addToast({ status, title: "Create failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createProductTypeState.status, dispatch]);

  return (
    <ManageProductType
      dataUsedIn={"project"}
      navigateBackUrl={"/project-type"}
      pageTitle={"Create Project Type"}
      onClickCreate={(data) => onHandleCreateProductType(data)}
    />
  );
}
