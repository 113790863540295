import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { addToast } from "../../store/features/toast";
import {
  createUnitType,
  setDuplicateUnitTypeData,
  unitTypeSelector,
} from "../../store/features/unitType";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ManageUnitType } from "../../templates/manageUnitType/ManageUnitType";
import { IEditableUnitType } from "../../types/unitType";

export function CreateUnitType() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { createUnitTypeState } = useAppSelector(unitTypeSelector);

  // onClick Handler
  async function onHandleCreateUnitType(data: IEditableUnitType) {
    await dispatch(
      createUnitType({ ...data, isTemplate: data.forProjectId ? false : true })
    );
  }

  useEffect(() => {
    const status = createUnitTypeState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully created" }));
      navigate(`/unit-type`);
    }
    if (status === "error")
      dispatch(addToast({ status, title: "Create failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUnitTypeState.status, dispatch]);

  useEffect(() => {
    // dispatch(fetchProjectSelectItems({ languageCode }));

    return () => {
      dispatch(setDuplicateUnitTypeData(null));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ManageUnitType
      pageTitle="Create Unit Type"
      onClickCreate={(data) => onHandleCreateUnitType(data)}
      isShowProjectIdSelection
    />
  );
}
