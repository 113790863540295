import {
  createAction,
  createAsyncThunk as nativeCreateAsyncThunk,
} from "@reduxjs/toolkit";
import createAsyncThunk from "../../middleware/customCreateThunk";
import { loadAllLocation } from "../../../services/api";

import { SelectItemProps } from "../../../types";
import { formatSelectItems } from "../../../utils/dataService";

export const fetchLocations = createAsyncThunk({
  api: loadAllLocation,
  EVENT_NAME: "locations/loadAllLocation",
});

export const fetchLocationSelectItems = nativeCreateAsyncThunk(
  "location/selectItem",
  async (payload: { languageCode: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = await loadAllLocation({limit:"all"});
      const data = response?.data;
      if (data.data) {
        let selectItems: SelectItemProps[] = [];
        const filterdLocations = formatSelectItems(
          data.data,
          "name",
          payload.languageCode
        );
        // eslint-disable-next-line array-callback-return
        filterdLocations.map((item) => {
          if (!selectItems.some((obj) => obj.label === item.label))
            selectItems.push(item);
        });
        dispatch(setLocationSelectItems(selectItems));
      }
      return response.data;
      // eslint-disable-next-line
    } catch (error: any) {
      const status = error?.response?.status || 404;
      const data = error?.response?.data;
      return rejectWithValue({ status, data });
    }
  }
);

export const setLocationSelectItems = createAction<SelectItemProps[]>(
  "location/setSelectItem"
);
export const resetLocation = createAction("location/reset");
