import styled from "@emotion/styled/macro"
import { css } from '@emotion/react'
import { maxLayout } from '../../../utils/breakpoints';
import { TitleStyle } from '../../../globalStyle/GlobalStyle.style';

export const Container = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
`;

export const ChildrenWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: var(--space-sm);
  z-index: 1002;
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(33, 45, 55, 0.7);
  z-index: 1001;
`;

export const ChildrenContainer = styled.div`
  background-color: var(--white);
  border-radius: var(--border-radius);
  padding: var(--space-sm);
  min-width: 500px;
  min-height: 100px;

  @media ${maxLayout.sm} {
    min-width: unset;
    width: 90vw;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-sm);
`;

export const Title = styled.div<{ isShowBackButton?: boolean }>`
  ${TitleStyle};
  color: var(--grey900);
  cursor: ${(props) => (props.isShowBackButton ? 'pointer' : 'normal')};
  padding-right: var(--space-sm);
  transition: color 100ms ease;

  :hover {
    ${(props) => (props.isShowBackButton && css`
      svg {
        color: var(--primary600);
      }
    `)};
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  color: var(--grey800);
  padding: var(--space-xs) var(--space-sm);
  margin-right: -16px;
  margin-top: -6px;
  margin-bottom: -6px;
`;

export const BackButton = styled.button`
  position: relative;
  left: -4px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;

  svg {
    transition: color 100ms ease;
  }
`;
