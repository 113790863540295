import { InputHTMLAttributes, useState, FocusEvent } from "react";
import {
  TextInput as CoreTextInput,
  Button as CoreButton,
  createStyles,
  MantineTheme,
} from "@mantine/core";
import { TextInputProps } from "@mantine/core/lib/TextInput/TextInput";
import { CopyButton } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import { Button } from "../../atoms/button/Button";

import { Container } from "./CopyToClipboard.style";

const useStyles = createStyles(
  (theme, { floating }: { floating: boolean }) => ({
    root: {
      width: "100%",
    },
    label: {
      fontSize: theme.fontSizes.lg,
      marginBottom: theme.spacing.xs,
      color: "var(--grey700)",
    },
    required: {
      transition: "opacity 150ms ease",
      opacity: floating ? 1 : 0,
    },
    input: {
      minHeight: 44,
      height: 44,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: theme.fontSizes.lg,
      width: "100%",
      border: "1px solid var(--grey300)",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
      borderRight: 0,
      borderRadius: "8px 0 0 8px",
      ":disabled": {
        color: "var(--grey500)",
        backgroundColor: "var(--grey50)",
        cursor: "text",
        opacity: "unset"
      },
    },
    icon: {
      fontSize: 16,
      fontWeight: 700,
    },
  })
);

const useCopyStyles = createStyles((theme: MantineTheme) => {
  return {
    root: {
      minHeight: 44,
      minWidth: 104,
      position: "relative",
      paddingLeft: theme.spacing.sm,
      paddingRight: theme.spacing.sm,
      fontWeight: 600,
      fontSize: theme.fontSizes.md,
      borderRadius: "0 8px 8px 0",
      transition: "background-color 150ms ease",
      width: "fit-content",
      backgroundColor: "white",
      borderColor: "#D0D5DD",
      color: "var(--grey700)",
      variant: "outline",

      ":active": {
        transform: "none",
      },
    },
    inner: {
      width: "100%",
    },
  };
});

interface CopyToClipboardProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "size">,
    TextInputProps {}

/**
 * Documentation: {@link https://mantine.dev/core/copy-button/}
 *
 * Documentation: {@link https://mantine.dev/core/text-input/}
 * 
 * Documentation: {@link https://mantine.dev/core/button/}
 */
export function CopyToClipboard(props: CopyToClipboardProps) {
  const { label, placeholder, onFocus, onBlur, ...rest } = props;
  const [focused, setFocused] = useState(false);
  const { classes: textInpuClasses } = useStyles({
    floating: focused,
  });

  const { classes: copyButtonClasses } = useCopyStyles();

  return (
    <Container>
      <CoreTextInput
        {...rest}
        label={label}
        placeholder={placeholder}
        classNames={textInpuClasses}
        disabled
        onFocus={(event: FocusEvent<HTMLInputElement>) => {
          setFocused(true);
          if (onFocus) {
            onFocus(event);
          }
        }}
        onBlur={(event: FocusEvent<HTMLInputElement>) => {
          setFocused(false);
          if (onBlur) {
            onBlur(event);
          }
        }}
      />
      <CopyButton value={props.value?.toString() || ""}>
        {({ copied, copy }) => (
          <CoreButton
            leftIcon={
              <FontAwesomeIcon
                icon={!copied ? ["far", "copy"] : ["far", "check"]}
              />
            }
            classNames={copyButtonClasses}
            onClick={copy}
          >
            {!copied ? "Copy" : "Copied"}
          </CoreButton>
        )}
      </CopyButton>
    </Container>
  );
}
