import styled from "@emotion/styled/macro";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-sm);
  cursor: pointer;
`;

export const Text = styled.p`
  color: var(--grey600);
  margin-top: 4px;
  margin-right: var(--space-xs);
  margin-bottom: 0;
`;

export const Body = styled.div`
  position: relative;
  width: 44px;
`;

export const Label = styled.label`
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
`;

export const Inner = styled.span<{ isChecked: boolean }>`
  display: block;
  width: 200%;
  height: 22px;
  margin-left: -100%;
  background-color: ${(props) => (props.isChecked ? 'var(--primary800)' : '#9EA4A9')};
  transition: background-color 300ms cubic-bezier(0.57, 0, 0, 1);
`;

export const Circle = styled.span<{ isChecked: boolean }>`
  display: block;
  width: 14px;
  height: 14px;
  margin: 4px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${(props) => (props.isChecked ? '0' : '20px')};
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: right 300ms cubic-bezier(0.57, 0, 0, 1);
`;

export const Checkbox = styled.input`
  display: none;
`;
