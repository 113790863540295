import { createAction } from "@reduxjs/toolkit";
import createAsyncThunk from "../../middleware/customCreateThunk";
import { createTerm, loadAllTag } from "../../../services/api";

export const fetchAllTag = createAsyncThunk({
  api: loadAllTag,
  EVENT_NAME: "Tags/loadAllBadge",
});

export const createNewTag = createAsyncThunk({
  api: createTerm,
  EVENT_NAME: "Tags/createNew",
});


export const resetTag = createAction("Tag/reset");
export const addNewTag = createAction("Tag/addNewTag");
