import styled from "@emotion/styled/macro";

export const Container = styled.div<{ size?: string }>`
  position: relative;
  max-width: ${(props) => props.size && props.size};
  width: 100%;
  /* height: 180px; */
  /* border: 1px solid var(--grey300); */
  /* padding: 0px 16px; */
`;

export const InputImageWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--grey200);
  width: 100%;
  height: 180px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: var(--white);

  cursor: pointer;
`;

export const ChooseImageText = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: var(--grey600);
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--grey100);
  border: 6px solid var(--grey50);
  border-radius: 28px;

  color: var(--grey600);
`;

export const LabelText = styled.div`
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 500;
  color: var(--grey700);
`;

export const ImageWrapper = styled.div`
  position: relative;
  /* padding: 0px 16px; */
  border: 1px solid var(--grey200);
  background-color: var(--grey25);

  cursor: pointer;
`;

export const Overlay = styled.div<{ isHasImage: boolean }>`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OverlayText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--white);
`;
export const DimentionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  margin-top: 6px;
`;

export const DimentionText = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: var(--grey600);
`;

export const ImageSuggestionSizeText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey600);
`;
