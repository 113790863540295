import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { formatIsoLanguage } from "../../utils/format";

import { Heading } from "../../organisms/heading/Heading";
import { Button } from "../../atoms/button/Button";

import { ContentVariation, lang, StatusState } from "../../types";
import { SidePanel } from "../../organisms/sidePanel/SidePanel";
import { ContentDivider } from "../../molecules/contentDivider/ContentDivider";
import { onHandleSingleDataContentVariations } from "../../utils/dataService";
import { TextInput } from "../../atoms/textInput/TextInput";
import { DataTableContainer } from "../../pages/productTypeList/ProductTypeList.style";
import {
  Container,
  SidePanelWrapper,
  SidePanelContainer,
  ButtonWrapper,
  ButtonGroup,
} from "./ListPageWithLanguageSidebar.style";
import { useAppDispatch } from "../../store/hooks";
import { useLocation } from "react-router-dom";
import { addToast } from "../../store/features/toast";

interface IEditData {
  availableLanguages: string[];
  contentVariations: ContentVariation[];
  [key: string]: any;
}

interface ListPageWithLanguageSidebarProps {
  isSidePanelActive?: boolean;
  headerActionComponent?: React.ReactElement | React.ReactElement[];
  onClickSave?: () => void;
  onClickCancel?: () => void;
  children: React.ReactElement | React.ReactElement[];
  pageTitle: string;
  sideBarTitle?: string;
  editData: IEditData;
  onUpdateEditData?: (data: IEditData) => void;
  saveState?: StatusState;
}

export function ListPageWithLanguageSidebar(
  props: ListPageWithLanguageSidebarProps
) {
  const {
    isSidePanelActive = false,
    headerActionComponent = <></>,
    onClickSave = () => {},
    onClickCancel = () => {},
    children = <></>,
    editData,
    pageTitle = "",
    sideBarTitle,
    onUpdateEditData = () => {},
    saveState = { status: "idle", error: null },
  } = props;
  const languageCode = lang;
  const dispatch = useAppDispatch();
  const location = useLocation();

  function onChangeContentVariationData(
    editData: IEditData,
    path: string,
    value: string | string[],
    contentVariationKey?: string,
    customLanguage?: string
  ) {
    const clonedData = _.cloneDeep(editData);

    if (clonedData) {
      const contentVariations = _.get(clonedData, `${path}`, null);

      if (contentVariations) {
        const filtedIndex = _.findIndex(contentVariations, {
          languageCode: customLanguage ? customLanguage : languageCode,
        });
        _.set(
          clonedData,
          `${path}[${filtedIndex}].${contentVariationKey}`,
          value
        );
      } else {
        _.set(clonedData, `${path}`, [
          {
            languageCode: customLanguage ? customLanguage : languageCode,
            [`${contentVariationKey}`]: value,
          },
        ]);
      }
      onUpdateEditData(clonedData);
    }
  }

  useEffect(() => {
    const status = saveState.status;
    if (status === "success") dispatch(addToast({ status }));
    if (status === "error")
      dispatch(addToast({ status, title: "Update failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveState.status, dispatch, location.pathname]);

  return (
    <Container isSidePanelActive={isSidePanelActive}>
      <DataTableContainer>
        <Heading title={pageTitle} actionComponent={headerActionComponent} />
        {children}
      </DataTableContainer>
      {!_.isEmpty(editData) && isSidePanelActive && (
        <SidePanelWrapper isActive={isSidePanelActive}>
          <SidePanel
            title={
              sideBarTitle
                ? sideBarTitle
                : onHandleSingleDataContentVariations(
                    editData.contentVariations,
                    "name",
                    languageCode
                  ) || ""
            }
            closeButton
            onClose={onClickCancel}
            sidePanelAction={
              <SidePanelContainer>
                <ContentDivider title="Language" />
                {editData.availableLanguages?.map((item, index) => (
                  <TextInput
                    key={`${item}-${index + 1000}`}
                    label={formatIsoLanguage(item)}
                    value={
                      onHandleSingleDataContentVariations(
                        editData.contentVariations,
                        "name",
                        item
                      ) || ""
                    }
                    onChange={(e) =>
                      onChangeContentVariationData(
                        editData,
                        "contentVariations",
                        e.target.value,
                        "name",
                        item
                      )
                    }
                  />
                ))}
                <ButtonWrapper>
                  <Button
                    buttonStyle="outline-light"
                    leftIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
                  >
                    Add Language
                  </Button>
                </ButtonWrapper>
              </SidePanelContainer>
            }
            sidePanelFooterAction={
              <ButtonGroup>
                <Button buttonStyle="outline-light" onClick={onClickCancel}>
                  Cancel
                </Button>
                <Button buttonStyle="primary" onClick={onClickSave}>
                  Save
                </Button>
              </ButtonGroup>
            }
          />
        </SidePanelWrapper>
      )}
    </Container>
  );
}
