import styled from "@emotion/styled/macro";

export const Container = styled.div<{ column: number; row: number; columnWidth: string }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.column}, ${(props) => props.columnWidth ? props.columnWidth : "auto"});
  grid-template-rows: repeat(${(props) => props.row}, auto);
  gap: 4px;
`;

export const ListWrapper = styled.div`
  display: flex;
  padding: 4px;
`;
