import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../atoms/button/Button";
import { HeaderCell } from "../../../atoms/headerCell/HeaderCell";
import { TextCell } from "../../../atoms/textCell/TextCell";
import { Column } from "../../../molecules/column/Column";
import { DropdownButton } from "../../../molecules/dropdownButton/DropdownButton";
import { Row } from "../../../molecules/row/Row";
import { ProjectUnitTypeData } from "../../../types";
import {
  UnitTypeFilterByHouseCollection,
  UnitTypeForProject,
} from "../../../types/unitType";
import { groupUnitTypeByHouseCollection } from "../../../utils/mapData";
import { ConfirmModal } from "../../confirmModal/ConfirmModal";
import { DataTable } from "../../dataTable/DataTable";
import { ModalUnitType } from "../../modal/modalUnitType/ModalUnitType";
import { Section } from "../Section";
import {
  ButtonGroup,
  HeaderTable,
  IconWrapper,
  TitleWrapper,
  ContentWrapper,
  HeaderText,
  ButtonWrapper,
} from "./SectionUnitType.style";

interface SectionUnitTypeProps {
  previousUnitType: ProjectUnitTypeData | null;
  sectionTitle?: string;
  projectId?: string;
  onChangeData: (value: string[]) => void;
}

export function SectionUnitType(props: SectionUnitTypeProps) {
  const {
    previousUnitType,
    sectionTitle = "Unit Type / House Collection",
    projectId,
    onChangeData,
  } = props;
  const navigate = useNavigate();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] =
    useState<boolean>(false);
  const [isReorder, setIsReorder] = useState<boolean>(false);

  const [filterData, setFilterData] = useState<
    UnitTypeFilterByHouseCollection[]
  >([]);
  const [tempFilterData, setTempFilterData] = useState<
    UnitTypeFilterByHouseCollection[]
  >([]);

  const [selectedUnitTypeListData, setSelectedUnitTypeListData] = useState<{
    unitTypeIds: string[];
    unitTypes: UnitTypeForProject[];
  }>({ unitTypeIds: [], unitTypes: [] });

  const [selectedFilterData, setSelectedFilterData] =
    useState<UnitTypeFilterByHouseCollection | null>(null);

  function sortUnitTypeIdsByHouseCollection(
    filterData: UnitTypeFilterByHouseCollection[]
  ): string[] {
    let ids: string[] = [];
    filterData.forEach((item) => {
      item.unitTypes.forEach((unitType) => ids.push(unitType.id));
    });
    return ids;
  }

  async function onHandleDeleteUnitTypeByHouseCollection(
    data: UnitTypeFilterByHouseCollection
  ) {
    const cloneData = _.cloneDeep(selectedUnitTypeListData);
    data.unitTypes.forEach((item) => {
      const unitTypeIndex = cloneData.unitTypeIds.findIndex(
        (id) => id === item.id
      );
      if (unitTypeIndex >= 0) {
        cloneData.unitTypeIds.splice(unitTypeIndex, 1);
        cloneData.unitTypes.splice(unitTypeIndex, 1);
      }
    });

    setSelectedUnitTypeListData(cloneData);
    onChangeData(cloneData.unitTypeIds);
    setSelectedFilterData(null);
  }

  function mergeUnitType(
    defaultValue: UnitTypeForProject[],
    availableValue: UnitTypeForProject[]
  ) {
    let mergedArray: UnitTypeForProject[] = [];
    defaultValue.forEach((item) => {
      const availableObj = _.find(availableValue, { id: item.id });
      if (availableObj) {
        mergedArray.push(availableObj);
      } else {
        mergedArray.push(item);
      }
    });
    return mergedArray;
  }

  function onMoveUpHouseCollection(index: number) {
    const clonedData = _.clone(filterData);
    const targetData = clonedData[index];
    clonedData.splice(index, 1);
    clonedData.splice(index - 1, 0, targetData);
    setFilterData(clonedData);
    // onChangeData(sortUnitTypeIdsByHouseCollection(clonedData));
  }

  function onMoveDownHouseCollection(index: number) {
    const clonedData = _.clone(filterData);
    const targetData = clonedData[index];
    clonedData.splice(index, 1);
    clonedData.splice(index + 1, 0, targetData);
    setFilterData(clonedData);
    // onChangeData(sortUnitTypeIdsByHouseCollection(clonedData));
  }

  function onMoveUpUnitType(filterIndex: number, itemIndex: number) {
    const clonedFilterData = _.clone(filterData);
    const targetData = clonedFilterData[filterIndex].unitTypes[itemIndex];
    clonedFilterData[filterIndex].unitTypes.splice(itemIndex, 1);
    clonedFilterData[filterIndex].unitTypes.splice(
      itemIndex - 1,
      0,
      targetData
    );
    setFilterData(clonedFilterData);
    // onChangeData(sortUnitTypeIdsByHouseCollection(clonedFilterData));
  }

  function onMoveDownUnitType(filterIndex: number, itemIndex: number) {
    const clonedFilterData = _.clone(filterData);
    const targetData = clonedFilterData[filterIndex].unitTypes[itemIndex];
    clonedFilterData[filterIndex].unitTypes.splice(itemIndex, 1);
    clonedFilterData[filterIndex].unitTypes.splice(
      itemIndex + 1,
      0,
      targetData
    );
    setFilterData(clonedFilterData);
    // onChangeData(sortUnitTypeIdsByHouseCollection(clonedFilterData));
  }

  useEffect(() => {
    if (previousUnitType) {
      if (
        _.isEqual(
          previousUnitType.defaultUnitTypes,
          previousUnitType.availableUnitTypes
        )
      ) {
        setSelectedUnitTypeListData({
          unitTypeIds: previousUnitType.unitTypeIds,
          unitTypes: previousUnitType.availableUnitTypes,
        });
      } else {
        const mergedData = mergeUnitType(
          previousUnitType.defaultUnitTypes,
          previousUnitType.availableUnitTypes
        );
        setSelectedUnitTypeListData({
          unitTypeIds: previousUnitType.unitTypeIds,
          unitTypes: mergedData,
        });
      }
    } else {
      setSelectedUnitTypeListData({ unitTypeIds: [], unitTypes: [] });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousUnitType]);

  useEffect(() => {
    setFilterData(
      groupUnitTypeByHouseCollection(selectedUnitTypeListData.unitTypes)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnitTypeListData]);

  useEffect(() => {
    setTempFilterData(
      groupUnitTypeByHouseCollection(selectedUnitTypeListData.unitTypes)
    );
  }, [selectedUnitTypeListData]);

  return (
    <>
      {selectedFilterData ? (
        <ConfirmModal
          onClose={() => {
            setIsDeleteModalOpened(false);
            // setSelectedUnitTypeData(null);
          }}
          title="Remove House Collection"
          opened={isDeleteModalOpened}
          onClickPrimaryButton={() =>
            onHandleDeleteUnitTypeByHouseCollection(selectedFilterData)
          }
          primaryButtonTitle={"Remove"}
        >
          <div>
            Are you sure you want to remove{" "}
            <b>
              ‘{selectedFilterData.houseCollectionName || "No house collection"}’
            </b>
            ?
          </div>
          <div style={{ marginTop: "16px" }}>
            This action cannot be undone and will only remove the house
            collection and all unit types under it from this project.
          </div>
        </ConfirmModal>
      ) : (
        <></>
      )}
      <Section
        isOverflowHidden
        title={sectionTitle}
        headerActionComponent={
          <ButtonGroup gap={"12px"}>
            {isReorder ? (
              <>
                <Button
                  buttonStyle="outline-light"
                  onClick={() => {
                    setIsReorder(false);
                    setFilterData(tempFilterData);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  buttonStyle="primary"
                  onClick={() => {
                    setIsReorder(false);
                    onChangeData(sortUnitTypeIdsByHouseCollection(filterData));
                  }}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button
                  buttonStyle="outline-light"
                  onClick={() => {
                    setIsReorder(true);
                  }}
                  leftIcon={
                    <FontAwesomeIcon icon={["far", "arrow-up-arrow-down"]} />
                  }
                  disabled={
                    !Boolean(
                      (filterData.length < 2 &&
                        _.get(filterData, "[0].unitTypes", [])?.length > 2) ||
                        filterData.length >= 2
                    )
                  }
                >
                  Reorder
                </Button>
                <ModalUnitType
                  previousUnitTypes={selectedUnitTypeListData}
                  isModalOpened={isModalOpened}
                  onCloseModal={() => setIsModalOpened(false)}
                  onOpenModal={() => setIsModalOpened(true)}
                  forProjectId={projectId}
                  onSubmit={(value, objValue) => {
                    if (selectedUnitTypeListData) {
                      setIsModalOpened(false);
                      setSelectedUnitTypeListData({
                        unitTypeIds: value,
                        unitTypes: objValue,
                      });
                      onChangeData(
                        sortUnitTypeIdsByHouseCollection(
                          groupUnitTypeByHouseCollection(objValue)
                        )
                      );
                    }
                  }}
                />
              </>
            )}
          </ButtonGroup>
        }
      >
        {filterData.map((item, index) => (
          <ContentWrapper
            key={`${item.houseCollectionId}-${index}-house-collection`}
          >
            <HeaderTable>
              <HeaderText>
                {item.houseCollectionName || "No House Collection"}
              </HeaderText>
              <ButtonGroup>
                {isReorder ? (
                  <>
                    <Button
                      buttonStyle="secondary-grey"
                      borderradius={"8px 0px 0px 8px"}
                      width="28px"
                      height="28px"
                      padding="0"
                      onClick={() => {
                        onMoveUpHouseCollection(index);
                      }}
                      disabled={!Boolean(index - 1 >= 0)}
                    >
                      <FontAwesomeIcon icon={["far", "arrow-up"]} />
                    </Button>
                    <Button
                      buttonStyle="secondary-grey"
                      borderradius={"0px 8px 8px 0px"}
                      width="28px"
                      height="28px"
                      padding="0"
                      onClick={() => {
                        onMoveDownHouseCollection(index);
                      }}
                      disabled={!Boolean(index + 1 < filterData.length)}
                    >
                      <FontAwesomeIcon icon={["far", "arrow-down"]} />
                    </Button>
                  </>
                ) : (
                  <ButtonWrapper>
                    <DropdownButton
                      horizontalAlign="right"
                      buttonProps={{
                        children: (
                          <FontAwesomeIcon
                            icon={["far", "ellipsis-vertical"]}
                          />
                        ),
                        buttonStyle: "text-link-grey",
                        width: "20px",
                        position: "right",
                      }}
                      dropdownMenuProps={{
                        data: [
                          {
                            icon: ["far", "trash-can"],
                            label: "Remove from this project",
                            onClick: () => {
                              setIsDeleteModalOpened(true);
                              setSelectedFilterData(item);
                            },
                          },
                        ],
                        maxWidth: "240px",
                        padding: "16px",
                      }}
                    />
                  </ButtonWrapper>
                )}
              </ButtonGroup>
            </HeaderTable>
            <DataTable
              disableBorderRadius
              header={
                <Row>
                  <Column>
                    <HeaderCell title="Unit Type" />
                  </Column>
                  <Column maxWidth="150px" horizontalAlign="right">
                    <></>
                  </Column>
                </Row>
              }
            >
              {item.unitTypes.map((subItem, subItemIndex) => (
                <Row key={`${subItem.id}-${subItemIndex}-unit-type`}>
                  <Column height="78px">
                    <TitleWrapper>
                      {subItem.isTemplate && (
                        <IconWrapper>
                          <FontAwesomeIcon icon={["far", "lock"]} />
                        </IconWrapper>
                      )}
                      <TextCell
                        primaryTextLimitLine={1}
                        primaryText={subItem?.name || ""}
                        text={subItem?.internalNote || ""}
                        textLimitLine={1}
                      />
                    </TitleWrapper>
                  </Column>
                  <Column maxWidth="150px" horizontalAlign="right">
                    <ButtonGroup>
                      {isReorder ? (
                        <>
                          <Button
                            buttonStyle="secondary-grey"
                            borderradius={"8px 0px 0px 8px"}
                            width="28px"
                            height="28px"
                            padding="0"
                            onClick={() => {
                              onMoveUpUnitType(index, subItemIndex);
                            }}
                            disabled={!Boolean(subItemIndex - 1 >= 0)}
                          >
                            <FontAwesomeIcon icon={["far", "arrow-up"]} />
                          </Button>
                          <Button
                            buttonStyle="secondary-grey"
                            borderradius={"0px 8px 8px 0px"}
                            width="28px"
                            height="28px"
                            padding="0"
                            onClick={() => {
                              onMoveDownUnitType(index, subItemIndex);
                            }}
                            disabled={
                              !Boolean(subItemIndex + 1 < item.unitTypes.length)
                            }
                          >
                            <FontAwesomeIcon icon={["far", "arrow-down"]} />
                          </Button>
                        </>
                      ) : (
                        <>
                          {subItem.isTemplate ? (
                            <Button
                              buttonStyle="text-link-primary"
                              fontSize="sm"
                              onClick={() => {
                                navigate(`/unit-type/${subItem.id}`);
                              }}
                            >
                              View
                            </Button>
                          ) : (
                            <Button
                              buttonStyle="text-link-primary"
                              fontSize="sm"
                              onClick={() => {
                                navigate(`/unit-type/${subItem.id}`);
                              }}
                            >
                              Edit
                            </Button>
                          )}
                        </>
                      )}
                    </ButtonGroup>
                  </Column>
                </Row>
              ))}
            </DataTable>
          </ContentWrapper>
        ))}
      </Section>
    </>
  );
}
