import { Section } from "../Section";
import { MediaInputContainer } from "./SectionMedia.style";

interface SectionMediaProps {
  mediaSection: React.ReactElement;
  row?: number;
  column?: number;
}

export function SectionMedia(props: SectionMediaProps) {
  const { mediaSection, row = 2, column } = props;
  return (
    <>
      <Section title="Media">
        <MediaInputContainer column={column} row={row}>
          {mediaSection}
        </MediaInputContainer>
      </Section>
    </>
  );
}
