import styled from "@emotion/styled/macro";

export const Container = styled.div`
  padding: 0px 32px 32px;
  overflow: auto;
  height: 100%;
  max-height: 100vh;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
  align-item: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary100);
  border: 6px solid var(--primary50);
  color: var(--primary600);
  width: 40px;
  height: 40px;
  border-radius: 28px;
  flex: 0 0 40px;
`;

export const IconWrapperGrey = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey100);
  border: 6px solid var(--grey50);
  color: var(--grey600);
  width: 40px;
  height: 40px;
  border-radius: 28px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

export const PaginateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 12px 24px 16px;
`;

export const InputWrapper = styled.div`
  padding-top: 4px;
`;
