import _ from "lodash";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  productTypeSelector,
  deletedProductTypeById,
  updatedProductTypeById,
} from "../../store/features/productType";
import { addToast } from "../../store/features/toast";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ManageProductType } from "../../templates/manageProductType/ManageProductType";
import { IProductTypeEditable, lang } from "../../types";

export function ProductTypeDetail() {
  const { id: productTypeId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentProductType: productType, updateProductTypeState, deleteProductTypeState } =
    useAppSelector(productTypeSelector);
  const languageCode = lang;

  async function onHandleDeleteProductType(productTypeId?: string) {
    if (productTypeId) {
      await dispatch(deletedProductTypeById(productTypeId));
      navigate("/product-type");
    }
  }

  function onHandleSaveProductType(productTypeData: IProductTypeEditable) {
    if (productTypeId && productTypeData) {
      dispatch(
        updatedProductTypeById({
          productTypeId: productTypeId,
          data: productTypeData,
        })
      );
    }
  }

  useEffect(() => {
    const status = updateProductTypeState.status;
    if (status === "success") dispatch(addToast({ status }));
    if (status === "error")
      dispatch(addToast({ status, title: "Update failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProductTypeState.status, dispatch]);

  useEffect(() => {
    const status = deleteProductTypeState.status;
    if (status === "success")
      dispatch(addToast({ status, title: "Successfully deleted" }));
    if (status === "error")
      dispatch(addToast({ status, title: "Delete failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProductTypeState.status, dispatch]);

  return (
    <ManageProductType
      isShowAddLanguageButton
      productTypeId={productTypeId}
      pageTitle={
        _.find(productType?.contentVariations, {
          languageCode: languageCode,
        })?.name || ""
      }
      dataUsedIn={'unit-type'}
      submitButtonTitle={"Save"}
      onClickDelete={() => onHandleDeleteProductType(productTypeId)}
      onClickSubmit={(data) => onHandleSaveProductType(data)}
    />
  );
}
