import styled from "@emotion/styled/macro";

export const Container = styled.div<{ width?: string }>`
  position: relative;
  z-index: 1;
  overflow: hidden;

  background-color: var(--white);
  border-radius: 8px;
  border: 1px solid var(--grey200);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${(props) => props.width && props.width};
`;

export const MenuWrapper = styled.div<{ padding?: string }>`
  display: flex;
  align-items: center;
  gap: 8px;

  padding: ${(props) => (props.padding ? props.padding : "11px 16px")};
  color: var(--grey700);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  > * {
    pointer-events: none;
  }

  &:hover {
    background-color: var(--grey50);
  }

  /* &:active {
    background-color: var(--grey25);
  } */
`;

export const LabelText = styled.div`
  position: relative;
`;
