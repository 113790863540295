export function convertColorToSixDigit(color: string) {
  if(!color){
    return ''
  }
  let hex = color.replace(/^#/, "");

  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((c) => (c + c).padStart(2, "0"))
      .join("");
  }

  if (hex.length === 4 || hex.length === 5) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
  }

  if (hex.length === 8) {
    hex = hex.substr(0, 6);
  }

  return "#" + hex;
}
