import styled from "@emotion/styled/macro";

export const FullFilledContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  width: 100%;
  gap: 16px;

  height: 74px;

  border: 1px solid var(--grey200);
  border-radius: 12px;

  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  width: 100%;
  gap: 16px;

  height: 74px;

  border: 1px solid var(--grey200);
  border-radius: 12px;

  cursor: pointer;
`;

export const IconWrapper = styled.div`
  display: flex;
  background-color: var(--grey100);
  border: 6px solid var(--grey50);
  border-radius: 28px;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

export const EmptyDataTextWrapper = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: var(--grey600);
`;

export const TextWrapper = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--grey700);

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;

  padding-right: 40%;
  display: -webkit-box;
`;

// export const SubTextWrapper = styled.div`
//   font-size: 14px;
//   font-weight: 400;
//   color: var(--grey600);

//   display: -webkit-box;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   -webkit-line-clamp: 2; /* number of lines to show */
//   line-clamp: 2;
//   -webkit-box-orient: vertical;

//   padding-right: 40%;
// `;

export const FullFilledIconWrapper = styled.div`
  display: flex;
  color: var(--primary600);
  background-color: var(--primary100);
  border: 4px solid var(--primary50);
  border-radius: 28px;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`;

export const ContextWrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
  width:100%;
  align-items: center;
`;
export const ContentWrapper = styled.div`
  display: flex;
  gap: 16px;
  width:100%;
  align-items: center;
`;

export const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 36px 36px;
  gap: 4px;
  /* position: absolute;
  top: 8px;
  right: 8px; */
  pointer-event: none;

  span,
  svg {
    pointer-events: none;
  }
`;

export const LabelText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--grey700);
  margin-bottom: 6px;
`;
