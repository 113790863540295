import { MouseEvent } from 'react';
import { Body, Checkbox, Circle, Container, Inner, Label, Text } from './Toggle.style';

export interface ToggleProps {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export const Toggle = (props: ToggleProps) => {
  const { label, value, onChange } = props;

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onChange(!value);
  };

  return (
    <Container onClick={onClick}>
      <Text>
        {label}
      </Text>
      <Body>
        <Checkbox type="checkbox" name={label} id={label} />
        <Label htmlFor={label}>
          <Inner isChecked={value} />
          <Circle isChecked={value} />
        </Label>
      </Body>
    </Container>
  );
};
