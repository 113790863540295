import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

export const Container = styled.div`
  padding: 32px;
  height: 100%;
  overflow: hidden;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: var(--space-sm);
`;
export const SectionContentWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
`;

export const TotalText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey600);
`;

export const PaginateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
`;

export const MenuButton = styled.div`
  position: relative;
`;

export const DropdownMenuContainer = styled.div<{ isActive: boolean }>`
  position: absolute;
  top: 48px;
  right: 0;
  opacity: 0;
  pointer-events: none;

  ${(props) =>
    props.isActive &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}
`;

export const MoveFolderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InputWrapper = styled.div`
  padding-top: 4px;
`;
