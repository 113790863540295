type RuntimeConfig = {
  REACT_APP_ORIGIN: string
  REACT_APP_PF_BASE_URL: string
}

const defaultRuntimeConfig = {
  REACT_APP_ORIGIN: window.origin,
  REACT_APP_PF_BASE_URL: 'https://www.pf.co.th',
  REACT_APP_BASE_API_URL: `${window.origin}/api/v1`,
};

export const getRuntimeConfig = () => {
  const customRuntimeConfig = (window as any).publicRuntimeConfig || {};

  // TODO: How to not using "any" type here?
  const mergedConfig: any = {
    ...defaultRuntimeConfig,
  };
  for (var key in customRuntimeConfig) {
    const placeholderConfigValue = '{{' + key + '}}';
    const configValue = customRuntimeConfig[key];

    if (configValue !== placeholderConfigValue) {
      mergedConfig[key] = configValue;
    }
  }

  return mergedConfig as RuntimeConfig;
}