import { HeaderCellContainer, HeaderCellTitle } from "./HeaderCell.style";

interface HeaderCellProps {
  title: string;
}

export function HeaderCell(props: HeaderCellProps) {
  const { title = '' } = props
  return (
    <HeaderCellContainer>
      <HeaderCellTitle>{title}</HeaderCellTitle>
    </HeaderCellContainer>
  )
}