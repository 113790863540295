import { createStyles, Modal } from "@mantine/core";
import { useEffect } from "react";
import { Button } from "../../../atoms/button/Button";
import { projectSelector } from "../../../store/features/project";
import { addToast } from "../../../store/features/toast";
import {
  unitTypeSelector,
  createUnitType,
  fetchUnitTypeList,
} from "../../../store/features/unitType";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ManageUnitType } from "../../../templates/manageUnitType/ManageUnitType";
import { IEditableUnitType } from "../../../types/unitType";
import { FooterContainer } from "./ModalCreateUnitType.style";

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: "18px",
    color: "var(--grey900)",
    fontWeight: 600,
  },
  close: {
    color: "var(--grey500)",

    "> svg": {
      width: "24px",
      height: "24px",
    },
  },
  header: {
    position: "absolute",
    right: 32,
    top: 28,
  },
  inner: {
    padding: 0,
    backgroundColor: "rgba(52, 64, 84, 0.7)",
  },
  modal: {
    maxHeight: "calc(100vh - 60px)",
    marginTop: 60,
    width: "100%",
    overflow: "hidden",
    padding: "0 !important",
    borderRadius: "12px 12px 0px 0px",
  },
  body: {
    height: "calc(100vh - 60px)",
    backgroundColor: "var(--grey100)",
  },
}));

interface ModalCreateUnitTypeProps {
  isModalOpened: boolean;
  onCloseModal: () => void;
}

export function ModalCreateUnitType(props: ModalCreateUnitTypeProps) {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { createUnitTypeState, editUnitType } =
    useAppSelector(unitTypeSelector);
  const { currentProject } = useAppSelector(projectSelector);

  // onClick Handler
  async function onHandleCreateUnitType(data: IEditableUnitType) {
    await dispatch(
      createUnitType({
        ...data,
        isTemplate: !Boolean(currentProject?.id),
        forProjectId: currentProject?.id || null,
      })
    );
  }

  useEffect(() => {
    const status = createUnitTypeState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully created" }));
      dispatch(
        fetchUnitTypeList({
          'for-project': currentProject?.id,
          type: currentProject?.id ? "template,project" : "template",
        })
      );
      props.onCloseModal();
    }
    if (status === "error")
      dispatch(addToast({ status, title: "Create failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUnitTypeState.status, dispatch]);

  return (
    <Modal
      opened={props.isModalOpened}
      onClose={props.onCloseModal}
      classNames={classes}
    >
      <ManageUnitType
        customMaxHeightBody="calc(100% - 175px)"
        customMaxHeightContent="100%"
        isShowBackButton={false}
        pageTitle="Create Unit Type"
        isShowProjectIdSelection
        isDisableProjectSelection
        defaultForProjectId={currentProject?.id}
        // onClickCreate={(data) => {}}
      />
      <FooterContainer>
        <Button buttonStyle="outline-light" onClick={props.onCloseModal}>
          Cancel
        </Button>
        <Button
          buttonStyle="primary"
          onClick={() => editUnitType && onHandleCreateUnitType(editUnitType)}
        >
          Create Unit Type
        </Button>
      </FooterContainer>
    </Modal>
  );
}
