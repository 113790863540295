import styled from "@emotion/styled/macro"
import { css } from '@emotion/react';

export const Container = styled.div<{ withMargin: boolean }>`
  margin-bottom: ${(props) => (props.withMargin ? 'var(--space-sm)' : '0')};
  width: 100%;
`;

export const InputText = styled.input<{ error?: boolean }>`
  color: var(--grey900);
  background-color: var(--white);
  border: 1px solid var(--grey400);
  padding-top: var(--space-xs);
  padding-right: var(--space-xs);
  padding-bottom: var(--space-xs);
  padding-left: 9px;
  font-size: 14px;
  line-height: 1.4;
  width: 100%;
  border-radius: var(--border-radius);
  transition: border-color 0.2s ease-out;

  ::placeholder {
    color: var(--grey500);
  }

  &:focus,
  &:focus-visible {
    border-color: rgba(0, 166, 237, 0.5);
    outline: none;
  }

  &:disabled {
    color: var(--grey700);
    background-color: var(--grey400);
  }

  ${(props) => props.error
    && css`
      border-color: var(--error500) !important;
    `}
`;

export const Label = styled.p<{ error?: boolean }>`
  color: var(--grey700);
  font-size: 12px;
  margin-top: var(--space-xs);

  ${(props) => props.error
    && css`
      color: var(--error500);
    `}
`;
