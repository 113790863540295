import styled from "@emotion/styled/macro"

export const ButtonGroup = styled.div<{gap?:string}>`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: ${props => props.gap ? props.gap : "12px"};
`;

export const MediaCardWrapper = styled.div`
  max-width: 520px;
  :not(:last-child) {
    padding-bottom: var(--space-md);
  }
`;