import { CheckboxProps } from "@mantine/core";
import React from "react";
import { BadgeColorStyle } from "../badge/Badge";
import { CheckBoxItemA } from "./checkBoxItemA/CheckBoxItemA";

export type CheckBoxItemLayout = "A" | "B";

export interface CheckBoxItemProps extends CheckboxProps {
  layout?: CheckBoxItemLayout;
  value?:string;
  primaryText?: string;
  text?: string;
  badgeText?: string;
  badgeColor?: BadgeColorStyle;
  highlightText?:string;
  onClickSelect?:(isSelect:React.ReactNode) => void;
  onChecked?:(e:boolean) => void;
}

export function CheckBoxItem(props: CheckBoxItemProps) {
  const { layout = "A" } = props;
  return <>{layout === "A" && <CheckBoxItemA {...props} />}</>;
}
