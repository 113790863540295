import { useState, useRef, useEffect } from "react";
import { Button, ButtonProps } from "../../atoms/button/Button";
import {
  DropdownMenu,
  DropdownMenuProps,
} from "../../atoms/dropdownMenu/DropdownMenu";
import { DropdownMenuContainer, MenuButton } from "./DropdownButton.style";

export type MenuPosition = "top" | "bottom";
interface DropdownButtonProps {
  buttonProps: ButtonProps;
  dropdownMenuProps: DropdownMenuProps;
  position?: MenuPosition;
  horizontalAlign?: "left" | "center" | "right";
  gap?: string;
  right?: string;
}

export function DropdownButton(props: DropdownButtonProps) {
  const {
    buttonProps,
    dropdownMenuProps,
    position = "bottom",
    horizontalAlign = "center",
    gap,
    right,
  } = props;
  const dropdownRef = useRef(null);
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickOutside = (event: MouseEvent) => {
    // @ts-ignore
    if (dropdownRef?.current && !dropdownRef?.current?.contains(event.target)) {
      setIsMenuActive(false)
    }
  };


  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };

  }, [dropdownRef]);

  return (
    <>
      <MenuButton horizontalAlign={horizontalAlign} ref={dropdownRef}>
        <Button {...buttonProps} onClick={() => {setIsMenuActive(!isMenuActive)}}/>

        <DropdownMenuContainer
          position={position}
          isActive={isMenuActive}
          gap={gap}
          right={right}
        >
          <DropdownMenu {...dropdownMenuProps} />
        </DropdownMenuContainer>
      </MenuButton>
    </>
  );
}
