import _ from "lodash";
import { IEmbeddedMedia, IMediaResponse } from "../types";
import { IUnitType } from "../types/unitType";

export function onCastIUploadDataToEmbeddedImageData(
  data: IMediaResponse | null,
  deviceType: string | null,
  useFor?: string | null
) {
  if (data) {
    return {
      ...data,
      originalUrl: data.urls?.original || "",
      deviceType: deviceType,
      useFor: useFor || null,
    };
  } else {
    return null;
  }
}

export function onCastEmbeddedImageDataToIMediaResponseData(
  data?: IEmbeddedMedia | null
) {
  if (data) {
    return {
      ...data,
      id: "null",
      originalUrl: data.urls?.original || "",
      urls: { ...data?.urls, original: data.urls?.original || "" },
      parentMediaFolderId: null,
      parentMediaFolder: null,
      type: data?.type || "",
    };
  }
}

export function onCastUnitTypeDataToUnitTypeDataForProject(data: IUnitType, languageCode: string) {
  const contentVariation = _.find(data?.contentVariations, {
    languageCode: languageCode,
  });
  const houseCollectionContentVariation = _.find(
    data?.houseCollection?.contentVariations,
    {
      languageCode: languageCode,
    }
  );
  if (contentVariation) {
    return {
      ..._.omit(data, "contentVariations"),
      ...contentVariation,
      houseCollection: data.houseCollection
        ? {
            ..._.omit(data.houseCollection, "contentVariations"),
            ...houseCollectionContentVariation,
          }
        : null,
    };
  }
}