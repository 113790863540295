import styled from "@emotion/styled/macro";

export const TextCellContainer = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
  /* > :nth-child(2) {
    margin-top: 4px;
  } */
`;

export const TextCellPrimaryText = styled.div<{limitLine:number}>`
  color: #101828;
  font-weight: 500;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${props => props.limitLine}; /* number of lines to show */
  line-clamp: ${props => props.limitLine};
  -webkit-box-orient: vertical;

  /* padding-right: 5%; */
`;

export const TextCellText = styled.div<{limitLine:number}>`
  color: #475467;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${props => props.limitLine}; /* number of lines to show */
  line-clamp: ${props => props.limitLine};
  -webkit-box-orient: vertical;
`;

export const IconContainer = styled.div`
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  margin-right: 12px;
  flex: 0 0 40px;
`;
