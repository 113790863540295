import { PostListLayout } from "../../../templates/postListLayout/PostListLayout";

export function LocationSuggestionList() {
  return (
    <PostListLayout
      pageHeader="Location Suggestion"
      pathname="/location-suggestion"
      categoryKey="location-suggestion"
    />
  );
}
