import createAsyncThunk from "../../middleware/customCreateThunk";
import {
  createUnitTypeAPI,
  deleteUnitTypeAPI,
  loadAllUnitTypeAPI,
  loadUnitTypeByIdAPI,
  updateUnitTypeAPI,
} from "../../../services/api";
import { createAction } from "@reduxjs/toolkit";
import { IEditableUnitType, IUnitType } from "../../../types/unitType";
import { loadFunctionTypeListAPI } from "../../../services/api/function";

export const fetchUnitTypeList = createAsyncThunk({
  api: loadAllUnitTypeAPI,
  EVENT_NAME: "unitType/loadUnitTypeList",
});

export const fetchAllUnitTypeList = createAsyncThunk({
  api: loadAllUnitTypeAPI,
  EVENT_NAME: "unitType/loadAllUnitTypeList",
});

export const fetchUnitTypeDetail = createAsyncThunk({
  api: loadUnitTypeByIdAPI,
  EVENT_NAME: "unitType/loadUnitTypeDetail",
});

export const updateUnitTypeById = createAsyncThunk({
  api: updateUnitTypeAPI,
  EVENT_NAME: "unitType/updateById",
});

export const deleteUnitTypeById = createAsyncThunk({
  api: deleteUnitTypeAPI,
  EVENT_NAME: "unitType/deleteById",
});

export const createUnitType = createAsyncThunk({
  api: createUnitTypeAPI,
  EVENT_NAME: "unitType/createUnitType",
});

export const fetchFunctionTypeList = createAsyncThunk({
  api: loadFunctionTypeListAPI,
  EVENT_NAME: "unitType/loadFunctionTypeList",
})

export const setCurrentUnitType = createAction(
  "unitType/setCurrentUnitType",
  (payload: IUnitType) => {
    return {
      payload,
    };
  }
);

export const setDuplicateUnitTypeData = createAction<IUnitType | null>("unitType/setDuplicateUnitTypeData")
export const setEditUnitTypeData = createAction<IEditableUnitType | null>("unitType/setEditUnitTypeData")

export const resetUnitTypeListData = createAction("unitType/resetUnitTypeListData");
export const resetUnitTypeDetailData = createAction("unitType/resetUnitTypeDetailData");
