import EmptyMediaImage from "../../assets/images/Illustration-x1.png";

import { Image } from "@mantine/core";
import {
  ImageWrapper,
  Container,
  ContentContainer,
  ContentWrapper,
  MainText,
  SubText,
} from "./EmptyMedia.style";
import { ModalUpload } from "../modal/modalUpload/ModalUpload";

interface EmptyMediaProps {
  parentFolderId?: string;
  dataLoaderAfterCloseUpload:() => void;
  isAttachment?:boolean;
  supportingType?:string;
}

export function EmptyMedia(props: EmptyMediaProps) {
  const { parentFolderId, dataLoaderAfterCloseUpload, isAttachment, supportingType } = props;
  return (
    <Container>
      <ContentContainer>
        <ImageWrapper>
          <Image src={EmptyMediaImage} alt={"empty-media"} />
        </ImageWrapper>
        <ContentWrapper>
          <MainText>No Items yet</MainText>
          <SubText>Start by uploading your files.</SubText>
        </ContentWrapper>
        <ModalUpload loadData={dataLoaderAfterCloseUpload}  parentFolderId={parentFolderId} isAttachment={isAttachment} supportingType={supportingType}/>
      </ContentContainer>
    </Container>
  );
}
