import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../atoms/button/Button";
import { ImageInput } from "../../atoms/imageInput/ImageInput";
import { Loader } from "../../atoms/loading/loader/Loader";
import { Select } from "../../atoms/select/Select";
import { TextArea } from "../../atoms/textArea/TextArea";
import { TextInput } from "../../atoms/textInput/TextInput";
import {
  ContentContainer,
  ContentHolder,
  ContentWrapper,
  FlexContainer,
  ScrollWrapper,
} from "../../globalStyle/styles/ManagePage.style";
import { ContentDivider } from "../../molecules/contentDivider/ContentDivider";
import { PageHeader } from "../../organisms/pageHeader/PageHeader";
import {
  SidePanelTextGroup,
  SubText,
  SubTitle,
} from "../../organisms/projectSidePanel/ProjectSidePanel.style";
import { Section } from "../../organisms/section/Section";
import { SectionGallery } from "../../organisms/section/sectionGallery/SectionGallery";
import { SectionMedia } from "../../organisms/section/sectionMedia/SectionMedia";
import { SeoSection } from "../../organisms/section/seoSection/SeoSection";
import { SidePanel } from "../../organisms/sidePanel/SidePanel";
import {
  postSelector,
  resetPostDetail,
  setEditPostData,
} from "../../store/features/post";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { publishedData } from "../../types";
import { mapImageInputPostPage } from "../../types/mapMediaSection";
import { initialPostEditData, IPostEditable } from "../../types/post";
import { onCastIUploadDataToEmbeddedImageData } from "../../utils/castType";
import {
  onHandleChangeArrayImage,
  onHandleChangeEditableData,
} from "../../utils/dataService";
import { formatFullDateTime } from "../../utils/format";
import { ButtonGroup } from "../manageProject/ManageProject.style";
import { SidePanelSection } from "./ManagePost.style";

interface ManagePostProps {
  pageTitle: string;
  isCreatePage?: boolean;
  submitButtonTitle?: string;
  customElements?: React.ReactElement;
  isShowPreviewPage?: boolean;
  isShowUpdatedAt?: boolean;
  isShowCreatedAt?: boolean;
  isShowUrl?: boolean;
  isShowPostType?: boolean;
  isShowSelectLanguage?: boolean;
  onSubmit?: (data: IPostEditable) => void;
}

export function ManagePost(props: ManagePostProps) {
  const {
    pageTitle,
    isCreatePage,
    onSubmit = () => {},
    submitButtonTitle,
    customElements,
    isShowPreviewPage,
    isShowUpdatedAt,
    isShowCreatedAt,
    isShowUrl,
    isShowPostType,
    isShowSelectLanguage,
  } = props;
  const dispatch = useAppDispatch();
  const { currentPost, postEditData } = useAppSelector(postSelector);
  const [searchParams, setSearchParams] = useSearchParams();

  function onChangePostData(value: any, key: string) {
    if (postEditData) {
      dispatch(
        setEditPostData(onHandleChangeEditableData(postEditData, key, value))
      );
    }
  }

  useEffect(() => {
    if (postEditData) {
      searchParams.set("categoryKey", postEditData?.categoryKey);
      searchParams.set("type", postEditData?.type);
      setSearchParams(searchParams);
    } else {
      dispatch(
        setEditPostData({
          ...initialPostEditData,
          categoryKey: searchParams.get("categoryKey") || "",
          type: searchParams.get("type") || "",
        })
      );
    }
    return () => {
      dispatch(resetPostDetail());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return postEditData ? (
    <>
      <PageHeader
        backLink={`/${postEditData?.categoryKey}`}
        title={pageTitle}
        rightAction={
          <>
            {isCreatePage ? (
              <>
                <ButtonGroup>
                  <Button
                    leftIcon={<FontAwesomeIcon icon={["far", "eye"]} />}
                    buttonStyle="outline-light"
                    onClick={() => {}}
                  >
                    Preview / Customize Page
                  </Button>
                  <Button buttonStyle="primary" onClick={() => {}}>
                    Create
                  </Button>
                </ButtonGroup>
              </>
            ) : (
              <>
                <ButtonGroup>
                  <Button buttonStyle="outline-light" onClick={() => {}}>
                    <FontAwesomeIcon icon={["far", "trash-can"]} />
                  </Button>
                  <Button
                    leftIcon={<FontAwesomeIcon icon={["far", "eye"]} />}
                    buttonStyle="outline-light"
                    onClick={() => {}}
                  >
                    Preview / Customize Page
                  </Button>
                </ButtonGroup>
              </>
            )}
          </>
        }
      />
      <FlexContainer>
        <ContentContainer>
          <ScrollWrapper>
            <Section
              headerButtonStyle="primary"
              headerButtonTitle={submitButtonTitle}
              // isHeaderButtonDisabled={Boolean(slugError)}
              onClickHeaderButton={() => onSubmit(postEditData as IPostEditable)}
              title="General Information"
            >
              <ContentHolder>
                <ContentWrapper>
                  <TextInput
                    label="Title"
                    value={postEditData?.title || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChangePostData(e.target.value, "title");
                    }}
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <TextArea
                    label="Description"
                    value={postEditData?.description || ""}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      onChangePostData(e.target.value, "description");
                    }}
                  />
                </ContentWrapper>
                <ContentWrapper>{customElements}</ContentWrapper>
                <ContentWrapper>
                  <ContentDivider title="URL" />
                </ContentWrapper>
                <ContentWrapper>
                  <TextInput
                    label="Slug (Code)"
                    value={postEditData?.slugCode || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChangePostData(e.target.value, "slugCode");
                    }}
                  />
                </ContentWrapper>
              </ContentHolder>
            </Section>
            <SectionMedia
              column={2}
              mediaSection={
                <>
                  {mapImageInputPostPage.map((item, index) => (
                    <ImageInput
                      {...item}
                      key={`${item.label}-${index}`}
                      previousImageData={
                        _.find(_.get(postEditData, `${item.key}`), {
                          deviceType: item.device,
                        }) || null
                      }
                      data={{
                        imgUrl: _.get(
                          _.find(_.get(postEditData, `${item.key}`, null), {
                            deviceType: item.device,
                          }),
                          "originalUrl",
                          ""
                        ),
                        imgAlt: _.get(
                          _.find(_.get(postEditData, `${item.key}`, null), {
                            deviceType: item.device,
                          }),
                          "name",
                          ""
                        ),
                      }}
                      onClickChooseImage={(img) => {
                        dispatch(
                          setEditPostData(
                            onHandleChangeArrayImage(
                              postEditData,
                              img,
                              item.device,
                              item.key
                            )
                          )
                        );
                      }}
                    />
                  ))}
                </>
              }
            />
            <SectionGallery
              galleries={postEditData?.galleries || []}
              onChangeImagesData={(images) => {
                onChangePostData(images, "galleries");
              }}
            />
            <SeoSection
              data={{ ...postEditData, ogImage: postEditData?.openGraphImage }}
              onChangeOgDescription={(e) => {
                onChangePostData(e, "ogDescription");
              }}
              onChangeOgTitle={(e) => {
                onChangePostData(e, "ogTitle");
              }}
              onChangeSeoTitle={(e) => {
                onChangePostData(e, "seoTitle");
              }}
              onChangeSeoDescription={(e) => {
                onChangePostData(e, "seoDescription");
              }}
              onChangeOgImage={(e) => {
                onChangePostData(
                  onCastIUploadDataToEmbeddedImageData(e, null),
                  "openGraphImage"
                );
              }}
              onClickSave={() => {}}
            />
          </ScrollWrapper>
        </ContentContainer>
        <SidePanel
          title="Summary"
          sidePanelAction={
            <SidePanelSection>
              {isShowSelectLanguage && (
                <Select
                  disabled={!Boolean(isCreatePage)}
                  label={"Language"}
                  data={[
                    { label: "Thai", value: "th" },
                    { label: "English", value: "en" },
                  ]}
                  value={postEditData?.languageCode}
                  onChange={(e) => {
                    onChangePostData(e, "languageCode");
                  }}
                />
              )}
              {isShowPreviewPage && (
                <>
                  <ContentDivider title={"Page builder"} />
                  {/* {currentPageId ? ( */}
                  <>
                    <Select
                      data={[
                        { value: "public", label: "Public" },
                        { value: "unlisted", label: "Unlisted" },
                      ]}
                      value={postEditData?.visibilityStatus}
                      onChange={(e) => {
                        onChangePostData(e, "visibilityStatus");
                      }}
                      label={"Visibility"}
                    />
                    <Select
                      data={publishedData}
                      value={postEditData?.publishStatus}
                      onChange={(e) => {
                        onChangePostData(e, "publishStatus");
                      }}
                      label={"Publish status"}
                    />
                    <SidePanelTextGroup>
                      <SubTitle>Published at</SubTitle>
                      <SubText>
                        {currentPost?.publishedAt
                          ? formatFullDateTime(
                              new Date(currentPost?.publishedAt)
                            )
                          : ""}
                      </SubText>
                    </SidePanelTextGroup>
                    {/* <div style={{ display: "flex", justifyContent: "end" }}>
                        <Button
                          buttonStyle="outline-light"
                          onClick={() => {
                            navigate(`/page-builder/page/${currentPageId}`);
                          }}
                          rightIcon={
                            <FontAwesomeIcon
                              icon={["far", "arrow-right-arrow-left"]}
                            />
                          }
                        >
                          Switch to page builder
                        </Button>
                      </div> */}
                  </>
                  {/* ) : (
                    <>
                      <Button
                        leftIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
                        onClick={() => setIsShowCreatePageModal(true)}
                        buttonStyle="outline-light"
                      >
                        Create Page
                      </Button>
                    </>
                  )} */}
                </>
              )}
              {(isShowPostType || isShowUrl) && <ContentDivider />}
              <>
                {isShowPostType && (
                  <SidePanelTextGroup>
                    <SubTitle>Post type</SubTitle>
                    <SubText>{postEditData?.type || ""}</SubText>
                  </SidePanelTextGroup>
                )}
                {isShowUrl && (
                  <SidePanelTextGroup>
                    <SubTitle>URL</SubTitle>
                    <SubText>
                      {currentPost?.slugCode || postEditData?.slugCode || ""}
                    </SubText>
                  </SidePanelTextGroup>
                )}
              </>
              {isShowUpdatedAt && (
                <>
                  <ContentDivider />
                  {isShowUpdatedAt && (
                    <SidePanelTextGroup>
                      <SubTitle>Updated at</SubTitle>
                      <SubText>
                        {currentPost?.updatedAt
                          ? formatFullDateTime(new Date(currentPost?.updatedAt))
                          : ""}
                      </SubText>
                    </SidePanelTextGroup>
                  )}
                  {isShowCreatedAt && (
                    <SidePanelTextGroup>
                      <SubTitle>Created at</SubTitle>
                      <SubText>
                        {currentPost?.createdAt
                          ? formatFullDateTime(new Date(currentPost?.createdAt))
                          : ""}
                      </SubText>
                    </SidePanelTextGroup>
                  )}
                </>
              )}
            </SidePanelSection>
          }
        />
      </FlexContainer>
    </>
  ) : (
    <div style={{ margin: "auto" }}>
      <Loader color={"grey"} />
    </div>
  );
}
