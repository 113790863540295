import { Tabs, createStyles } from "@mantine/core";
import { LeftIcon, RightIcon } from "./TabMenu.style";

export type TabMenuData = {
  label: string;
  value: string;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
};

interface TabMenuProps {
  data: TabMenuData[];
  defaultValue?: string;
  selected: string | null;
  onChangeTab?: (value: string) => void;
}

const useStyles = createStyles((theme) => ({
  tabsList: {
    border: "1px solid var(--grey300)",
    borderRadius: "5px",
    overflow: "hidden"
  },
  tab: {
    color: "var(--grey700)",
    transition: "all .2s ease-in-out",
    border: 0,
    borderRadius: 0,
    marginBottom: 0,
    padding: "8px 16px",
    "&:hover": {
      color: "var(--grey800)",
      background: "var(--grey50)",
    },
    "&[data-active]": {
      color: "var(--grey800)",
      background: "var(--grey50)",
    },
    "&:not(:last-child)": {
      borderRight: "1px solid var(--grey300)",
    }
  },
  tabLabel: {
    fontSize: "14px",
    fontWeight: 600,
    display: "flex",
    alignItems: "center"
  },
  panel: {
    marginTop: 20,
    paddingTop: "0px !important",
  },
}));

export function TabMenu(props: TabMenuProps) {
  const { data, onChangeTab = () => {}, selected, defaultValue } = props;
  const { classes } = useStyles();

  return (
    <Tabs
      value={selected ? selected : defaultValue}
      onTabChange={(value: string) => onChangeTab(value)}
      classNames={classes}
    >
      <Tabs.List>
        {data.map((item, index) => (
          <Tabs.Tab key={`${item.label}-${index + 200}`} value={item.value}>
            {
              Boolean(item.leftIcon) && (
                <LeftIcon>
                  {item.leftIcon}
                </LeftIcon>
              )
            }
            {item.label}
            {
              Boolean(item.rightIcon) && (
                <RightIcon>
                  {item.rightIcon}
                </RightIcon>
              )
            }
          </Tabs.Tab>
        ))}
      </Tabs.List>
      {/* {data.map((item, index) => (
        <Tabs.Panel
          key={`${item.label}-panel-${index + 200}`}
          value={item.value}
          pt="xs"
        >
          {item.children}
        </Tabs.Panel>
      ))} */}
    </Tabs>
  );
}
