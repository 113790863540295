import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Battery,
  Canvas,
  Container, HintText,
  IFrame, IFrameContainer, IPhoneClock, IPhoneHeader, IPhoneHoles,
  PreviewContainer,
} from './PreviewArea.style';
import { PreviewAreaHeader } from '../../molecules/previewAreaHeader/PreviewAreaHeader';
import { useAppSelector } from '../../../store/hooks';
import { pageLoadingSelector } from '../../../store/features/page';

interface PreviewAreaProps {
  onIframeLoaded?: () => void;
}

export const PreviewArea = (props: PreviewAreaProps) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const { onIframeLoaded = () => {} } = props;
  const isLoading = useAppSelector(pageLoadingSelector);
  const { currentPage, saveDraftState } = useAppSelector((state) => state.page);
  const { previewLayout } = useAppSelector((state) => state.editor);
  const [iFrameUrl, setIFrameUrl] = useState<string>('');
  // const consoleBaseURL = process.env.REACT_APP_ORIGIN || 'https://console-page.thaipbs.or.th';
  // const websiteBaseURL = process.env.REACT_APP_PREVIEW || '//www.thaipbsbeta.com';

  // eslint-disable-next-line
  const formatPreviewPath = () => {
    if (!currentPage) {
      return '';
    }

    if (currentPage.path === 'content-preview') {
      return 'preview/content';
    }

    if (currentPage.path === 'episode-preview') {
      return 'preview/episode';
    }

    return `preview/${currentPage.layoutName === 'standard' ? 'light' : 'dark'}`;
  };

  const getIFrameUrl = () => {
    const { pathname } = window.location;
    if (pathname === '/page-builder') {
      // Homepage, no subpage selected
      return '';
    }

    if (!currentPage?.previewUrl) {
      return '';
    }

    // const jsonUrl = `${consoleBaseURL}/api/page/v1/pages/${currentPage.id}/latest-draft-revision/content`;
    // return `${websiteBaseURL}/${formatPreviewPath()}?page_content_url=${jsonUrl}`;
    return currentPage.previewUrl;
  };

  const padZero = (number: number) => (number < 10 ? `0${number}` : `${number}`);

  useEffect(() => {
    setIFrameUrl(getIFrameUrl());
  }, [currentPage]);

  useEffect(() => {
    // Reload iframe
    setIFrameUrl('');
    const timer = setTimeout(() => {
      setIFrameUrl(getIFrameUrl());
      clearTimeout(timer);
    }, 10);
  }, [saveDraftState.isDraftSaved]);

  return (
    <Container>
      <PreviewAreaHeader />
      <PreviewContainer>
        { iFrameUrl ? (
          <IFrameContainer previewLayout={previewLayout}>
            {Boolean(previewLayout === 'mobile') && (
              <IPhoneHeader dark={currentPage?.previewUri.includes('dark')}>
                <IPhoneClock>{`${new Date().getHours()}:${padZero(new Date().getMinutes())}`}</IPhoneClock>
                <IPhoneHoles />
                <Battery />
              </IPhoneHeader>
            )}
            <IFrame id="preview-iframe" src={iFrameUrl} onLoad={onIframeLoaded} />
            <FontAwesomeIcon icon={['fad', 'spinner-third']} spin />
          </IFrameContainer>
        ) : (
          <Canvas previewLayout={previewLayout}>
            <HintText>{isLoading ? 'กำลังโหลดข้อมูล กรุณารอสักครู่' : 'เลือกหน้าเว็บในเมนูด้านซ้ายเพื่อเริ่มต้นการแก้ไข'}</HintText>
          </Canvas>
        ) }
      </PreviewContainer>
    </Container>
  );
};
