import styled from '@emotion/styled'

export const RadioChoiceContainer = styled.div<{ checked?: boolean }>`
  padding: 16px;
  border: 2px solid ${(props) => props.checked ? 'var(--primary)' : '#EAECF0'};
  border-radius: 12px;
  display: flex;
  background: ${(props) => props.checked ? 'var(--primary25)' : 'transparent'};
  transition: all .2s ease-in-out;

  &:hover {
    .radio-text {
      color: var(--primary800);
    }
    cursor: pointer;
    border-color: var(--primary);
    background: var(--primary25);
  }
`

export const RadioContainer = styled.div`
  margin-right: 8px;
  margin-top: 2px;
`

export const RadioChoiceTextContianer = styled.div`
`

export const RadioChoiceTitle = styled.div<{ checked?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.checked ? 'var(--primary800)' : 'var(--grey700)'};
`

export const RadioChoiceSubtitle = styled.div<{ checked?: boolean }>`
  font-size: 14px;
  color: ${(props) => props.checked ? 'var(--primary800)' : '#475467'};
`