import { IEmbeddedMedia } from "./image";
import { IPlaceTypeSpecificLanguage } from "./placeType";

export interface INearby {
  places: PlaceDataType[];
  placeTypeId: string;
  placeType: IPlaceTypeSpecificLanguage | null;
  thumbnail: IEmbeddedMedia | null;
}

export type NearbyFilterByPlaceType = {
  placeTypeId: string | null;
  placeTypeName: string | null;
  nearbys: INearby[];
}

export type PlaceDataType = {
  distance: number;
  unit: string;
  names: string[];
}

export const initialPlace = {
  distance: 0,
  unit: "กม.",
  names: []
}
export const initNearby = {
  places: [initialPlace],
  placeTypeId: "",
  placeType: null,
  thumbnail: null,
};
