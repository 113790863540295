import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "@mantine/core";
import _ from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, ButtonStyles } from "../../../atoms/button/Button";
import { CheckBoxItem } from "../../../atoms/checkBoxItem/CheckBoxItem";
import { HorizontalList } from "../../../atoms/horizontalList/HorizontalList";
import { Pagination } from "../../../atoms/pagination/Pagination";
import { SearchInput } from "../../../atoms/searchInput/SearchInput";
import { PaginateWrapper } from "../../../globalStyle/GlobalStyle.style";
import { ContentDivider } from "../../../molecules/contentDivider/ContentDivider";
import { projectSelector } from "../../../store/features/project";
import {
  fetchAllUnitTypeList,
  fetchUnitTypeList,
  unitTypeSelector,
} from "../../../store/features/unitType";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { lang } from "../../../types";
import { UnitTypeForProject } from "../../../types/unitType";
import { mappingUnitTypeForProjectListData } from "../../../utils/mapData";
import { Modal } from "../Modal";
import { ModalCreateUnitType } from "./ModalCreateUnitType";
import {
  ModalBodyWrapper,
  CheckBoxWrapper,
  CreateUnitTypeWrapper,
  MainText,
} from "./ModalUnitType.style";

interface ModalUnitTypeProps {
  forProjectId?: string;
  previousUnitTypes: {
    unitTypeIds: string[];
    unitTypes: UnitTypeForProject[];
  };
  isModalOpened: boolean;
  modalButton?: React.ReactElement;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  primaryButtonStyle?: ButtonStyles;
  secondaryButtonStyle?: ButtonStyles;
  isButtonFullWidth?: boolean;
  onOpenModal?: () => void;
  onClickPrimaryButton?: () => void;
  onClickSecondaryButton?: () => void;
  onCloseModal: () => void;
  onSubmit?: (value: string[], objValue: UnitTypeForProject[]) => void;
}

export function ModalUnitType(props: ModalUnitTypeProps) {
  const {
    isModalOpened,
    onOpenModal,
    onCloseModal,
    previousUnitTypes,
    modalButton = (
      <Button
        buttonStyle="outline-light"
        onClick={onOpenModal}
        leftIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
      >
        Add
      </Button>
    ),
    onSubmit,
    forProjectId,
    // isButtonFullWidth,
    // primaryButtonText,
    // secondaryButtonText,
    // primaryButtonStyle,
    // secondaryButtonStyle,
    // onClickPrimaryButton,
    // onClickSecondaryButton,
  } = props;

  const languageCode = lang;

  const dispatch = useAppDispatch();
  const { allUnitTypeList, unitTypeList, pagination, createUnitTypeState } =
    useAppSelector(unitTypeSelector);
  const { currentProject } = useAppSelector(projectSelector);

  const [searchValue, setSearchValue] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedUnitTypeIds, setSelectedUnitTypeIds] = useState<string[]>([]);
  const [selectedUnitTypes, setSelectedUnitTypes] = useState<
    UnitTypeForProject[]
  >([]);

  const [isShowModalCreateUnitType, setIsShowModalCreateUnitType] =
    useState<boolean>(false);

  function onHandleSearch(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      dispatch(
        fetchUnitTypeList({
          q: searchValue,
          "for-project": forProjectId,
          type: "template,project",
        })
      );
    }
  }

  function onGenerateCheckboxItem() {
    return unitTypeList.map((item, index) => {
      const houseCollection = _.find(item?.houseCollection?.contentVariations, {
        languageCode: languageCode,
      });
      const note = item?.internalNote;
      return (
        <div key={`${item.id}-${index + 1}-unit-type-list`}>
          <CheckBoxWrapper>
            <CheckBoxItem
              value={item.id}
              primaryText={
                _.find(item?.contentVariations, { languageCode: languageCode })
                  ?.name || "-"
              }
              text={
                houseCollection || note
                  ? `${houseCollection?.name || ""}${
                      note && houseCollection ? " • " : ""
                    }${note ? note : ""}`
                  : "-"
              }
              badgeText={item.isTemplate ? "Template" : undefined}
              highlightText={item?.internalNote || ""}
            />
          </CheckBoxWrapper>
          {index === unitTypeList.length - 1 ? <></> : <ContentDivider />}
        </div>
      );
    });
  }

  useEffect(() => {
    dispatch(
      fetchUnitTypeList({
        page: currentPage,
        q: searchValue,
        "for-project": forProjectId,
        type: forProjectId ? "template,project" : "template",
      })
    );
    dispatch(
      fetchAllUnitTypeList({
        page: currentPage,
        q: searchValue,
        "for-project": forProjectId,
        type: forProjectId ? "template,project" : "template",
        limit: "all",
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, forProjectId]);

  useEffect(() => {
    setSelectedUnitTypeIds(previousUnitTypes.unitTypeIds);
    setSelectedUnitTypes(previousUnitTypes.unitTypes);
  }, [previousUnitTypes]);

  useEffect(() => {
    const status = createUnitTypeState.status
    if(status === "success"){
      dispatch(
        fetchAllUnitTypeList({
          page: currentPage,
          q: searchValue,
          "for-project": forProjectId,
          type: forProjectId ? "template,project" : "template",
          limit: "all",
        })
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUnitTypeState.status]);

  return (
    <>
      <Modal
        size={600}
        opened={isModalOpened}
        onClose={() => {
          onCloseModal();
          setSelectedUnitTypeIds(previousUnitTypes.unitTypeIds);
        }}
        title={"Choose Unit Types"}
        // description={
        //   "Choose the unit types that you want to include in this project"
        // }
        button={modalButton}
        // primaryButtonTitle={`Save ${
        //   selectedUnitTypeIds.length ? selectedUnitTypeIds.length : ""
        // } ${selectedUnitTypeIds.length === 1 ? "unit type" : "unit types"}`}
        primaryButtonTitle={`Choose`}
        onClickPrimaryButton={() => {
          onSubmit && onSubmit(selectedUnitTypeIds, selectedUnitTypes);
          setSelectedUnitTypeIds([]);
        }}
        isDisablePrimaryButton={_.isEqual(
          previousUnitTypes.unitTypeIds,
          selectedUnitTypeIds
        )}
        secondaryButtonTitle={"Cancel"}
        onClickSecondaryButton={() => {
          onCloseModal();
          setSelectedUnitTypeIds([]);
        }}
      >
        <ModalCreateUnitType
          isModalOpened={isShowModalCreateUnitType}
          onCloseModal={() => setIsShowModalCreateUnitType(false)}
        />
        <ModalBodyWrapper>
          <SearchInput
            onKeyDown={(e) => onHandleSearch(e)}
            value={searchValue}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setSearchValue(event.target.value)
            }
          />
          <HorizontalList gap={"0"} maxHeight={"calc(90vh - 385px)"}>
            <Checkbox.Group
              spacing={0}
              value={selectedUnitTypeIds}
              onChange={(e) => {
                setSelectedUnitTypeIds(e);
                setSelectedUnitTypes(
                  mappingUnitTypeForProjectListData(
                    allUnitTypeList,
                    e,
                    languageCode
                  )
                );
              }}
              orientation={"vertical"}
            >
              <>{onGenerateCheckboxItem()}</>
            </Checkbox.Group>
          </HorizontalList>
          <PaginateWrapper>
            <Pagination
              total={pagination.lastPage}
              page={currentPage || pagination.currentPage}
              // withControls={false}
              onChange={(e) => setCurrentPage(e)}
            />
          </PaginateWrapper>
          {Boolean(currentProject) && (
            <CreateUnitTypeWrapper>
              <MainText>Don't have unit type you're looking for?</MainText>
              <Button
                buttonStyle="text-link-primary"
                onClick={() => setIsShowModalCreateUnitType(true)}
                height="fit-content"
              >
                Create unit type for this project
              </Button>
            </CreateUnitTypeWrapper>
          )}
        </ModalBodyWrapper>
      </Modal>
    </>
  );
}
