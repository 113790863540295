import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button } from "../../../atoms/button/Button";
import { ImageInputModalWithMultiSelection } from "../../../atoms/imageInput/imageInputModalWithMultiSelection/ImageInputModalWithMultiSelection";
import { MediaItem } from "../../../atoms/mediaItem/MediaItem";
import { Pagination } from "../../../atoms/pagination/Pagination";
import { TextArea } from "../../../atoms/textArea/TextArea";
import { ContentDivider } from "../../../molecules/contentDivider/ContentDivider";
import { MediaList } from "../../../molecules/mediaList/MediaList";
import { IEmbeddedMedia } from "../../../types";
import { ModalEditEmbeddedMedia } from "../../modal/modalEditMedia/modalEditEmbeddedMedia/ModalEditEmbeddedMedia";
import { Section } from "../Section";
import {
  MediaListWrapper,
  PaginateWrapper,
  ContentWrapper,
  DescriptionWrapper,
  ContentDividerWrapper,
} from "./SectionGallery.style";

interface SectionGalleryProps {
  galleries: IEmbeddedMedia[];
  title?: string;
  buttonTitle?: string;
  isShowDescription?: boolean;
  isShowContentDivider?: boolean;
  contentDividerTitle?: string;
  descriptionValue?: string;
  descriptionLabel?: string;
  descriptionPlaceholder?: string;
  limitImage?: number;
  onChangeDescription?: (value: string) => void;
  onChangeImagesData: (value: any) => void;
}

export function SectionGallery(props: SectionGalleryProps) {
  const {
    title = "Gallery",
    buttonTitle = "Add",
    descriptionLabel = "Gallery description",
    descriptionPlaceholder = "Enter a description",
    limitImage = 15,
    galleries,
    onChangeImagesData,
    onChangeDescription,
    contentDividerTitle,
    isShowDescription,
    isShowContentDivider,
    descriptionValue,
  } = props;
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [isShowEditImageModal, setIsShowEditImageModal] =
    useState<boolean>(false);
  const [selectedImageIndex, setSelectedImageIndex] =
    useState<number | null>(null);

  const [paginate, setPaginate] = useState<{ from: number; to: number }>({
    from: 0,
    to: 15,
  });

  function onHandleClickAddImage(data: IEmbeddedMedia[] | null) {
    onChangeImagesData(data);
  }

  return (
    <Section
      title={title}
      headerActionComponent={
        <Button
          buttonStyle="outline-light"
          leftIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
          onClick={() => {
            setIsModalOpened(true);
          }}
        >
          {buttonTitle}
        </Button>
      }
    >
      <>
        <ImageInputModalWithMultiSelection
          previousImagesData={galleries}
          isModalOpened={isModalOpened}
          onCloseModal={() => setIsModalOpened(false)}
          onClickChooseImage={(img) => {
            onHandleClickAddImage(img);
            setIsModalOpened(false);
          }}
        />
        {selectedImageIndex !== null && (
          <ModalEditEmbeddedMedia
            data={galleries[selectedImageIndex]}
            isModalOpened={isShowEditImageModal}
            isModalDeleteImageOpened={false}
            isDisableNextButton={selectedImageIndex + 1 > galleries.length - 1}
            isDisablePreviousButton={selectedImageIndex - 1 < 0}
            onClickNext={() => {
              setSelectedImageIndex(selectedImageIndex + 1);
            }}
            onClickPrevious={() => {
              setSelectedImageIndex(selectedImageIndex - 1);
            }}
            onCloseModal={() => {
              setIsShowEditImageModal(false);
              setSelectedImageIndex(null);
            }}
          />
        )}
        <ContentWrapper>
          {(isShowDescription && onChangeDescription) ||
          (galleries && galleries.length) ? (
            <MediaListWrapper>
              {isShowDescription && onChangeDescription ? (
                <DescriptionWrapper>
                  <TextArea
                    label={descriptionLabel}
                    placeholder={descriptionPlaceholder}
                    value={descriptionValue}
                    onChange={(e) => onChangeDescription(e.target.value)}
                  />
                </DescriptionWrapper>
              ) : (
                <></>
              )}
              {isShowContentDivider ? (
                <ContentDividerWrapper>
                  <ContentDivider title={contentDividerTitle} />
                </ContentDividerWrapper>
              ) : (
                <></>
              )}
              {galleries && galleries.length ? (
                <>
                  <MediaList
                    columnWidth="auto"
                    limit={limitImage}
                    data={
                      galleries &&
                      galleries
                        .slice(paginate.from, paginate.to)
                        .map((item, index) => {
                          return (
                            <MediaItem
                              onClickMedia={() => {
                                setIsShowEditImageModal(true);
                                setSelectedImageIndex(index);
                              }}
                              isEditButton={false}
                              ratio={1 / 1}
                              layout="A"
                              data={{
                                src: item.originalUrl || "",
                                alt: item?.name || "",
                              }}
                            />
                          );
                        })
                    }
                  />
                  <PaginateWrapper>
                    <Pagination
                      total={Math.ceil(galleries.length / limitImage)}
                      onChange={(e) => {
                        setPaginate({
                          from: limitImage * (e - 1),
                          to: limitImage * e,
                        });
                      }}
                      // withControls={false}
                    />
                  </PaginateWrapper>
                </>
              ) : (
                <></>
              )}
            </MediaListWrapper>
          ) : (
            <></>
          )}
        </ContentWrapper>
      </>
    </Section>
  );
}
