import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { BackButton, Container, Content, HamburgerButton, Header, Title } from './BaseSidebar.style';

export type BottomAreaMarginProps = 'none' | 'small' | 'large';

interface SidebarProps {
  title: string | JSX.Element;
  backUrl?: string;
  children?: JSX.Element;
  backText?: string;
  onClickBack?: () => void;
  bottomAreaMargin?: BottomAreaMarginProps;
  searchElement?: JSX.Element;
  isMobileHamburger?: boolean;
}

export const BaseSidebar = (props: SidebarProps) => {
  const {
    title,
    backUrl,
    children,
    onClickBack,
    bottomAreaMargin = 'none',
    backText = 'ย้อนกลับ',
    searchElement,
    isMobileHamburger = true,
  } = props;

  const navigate = useNavigate();
  const [headerHeight, setHeaderHeight] = useState<number>(120);
  const [isHiding, setIsHiding] = useState<boolean>(false);
  const headerRef = useRef(null);

  const onBack = () => {
    if (onClickBack) {
      onClickBack();
    }

    if (backUrl) {
      navigate(backUrl);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (headerRef.current) {
        const header = headerRef.current as HTMLDivElement;
        setHeaderHeight(header.clientHeight);
      }
      clearTimeout(timer);
    }, 500);
  }, []);

  return (
    <>
      <HamburgerButton
        isMobileHamburger={isMobileHamburger}
        isHiding={isHiding}
        onClick={() => setIsHiding(!isHiding)}>
        <span />
        <span />
        <span />
      </HamburgerButton>
      <Container isMobileHamburger={isMobileHamburger} isHiding={isHiding}>
        <Header id="sidebar-header" ref={headerRef}>
          <BackButton
            isMobileHamburger={isMobileHamburger}
            hasUrl={Boolean(backUrl) || Boolean(onClickBack)}
            onClick={onBack}>
            { backUrl || onClickBack ? (
              <>
                <FontAwesomeIcon icon={['fas', 'chevron-left']} /> {backText}
              </>
            ) : 'CMS Page Builder' }
          </BackButton>
          <Title dangerouslySetInnerHTML={{ __html: title as string }}/>
          {searchElement}
        </Header>
        <Content id="sidebar-content" headerHeight={headerHeight} bottomAreaMargin={bottomAreaMargin}>
          { children }
        </Content>
      </Container>
    </>
  );
};
