import { useSearchParams } from "react-router-dom";
import { postSelector } from "../../../store/features/post";
import { useAppSelector } from "../../../store/hooks";
import { ManagePost } from "../../../templates/managePost/ManagePost";

export function CreateCollection() {
  const { postEditData } = useAppSelector(postSelector);
  const [searchParams] = useSearchParams();
  return (
    <>
      <ManagePost
        pageTitle={`Create Collection`}
        isShowPostType
        isShowSelectLanguage
        isShowPreviewPage
        isCreatePage
      />
    </>
  );
}
