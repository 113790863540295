import styled from "@emotion/styled/macro";

export const LogoContainer = styled.div`
  /* padding-top: 56.25%; */
  position: relative;
  border: 1px solid var(--grey200);
  /* width: 100%;
  overflow: hidden;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  } */
`;

export const HouseCollectionNumber = styled.div`
  padding: 12px 24px;
  border-bottom: 1px solid #eaecf0;
  /* border: 1px solid #EAECF0; */
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--grey400);
`;
