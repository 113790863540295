import { createReducer } from "@reduxjs/toolkit";
import { initialPagination, PaginationType, StatusState } from "../../../types";
import { IUser, Role } from "../../../types/user";
import { formatErrorObject } from "../../../utils/errorFormatter";
import { getStateObject } from "../../../utils/stateManagement";
import {
  createUser,
  fetchRoleList,
  fetchUserById,
  fetchUserList,
  resetRoleList,
  resetUser,
  resetUserDetail,
  resetUserList,
  updateUserById,
  userActivateById,
  userChangePassword,
  userSuspendById,
} from "./actions";

interface UserDetailInitialStateProps {
  currentUser: IUser | null;
  loadUserByIdState: StatusState;
  updateUserByIdState: StatusState;
  createUserState: StatusState;
  changeUserPasswordState: StatusState;
  activateUserByIdState: StatusState;
  suspendUserByIdState: StatusState;
}

const UserDetailInitialState = {
  currentUser: null,
  loadUserByIdState: getStateObject({ type: "idle" }),
  updateUserByIdState: getStateObject({ type: "idle" }),
  createUserState: getStateObject({ type: "idle" }),
  changeUserPasswordState: getStateObject({ type: "idle" }),
  activateUserByIdState: getStateObject({ type: "idle" }),
  suspendUserByIdState: getStateObject({ type: "idle" }),
};

interface UserListInitialStateProps {
  users: IUser[];
  loadUserListState: StatusState;
  pagination: PaginationType;
}

const UserListInitialState = {
  users: [],
  loadUserListState: getStateObject({ type: "idle" }),
  pagination: initialPagination,
};

interface RoleListInitialStateProps {
  roles: Role[];
  loadRoleListState: StatusState;
}

const RoleListInitialState = {
  roles: [],
  loadRoleListState: getStateObject({ type: "idle" }),
};

type InitialStateProps = UserDetailInitialStateProps &
  UserListInitialStateProps &
  RoleListInitialStateProps;

const initialState: InitialStateProps = {
  ...UserDetailInitialState,
  ...UserListInitialState,
  ...RoleListInitialState,
};

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUserList.pending, (state) => {
      state.loadUserListState = getStateObject({ type: "loading" });
    })
    .addCase(fetchUserList.fulfilled, (state, { payload }) => {
      state.users = payload?.data;
      state.loadUserListState = getStateObject({ type: "success" });
      state.pagination = {
        from: payload?.from,
        to: payload?.to,
        lastPage: payload?.lastPage,
        perPage: payload?.perPage,
        total: payload?.total,
        currentPage: payload?.currentPage,
      };
    })
    .addCase(fetchUserList.rejected, (state, { payload }) => {
      state.users = [];
      state.loadUserListState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "User List"),
      });
    })
    .addCase(fetchUserById.pending, (state) => {
      state.loadUserByIdState = getStateObject({ type: "loading" });
    })
    .addCase(fetchUserById.fulfilled, (state, { payload }) => {
      state.currentUser = payload;
      state.loadUserByIdState = getStateObject({ type: "success" });
    })
    .addCase(fetchUserById.rejected, (state, { payload }) => {
      state.loadUserByIdState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Current User"),
      });
    })
    .addCase(updateUserById.pending, (state) => {
      state.updateUserByIdState = getStateObject({ type: "loading" });
    })
    .addCase(updateUserById.fulfilled, (state, { payload }) => {
      state.currentUser = payload
      state.updateUserByIdState = getStateObject({ type: "success" });
    })
    .addCase(updateUserById.rejected, (state, { payload }) => {
      state.updateUserByIdState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Update User"),
      });
    })
    .addCase(createUser.pending, (state) => {
      state.createUserState = getStateObject({ type: "loading" });
    })
    .addCase(createUser.fulfilled, (state, { payload }) => {
      state.createUserState = getStateObject({ type: "success" });
    })
    .addCase(createUser.rejected, (state, { payload }) => {
      state.createUserState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Create User"),
      });
    })
    .addCase(fetchRoleList.pending, (state) => {
      state.loadRoleListState = getStateObject({ type: "loading" });
    })
    .addCase(fetchRoleList.fulfilled, (state, { payload }) => {
      state.roles = payload?.data;
      state.loadRoleListState = getStateObject({ type: "success" });
    })
    .addCase(fetchRoleList.rejected, (state, { payload }) => {
      state.loadRoleListState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Role List"),
      });
    })
    .addCase(userActivateById.pending, (state) => {
      state.activateUserByIdState = getStateObject({ type: "loading" });
    })
    .addCase(userActivateById.fulfilled, (state, { payload }) => {
      state.currentUser = payload;
      state.activateUserByIdState = getStateObject({ type: "success" });
    })
    .addCase(userActivateById.rejected, (state, { payload }) => {
      state.activateUserByIdState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Activate"),
      });
    })
    .addCase(userSuspendById.pending, (state) => {
      state.suspendUserByIdState = getStateObject({ type: "loading" });
    })
    .addCase(userSuspendById.fulfilled, (state, { payload }) => {
      state.currentUser = payload;
      state.suspendUserByIdState = getStateObject({ type: "success" });
    })
    .addCase(userSuspendById.rejected, (state, { payload }) => {
      state.suspendUserByIdState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Suspend"),
      });
    })
    .addCase(userChangePassword.pending, (state) => {
      state.changeUserPasswordState = getStateObject({ type: "loading" });
    })
    .addCase(userChangePassword.fulfilled, (state, { payload }) => {
      state.changeUserPasswordState = getStateObject({ type: "success" });
    })
    .addCase(userChangePassword.rejected, (state, { payload }) => {
      state.changeUserPasswordState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Change Password"),
      });
    })
    .addCase(resetUserDetail, (state) => {
      Object.assign(state, UserDetailInitialState);
    })
    .addCase(resetUserList, (state) => {
      Object.assign(state, UserListInitialState);
    })
    .addCase(resetRoleList, (state) => {
      Object.assign(state, RoleListInitialState);
    })
    .addCase(resetUser, (state) => {
      Object.assign(state, initialState);
    });
});
