export * from "./project";
export * from "./brand";
export * from "./location";
export * from "./productType";
export * from "./attachment";
export * from "./term";
export * from "./image";
export * from "./contentVariation";
export * from "./error";
export * from "./upload";
export * from "./state";
export * from "./media";
// export * from "./post";
export * from "./page";
export * from "./section";
export * from "./account";
export * from "./placeType";

export type QueryParams = {
  page?: string;
  q?: string;
  limit?: string;
  "used-in"?: string;
  lang?: string;
  brand?: string;
};

export type SelectItemProps = {
  value: string;
  label: string;
  [key: string]: any;
};

export type PaginationType = {
  from: number;
  to: number;
  lastPage: number;
  perPage: number;
  total: number;
  currentPage: number;
};

export const initialPagination = {
  from: 0,
  to: 0,
  lastPage: 0,
  perPage: 0,
  total: 0,
  currentPage: 0,
};
;
export const lang = "th";
export const currency = "บาท";
export const priceUnit = "ล้าน";

export const publishedData = [
  { value: "published", label: "Published" },
  { value: "draft", label: "Draft" },
  // { value: "scheduled", label: "Scheduled" },
];

export const unitArea = [
  { value: "ตร.ม.", label: "Sq.m." },
  { value: "ตร.ว.", label: "Sq.w." },
];

export const unitDistance = [
  {
    value: "กม.",
    label: "km",
  },
  {
    value: "ม.",
    label: "m",
  },
];
