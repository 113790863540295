import createAsyncThunk from "../../middleware/customCreateThunk";
import {
  createHouseCollectionAPI,
  deleteHouseCollectionAPI,
  loadAllHouseCollectionAPI,
  loadHouseCollectionByIdAPI,
  updateHouseCollectionAPI,
} from "../../../services/api";
import {
  createAction,
  createAsyncThunk as nativeCreateAsyncThunk,
} from "@reduxjs/toolkit";
import { IHouseCollectionType } from "../../../types/houseCollection";
import { SelectItemProps } from "../../../types";
import { formatSelectItems } from "../../../utils/dataService";

export const fetchHouseCollectionList = createAsyncThunk({
  api: loadAllHouseCollectionAPI,
  EVENT_NAME: "houseCollection/loadAllHouseCollection",
});

export const fetchHouseCollectionDetail = createAsyncThunk({
  api: loadHouseCollectionByIdAPI,
  EVENT_NAME: "houseCollection/loadHouseCollectionDetail",
});

export const updateHouseCollectionById = createAsyncThunk({
  api: updateHouseCollectionAPI,
  EVENT_NAME: "houseCollection/updateById",
});

export const deleteHouseCollectionById = createAsyncThunk({
  api: deleteHouseCollectionAPI,
  EVENT_NAME: "houseCollection/deleteById",
});

export const createHouseCollection = createAsyncThunk({
  api: createHouseCollectionAPI,
  EVENT_NAME: "houseCollection/createHouseCollection",
});

export const setCurrentHouseCollection = createAction(
  "houseCollection/setCurrentHouseCollection",
  (payload: IHouseCollectionType | null) => {
    return {
      payload,
    };
  }
);

export const fetchHouseCollectionSelectItems = nativeCreateAsyncThunk(
  "houseCollection/loadSelectItem",
  async (payload: { languageCode: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = await loadAllHouseCollectionAPI({ limit: "all", lang: payload.languageCode });
      const data = response?.data;
      if (data.data) {
        let selectItems: SelectItemProps[] = [];
        const filterd = formatSelectItems(
          data.data,
          "name",
          payload.languageCode
        );
        // eslint-disable-next-line array-callback-return
        filterd.map((item) => {
          if (!selectItems.includes(item)) {
            selectItems.push(item);
          }
        });
        dispatch(setHouseCollectionSelectItem(selectItems));
      }
      return response.data;
      // eslint-disable-next-line
    } catch (error: any) {
      const status = error?.response?.status || 404;
      const data = error?.response?.data;
      return rejectWithValue({ status, data });
    }
  }
);

export const setHouseCollectionSelectItem = createAction<SelectItemProps[]>(
  "houseCollection/setSelectItem"
);
export const resetHouseCollectionListData = createAction(
  "houseCollection/resetHouseCollectionListData"
);
export const resetHouseCollectionDetailData = createAction(
  "houseCollection/resetHouseCollectionDetailData"
);
