import styled from "@emotion/styled";

export const ContentWrapper = styled.div`
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const HeaderTable = styled.div`
  display: flex;
  width: 100%;
  height: 52px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: var(--grey100);
`;

export const HeaderText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--grey900);
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 44px;
`;

export const IconWrapper = styled.div``;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ButtonGroup = styled.div<{gap?:string}>`
  display: flex;
  align-items: center;
  gap: ${props => props.gap ? props.gap : "0px"};

  svg {
    pointer-events: none;
  }
`;
