import styled from "@emotion/styled/macro";

export const Container = styled.div<{ isError: boolean }>`
  position: relative;
  width: 100%;
  border: ${(props) => props.isError && "1px solid var(--error500)"};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Text = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: var(--white);
  background-color: rgba(0, 0, 0, 0.4);
  padding: 2px 0;

  cursor: pointer;
`;

export const CircleText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UploadedError = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 20px;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const RingProgressWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 20px;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const ErrorText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: var(--white);
`;

export const ErrorIconWrapper = styled.div`
  color: var(--error700);
`;
