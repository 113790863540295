import { createReducer } from "@reduxjs/toolkit";
import { ITerm, StatusState } from "../../../types";
import { addNewTag, createNewTag, fetchAllTag } from "./actions";
import { getStateObject } from '../../../utils/stateManagement';
import { formatErrorObject } from '../../../utils/errorFormatter';

interface InitialStateProps {
  tags: ITerm[];
  newTag: ITerm | null;
  loadTagState: StatusState;
  saveTagState: StatusState;
}

const initialState: InitialStateProps = {
  tags: [],
  newTag: null,
  loadTagState: {
    error: null,
    status: "",
  },
  saveTagState: {
    error: null,
    status: "",
  },
};

export const tagReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAllTag.pending, (state) => {
      state.loadTagState = getStateObject({ type: 'loading' });
    })
    .addCase(fetchAllTag.fulfilled, (state, { payload }) => {
      state.tags = payload?.data;
      state.loadTagState = getStateObject({ type: 'success' });
    })
    .addCase(fetchAllTag.rejected, (state, { payload }) => {
      state.tags = [];
      state.loadTagState = getStateObject({
        type: 'error',
        error: formatErrorObject(payload, 'Tags'),
      });
    })
    .addCase(createNewTag.pending, (state) => {
      state.saveTagState = getStateObject({ type: 'loading' });
    })
    .addCase(createNewTag.fulfilled, (state, { payload }) => {
      state.newTag = payload;
      state.saveTagState = getStateObject({ type: 'success' });
    })
    .addCase(createNewTag.rejected, (state, { payload }) => {
      state.newTag = null;
      state.saveTagState = getStateObject({
        type: 'error',
        error: formatErrorObject(payload, 'Tag'),
      });
    })
    .addCase(addNewTag, (state) => {
      if (state.newTag) {
        state.tags.push(state.newTag);
        state.newTag = null;
      }
    });
});
