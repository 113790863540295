import styled from "@emotion/styled/macro";
import { SubTitleStyle } from "../../../globalStyle/GlobalStyle.style";

export const Text = styled.p`
  ${SubTitleStyle};
  color: var(--grey900);
  margin-bottom: var(--space-sm);
  width: 100%;
  white-space: break-spaces;
  line-height: 1.8;
`;

export const Container = styled.div`
  max-width: 494px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    flex: 0 0 100px;
  }
`;
