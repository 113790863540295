import { ContentContainer, Page, SidebarContainer } from "./PageLayout.style";

interface PageLayoutProps {
  children?: React.ReactElement;
  navigationSidebar: React.ElementType;
  isShowNavigationSidebar?: boolean;
}

export function PageLayout(props: PageLayoutProps) {
  const {
    children = <></>,
    navigationSidebar: NavigationSidebar,
    isShowNavigationSidebar = true } = props;

  return (
    <Page>
      {
        isShowNavigationSidebar && (
          <SidebarContainer>
            <NavigationSidebar />
          </SidebarContainer>
        )
      }
      <ContentContainer>
        {children}
      </ContentContainer>
    </Page>
  )
}