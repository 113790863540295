import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createPage, pageDataSelector, resetCreatePageState } from "../../../store/features/page";
import {
  createPost,
  postSelector,
  setEditPostData,
} from "../../../store/features/post";
import { addToast } from "../../../store/features/toast";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IPostPromotion, IPostPromotionEditable } from "../../../types/post";
import { ManagePromotion } from "./ManagePromotion";

export function CreatePromotion() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { createPostState, newPost } = useAppSelector(postSelector);
  const { createPageState, newPage } = useAppSelector(pageDataSelector);
  const [isCreatePostWithoutPage, setIsCreatePostWithoutPage] =
    useState<boolean>(false);

  async function onCreatePost(data: IPostPromotionEditable) {
    await dispatch(createPost({ payload: { ...data } }));
  }

  async function onCreatePage(data: IPostPromotion) {
    if (data?.slugCode) {
      await dispatch(
        createPage({
          title: `${data?.title} (${data?.languageCode.toUpperCase()})`,
          path: `/${data?.languageCode}/promotion/${data?.slugCode}`,
          category: "post",
          resourceId: data?.id,
          resourceUrl: `/promotion/${data?.id}`,
          slug: `${data?.slugCode}-${data.languageCode}`,
          languageCode: data.languageCode,
        })
      );
    }
  }

  useEffect(() => {
    const status = createPostState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully created" }));
      dispatch(setEditPostData(null));
      if (isCreatePostWithoutPage) {
        navigate("/promotion");
      }else{
        onCreatePage(newPost as IPostPromotion)
      }
    }
    if (status === "error") {
      dispatch(addToast({ status, title: "Create failed" }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPostState.status]);

  useEffect(() => {
    const status = createPageState.status;
    if (status === "success") {
      if (newPage && !isCreatePostWithoutPage) {
        navigate(`/page-builder/page/${newPage?.id}`);
        return () => {
          dispatch(resetCreatePageState())
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPageState]);

  return (
    <>
      <ManagePromotion
        pageTitle={"Create Promotion"}
        isShowPostType
        isShowSelectLanguage
        isShowPreviewPage
        isShowCreatedAt
        isShowVisibilityStatus
        isShowHighlightStatus
        isCreatePage
        onSubmit={(data) => {
          onCreatePost(data);
          setIsCreatePostWithoutPage(true);
        }}
        onCreatePage={(data) => onCreatePost(data)}
      />
    </>
  );
}
