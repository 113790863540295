import { ReactChild, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Container,
  ChildrenWrapper,
  ChildrenContainer,
  Background,
  HeaderContainer,
  Title,
  IconWrapper,
  BackButton,
} from './Modal.style';

interface ModalProps {
  /**
   * Title of this card.
   */
  title: string;
  /**
   * React Component that was render on Modal.
   */
  children: ReactChild | ReactChild[];
  /**
   * @default false
   */
  isOpen: boolean;
  /**
   * function that triggers when clicking the modal's background.
   */
  onClose?: () => void;
  isShowBackButton?: boolean;
  onClickBack?: () => void;
}

export const Modal = (props: ModalProps) => {
  const { title, isOpen = false, children, onClose, onClickBack, isShowBackButton } = props;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  return (
    <Container isOpen={isOpen}>
      <Background onClick={onClose} />
      <ChildrenWrapper>
        <ChildrenContainer className="modal-card">
          <HeaderContainer>
            <Title
              isShowBackButton={isShowBackButton}
              onClick={isShowBackButton && onClickBack ? onClickBack : undefined}>
              { Boolean(isShowBackButton) && (
                <BackButton type="button">
                  <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                </BackButton>
              ) }
              {title}
            </Title>
            <IconWrapper onClick={onClose}>
              <FontAwesomeIcon icon={['fas', 'times']} />
            </IconWrapper>
          </HeaderContainer>
          {children}
        </ChildrenContainer>
      </ChildrenWrapper>
    </Container>
  );
};
