import {
  Tooltip as CoreTooltip,
  TooltipProps as CoreTooltipProps,
  createStyles,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  tooltip: {
    borderRadius: 8,
  },
}));

interface TooltipProps extends CoreTooltipProps {}

export function Tooltip(props: TooltipProps) {
  const { ...rest } = props;
  const { classes } = useStyles();

  return <CoreTooltip {...rest} classNames={classes} />;
}
