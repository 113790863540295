import styled from "@emotion/styled/macro";
import { maxLayout } from "../../utils/breakpoints";

//
export const ContentContainer = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 24px 24px 0px 24px; */

  > * {
    max-width: 750px;
    width: 100%;
    margin-bottom: 24px;
  }
`;

//
export const ContentHolder = styled.div`
  padding: var(--space-sm) var(--space-md) var(--space-md);
`;

//
export const ContentWrapper = styled.div`
  padding-bottom: var(--space-md);
  max-width: 520px;
`;

export const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 12px;
`;

export const FixedButtonGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 12px;
  /* position: fixed; */
  /* bottom: 24px;
  left: 24px;
  right: 24px; */
  /* margin-bottom: 24px; */
`;

export const InfoText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey600);
  margin-top: 6px;
`;

//
export const ScrollWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 89px);
  margin-bottom: 0;
  padding: 24px 0;

  @media ${maxLayout.xxl} {
    padding: 24px;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  > section {
    :last-child {
      margin-bottom: 0px;
    }
    margin-bottom: 24px;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

export const DistanceContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-end;
  max-width: 344px;
`;

export const ModalContentHolder = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  height: 100%;

  max-height: 61vh;
  overflow: auto;
  padding: 0 24px;
  /* padding-bottom: 24px; */

  > div {
    width: 100%;
  }
`;

export const FacilityWrapper = styled.div`
  max-width: 520px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
`;

export const MediaInputContainer = styled.div`
  display: grid;
  /* grid-template-columns: auto auto; */
  grid-template-rows: auto auto;
  grid-gap: 24px;
  padding: 24px;
  /* grid-auto-flow: column; */
`;

//
export const MediaInputWrapper = styled.div`
  display: grid;
  /* grid-template-columns: auto auto; */
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  /* grid-auto-flow: column; */
`;

export const MediaCardWrapper = styled.div`
  max-width: 520px;
  :not(:last-child) {
    padding-bottom: var(--space-md);
  }
`;
