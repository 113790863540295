import styled from "@emotion/styled/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 32px 32px;
  overflow: auto;
  max-height: 100vh;
`;

export const Text = styled.div`
  color: var(--grey600);
  font-size: 14px;
  font-weight: 400;
  padding: 12px 16px;
`;

export const StatusText = styled.div<{ isActive: boolean }>`
  color: ${(props) =>
    props.isActive ? "var(--success500)" : "var(--error700)"};
  font-size: 14px;
  font-weight: 400;
`;

export const BadgeWrapper = styled.div``;

export const IconWrapper = styled.div`
  color: var(--grey500);
`;
