import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import { Button, Container, Content, Icon, Title } from './Accordion.style';

interface AccordionProps {
  title: string | JSX.Element;
  children: Array<JSX.Element> | JSX.Element;
}

export const Accordion = (props: AccordionProps) => {
  const { title, children } = props;
  const [isCollapse, setIsCollapse] = useState<boolean>(true);
  const [contentHeight, setContentHeight] = useState<string>('0px');

  const contentRef = useRef(null);

  const onClickAccordion = () => {
    setIsCollapse(!isCollapse);

    if (!isCollapse) {
      setContentHeight('0px');
      return;
    }

    if (contentRef && contentRef.current) {
      const element = contentRef.current as HTMLDivElement;
      setContentHeight(`${element.scrollHeight}px`);
    }
  };

  return (
    <Container>
      <Button onClick={onClickAccordion}>
        <Title>{ title }</Title>
        <Icon isRotated={isCollapse}>
          <FontAwesomeIcon icon={['fas', 'chevron-up']} />
        </Icon>
      </Button>
      <Content
        style={{
          marginBottom: !isCollapse ? 'var(--space-sm)' : 0,
          maxHeight: `${contentHeight}`
        }}
        ref={contentRef}>
        { children }
      </Content>
    </Container>
  );
};
