import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, ChangeEvent } from "react";
import { TextArea } from "../../atoms/textArea/TextArea";
import { PageHeader } from "../../organisms/pageHeader/PageHeader";
import { Section } from "../../organisms/section/Section";
import { SeoSection } from "../../organisms/section/seoSection/SeoSection";
import { SidePanel } from "../../organisms/sidePanel/SidePanel";
import {
  productTypeSelector,
  fetchProductTypeById,
  fetchProductTypeSelectItems,
  resetProductTypeDetailState,
} from "../../store/features/productType";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  IMediaResponse,
  initProductType,
  IProductTypeEditable,
  lang,
  ProductTypeUsedIn,
} from "../../types";
import {
  onHandleChangeArrayImage,
  onHandleChangeEditableData,
} from "../../utils/dataService";
import { Button } from "../../atoms/button/Button";
import {
  ContentContainer,
  ContentHolder,
  ContentWrapper,
  ScrollWrapper,
  SidePanelContainer,
  ButtonWrapper,
  MediaInputWrapper,
  FlexContainer,
} from "../../globalStyle/styles/ManagePage.style";
import { TextInput } from "../../atoms/textInput/TextInput";
import { SectionMedia } from "../../organisms/section/sectionMedia/SectionMedia";
import { Select } from "../../atoms/select/Select";
import { onCastIUploadDataToEmbeddedImageData } from "../../utils/castType";
import { formatIsoLanguage } from "../../utils/format";
import { mapImageInputProductTypePage } from "../../types/mapMediaSection";
import { ImageInput } from "../../atoms/imageInput/ImageInput";
import { ConfirmModal } from "../../organisms/confirmModal/ConfirmModal";
import { Loader } from "../../atoms/loading/loader/Loader";

interface ManageProductTypeProps {
  productTypeId?: string;
  pageTitle: string;
  isShowAddLanguageButton?: boolean;
  submitButtonTitle?: string;
  navigateBackUrl?: string;
  dataUsedIn?: ProductTypeUsedIn;
  onClickSubmit?: (data: IProductTypeEditable) => void;
  onClickDelete?: () => void;
  onClickCreate?: (data: IProductTypeEditable) => void;
}

export function ManageProductType(props: ManageProductTypeProps) {
  const {
    productTypeId,
    pageTitle,
    isShowAddLanguageButton,
    submitButtonTitle,
    onClickSubmit,
    onClickDelete,
    onClickCreate,
    navigateBackUrl = "/product-type",
    dataUsedIn = "project",
  } = props;

  const dispatch = useAppDispatch();
  const { currentProductType: productType } =
    useAppSelector(productTypeSelector);
  const languageCode = lang;
  const [selectedLanguage, setSelectedLanguage] =
    useState<string>(languageCode);

  const [productTypeData, setProductTypeData] =
    useState<IProductTypeEditable | null>();
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);

  function onHandleChangeProductTypeData(path: string, value: any) {
    setProductTypeData(
      onHandleChangeEditableData(productTypeData, path, value)
    );
  }

  function onHandleChangeImages(
    img: IMediaResponse | null,
    device: string | null,
    key: string,
    useFor?: string | null
  ) {
    if (productTypeData) {
      const newData = onHandleChangeArrayImage(
        productTypeData,
        img,
        device,
        key,
        useFor
      );
      if (onClickSubmit) {
        onClickSubmit(newData);
      }
      setProductTypeData(newData);
    }
  }

  function loadData() {
    if (productTypeId) {
      dispatch(fetchProductTypeById(productTypeId));
    }
  }

  function reset() {
    dispatch(resetProductTypeDetailState());
  }

  // load data
  useEffect(() => {
    loadData();

    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productType) {
      const contentVariation = _.find(productType?.contentVariations, {
        languageCode: selectedLanguage,
      });

      if (contentVariation) {
        setProductTypeData({
          ...productType,
          contentVariation: contentVariation,
        });
      }
    } else {
      setProductTypeData({ ...initProductType, usedIn: dataUsedIn });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productType]);

  return productTypeData ? (
    <>
      <PageHeader
        title={pageTitle}
        backLink={navigateBackUrl}
        onClickBack={() => {}}
        rightAction={
          <>
            {onClickDelete && (
              <Button
                buttonStyle="outline-light"
                onClick={() => setIsShowDeleteModal(true)}
              >
                <FontAwesomeIcon icon={["far", "trash-can"]} />
              </Button>
            )}
            {onClickCreate && (
              <Button
                onClick={() =>
                  onClickCreate ? onClickCreate(productTypeData) : undefined
                }
              >
                Create
              </Button>
            )}
          </>
        }
      />
      <ConfirmModal
        onClose={() => {
          setIsShowDeleteModal(false);
        }}
        title="Delete Product Type"
        opened={isShowDeleteModal}
        onClickPrimaryButton={onClickDelete}
      >
        <div>
          Are you sure you want to delete{" "}
          <b>‘{productTypeData.contentVariation?.name || ""}’</b>?
        </div>
        <div style={{ marginTop: "16px" }}>
          Once you confirm, this product type will be permanently deleted and
          also removed from all contents that use it.
        </div>
      </ConfirmModal>
      <FlexContainer>
        <ContentContainer>
          <ScrollWrapper>
            <Section
              headerButtonStyle="primary"
              headerButtonTitle={submitButtonTitle}
              onClickHeaderButton={
                onClickSubmit ? () => onClickSubmit(productTypeData) : undefined
              }
              title="General Information"
            >
              <ContentHolder>
                <ContentWrapper>
                  <TextInput
                    label="Name"
                    value={productTypeData?.contentVariation?.name || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onHandleChangeProductTypeData(
                        "contentVariation.name",
                        e.target.value
                      )
                    }
                  />
                </ContentWrapper>

                <ContentWrapper>
                  <TextInput
                    label="Slug (Code)"
                    value={productTypeData?.code || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onHandleChangeProductTypeData("code", e.target.value)
                    }
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <TextArea
                    label="Description"
                    value={productTypeData?.contentVariation?.description || ""}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      onHandleChangeProductTypeData(
                        "contentVariation.description",
                        e.target.value
                      )
                    }
                    placeholder={"Enter a description"}
                  />
                </ContentWrapper>
              </ContentHolder>
            </Section>
            <SectionMedia
              row={1}
              mediaSection={
                <>
                  <MediaInputWrapper>
                    {mapImageInputProductTypePage.map((item, index) => (
                      <ImageInput
                        {...item}
                        key={`${item.label}-${index + 10}`}
                        previousImageData={_.find(productTypeData.banners, {
                          deviceType: item.device,
                        })}
                        onClickChooseImage={(img) => {
                          onHandleChangeImages(
                            img,
                            item.device || null,
                            "banners",
                            item.usedFor
                          );
                        }}
                        data={{
                          imgUrl: _.get(
                            _.find(productTypeData.banners, {
                              deviceType: item.device,
                            }),
                            "originalUrl",
                            ""
                          ),
                          imgAlt: _.get(
                            _.find(productTypeData.banners, {
                              deviceType: item.device,
                            }),
                            "name",
                            ""
                          ),
                        }}
                      />
                    ))}
                  </MediaInputWrapper>
                </>
              }
            />
            <SeoSection
              onClickSave={
                onClickSubmit ? () => onClickSubmit(productTypeData) : undefined
              }
              data={{
                ...productTypeData.contentVariation,
                ogImage: productTypeData?.openGraphImage,
              }}
              onChangeSeoTitle={(e: string) =>
                onHandleChangeProductTypeData("contentVariation.seoTitle", e)
              }
              onChangeSeoDescription={(e: string) =>
                onHandleChangeProductTypeData(
                  "contentVariation.seoDescription",
                  e
                )
              }
              onChangeOgTitle={(e: string) =>
                onHandleChangeProductTypeData("contentVariation.ogTitle", e)
              }
              onChangeOgDescription={(e: string) =>
                onHandleChangeProductTypeData(
                  "contentVariation.ogDescription",
                  e
                )
              }
              onChangeOgImage={(img) =>
                onHandleChangeProductTypeData(
                  "openGraphImage",
                  onCastIUploadDataToEmbeddedImageData(img, null)
                )
              }
            />
          </ScrollWrapper>
        </ContentContainer>
        <SidePanel
          sidePanelAction={
            <SidePanelContainer>
              {productType ? (
                <Select
                  disabled={Boolean(
                    !(productType.availableLanguages.length > 1)
                  )}
                  label={
                    Boolean(!(productType.availableLanguages.length > 1))
                      ? "Language"
                      : "Switch Language"
                  }
                  data={productType.availableLanguages.map((item) => ({
                    label: formatIsoLanguage(item),
                    value: item,
                  }))}
                  value={selectedLanguage}
                  onChange={(e) => {}}
                />
              ) : (
                <Select
                  disabled
                  label={"Language"}
                  data={[{ value: "th", label: "Thai" }]}
                  value={selectedLanguage}
                  onChange={(e) => setSelectedLanguage(e || "th")}
                />
              )}
              {isShowAddLanguageButton && (
                <ButtonWrapper>
                  <Button
                    buttonStyle="outline-light"
                    leftIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
                  >
                    Add Language
                  </Button>
                </ButtonWrapper>
              )}
            </SidePanelContainer>
          }
        />
      </FlexContainer>
    </>
  ) : (
    <div style={{ margin: "auto" }}>
      <Loader color="gray" />
    </div>
  );
}
