import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button } from "../../../atoms/button/Button";
import { UploadFileField } from "../../../atoms/uploadFileField/UploadFileField";
import { UploadItem } from "../../../atoms/uploadItem/UploadItem";
import { fetchFolderById } from "../../../store/features/media";
import {
  cancelUploadFile,
  resetUpload,
  retryUploadFile,
  uploadFile,
  uploadSelector,
} from "../../../store/features/upload";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Modal } from "../Modal";
import {
  MediaUploadItemWrapper,
  UploadFileModalWrapper,
} from "./ModalUpload.style";

interface ModalUploadProps {
  parentFolderId?: string | null;
  isFullWidth?: boolean;
  loadData: () => void;
  isAttachment?: boolean;
  supportingType?: string;
}

export function ModalUpload(props: ModalUploadProps) {
  const {
    parentFolderId = null,
    isFullWidth,
    loadData,
    isAttachment = false,
    supportingType = "PNG or JPG",
  } = props;
  const dispatch = useAppDispatch();
  const { uploadCompleted, files } = useAppSelector(uploadSelector);

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadFileCount, setUploadFileCount] = useState<number>(0);

  function resetStore() {
    dispatch(resetUpload());
  }

  function resetState() {
    setIsModalOpened(false);
    setIsUploading(false);
    setUploadFileCount(0);
  }

  async function onFileUpload(filesList: FileList) {
    setIsUploading(true);
    setUploadFileCount(Array.from(filesList).length);
    Array.from(filesList).forEach(async (item) => {
      await dispatch(
        uploadFile({
          file: item,
          selectedFolder: `${parentFolderId}`,
          isAttachment,
        })
      );
    });
  }

  function onHandleUploadFailed(fileId: string) {
    dispatch(retryUploadFile({ fileId, isAttachment }));
  }

  function onHandleCancelUpload(fileId: string) {
    setUploadFileCount(uploadFileCount - 1);
    dispatch(cancelUploadFile(fileId));
  }

  return (
    <>
      <Modal
        onClose={() => {
          setIsModalOpened(false);
          loadData();
          dispatch(fetchFolderById(parentFolderId));
          resetStore();
          resetState();
        }}
        button={
          <Button
            onClick={() => setIsModalOpened(true)}
            leftIcon={<FontAwesomeIcon icon={["far", "cloud-upload"]} />}
            buttonStyle="primary"
            fullWidth={isFullWidth}
          >
            Upload
          </Button>
        }
        opened={Boolean(isModalOpened)}
        title="Upload or Browse Files"
        size={580}
        onClickPrimaryButton={() => {
          loadData();
          dispatch(fetchFolderById(parentFolderId));
          resetStore();
          resetState();
        }}
        isDisablePrimaryButton={
          !(
            files.length -
              files.filter((item) => item.state === "error").length ===
            uploadCompleted.length
          )
        }
        primaryButtonTitle={isUploading ? "Done" : undefined}
      >
        <UploadFileModalWrapper>
          <UploadFileField
            height={!isUploading ? "420px" : undefined}
            onFileUpload={(fileList) => onFileUpload(fileList)}
            supportingType={supportingType}
            multipleFile
            acceptType={isAttachment ? ".pdf" : "image/*"}
          />
          {Boolean(files.length) && <MediaUploadItemWrapper>
            {files &&
              files.map((item, index) => (
                <UploadItem
                  isAttachment={isAttachment}
                  key={`${item.name || ""}-${index + 100}`}
                  uploadState={item.state}
                  layout={"B"}
                  onClickUploadAgain={() => onHandleUploadFailed(item.fileId)}
                  onClickCancel={() => onHandleCancelUpload(item.fileId)}
                  uploadProgress={item.uploadProgress.uploadProgress}
                  uploadProgressFileSize={item.uploadProgress.loaded}
                  totalFileSize={item.uploadProgress.total}
                  imgRatio={1 / 1}
                  imgData={{
                    src: item.file ? URL.createObjectURL(item.file) : "",
                    alt: item.name || "",
                  }}
                />
              ))}
          </MediaUploadItemWrapper>}
        </UploadFileModalWrapper>
      </Modal>
    </>
  );
}
