import {
  createAction,
  createAsyncThunk as nativeCreateAsyncThunk,
} from "@reduxjs/toolkit";
import createAsyncThunk from "../../middleware/customCreateThunk";
import {
  createProductType,
  deleteProductTypeById,
  loadAllProductType,
  loadProductTypeById,
  updateProductTypeById,
} from "../../../services/api";
import {
  IProductType,
  ProductTypeUsedIn,
  SelectItemProps,
} from "../../../types";
import { formatSelectItems } from "../../../utils/dataService";

export const fetchProductTypes = createAsyncThunk({
  api: loadAllProductType,
  EVENT_NAME: "productTypes/loadAllProductType",
});

export const fetchProductTypeById = createAsyncThunk({
  api: loadProductTypeById,
  EVENT_NAME: "productTypes/loadById",
});

export const deletedProductTypeById = createAsyncThunk({
  api: deleteProductTypeById,
  EVENT_NAME: "productTypes/deleteById",
});

export const updatedProductTypeById = createAsyncThunk({
  api: updateProductTypeById,
  EVENT_NAME: "productTypes/updateById",
});

export const createdProductType = createAsyncThunk({
  api: createProductType,
  EVENT_NAME: "productTypes/created",
});

export const fetchProductTypeSelectItems = nativeCreateAsyncThunk(
  "productType/selectItem",
  async (
    payload: { languageCode: string; usedIn?: ProductTypeUsedIn },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await loadAllProductType({
        limit: "all",
        'used-in': payload.usedIn,
        lang: payload.languageCode
      });
      const data = response?.data;
      if (data.data) {
        let selectItems: SelectItemProps[] = [];
        const filterdProductTypes = formatSelectItems(
          data.data,
          "name",
          payload.languageCode
        );
        // eslint-disable-next-line array-callback-return
        filterdProductTypes.map((item) => {
          if (!selectItems.includes(item)) {
            selectItems.push(item);
          }
        });
        dispatch(setProductTypeSelectItems(selectItems));
      }
      return response.data;
      // eslint-disable-next-line
    } catch (error: any) {
      const status = error?.response?.status || 404;
      const data = error?.response?.data;
      return rejectWithValue({ status, data });
    }
  }
);

export const setProductTypeSelectItems = createAction<SelectItemProps[]>(
  "productType/setSelectItem"
);

export const setCurrentProductType = createAction<IProductType>(
  "productType/setCurrentProductType"
);
export const resetCurrentProductType = createAction(
  "productType/resetCurrentProductType"
);
export const resetProductType = createAction("productType/reset");
export const resetProductTypeDetailState = createAction(
  "productType/resetDetailState"
);
export const resetProductTypeListState = createAction(
  "productType/resetListState"
);
