import { createReducer } from "@reduxjs/toolkit";
import { IProductType, SelectItemProps, StatusState } from "../../../types";
import { fetchLocations, setLocationSelectItems } from "./actions";
import { getStateObject } from '../../../utils/stateManagement';
import { formatErrorObject } from '../../../utils/errorFormatter';

interface InitialStateProps {
  locations: IProductType[];
  locationSelectItems: SelectItemProps[];
  loadLocationState: StatusState;
}

const initialState: InitialStateProps = {
  locations: [],
  locationSelectItems: [],
  loadLocationState: {
    error: null,
    status: '',
  },
};

export const locationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchLocations.pending, (state) => {
      state.loadLocationState = getStateObject({ type: 'loading' });
    })
    .addCase(fetchLocations.fulfilled, (state, { payload }) => {
      state.locations = payload?.data;
      state.loadLocationState = getStateObject({ type: 'success' });
    })
    .addCase(fetchLocations.rejected, (state, { payload }) => {
      state.locations = [];
      state.loadLocationState = getStateObject({
        type: 'error',
        error: formatErrorObject(payload, 'Locations'),
      });
    })
    .addCase(setLocationSelectItems, (state, { payload }) => {
      state.locationSelectItems = payload;
      state.loadLocationState = getStateObject({ type: 'success' });
    });
});
