import {
  Container,
  Overlay,
  Text,
  CircleText,
  // LoadingWrapper,
  UploadedError,
  ErrorText,
  ErrorIconWrapper,
  RingProgressWrapper,
} from "./UploadItemA.style";
import { UploadItemProps } from "../UploadItem";
import { AspectRatio, Image, RingProgress } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../button/Button";

export function UploadItemA(props: UploadItemProps) {
  const {
    imgData,
    uploadState,
    uploadProgress = 0,
    imgRatio = 1 / 1,
    ringProgressProps,
    onClickUploadAgain = () => {},
    onClickCancel = () => {},
  } = props;
  return (
    <>
      <Container isError={Boolean(uploadState === "error")}>
        <AspectRatio ratio={imgRatio}>
          <Image {...imgData} />
        </AspectRatio>
        <Overlay />
        {ringProgressProps && uploadState !== "error" && (
          <RingProgressWrapper>
            <RingProgress
              {...ringProgressProps}
              label={<CircleText>{uploadProgress}%</CircleText>}
            />
          </RingProgressWrapper>
        )}
        {uploadState === "error" && (
          <UploadedError onClick={() => onClickUploadAgain()}>
            <ErrorText>Upload failed</ErrorText>
            <Button height="36px" buttonStyle="outline-light">
              <ErrorIconWrapper>
                <FontAwesomeIcon icon={["far", "arrow-rotate-left"]} />
              </ErrorIconWrapper>
            </Button>
          </UploadedError>
        )}
        <Text
          onClick={() => {
            if (uploadProgress < 100 || uploadState === "error") {
              onClickCancel();
            }
          }}
        >
          Cancel
        </Text>
      </Container>
    </>
  );
}
