import isoLanguages from "@cospired/i18n-iso-languages";

export function formatFullDateTime(date: Date) {
  const day = date.getDate();
  const month = date.toLocaleString("default", {
    month: "short",
  });
  const year = date.toLocaleString("default", {
    year: "numeric",
  });
  const hour = date.getHours();
  const minute = date.getMinutes();

  const result = `${day} ${month} ${year} at ${hour < 10 ? `0${hour}` : hour}:${
    minute < 10 ? `0${minute}` : minute
  }`;
  return result;
}

export function formatIsoLanguage(value: string) {
  return isoLanguages.getName(value, "en");
}

export function formatLanguageSelectItem(data: string[]) {
  if(data){
    return data.map((item) => {
      return { label: formatIsoLanguage(item) || "", value: item };
    });
  }
  
  return []
}

export function formatBytes(bytes: number, decimals:number = 2) {
  if (!+bytes) return "0 B";

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return [parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), sizes[i]];
}
