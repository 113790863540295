import styled from "@emotion/styled/macro";

export const ContentWrapper = styled.div``;

export const MediaListWrapper = styled.div`
  padding: 16px 24px;
`;

export const PaginateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
`;
export const DescriptionWrapper = styled.div`
  margin-bottom: 62px;
`;
export const ContentDividerWrapper = styled.div`
  margin-bottom: 38px;
`;
