import styled from "@emotion/styled/macro";

export const Container = styled.div`
  position: relative;
  background-color: var(--grey100);
  border-radius: var(--border-radius);
  
  :last-child {
    margin-bottom: var(--space-md);
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid var(--grey200);
  padding-top: 12px;
  padding-right: var(--space-xs);
  padding-bottom: var(--space-xs);
  padding-left: 12px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.p`
  font-weight: 700;
  margin-right: var(--space-sm);

  .fa-eye-slash {
    margin-right: var(--space-xs);
    color: var(--error500);
  }
`;

export const Description = styled.p`
  color: var(--grey600);
  font-size: 13px;
  font-weight: 400;
  margin-top: var(--space-xs);
`;

export const HeaderText = styled.div``;

export const HeaderButton = styled.div``;

export const Content = styled.div``;

export const AddAfterArea = styled.div`
  position: absolute;
  left: 0;
  bottom: -13px;
  width: 100%;
  height: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: var(--grey200);
  cursor: pointer;
  opacity: 0;
  transition: opacity 150ms ease;

  &:hover {
    opacity: 1;
  }

  &:before {
    background-color: #FFF;
    display: block;
    position: relative;
    top: -8px;
    margin-left: auto;
    content: '+ เพิ่ม Section ใหม่ที่นี่';
    color: var(--grey500);
    font-size: 13px;
    text-align: right;
    padding-left: 2px;
    width: fit-content;
  }
`;
