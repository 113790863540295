import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

export const PageHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 32px;
  background: white;
  width: 100%;
  border-bottom: 1px solid #EAECF0;
`

export const PageHeaderBackButton = styled(Link)`
  flex: 1 0 auto;
  margin-right: 16px;
`

export const PageHeaderTitle = styled.div`
  font-size: 24px;
  color: var(--grey900);
  font-weight: 600;
  flex: 1 1 100%;
`

export const PageHeaderActionContainer = styled.div<{ marginDirection?: 'left' | 'right'}>`
  flex: 1 0 auto;
  ${(props) => {
    if (props.marginDirection === 'right') {
      return css`
        margin-right: 24px;
      `
    }
    return css`
      margin-left: 24px;
    `
  }}
`