import {
  createAction,
  createAsyncThunk as nativeCreateAsyncThunk,
} from "@reduxjs/toolkit";
import createAsyncThunk from "../../middleware/customCreateThunk";
import {
  createProject,
  deleteProjectById,
  loadAllProject,
  loadProjectById,
  loadProjectUnitTypeAPI,
  updateProjectById,
  updateProjectVisibilityStatusAPI,
  updateProjectPublishStatusAPI
} from "../../../services/api";
import { IProject, SelectItemProps } from "../../../types";

export const fetchProjectList = createAsyncThunk({
  api: loadAllProject,
  EVENT_NAME: "project/loadProjectList",
});

export const fetchProjectById = createAsyncThunk({
  api: loadProjectById,
  EVENT_NAME: "project/loadProjectById",
});

export const updateProjectVisibilityStatus = createAsyncThunk({
  api: updateProjectVisibilityStatusAPI,
  EVENT_NAME: "project/updateVisibilityStatus",
});

export const updateProjectPublishStatus = createAsyncThunk({
  api: updateProjectPublishStatusAPI,
  EVENT_NAME: "project/updatePublishStatus",
});

export const updatedProjectById = createAsyncThunk({
  api: updateProjectById,
  EVENT_NAME: "project/updateProject",
});

export const createdProject = createAsyncThunk({
  api: createProject,
  EVENT_NAME: "project/createProject",
});

export const deleteProject = createAsyncThunk({
  api: deleteProjectById,
  EVENT_NAME: "project/deleteProject",
});

export const fetchProjectSelectItems = nativeCreateAsyncThunk(
  "project/selectItem",
  async (payload: { languageCode: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = await loadAllProject({ limit: "all", lang: payload.languageCode });
      const data = response?.data;
      if (data.data) {
        let selectItems: SelectItemProps[] = [];
        const filterd = data.data.map((item: any) => ({value: item.id, label: item.name}))
        // eslint-disable-next-line array-callback-return
        filterd.map((item:any) => {
          if (!selectItems.includes(item)) {
            selectItems.push(item);
          }
        });
        dispatch(setProjectSelectItems(selectItems));
      }
      return response.data;
      // eslint-disable-next-line
    } catch (error: any) {
      const status = error?.response?.status || 404;
      const data = error?.response?.data;
      return rejectWithValue({ status, data });
    }
  }
);

export const fetchProjectUnitType = createAsyncThunk({
  api: loadProjectUnitTypeAPI,
  EVENT_NAME: "project/loadUnitTypes",
});

export const setProjectSelectItems = createAction<SelectItemProps[]>(
  "project/setProjectSelecteItems"
);
export const setCurrentProject = createAction<IProject>(
  "project/setCurrentProject"
);
export const resetProjectDetail = createAction("project/resetDetail");
export const resetProjectList = createAction("project/resetList");
export const resetProject = createAction("project/reset");
