import { createStyles } from "@mantine/core";
import { MediaItemB } from "./mediaItemB/MediaItemB";
import { MediaItemA } from "./mediaItemA/MediaItemA";
import { MediaItemC } from "./mediaItemC/MediaItemC";

export const useStyles = createStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  figure: {
    width: "100%",
  },
}));

export type MediaItemData = {
  src: string;
  alt: string;
  key?:string;
};

type IconSize = {
  width: string;
  height: string;
};

type MediaItemLayout = "A" | "B" | "C"
export type SelecterShape = "radio" | "checkbox";

export interface MediaItemProps extends Omit<Partial<React.DOMAttributes<HTMLDivElement>>, ''> {
  data: MediaItemData;
  ratio?: number;
  maxWidth?: string;
  iconSize?: IconSize;
  isShowSelecter?: boolean;
  selecterShape?: SelecterShape;
  isSelected?: boolean;
  isEditButton?:boolean;
  isMenuItemActive?:boolean;
  layout?: MediaItemLayout;
  onClickMedia?:(e:React.MouseEvent<HTMLElement>) => void;
  onClickButton?:(key:string) => void;
  onEdit?:() => void;
  onMove?:() => void;
  onDelete?:() => void;
  isDisable?:boolean;
}

export function MediaItem(props: MediaItemProps) {
  const { layout = "A" } = props;
  return (
    <>
      {layout === "A" && <MediaItemA {...props} />}
      {layout === "B" && <MediaItemB {...props} />}
      {layout === "C" && <MediaItemC {...props} />}
    </>
  );
}
