import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  brandSelector,
  createdBrand,
  resetBrandDetailState,
  resetTempBrand,
} from "../../store/features/brand";
import {
  createPage,
  pageDataSelector,
  resetCreatePageState,
} from "../../store/features/page";
import { addToast } from "../../store/features/toast";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ManageBrand } from "../../templates/manageBrand/ManageBrand";
import { IBrandEditable } from "../../types";
import { mapConvertThemeDataTo6DigitHexstring } from "../../utils/mapData";

export function CreateBrand() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { createBrandState, newBrand, tempBrand } =
    useAppSelector(brandSelector);
  const { newPage, createPageState } = useAppSelector(pageDataSelector);
  // const [isShowConfirmCreatePageModal, setIsShowConfirmCreatePageModal] = useState<boolean>(false)
  const [isClickCreatePage, setIsClickCreatePage] = useState<boolean>(false);

  async function onHandleCreateBrand(data: IBrandEditable) {
    await dispatch(
      createdBrand({
        ...data,
        theme: mapConvertThemeDataTo6DigitHexstring(data.theme),
      })
    );
  }

  async function onCreatePage() {
    if (tempBrand?.name && tempBrand?.slugCode) {
      setIsClickCreatePage(true);
      await onHandleCreateBrand(tempBrand);
    }
  }

  useEffect(() => {
    const status = createBrandState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully created" }));
      if (!isClickCreatePage) {
        navigate(`/brand`);
      } else {
        if (newBrand) {
          dispatch(
            createPage({
              title: `${
                newBrand.name || ""
              } (${newBrand.languageCode.toUpperCase()})`,
              path: `/${newBrand.languageCode}/project/${
                newBrand.productType.code || ""
              }/${newBrand.slugCode}`,
              category: "brand",
              slug: `${newBrand.slugCode} - ${newBrand.languageCode}`,
              resourceId: newBrand.id,
              resourceUrl: `/brand/${newBrand.id}`,
              languageCode: newBrand.languageCode,
            })
          );
        }
      }

      return () => {
        dispatch(resetBrandDetailState());
      };
    }
    if (status === "error") {
      dispatch(addToast({ status, title: "Create failed" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBrandState.status, dispatch]);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [createPageState.status, createBrandState.status]);

  useEffect(() => {
    const status = createPageState.status;
    if (status === "success") {
      dispatch(resetTempBrand());
      if (isClickCreatePage) {
        navigate(`/page-builder/page/${newPage?.id}`);
      }

      return () => {
        dispatch(resetCreatePageState());
        dispatch(resetBrandDetailState());
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPageState.status]);

  return (
    <ManageBrand
      isCreatePage
      isShowPreviewButton
      pageTitle={"Create Brand"}
      onClickPreview={() => {
        onCreatePage();
      }}
      onClickCreate={(data) => onHandleCreateBrand(data)}
    />
  );
}
