import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../../index';

export const selectPages = (state: RootState) => state.page.pages;
export const selectPage = (state: RootState) => state.page.currentPage;
export const selectPageLoading = (state: RootState) => state.page.isLoading;
export const selectPageError = (state: RootState) => state.page.pageError;
export const selectPageData = (state: RootState) => state.page;

export const pagesSelector = createSelector(selectPages, (state) => state);
export const pageSelector = createSelector(selectPage, (state) => state);
export const pageLoadingSelector = createSelector(selectPageLoading, (state) => state);
export const pageErrorSelector = createSelector(selectPageError, (state) => state);
export const pageDataSelector = createSelector(selectPageData, (state) => state);
