/* eslint-disable react/jsx-props-no-spreading */
import { showNotification } from "@mantine/notifications";
import { NotificationProps } from "@mantine/core";
import { useEffect } from "react";
import CheckIcon from "../../assets/icon/bg-check-icon.svg";
import ErrorIcon from "../../assets/icon/bg-error-icon.svg";

export interface ToastProps extends NotificationProps {
  description?: string;
  status: string;
}

const toastVariants: {
  [status: string]: {
    icon: string;
    color: string;
    defaultTitle: string;
    defaultDescription: string;
  };
} = {
  success: {
    icon: CheckIcon,
    color: "green",
    defaultTitle: "Successfully updated",
    defaultDescription: "Your changes have been saved.",
  },
  error: {
    icon: ErrorIcon,
    color: "red",
    defaultTitle: "Save error",
    defaultDescription: "Please try again later.",
  },
};

/**
 * Documentation: {@link https://mantine.dev/core/notification/} {@link https://mantine.dev/others/notifications/}
 */
export const Toast = (props: ToastProps) => {
  const { title, description, status = "success", id } = props;
  useEffect(() => {
    showNotification({
      id,
      title: title || toastVariants[status].defaultTitle,
      message: description || toastVariants[status].defaultDescription,
      autoClose: 5000,
      icon: (
        <img
          src={toastVariants[status].icon}
          alt="check-icon"
          style={{ width: "100%" }}
        />
      ),
      styles: (theme) => ({
        root: {
          // backgroundColor: ,
          // borderColor: ,
          alignItems: 'flex-start',
          padding: 12,
          "&::before": { backgroundColor: "#fff" },
        },
        icon: {
          width: 40,
          marginRight: 16,
          height: 40,
        },
        title: {
          color: "var(--grey900)",
          fontSize: 16,
          marginBottom: 0,
          marginTop: 6,
          fontWeight: 600
        },
        description: { color: "var(--grey600)" , fontSize: 14, marginTop: 4 },
        closeButton: {
          color: "var(--grey500)",
        },
      }),
    });
  }, [id, description, status, title]);
  return <></>;
};
