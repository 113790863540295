import qs from "qs";
import { axiosAccountAPI } from "../../axios";
import { QueryParams } from "../../types";
import {
  IEditableUser,
  UpdateStatusParam,
  UpdateUserParam,
} from "../../types/user";

export const loadUserListAPI = (filter?: QueryParams) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosAccountAPI.get(`/accounts${query}`);
};

export const loadUserByIdAPI = (id: string) => {
  return axiosAccountAPI.get(`/accounts/${id}`);
};

export const createUserAPI = (data: IEditableUser) => {
  return axiosAccountAPI.post(`/accounts`, data);
};

export const updateUserAPI = ({ id, data }: UpdateUserParam) => {
  return axiosAccountAPI.put(`/accounts/${id}`, data);
};

export const userActivateByIdAPI = ({ id, data }: UpdateStatusParam) => {
  return axiosAccountAPI.post(`/accounts/${id}/activate`, data);
};

export const userSuspendByIdAPI = ({ id, data }: UpdateStatusParam) => {
  return axiosAccountAPI.post(`/accounts/${id}/suspend`, data);
};

export const loadRoleListAPI = () => {
  return axiosAccountAPI.get(`/roles`);
};

export const userChangePasswordAPI = ({
  id,
  data,
}: {
  id: string;
  data: { password: string };
}) => {
  return axiosAccountAPI.post(`/accounts/${id}/passwords`, data);
};
