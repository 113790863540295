import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { faCloud } from '@fortawesome/pro-solid-svg-icons/faCloud';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  ButtonContainer,
  CardContainer,
  CardMenu,
  // Container,
  AddSectionButtonWrapper,
  SubContent,
  SubContentTitle,
  ContentWrapper,
  LoadingContainer,
  TitleLoadingContainer,
  EmptyContainer, LoadingOverlay, OrderDropdownWrapper, OrderDropdownButton, SearchBox, SearchBoxContainer, FoundCount, SearchToolsContainer, SearchToolButton,
} from './ListSectionSidebar.style';
import {
  CurrentEditProps,
  setCurrentEdit, setCurrentEditType,
  setIsEnableSave, setScrollPosition, updateSection,
} from '../../../store/features/editor';
import { pageLoadingSelector, pageSelector, resetCurrentPage, selectPageError, setCurrentPageSections, setShouldSaveDraft } from '../../../store/features/page';
import {
  ChildComponentSpec,
  ComponentSpecProps,
  CurrentEditTypes, EditableComponentSettingTypes,
  PublishedSectionProps,
} from '../../../types';
import { LoadingPlaceholder } from '../../atoms/loadingPlaceholder/LoadingPlaceholder';
import { EmptyMessage } from '../messages/EmptyMessage';
import { BaseErrorMessage } from '../../molecules/baseErrorMessage/BaseErrorMessage';
import { Card } from '../../atoms/card/Card';
import { Accordion } from '../../atoms/accordion/Accordion';
import { BaseSidebar } from '../../molecules/baseSidebar/BaseSidebar';
import { Button } from '../../../atoms/button/Button';
import { TextInput } from '../../../atoms/textInput/TextInput';
import _ from 'lodash';

interface ListSectionSidebarProps {
  componentSpecList: Array<ComponentSpecProps>;
  iframeTargetOrigin: string;
  isShowAddSectionButton: boolean;
}

export const ListSectionSidebar = (props: ListSectionSidebarProps) => {
  const { componentSpecList, iframeTargetOrigin = '', isShowAddSectionButton = true } = props;
  const isLoading = useAppSelector(pageLoadingSelector);
  const pageError = useAppSelector(selectPageError);
  const { id: pageId, title, sections: originalSections } = useAppSelector(pageSelector);
  const { currentPage } = useAppSelector((state) => state.page);
  const { sections = [] } = currentPage;
  const { saveSectionState } = useAppSelector((state) => state.editor);
  // const [allComponents, setAllComponents] = useState<Array<ComponentSpecProps>>(allComponentsGeneral);
  const [sidebarHeaderHeight, setSidebarHeaderHeight] = useState<number>(118);
  const [arrowDownHoverIndex, setArrowDownHoverIndex] = useState<number>(-1);
  const [arrowUpHoverIndex, setArrowUpHoverIndex] = useState<number>(-1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchFoundCount, setSearchFoundCount] = useState<number>(0);
  const [searchFoundCurrentItem, setSearchFoundCurrentItem] = useState<number>(0);
  const [foundSections, setFoundSections] = useState<Array<Element> | undefined>(undefined);
  const [activeSectionIndex, setActiveSectionIndex] = useState<number>(0);

  const cardContainerRef = useRef<HTMLDivElement>(null);

  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function moveIndexAndGetArray(arr: Array<PublishedSectionProps>, fromIndex: number, toIndex: number) {
    const clonedArray = [...arr];
    const element = clonedArray[fromIndex];
    clonedArray.splice(fromIndex, 1);
    clonedArray.splice(toIndex, 0, element);
    return clonedArray;
  }

  function onOrderUp(fromIndex: number, toIndex = fromIndex - 1) {
    if (!sections) {
      return;
    }

    const newArray = moveIndexAndGetArray([...sections], fromIndex, fromIndex === 0 ? 0 : toIndex);
    dispatch(setCurrentPageSections([...newArray]));
    dispatch(setIsEnableSave(true));
    dispatch(setCurrentEditType('order'));
    dispatch(setShouldSaveDraft(true));
  }

  function onOrderDown(total: number, fromIndex: number, toIndex = fromIndex + 1) {
    if (!sections) {
      return;
    }

    const newArray = moveIndexAndGetArray([...sections], fromIndex, fromIndex + 1 === total ? total - 1 : toIndex);
    dispatch(setCurrentPageSections([...newArray]));
    dispatch(setIsEnableSave(true));
    dispatch(setCurrentEditType('order'));
    dispatch(setShouldSaveDraft(true));
  }

  const onClickBack = () => {
    dispatch(resetCurrentPage());
    navigate('/');
  };

  const onClickAddSection = (afterIndex?: number) => {
    const scroller = document.getElementById('sidebar-content');
    if (scroller) {
      dispatch(setScrollPosition(scroller.scrollTop));
    }

    navigate(`/page-builder/page/${pageId}?action=add${afterIndex !== undefined ? `&after=${afterIndex}` : ''}`);
  };

  const onClickMenu = (selectedItem: CurrentEditProps | null) => {
    if (saveSectionState.isSaving) {
      // Do not go back if the draft is being saved.
      return;
    }

    const scroller = document.getElementById('sidebar-content');
    if (scroller) {
      dispatch(setScrollPosition(scroller.scrollTop));
    }

    dispatch(setCurrentEdit(selectedItem as CurrentEditProps));
  };

  const onClickAddChildComponent = async (item: PublishedSectionProps, childDefaultSpec: ChildComponentSpec) => {
    const newComponentData = { ...item, childComponents: item.childComponents || [] };
    newComponentData.childComponents = [...newComponentData.childComponents, childDefaultSpec];
    await dispatch(updateSection({ sectionId: newComponentData.id, payload: newComponentData }));
    dispatch(setShouldSaveDraft(true));
    // dispatch(addNewChildComponent(childDefaultSpec))
  }

  function getTitle() {
    if (title) {
      return title;
    }

    return (
      <TitleLoadingContainer>
        <LoadingPlaceholder width={288} height={38} />
      </TitleLoadingContainer>
    );
  }

  const onMouseEnterCard = (index: undefined | null | number) => {
    if (typeof index === 'number') {
      setActiveSectionIndex(index);
    }
  };

  useEffect(() => {
    function getIsStartInEnglish(string: string) {
      const str = string.toLowerCase();
      return str.charCodeAt(0) >= 97 && str.charCodeAt(0) <= 122;
    }

    document.title = `แก้ไข${getIsStartInEnglish(title) ? ` ${title}` : title} - ${process.env.REACT_APP_SITE_TITLE || 'Thai PBS Page Builder'}`;
  }, [originalSections]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const timer = setTimeout(() => {
      const element = document.getElementById('sidebar-content');
      if (params.get('success') && element) {
        element.scrollTop = element.scrollHeight;
      }
      clearTimeout(timer);
    }, 300);
  }, [search]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const sidebarHeader = document.getElementById('sidebar-header');
    if (sidebarHeader) {
      setSidebarHeaderHeight(sidebarHeader.offsetHeight);
    }
  }, [isLoading]);

  function scrollToFoundSection(element: Element, index: number) {
    if (index === searchFoundCurrentItem - 1) {
      const parent = document.getElementById('sidebar-content') as HTMLDivElement;
      // Where is the parent on page
      const parentRect = parent.getBoundingClientRect();
      const parentHeight = parent.clientHeight - 200;

      // Where is the child
      const childRect = element.getBoundingClientRect();
      // Is the child viewable?
      const isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentHeight);

      if (!isViewable && parent) {
        const scrollTo = childRect.top - parentRect.top - 300;
        parent.scrollTop += scrollTo;
      }
    }
  }

  useEffect(() => {
    if (cardContainerRef.current) {
      const cards = Array.from(cardContainerRef.current.children);
      cards.forEach((element) => {
        // Reset all marked highlights
        const cardTitle = element.getElementsByClassName('card-title')?.item(0) as HTMLDivElement;
        if (cardTitle) {
          cardTitle.innerHTML = element.getAttribute('title') || '';
        }
      });

      if (searchValue === '') {
        return;
      }

      const regexp = new RegExp(searchValue, 'i');
      const found = cards.filter((c: Element) => c.getAttribute('title')?.match(regexp));
      setSearchFoundCount(found?.length || 0);
      setSearchFoundCurrentItem(found?.length ? 1 : 0);
      setFoundSections(found);

      if (found) {
        found.forEach((element, index) => {
          const cardTitleElement = element.getElementsByClassName('card-title')?.item(0) as HTMLDivElement;
          const cardTitle = cardTitleElement.innerHTML;

          const [originalText] = cardTitle.match(regexp) || [];
          cardTitleElement.innerHTML = cardTitle.replace(regexp, `<mark>${originalText}</mark>`);

          scrollToFoundSection(element, index);
        });
      }
    }
  }, [searchValue]);

  useEffect(() => {
    if (foundSections) {
      foundSections.forEach(scrollToFoundSection);
    }
  }, [searchFoundCurrentItem]);

  useEffect(() => {
    const iframe = document.getElementById('preview-iframe') as HTMLIFrameElement;

    if (iframe?.contentWindow) {
      iframe.contentWindow.postMessage(`${activeSectionIndex}`, iframeTargetOrigin);
    }
  }, [activeSectionIndex]);

  return (
    <BaseSidebar
      title={getTitle()}
      backText="Back to cms"
      onClickBack={onClickBack}
      bottomAreaMargin="small"
      searchElement={(
        <SearchBoxContainer isVisible={Boolean(title)}>
          <TextInput
            icon={<FontAwesomeIcon icon={faSearch} />}
            value={searchValue}
            placeholder="ค้นหา Section"
            onChange={(event: any) => setSearchValue(event.target.value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, ''))} />
          <SearchToolsContainer hide={searchValue.length === 0}>
            <FoundCount>
              {searchFoundCount > 0 ? searchFoundCurrentItem : 0}/{searchFoundCount}
            </FoundCount>
            <SearchToolButton
              title="เลื่อนไปยังผลลัพธ์ก่อนหน้า"
              disabled={searchFoundCount === 0}
              onClick={() => setSearchFoundCurrentItem(searchFoundCurrentItem - 1 <= 0 ? searchFoundCount : searchFoundCurrentItem - 1)}>
              <FontAwesomeIcon icon={faChevronUp} />
            </SearchToolButton>
            <SearchToolButton
              title="เลื่อนไปยังผลลัพธ์ถัดไป"
              disabled={searchFoundCount === 0}
              onClick={() => setSearchFoundCurrentItem(searchFoundCurrentItem + 1 > searchFoundCount ? 1 : searchFoundCurrentItem + 1)}>
              <FontAwesomeIcon icon={faChevronDown} />
            </SearchToolButton>
            <SearchToolButton title="เคลียร์คำค้นหา" onClick={() => setSearchValue('')}>
              <FontAwesomeIcon icon={faTimes} />
            </SearchToolButton>
          </SearchToolsContainer>
        </SearchBoxContainer>
      )}>
      <ContentWrapper>
        { Boolean(sections?.length && isLoading && !pageError) && (
          <LoadingOverlay headerHeight={sidebarHeaderHeight}>
            <FontAwesomeIcon icon={faSpinnerThird} spin />
            <FontAwesomeIcon icon={faCloud} />
            <span>กำลังซิงค์...</span>
          </LoadingOverlay>
        ) }
        { !sections?.length && isLoading && !pageError ? (
          <LoadingContainer>
            {[...Array(3)].map(() => (<LoadingPlaceholder key={`loading-${Math.random()}`} width={288} height={23} />))}
          </LoadingContainer>
        ) : (
          <>
            <CardContainer ref={cardContainerRef}>
              {
                !sections.length && pageError && (
                  <EmptyContainer>
                    <BaseErrorMessage
                      icon="exclamation-triangle"
                      title="ไม่สามารถโหลดข้อมูลได้ในขณะนี้ กรุณาลองรีเฟรชหน้าเว็บใหม่อีกครั้ง" />
                  </EmptyContainer>
                )
              }
              { !sections.length && !pageError && (
                <EmptyContainer>
                  <EmptyMessage
                    title="ยังไม่มี Section"
                    description="คลิกปุ่มด้านล่างเพื่อเพิ่ม Section ให้หน้านี้"
                    button={{
                      text: 'เพิ่ม Section',
                      onClick: () => onClickAddSection(),
                    }} />
                </EmptyContainer>
              ) }
              { sections.map((item, index) => {
                const total = sections.length;
                const { id: sectionId, componentType, name, settings, isHidden } = item;

                // ค้นหา Spec ของ Component
                const componentSpec = componentSpecList.find((component) => {
                  if (item.settings?.itemsPerRow) {
                    return component.componentType === item.componentType
                      && component.settings.itemsPerRow === item.settings.itemsPerRow;
                  }
                  if (item.settings?.itemsPerSlide) {
                    return component.componentType === item.componentType
                      && component.settings.itemsPerSlide === item.settings.itemsPerSlide;
                  }
                  return component.componentType === item.componentType;
                }) as ComponentSpecProps;

                const hasChild = componentSpec && 'childComponents' in componentSpec;

                let cardTitle = name;
                if (settings && settings.title) {
                  cardTitle = settings.title;
                }

                const getEditDataMenuItem = (component: PublishedSectionProps) => {
                  if (!componentSpec) {
                    return (<></>);
                  }

                  const array: Array<{ type: CurrentEditTypes, count: number }> = [];
                  if (componentSpec.dataSources && component.dataSources) array.push({ type: 'data', count: component.dataSources.length });
                  if (componentSpec.dataSources2 && component.dataSources2) array.push({ type: 'data2', count: component.dataSources2.length });

                  return array.map((arrayItem) => (
                    <CardMenu
                      key={`data-source-${Math.random()}`}
                      onClick={() => onClickMenu({
                        component: item,
                        componentSpec,
                        childComponent: null,
                        selectedChildIndex: null,
                        type: arrayItem.type,
                      })}>
                      เลือกคอนเทนต์ ({arrayItem.count})
                    </CardMenu>
                  ));
                };

                return (
                  <Card
                    id={`section-${index}`}
                    onClickAddSection={() => {
                      const scroller = document.getElementById('sidebar-content');
                      if (scroller) {
                        dispatch(setScrollPosition(scroller.scrollTop));
                      }
                      onClickAddSection(index);
                    }}
                    isHidden={isHidden}
                    onMouseEnter={() => onMouseEnterCard(item.index)}
                    onTouchEnd={() => onMouseEnterCard(item.index)}
                    key={`card-${sectionId}`}
                    button={(
                      <ButtonContainer>
                        <Button
                          title={index === 0 ? 'Section นี้อยู่ด้านบนสุดแล้ว' : 'คลิกเพื่อเลื่อนขึ้น'}
                          disabled={index === 0}
                          onMouseEnter={() => setArrowUpHoverIndex(index)}
                          onMouseLeave={() => setArrowUpHoverIndex(-1)}
                          onClick={() => onOrderUp(index)}>
                          <FontAwesomeIcon icon={['fas', 'arrow-up']} />
                        </Button>
                        <Button
                          title={index === 0 ? 'Section นี้อยู่ด้านล่างสุดแล้ว' : 'คลิกเพื่อเลื่อนลง'}
                          disabled={index + 1 === total}
                          onMouseEnter={() => setArrowDownHoverIndex(index)}
                          onMouseLeave={() => setArrowDownHoverIndex(-1)}
                          onClick={() => onOrderDown(total, index)}>
                          <FontAwesomeIcon icon={['fas', 'arrow-down']} />
                        </Button>
                      </ButtonContainer>
                    )}
                    title={cardTitle}
                    description={componentSpec ? `${componentSpec.name}` : ''}>
                    <>
                      <div style={{ display: 'block' }}>
                        {arrowUpHoverIndex === index ? (
                          <OrderDropdownWrapper
                            onMouseEnter={() => setArrowUpHoverIndex(index)}
                            onMouseLeave={() => setArrowUpHoverIndex(-1)}>
                            <OrderDropdownButton onClick={() => onOrderUp(index)}>
                              เลื่อนขึ้น
                              <svg width={19} viewBox="0 0 450.47 450.47">
                                <path d="M237.298,99.533c-0.301-0.34-0.562-0.677-0.916-0.999c-3.937-3.535-4.043-2.491,0.266-6.463
                                    c3.192-2.929,7.063-5.222,10.574-7.755c9.286-6.711,15.398-15.699,19.529-26.356C276.02,34.033,248.707,17.503,235,3.344
                                    c-9.904-10.247-25.496,5.382-15.604,15.604c7.643,7.912,17.489,14.328,24.14,23.123c7.453,9.848-3.901,20.712-11.68,26.194
                                    c-12.026,8.473-22.423,19.727-20.02,31.794c-53.971,5.042-103.87,34.623-103.87,86.767V333.2c0,64.664,52.603,117.27,117.27,117.27
                                    c64.663,0,117.27-52.605,117.27-117.27V186.817C342.51,129.888,289.543,102.317,237.298,99.533z M130.044,186.817
                                    c0-38.707,42.017-61.117,85.535-64.841v135.005c-39.697-1.998-71.928-11.042-85.535-15.457V186.817z M320.433,333.194
                                    c0,52.5-42.705,95.199-95.192,95.199c-52.488,0-95.196-42.699-95.196-95.199V264.73c19.713,5.958,56.817,14.995,100.676,14.995
                                    c28.088,0,58.93-3.759,89.713-14.352V333.194z M320.433,241.896c-27.916,10.675-56.424,14.849-82.78,15.415v-135.66
                                    c42.569,2.553,82.78,22.969,82.78,65.175V241.896z M206.072,133.429v111.973c-17.153,3.027-67.583-11.094-67.583-11.094
                                    C131.049,155.812,160.429,142.005,206.072,133.429z" />
                              </svg>
                            </OrderDropdownButton>
                            <OrderDropdownButton onClick={() => onOrderUp(index, 0)}>
                              เลื่อนไปด้านบนสุด
                            </OrderDropdownButton>
                          </OrderDropdownWrapper>
                        ) : (<></>)}
                        {arrowDownHoverIndex === index ? (
                          <OrderDropdownWrapper
                            onMouseEnter={() => setArrowDownHoverIndex(index)}
                            onMouseLeave={() => setArrowDownHoverIndex(-1)}>
                            <OrderDropdownButton onClick={() => onOrderDown(total, index)}>
                              เลื่อนลง
                              <svg width={19} viewBox="0 0 450.47 450.47">
                                <path d="M237.298,99.533c-0.301-0.34-0.562-0.677-0.916-0.999c-3.937-3.535-4.043-2.491,0.266-6.463
                                    c3.192-2.929,7.063-5.222,10.574-7.755c9.286-6.711,15.398-15.699,19.529-26.356C276.02,34.033,248.707,17.503,235,3.344
                                    c-9.904-10.247-25.496,5.382-15.604,15.604c7.643,7.912,17.489,14.328,24.14,23.123c7.453,9.848-3.901,20.712-11.68,26.194
                                    c-12.026,8.473-22.423,19.727-20.02,31.794c-53.971,5.042-103.87,34.623-103.87,86.767V333.2c0,64.664,52.603,117.27,117.27,117.27
                                    c64.663,0,117.27-52.605,117.27-117.27V186.817C342.51,129.888,289.543,102.317,237.298,99.533z M130.044,186.817
                                    c0-38.707,42.017-61.117,85.535-64.841v135.005c-39.697-1.998-71.928-11.042-85.535-15.457V186.817z M320.433,333.194
                                    c0,52.5-42.705,95.199-95.192,95.199c-52.488,0-95.196-42.699-95.196-95.199V264.73c19.713,5.958,56.817,14.995,100.676,14.995
                                    c28.088,0,58.93-3.759,89.713-14.352V333.194z M320.433,241.896c-27.916,10.675-56.424,14.849-82.78,15.415v-135.66
                                    c42.569,2.553,82.78,22.969,82.78,65.175V241.896z M206.072,133.429v111.973c-17.153,3.027-67.583-11.094-67.583-11.094
                                    C131.049,155.812,160.429,142.005,206.072,133.429z" />
                              </svg>
                            </OrderDropdownButton>
                            <OrderDropdownButton onClick={() => onOrderDown(total, index, total - 1)}>
                              เลื่อนไปด้านล่างสุด
                            </OrderDropdownButton>
                          </OrderDropdownWrapper>
                        ) : (<></>)}
                      </div>
                      <CardMenu onClick={() => onClickMenu({
                        component: item,
                        componentSpec,
                        childComponent: null,
                        selectedChildIndex: null,
                        type: 'appearance',
                      })}>
                        ตั้งค่าการแสดงผล
                      </CardMenu>
                      { getEditDataMenuItem(item) }
                      { hasChild && (
                        <SubContent>
                          <SubContentTitle>
                            คอนเทนต์ย่อย:
                          </SubContentTitle>
                          {
                            item.childComponents && item.childComponents.map((child, childIndex) => {
                              const { dataSources: ds, name } = child as PublishedSectionProps;
                              let accordionTitle = child.settings?.title || child.settings?.badgeText || name || '';

                              let childSpec: ChildComponentSpec | undefined;
                              if (componentSpec) {
                                childSpec = componentSpec.childComponentDefaultSpec;
                              }

                              // const editableCS = ['backgroundColor', 'isShowTitle', 'isShowDescription', 'isShowSubtitle',
                              //   'isShowCategory', 'isShowDate', 'isShowRanking', 'limitItem'];

                              // let filteredComponentSettings = child?.settings;

                              // if (filteredComponentSettings && child.settings) {
                              //   filteredComponentSettings = Object.keys(child.settings)
                              //     .filter((key) => editableCS.includes(key))
                              //     .reduce((obj: {[key: string]: string | boolean | number | null | undefined}, key: string) => {
                              //       if (child.settings) {
                              //         obj[key] = child.settings[key as EditableComponentSettingTypes];
                              //       }
                              //       return obj;
                              //     }, {});
                              // }

                              return (
                                <Accordion title={accordionTitle} key={`accordion-${accordionTitle}-${accordionTitle}-${Math.random()}`}>
                                  { (!_.isEmpty(childSpec?.settingFields)) ? (
                                    <CardMenu
                                      smaller
                                      onClick={() => onClickMenu({
                                        component: item,
                                        componentSpec: childSpec,
                                        childComponent: child,
                                        selectedChildIndex: childIndex,
                                        type: 'appearance',
                                      })}>
                                      ตั้งค่าการแสดงผล
                                    </CardMenu>
                                  ) : (<></>)}
                                  {
                                    (!_.isEmpty(childSpec?.dataSources)) ? (
                                      <CardMenu
                                        smaller
                                        onClick={() => onClickMenu({
                                          component: item,
                                          componentSpec: childSpec,
                                          childComponent: child,
                                          selectedChildIndex: childIndex,
                                          type: 'data',
                                        })}>
                                        เลือกคอนเทนต์ {ds && `(${ds.length})`}
                                      </CardMenu>
                                    ) : (<></>)
                                  }
                                </Accordion>
                              );
                            })
                          }
                          {
                            Boolean(hasChild && componentSpec.childComponentDefaultSpec) && (
                              <Button
                                fullWidth
                                buttonStyle="text-primary"
                                fontSize="sm"
                                onClick={() => onClickAddChildComponent(item, componentSpec.childComponentDefaultSpec as ChildComponentSpec)}>
                                + เพิ่มคอนเทนต์ย่อย
                              </Button>
                            )
                          }
                        </SubContent>
                      ) }
                    </>
                  </Card>
                );
              }) }
            </CardContainer>
            {
              isShowAddSectionButton && (
                <AddSectionButtonWrapper>
                  <Button
                    onClick={() => onClickAddSection()}
                    fullWidth={true}
                    buttonStyle="secondary">
                    <><FontAwesomeIcon icon={['fas', 'plus']} /> เพิ่ม Section</>
                  </Button>
                </AddSectionButtonWrapper>
              )
            }
          </>
        )}
      </ContentWrapper>
    </BaseSidebar>
  );
};
