import styled from "@emotion/styled";

export const FooterContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  padding: 24px;
  background-color: var(--white);
  justify-content: flex-end;
  gap: 12px;

  border-top: 1px solid var(--grey200);
`;
