import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

export const SelectIconWrapper = styled.div<{ isActive?: boolean }>`
  width: 16px;
  height: 16px;

  > svg {
    font-size: 10px;
  }

  background-color: var(--white);
  border: 1px solid var(--grey300);
  color: var(--white);
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.isActive &&
    css`
      background-color: var(--primary700);
      border: 1px solid var(--primary700);
      color: var(--white);
    `}
`;

export const Container = styled.div<{ isActive?: boolean }>`
  display: flex;
  border: 1px solid var(--grey200);
  border-radius: 12px;
  padding: 16px;
  gap: 4px;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  &:hover {
    border: 1px solid var(--primary300);
  }

  &:hover ${SelectIconWrapper} {
    background-color: var(--primary100);
    border: 1px solid var(--primary600);
    color: var(--primary100);
  }

  ${(props) =>
    props.isActive &&
    css`
      border: 1px solid var(--primary600);
      background-color: var(--primary50);

      &:hover {
        border: 1px solid var(--primary600);
        background-color: var(--primary50);
      }

      &:hover ${SelectIconWrapper} {
        background-color: var(--primary700);
        border: 1px solid var(--primary700);
        color: var(--white);
      }
    `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
`;

export const FileIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  background-color: var(--grey100);
  border: 1px solid var(--grey50);
  border-radius: 16px;
  color: var(--grey600);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--grey700);

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;

  padding-right: 5%;
`;
