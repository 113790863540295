import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { addToast } from "../../store/features/toast";
import { createUser, userSelector } from "../../store/features/user";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ManageUserManagement } from "../../templates/manageUserManagement/ManageUserManagement";
import { IEditableUser } from "../../types/user";

export function CreateUser() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { createUserState } = useAppSelector(userSelector);

  function onHandleCreateUser(data: IEditableUser) {
    dispatch(createUser(data));
  }

  useEffect(() => {
    const status = createUserState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully created" }));
      navigate(`/user-management`);
    }
    if (status === "error") {
      dispatch(addToast({ status, title: "Create failed" }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUserState.status]);

  return (
    <ManageUserManagement
      pageTitle={"Create User"}
      submitButtonTitle="Create"
      onClickSubmit={(data) => onHandleCreateUser(data)}
    />
  );
}
