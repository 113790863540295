import { createReducer } from '@reduxjs/toolkit';
import { ErrorTypes, PublishedSectionProps, StatusState } from '../../../types';
import { PageDataProps } from '../../../types/page';
import { formatErrorObject } from '../../../utils/errorFormatter';
import { getStateObject } from '../../../utils/stateManagement';
import {
  createPage,
  deletePage,
  fetchCurrentPage, fetchLatestDraftRevision, fetchLatestPublishedRevision,
  fetchPages, publishRevision,
  resetCreatePageState,
  resetCurrentPage,
  resetPageError,
  setCurrentPageSections, setIsDraftSaved, setShouldSaveDraft, updateDraftRevision,
  updatePage,
} from './actions';

interface InitialStateProps {
  pages: Array<PageDataProps>;
  currentPage: PageDataProps;
  newPage: PageDataProps;
  createPageState: StatusState;
  deletePageState: StatusState;
  isLoading: boolean;
  isPageSaving: boolean;
  isPageSaved: boolean;
  saveDraftState: {
    isDraftSaving: boolean;
    isDraftSaved: boolean;
    draftSaveError: ErrorTypes | null;
  };
  publishState: {
    isPublishing: boolean;
    isPublished: boolean;
    publishError: ErrorTypes | null;
  };
  pageError: ErrorTypes | null;
  shouldSaveDraft: boolean;
}

const getDefaultCurrentPage = (): PageDataProps => ({
  id: '',
  title: '',
  path: '',
  previewUri: '',
  category: '',
  sectionIds: [],
  createdAt: '',
  updatedAt: '',
  sections: [],
  originalSections: [],
  hasDraft: false,
  hasPublished: false,
  latestPublishedRevisionId: '',
  layoutName: 'standard',
  order: 0,
  resourceId: '',
  slug: ''
});

const initialState: InitialStateProps = {
  pages: [],
  currentPage: getDefaultCurrentPage(),
  newPage: getDefaultCurrentPage(),
  createPageState: getStateObject({ type: 'idle' }),
  deletePageState: getStateObject({ type: 'idle' }),
  isLoading: true,
  isPageSaving: false,
  isPageSaved: false,
  pageError: null,
  saveDraftState: {
    isDraftSaving: false,
    isDraftSaved: false,
    draftSaveError: null,
  },
  publishState: {
    isPublishing: false,
    isPublished: false,
    publishError: null,
  },
  shouldSaveDraft: false,
};

export const pageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchPages.pending, (state) => {
      state.isLoading = true;
      state.pageError = null;
    })
    .addCase(fetchPages.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.pages = payload.data;
      state.pageError = null;
    })
    .addCase(fetchPages.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.pages = [];
      state.pageError = payload as ErrorTypes;
    })

    .addCase(createPage.pending, (state) => {
      state.pageError = null;
      state.createPageState = getStateObject({ type: "pending" });
    })
    .addCase(createPage.fulfilled, (state, { payload }) => {
      state.pageError = null;
      state.newPage = {
        ...payload,
        sections: state.newPage?.sections || [],
        originalSections: payload.sections,
      };
      state.createPageState = getStateObject({ type: "success" });
    })
    .addCase(createPage.rejected, (state, { payload }) => {
      state.pageError = payload as ErrorTypes;
      state.newPage = getDefaultCurrentPage();
      state.createPageState = getStateObject({ type: "error",  error: formatErrorObject(payload, "Create Page Error"), });
    })

    .addCase(deletePage.pending, (state) => {
      state.deletePageState = getStateObject({ type: "pending" });
    })
    .addCase(deletePage.fulfilled, (state, { payload }) => {
      state.deletePageState = getStateObject({ type: "success" });
    })
    .addCase(deletePage.rejected, (state, { payload }) => {
      state.deletePageState = getStateObject({ type: "error",  error: formatErrorObject(payload, "Delete Page Error"), });
    })

    .addCase(fetchCurrentPage.pending, (state) => {
      state.isLoading = true;
      state.pageError = null;
    })
    .addCase(fetchCurrentPage.fulfilled, (state, { payload }) => {
      // state.isLoading = false will be set at fetchLatestPublishedRevision.fulfilled
      state.currentPage = {
        ...payload,
        sections: state.currentPage?.sections || [],
        originalSections: payload.sections,
      };
      state.pageError = null;
    })
    .addCase(fetchCurrentPage.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.currentPage = getDefaultCurrentPage();
      state.pageError = payload as ErrorTypes;
    })

    .addCase(fetchLatestPublishedRevision.pending, (state) => {
      state.isLoading = true;
      state.pageError = null;
    })
    .addCase(fetchLatestPublishedRevision.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.currentPage.sections = payload.sections;
      state.currentPage.sectionIds = payload.sectionIds;
      state.pageError = null;
    })
    .addCase(fetchLatestPublishedRevision.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.currentPage = getDefaultCurrentPage();
      state.pageError = payload as ErrorTypes;
    })

    .addCase(fetchLatestDraftRevision.pending, (state) => {
      state.isLoading = true;
      state.pageError = null;
    })
    .addCase(fetchLatestDraftRevision.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      let index = -1;
      state.currentPage.sections = payload.sections.map((s: PublishedSectionProps) => {
        if (!s.isHidden) {
          index += 1;
        }

        return {
          ...s,
          index: s.isHidden ? null : index,
        };
      });
      state.currentPage.sectionIds = payload.sectionIds;
      state.pageError = null;
    })
    .addCase(fetchLatestDraftRevision.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.currentPage = getDefaultCurrentPage();
      state.pageError = payload as ErrorTypes;
    })

    .addCase(updateDraftRevision.pending, (state) => {
      state.saveDraftState.isDraftSaving = true;
      state.saveDraftState.isDraftSaved = false;
      state.saveDraftState.draftSaveError = null;
    })
    .addCase(updateDraftRevision.fulfilled, (state) => {
      state.saveDraftState.isDraftSaving = false;
      state.saveDraftState.isDraftSaved = true;
      state.saveDraftState.draftSaveError = null;
    })
    .addCase(updateDraftRevision.rejected, (state, { payload }) => {
      state.saveDraftState.isDraftSaving = false;
      state.saveDraftState.isDraftSaved = false;
      state.saveDraftState.draftSaveError = payload as ErrorTypes;
    })

    .addCase(updatePage.pending, (state) => {
      state.isPageSaved = false;
      state.isPageSaving = true;
      state.pageError = null;
    })
    .addCase(updatePage.fulfilled, (state, { payload }) => {
      state.isPageSaving = false;
      state.isPageSaved = true;
      state.pages = payload.data;
      state.pageError = null;

      const timer = setTimeout(() => {
        state.isPageSaved = false;
        clearTimeout(timer);
      }, 2000);
    })
    .addCase(updatePage.rejected, (state, { payload }) => {
      state.isPageSaving = false;
      state.isPageSaved = false;
      state.pageError = payload as ErrorTypes;
    })

    .addCase(publishRevision.pending, (state) => {
      state.publishState.isPublishing = true;
      state.publishState.isPublished = false;
      state.publishState.publishError = null;
    })
    .addCase(publishRevision.fulfilled, (state) => {
      state.publishState.isPublishing = false;
      state.publishState.isPublished = true;
      state.publishState.publishError = null;
    })
    .addCase(publishRevision.rejected, (state, { payload }) => {
      state.publishState.isPublishing = true;
      state.publishState.isPublished = false;
      state.publishState.publishError = payload as ErrorTypes;
    })

    .addCase(setShouldSaveDraft, (state, { payload }) => {
      state.shouldSaveDraft = payload;
    })
    .addCase(setIsDraftSaved, (state, { payload }) => {
      state.saveDraftState.isDraftSaved = payload;
    })
    .addCase(setCurrentPageSections, (state, { payload }) => {
      state.currentPage.sections = payload;
    })
    .addCase(resetPageError, (state) => {
      state.pageError = null;
    })
    .addCase(resetCreatePageState, (state) => {
      state.newPage = getDefaultCurrentPage();
      state.createPageState = getStateObject({type:'idle'})
    })
    .addCase(resetCurrentPage, (state) => {
      state.currentPage = getDefaultCurrentPage();
      state.isLoading = true;
      state.isPageSaving = false;
      state.isPageSaved = false;
      state.pageError = null;
      state.saveDraftState = {
        isDraftSaving: false,
        isDraftSaved: false,
        draftSaveError: null,
      };
      state.publishState = {
        isPublishing: false,
        isPublished: false,
        publishError: null,
      };
      state.shouldSaveDraft = false;
    });
});

export default pageReducer;