import qs from 'qs';
import { axiosAccountAPI, axiosAuthAPI, axiosImageAPI, axiosPageAPI } from '../../../axios';
import {
  AccountId,
  AccountProps,
  PageDataProps,
  PublishedSectionProps,
  UserFilterProps,
  AuthTokenPayloadProps,
  LoginPayloadProps, BaseUserProps, SectionProps,
} from '../../../types';

export const getPages = () => axiosPageAPI.get('/pages');
export const getPage = (pageIdOrPath: string) => axiosPageAPI.get(`/pages/${encodeURI(pageIdOrPath)}`);
export const updatePage = ({ pageIdOrPath, payload}:{pageIdOrPath:string, payload: { title: string, path?:string, sectionIds?: Array<string | number>}}) => axiosPageAPI.put(`/pages/${pageIdOrPath}`, payload);
export const createPageAPI = (payload: { title:string, path:string, category:string, languageCode?:string }) => axiosPageAPI.post("/pages", payload);
export const deletePageAPI = (pageIdOrPath: string) => axiosPageAPI.delete(`/pages/${encodeURI(pageIdOrPath)}`);

export const getSectionById = (sectionId: string) => axiosPageAPI.get(`/sections/${sectionId}`);
export const createSection = (params: {pageId: string, payload: SectionProps}) => axiosPageAPI.post(`/pages/${params.pageId}/latest-draft-revision/sections`, params.payload);

type UpdateSectionParamProps = {
  sectionId: string;
  payload: PublishedSectionProps;
}

export const updateSection = (params: UpdateSectionParamProps) => axiosPageAPI.put(`/sections/${params.sectionId}`, params.payload);
export const deleteSection = (sectionId: string) => axiosPageAPI.delete(`/sections/${sectionId}`);

export const getDataSources = () => axiosPageAPI.get('data-sources');

export const uploadImage = (file: File, folderKey = 'general-images') => {
  const formData = new FormData();
  formData.append('file', file);

  return axiosImageAPI.post(`/assets/${folderKey}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getDataById = (contentTypeName: string, contentId: string | number) => (
  axiosPageAPI.get(`/content-types/${contentTypeName}/contents/${contentId}`)
);

export const getParametersOptionsByContentType = (sourceType: string, parameterName: string) => (
  axiosPageAPI.get(`/data-sources/${sourceType}/parameters/${parameterName}/options`)
);

export const getParametersOptionsByContentTypeById = (sourceType: string, parameterName: string, id: string | number) => (
  axiosPageAPI.get(`/data-sources/${sourceType}/parameters/${parameterName}/options/${id}`)
);

export const getDataSuggestionsByContentTypeId = (sourceType: string, query: string) => {
  const q = qs.stringify({ q: query }, {
    addQueryPrefix: true,
    skipNulls: true,
  });

  return axiosPageAPI.get(`/content-types/${sourceType}/contents${q}`);
};

export const getDataSuggestionsByContentTypeAuto = (sourceType: string, parameterName: string, query: string) => {
  const q = qs.stringify({ q: query }, {
    addQueryPrefix: true,
    skipNulls: true,
  });

  return axiosPageAPI.get(`/data-sources/${sourceType}/parameters/${parameterName}/options${q}`);
};

export const getLatestPublishedRevision = (pageId: string) => axiosPageAPI.get(`/pages/${pageId}/latest-published-revision`);
export const getLatestDraftRevision = (pageId: string) => axiosPageAPI.get(`/pages/${pageId}/latest-draft-revision`);
export const createNewDraft = (pageId: string) => axiosPageAPI.post(`/pages/${pageId}/latest-draft-revision`);

type UpdateDraftParamProps = {
  pageId: string;
  payload: PageDataProps;
}

export const updateDraft = (params: UpdateDraftParamProps) => {
  const { pageId, payload } = params;
  return axiosPageAPI.put(`/pages/${pageId}/latest-draft-revision`, payload);
};

export const deleteDraft = (pageId: string) => axiosPageAPI.delete(`/pages/${pageId}/latest-draft-revision`);
export const publishDraft = (pageId: string) => axiosPageAPI.post(`/pages/${pageId}/latest-draft-revision/actions/publish`);

export const performLogin = (payload: LoginPayloadProps) => axiosAuthAPI.post('/login', payload);
export const performLogout = () => axiosAuthAPI.post('/logout');
export const loadCurrentUser = () => axiosAccountAPI.get('/context');

export const validateAuthTokenPermission = (payload: AuthTokenPayloadProps) => {
  const { teamName, authToken } = payload;
  return axiosAccountAPI.post('/authorize', {}, {
    headers: {
      Authorization: `${teamName} ${authToken}`,
    },
  });
};

export const loadUsers = (filter: UserFilterProps) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  if (query) {
    return axiosAccountAPI.get(`/accounts${query}`);
  }
  return axiosAccountAPI.get('/accounts');
};

export const addUser = (newUser: BaseUserProps) => axiosAccountAPI.post('/accounts', newUser);
export const loadUserById = (accountId: AccountId) => axiosAccountAPI.get(`/accounts/${accountId}`);
export const suspendUser = (accountId: AccountId) => axiosAccountAPI.post(`/accounts/${accountId}/suspend`);
export const activateUser = (accountId: AccountId) => axiosAccountAPI.post(`/accounts/${accountId}/activate`);

export const updateUserByAccountId = (accountId: AccountId, newUser: AccountProps) => (
  axiosAccountAPI.put(`/accounts/${accountId}`, newUser)
);

export const updateUserPassword = (accountId: AccountId, password: string) => (
  axiosAccountAPI.post(`/accounts/${accountId}/passwords`, { password })
);
