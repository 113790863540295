/* eslint-disable react/jsx-props-no-spreading, no-nested-ternary */
import { InputHTMLAttributes, FocusEvent, useState } from "react";
import { TextInput as CoreTextInput, createStyles } from "@mantine/core";
import { TextInputProps } from "@mantine/core/lib/TextInput/TextInput";
import { InfoText } from "./TextInput.style";

const useStyles = createStyles(
  (
    theme,
    {
      floating,
      isSearchInput,
      isHasIcon,
      error,
    }: {
      floating: boolean;
      isSearchInput: boolean | undefined;
      isHasIcon?: boolean;
      error?: React.ReactNode;
    }
  ) => {
    return {
      root: {
        width: "100%",
      },
      label: {
        fontSize: theme.fontSizes.lg,
        marginBottom: theme.spacing.xs,
        color: "var(--grey700)",
      },
      required: {
        color: "var(--error500)",
      },
      input: {
        minHeight: 44,
        height: 44,
        paddingTop: 8,
        paddingBottom: 8,
        fontSize: theme.fontSizes.lg,
        width: "100%",
        border: error ? "1px solid var(--error500)" : "1px solid #D0D5DD",
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
        borderRadius: 8,
        paddingLeft: isHasIcon ? "44px !important" : undefined,
        // @ts-ignore
        color: theme.colors.grey[10],
        transition: error ? "border-color 100ms ease 0s" : undefined,

        ":disabled": {
          color: "var(--grey500)",
          backgroundColor: "var(--grey50)",
          cursor: "text",
          opacity: "unset",
        },

        ":focus": {
          border: error ? "1px solid var(--error300)" : "1px solid var(--primary300)",
          boxShadow: error
            ? "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--error100)"
            : "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--primary100)",
        },
      },
      icon: {
        fontSize: 18,
        width: 48,
        fontWeight: 700,
        color: "var(--grey500)",
      },

      error: {
        fontSize: 14,
        fontWeight: 400,
        color: "var(--error500)",
      },
    };
  }
);

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "size">,
    TextInputProps {
  isSearchInput?: boolean;
  isHasIcon?: boolean;
  hintText?: string;
  inputWidth?:string;
}

/**
 * Documentation: {@link https://mantine.dev/core/input/}
 */
export function TextInput(props: InputProps) {
  const {
    isSearchInput,
    hintText,
    label,
    placeholder,
    onFocus,
    onBlur,
    isHasIcon,
    inputWidth="100%",
    error,
    ...rest
  } = props;
  const [focused, setFocused] = useState(false);
  const { classes } = useStyles({
    floating: focused,
    isSearchInput,
    isHasIcon,
    error,
  });

  return (
    <div style={{ width: inputWidth }}>
      <CoreTextInput
        {...rest}
        label={label}
        placeholder={placeholder}
        classNames={classes}
        error={error}
        onFocus={(event: FocusEvent<HTMLInputElement>) => {
          setFocused(true);
          if (onFocus) {
            onFocus(event);
          }
        }}
        onBlur={(event: FocusEvent<HTMLInputElement>) => {
          setFocused(false);
          if (onBlur) {
            onBlur(event);
          }
        }}
      />
      {hintText && <InfoText>{hintText}</InfoText>}
    </div>
  );
}
