import styled from "@emotion/styled/macro";

export const ModalWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const NextButton = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 5%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  z-index: 1;
`;

export const PrevButton = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  z-index: 1;
`;
