import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  SelectChoiceContainer,
  SelectChoiceSubtitle,
  SelectChoiceTextContianer,
  SelectChoiceTitle,
  SelectContainer,
  SelectIcon,
  IconContainer,
} from "./SelectChoice.style";

interface SelectChoiceProps {
  mediaItem?: React.ReactNode;
  title?: string;
  subtitle?: string;
  checked?: boolean;
  icon: IconProp;
  iconColor?: string;
  iconSize?: string;
  iconContainerBg?: string;
  iconContainerWidth?: string;
  height?: string;
  onClick?: () => void;
}

export function SelectChoice(props: SelectChoiceProps) {
  const {
    title = "",
    subtitle = "",
    checked = false,
    icon,
    iconSize,
    iconContainerBg,
    iconContainerWidth,
    iconColor,
    height,
    mediaItem,
    onClick,
  } = props;
  return (
    <>
      <SelectChoiceContainer
        checked={checked}
        height={height}
        onClick={onClick}
      >
        {mediaItem ? (
          mediaItem
        ) : (
          <IconContainer
            color={iconColor}
            size={iconSize}
            bgColor={iconContainerBg}
            width={iconContainerWidth}
          >
            <FontAwesomeIcon icon={icon} />
          </IconContainer>
        )}
        <SelectChoiceTextContianer>
          <SelectChoiceTitle className="radio-text">{title}</SelectChoiceTitle>
          <SelectChoiceSubtitle className="radio-text">
            {subtitle}
          </SelectChoiceSubtitle>
        </SelectChoiceTextContianer>
        <SelectContainer checked={checked}>
          <SelectIcon>
            <FontAwesomeIcon icon={["far", "check"]} />
          </SelectIcon>
        </SelectContainer>
      </SelectChoiceContainer>
    </>
  );
}
