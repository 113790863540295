import styled from "@emotion/styled/macro";

export const UploadFileModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
`;

export const MediaUploadItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: calc(90vh - 350px);
  overflow: auto;
`;

export const MediaUploadWithFolderSelectionItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: calc(90vh - 390px);
  overflow: auto;
`;


export const PaginateWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;
