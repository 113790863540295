import styled from "@emotion/styled/macro"
import { css, keyframes } from '@emotion/react';
import { Container as Card, AddAfterArea } from '../../atoms/card/Card.style';

export const WrapperAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ContentWrapper = styled.div`
  animation: ${WrapperAnimation} 500ms 200ms forwards;
  opacity: 0;
`;

export const CardMenu = styled.a<{ smaller?: boolean }>`
  display: block;
  cursor: pointer;
  font-weight: 400;
  color: var(--grey900);
  width: 100%;
  ${({ smaller }) => (smaller
    ? css`padding: 6px var(--space-sm) var(--space-xs)`
    : css`padding: 12px 12px var(--space-xs)`)};
  transition: background-color 250ms ease;
  
  :hover {
    background-color: var(--grey200);
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: var(--space-sm);
  padding-left: var(--space-sm); 
  padding-right: var(--space-sm);

  ${Card} {
    :last-child {
      margin-bottom: var(--space-m);

      ${AddAfterArea} {
        display: none;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;

  button {
    padding: 8px 10px;
    :first-of-type{
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    :last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

export const ColorWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  span {
    flex: 0 0 100px;
  }
`;

export const SubContent = styled.div`
  padding-top: 12px;
  padding-right: var(--space-xs);
  padding-bottom: var(--space-xs);
  padding-left: 12px;
`;

export const SubContentTitle = styled.p`
  margin-bottom: var(--space-xs);
`;

export const AddSectionButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: var(--space-sm);
  background-color: #FFF;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  
  button {
    padding: 9px 15px 8px;
  }
`;

export const TitleLoadingContainer = styled.div`
  margin-bottom: -10.5px;
`;

export const LoadingContainer = styled.div`
  padding: var(--space-sm);
`;

export const EmptyContainer = styled.div`
  background-color: var(--grey100);
  border-radius: var(--border-radius);
`;

export const LoadingOverlay = styled.div<{ headerHeight: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${(props) => (`${props.headerHeight}px`)};
  bottom: 68px;
  left: 0;
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;

  svg {
    position: absolute;
  }

  .fa-cloud {
    font-size: 60px;
    color: #FFF;
    z-index: 6;
  }

  .fa-spinner-third {
    font-size: 32px;
    color: var(--primary600);
    margin-top: 10px;
    z-index: 7;
  }

  span {
    color: #FAFAFA;
    margin-top: 102px;
  }
`;

export const OrderDropdownWrapper = styled.div`
  position: absolute;
  top: 48px;
  right: 8px;
  background-color: #FFF;
  width: fit-content;
  padding: var(--space-xs);
  border-radius: 4px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  border: none;
  outline: 0;
  z-index: 5;
`;

export const OrderDropdownButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFF;
  width: 100%;
  padding: var(--space-xs) var(--space-xs) 6px var(--space-xs);
  border-radius: 4px;
  box-shadow: none;
  border: none;
  outline: 0;
  cursor: pointer;
  text-align: left;

  svg {
    position: relative;
    top: -2px;
    background-color: var(--grey100);
    border-radius: 3px;
    padding: 2px;
  }

  path {
    fill: var(--grey500);
  }

  &:hover {
    background-color: var(--primary600);
    color: #FFF;

    svg {
      display: none;
    }
  }
`;

export const SearchBoxContainer = styled.div<{ isVisible?: boolean }>`
  position: relative;
  top: 8px;
  width: 100%;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 150ms;
  /* padding-top: var(--space-lg); */

  .fa-search {
    position: absolute;
    top: 6px;
    left: 8px;
    font-size: 14px;
    color: var(--grey700);
  }
`;

export const SearchBox = styled.input`
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid var(--grey500);
  box-shadow: none;
  border-radius: 12px;
  padding: 4px 32px 2px;
  width: 100%;

  &:focus, &:active {
    outline: none;
    border: 1px solid var(--primary600);
  }
`;

export const SearchToolsContainer = styled.div<{hide: boolean}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 4px;
  opacity: ${(props) => (props.hide ? 0 : 1)};
  visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};
  transition: all 50ms ease;
`;

export const SearchToolButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  padding: 5px var(--space-xs) var(--space-xs) var(--space-xs);
  font-size: 14px;
  color: var(--grey600);
  transition: color 150ms ease;

  &:disabled {
    color: var(--grey300);
    cursor: default;
  }

  &:not(:disabled)&:hover {
    color: #000;
  }
`;

export const FoundCount = styled.p`
  color: var(--grey600);
  font-size: 14px;
  margin-top: 1px;
  margin-right: var(--space-xs);
  pointer-events: none;
`;
