import {
  createAction,
  createAsyncThunk as nativeCreateAsyncThunk,
} from "@reduxjs/toolkit";
import createAsyncThunk from "../../middleware/customCreateThunk";
import {
  createFolderAPI,
  deleteFolderAPI,
  deleteImageAPI,
  loadAllFolder,
  loadFolderById,
  loadImagesAPI,
  moveImageAPI,
  updateFolderAPI,
  updateImage,
} from "../../../services/api";
import {
  deleteAttachmentAPI,
  loadAttachmentsAPI,
  moveAttachmentAPI,
  updateAttachmentAPI,
} from "../../../services/api/attachment";
import { FolderItemQueryParams, IMediaResponse } from "../../../types";

export const fetchAllFolder = createAsyncThunk({
  api: loadAllFolder,
  EVENT_NAME: "media/loadAllFolder",
});

export const fetchFoldersForListPage = createAsyncThunk({
  api: loadAllFolder,
  EVENT_NAME: "media/loadFoldersForListPage",
});

export const fetchImageList = createAsyncThunk({
  api: loadImagesAPI,
  EVENT_NAME: "media/loadImageInFolder",
});

export const fetchAllImages = nativeCreateAsyncThunk(
  "media/loadAllImagesInFolder",
  async (payload: FolderItemQueryParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await loadImagesAPI({ ...payload, limit: "all" });
      const data = response?.data;
      return data;
      // eslint-disable-next-line
    } catch (error: any) {
      const status = error?.response?.status || 404;
      const data = error?.response?.data;
      return rejectWithValue({ status, data });
    }
  }
);

export const fetchAttachmentList = createAsyncThunk({
  api: loadAttachmentsAPI,
  EVENT_NAME: "media/loadAttachmentInFolder",
});

export const moveAttachment = createAsyncThunk({
  api: moveAttachmentAPI,
  EVENT_NAME: "media/moveAttachment",
});

export const updateAttachmentById = createAsyncThunk({
  api: updateAttachmentAPI,
  EVENT_NAME: "media/updateAttachment",
});

export const deleteAttachmentById = createAsyncThunk({
  api: deleteAttachmentAPI,
  EVENT_NAME: "media/deleteAttachment",
});

export const fetchFolderById = createAsyncThunk({
  api: loadFolderById,
  EVENT_NAME: "media/loadFolderById",
});

export const fetchUncategorizedFolder = createAsyncThunk({
  api: loadFolderById,
  EVENT_NAME: "media/loadUncategorizedFolder",
});

export const createFolder = createAsyncThunk({
  api: createFolderAPI,
  EVENT_NAME: "media/createFolder",
});

export const deleteImageById = createAsyncThunk({
  api: deleteImageAPI,
  EVENT_NAME: "media/deleteImage",
});

export const updateImageById = createAsyncThunk({
  api: updateImage,
  EVENT_NAME: "media/updateImage",
});

export const moveImage = createAsyncThunk({
  api: moveImageAPI,
  EVENT_NAME: "media/moveImage",
});

export const updateFolder = createAsyncThunk({
  api: updateFolderAPI,
  EVENT_NAME: "media/updateFolder",
});

export const deleteFolder = createAsyncThunk({
  api: deleteFolderAPI,
  EVENT_NAME: "media/deleteFolder",
});

export const setCurrentFolderId = createAction<string>(
  "media/setCurrentFolderId"
);
export const addNewFile = createAction<IMediaResponse>("media/addNewFile");
export const deleteFolderItemById = createAction<string>(
  "media/deleteFolderItemById"
);

export const resetMedia = createAction("media/reset");
export const resetMediaItemDetail = createAction("media/resetMediaDetail");
export const resetFolderDetailState = createAction(
  "media/resetFolderDetailState"
);
