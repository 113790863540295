import { css } from '@emotion/react';

const Spaces = css`
  --space-xxxl: 56px;
  --space-xxl: 48px;
  --space-xl: 40px;
  --space-lg: 32px;
  --space-md: 24px;
  --space-sm: 16px;
  --space-xs: 8px;
`;

export default Spaces;
