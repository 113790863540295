import styled from "@emotion/styled";

export const SelectChoiceContainer = styled.div<{
  checked?: boolean;
  height?: string;
}>`
  position: relative;
  border: 1px solid
    ${(props) => (props.checked ? "var(--primary600)" : "var(--grey200)")};
  border-radius: 12px;
  display: flex;
  align-items: center;
  background: var(--white);
  transition: all 0.2s ease-in-out;

  height: ${(props) => (props.height ? props.height : "90px")};
  gap: 16px;

  &:hover {
    cursor: pointer;
    border-color: var(--primary600);
  }
`;

export const SelectContainer = styled.div<{ checked?: boolean }>`
  position: absolute;
  top: 16px;
  right: 16px;

  opacity: ${(props) => (props.checked ? "1" : "0")};
  transition: all 0.2s ease-in-out;
`;

export const SelectChoiceTextContianer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0;
`;

export const SelectChoiceTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: var(--grey700);
`;

export const SelectChoiceSubtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey600);
`;

export const SelectIcon = styled.div<{ checked?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  width: 20px;
  height: 20px;
  background-color: var(--primary600);
  border: 1px solid var(--primary600);
  border-radius: 10px;
`;

export const IconContainer = styled.div<{
  color?: string;
  bgColor?: string;
  size?: string;
  width?: string;
}>`
  width: ${(props) => (props.width ? props.width : "130px")};
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--grey200);
  border-radius: 12px 0px 0px 12px;
  font-size: ${(props) => (props.size ? props.size : "28px")};
  color: ${(props) => (props.color ? props.color : "var(--primary500)")};
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "var(--primary25)"};
`;
