import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../atoms/button/Button";
import { HeaderCell } from "../../atoms/headerCell/HeaderCell";
import { TextCell } from "../../atoms/textCell/TextCell";
import { Column } from "../../molecules/column/Column";
import { Row } from "../../molecules/row/Row";
import { DataTable } from "../../organisms/dataTable/DataTable";
import { Heading } from "../../organisms/heading/Heading";
import { Section } from "../../organisms/section/Section";
import { ListPageContent } from "./ListExample.style";

const mockData = [
  {
    title: "สรุปข่าวรอบวัน",
    subtitle: "เนื้อหาเต็ม",
    status: 'published',
    dateUpload: 'Jan 4, 2022',
    lastUpload: 'Jan 4, 2022' 
  },
  {
    title: "สรุปข่าวรอบวัน",
    subtitle: "เนื้อหาเต็ม",
    status: 'published',
    dateUpload: 'Jan 4, 2022',
    lastUpload: 'Jan 4, 2022' 
  }
]

export default function ListExample() {
  return (
    <ListPageContent>
      <Heading
        title="เสียงบทความ"
        subtitle="เสียงที่ถูกสร้างจากบทความในเว็บไซต์ต่างๆ"
        actionComponent={
          <Button leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} />}>
            สร้างบทความใหม่
          </Button>
        } />
      <Section>
        <DataTable
          header={
            <Row>
              <Column>
                <HeaderCell title="ชื่อเรื่อง" />
              </Column>
              <Column maxWidth="150px">
                <HeaderCell title="สถานะ" />
              </Column>
              <Column maxWidth="150px">
                <HeaderCell title="Date uploaded" />
              </Column>
              <Column maxWidth="150px">
                <HeaderCell title="Last updated" />
              </Column>
              <Column maxWidth="150px">
              </Column>
            </Row>
          }
        >
          {
            mockData.map(data => (
              <Row>
                <Column>
                  <TextCell primaryText={data.title} text={data.subtitle} />
                </Column>
                <Column maxWidth="150px">
                  <TextCell text={data.status} />
                </Column>
                <Column maxWidth="150px">
                  <TextCell text={data.dateUpload} />
                </Column>
                <Column maxWidth="150px">
                  <TextCell text={data.lastUpload} />
                </Column>
                <Column maxWidth="150px">
                  <Button buttonStyle="text-primary">
                    Delete
                  </Button>
                </Column>
              </Row>
            ))
          }
        </DataTable>
      </Section>
    </ListPageContent>
  )
}