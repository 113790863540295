import { InputHTMLAttributes, DetailedHTMLProps } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, InputText, Label } from './Input.style';

interface TextInputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  id?: string;
  /**
   * hint text or error message.
   */
  errorMessage?: string;
  /**
   * display warning if true.
   * @default false
   */
  isError?: boolean;
  /**
   * Add margin-bottom or not?
   * @default true
   */
  withMargin?: boolean;
}

export const Input = (props: TextInputProps) => {
  const {
    autoFocus = false,
    value,
    placeholder,
    errorMessage,
    isError = false,
    onChange,
    onKeyDown,
    onKeyUp,
    onBlur,
    readOnly = false,
    withMargin = true,
    disabled,
    required = false,
    id,
    type = 'text',
    min,
    max,
    step,
    autoComplete,
  } = props;

  return (
    <Container
      withMargin={withMargin}>
      <InputText
        id={id}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        value={value}
        type={type}
        placeholder={placeholder}
        error={isError}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onBlur={onBlur}
        readOnly={readOnly}
        disabled={disabled}
        step={step}
        min={min}
        max={max}
        ref={(input) => input && autoFocus && input.focus()}
        required={required} />
      {errorMessage && isError && (
        <Label error={isError}>
          <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} /> {errorMessage}
        </Label>
      )}
    </Container>
  );
};
