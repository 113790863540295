import { AttachmentInput } from "../../../atoms/attachmentInput/AttachmentInput";
import { IEmbeddedMedia, IMediaResponse } from "../../../types";
import { onCastEmbeddedImageDataToIMediaResponseData } from "../../../utils/castType";
import { Section } from "../Section";
import { Container } from "./SectionAttachments.style";

interface SectionAttachmentProps {
  title?: string;
  mapAttachmentInputData: {
    label?: string;
    data?: IEmbeddedMedia;
    onClickChooseAttachment: (data: IMediaResponse) => void;
    onClickDeleteAttachment: () => void;
  }[];
}

export function SectionAttachment(props: SectionAttachmentProps) {
  const { title, mapAttachmentInputData } = props;
  return (
    <Section title={title}>
      <Container>
        {mapAttachmentInputData.map((item, index) => (
          <AttachmentInput
            key={`${item.label}-${index + 400}`}
            label={item.label}
            data={onCastEmbeddedImageDataToIMediaResponseData(item.data)}
            onClickChooseAttachment={(data) =>
              item.onClickChooseAttachment(data)
            }
            onClickDeleteAttachment={() => item.onClickDeleteAttachment()}
            onClickDownloadAttachment={() => window.open(item.data?.originalUrl || "")}
          />
        ))}
      </Container>
    </Section>
  );
}
