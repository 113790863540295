import { ComponentSpecProps } from "../../../types"

export const CardListSection: ComponentSpecProps = {
  name: 'ลิสต์การ์ด',
  componentType: 'CardListSection',
  settingFields: {
    title: {
      displayName: 'หัวข้อหลัก',
      fieldName: 'title',
      type: 'string',
      defaultValue: 'ลิสต์การ์ด',
    },
  },
  settings: {
    title: 'ลิสต์การ์ด'
  },
  required: {
    title: true,
  },
  dataSources: []
}

export const FixedTitleCardListSection: ComponentSpecProps = {
  name: 'ลิสต์การ์ดแบบ Fix Title ด้านซ้าย',
  componentType: 'FixedTitleCardListSection',
  settingFields: {
    title: {
      displayName: 'หัวข้อหลัก',
      fieldName: 'title',
      type: 'string',
      defaultValue: 'ลิสต์การ์ด',
    },
  },
  settings: {
    title: 'ลิสต์การ์ดแบบ Fix Title'
  },
  required: {
    title: true,
  },
  dataSources: []
}

export const DetailedCardListWithFilterBar: ComponentSpecProps = {
  name: 'ลิสต์การ์ดแบบมีฟิลเตอร์',
  componentType: 'DetailedCardListWithFilterBar',
  settingFields: {
    title: {
      displayName: 'หัวข้อหลัก',
      fieldName: 'title',
      type: 'string',
      defaultValue: 'ลิสต์การ์ดแบบมีฟิลเตอร์',
    },
    subtitle: {
      displayName: 'หัวข้อรอง',
      fieldName: 'subtitle',
      type: 'string',
      defaultValue: 'หัวข้อรอง',
    },
  },
  settings: {
    title: 'ลิสต์การ์ดแบบมีฟิลเตอร์'
  },
  required: {
    title: true,
  },
  dataSources: []
}