import styled from "@emotion/styled/macro";

export const Container = styled.div`
  background-color: var(--white);
  border-left: 1px solid #eaecf0;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 24px; */
  max-width: 350px;
  width: 100%;
  height: 100%;
`;

export const Title = styled.div`
  font-size: var(--font-size-xl);
  font-weight: 600;
  color: var(--grey900);
`;

export const SidePanelTopContainer = styled.div<{ isHasFooter: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100%;
  margin-bottom: ${(props) => (props.isHasFooter ? "var(--space-md)" : "0")};
  padding: var(--space-md);
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
