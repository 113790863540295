import { Container, ListWrapper } from "./MediaList.style";

interface MediaListProps {
  data: React.ReactElement[];
  column?: number;
  row?: number;
  columnWidth?: string;
  limit? :number;
  // onClickSelect?: (index: number, id?:string) => void;
}

export function MediaList(props: MediaListProps) {
  const {
    data,
    column = 5,
    row = 5,
    limit = 15,
    columnWidth = "18%",
    // onClickSelect = () => {},
  } = props;
  return (
    <Container column={column} columnWidth={columnWidth} row={row}>
      {data.slice(0, limit).map((item, index) => (
        <ListWrapper
          key={`${item}-${index + 100}`}
          // onClick={() => {onClickSelect(index)}}
        >
          {item}
        </ListWrapper>
      ))}
    </Container>
  );
}
