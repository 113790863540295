import { createReducer } from "@reduxjs/toolkit";
import type { StatusState, ITerm } from "../../../types";
import { fetchAllBadge } from "./actions";
import { getStateObject } from '../../../utils/stateManagement';
import { formatErrorObject } from '../../../utils/errorFormatter';

interface InitialStateProps {
  badges: ITerm[];
  loadBadgeState: StatusState;
}

const initialState: InitialStateProps = {
  badges: [],
  loadBadgeState: {
    error: null,
    status: '',
  },
};

export const badgeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAllBadge.pending, (state) => {
      state.loadBadgeState = getStateObject({ type: 'loading' });
    })
    .addCase(fetchAllBadge.fulfilled, (state, { payload }) => {
      state.badges = payload?.data;
      state.loadBadgeState = getStateObject({ type: 'success' });
    })
    .addCase(fetchAllBadge.rejected, (state, { payload }) => {
      state.badges = [];
      state.loadBadgeState = getStateObject({
        type: 'error',
        error: formatErrorObject(payload, 'Badges'),
      });
    })
});
