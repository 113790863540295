import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Container, ContentWrapper, TitleLoadingContainer } from './EditorSidebar.style';
import { resetCurrentEdit, setEditingDataSourceIndex } from '../../../store/features/editor';
import { SectionEditData } from './SectionEditData';
import { SectionEditAppearance } from './SectionEditAppearance';
import { pageSelector } from '../../../store/features/page';
import { JSONComponentDataSourceProps, PublishedSectionProps } from '../../../types';
import { LoadingPlaceholder } from '../../atoms/loadingPlaceholder/LoadingPlaceholder';
import { ModalDelete } from '../modalDelete/ModalDelete';
import { BaseSidebar } from '../../molecules/baseSidebar/BaseSidebar';

export const EditorSidebar = () => {
  const { id: pageId } = useAppSelector(pageSelector);
  const { currentEdit } = useAppSelector((state) => state.editor);
  const [isConfirmModalShowing, setIsConfirmModalShowing] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { componentSpec } = currentEdit;
  // ตรวจสอบว่าเมนูที่เลือกมานั้นเป็นการแก้ไข Component หลัก หรือแก้ไข Child Component
  // (ตัวอย่าง Component ที่มี Child Component คือ คัดสรร by ThaiPBS)
  const currentComponent = currentEdit.childComponent || currentEdit.component as PublishedSectionProps;
  // ถ้าไม่เจอ Spec จะไม่มีสิ่งใดแสดงผลออกมา
  if (!componentSpec) {
    return (<></>);
  }

  // ประกาศ has2DataSources ที่ตรวจสอบว่ามี Data Source 2 อันหรือไม่
  const has2DataSources = 'dataSources' in componentSpec && 'dataSources2' in componentSpec;
  const dataSources = currentComponent.dataSources as Array<JSONComponentDataSourceProps>;
  const dataSources2 = currentComponent.dataSources2 as Array<JSONComponentDataSourceProps>;

  const goBackToEditingPage = () => {
    setIsConfirmModalShowing(false);
    navigate(`/page-builder/page/${pageId}`);
    dispatch(resetCurrentEdit());
  };

  const onClickBack = () => {
    dispatch(setEditingDataSourceIndex(-1)); // Reset state if the user was editing data source.

    let haveMissingValue = false;
    if (currentEdit.componentSpec?.required) {
      const { required } = currentEdit.componentSpec;
      if (currentComponent?.settings) {
        // @ts-expect-error: Incompatible
        haveMissingValue = Object.entries(currentComponent.settings).some(([key, value]) => (required[key] && !value));
      }
    }

    if (haveMissingValue && !isConfirmModalShowing) {
      setIsConfirmModalShowing(true);
    } else {
      setIsConfirmModalShowing(false);
      goBackToEditingPage();
    }
  };

  const getSidebarContent = () => {
    // ถ้าเลือกเมนู "เลือกคอนเทนต์" หรือ "เลือกคอนเทนต์หลัก"
    if (currentEdit.type === 'data') {
      return (
        <SectionEditData
          title={has2DataSources ? 'รายการคอนเทนต์ใหญ่' : 'รายการคอนเทนต์'}
          data={dataSources} />
      );
    }

    // ถ้าเลือกเมนู "เลือกคอนเทนต์รอง"
    if (currentEdit.type === 'data2') {
      return (
        <SectionEditData
          title="รายการคอนเทนต์รอง"
          data={dataSources2} />
      );
    }

    console.log('in');

    // ถ้าเลือกเมนู "ตั้งค่าการแสดงผล"
    return (
      <SectionEditAppearance />
    );
  };

  const getTitle = () => {
    if (currentEdit?.childComponent?.settings?.title) {
      return currentEdit.childComponent.settings.title;
    }

    if (currentEdit?.component?.settings?.title) {
      return currentEdit.component.settings.title;
    }

    if (currentEdit?.component?.name) {
      return currentEdit.component.name;
    }

    return (
      <TitleLoadingContainer>
        <LoadingPlaceholder width={288} height={38} />
      </TitleLoadingContainer>
    );
  };

  return (
    <Container>
      <ModalDelete
        title="มีฟิลด์สำคัญที่คุณยังไม่ได้กรอก"
        description="ข้อมูลจะไม่ถูกบันทึกหากคุณออกจากหน้านี้โดยไม่กรอกฟิลด์ที่จำเป็น คุณยืนยันที่จะออกจากหน้านี้หรือไม่?"
        isOpen={isConfirmModalShowing}
        onClose={() => setIsConfirmModalShowing(false)}
        onConfirm={goBackToEditingPage} />
      <BaseSidebar
        title={getTitle()}
        backText="Section ทั้งหมด"
        isMobileHamburger={false}
        onClickBack={onClickBack}>
        <ContentWrapper>
          { getSidebarContent() }
        </ContentWrapper>
      </BaseSidebar>
    </Container>
  );
};
