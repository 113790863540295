import styled from "@emotion/styled/macro";

export const Container = styled.div`
  background-color: var(--grey100);
  overflow: hidden;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
