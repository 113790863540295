export interface IFolder {
  id: string;
  name: string;
  imagesCount?: number;
  attachmentsCount?: number;
  parentMediaFolder: string | null;
  createdAt?: string;
  updatedAt?: string;
}

export interface IMediaEditable {
  name: string;
  parentMediaFolderId: string | null;
}

export type FolderItemQueryParams = {
  'parent-media-folder-id'?: string;
  q?: string | null;
  page?: string | null;
  limit?: string
};

export type MoveImageFolderParams = {
  data: { imageIds: string[]; destinationMediaFolderId: string };
};

export type MoveAttachmentFolderParams = {
  data: {
    attachmentIds: string[];
    destinationMediaFolderId: string;
  };
};

export interface IUpdateFolder {
  id: string;
  data: { name: string };
}

export interface IUpdateMedia {
  id: string;
  data: IMediaEditable;
}

export const initMedia = {
  name: "",
  parentMediaFolderId: null,
};
