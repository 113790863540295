import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { IconStyles } from "./MediaCard";

export const Container = styled.div<{ isSelected?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  padding: 16px;
  ${(props) =>
    props.isSelected
      ? css`
          border: 1px solid var(--primary300);
          box-shadow: 0px 0px 0px 4px var(--primary100);
        `
      : css`
          border: 1px solid var(--grey200);
        `}

  border-radius: 12px;
  justify-content: space-between;

  &:hover {
    border: 1px solid var(--primary300);
  }
`;

export const Label = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--grey700);
  margin-bottom: 6px;
`;

export const ContextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  width: 100%;
`;

export const TextHolder = styled.div``;

export const IconWrapper = styled.div<{ cardStyles: IconStyles }>`
  display: flex;
  width: 32px;
  height: 32px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  flex: 0 0 32px;

  ${(props) => {
    switch (props.cardStyles) {
      case "primary":
        return css`
          color: var(--primary600);
          background-color: var(--primary100);

          border: 4px solid var(--primary50);
        `;
      case "grey":
        return css`
          color: var(--grey600);
          background-color: var(--grey100);

          border: 4px solid var(--grey50);
        `;
      default:
        return css`
          color: var(--primary600);
          background-color: var(--primary100);

          border: 4px solid var(--primary50);
        `;
    }
  }}
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--grey700);

  padding-right: 5%;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey600);
`;

export const ActionContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 8px;
  display: flex;
  gap: 4px;
`;
