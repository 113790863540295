import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Button } from "../../../atoms/button/Button";
import { HeaderCell } from "../../../atoms/headerCell/HeaderCell";
import { TextCell } from "../../../atoms/textCell/TextCell";
import { Column } from "../../../molecules/column/Column";
import { Row } from "../../../molecules/row/Row";
import { unitArea } from "../../../types";
import { ISubtype, initSubtype } from "../../../types/unitType";
import { onClickRow } from "../../../utils/dataTable";
import { ConfirmModal } from "../../confirmModal/ConfirmModal";
import { DataTable } from "../../dataTable/DataTable";
import { ModalUnitVariant } from "../../modal/modalUnitVariant/ModalUnitVariant";
import { Section } from "../Section";
import { ActionWrapper, ButtonGroup } from "./SectionUnitVariant.style";

interface SectionUnitVariantProps {
  subtypesData: ISubtype[];
  onChangeDataAndSave?: (value: ISubtype[]) => void;
}

export function SectionUnitVariant(props: SectionUnitVariantProps) {
  const { subtypesData, onChangeDataAndSave = () => {} } = props;

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [selectedSubtype, setSelectedSubtype] = useState<ISubtype | null>(null);
  const [selectedSubtypeIndex, setSelectedSubtypeIndex] =
    useState<number | null>(null);
  const [tempSubtypesData, setTempSubtypesData] = useState<ISubtype[]>([]);

  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isReorder, setIsReorder] = useState<boolean>(false);

  function onHandleEditSubtype(data: ISubtype, index: number) {
    setSelectedSubtype(data);
    setSelectedSubtypeIndex(index);
    setIsModalOpened(true);
  }

  function onHandleDeleteSubtype(index: number) {
    const clonedData = _.cloneDeep(subtypesData);
    _.pullAt(clonedData, [index]);

    onChangeDataAndSave(clonedData);
    setSelectedSubtypeIndex(null);
    setIsShowDeleteModal(false);
  }

  function onHandleCreateSubtype(data: ISubtype) {
    onChangeDataAndSave([...subtypesData, data]);
    setSelectedSubtype(initSubtype);
    setIsModalOpened(false);
  }

  function onHandleUpdateSubtype(data: ISubtype, index: number | null) {
    const cloned = _.cloneDeep(subtypesData);
    if (index !== null) {
      cloned[index] = data;
      onChangeDataAndSave(cloned);
      setSelectedSubtype(null);
      setSelectedSubtypeIndex(null);
      setIsModalOpened(false);
    }
  }

  function onMoveUpSubtype(index: number) {
    const clonedData = _.cloneDeep(tempSubtypesData);
    clonedData.splice(index - 1, 0, clonedData[index]);
    clonedData.splice(index + 1, 1);
    setTempSubtypesData(clonedData);
  }

  function onMoveDownSubtype(index: number) {
    const clonedData = _.cloneDeep(tempSubtypesData);
    const targetData = clonedData[index];
    clonedData.splice(index, 1);
    clonedData.splice(index + 1, 0, targetData);
    setTempSubtypesData(clonedData);
  }

  useEffect(() => {
    setTempSubtypesData(subtypesData);
  }, [subtypesData]);

  return (
    <>
      {selectedSubtypeIndex !== null ? (
        <ConfirmModal
          onClose={() => {
            setIsShowDeleteModal(false);
            setSelectedSubtypeIndex(null);
          }}
          title="Delete Unit Variant"
          opened={isShowDeleteModal}
          onClickPrimaryButton={() =>
            onHandleDeleteSubtype(selectedSubtypeIndex)
          }
        >
          <div>
            Are you sure you want to delete{" "}
            <b>‘{subtypesData[selectedSubtypeIndex].title}’</b> variant?
          </div>
          <div style={{ marginTop: "16px" }}>
            Once you confirm, this unit variant will be permanently deleted from
            this unit type.
          </div>
        </ConfirmModal>
      ) : (
        <></>
      )}
      <Section
        title="Unit Variants"
        headerActionComponent={
          <ButtonGroup gap={"12px"}>
            {isReorder ? (
              <>
                <Button
                  buttonStyle="outline-light"
                  onClick={() => {
                    setIsReorder(false);
                    setTempSubtypesData(subtypesData);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  buttonStyle="primary"
                  onClick={() => {
                    setIsReorder(false);
                    onChangeDataAndSave(tempSubtypesData);
                  }}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button
                  buttonStyle="outline-light"
                  onClick={() => {
                    setIsReorder(true);
                  }}
                  leftIcon={
                    <FontAwesomeIcon icon={["far", "arrow-up-arrow-down"]} />
                  }
                  disabled={subtypesData.length < 2}
                >
                  Reorder
                </Button>
                <ModalUnitVariant
                  isUpdate={Boolean(selectedSubtypeIndex !== null)}
                  setSubtypeData={(data) => setSelectedSubtype(data)}
                  onOpen={() => {
                    setIsModalOpened(true);
                  }}
                  onClose={() => {
                    setIsModalOpened(false);
                    setSelectedSubtypeIndex(null);
                    setSelectedSubtype(null);
                  }}
                  opened={isModalOpened}
                  subtype={selectedSubtype ? selectedSubtype : initSubtype}
                  onClickAdd={(data) => onHandleCreateSubtype(data)}
                  onClickUpdate={(data) =>
                    onHandleUpdateSubtype(data, selectedSubtypeIndex)
                  }
                />
              </>
            )}
          </ButtonGroup>
        }
      >
        <>
          {Boolean(tempSubtypesData.length) && (
            <DataTable
              header={
                <Row>
                  <Column>
                    <HeaderCell title="Name" />
                  </Column>
                  <Column maxWidth="111px">
                    <HeaderCell title="Usage Area" />
                  </Column>
                  <Column maxWidth="111px">
                    <HeaderCell title="Floor Amount" />
                  </Column>
                  <Column maxWidth="133px" children={<></>} />
                </Row>
              }
            >
              <>
                {tempSubtypesData.length &&
                  tempSubtypesData &&
                  tempSubtypesData.map((item, index) => (
                    <Row
                      key={`${item.title}-${index + 500}`}
                      onClick={(e) => {
                        onClickRow(e, () => {
                          onHandleEditSubtype(item, index);
                        });
                      }}
                    >
                      <Column>
                        <TextCell primaryText={item?.title || ""} />
                      </Column>
                      <Column maxWidth="111px">
                        <TextCell
                          text={
                            item?.usageArea?.minValue
                              ? `${item?.usageArea?.minValue}${
                                  item?.usageArea?.maxValue
                                    ? "-" + item?.usageArea?.maxValue
                                    : ""
                                } ${_.get(
                                  _.find(unitArea, {
                                    value: item?.usageArea?.unit,
                                  }),
                                  "label",
                                  ""
                                )}`
                              : "-"
                          }
                        />
                      </Column>
                      <Column maxWidth="111px">
                        <TextCell text={item?.floorsCount.toString()} />
                      </Column>
                      <Column maxWidth="133px" horizontalAlign="right">
                        {isReorder ? (
                          <ButtonGroup>
                            <Button
                              buttonStyle="secondary-grey"
                              borderradius={"8px 0px 0px 8px"}
                              onClick={() => onMoveUpSubtype(index)}
                              width="28px"
                              height="28px"
                              padding="0"
                              disabled={!Boolean(index - 1 >= 0)}
                            >
                              <FontAwesomeIcon icon={["far", "arrow-up"]} />
                            </Button>
                            <Button
                              buttonStyle="secondary-grey"
                              borderradius={"0px 8px 8px 0px"}
                              onClick={() => onMoveDownSubtype(index)}
                              width="28px"
                              height="28px"
                              padding="0"
                              disabled={
                                !Boolean(index + 1 < subtypesData.length)
                              }
                            >
                              <FontAwesomeIcon icon={["far", "arrow-down"]} />
                            </Button>
                          </ButtonGroup>
                        ) : (
                          <ActionWrapper>
                            <Button
                              buttonStyle="text-link-grey"
                              onClick={() => {
                                setSelectedSubtypeIndex(index);
                                setIsShowDeleteModal(true);
                              }}
                              fontSize="sm"
                            >
                              Delete
                            </Button>
                            <Button
                              buttonStyle="text-link-primary"
                              onClick={() => onHandleEditSubtype(item, index)}
                              fontSize="sm"
                            >
                              Edit
                            </Button>
                          </ActionWrapper>
                        )}
                      </Column>
                    </Row>
                  ))}
              </>
            </DataTable>
          )}
        </>
      </Section>
    </>
  );
}
