import styled from '@emotion/styled'

export const SideFormGroupContainer = styled.div`
  display: flex;
`

export const LeftContainer = styled.div`
  flex: 0 0 280px;
`

export const RightContainer = styled.div<{ childrenGap: string }>`
  flex: 1;
  max-width: 520px;
  margin-left: 32px;
  
  > *:not(:last-child) {
    margin-bottom: ${(props) => props.childrenGap};
  }
`

export const SideFormGroupTitle = styled.div`
  font-weight: 700;
  color: var(--grey700);
  font-size: 14px;
`

export const SideFormGroupSubtitle = styled.div`
  color: #475467;
`