import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AspectRatio } from "@mantine/core";
import _ from "lodash";
import qs from "qs";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../../atoms/button/Button";
import { HeaderCell } from "../../atoms/headerCell/HeaderCell";
import { TextCell } from "../../atoms/textCell/TextCell";
import { Column } from "../../molecules/column/Column";
import { Row } from "../../molecules/row/Row";
import { DataTable } from "../../organisms/dataTable/DataTable";
import {
  fetchPostList,
  postSelector,
  setEditPostData,
} from "../../store/features/post";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ListPageLayout } from "../../templates/listPageLayout/ListPageLayout";
import { onClickRow } from "../../utils/dataTable";
import NoImage from "../../assets/images/no_image.png";
import {
  StatusWrapper,
  MainStatusWrapper,
  Dot,
  MainStatusText,
  SubStatusText,
  TitleHolder,
  ImageContainer,
} from "./PostListLayout.style";
import { formatFullDateTime } from "../../utils/format";
import { Modal } from "../../organisms/modal/Modal";
import { SelectChoice } from "../../atoms/selectChoice/SelectChoice";
import { HorizontalList } from "../../atoms/horizontalList/HorizontalList";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { initialPostEditData } from "../../types/post";

const publishedStatusColor = [
  { color: undefined, value: "draft", label: "Draft" },
  { color: "var(--success500)", value: "published", label: "Published" },
  { color: "var(--warning500)", value: "scheduled", label: "Scheduled" },
];

const postType = [
  { value: "blog", label: "Blog" },
  { value: "image", label: "Image" },
  { value: "html", label: "HTML" },
  { value: "page-builder", label: "Page builder" },
];

const selectChoiceItem = [
  {
    title: "Write a blog",
    subtitle: "Share your content by writing blog post",
    icon: ["far", "align-left"],
    value: "blog",
  },
  {
    title: "Use a code template",
    subtitle: "Create a post from your code template",
    icon: ["far", "code"],
    iconColor: "var(--success500)",
    iconContainerBg: "var(--success25)",
    value: "html",
  },
  {
    title: "Upload image",
    subtitle: "Just upload your image content and post it",
    icon: ["far", "mountain-sun"],
    iconColor: "var(--highlight500)",
    iconContainerBg: "var(--highlight25)",
    value: "image",
  },
  {
    title: "Build a new page",
    subtitle: "Build your page from the supported layouts",
    icon: ["far", "table-layout"],
    iconColor: "var(--warning500)",
    iconContainerBg: "var(--warning25)",
    value: "page-builder",
  },
];

interface PostListLayoutProps {
  pageHeader: string;
  categoryKey: string;
  pathname: string;
  isPromotion?: boolean;
}

export function PostListLayout(props: PostListLayoutProps) {
  const { pageHeader, categoryKey, pathname, isPromotion } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { pagination, postList } = useAppSelector(postSelector);

  const filter = {
    q: searchParams.get("q"),
    page: searchParams.get("page"),
    "category-key": categoryKey,
    limit: 15,
  };

  function onGeneratePostStatus(status: string, updatedAt: string) {
    const statusObj = _.find(publishedStatusColor, { value: status }) || null;
    if (statusObj && statusObj.value === "draft") {
      return (
        <StatusWrapper>
          <SubStatusText>{statusObj.label}</SubStatusText>
        </StatusWrapper>
      );
    }
    if (statusObj) {
      return (
        <StatusWrapper>
          <MainStatusWrapper>
            <Dot color={statusObj.color} />
            <MainStatusText>{statusObj.label}</MainStatusText>
          </MainStatusWrapper>
          <SubStatusText>
            {formatFullDateTime(new Date(updatedAt))}
          </SubStatusText>
        </StatusWrapper>
      );
    }
  }

  useEffect(() => {
    dispatch(fetchPostList({ ...filter }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("q"), searchParams.get("page")]);

  const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<string>("");

  return (
    <>
      {/* Modal */}
      <>
        {/* Modal Create Post*/}
        <Modal
          size={529}
          opened={isShowCreateModal}
          onClose={() => {
            setIsShowCreateModal(false);
            setSelectedType("");
          }}
          primaryButtonTitle="Choose"
          onClickPrimaryButton={() => {
            dispatch(
              setEditPostData({
                ...initialPostEditData,
                type: selectedType,
                categoryKey: categoryKey,
              })
            );
            navigate(`${pathname}/create`);
          }}
          isDisablePrimaryButton={!Boolean(selectedType)}
          secondaryButtonTitle="Cancel"
          onClickSecondaryButton={() => {
            setIsShowCreateModal(false);
            setSelectedType("");
          }}
          title="Choose how you want to create"
        >
          <HorizontalList>
            <>
              {selectChoiceItem.map((item, index) => (
                <SelectChoice
                  {...item}
                  icon={item.icon as IconProp}
                  key={`${item.title}-select-choice-${index + 1}`}
                  onClick={() => {
                    selectedType === item.value
                      ? setSelectedType("")
                      : setSelectedType(item.value);
                  }}
                  checked={item.value === selectedType}
                />
              ))}
            </>
          </HorizontalList>
        </Modal>
      </>
      <ListPageLayout
        pageTitle={pageHeader}
        actionComponent={
          <Button
            buttonStyle="primary"
            leftIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
            onClick={() => {
              if (isPromotion) {
                navigate(`/promotion/create`);
              }
              setIsShowCreateModal(true);
            }}
          >
            Create Post
          </Button>
        }
      >
        <DataTable
          searchPlaceHolder={"Search by title"}
          onSearchData={(value) => {
            searchParams.set("q", value);
            setSearchParams(searchParams);
          }}
          currentPage={parseInt(searchParams.get("page") as string, 10) || 1}
          totalPages={pagination.lastPage || 1}
          onChangePage={(page) =>
            navigate({
              pathname: pathname,
              search: qs.stringify(
                {
                  q: searchParams.get("q") || undefined,
                  page,
                },
                { addQueryPrefix: true, skipNulls: true }
              ),
            })
          }
          header={
            <Row>
              <Column>
                <HeaderCell title="Title" />
              </Column>
              <Column maxWidth="130px">
                <HeaderCell title="Type" />
              </Column>
              <Column maxWidth="200px">
                <HeaderCell title="Status" />
              </Column>
              <Column maxWidth="88px" children={<></>} />
            </Row>
          }
        >
          {postList &&
            postList.map((item, index) => (
              <Row
                key={`${item.id}-${index + 1}-${pathname}`}
                onClick={(e) =>
                  onClickRow(e, () => {
                    navigate(`${pathname}/${item.id}`);
                  })
                }
              >
                <Column>
                  <TitleHolder>
                    <ImageContainer>
                      <AspectRatio ratio={16 / 9}>
                        <img
                          src={
                            _.find(item.thumbnails, { deviceType: "desktop" })
                              ?.originalUrl || NoImage
                          }
                          alt={item?.title || "no-image"}
                        />
                      </AspectRatio>
                    </ImageContainer>
                    <TextCell primaryText={item?.title || ""} />
                  </TitleHolder>
                </Column>
                <Column maxWidth="130px">
                  <TextCell
                    text={_.find(postType, { value: item.type })?.label}
                  />
                </Column>
                <Column maxWidth="200px">
                  {onGeneratePostStatus(item.publishStatus, item.updatedAt)}
                </Column>
                <Column maxWidth="88px" horizontalAlign="right">
                  <Button
                    buttonStyle="text-link-primary"
                    onClick={() => {
                      navigate(`${pathname}/${item.id}`);
                    }}
                  >
                    Edit
                  </Button>
                </Column>
              </Row>
            ))}
        </DataTable>
      </ListPageLayout>
    </>
  );
}
