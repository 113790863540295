import styled from "@emotion/styled/macro";

export const ContentContainer = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 24px; */

  > * {
    max-width: 750px;
    width: 100%;
    /* margin-bottom: 24px; */
  }
`;

export const ContentHolder = styled.div`
  padding: var(--space-sm) var(--space-md) var(--space-md);
`;

export const ContentWrapper = styled.div`
  padding-bottom: var(--space-md);
  max-width: 520px;
`;

// export const ButtonGroup = styled.div`
//   display: grid;
//   grid-template-columns: repeat(2, auto);
//   gap: 12px;
// `;

export const InfoText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey600);
  margin-top: 6px;
`;

export const SidePanelSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FixedButtonGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 12px;
  /* position: fixed; */
  /* bottom: 24px;
  left: 24px;
  right: 24px; */
  /* margin-bottom: 24px; */
`;

