import { IEmbeddedMedia } from "./image";
import { ContentVariation } from "./contentVariation";
import { StatusState } from "./state";
import { IHouseCollectionType } from "./houseCollection";
import { IFacilityTypes } from "./facility";
import { IProductType } from "./productType";

export interface IUnitType {
  id: string;
  contentVariations: (ContentVariation & {
    subtypes: ISubtype[];
    functions: IFunction[];
  })[];
  forProjectId: string | null;
  project: {
    contentVariations: ContentVariation[];
  };
  productTypeId: string;
  productType: IProductType;
  availableLanguages: string[];
  logo: IEmbeddedMedia | null;
  houseCollectionId: string;
  houseCollection: IHouseCollectionType;
  banners: IEmbeddedMedia[];
  thumbnail: IEmbeddedMedia | null;
  attachments: IEmbeddedMedia[];
  isTemplate: boolean;
  internalNote: string | null;
  createdAt?: string;
  updatedAt?: string;
}

export interface IEditableUnitType
  extends Omit<
    IUnitType,
    | "contentVariations"
    | "id"
    | "houseCollection"
    | "project"
    | "productType"
    | "createdAt"
    | "updatedAt"
  > {
  contentVariation: ContentVariation & {
    subtypes: ISubtype[];
    functions: IFunction[];
  };
}

export type UpdateUnitTypeTypeParam = {
  unitTypeId: string;
  data: IEditableUnitType;
};

export interface ISubtype {
  floorPlans: IFloorPlan[];
  title: string;
  floorsCount: number;
  usageArea: {
    minValue: number;
    maxValue: number;
    unit: string | null;
  };
}
export interface IFloorPlan {
  index: number | null;
  // contentVariation: ContentVariation;
  description: string;
  image: IEmbeddedMedia | null;
}

export const initFloorPlan = {
  index: null,
  description: "",
  image: null,
};

export interface IFunction {
  name: string;
  functionTypeId: string | null;
  amount: number;
}

export const initFunction = {
  functionTypeId: null,
  name: "",
  amount: 1,
};

export const initSubtype = {
  floorPlans: [initFloorPlan],
  title: "",
  floorsCount: 1,
  usageArea: {
    minValue: 0,
    maxValue: 0,
    unit: "ตร.ม.",
  },
};

export type UnitTypeQueryParam = {
  page?: string;
  q?: string;
  type?: string;
  "for-project"?: string;
  limit?: string;
};

export type UnitTypeFilterByHouseCollection = {
  houseCollectionId: string | null;
  houseCollectionName: string | null;
  unitTypes: UnitTypeForProject[];
};

export type UnitTypeForProject = {
  id: string;
  // forProjectId: string | null;
  productTypeId: string;
  // avilableLanguages: string[];
  languageCode: string;
  // name?: string;
  // description: string;
  houseCollectionId: string | null;
  houseCollection: { [key: string]: any } | null;
  internalNote: string | null;
  isTemplate: boolean;
  createdAt?: string;
  updatedAt?: string;
  [key: string]: any;
};

interface unitTypeListInitialState {
  unitTypeList: IUnitType[];
  allUnitTypeList: IUnitType[];
  functionTypeList: IFacilityTypes[];
  loadUnitTypeListState: StatusState;
  loadAllUnitTypeListState: StatusState;
  loadFunctionTypeListState: StatusState;
  pagination: {
    from: number;
    to: number;
    lastPage: number;
    perPage: number;
    total: number;
    currentPage: number;
  };
}

interface unitTypeDetailInitialState {
  currentUnitType: IUnitType | null;
  editUnitType: IEditableUnitType | null;
  duplicateUnitType: IUnitType | null;
  loadUnitTypeDetailState: StatusState;
  createUnitTypeState: StatusState;
  updateUnitTypeState: StatusState;
  deleteUnitTypeState: StatusState;
}

export type unitTypeInitialState = unitTypeListInitialState &
  unitTypeDetailInitialState;

export const initUnitType = {
  contentVariation: {
    languageCode: "th",
    name: "",
    description: "",
    subtypes: [],
    functions: [],
  },
  forProjectId: null,
  productTypeId: "",
  availableLanguages: [],
  logo: null,
  houseCollectionId: "",
  houseCollection: null,
  banners: [],
  thumbnail: null,
  attachments: [],
  isTemplate: false,
  internalNote: null,
};
