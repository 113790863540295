import { keyframes } from '@emotion/react';
import styled from "@emotion/styled/macro"

export const Container = styled.div`
  position: relative;
`;

export const ClearButton = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
  position: absolute;
  right: 0;
  top: 0;
  padding: 11.5px 14px 10.5px;
  cursor: pointer;
  font-size: 14px;
  z-index: 10;

  svg {
    color: #cccccc;
    transition: color 100ms ease-in;
  }

  &:hover {
    svg {
      color: #9f9f9f;
    }
  }
`;

export const shimmer = keyframes`
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
`;

export const LoadingPlaceholder = styled.div`
  width: 100%;
  height: 38px;

  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${shimmer};
  animation-timing-function: linear;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, var(--grey200) 33%);
  background-size: 1200px 100%;
`;
