import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import isoLanguages from "@cospired/i18n-iso-languages";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { formatFullDateTime } from "../../utils/format";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  deletedProductTypeById,
  fetchProductTypes,
  productTypeSelector,
  resetProductTypeDetailState,
  setCurrentProductType,
  updatedProductTypeById,
} from "../../store/features/productType";

import { HeaderCell } from "../../atoms/headerCell/HeaderCell";
import { TextCell } from "../../atoms/textCell/TextCell";
import { Column } from "../../molecules/column/Column";
import { Row } from "../../molecules/row/Row";
import { DataTable } from "../../organisms/dataTable/DataTable";
import { Section } from "../../organisms/section/Section";
import { Button } from "../../atoms/button/Button";
import { Badge } from "../../atoms/badge/Badge";

import { BadgeWrapper, ActionWrapper } from "./ProductTypeList.style";
import { IProductType, lang } from "../../types";
import { ListPageWithLanguageSidebar } from "../../templates/listPageWithLanguageSidebar/ListPageWithLanguageSidebar";
import { ConfirmModal } from "../../organisms/confirmModal/ConfirmModal";
import { onClickRow } from "../../utils/dataTable";
import { onHandleSingleDataContentVariations } from "../../utils/dataService";
import qs from "qs";
import { addToast } from "../../store/features/toast";
import { Skeleton } from "../../atoms/loading/skeleton/Skeleton";

export function ProductTypeList() {
  const languageCode = lang;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    productTypes,
    currentProductType,
    updateProductTypeState,
    pagination,
    deleteProductTypeState,
    loadProductTypeListState,
  } = useAppSelector(productTypeSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedProductType, setSelectedProductType] =
    useState<IProductType>();
  const [isSidePanelActive, setIsSidePanelActive] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);

  const filter = { page: searchParams.get("page"), q: searchParams.get("q"), 'used-in': "unit-type" }

  function onHandleGenerateBadges(data: string[]) {
    if (data) {
      return data.map((item, index) => (
        <Badge color={"grey"} key={item + index}>
          <>{isoLanguages.getName(item, "en")}</>
        </Badge>
      ));
    }
    return (
      <Badge color={"grey"}>
        <FontAwesomeIcon icon={["far", "plus"]} />
      </Badge>
    );
  }

  function onHandleUpdateProductType(data?: IProductType) {
    if (data) {
      const contentVariation = data.contentVariations.find(
        (item) => item.languageCode === languageCode
      );
      if (contentVariation) {
        dispatch(
          updatedProductTypeById({
            productTypeId: data.id,
            data: { ...data, contentVariation: contentVariation },
          })
        );
      }
    }
  }

  async function onHandleDeleteProductType() {
    await dispatch(deletedProductTypeById(currentProductType?.id));
    await dispatch(
      fetchProductTypes(filter)
    );
    onCloseDeleteModal();
  }

  function onHandleEditProductType(productType: IProductType) {
    dispatch(setCurrentProductType(productType));
    setSelectedProductType(productType);
    setIsSidePanelActive(true);
  }

  function onCloseSidebar() {
    setIsSidePanelActive(false);
    dispatch(resetProductTypeDetailState());
  }

  function onCloseDeleteModal() {
    setIsShowDeleteModal(false);
    dispatch(resetProductTypeDetailState());
  }
  function onOpenDeleteModal(productType: IProductType) {
    dispatch(setCurrentProductType(productType));
    setIsShowDeleteModal(true);
  }

  async function loadAllProductTypeData() {
    dispatch(
      fetchProductTypes(filter)
    );
  }

  useEffect(() => {
    loadAllProductTypeData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, dispatch, updateProductTypeState]);

  useEffect(() => {
    const status = deleteProductTypeState.status;
    if (status === "success")
      dispatch(addToast({ status, title: "Successfully deleted" }));
    if (status === "error")
      dispatch(addToast({ status, title: "Delete failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProductTypeState.status, dispatch, location.pathname]);

  return (
    <ListPageWithLanguageSidebar
      sideBarTitle={
        currentProductType
          ? onHandleSingleDataContentVariations(
              currentProductType?.contentVariations,
              "name",
              languageCode
            )
          : ""
      }
      editData={selectedProductType as IProductType}
      pageTitle="Product Type"
      isSidePanelActive={isSidePanelActive}
      onUpdateEditData={(editData) =>
        setSelectedProductType(editData as IProductType)
      }
      headerActionComponent={
        <Link to={"/product-type/create"}>
          <Button leftIcon={<FontAwesomeIcon icon={["fas", "plus"]} />}>
            Create Product Type
          </Button>
        </Link>
      }
      onClickCancel={onCloseSidebar}
      onClickSave={() => onHandleUpdateProductType(selectedProductType)}
      saveState={updateProductTypeState}
    >
      <>
        <ConfirmModal
          onClose={onCloseDeleteModal}
          title="Delete Product Type"
          opened={isShowDeleteModal}
          onClickPrimaryButton={() => onHandleDeleteProductType()}
        >
          <div>
            Are you sure you want to delete{" "}
            <b>
              ‘
              {
                _.find(currentProductType?.contentVariations, {
                  languageCode: languageCode,
                })?.name
              }
              ’
            </b>
            ?
          </div>
          <div style={{ marginTop: "16px" }}>
            Once you confirm, this product type will be permanently deleted and
            also removed from all contents that use it.
          </div>
        </ConfirmModal>
        <Section>
          <DataTable
            onSearchData={(value) => {
              searchParams.set("q", value);
              setSearchParams(searchParams);
            }}
            currentPage={parseInt(searchParams.get("page") as string, 10) || 1}
            totalPages={pagination.total && pagination.lastPage}
            onChangePage={(page) =>
              navigate({
                pathname: "/product-type",
                search: qs.stringify(
                  {
                    q: searchParams.get("q") || undefined,
                    page,
                  },
                  { addQueryPrefix: true, skipNulls: true }
                ),
              })
            }
            header={
              <Row>
                <Column>
                  <HeaderCell title="Name" />
                </Column>
                <Column maxWidth="260px">
                  <HeaderCell title="Language" />
                </Column>
                <Column maxWidth="160px">
                  <HeaderCell title="Updated At" />
                </Column>
                <Column maxWidth="100px" children={<></>} />
              </Row>
            }
          >
            {loadProductTypeListState.status === "success" && productTypes ? (
              productTypes.map((data, index) => (
                <Row
                  key={data.id + index + 1}
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    onClickRow(event, () =>
                      navigate(`/product-type/${data.id}`)
                    )
                  }
                >
                  <Column>
                    <TextCell
                      primaryText={
                        _.find(data.contentVariations, {
                          languageCode: languageCode,
                        })?.name
                      }
                    />
                  </Column>
                  <Column maxWidth="260px">
                    <BadgeWrapper>
                      {onHandleGenerateBadges(data.availableLanguages)}
                    </BadgeWrapper>
                  </Column>
                  <Column maxWidth="160px">
                    <TextCell
                      text={formatFullDateTime(new Date(data.updatedAt))}
                    />
                  </Column>
                  <Column maxWidth="100px">
                    <ActionWrapper>
                      <Button
                        buttonStyle="text-link-grey"
                        onClick={() => onOpenDeleteModal(data)}
                        fontSize="sm"
                      >
                        Delete
                      </Button>
                      <Button
                        buttonStyle="text-link-primary"
                        onClick={() => onHandleEditProductType(data)}
                        fontSize="sm"
                      >
                        Edit
                      </Button>
                    </ActionWrapper>
                  </Column>
                </Row>
              ))
            ) : (
              <Skeleton height={"250px"} radius={0} />
            )}
          </DataTable>
        </Section>
      </>
    </ListPageWithLanguageSidebar>
  );
}
