import { ChangeEvent } from "react";
import { TextArea } from "../../../atoms/textArea/TextArea";
import { TextInput } from "../../../atoms/textInput/TextInput";
import { ContentHolder, ContentWrapper } from "./SeoSection.style";
import { Section } from "../Section";
import { IEmbeddedMedia, IMediaResponse } from "../../../types";
import { ImageInput } from "../../../atoms/imageInput/ImageInput";

type SeoData = {
  seoTitle?: string | null;
  seoDescription?: string | null;
  ogTitle?: string | null;
  ogDescription?: string | null;
  ogImage?: IEmbeddedMedia | null;
};

interface SeoSectionProps {
  data: SeoData;
  onClickSave?: () => void;
  onChangeSeoTitle?: (e: string) => void;
  onChangeSeoDescription?: (e: string) => void;
  onChangeOgTitle?: (e: string) => void;
  onChangeOgDescription?: (e: string) => void;
  onChangeOgImage?: (img: IMediaResponse | null) => void;
}

export function SeoSection(props: SeoSectionProps) {
  const {
    data,
    onClickSave,
    onChangeSeoTitle = () => {},
    onChangeSeoDescription = () => {},
    onChangeOgTitle = () => {},
    onChangeOgDescription = () => {},
    onChangeOgImage = () => {},
  } = props;
  return (
    <Section
      headerButtonStyle="primary"
      headerButtonTitle={onClickSave && "Save"}
      onClickHeaderButton={() => onClickSave && onClickSave()}
      title="SEO"
    >
      <ContentHolder>
        <ContentWrapper>
          <TextInput
            label="Title"
            // placeholder="SEO_LOCATION_TITLE"
            value={data?.seoTitle || ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangeSeoTitle(e.target.value)
            }
          />
        </ContentWrapper>
        <ContentWrapper>
          <TextArea
            label="Description"
            // placeholder="SEO_LOCATION_DESCRIPTION"
            value={data?.seoDescription || ""}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              onChangeSeoDescription(e.target.value)
            }
          />
        </ContentWrapper>
        <ContentWrapper>
          <TextInput
            label="og:title"
            // placeholder="OPEN_GRAPH_LOCATION_TITLE"
            value={data?.ogTitle || ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangeOgTitle(e.target.value)
            }
          />
        </ContentWrapper>
        <ContentWrapper>
          <TextArea
            label="og:description"
            // placeholder="OPEN_GRAPH_LOCATION_DESCRIPTION"
            value={data?.ogDescription || ""}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              onChangeOgDescription(e.target.value)
            }
          />
        </ContentWrapper>
        <ContentWrapper>
          <ImageInput
            label="og:image"
            previousImageData={data.ogImage}
            ratio={16/9}
            size={"320px"}
            imgSize={"1200 x 628"}
            onClickChooseImage={(img) => onChangeOgImage(img)}
            data={{
              imgUrl: data?.ogImage?.originalUrl || "",
              imgAlt: data?.ogImage?.name || "",
            }}
          />
        </ContentWrapper>
      </ContentHolder>
    </Section>
  );
}
