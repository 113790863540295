export const mapAttachmentUnitTypePage = [
  {
    label: "Brochure",
    value: "brochure"
  },
];

export const mapAttachmentProjectInputData = [
  {
    label: "Fact sheet",
    value: "fact-sheet"
  },
  {
    label: "Master plan",
    value: "master-plan"
  },
  {
    label: "Brochure",
    value: "brochure"
  },
];
