import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Container,
  Label,
  ContextWrapper,
  IconWrapper,
  Title,
  SubTitle,
  TextHolder,
  ActionContainer,
} from "./MediaCard.style";

export type IconStyles = "primary" | "grey";

interface MediaCardProps {
  isSelected?:boolean;
  iconStyles?: IconStyles;
  title: string;
  cardAction?: React.ReactElement;
  icon?: IconProp;
  onClick?: () => void;
}

export function MediaCard(props: MediaCardProps) {
  const {
    isSelected,
    icon,
    cardAction,
    title,
    onClick = () => {},
    iconStyles = "primary",
  } = props;
  return (
    <Container onClick={() => onClick()} isSelected={isSelected}>
      <ContextWrapper>
        {icon && (
          <IconWrapper cardStyles={iconStyles}>
            <FontAwesomeIcon icon={icon} />
          </IconWrapper>
        )}
        <Title>{title}</Title>
      </ContextWrapper>
      {cardAction}
    </Container>
  );
}
