import styled from "@emotion/styled/macro"
import { css } from '@emotion/react'
import { TabsContainer } from '../../atoms/tabs/Tabs.style';
import { SelectButton } from '../../atoms/select/Select.style';

interface StyleProps {
  isEditMode: boolean;
}

export const Container = styled.div<StyleProps>`
  margin-top: var(--space-sm);
  margin-bottom: ${(props) => (props.isEditMode ? '0' : 'var(--space-md)')};

  ${TabsContainer} {
    ${(props) => (props.isEditMode && css`
      background-color: var(--grey200);
    `)};
  }

  ${SelectButton} {
    ${(props) => (props.isEditMode && css`
      background-color: #FFF;
      border: 1px solid var(--grey400);
    `)};
  }
`;

export const Separator = styled.div`
  padding: var(--space-md) 48px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--grey300)
`;

export const Content = styled.div``;

export const FormGroup = styled.div`
  font-size: 14px;
  margin-top: var(--space-sm);

  label {
    display: block;
    color: var(--grey600);
    margin-bottom: var(--space-xs);
  }
`;

export const InputWithUnit = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  div {
    width: 100px;
    margin-right: var(--space-sm);
    margin-bottom: 0;
  }
`;

export const SubmitButtonContainer = styled.div`
  button {
    display: block;
    padding: var(--space-xs) var(--space-sm);
    height: auto;
  }
`;

export const CancelButton = styled.button`
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: var(--grey600);
  cursor: pointer;
  display: block;
  margin-top: 2px;
  padding: var(--space-xs);
  text-align: center;
  transition: color 150ms ease;
  width: 100%;

  &:hover {
    color: var(--grey1000);
  }
`;
