import { createAction } from "@reduxjs/toolkit";
import createAsyncThunk from "../../middleware/customCreateThunk";
import { loadAllBadge } from "../../../services/api";

export const fetchAllBadge = createAsyncThunk({
  api: loadAllBadge,
  EVENT_NAME: "Badges/loadAllBadge",
});

export const resetBadge = createAction("Badge/reset");
