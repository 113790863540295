import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";

export const HeadingContainer = styled.div`
  display: flex;
  padding: 32px 0;
  justify-content: space-between;
  align-items: center;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const HeadingText = styled.div`
  padding-right: 24px;
  width: 100%;
`;

export const HeadingActionContainer = styled.div`
  /* margin-left: auto;
  margin-top: 14px; */
`;

export const HeadingTitle = styled.div`
  font-weight: 600;
  font-size: 32px;
  color: var(--grey900);
  margin-top: 4px;
  width: 100%;
  
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-right: 5%;
`;

export const HeadingSubtitle = styled.div`
  font-size: 16px;
  color: #475467;
  width: 100%;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-right: 5%;
`;

export const HeaderBackButton = styled(Link)`
  flex: 1 0 auto;
  margin-right: 24px;
`;
