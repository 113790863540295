import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { BreadcrumbStyle } from "./Breadcrumb";

export const Text = styled.div<{ breadcrumbStyle: BreadcrumbStyle }>`
  font-size: 14px;
  font-weight: 600;
  cursor: default;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 45vw;

  padding-right: 5%;
  /* height: 25px; */

  /* display: flex;
  align-items: center; */

  ${(props) => {
    switch (props.breadcrumbStyle) {
      case "primary":
        return css`
          color: var(--primary700);
        `;
      case "grey":
        return css`
          color: var(--grey700);
        `;
      default:
        return css`
          color: var(--primary700);
        `;
    }
  }}
`;
