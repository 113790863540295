import { ComponentOptionProps } from "../../types/section";
// HeroSection
import HeroSectionImage from "../../assets/preview-images/HeroSection.png";
import HeroSectionWithTextBoxImage from "../../assets/preview-images/HeroSectionWithTextBox.png";
// SplitView
import HorizontalImageSplitViewSectionImage from "../../assets/preview-images/HorizontalImageSplitViewSection.png";
import SquareImageSplitViewSectionImage from "../../assets/preview-images/SquareImageSplitViewSection.png";
import HorizontalVideoSplitViewSectionImage from "../../assets/preview-images/HorizontalVideoSplitViewSection.png";
import SquareLogoSplitViewSectionImage from "../../assets/preview-images/SquareLogoSplitViewSection.png";
import LinkSquareImageSplitViewSectionImage from "../../assets/preview-images/LinkSquareImageSplitViewSection.png";
import LinkSquareVideoSplitViewSectionImage from "../../assets/preview-images/LinkSquareVideoSplitViewSection.png";
import AccordionSquareImageSplitViewSectionImage from "../../assets/preview-images/AccordionSquareImageSplitViewSection.png";
import AccordionHorizontalImageSplitViewSectionImage from "../../assets/preview-images/AccordionHorizontalImageSplitViewSection.png";
import SquareSlideSplitViewSectionImage from "../../assets/preview-images/SquareSlideSplitViewSection.png";
import GallerySplitViewSectionImage from "../../assets/preview-images/GallerySplitViewSection.png";
import MapSplitViewSectionImage from "../../assets/preview-images/MapSplitViewSection.png";
import AutoFillHouseTypeSection from "../../assets/preview-images/AutoFillHouseTypeSection.png";

// CardList
import CardListSectionImage from "../../assets/preview-images/CardListSection.png";
import FixedTitleCardListSectionImage from "../../assets/preview-images/FixedTitleCardListSection.png";
import DetailedCardListWithFilterBarImage from "../../assets/preview-images/DetailedCardListWithFilterBar.png";
// InformationSection
import HorizontalInformationSectionImage from "../../assets/preview-images/HorizontalInformationSection.png";
import VerticalInformationSectionImage from "../../assets/preview-images/VerticalInformationSection.png";
// Others
import HighlightTopicSectionImage from "../../assets/preview-images/HighlightTopicSection.png";

export const componentPreviewList: { [key: string]: {
  sectionTitle: string;
  components: ComponentOptionProps[]
}[]} = {
  "standard": [
    {
      "sectionTitle": "",
      "components": [
        {
          "componentName": "แบนเนอร์",
          "componentAmount": 2,
          "componentOptions": [
            {
              "name": "แบนเนอร์หลัก",
              "componentType": "HeroSection"
            },
            {
              "name": "แบนเนอร์หลักพร้อมกล่องข้อความ",
              "componentType": "HeroSectionWithTextBox"
            }
          ],
          "componentPreviews": [
            HeroSectionImage,
            HeroSectionWithTextBoxImage
          ],
          "componentPreviewsMobile": [
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png",
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png",
          ]
        },
        {
          "componentName": "Section แบ่งครึ่งซ้าย-ขวา",
          "componentAmount": 11,
          "componentOptions": [
            {
              name: 'Section แบ่งซ้าย-ขวาแบบภาพแนวนอน (Text)',
              componentType: 'HorizontalImageSplitViewSection',
            },
            {
              name: 'Section แบ่งซ้าย-ขวาแบบภาพจัตุรัส (Text)',
              componentType: 'SquareImageSplitViewSection',
            },
            {
              name: 'Section แบ่งซ้าย-ขวาแบบวิดีโอแนวนอน (Text)',
              componentType: 'HorizontalVideoSplitViewSection',
            },
            {
              name: 'Section แบ่งซ้าย-ขวาแบบภาพจัตุรัส (Logo)',
              componentType: 'LogoSquareImageSplitViewSection',
            },
            {
              name: 'Section แบ่งซ้าย-ขวาแบบภาพจัตุรัส (Link)',
              componentType: 'LinkSquareImageSplitViewSection',
            },
            {
              name: 'Section แบ่งซ้าย-ขวาแบบวิดีโอจัตุรัส (Link)',
              componentType: 'LinkSquareVideoSplitViewSection',
            },
            {
              name: 'Section แบ่งซ้าย-ขวาแบบภาพแนวนอน (Accordion)',
              componentType: 'AccordionHorizontalImageSplitViewSection',
            },
            {
              name: 'Section แบ่งซ้าย-ขวาแบบภาพจัตุรัส (Accordion)',
              componentType: 'AccordionSquareImageSplitViewSection',
            },
            {
              name: 'Section แบ่งซ้าย-ขวาแบบภาพสไลด์',
              componentType: 'SquareSlideSplitViewSection',
            },
            {
              name: 'Section สำหรับแสดงแกลเลอรี',
              componentType: 'AutoFillGallerySplitViewSection',
            },
            {
              name: 'Section สำหรับแสดงแผนที่',
              componentType: 'AutoFillMapSplitViewSection',
            },
            {
              name: 'Section สำหรับแสดงแบบบ้านโครงการ',
              componentType: 'AutoFillHouseTypeSection',
            }
          ],
          "componentPreviews": [
            HorizontalImageSplitViewSectionImage,
            SquareImageSplitViewSectionImage,
            HorizontalVideoSplitViewSectionImage,
            SquareLogoSplitViewSectionImage,
            LinkSquareImageSplitViewSectionImage,
            LinkSquareVideoSplitViewSectionImage,
            AccordionHorizontalImageSplitViewSectionImage,
            AccordionSquareImageSplitViewSectionImage,
            SquareSlideSplitViewSectionImage,
            GallerySplitViewSectionImage,
            MapSplitViewSectionImage,
            AutoFillHouseTypeSection,
          ],
          "componentPreviewsMobile": [
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png",
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png",
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png",
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png",
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png",
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png",
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png",
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png",
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png",
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png"
          ]
        },
        {
          "componentName": "ลิสต์การ์ด",
          "componentAmount": 3,
          "componentOptions": [
            {
              "name": "ลิสต์การ์ด",
              "componentType": "CardListSection"
            },
            {
              "name": "ลิสต์การ์ดแบบ fixed หัวข้อด้านซ้าย",
              "componentType": "FixedTitleCardListSection"
            },
            {
              "name": "ลิสต์การ์ดแบบมีฟิลเตอร์",
              "componentType": "DetailedCardListWithFilterBar"
            },
          ],
          "componentPreviews": [
            CardListSectionImage,
            FixedTitleCardListSectionImage,
            DetailedCardListWithFilterBarImage
          ],
          "componentPreviewsMobile": [
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png",
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png",
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png",
          ]
        },
        {
          "componentName": "Section สำหรับแสดงข้อมูลทั่วไป",
          "componentAmount": 2,
          "componentOptions": [
            {
              "name": "Section สำหรับแสดงข้อมูลทั่วไป (แนวตั้ง)",
              "componentType": "AutoFillVerticalInformationSection"
            },
            {
              "name": "Section สำหรับแสดงข้อมูลทั่วไป (แนวนอน)",
              "componentType": "AutoFillHorizontalInformationSection"
            },
          ],
          "componentPreviews": [
            VerticalInformationSectionImage,
            HorizontalInformationSectionImage,
          ],
          "componentPreviewsMobile": [
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png",
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png",
          ]
        },
        {
          "componentName": "อื่นๆ",
          "componentAmount": 1,
          "componentOptions": [
            {
              "name": "Section สำหรับแสดง facilities",
              "componentType": "AutoFillHighlightTopicSection"
            }
          ],
          "componentPreviews": [
            HighlightTopicSectionImage,
          ],
          "componentPreviewsMobile": [
            "https://thaipbs-web-lionhead-asset.s3.amazonaws.com/thaipbs-web-lionhead-static/componentData/componentPreviewImages/HorizontalBannerMobile.png",
          ]
        }
      ]
    }
  ]
}
