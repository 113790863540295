import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { Button } from "../button/Button";
import {
  Container,
  IconWrapper,
  ContentHolder,
  MainText,
  MainTextBold,
  SupportingText,
} from "./UploadFileField.style";

interface UploadFileFieldProps {
  text?: string;
  textFont?: "sm" | "md";
  supportingType?: string;
  height?: string;
  acceptType?: string;
  multipleFile?: boolean;
  disabled?: boolean;
  onFileUpload: (files: FileList) => void;
}

export function UploadFileField(props: UploadFileFieldProps) {
  const {
    text,
    textFont = "sm",
    supportingType,
    height,
    onFileUpload,
    acceptType,
    multipleFile = false,
    disabled = false,
  } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dragCounterRef = useRef(0);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onFileUpload(event.target.files);
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounterRef.current++;
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounterRef.current--;
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounterRef.current = 0;
    onFileUpload(event.dataTransfer.files);
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleChange}
        accept={acceptType}
        multiple={multipleFile}
        disabled={disabled}
      />
      {!text && (
        <Container
          height={height}
          onClick={() => handleClick()}
          onDragEnter={(e) => handleDragEnter(e)}
          onDragOver={(e) => handleDragOver(e)}
          onDragLeave={(e) => handleDragLeave(e)}
          onDrop={(e) => handleDrop(e)}
        >
          <IconWrapper>
            <FontAwesomeIcon icon={["far", "cloud-upload"]} />
          </IconWrapper>
          <ContentHolder>
            <div>
              <MainTextBold>Click to upload</MainTextBold>
              <MainText>or drag and drop</MainText>
            </div>
            {supportingType && (
              <SupportingText>{supportingType}</SupportingText>
            )}
          </ContentHolder>
        </Container>
      )}
      {text && (
        <Button
          height="fit-content"
          buttonStyle="text-link-primary"
          onClick={() => handleClick()}
          fontSize={textFont}
        >
          {text}
        </Button>
      )}
    </>
  );
}
