import styled from "@emotion/styled/macro";
import { maxLayout } from "../../utils/breakpoints";

export const FlexContainer = styled.div<{ customHeight?: string }>`
  display: flex;
  height: ${(props) =>
    props.customHeight ? props.customHeight : "100%"};
`;

export const ContentContainer = styled.div<{ customHeight?: string }>`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    max-width: 750px;
    width: 100%;
    /* margin-bottom: 80px; */
  }

  overflow-y: auto;
  overflow-x: hidden;
  max-height: ${(props) =>
    props.customHeight ? props.customHeight : "calc(100vh - 89px)"};

  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollWrapper = styled.div`
  /* overflow-y: scroll;
  overflow-x: hidden; */
  /* max-height: calc(100vh - 89px); */
  /* margin-bottom: 0; */
  padding: 24px 0;
  margin-bottom: 80px;

  @media ${maxLayout.xxl} {
    padding: 24px;
  }

  /* -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  } */

  > section {
    :last-child {
      margin-bottom: 0px;
    }
    margin-bottom: 24px;
  }
`;

export const MediaInputWrapper = styled.div`
  display: grid;
  /* grid-template-columns: auto auto; */
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  /* grid-auto-flow: column; */
`;

export const ContentHolder = styled.div`
  padding: var(--space-sm) var(--space-md) var(--space-md);
`;

export const ContentWrapper = styled.div<{paddingBottom?: string}>`
  padding-bottom: ${props => props.paddingBottom ? props.paddingBottom : 'var(--space-md)'};
  max-width: 520px;

  &:last-of-type {
    padding-bottom: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SidePanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ErrorInputIconWrapper = styled.div`
  color: var(--error500);
  font-size: 14px;
  margin-top: 3px;
`;

export const SelectLanguageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
