import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, useEffect, useState } from "react";
import { Button } from "../../../atoms/button/Button";
import { HeaderCell } from "../../../atoms/headerCell/HeaderCell";
import { Select } from "../../../atoms/select/Select";
import { TextArea } from "../../../atoms/textArea/TextArea";
import { TextCell } from "../../../atoms/textCell/TextCell";
import { TextInput } from "../../../atoms/textInput/TextInput";
import { Column } from "../../../molecules/column/Column";
import { Modal } from "../../modal/Modal";
import { Row } from "../../../molecules/row/Row";
import {
  ActionWrapper,
  DistanceContainer,
} from "../../../pages/projectDetail/ProjectDetail.style";
import {
  INearby,
  initialPlace,
  initNearby,
  PlaceDataType,
} from "../../../types/nearby";
import { DataTable } from "../../dataTable/DataTable";
import { Section } from "../Section";
import _ from "lodash";

import { ConfirmModal } from "../../confirmModal/ConfirmModal";
import { onClickRow } from "../../../utils/dataTable";
import {
  NearbyModalWrapper,
  ButtonGroup,
  DropdownWrapper,
  HeaderTable,
  HeaderText,
  ContentWrapper,
  PlaceListContainer,
  PlaceTypeWrapper,
  DeleteButtonWrapper,
} from "./SectionNearby.style";
import { DropdownButton } from "../../../molecules/dropdownButton/DropdownButton";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchPlaceTypeList,
  placeTypeSelector,
} from "../../../store/features/placeType";
import { ModalEditPlace } from "./modal/ModalEditPlace";
import { ContentDivider } from "../../../molecules/contentDivider/ContentDivider";
import { ImageInput } from "../../../atoms/imageInput/ImageInput";
import { onCastIUploadDataToEmbeddedImageData } from "../../../utils/castType";
import { unitDistance } from "../../../types";

interface SectionNearbyProps {
  nearbysData: INearby[];
  onChangeDataAndSave: (path: string, value: string | any[] | null) => void;
  languageCode?: string;
}

export function SectionNearby(props: SectionNearbyProps) {
  const { nearbysData, onChangeDataAndSave, languageCode = "th" } = props;
  const dispatch = useAppDispatch();
  const { placeTypes } = useAppSelector(placeTypeSelector);

  const [editableNearbyData, setEditableNearbyData] =
    useState<INearby>(initNearby);
  const [editablePlaceData, setEditablePlaceData] =
    useState<PlaceDataType>(initialPlace);
  const [filterData, setFilterData] = useState<INearby[]>([]);

  const [selectedNearby, setSelectedNearby] = useState<number | null>(null);
  const [selectedPlaceType, setSelectedPlaceType] =
    useState<number | null>(null);
  const [selectedDropdown, setSelectedDropdown] = useState<number | null>(null);

  const [nearbyModalOpened, setNearbyModalOpened] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isDeleteByPlaceTypeModal, setIsDeleteByPlaceTypeModal] =
    useState<boolean>(false);
  const [isShowModalEditPlaces, setIsShowModalEditPlaces] =
    useState<boolean>(false);
  const [isReorder, setIsReorder] = useState<boolean>(false);

  function resetState() {
    setSelectedNearby(null);
    setSelectedPlaceType(null);
    setSelectedDropdown(null);
    setEditableNearbyData({ ...initNearby, places: [initialPlace] });
  }

  async function onHandleCreateNearby(data: INearby) {
    const clonedData = _.cloneDeep(data);
    onChangeDataAndSave("nearbys", [
      ...nearbysData,
      _.omit(clonedData, "placeType"),
    ]);
    setNearbyModalOpened(false);
    setEditableNearbyData(initNearby);
    resetState();
  }

  async function onHandleUpdateNearby(index: number, data: INearby) {
    const clonedFilter = _.cloneDeep(filterData);
    const clonedData = _.cloneDeep(data);
    clonedFilter[index] = clonedData;
    onChangeDataAndSave("nearbys", clonedFilter);
    setNearbyModalOpened(false);
    setSelectedDropdown(null);
    resetState();
  }

  async function onHandleUpdatePlace(
    index: number,
    subIndex: number,
    data: PlaceDataType
  ) {
    const clonedFilter = _.cloneDeep(filterData);
    const clonedData = _.cloneDeep(data);
    clonedFilter[index].places[subIndex] = clonedData;
    onChangeDataAndSave("nearbys", clonedFilter);
    resetState();
    setIsShowModalEditPlaces(false);
  }

  async function onHandleDeleteNearby(index: number, subIndex: number) {
    const clonedData = _.cloneDeep(filterData);
    _.pullAt(clonedData[index].places, [subIndex]);

    onChangeDataAndSave(
      "nearbys",
      removePlaceTypeThatHaveEmptyNearby(clonedData)
    );
    setSelectedNearby(null);
    setSelectedPlaceType(null);
    setIsShowDeleteModal(false);
    resetState();
  }

  async function onHandleDeletePlaceType(index: number) {
    const clonedData = _.cloneDeep(filterData);
    _.pullAt(clonedData, [index]);
    onChangeDataAndSave("nearbys", clonedData);
    setSelectedDropdown(null);
    setIsDeleteByPlaceTypeModal(false);
    resetState();
  }

  function onHandleChangeEditableNearbyData(value: any, path: string) {
    const clonedNearby = _.cloneDeep(editableNearbyData);
    _.set(clonedNearby, path, value);
    setEditableNearbyData(clonedNearby);
  }

  function onMoveUpPlaceType(index: number) {
    const clonedData = _.cloneDeep(filterData);
    const targetData = clonedData[index];
    clonedData.splice(index, 1);
    clonedData.splice(index - 1, 0, targetData);
    setFilterData(clonedData);
  }

  function onMoveDownPlaceType(index: number) {
    const clonedData = _.cloneDeep(filterData);
    const targetData = clonedData[index];
    clonedData.splice(index, 1);
    clonedData.splice(index + 1, 0, targetData);
    setFilterData(clonedData);
  }

  function onAddPlace() {
    const clonedData = _.cloneDeep(editableNearbyData);
    clonedData.places = [...clonedData.places, initialPlace];
    setEditableNearbyData(clonedData);
  }

  function onDeletePlace(index: number) {
    const clonedData = _.cloneDeep(editableNearbyData);
    _.pullAt(clonedData.places, [index]);
    setEditableNearbyData(clonedData);
  }

  function CheckIsValidNearbyData() {
    return !Boolean(
      (_.get(
        editableNearbyData,
        `places[${editableNearbyData?.places?.length - 1}].distance`,
        null
      ) &&
        _.get(
          editableNearbyData,
          `places[${editableNearbyData?.places?.length - 1}].names`,
          null
        )?.length &&
        _.get(
          editableNearbyData,
          `places[${editableNearbyData?.places?.length - 1}].names[0]`,
          null
        ) !== "") ||
        editableNearbyData.places.length === 0
    );
  }

  function removePlaceTypeThatHaveEmptyNearby(data: INearby[]) {
    return data
      .map((item) => {
        return item.places.length && item;
      })
      .filter(Boolean);
  }

  useEffect(() => {
    dispatch(fetchPlaceTypeList({}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilterData(nearbysData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nearbysData]);

  return (
    <>
      {/* Modal */}
      <>
        {/* Remove nearby */}
        {selectedNearby !== null && selectedPlaceType !== null ? (
          <ConfirmModal
            onClose={() => {
              setIsShowDeleteModal(false);
              setSelectedNearby(null);
            }}
            title="Delete Nearby Item"
            opened={isShowDeleteModal}
            onClickPrimaryButton={() =>
              onHandleDeleteNearby(selectedPlaceType, selectedNearby)
            }
          >
            <div>Are you sure you want to delete this item?</div>
            <div style={{ marginTop: "16px" }}>
              Once you confirm, this item will be permanently deleted from this
              project.
            </div>
          </ConfirmModal>
        ) : (
          <></>
        )}
        {/* Remove nearby by place type */}
        {selectedDropdown !== null ? (
          <ConfirmModal
            onClose={() => {
              setIsDeleteByPlaceTypeModal(false);
              setSelectedDropdown(null);
            }}
            title="Remove Place Type"
            opened={isDeleteByPlaceTypeModal}
            onClickPrimaryButton={() =>
              onHandleDeletePlaceType(selectedDropdown)
            }
            primaryButtonTitle={"Remove"}
          >
            <div>
              Are you sure you want to remove{" "}
              <b>‘{filterData[selectedDropdown].placeType?.name}’</b>?
            </div>
            <div style={{ marginTop: "16px" }}>
              Once you confirm, all nearby data under this place type will be
              permanently deleted from this project.
            </div>
          </ConfirmModal>
        ) : (
          <></>
        )}
        {/* Edit Place Modal */}
        <ModalEditPlace
          placeData={editablePlaceData}
          isModalOpened={isShowModalEditPlaces}
          onClose={() => {
            setIsShowModalEditPlaces(false);
          }}
          onUpdatePlace={(data) => {
            if (selectedNearby !== null && selectedPlaceType !== null) {
              onHandleUpdatePlace(selectedPlaceType, selectedNearby, data);
            }
          }}
        />
      </>
      <Section
        title="Nearby"
        headerActionComponent={
          <ButtonGroup gap={"12px"}>
            {isReorder ? (
              <>
                <Button
                  buttonStyle="outline-light"
                  onClick={() => {
                    setIsReorder(false);
                    setFilterData(nearbysData);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  buttonStyle="primary"
                  onClick={() => {
                    setIsReorder(false);
                    onChangeDataAndSave("nearbys", filterData);
                  }}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button
                  buttonStyle="outline-light"
                  onClick={() => {
                    setIsReorder(true);
                    resetState();
                  }}
                  leftIcon={
                    <FontAwesomeIcon icon={["far", "arrow-up-arrow-down"]} />
                  }
                  disabled={filterData.length < 2}
                >
                  Reorder
                </Button>
                <Modal
                  button={
                    <Button
                      onClick={() => {
                        setNearbyModalOpened(true);
                        setEditableNearbyData(editableNearbyData);
                      }}
                      leftIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
                      buttonStyle={"outline-light"}
                    >
                      Add
                    </Button>
                  }
                  onClose={() => {
                    setNearbyModalOpened(false);
                    resetState();
                  }}
                  opened={nearbyModalOpened}
                  title={
                    selectedDropdown !== null ? "Edit Nearby" : "Add Nearby"
                  }
                  size={700}
                  primaryButtonTitle={
                    selectedDropdown !== null ? "Save" : "Add"
                  }
                  isDisablePrimaryButton={
                    !Boolean(
                      !CheckIsValidNearbyData() &&
                        Boolean(editableNearbyData?.placeTypeId)
                    )
                  }
                  onClickPrimaryButton={() => {
                    if (selectedDropdown !== null) {
                      onHandleUpdateNearby(
                        selectedDropdown,
                        editableNearbyData
                      );
                    } else {
                      onHandleCreateNearby(editableNearbyData);
                    }
                  }}
                  secondaryButtonTitle="Cancel"
                  onClickSecondaryButton={() => {
                    setNearbyModalOpened(false);
                    resetState();
                  }}
                >
                  <>
                    <NearbyModalWrapper>
                      <PlaceTypeWrapper>
                        <ImageInput
                          ratio={16 / 9}
                          previousImageData={editableNearbyData?.thumbnail}
                          data={{
                            imgUrl:
                              editableNearbyData?.thumbnail?.originalUrl || "",
                            imgAlt: editableNearbyData?.thumbnail?.name || "",
                          }}
                          onClickChooseImage={(img) =>
                            onHandleChangeEditableNearbyData(
                              onCastIUploadDataToEmbeddedImageData(img, null),
                              "thumbnail"
                            )
                          }
                        />
                        <Select
                          label="Place type"
                          placeholder="Select a place type"
                          value={editableNearbyData?.placeTypeId}
                          data={
                            placeTypes.map((item) => ({
                              value: item.id,
                              label:
                                _.find(item.contentVariations, {
                                  languageCode: languageCode,
                                })?.name || "",
                              disabled: Boolean(
                                filterData.find(
                                  (data) => data.placeTypeId === item.id
                                )
                              ),
                            })) || []
                          }
                          onChange={(e: string) => {
                            onHandleChangeEditableNearbyData(e, "placeTypeId");
                          }}
                        />
                      </PlaceTypeWrapper>
                      <ContentDivider title="Nearby List" />
                      {/* <HintText>The list will be sorted by the distance (nearest to farthest).</HintText> */}
                      {editableNearbyData?.places.map((item, index) => (
                        <PlaceListContainer key={`${index + 1}-places`}>
                          {index > 0 ? (
                            <DeleteButtonWrapper>
                              <Button
                                buttonStyle="text-link-danger"
                                onClick={() => onDeletePlace(index)}
                                fontSize="sm"
                              >
                                Delete
                              </Button>
                            </DeleteButtonWrapper>
                          ) : (
                            <></>
                          )}
                          <DistanceContainer>
                            <TextInput
                              autoComplete={"off"}
                              type="number"
                              label={"Distance"}
                              value={item?.distance || ""}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                onHandleChangeEditableNearbyData(
                                  e.target.value,
                                  `places[${index}].distance`
                                );
                              }}
                            />
                            <Select
                              data={unitDistance}
                              value={item?.unit}
                              onChange={(e: string) => {
                                onHandleChangeEditableNearbyData(
                                  e,
                                  `places[${index}].unit`
                                );
                              }}
                            />
                          </DistanceContainer>

                          <TextArea
                            label={"Place list (one per line)"}
                            placeholder={"Enter places"}
                            value={item?.names?.join("\n")}
                            height={150}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                              onHandleChangeEditableNearbyData(
                                e.target.value.split("\n"),
                                `places[${index}].names`
                              );
                            }}
                          />
                        </PlaceListContainer>
                      ))}
                      <Button
                        buttonStyle="text-link-grey"
                        leftIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
                        onClick={() => onAddPlace()}
                        disabled={CheckIsValidNearbyData()}
                      >
                        Add nearby list
                      </Button>
                    </NearbyModalWrapper>
                  </>
                </Modal>
              </>
            )}
          </ButtonGroup>
        }
      >
        <>
          {Boolean(filterData.length) &&
            filterData?.map((item, index) => (
              <ContentWrapper
                key={`${item?.placeTypeId}-place-type-group-${index}`}
              >
                <HeaderTable>
                  <HeaderText>{item?.placeType?.name || ""}</HeaderText>
                  <ButtonGroup gap={"0px"}>
                    {isReorder ? (
                      <>
                        <Button
                          buttonStyle="secondary-grey"
                          onClick={() => {
                            onMoveUpPlaceType(index);
                          }}
                          disabled={!Boolean(index - 1 >= 0)}
                          fontSize="sm"
                          width="28px"
                          height="28px"
                          padding="0"
                          borderradius={"8px 0px 0px 8px"}
                        >
                          <FontAwesomeIcon icon={["far", "arrow-up"]} />
                        </Button>
                        <Button
                          buttonStyle="secondary-grey"
                          onClick={() => {
                            onMoveDownPlaceType(index);
                          }}
                          disabled={!Boolean(index + 1 < filterData.length)}
                          fontSize="sm"
                          width="28px"
                          height="28px"
                          borderradius={"0px 8px 8px 0px"}
                          padding="0"
                        >
                          <FontAwesomeIcon icon={["far", "arrow-down"]} />
                        </Button>
                      </>
                    ) : (
                      <DropdownWrapper>
                        <DropdownButton
                          right="-8px"
                          gap={"36px"}
                          horizontalAlign="right"
                          buttonProps={{
                            children: (
                              <FontAwesomeIcon
                                icon={["far", "ellipsis-vertical"]}
                              />
                            ),
                            buttonStyle: "text-link-grey",
                            width: "20px",
                            position: "right",
                          }}
                          dropdownMenuProps={{
                            data: [
                              {
                                icon: ["far", "pen"],
                                label: "Edit",
                                onClick: () => {
                                  setNearbyModalOpened(true);
                                  setEditableNearbyData(filterData[index]);
                                  setSelectedDropdown(index);
                                },
                              },
                              {
                                icon: ["far", "trash-can"],
                                label: "Remove from this project",
                                onClick: () => {
                                  setIsDeleteByPlaceTypeModal(true);
                                  setSelectedDropdown(index);
                                },
                              },
                            ],
                            maxWidth: "240px",
                            padding: "16px",
                          }}
                        />
                      </DropdownWrapper>
                    )}
                  </ButtonGroup>
                </HeaderTable>
                <DataTable
                  header={
                    <Row>
                      <Column maxWidth="96px">
                        <HeaderCell title="Distance" />
                      </Column>
                      <Column>
                        <HeaderCell title="From" />
                      </Column>
                      <Column maxWidth="133px" children={<></>} />
                    </Row>
                  }
                >
                  {item?.places?.map((subItem, subItemIndex) => (
                    <Row
                      key={`${subItem?.names?.join("-")}-${subItem?.unit}-${
                        subItem?.distance
                      }-${subItemIndex + 1}-nearbys`}
                      onClick={(e) =>
                        onClickRow(e, () => {
                          setIsShowModalEditPlaces(true);
                          setEditablePlaceData(subItem);
                          setSelectedNearby(subItemIndex);
                          setSelectedPlaceType(index);
                        })
                      }
                    >
                      <Column maxWidth="96px">
                        <TextCell
                          text={`${subItem?.distance} ${_.get(
                            _.find(unitDistance, { value: subItem?.unit }),
                            "label",
                            ""
                          )}`}
                        />
                      </Column>
                      <Column>
                        <TextCell text={subItem?.names?.join(", ") || "-"} />
                      </Column>
                      <Column maxWidth="133px" horizontalAlign="right">
                        {!isReorder ? (
                          <ActionWrapper>
                            <Button
                              buttonStyle="text-link-grey"
                              onClick={() => {
                                setSelectedNearby(subItemIndex);
                                setSelectedPlaceType(index);
                                setIsShowDeleteModal(true);
                              }}
                              fontSize="sm"
                            >
                              Delete
                            </Button>
                            <Button
                              buttonStyle="text-link-primary"
                              onClick={() => {
                                setIsShowModalEditPlaces(true);
                                setEditablePlaceData(subItem);
                                setSelectedNearby(subItemIndex);
                                setSelectedPlaceType(index);
                              }}
                              fontSize="sm"
                            >
                              Edit
                            </Button>
                          </ActionWrapper>
                        ) : (
                          <></>
                        )}
                      </Column>
                    </Row>
                  ))}
                </DataTable>
              </ContentWrapper>
            ))}
        </>
      </Section>
    </>
  );
}
