import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled/macro";

const slideInRight = keyframes`
  0% {
    transform: translateX(350px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideOutRight = keyframes`
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% {
    transform: translateX(350);
    opacity: 0;
  }
`;

export const BrandTableContainer = styled.div`
  padding: 32px;
`;

export const Container = styled.div<{ isSidePanelActive: boolean }>`
  ${(props) =>
    props.isSidePanelActive
      ? css`
          display: grid;
          grid-template-columns: auto 350px;
          height: 100%;
          width: 100%;
        `
      : css`
          display: grid;
          grid-template-columns: auto;
        `}
`;

export const BadgeWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: flex-end;
`;

export const SidePanelWrapper = styled.div<{ isActive: boolean }>`
  max-width: 350px;
  width: 100%;
  max-height: 100vh;
  /* ${(props) =>
    props.isActive
      ? css`
          animation: ${slideInRight} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
        `
      : css`
          animation: ${slideOutRight} 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
            both;
        `} */
`;

//
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

//
export const SidePanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  padding: 16px;
  justify-content: flex-end;
  gap: 12px;
`;
