import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Container, Wrapper } from "./Page.style";
import {
  fetchCurrentPage,
  pageSelector,
  resetCurrentPage,
} from "../../store/features/page";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  fetchContentCategories,
  fetchDataSourceList,
  setBgColorOptions,
} from "../../store/features/editor";
import {
  COMPONENT_SPEC__LIST_URL,
  COMPONENT_PREVIEW_LIST_URL,
} from "../../constants";
import { ListSectionSidebar } from "../../page-builder-components/organisms/listSectionSidebar/ListSectionSidebar";
import { AddSectionSidebar } from "../../page-builder-components/organisms/addSectionSidebar/AddSectionSidebar";
import { EditorSidebar } from "../../page-builder-components/organisms/editorSidebar/EditorSidebar";
import { PreviewArea } from "../../page-builder-components/organisms/previewArea/PreviewArea";
import { ComponentOptionProps, ComponentSpecProps } from "../../types/section";
import { componentSpecList } from "../../page-builder-components/component-spec/component-spec";
import { componentPreviewList } from "../../page-builder-components/component-spec/component-preview-list";
import { deleteDraft } from "../../services/api/page-builder";
import {
  brandSelector,
  fetchBrandById,
  resetBrandDetailState,
} from "../../store/features/brand";
import {
  fetchProjectById,
  projectSelector,
} from "../../store/features/project";

export const Page = () => {
  const [isAddingSection, setIsAddingSection] = useState<boolean>(false);
  const [componentSpec, setComponentSpec] = useState<Array<ComponentSpecProps>>(
    []
  );

  // const [componentPreviewList, setComponentPreviewList] = useState<Array<{
  //   sectionTitle: string;
  //   components: ComponentOptionProps[]
  // }>>([]);

  const dispatch = useAppDispatch();
  const { pageId } = useParams();
  const [searchParams] = useSearchParams();
  const { currentEdit, scrollPosition } = useAppSelector(
    (state) => state.editor
  );

  const { layoutName, sections, resourceId, category } =
    useAppSelector(pageSelector);
  const { currentBrand } = useAppSelector(brandSelector);
  const { currentProject } = useAppSelector(projectSelector);

  const onIframeLoaded = () => {
    const iframe = document.getElementById(
      "preview-iframe"
    ) as HTMLIFrameElement;
    const activeSectionIndex = currentEdit?.component?.index;

    if (iframe?.contentWindow && Number.isInteger(activeSectionIndex)) {
      iframe.contentWindow.postMessage(
        `${activeSectionIndex}`,
        "https://www.thaipbs.or.th"
      );
    }
  };

  const fetchData = useCallback(async () => {
    await dispatch(fetchCurrentPage(pageId as string));
    const scroller = document.getElementById("sidebar-content");
    if (scroller) {
      const timer = setTimeout(() => {
        scroller.scrollTop = scrollPosition;
        clearTimeout(timer);
      }, 100);
    }
  }, [dispatch, scrollPosition]);

  useEffect(() => {
    // เช็คว่ามี searchParams ชื่อ action อยู่หรือไม่
    const action = searchParams.get("action");
    const isAddSection = Boolean(action && action === "add");
    setIsAddingSection(isAddSection);

    // ถ้าเลือกเมนูเพิ่ม Section ไม่ต้องโหลดรายการ Section ใน Page
    if (isAddSection) {
      return;
    }

    // ถ้ากลับมาจากหน้าแก้ไข Appearance หรือ Data (หรือเมื่อไรก็ตามที่หน้านี้กลับมาในสถานะที่ Sidebar ลิสต์ Sections ทุกอันของ Page ออกมา)
    // ให้โหลดโหลดรายการ Section ใน Page
    if (!isAddSection && currentEdit?.type === null) {
      fetchData();
    }
  }, [currentEdit, searchParams]);

  const onPageDestroy = async (id: string) => {
    try {
      await deleteDraft(id);
    } catch (deleteError) {
      console.error(deleteError);
    }
  };

  useEffect(() => {
    // ถ้าเข้าหน้าเพิ่ม Section จากลิงก์ตรง ๆ (eg. https://.../page/home?action=add) ให้โหลดรายการ Section ใน Page ด้วย
    // ส่วนถ้าเข้าหน้า Page จากลิงก์ตรง ๆ (eg. https://.../page/home) จะไปโหลดใน UseEffect ด้านบน
    const action = searchParams.get("action");
    const isAddSection = Boolean(action && action === "add");
    if (isAddSection) {
      fetchData();
    }

    // โหลดรายการ Data Sources เตรียมไว้เลย จะได้ไม่ต้องโหลดใหม่เรื่อย ๆ ตอนกดเข้าหน้าแก้ไข Data
    dispatch(fetchDataSourceList({}));
    dispatch(fetchContentCategories({}));

    return () => {
      // if (pageId) {
      //   onPageDestroy(pageId);
      // }
      dispatch(resetCurrentPage());
      dispatch(resetBrandDetailState());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // โหลด related brand มาเก็บไว้
    if (category === "brand") {
      dispatch(fetchBrandById(resourceId));
    }

    if (category === "project") {
      dispatch(fetchProjectById(resourceId));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceId]);

  useEffect(() => {
    // พอโหลด brand เสร็จ ก็เอา สี มาเก็บไว้เป็น bg options
    if (currentBrand?.theme?.backgroundColors) {
      dispatch(
        setBgColorOptions(
          currentBrand?.theme?.backgroundColors.map((item, index) => ({
            value: item,
            label: `Color ${index + 1} (${item})`,
            key: `bg-color-option-${index + 1}-${item}`,
          }))
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBrand?.theme?.backgroundColors]);

  useEffect(() => {
    // พอโหลด brand เสร็จ ก็เอา สี มาเก็บไว้เป็น bg options
    if (currentProject?.brandId) {
      dispatch(fetchBrandById(currentProject?.brandId));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject?.brandId]);

  useEffect(() => {
    const loadComponentSpecList = async () => {
      const response = await axios.get(COMPONENT_SPEC__LIST_URL);
      setComponentSpec(response?.data[layoutName]);
    };

    // const loadComponentPreviewList = async () => {
    //   const response = await axios.get(COMPONENT_PREVIEW_LIST_URL);
    //   setComponentPreviewList(response?.data['standard']);
    // };

    loadComponentSpecList();
    // loadComponentPreviewList();
  }, [layoutName]);

  const getIsShowAddButton = () => {
    if (layoutName !== "ads") {
      return true;
    }
    if (layoutName === "ads" && !sections?.length) {
      return true;
    }
    return false;
  };

  return (
    <Container>
      <Wrapper>
        {Boolean(currentEdit?.component || currentEdit?.childComponent) && (
          <EditorSidebar />
        )}
        {/* // TODO: Change Spec Sheet later */}
        <ListSectionSidebar
          componentSpecList={componentSpecList.standard}
          iframeTargetOrigin="https://www.thaipbs.or.th"
          isShowAddSectionButton={getIsShowAddButton()}
        />
        {Boolean(isAddingSection) && (
          <AddSectionSidebar
            componentList={componentPreviewList.standard}
            componentSpecList={componentSpecList.standard}
          />
        )}
        <PreviewArea onIframeLoaded={onIframeLoaded} />
      </Wrapper>
    </Container>
  );
};
