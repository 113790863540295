import { IEmbeddedMedia } from "./image";

export interface IFacility {
  title: string;
  subtitle: string;
  description: string;
  facilityType?: IFacilityTypes | null;
  facilityTypeId: string | null;
  images: IEmbeddedMedia[];
  createdAt?: string;
  updatedAt?: string;
}

export interface IFacilityTypes {
  id: string;
  usedIn: string;
  name: string;
  iconId: string;
  icon?:IEmbeddedMedia | null;
  createdAt?: string;
  updatedAt?: string;
}

export const initFacility = {
  title: "",
  subtitle: "",
  description: "",
  facilityTypeId: null,
  images: [],
};
