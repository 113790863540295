import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../atoms/button/Button";
import { TextInput } from "../../../atoms/textInput/TextInput";
import { createFolder, mediaSelector, resetFolderDetailState } from "../../../store/features/media";
import { addToast } from "../../../store/features/toast";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IMediaEditable, initMedia } from "../../../types";
import { Modal } from "../Modal";
import { InputWrapper } from "./ModalNewFolder.style";

interface ModalNewFolderProps {
  pathToRedirect:string;
}
export function ModalNewFolder(props: ModalNewFolderProps) {
  const {pathToRedirect} = props
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [folderData, setFolderData] = useState<IMediaEditable>(initMedia);
  const { currentFolderId, createFolderState } = useAppSelector(mediaSelector);

  async function onHandleCreateFolder() {
    if (folderData.name) {
      await dispatch(createFolder(folderData));
    } else {
      console.log("please enter folder name");
    }
  }

  useEffect(() => {
    const status = createFolderState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully created" }));
      navigate(`/${pathToRedirect}/folder/${currentFolderId}`);
      // navigate(`${pathToRedirect}`);
      dispatch(resetFolderDetailState())
    }
    if (status === "error")
      dispatch(addToast({ status, title: "Create failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFolderState.status, dispatch]);

  return (
    <>
      <Modal
        onClose={() => {
          setIsModalOpened(false);
        }}
        button={
          <Button
            onClick={() => setIsModalOpened(true)}
            leftIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
            buttonStyle="outline-light"
            fullWidth
          >
            New Folder
          </Button>
        }
        opened={Boolean(isModalOpened)}
        title="New Folder"
        size={400}
        onClickPrimaryButton={() => onHandleCreateFolder()}
        onClickSecondaryButton={() => setIsModalOpened(false)}
        // isDisablePrimaryButton={!(files.length === uploadCompleted.length)}
        primaryButtonTitle={"Create"}
        secondaryButtonTitle={"Cancel"}
      >
        <>
          <InputWrapper>
            <TextInput
              data-autofocus
              placeholder="Untitled folder"
              value={folderData.name}
              onChange={(e) =>
                setFolderData({ ...folderData, name: e.target.value })
              }
            />
          </InputWrapper>
        </>
      </Modal>
    </>
  );
}
