import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Breadcrumb } from "../../../atoms/breadcrumb/Breadcrumb";
import { Button } from "../../../atoms/button/Button";
import { SearchInput } from "../../../atoms/searchInput/SearchInput";
import { UploadFileField } from "../../../atoms/uploadFileField/UploadFileField";
import { UploadItem } from "../../../atoms/uploadItem/UploadItem";
import { FolderList } from "../../../molecules/folderList/FolderList";
import {
  fetchAllFolder,
  fetchFoldersForListPage,
} from "../../../store/features/media";
import {
  cancelUploadFile,
  resetUpload,
  retryUploadFile,
  uploadFile,
  uploadSelector,
} from "../../../store/features/upload";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Modal } from "../Modal";
import {
  UploadFileModalWrapper,
  MediaUploadWithFolderSelectionItemWrapper,
} from "./ModalUpload.style";
interface ModalUploadWithFolderSelectionProps {
  isButtonFullWidth?: boolean;
  modalUploadHeader?: string;
  modalFolderDescription?: string;
  isAttachment?: boolean;
  supportingType?: string;
}
export function ModalUploadWithFolderSelection(
  props: ModalUploadWithFolderSelectionProps
) {
  const {
    isButtonFullWidth,
    modalUploadHeader,
    modalFolderDescription,
    isAttachment,
    supportingType = "PNG or JPG",
  } = props;
  const defaultBreadcrumb = [
    { value: null, label: isAttachment ? "All attachments" : "All media" },
  ];

  const uncategorizedFileName = isAttachment
    ? "Uncategorized attachments"
    : "Uncategorized media";

  const dispatch = useAppDispatch();

  const [breadcrumbData, setBreadcrumbData] =
    useState<{ value: string | null; label: string }[]>(defaultBreadcrumb);
  const { uploadCompleted, files } = useAppSelector(uploadSelector);

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
  const [uploadFileCount, setUploadFileCount] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [searchParams] = useSearchParams({});
  const [searchValue, setSearchValue] = useState(searchParams.get("q"));
  const [searchFolder, setSearchFolder] = useState("");
  const [isShowUncategorizedFolder, setIsShowUncategorizedFolder] =
    useState<boolean>(true);

  function onHandleSearch(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      setSearchFolder(searchValue || "");
      // dispatch(fetchAllFolder({ q: searchValue }));
      if (
        !(
          uncategorizedFileName
            .toLowerCase()
            .includes(searchValue?.toLowerCase() || "") || searchValue === null
        )
      ) {
        setIsShowUncategorizedFolder(false);
      } else {
        setIsShowUncategorizedFolder(true);
      }
    }
  }

  function resetStore() {
    dispatch(resetUpload());
  }

  function resetState() {
    setIsModalOpened(false);
    setIsUploading(false);
    setUploadFileCount(0);
    setSearchValue(null);
    setIsShowUncategorizedFolder(true);
    setSelectedFolder(null);
    setSearchFolder("");
    setBreadcrumbData(defaultBreadcrumb);
    dispatch(fetchAllFolder({ limit: 15 }));
    dispatch(fetchFoldersForListPage({ limit: 15 }));
  }

  function onHandleClickUncategorizedFolder() {
    setBreadcrumbData([
      breadcrumbData[0],
      { value: "null", label: uncategorizedFileName },
    ]);
    setSelectedFolder("null");
    setSearchValue(null);
    setIsShowUncategorizedFolder(true);
  }

  async function onFileUpload(filesList: FileList) {
    setIsUploading(true);
    setUploadFileCount(Array.from(filesList).length);
    Array.from(filesList).forEach(async (item) => {
      await dispatch(
        uploadFile({
          file: item,
          selectedFolder: `${selectedFolder}`,
          isAttachment,
        })
      );
    });
  }

  function onHandleUploadFailed(fileId: string) {
    dispatch(retryUploadFile({ fileId, isAttachment }));
  }

  function onHandleCancelUpload(fileId: string) {
    setUploadFileCount(uploadFileCount - 1);
    dispatch(cancelUploadFile(fileId));
  }

  return (
    <Modal
      opened={isModalOpened}
      onClose={() => {
        resetState();
        resetStore();
      }}
      button={
        <Button
          onClick={() => setIsModalOpened(true)}
          leftIcon={<FontAwesomeIcon icon={["far", "cloud-upload"]} />}
          buttonStyle="primary"
          fullWidth={isButtonFullWidth}
        >
          Upload
        </Button>
      }
      title={selectedFolder ? modalUploadHeader : "Choose a folder"}
      description={selectedFolder ? undefined : modalFolderDescription}
      size={580}
      isDisablePrimaryButton={!(files.length === uploadCompleted.length)}
      onClickPrimaryButton={() => {
        resetStore();
        resetState();
      }}
      primaryButtonTitle={isUploading ? "Done" : undefined}
    >
      <UploadFileModalWrapper>
        <Breadcrumb
          separator={<FontAwesomeIcon icon={["fas", "angle-right"]} />}
          data={breadcrumbData}
          onClickBack={() => {
            breadcrumbData.pop();
            setSelectedFolder(null);
            // dispatch(fetchAllFolder({}));
          }}
        />
        {!selectedFolder && (
          <SearchInput
            onKeyDown={(e) => onHandleSearch(e)}
            value={searchValue || ""}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setSearchValue(event.target.value)
            }
          />
        )}
        {breadcrumbData.length <= 1 && (
          <FolderList
            uncategorizedFolderName={uncategorizedFileName}
            onClickUncategorizedFolder={() =>
              onHandleClickUncategorizedFolder()
            }
            onClickFolder={(item) => {
              setBreadcrumbData([
                breadcrumbData[0],
                { value: item.id, label: item.name },
              ]);
              setSelectedFolder(item.id || null);
              setSearchValue(null);
              setIsShowUncategorizedFolder(true);
            }}
            isShowUncategorizedFolder={isShowUncategorizedFolder}
            isShowPagination
            search={searchFolder || ""}
          />
        )}
        {selectedFolder && (
          <>
            <UploadFileField
              textFont="md"
              multipleFile
              onFileUpload={(fileList) => onFileUpload(fileList)}
              height={!isUploading ? "380px" : ""}
              supportingType={supportingType}
              acceptType={isAttachment ? ".pdf" : "image/*"}
            />
            <MediaUploadWithFolderSelectionItemWrapper>
              {files &&
                files.map((item, index) => (
                  <UploadItem
                    isAttachment={isAttachment}
                    key={`${item.name || ""}-${index + 100}`}
                    uploadState={item.state}
                    layout={"B"}
                    onClickUploadAgain={() => onHandleUploadFailed(item.fileId)}
                    onClickCancel={() => onHandleCancelUpload(item.fileId)}
                    uploadProgress={item.uploadProgress.uploadProgress}
                    uploadProgressFileSize={item.uploadProgress.loaded}
                    totalFileSize={item.uploadProgress.total}
                    imgRatio={1 / 1}
                    imgData={{
                      src: item.file ? URL.createObjectURL(item.file) : "",
                      alt: item.name || "",
                    }}
                  />
                ))}
            </MediaUploadWithFolderSelectionItemWrapper>
          </>
        )}
      </UploadFileModalWrapper>
    </Modal>
  );
}
