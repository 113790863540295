import styled from "@emotion/styled/macro";

export const MediaInputContainer = styled.div<{row:number, column?:number}>`
  display: grid;
  grid-template-rows: repeat(${props => props.row ? props.row : "2"}, auto);
  grid-template-columns: repeat(${props => props.column ? props.column : "1"}, auto);
  grid-gap: 24px;
  padding: 16px 24px 24px;
`;

export const MediaCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 45vh;
  overflow-y: auto;
`;

export const MediaItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 39vh;
  overflow-y: auto;
`;

export const MediaCardWrapper = styled.div`
  cursor: pointer;
`;

export const PaginateWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonGroup = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const UploadText = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: var(--grey600);
`;

export const UploadTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px 24px;
`;
