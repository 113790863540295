/* eslint-disable react/jsx-props-no-spreading */
import { createStyles, Select as CoreSelect } from "@mantine/core";
import { SelectProps as CoreSelectProps } from "@mantine/core/lib/Select/Select";
import { MantineTheme } from "@mantine/styles/lib/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { InfoText } from "../textInput/TextInput.style";
import React from "react";

const useStyles = createStyles(
  (
    theme: MantineTheme,
    { error, inputWidth }: { error: React.ReactNode; inputWidth?: string }
  ) => ({
    root: {
      width: inputWidth ? inputWidth : undefined,
    },
    input: {
      border: error ? "1px solid var(--error500)" : "1px solid #D0D5DD",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
      borderRadius: 8,
      fontSize: 16,
      minHeight: 44,
      height: 44,
      paddingTop: 8,
      paddingBottom: 8,
      // @ts-ignore
      color: theme.colors.grey[10],
      transition: error ? "border-color 100ms ease 0s" : undefined,
      ":disabled": {
        color: "var(--grey500)",
        backgroundColor: "var(--grey50)",
        opacity: "unset",
      },

      ":focus": {
        border: error
          ? "1px solid var(--error300)"
          : "1px solid var(--primary300)",
        boxShadow: error
          ? "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--error100)"
          : "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--primary100)",
      },
    },
    required: {
      color: "red",
    },

    label: {
      fontSize: theme.fontSizes.lg,
      marginBottom: theme.spacing.xs,
      color: "var(--grey700)",
    },
    rightSection: {
      fontSize: 12,
      color: theme.colors.navy[0],
    },

    error: {
      fontSize: 14,
      fontWeight: 400,
      color: "var(--error500)",
    },

    item: {
      fontWeight: 400,
      fontSize: 16,
      padding: theme.spacing.xs,
      color: theme.colors.navy[0],
      // backgroundColor: "transparent !important",
      maxWidth: "100%",
      // height: "42px",

      "&:hover": {
        color: "var(--grey900)",
        backgroundColor: "var(--grey50)",
      },

      "&[data-selected]": {
        "&, &:hover": {
          color: "var(--grey900)",
          fontWeight: 500,
          backgroundColor: "var(--grey100)",
        },
      },

      "&[data-disabled]": {
        "&, &:hover": {
          cursor: "not-allowed",
          color: "var(--grey300)",
          backgroundColor: "transparent",
        },
      },
    },
  })
);
/**
 * Documentation: {@link https://mantine.dev/core/select/}
 */
export function Select(
  props: CoreSelectProps & { hintText?: string; inputWidth?: string }
) {
  const { label, placeholder, data, hintText, error, inputWidth, rightSection,...rest } =
    props;
  const { classes } = useStyles({ error, inputWidth });

  return (
    <div>
      <CoreSelect
        {...rest}
        label={label}
        placeholder={placeholder}
        data={data}
        rightSection={rightSection ? rightSection : <FontAwesomeIcon icon={faChevronDown} />}
        classNames={classes}
        error={error}
      />
      {hintText && <InfoText>{hintText}</InfoText>}
    </div>
  );
}
