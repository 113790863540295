/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { Container, LinkItem, LoadingContainer, Wrapper } from './Home.style';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  fetchPages,
  pageErrorSelector,
  pageLoadingSelector,
  pagesSelector,
} from '../../store/features/page';
import { PageDataProps } from '../../types';
import { resetCurrentEdit, setScrollPosition } from '../../store/features/editor';
import { BaseSidebar } from '../../page-builder-components/molecules/baseSidebar/BaseSidebar';
import { LoadingPlaceholder } from '../../page-builder-components/atoms/loadingPlaceholder/LoadingPlaceholder';
import { ErrorMessage } from '../../page-builder-components/organisms/messages/ErrorMessage';
import { PreviewArea } from '../../page-builder-components/organisms/previewArea/PreviewArea';
import { performLogout } from '../../services/api/page-builder';

const sortPages = (page1: { order: number }, page2: { order: number }) => {
  if (page1.order < page2.order) {
    return -1;
  }
  if (page1.order > page2.order) {
    return 1;
  }
  return 0;
};

export const PageList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const pages = useAppSelector(pagesSelector);
  const isLoading = useAppSelector(pageLoadingSelector);
  const error = useAppSelector(pageErrorSelector);

  const onLogout = async () => {
    await performLogout();
    navigate('/login');
  };

  const fetchData = useCallback(() => {
    dispatch(fetchPages({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetCurrentEdit());
    fetchData();
    dispatch(setScrollPosition(0));
    document.title ='PF Page Builder';
  }, []);

  return (
    <Container>
      <Wrapper>
        <BaseSidebar title="หน้าเว็บ" bottomAreaMargin="large" isMobileHamburger={false}>
          <>
            { isLoading && (
              <LoadingContainer>
                { [...Array(6)].map(() => (<LoadingPlaceholder key={`loading-${Math.random()}`} width={288} height={23} />))}
              </LoadingContainer>
            ) }
            {
              !isLoading && pages.map((item: PageDataProps) => (
                <LinkItem
                  key={`link-item-${item.title}`}
                  to={`page/${item._id}`}>
                  { item.title }
                </LinkItem>
              ))
            }
            {/* <UserCard>
              <UserInfoItem user={currentUser} />
              <UserMenuItem onClick={() => navigate('/accounts')}>
                <FontAwesomeIcon icon={['fas', 'users']} /> จัดการบัญชีผู้ใช้งาน
              </UserMenuItem>
              <UserMenuItem onClick={onLogout}>
                <FontAwesomeIcon icon={['fas', 'sign-out-alt']} /> ออกจากระบบ
              </UserMenuItem>
            </UserCard> */}
            {
              !isLoading && error && (
                <ErrorMessage />
              )
            }
          </>
        </BaseSidebar>
        <PreviewArea />
      </Wrapper>
    </Container>
  );
};
