import _ from "lodash";
import {
  ContentVariation,
  IEmbeddedMedia,
  IMediaResponse,
  SelectItemProps,
} from "../types";
import { onCastIUploadDataToEmbeddedImageData } from "./castType";

export function onHandleMultipleDataContentVariations(
  data: any[],
  key: string,
  languageCode: string
): string[] {
  const result = data?.map((item: any) => {
    return _.get(
      _.find(item?.contentVariations, { languageCode: languageCode }),
      `${key}`,
      ""
    );
  });
  return result?.filter(Boolean) || [];
}

export function formatSelectItems(
  data: any[],
  key: string,
  languageCode: string,
  valueKey?:string
): SelectItemProps[] {
  const result = data?.map((item) => {
    const filtedLanguageCodeObj = _.find(item?.contentVariations, {
      languageCode: languageCode,
    });
    const fieldObjToChooseKey = {...item, ...filtedLanguageCodeObj}
    if(filtedLanguageCodeObj) {
      return {
        label: _.get(fieldObjToChooseKey, `${key}`, ""),
        value: valueKey ? _.get(fieldObjToChooseKey, `${valueKey}`, '') : item?.id,
        key: item?.id
      };
    }else{
      const defaultValueObj = _.find(item?.contentVariations, {
        languageCode: 'th',
      });
      const fieldObjToChooseKey = {...item, ...defaultValueObj}
      return {
        label: _.get(fieldObjToChooseKey, `${key}`, ""),
        value: valueKey ? _.get(fieldObjToChooseKey, `${valueKey}`, '') : item?.id,
        key: item?.id
      };
    }
  });
  return result?.filter(Boolean) || [];
}

export function onHandleSingleDataContentVariations(
  data: ContentVariation[],
  key: string,
  languageCode: string
): string {
  return _.get(_.find(data, { languageCode: languageCode }), `${key}`) || "";
}

export function onHandleChangeArrayImage(
  data: any,
  img: IMediaResponse | null,
  device: string | null,
  key: string,
  useFor?: string | null
) {
  // console.log(data, img, device, key, useFor)
  const previousData = _.cloneDeep(_.get(data, `${key}`));
  const clonedData = _.cloneDeep(_.get(data, `${key}`));
  const embeddedFile =
    onCastIUploadDataToEmbeddedImageData(img, device, useFor) || null;
  const replaceIndex = _.findIndex(clonedData, {
    deviceType: device,
  });
  if (replaceIndex >= 0) {
    if (embeddedFile) {
      clonedData.splice(replaceIndex, 1, embeddedFile);
    } else {
      clonedData.splice(replaceIndex, 1);
    }
    return onHandleChangeEditableData(data, key, clonedData);
  } else {
    return onHandleChangeEditableData(data, key, [
      ...previousData,
      onCastIUploadDataToEmbeddedImageData(img, device, useFor),
    ]);
  }
}

export function onHandleChangeArrayAttachment(
  data: any,
  file: IEmbeddedMedia | null,
  useFor: string,
  key: string
) {
  const previousData = _.cloneDeep(_.get(data, `${key}`));
  const clonedData = _.cloneDeep(_.get(data, `${key}`));
  const replaceIndex = _.findIndex(clonedData, {
    useFor: useFor,
  });
  if (file) {
    if (replaceIndex >= 0) {
      clonedData.splice(replaceIndex, 1, {
        ...file,
        useFor: clonedData[replaceIndex].useFor,
      });
      return onHandleChangeEditableData(data, key, clonedData);
    } else {
      return onHandleChangeEditableData(data, key, [
        ...previousData,
        { ...file, useFor: useFor },
      ]);
    }
  }
}

export function onHandleChangeEditableData(
  data: any,
  path: string,
  value: any
) {
  const clonedData = _.cloneDeep(data);
  if (clonedData) {
    _.set(clonedData, path, value);
    return clonedData;
  }
  return ''
}
