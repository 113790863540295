import {
  DefaultProps,
  Skeleton as CoreSkeleton,
  SkeletonStylesParams,
} from "@mantine/core";
import { SkeletonProps as CoreSkeletonProps } from "@mantine/core/lib/Skeleton/Skeleton";

export interface SkeletonProps
  extends DefaultProps<never, SkeletonStylesParams>,
    CoreSkeletonProps {}

export function Skeleton(props: SkeletonProps) {
  const { ...rest } = props;
  return <CoreSkeleton {...rest} />;
}
