import styled from "@emotion/styled/macro";
import { ReactSortable } from 'react-sortablejs';
import { SelectedValueLabel } from '../../atoms/select/Select.style';
import { Header } from '../../molecules/baseSidebar/BaseSidebar.style';
import { WrapperAnimation } from '../listSectionSidebar/ListSectionSidebar.style';
import { maxLayout } from '../../../utils/breakpoints';
import { TitleStyle } from "../../../globalStyle/GlobalStyle.style";

export const Container = styled.div`
  position: absolute;
  flex: 0 0 330px;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 330px;
  z-index: 10;

  ${Header} {
    padding: var(--space-md) var(--space-sm);
  }

  @media ${maxLayout.md} {
    width: 100vw;
    flex: 0 0 100vw;
    z-index: 21;
  }
`;

export const ContentWrapper = styled.div`
  animation: ${WrapperAnimation} 500ms 200ms forwards;
  opacity: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;

  button {
    padding: 8px 10px;
    :first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    :last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

export const ContentContainer = styled.div`
  padding-left: var(--space-sm);
  padding-right: var(--space-sm);
`;

export const SectionTitle = styled.p`
  ${TitleStyle};
  margin-bottom: var(--space-md);
`;

export const DeleteWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: var(--space-sm);
`;

export const DeleteTitle = styled.p`
  font-size: 14px;
  color: var(--grey700);
`;

export const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  color: var(--primary600);
`;

export const ColorWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  span {
    flex: 0 0 100px;
  }
`;

export const ImageTitleLabel = styled.label`
`;

export const ChangeImageButton = styled.span<{isImageUploading: boolean}>`
  cursor: ${({ isImageUploading }) => (isImageUploading ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ isImageUploading }) => (isImageUploading ? 'none' : 'all')};
  transition: color 200ms ease;

  &:hover {
    color: ${({ isImageUploading }) => (isImageUploading ? 'var(--grey600)' : 'var(--primary600)')};
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`;

export const RemoveImageButton = styled.span`
  cursor: pointer;
  margin-left: var(--space-sm);
  transition: color 200ms ease;

  &:hover {
    color: var(--error700);
  }
`;

export const FormGroup = styled.div<{ hide?: boolean }>`
  display: ${(props) => (props.hide ? 'none' : 'block')};
  font-size: 14px;
  margin-bottom: var(--space-sm);

  input {
    margin-bottom: 0;
  }

  label {
    display: block;
    color: var(--grey600);
    margin-bottom: var(--space-xs);
  }

  ${ImageTitleLabel} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${SelectedValueLabel} {
    width: 100%;
    ${ColorWrapper} {
      span {
        flex: 0 0 104px;
      }
    }
  }
`;

export const ErrorText = styled.p`
  color: var(--error500);

  svg {
    fill: var(--error500);
  }
`;

export const TextGroup = styled.div`
  font-size: 14px;
  margin-bottom: var(--space-sm);
  color: var(--grey800);

  label {
    color: var(--grey600);
    margin-right: var(--space-sm);
  }
`;

export const ContentCardContainer = styled(ReactSortable)`
  > div:not(:last-child) {
    margin-bottom: var(--space-sm);
  }
`;

export const SectionWrapper = styled.div`
  :not(:last-child) {
    margin-bottom: var(--space-xl);
  }
`;

export const TitleLoadingContainer = styled.div`
  margin-bottom: -10.5px;
`;

export const LoadingContainer = styled.div`
  padding: var(--space-sm);
`;

export const ContentImage = styled.img`
  width: 100%;
  height: 163px;
  object-fit: contain;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
`;

export const ContentEmptyImage = styled.div<{isImageUploading: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;

  background-color: var(--grey500);
  color: #FFF;
  cursor: pointer;
  line-height: 1.7;
  text-align: center;

  width: 100%;
  height: 163px;
  pointer-events: ${({ isImageUploading }) => (isImageUploading ? 'none' : 'all')};
  transition: background-color 200ms ease;

  svg {
    font-size: 32px;
    color: #FFF;
    margin-bottom: 12px;
  }

  &:hover {
    background-color: var(--grey600);
  }
`;

export const OtherContainer = styled.div`
  border-top: 1px solid var(--grey300);
  margin-top: var(--space-xl);
  padding: var(--space-md) var(--space-sm);
`;

export const OtherMenu = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  cursor: pointer;
  font-weight: 400;
  color: var(--grey800);
  width: 100%;
  padding: 12px 0 var(--space-xs);
  transition: color 200ms ease;

  svg {
    color: var(--grey500);
    transition: color 200ms ease;
  }
  
  :hover {
    color: var(--primary600);

    svg {
      color: var(--primary600);
    }
  }
`;
