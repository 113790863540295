import { createReducer } from "@reduxjs/toolkit";
import { IBrand, IBrandEditable, SelectItemProps, StatusState } from "../../../types";
import {
  createdBrand,
  deletedBrandById,
  fetchBrandById,
  fetchBrands,
  resetBrand,
  resetBrandDetailState,
  resetBrandListState,
  resetCurrentBrand,
  resetTempBrand,
  setBrandSelectItems,
  setCurrentBrand,
  setTempBrand,
  updatedBrandById,
} from "./actions";
import { getStateObject } from '../../../utils/stateManagement';
import { formatErrorObject } from '../../../utils/errorFormatter';

interface InitialStateProps {
  brands: IBrand[];
  brandSelectItems: SelectItemProps[];
  currentBrand: IBrand | null;
  tempBrand: IBrandEditable | null;
  newBrand: IBrand | null;
  loadBrandListState: StatusState;
  loadBrandSelectItemState: StatusState;
  loadBrandByIdState: StatusState;
  createBrandState: StatusState;
  deleteBrandState: StatusState;
  updateBrandState: StatusState;
  pagination: {
    from: number;
    to: number;
    lastPage: number;
    perPage: number;
    total: number;
    currentPage: number
  };
}

const brandListInitialState = {
  brands: [],
  brandSelectItems: [],
  loadBrandListState: {
    error: null,
    status: "",
  },
  loadBrandSelectItemState: {
    error: null,
    status: "",
  },
  pagination: {
    from: 0,
    to: 0,
    lastPage: 0,
    perPage: 0,
    total: 0,
    currentPage: 0,
  },
}

const brandDetailInitialState = {
  currentBrand: null,
  newBrand: null,
  tempBrand: null,
  loadBrandByIdState: {
    error: null,
    status: "",
  },
  createBrandState: {
    error: null,
    status: "",
  },
  updateBrandState: {
    error: null,
    status: "",
  },
  deleteBrandState: {
    error: null,
    status: "",
  },
}

const initialState: InitialStateProps = {
 ...brandListInitialState,
 ...brandDetailInitialState
};

export const brandReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchBrands.pending, (state) => {
      state.loadBrandListState = getStateObject({ type: 'loading' });
    })
    .addCase(fetchBrands.fulfilled, (state, { payload }) => {
      state.brands = payload?.data;
      state.loadBrandListState = getStateObject({ type: 'success' });
      state.pagination = {
        from: payload?.from,
        to: payload?.to,
        lastPage: payload?.lastPage,
        perPage: payload?.perPage,
        total: payload?.total,
        currentPage: payload?.currentPage,
      };
    })
    .addCase(fetchBrands.rejected, (state, { payload }) => {
      state.brands = [];
      state.loadBrandListState = getStateObject({
        type: 'error',
        error: formatErrorObject(payload, 'Brands'),
      });
    })
    .addCase(setBrandSelectItems, (state, { payload }) => {
      state.brandSelectItems = payload;
      state.loadBrandSelectItemState = getStateObject({
        type: 'success',
        error: formatErrorObject(payload, 'Brand Select Items'),
      });
    })
    .addCase(fetchBrandById.pending, (state) => {
      state.loadBrandByIdState = getStateObject({ type: 'loading' });
    })
    .addCase(fetchBrandById.fulfilled, (state, { payload }) => {
      state.currentBrand = payload;
      state.loadBrandByIdState = getStateObject({ type: 'success' });
    })
    .addCase(fetchBrandById.rejected, (state, { payload }) => {
      state.currentBrand = null;
      state.loadBrandByIdState = getStateObject({
        type: 'error',
        error: formatErrorObject(payload, 'Brand'),
      });
    })
    .addCase(createdBrand.pending, (state) => {
      state.createBrandState = getStateObject({ type: 'loading' });
    })
    .addCase(createdBrand.fulfilled, (state, { payload }) => {
      state.newBrand = payload
      state.createBrandState = getStateObject({ type: 'success' });
    })
    .addCase(createdBrand.rejected, (state, { payload }) => {
      state.createBrandState = getStateObject({
        type: 'error',
        error: formatErrorObject(payload, 'Create Brand'),
      });
    })
    .addCase(updatedBrandById.pending, (state) => {
      state.updateBrandState = getStateObject({ type: 'loading' });
    })
    .addCase(updatedBrandById.fulfilled, (state, { payload }) => {
      state.currentBrand = payload;
      state.updateBrandState = getStateObject({ type: 'success' });
    })
    .addCase(updatedBrandById.rejected, (state, { payload }) => {
      state.updateBrandState = getStateObject({
        type: 'error',
        error: formatErrorObject(payload, 'Brand'),
      });
    })
    .addCase(deletedBrandById.pending, (state) => {
      state.deleteBrandState = getStateObject({ type: 'loading' });
    })
    .addCase(deletedBrandById.fulfilled, (state) => {
      state.deleteBrandState = getStateObject({ type: 'success' });
    })
    .addCase(deletedBrandById.rejected, (state, { payload }) => {
      state.deleteBrandState = getStateObject({
        type: 'error',
        error: formatErrorObject(payload, 'Brand'),
      });
    })
    .addCase(setCurrentBrand, (state, {payload}) => {
      state.currentBrand = payload;
    })
    .addCase(resetCurrentBrand, (state) => {
      state.currentBrand = null;
    })
    .addCase(setTempBrand, (state, {payload}) => {
      state.tempBrand = payload;
    })
    .addCase(resetTempBrand, (state) => {
      state.tempBrand = null;
    })
    .addCase(resetBrandListState, (state) => {
      Object.assign(state, brandListInitialState);
    })
    .addCase(resetBrandDetailState, (state) => {
      Object.assign(state, brandDetailInitialState);
    })
    .addCase(resetBrand, (state) => {
      Object.assign(state, initialState);
    });
});
