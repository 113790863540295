/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  MultiSelect as CoreMultiSelect,
  MultiSelectProps as CoreMultiSelectProps,
  createStyles,
} from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = createStyles((theme: MantineTheme, searchable:boolean) => ({
  input: {
    minHeight: 44,
    paddingTop: 8,
    paddingLeft: searchable ? "44px !important" : undefined,
    paddingBottom: 8,
    paddingRight: 8,
    display: "flex",
    alignItems: "center",
    fontSize: theme.fontSizes.lg,
    width: "100%",
    border: "1px solid #D0D5DD",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    // @ts-ignore
    color: theme.colors.grey[10],
    borderRadius: 8,

    ":focus": {
      border: "1px solid var(--primary300)",
      boxShadow:
        "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--primary100)",
    },
  },
  wrapper: {
    overflow: "unset !important",
  },
  label: {
    fontSize: theme.fontSizes.lg,
    marginBottom: theme.spacing.xs,
    color: "var(--grey700)",
  },
  values: {
    height: "fit-content",
    minHeight: "fit-content",
    width: "100%",
  },
  value: {
    background: "#FFFFFF",
    border: "1px solid #D0D5DD",
    borderRadius: 6,
    fontSize: 14,
    color: theme.colors.grey[8],
    paddingLeft: 10,
  },
  defaultValueRemove: {
    color: "black",
  },
  searchInput: {
    height: 26,
    fontSize: 16,
  },
  icon: {
    fontSize: 18,
    fontWeight: 700,
    color: "var(--grey500)",
    width: 48,
    top: 13,
    bottom: "unset",
  },
  item: {
    fontWeight: 400,
    fontSize: 16,
    padding: theme.spacing.xs,
    color: theme.colors.navy[0],

    "&:hover": {
      color: theme.colors.navy[0],
    },
    "&[data-selected]": {
      "&, &:hover": {
        color: theme.colors.navy[1],
      },
    },
  },
}));

/**
 * Documentation: {@link https://mantine.dev/core/MultiSelect/}
 */
export function MultiSelect(props: CoreMultiSelectProps) {
  const { rightSection = <></>, searchable = true } = props;
  const { classes } = useStyles(searchable);

  return (
    <CoreMultiSelect
      icon={searchable ? <FontAwesomeIcon icon={["far", "search"]} /> : <></>}
      rightSection={rightSection}
      {...props}
      classNames={classes}
    />
  );
}
