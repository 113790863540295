import styled from "@emotion/styled/macro";

export const Container = styled.div<{ height?: string }>`
  position: relative;
  background-color: var(--white);
  border: 1px solid var(--grey200);
  border-radius: 12px;

  height: ${(props) => props.height && props.height};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;

  padding: 16px 24px;

  cursor: pointer;
`;

export const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  color: var(--grey600);
  background-color: var(--grey100);
  border: 6px solid var(--grey50);
  border-radius: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

export const MainTextBold = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: var(--primary700);
  margin-right: 4px;
`;

export const MainText = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: var(--grey600);
`;

export const SupportingText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: var(--grey600);
  margin-top: 4px;
`;
