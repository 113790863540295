import styled from "@emotion/styled";

export const ButtonGroup = styled.div<{ gap?: string }>`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.gap ? props.gap : "0px")};

  svg {
    pointer-events: none;
  }
`;

export const IconWrapper = styled.div`
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;

  img {
    filter: invert(0.3);
  }
`;
export const OptionItemGroup = styled.div`
  display: flex;
  gap: 8px;
`;
export const SelectOptionText = styled.div``;
