import axios from 'axios';
import { getRuntimeConfig } from '../config';

const runtimeConfig = getRuntimeConfig();
const baseURL = runtimeConfig.REACT_APP_ORIGIN;

// TODO: Edit change later
const axiosPageAPI = axios.create({
  baseURL: `${baseURL}/api/page/v1`,
  // baseURL: `http://localhost:8080`
});

const axiosImageAPI = axios.create({
  baseURL: `${baseURL}/api/image/v2`,
});

const axiosAccountAPI = axios.create({
  baseURL: `${baseURL}/api/account/v2`,
  withCredentials: true,
});

const axiosAuthAPI = axios.create({
  baseURL: `${baseURL}/api/auth/v2`,
  withCredentials: true,
});

const axiosContentAPI = axios.create({
  baseURL: `${baseURL}/api/v1`,
});

export {
  axiosAccountAPI,
  axiosAuthAPI,
  axiosContentAPI,
  axiosPageAPI,
  axiosImageAPI
};
