import { ComponentSpecProps } from "../../types";
import { CardListSection, FixedTitleCardListSection, DetailedCardListWithFilterBar } from "./seperate-component-spec/card-list";
import { HeroSection, HeroSectionWithTextBox } from "./seperate-component-spec/hero-section";
import { AutoFillHorizontalInformationSection, AutoFillVerticalInformationSection } from "./seperate-component-spec/information-section";
import { AutoFillHighlightTopicSection } from "./seperate-component-spec/other-components";
import {
  HorizontalImageSplitViewSection,
  SquareImageSplitViewSection,
  HorizontalVideoSplitViewSection,
  SquareVideoSplitViewSection,
  LogoSquareImageSplitViewSection,
  LinkSquareImageSplitViewSection,
  LinkSquareVideoSplitViewSection,
  AccordionHorizontalImageSplitViewSection,
  AccordionSquareImageSplitViewSection,
  SquareSlideSplitViewSection,
  AutoFillGallerySplitViewSection,
  AutoFillMapSplitViewSection,
  AutoFillHouseTypeSection,
} from "./seperate-component-spec/split-view-section";

export const componentSpecList: { [key: string]: ComponentSpecProps[] } = {
  standard: [
    HeroSection,
    HeroSectionWithTextBox,
    HorizontalImageSplitViewSection,
    SquareImageSplitViewSection,
    HorizontalVideoSplitViewSection,
    SquareVideoSplitViewSection,
    LogoSquareImageSplitViewSection,
    LinkSquareImageSplitViewSection,
    LinkSquareVideoSplitViewSection,
    AccordionHorizontalImageSplitViewSection,
    AccordionSquareImageSplitViewSection,
    SquareSlideSplitViewSection,
    AutoFillGallerySplitViewSection,
    AutoFillMapSplitViewSection,
    CardListSection,
    FixedTitleCardListSection,
    DetailedCardListWithFilterBar,
    AutoFillHorizontalInformationSection,
    AutoFillVerticalInformationSection,
    AutoFillHighlightTopicSection,
    AutoFillHouseTypeSection,
  ],
};
