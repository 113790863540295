import { ComponentSpecProps } from "../../../types";

export const HorizontalImageSplitViewSection: ComponentSpecProps = {
  name: 'Section แบ่งซ้าย-ขวาแบบภาพแนวนอน (Text)',
  componentType: 'HorizontalImageSplitViewSection',
  settingFields: {
    title: {
      displayName: 'หัวข้อหลัก',
      fieldName: 'title',
      type: 'text-editor',
      defaultValue: 'Section แบ่งครึ่งซ้าย-ขวา',
    },
    subtitle: {
      displayName: 'หัวข้อรอง',
      fieldName: 'subtitle',
      type: 'string',
      defaultValue: '',
    },
    description: {
      displayName: 'คำอธิบาย',
      fieldName: 'description',
      type: 'string',
      defaultValue: '',
    },
    mediaPosition: {
      displayName: 'ตำแหน่งของรูป',
      fieldName: 'mediaPosition',
      type: 'select',
      defaultValue: 'left',
      options: [
        {
          value: 'left',
          label: 'ซ้าย',
        },
        {
          value: 'right',
          label: 'ขวา',
        },
      ],
    },
    backgroundColor: {
      displayName: "สีพื้นหลัง",
      fieldName: 'backgroundColor',
      type: 'color-selector',
      defaultValue: '#FFFFFF',
      options: [{
        value: "#FFFFFF",
        label: "White (#FFFFFF)",
        key: "white-color-FFFFFF"
      },
      {
        value: "#F8F8F8",
        label: "White smoke (#F8F8F8)",
        key: "lighr-grey-color-FFFFFF"
      }
    ]
    },
    imgUrl: {
      displayName: 'รูปของ section',
      fieldName: 'imgUrl',
      type: 'image',
      defaultValue: '',
    },
  },
  settings: {
    title: 'Section แบ่งครึ่งซ้าย-ขวา',
    mediaShape: 'horizontal',
    mediaPosition: 'right',
  },
  required: {
    title: true,
  },
}

export const SquareImageSplitViewSection: ComponentSpecProps = {
  name: 'Section แบ่งซ้าย-ขวาแบบภาพจัตุรัส (Text)',
  componentType: 'SquareImageSplitViewSection',
  settingFields: {
    title: {
      displayName: 'หัวข้อหลัก',
      fieldName: 'title',
      type: 'text-editor',
      defaultValue: 'Section แบ่งครึ่งซ้าย-ขวา',
    },
    subtitle: {
      displayName: 'หัวข้อรอง',
      fieldName: 'subtitle',
      type: 'string',
      defaultValue: '',
    },
    description: {
      displayName: 'คำอธิบาย',
      fieldName: 'description',
      type: 'string',
      defaultValue: '',
    },
    mediaPosition: {
      displayName: 'ตำแหน่งของรูป',
      fieldName: 'mediaPosition',
      type: 'select',
      defaultValue: 'left',
      options: [
        {
          value: 'left',
          label: 'ซ้าย',
        },
        {
          value: 'right',
          label: 'ขวา',
        },
      ],
    },
    backgroundColor: {
      displayName: "สีพื้นหลัง",
      fieldName: 'backgroundColor',
      type: 'color-selector',
      defaultValue: '#FFFFFF',
      options: [{
        value: "#FFFFFF",
        label: "White (#FFFFFF)",
        key: "white-color-FFFFFF"
      },
      {
        value: "#F8F8F8",
        label: "White smoke (#F8F8F8)",
        key: "lighr-grey-color-FFFFFF"
      }
    ]
    },
    imgUrl: {
      displayName: 'รูปของ section',
      fieldName: 'imgUrl',
      type: 'image',
      defaultValue: '',
    },
  },
  settings: {
    title: 'Section แบ่งครึ่งซ้าย-ขวา',
    mediaShape: 'square',
    mediaPosition: 'right',
  },
  required: {
    title: true,
  },
}

export const HorizontalVideoSplitViewSection: ComponentSpecProps = {
  name: 'Section แบ่งซ้าย-ขวาแบบวิดีโอแนวนอน (Text)',
  componentType: 'HorizontalVideoSplitViewSection',
  settingFields: {
    title: {
      displayName: 'หัวข้อหลัก',
      fieldName: 'title',
      type: 'text-editor',
      defaultValue: 'Section แบ่งครึ่งซ้าย-ขวา',
    },
    subtitle: {
      displayName: 'หัวข้อรอง',
      fieldName: 'subtitle',
      type: 'string',
      defaultValue: '',
    },
    description: {
      displayName: 'คำอธิบาย',
      fieldName: 'description',
      type: 'string',
      defaultValue: '',
    },
    mediaPosition: {
      displayName: 'ตำแหน่งของรูป',
      fieldName: 'mediaPosition',
      type: 'select',
      defaultValue: 'left',
      options: [
        {
          value: 'left',
          label: 'ซ้าย',
        },
        {
          value: 'right',
          label: 'ขวา',
        },
      ],
    },
    backgroundColor: {
      displayName: "สีพื้นหลัง",
      fieldName: 'backgroundColor',
      type: 'color-selector',
      defaultValue: '#FFFFFF',
      options: [{
        value: "#FFFFFF",
        label: "White (#FFFFFF)",
        key: "white-color-FFFFFF"
      },
      {
        value: "#F8F8F8",
        label: "White smoke (#F8F8F8)",
        key: "lighr-grey-color-FFFFFF"
      }
    ]
    },
    videoSourceUrl: {
      displayName: 'รูปของ section',
      fieldName: 'videoSourceUrl',
      type: 'string',
      defaultValue: '',
    },
  },
  settings: {
    title: 'Section แบ่งครึ่งซ้าย-ขวา',
    mediaShape: 'horizontal',
    mediaPosition: 'right',
  },
  required: {
    title: true,
  },
}

export const SquareVideoSplitViewSection: ComponentSpecProps = {
  name: 'Section แบ่งซ้าย-ขวาแบบวิดีโอแนวนอน (Text)',
  componentType: 'SquareVideoSplitViewSection',
  settingFields: {
    title: {
      displayName: 'หัวข้อหลัก',
      fieldName: 'title',
      type: 'text-editor',
      defaultValue: 'Section แบ่งครึ่งซ้าย-ขวา',
    },
    subtitle: {
      displayName: 'หัวข้อรอง',
      fieldName: 'subtitle',
      type: 'string',
      defaultValue: '',
    },
    description: {
      displayName: 'คำอธิบาย',
      fieldName: 'description',
      type: 'string',
      defaultValue: '',
    },
    mediaPosition: {
      displayName: 'ตำแหน่งของรูป',
      fieldName: 'mediaPosition',
      type: 'select',
      defaultValue: 'left',
      options: [
        {
          value: 'left',
          label: 'ซ้าย',
        },
        {
          value: 'right',
          label: 'ขวา',
        },
      ],
    },
    backgroundColor: {
      displayName: "สีพื้นหลัง",
      fieldName: 'backgroundColor',
      type: 'color-selector',
      defaultValue: '#FFFFFF',
      options: [{
        value: "#FFFFFF",
        label: "White (#FFFFFF)",
        key: "white-color-FFFFFF"
      },
      {
        value: "#F8F8F8",
        label: "White smoke (#F8F8F8)",
        key: "lighr-grey-color-FFFFFF"
      }
    ]
    },
    videoSourceUrl: {
      displayName: 'รูปของ section',
      fieldName: 'videoSourceUrl',
      type: 'string',
      defaultValue: '',
    },
  },
  settings: {
    title: 'Section แบ่งครึ่งซ้าย-ขวา',
    mediaShape: 'square',
    mediaPosition: 'right',
  },
  required: {
    title: true,
  },
}

export const LogoSquareImageSplitViewSection: ComponentSpecProps = {
  name: 'Section แบ่งซ้าย-ขวาแบบภาพจัตุรัส (Logo)',
  componentType: 'LogoSquareImageSplitViewSection',
  settingFields: {
    title: {
      displayName: 'หัวข้อหลัก',
      fieldName: 'title',
      type: 'text-editor',
      defaultValue: 'Section แบ่งครึ่งซ้าย-ขวาสำหรับแสดงโลโก้',
    },
    subtitle: {
      displayName: 'หัวข้อรอง',
      fieldName: 'subtitle',
      type: 'string',
      defaultValue: '',
    },
    description: {
      displayName: 'คำอธิบาย',
      fieldName: 'description',
      type: 'string',
      defaultValue: '',
    },
    mediaPosition: {
      displayName: 'ตำแหน่งของรูป',
      fieldName: 'mediaPosition',
      type: 'select',
      defaultValue: 'left',
      options: [
        {
          value: 'left',
          label: 'ซ้าย',
        },
        {
          value: 'right',
          label: 'ขวา',
        },
      ],
    },
    backgroundColor: {
      displayName: "สีพื้นหลัง",
      fieldName: 'backgroundColor',
      type: 'color-selector',
      defaultValue: '#FFFFFF',
      options: [{
        value: "#FFFFFF",
        label: "White (#FFFFFF)",
        key: "white-color-FFFFFF"
      },
      {
        value: "#F8F8F8",
        label: "White smoke (#F8F8F8)",
        key: "lighr-grey-color-FFFFFF"
      }
    ]
    }
  },
  settings: {
    title: 'Section แบ่งครึ่งซ้าย-ขวาสำหรับแสดงโลโก้',
    mediaShape: 'square',
    mediaPosition: 'left',
  },
  childComponents: [],
  childComponentDefaultSpec: {
    name: 'แท็บ1',
    settingFields: {
      title: {
        displayName: 'ชื่อแท็บ',
        fieldName: 'title',
        type: 'string',
        defaultValue: 'ชื่อแท็บ',
      },
      imgUrl: {
        displayName: 'รูปของ section',
        fieldName: 'imgUrl',
        type: 'image',
        defaultValue: '',
      },
    },
    settings: {},
    dataSources: [],
    required: {
      title: true,
      imgUrl: true,
    },
  },
  required: {
    title: true,
  },
}

export const LinkSquareImageSplitViewSection: ComponentSpecProps = {
  name: 'Section แบ่งซ้าย-ขวาแบบภาพจัตุรัส (Link)',
  componentType: 'LinkSquareImageSplitViewSection',
  settingFields: {
    title: {
      displayName: 'หัวข้อหลัก',
      fieldName: 'title',
      type: 'text-editor',
      defaultValue: 'Section แบ่งซ้าย-ขวาแบบภาพจัตุรัส (Link)',
    },
    subtitle: {
      displayName: 'หัวข้อรอง',
      fieldName: 'subtitle',
      type: 'string',
      defaultValue: '',
    },
    description: {
      displayName: 'คำอธิบาย',
      fieldName: 'description',
      type: 'string',
      defaultValue: '',
    },
    mediaPosition: {
      displayName: 'ตำแหน่งของรูป',
      fieldName: 'mediaPosition',
      type: 'select',
      defaultValue: 'left',
      options: [
        {
          value: 'left',
          label: 'ซ้าย',
        },
        {
          value: 'right',
          label: 'ขวา',
        },
      ],
    },
    backgroundColor: {
      displayName: "สีพื้นหลัง",
      fieldName: 'backgroundColor',
      type: 'color-selector',
      defaultValue: '#FFFFFF',
      options: [{
        value: "#FFFFFF",
        label: "White (#FFFFFF)",
        key: "white-color-FFFFFF"
      },
      {
        value: "#F8F8F8",
        label: "White smoke (#F8F8F8)",
        key: "lighr-grey-color-FFFFFF"
      }
    ]
    },
    imgUrl: {
      displayName: 'รูปของ section',
      fieldName: 'imgUrl',
      type: 'image',
      defaultValue: '',
    },
  },
  settings: {
    title: 'Section แบ่งซ้าย-ขวาแบบภาพจัตุรัส (Link)',
    mediaShape: 'square'
  },
  required: {
    title: true,
  },
  dataSources: [],
}

export const LinkSquareVideoSplitViewSection: ComponentSpecProps = {
  name: 'Section แบ่งซ้าย-ขวาแบบวิดีโอจัตุรัส (Link)',
  componentType: 'LinkSquareVideoSplitViewSection',
  settingFields: {
    title: {
      displayName: 'หัวข้อหลัก',
      fieldName: 'title',
      type: 'text-editor',
      defaultValue: 'Section แบ่งซ้าย-ขวาแบบวิดีโอจัตุรัส (Link)',
    },
    subtitle: {
      displayName: 'หัวข้อรอง',
      fieldName: 'subtitle',
      type: 'string',
      defaultValue: '',
    },
    description: {
      displayName: 'คำอธิบาย',
      fieldName: 'description',
      type: 'string',
      defaultValue: '',
    },
    videoSourceUrl: {
      displayName: 'ลิงก์ source video',
      fieldName: 'videoSourceUrl',
      type: 'string',
      defaultValue: '',
    },
    mediaPosition: {
      displayName: 'ตำแหน่งของรูป',
      fieldName: 'mediaPosition',
      type: 'select',
      defaultValue: 'left',
      options: [
        {
          value: 'left',
          label: 'ซ้าย',
        },
        {
          value: 'right',
          label: 'ขวา',
        },
      ],
    },
    backgroundColor: {
      displayName: "สีพื้นหลัง",
      fieldName: 'backgroundColor',
      type: 'color-selector',
      defaultValue: '#FFFFFF',
      options: [{
        value: "#FFFFFF",
        label: "White (#FFFFFF)",
        key: "white-color-FFFFFF"
      },
      {
        value: "#F8F8F8",
        label: "White smoke (#F8F8F8)",
        key: "lighr-grey-color-FFFFFF"
      }
    ]
    }
  },
  settings: {
    title: 'Section แบ่งซ้าย-ขวาแบบวิดีโอจัตุรัส (Link)',
    mediaShape: 'square'
  },
  required: {
    title: true,
  },
  dataSources: [],
}

export const AccordionHorizontalImageSplitViewSection: ComponentSpecProps = {
  name: 'Section แบ่งซ้าย-ขวาแบบภาพจัตุรัส (Accordion)',
  componentType: 'AccordionHorizontalImageSplitViewSection',
  settingFields: {
    title: {
      displayName: 'ชื่อ',
      fieldName: 'title',
      type: 'text-editor',
      defaultValue: 'Section แบ่งซ้าย-ขวาแบบภาพจัตุรัส (Accordion)',
    },
    subtitle: {
      displayName: 'หัวข้อรอง',
      fieldName: 'subtitle',
      type: 'string',
      defaultValue: '',
    },
    description: {
      displayName: 'คำอธิบาย',
      fieldName: 'description',
      type: 'string',
      defaultValue: '',
    },
    mediaPosition: {
      displayName: 'ตำแหน่งของรูป',
      fieldName: 'mediaPosition',
      type: 'select',
      defaultValue: 'left',
      options: [
        {
          value: 'left',
          label: 'ซ้าย',
        },
        {
          value: 'right',
          label: 'ขวา',
        },
      ],
    },
    backgroundColor: {
      displayName: "สีพื้นหลัง",
      fieldName: 'backgroundColor',
      type: 'color-selector',
      defaultValue: '#FFFFFF',
      options: [{
        value: "#FFFFFF",
        label: "White (#FFFFFF)",
        key: "white-color-FFFFFF"
      },
      {
        value: "#F8F8F8",
        label: "White smoke (#F8F8F8)",
        key: "lighr-grey-color-FFFFFF"
      }
    ]
    }
  },
  settings: {
    title: 'Section แบ่งซ้าย-ขวาแบบภาพจัตุรัส (Accordion)',
    mediaShape: 'square',
    mediaPosition: 'left',
  },
  childComponents: [],
  childComponentDefaultSpec: {
    name: 'แท็บ1',
    settingFields: {
      title: {
        displayName: 'ชื่อแท็บ',
        fieldName: 'title',
        type: 'string',
        defaultValue: 'ชื่อแท็บ',
      },
      subtitle: {
        displayName: 'หัวข้อรอง',
        fieldName: 'subtitle',
        type: 'string',
        defaultValue: '',
      },
      description: {
        displayName: 'คำอธิบาย',
        fieldName: 'description',
        type: 'string',
        defaultValue: '',
      },
      imgUrl: {
        displayName: 'รูปของ section',
        fieldName: 'imgUrl',
        type: 'image',
        defaultValue: '',
      },
    },
    settings: {},
    dataSources: [],
    required: {
      title: true,
      imgUrl: true,
    },
  },
  required: {
    title: true,
  },
}

export const AccordionSquareImageSplitViewSection: ComponentSpecProps = {
  name: 'Section แบ่งซ้าย-ขวาแบบภาพแนวนอน (Accordion)',
  componentType: 'AccordionSquareImageSplitViewSection',
  settingFields: {
    title: {
      displayName: 'ชื่อ',
      fieldName: 'title',
      type: 'text-editor',
      defaultValue: 'Section แบ่งซ้าย-ขวาแบบภาพแนวนอน (Accordion)',
    },
    subtitle: {
      displayName: 'หัวข้อรอง',
      fieldName: 'subtitle',
      type: 'string',
      defaultValue: '',
    },
    description: {
      displayName: 'คำอธิบาย',
      fieldName: 'description',
      type: 'string',
      defaultValue: '',
    },
    mediaPosition: {
      displayName: 'ตำแหน่งของรูป',
      fieldName: 'mediaPosition',
      type: 'select',
      defaultValue: 'left',
      options: [
        {
          value: 'left',
          label: 'ซ้าย',
        },
        {
          value: 'right',
          label: 'ขวา',
        },
      ],
    },
    backgroundColor: {
      displayName: "สีพื้นหลัง",
      fieldName: 'backgroundColor',
      type: 'color-selector',
      defaultValue: '#FFFFFF',
      options: [{
        value: "#FFFFFF",
        label: "White (#FFFFFF)",
        key: "white-color-FFFFFF"
      },
      {
        value: "#F8F8F8",
        label: "White smoke (#F8F8F8)",
        key: "lighr-grey-color-FFFFFF"
      }
    ]
    }
  },
  settings: {
    title: 'Section แบ่งซ้าย-ขวาแบบภาพแนวนอน (Accordion)',
    mediaShape: 'horizontal',
    mediaPosition: 'left',
  },
  childComponents: [],
  childComponentDefaultSpec: {
    name: 'แท็บ1',
    settingFields: {
      title: {
        displayName: 'ชื่อแท็บ',
        fieldName: 'title',
        type: 'string',
        defaultValue: 'ชื่อแท็บ',
      },
      subtitle: {
        displayName: 'หัวข้อรอง',
        fieldName: 'subtitle',
        type: 'string',
        defaultValue: '',
      },
      description: {
        displayName: 'คำอธิบาย',
        fieldName: 'description',
        type: 'string',
        defaultValue: '',
      },
      imgUrl: {
        displayName: 'รูปของ section',
        fieldName: 'imgUrl',
        type: 'image',
        defaultValue: '',
      },
    },
    settings: {},
    dataSources: [],
    required: {
      title: true,
      imgUrl: true,
    },
  },
  required: {
    title: true,
  },
}

export const SquareSlideSplitViewSection: ComponentSpecProps = {
  name: 'Section สไลด์ภาพแบบจัตุรัส',
  componentType: 'SquareSlideSplitViewSection',
  settingFields: {
    title: {
      displayName: 'ชื่อ',
      fieldName: 'title',
      type: 'text-editor',
      defaultValue: 'Section สไลด์ภาพแบบจัตุรัส',
    },
    subtitle: {
      displayName: 'หัวข้อรอง',
      fieldName: 'subtitle',
      type: 'string',
      defaultValue: '',
    },
    description: {
      displayName: 'คำอธิบาย',
      fieldName: 'description',
      type: 'string',
      defaultValue: '',
    },
    mediaPosition: {
      displayName: 'ตำแหน่งของรูป',
      fieldName: 'mediaPosition',
      type: 'select',
      defaultValue: 'left',
      options: [
        {
          value: 'left',
          label: 'ซ้าย',
        },
        {
          value: 'right',
          label: 'ขวา',
        },
      ],
    },
    backgroundColor: {
      displayName: "สีพื้นหลัง",
      fieldName: 'backgroundColor',
      type: 'color-selector',
      defaultValue: '#FFFFFF',
      options: [{
        value: "#FFFFFF",
        label: "White (#FFFFFF)",
        key: "white-color-FFFFFF"
      },
      {
        value: "#F8F8F8",
        label: "White smoke (#F8F8F8)",
        key: "lighr-grey-color-FFFFFF"
      }
    ]
    }
  },
  settings: {
    title: 'Section สไลด์ภาพแบบจัตุรัส',
    mediaShape: 'square',
    mediaPosition: 'left',
  },
  childComponents: [],
  childComponentDefaultSpec: {
    name: 'Slide',
    settingFields: {
      isShowOverlayBox: {
        displayName: 'แสดงกล่องข้อความ',
        fieldName: 'isShowOverlayBox',
        type: 'boolean',
        defaultValue: false,
      },
      title: {
        displayName: 'หัวข้อหลักของกล่องข้อความ',
        fieldName: 'title',
        type: 'string',
        defaultValue: 'หัวข้อหลัก',
      },
      description: {
        displayName: 'คำอธิบายของกล่องข้อความ',
        fieldName: 'description',
        type: 'string',
        defaultValue: '',
      },
      imgUrl: {
        displayName: 'รูปของ slide',
        fieldName: 'imgUrl',
        type: 'image',
        defaultValue: '',
      },
      overlayBoxBackgroundColor: {
        displayName: "สีพื้นหลังกล่องข้อความ",
        fieldName: 'backgroundColor',
        type: 'color-selector',
        defaultValue: '#FFFFFF',
        options: [{
          value: "#FFFFFF",
          label: "White (#FFFFFF)",
          key: "white-color-FFFFFF"
        },
        {
          value: "#F8F8F8",
          label: "White smoke (#F8F8F8)",
          key: "lighr-grey-color-FFFFFF"
        }
        ]
      }
    },
    settings: {},
    required: {
      title: true,
      imgUrl: true,
    },
  },
  required: {
    title: true,
  },
}

export const AutoFillGallerySplitViewSection: ComponentSpecProps = {
  name: 'Section สำหรับแสดงแกลเลอรี',
  componentType: 'AutoFillGallerySplitViewSection',
  settingFields: {
    title: {
      displayName: 'หัวข้อหลัก',
      fieldName: 'title',
      type: 'text-editor',
      defaultValue: 'Section สำหรับแสดงแกลเลอรี',
    },
    subtitle: {
      displayName: 'หัวข้อรอง',
      fieldName: 'subtitle',
      type: 'string',
      defaultValue: '',
    },
    description: {
      displayName: 'คำอธิบาย',
      fieldName: 'description',
      type: 'string',
      defaultValue: '',
    },
    mediaPosition: {
      displayName: 'ตำแหน่งของรูป',
      fieldName: 'mediaPosition',
      type: 'select',
      defaultValue: 'left',
      options: [
        {
          value: 'left',
          label: 'ซ้าย',
        },
        {
          value: 'right',
          label: 'ขวา',
        },
      ],
    },
    backgroundColor: {
      displayName: "สีพื้นหลัง",
      fieldName: 'backgroundColor',
      type: 'color-selector',
      defaultValue: '#FFFFFF',
      options: [{
        value: "#FFFFFF",
        label: "White (#FFFFFF)",
        key: "white-color-FFFFFF"
      },
      {
        value: "#F8F8F8",
        label: "White smoke (#F8F8F8)",
        key: "lighr-grey-color-FFFFFF"
      }
    ]
    }
  },
  settings: {
    title: 'Section สำหรับแสดงแกลเลอรี',
    mediaShape: 'horizontal',
    mediaPosition: 'right',
  },
  required: {
    title: true,
  },
}

export const AutoFillMapSplitViewSection: ComponentSpecProps = {
  name: 'Section สำหรับแสดงแผนที่',
  componentType: 'AutoFillMapSplitViewSection',
  settingFields: {
    title: {
      displayName: 'หัวข้อหลัก',
      fieldName: 'title',
      type: 'text-editor',
      defaultValue: 'Section สำหรับแสดงแผนที่',
    },
    subtitle: {
      displayName: 'หัวข้อรอง',
      fieldName: 'subtitle',
      type: 'string',
      defaultValue: '',
    },
    description: {
      displayName: 'คำอธิบาย',
      fieldName: 'description',
      type: 'string',
      defaultValue: '',
    },
    mediaPosition: {
      displayName: 'ตำแหน่งของแผนที่',
      fieldName: 'mediaPosition',
      type: 'select',
      defaultValue: 'left',
      options: [
        {
          value: 'left',
          label: 'ซ้าย',
        },
        {
          value: 'right',
          label: 'ขวา',
        },
      ],
    },
    backgroundColor: {
      displayName: "สีพื้นหลัง",
      fieldName: 'backgroundColor',
      type: 'color-selector',
      defaultValue: '#FFFFFF',
      options: [{
        value: "#FFFFFF",
        label: "White (#FFFFFF)",
        key: "white-color-FFFFFF"
      },
      {
        value: "#F8F8F8",
        label: "White smoke (#F8F8F8)",
        key: "lighr-grey-color-FFFFFF"
      }
    ]
    }
  },
  settings: {
    title: 'Section สำหรับแสดงแผนที่',
    mediaShape: 'horizontal',
    mediaPosition: 'right',
  },
  required: {
    title: true,
  },
}

export const AutoFillHouseTypeSection: ComponentSpecProps = {
  name: 'Section สำหรับแสดงแบบบ้านโครงการ',
  componentType: 'AutoFillHouseTypeSection',
  settingFields: {
    title: {
      displayName: 'หัวข้อหลัก',
      fieldName: 'title',
      type: 'text-editor',
      defaultValue: 'House Type แบบบ้านโครงการ',
    },
  },
  settings: {
    title: 'House Type แบบบ้านโครงการ',
  },
  required: {
    title: false,
  },
}
