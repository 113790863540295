import styled from "@emotion/styled/macro";
import React from "react";

export const ColorPreview = styled.div<{ bgValue?: string, error?:React.ReactNode }>`
  width: 100%;
  height: 100%;
  /* background-color: ${(props) =>
    props.bgValue && props.bgValue?.charAt(0) === "#"
      ? props.bgValue
      : "#" + props.bgValue}; */
  background-color: ${(props) => props.bgValue};
  border: ${props => props.error ? "1px solid var(--error500)" : "1px solid var(--grey300)"};
  border-radius: 8px 0px 0px 8px;
  display: flex;
  ustify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

export const EmptyState = styled.div`
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 140%;
    height: 1px;
    background-color: var(--grey200);
    transform: translate(-50%, -50%) rotate(-43deg);
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 140%;
    height: 1px;
    background-color: var(--grey200);
    transform: translate(-50%, -50%) rotate(43deg);
  }
`;

export const InfoText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey600);
  margin-top: 6px;
`;
