import styled from '@emotion/styled'

export const NavigationSidebarContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const LogoImageContainer = styled.div`
  margin: 16px 0 0 0;
  padding: 0 24px;
`


export const MenuContainer = styled.div`
  margin-top: 24px;
  overflow-y: auto;
  
  > * {
    margin-bottom: 4px;
  }
`

export const UserContainer = styled.div`
  border-top: 1px solid #EAECF0;
  padding-top: 24px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
`

export const UserImage = styled.div`
`

export const UserInformation = styled.div`
  margin-left: 8px;
`

export const UserPrimaryInformation = styled.div`
  color: var(--grey700);
  font-weight: 600;
`

export const UserSecondaryInformation = styled.div`
  color: #475467;
`

export const LogoutIcon = styled.div`
  font-size: 20px;
  margin-left: auto;
`

export const SubMenuContainer = styled.div`
  margin-top: auto;
  > * {
    margin-bottom: 4px;
  }
`