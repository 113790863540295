import { IBrand } from "./brand";
import { IFacility } from "./facility";
import { IEmbeddedMedia } from "./image";
import { ILocation } from "./location";
import { INearby } from "./nearby";
import { ITerm } from "./term";
import { UnitTypeForProject } from "./unitType";

export interface IPaginateProject {
  data: IProject[];
  currentPage: number;
  from: number;
  perPage: number;
  to: number;
  total: number;
}

export interface IProject {
  id: string;
  languageCode: string;
  name: string;
  displayedLocationName: string;
  excerpt: string | null;
  description: string | null;
  seoTitle: string | null;
  seoDescription: string | null;
  ogTitle: string | null;
  ogDescription: string | null;
  nearbys: INearby[];
  facilities: IFacility[];
  slugCode: string;
  projectCode: string;
  price: price;
  address: address;
  locationIds: string[];
  locations: ILocation[];
  brandId: string | null;
  brand: IBrand;
  // productTypeId: string;
  // productType: { [key: string]: any };
  map: IEmbeddedMedia | null;
  attachments: IEmbeddedMedia[];
  thumbnails: IEmbeddedMedia[];
  zoneId: string;
  zone: ITerm;
  termIds: TermIds;
  terms: Terms;
  unitTypeIds: string[];
  masterPlans: IEmbeddedMedia[];
  galleries: IEmbeddedMedia[];
  banners: IEmbeddedMedia[];
  logo: IEmbeddedMedia | null;
  pageId: string;
  openGraphImage: IEmbeddedMedia | null;
  visibilityStatus: string;
  publishStatus: string;
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
  oxygenProjectId: string | null;
  altName: string;
}

export interface IProjectEditable
  extends Omit<
    IProject,
    | "id"
    | "locations"
    | "brand"
    | "projectType"
    | "terms"
    | "zone"
    | "publishedAt"
    | "createdAt"
    | "updatedAt"
  > {}

export interface IUpdateProjectPayload {
  projectId: string;
  payload: IProjectEditable;
}

export type price = {
  minValue: number;
  maxValue: number;
  unit: string | null;
  currency: string | null;
};

export type address = {
  type: string,
  coordinates: number[], //long, lat
};

export type thumbnail = {
  id: string;
  type: string;
  url: string;
};

export type TermIds = {
  tagIds: string[];
  badgeIds: string[];
};

export type Terms = {
  tags: ITerm[];
  badges: ITerm[];
};

export type visibility = {
  status: string;
};

export type ProjectUnitTypeData = {
  unitTypeIds: string[];
  defaultUnitTypes: UnitTypeForProject[];
  availableUnitTypes: UnitTypeForProject[];
};

export type ProjectQueryUnitTypeParam = {
  id: string;
  filter: { lang?: string };
};

export const initProject = {
  languageCode: "th",
  name: "",
  displayedLocationName: "",
  excerpt: "",
  description: "",
  seoTitle: "",
  seoDescription: "",
  ogTitle: "",
  ogDescription: "",
  facilities: [],
  nearbys: [],
  slugCode: "",
  projectCode: "",
  price: {
    minValue: 0,
    maxValue: 0,
    unit: "ล้าน",
    currency: "บาท",
  },
  address: {
    type: 'Point',
    coordinates: [],
  },
  unitTypeIds: [],
  locationIds: [],
  brandId: null,
  logo: null,
  map: null,
  thumbnails: [],
  banners: [],
  pageId: "",
  // projectTypeId: "",
  attachments: [],
  zoneId: "",
  termIds: {
    tagIds: [],
    badgeIds: [],
  },
  masterPlans: [],
  galleries: [],
  openGraphImage: null,
  publishStatus: "draft",
  visibilityStatus: "public",
  oxygenProjectId: null,
  altName: ""
};
