import {
  DefaultProps,
  MantineStyleSystemSize,
  Modal as CoreModal,
  ModalStylesParams,
  createStyles,
} from "@mantine/core";
import {
  ModalStylesNames,
  ModalProps as CoreModalProps,
} from "@mantine/core/lib/Modal/Modal";
import { Button, ButtonStyles } from "../../atoms/button/Button";
import {
  ButtonWrapper,
  Description,
  ModalBody,
  ModalButtonGroup,
  ModalFooter,
} from "./Modal.style";

const useStyles = createStyles(
  (
    theme,
    {
      description,
      closeButtonSize,
      fullScreen
    }: { description?: string; closeButtonSize?: string; fullScreen?:boolean }
  ) => ({
    title: {
      fontSize: "18px",
      color: "var(--grey900)",
      fontWeight: 600,
    },
    close: {
      color: "var(--grey500)",

      "> svg": {
        width: closeButtonSize ? closeButtonSize : "24px",
        height: closeButtonSize ? closeButtonSize : "24px",
      },
    },
    header: {
      marginBottom: 0,
      padding: description ? "24px 24px 0px 24px" : "24px",
      marginRight: 0,
    },
    inner: {
      padding: 0,
    },
    modal: {
      maxHeight: fullScreen ? "100vh" : "90vh",
      overflow: "hidden",
      padding: "0 !important",
      borderRadius: fullScreen ? 0 : 12,
    },
  })
);

export interface ModalProps
  extends Omit<
      DefaultProps<ModalStylesNames, ModalStylesParams>,
      MantineStyleSystemSize
    >,
    CoreModalProps {
  description?: string;
  closeButtonSize?: string;
  button?: React.ReactElement;
  space?: string;
  modalFooter?: React.ReactElement;
  primaryButtonStyle?: ButtonStyles;
  primaryButtonTitle?: string;
  isDisablePrimaryButton?: boolean;
  secondaryButtonTitle?: string;
  onClickPrimaryButton?: () => void;
  onClickSecondaryButton?: () => void;
  onClose: () => void;
}

export function Modal(props: ModalProps) {
  const {
    description,
    closeButtonSize,
    onClose,
    modalFooter,
    opened,
    button,
    title,
    children,
    onClickPrimaryButton = () => {},
    onClickSecondaryButton = () => {},
    primaryButtonTitle = "",
    primaryButtonStyle = "primary",
    secondaryButtonTitle = "",
    isDisablePrimaryButton = false,
    ...rest
  } = props;
  const { classes } = useStyles({ description, closeButtonSize, ...rest });

  const ModalFooterElement = () => {
    if (modalFooter) {
      return <ModalFooter>{modalFooter}</ModalFooter>;
    }
    if (primaryButtonTitle || secondaryButtonTitle) {
      return (
        <ModalFooter>
          <ModalButtonGroup>
            {secondaryButtonTitle && (
              <ButtonWrapper>
                {secondaryButtonTitle && (
                  <Button
                    buttonStyle="outline-light"
                    type="button"
                    onClick={onClickSecondaryButton}
                    fullWidth
                  >
                    {secondaryButtonTitle}
                  </Button>
                )}
              </ButtonWrapper>
            )}
            {primaryButtonTitle && (
              <ButtonWrapper>
                {primaryButtonTitle && (
                  <Button
                    buttonStyle={primaryButtonStyle}
                    type="submit"
                    onClick={onClickPrimaryButton}
                    fullWidth
                    disabled={isDisablePrimaryButton}
                  >
                    {primaryButtonTitle}
                  </Button>
                )}
              </ButtonWrapper>
            )}
          </ModalButtonGroup>
        </ModalFooter>
      );
    }
  };

  return (
    <>
      <CoreModal
        {...rest}
        centered
        opened={opened}
        onClose={onClose}
        title={title}
        classNames={classes}
        // overflow={'inside'}
      >
        {description && <Description>{description}</Description>}
        <ModalBody isHasFooter={Boolean(primaryButtonTitle || secondaryButtonTitle)}>{children}</ModalBody>
        {ModalFooterElement()}
      </CoreModal>
      {button}
    </>
  );
}
