import styled from "@emotion/styled/macro";

export const Page = styled.div`
  display: flex;
`;

export const SidebarContainer = styled.div`
  flex: 1 0 280px;
  border-right: 1px solid #eaecf0;
  height: 100vh;
`;

export const ContentContainer = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  background: #fcfcfd;

  /* max-height: 100vh;
  overflow: auto; */
`;
