import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, useEffect, useState } from "react";

import { Button } from "../../atoms/button/Button";
import { MultiSelect } from "../../atoms/multiSelect/MultiSelect";
import { Select } from "../../atoms/select/Select";
import { TextArea } from "../../atoms/textArea/TextArea";
import { TextInput } from "../../atoms/textInput/TextInput";
import { ContentDivider } from "../../molecules/contentDivider/ContentDivider";
import { PageHeader } from "../../organisms/pageHeader/PageHeader";
import { Section } from "../../organisms/section/Section";
import {
  fetchProjectById,
  fetchProjectUnitType,
  projectSelector,
  resetProjectDetail,
  updateProjectPublishStatus,
  updateProjectVisibilityStatus,
} from "../../store/features/project";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  IProjectEditable,
  IMediaResponse,
  SelectItemProps,
  initProject,
  IEmbeddedMedia,
  currency,
  priceUnit,
} from "../../types";
import {
  onHandleChangeArrayAttachment,
  onHandleChangeArrayImage,
  onHandleChangeEditableData,
} from "../../utils/dataService";
import { formatFullDateTime } from "../../utils/format";
import { SeoSection } from "../../organisms/section/seoSection/SeoSection";
import { brandSelector, fetchBrands } from "../../store/features/brand";
import {
  fetchProductTypeSelectItems,
  productTypeSelector,
} from "../../store/features/productType";
import {
  fetchLocationSelectItems,
  locationSelector,
} from "../../store/features/location";
import { badgeSelector, fetchAllBadge } from "../../store/features/badge";
import {
  addNewTag,
  createNewTag,
  fetchAllTag,
  tagSelector,
} from "../../store/features/tag";
import { ImageInput } from "../../atoms/imageInput/ImageInput";
import { SectionFacility } from "../../organisms/section/sectionFacility/SectionFacility";
import { SectionMedia } from "../../organisms/section/sectionMedia/SectionMedia";
import { SectionNearby } from "../../organisms/section/sectionNearby/SectionNearby";
import { fetchAllFacilityType } from "../../store/features/facility";
import { mapImageInputProjectPage } from "../../types/mapMediaSection";
import { fetchAllZone, zoneSelector } from "../../store/features/zone";
import {
  ContentContainer,
  ContentHolder,
  ContentWrapper,
  FlexContainer,
  MediaInputWrapper,
  ScrollWrapper,
} from "../../globalStyle/styles/ManagePage.style";
import { ButtonGroup } from "./ManageProject.style";
import { onCastIUploadDataToEmbeddedImageData } from "../../utils/castType";
import { ConfirmModal } from "../../organisms/confirmModal/ConfirmModal";
import { SidePanel } from "../../organisms/sidePanel/SidePanel";
import { SidePanelSection } from "../../pages/createProject/CreateProject.style";
import { Loader } from "../../atoms/loading/loader/Loader";
import { SectionAttachment } from "../../organisms/section/sectionAttachments/SectionAttachments";
import { mapAttachmentProjectInputData } from "../../types/mapAttachmentSection";
import { SectionGallery } from "../../organisms/section/sectionGallery/SectionGallery";
import { SectionUnitType } from "../../organisms/section/sectionUnitType/SectionUnitType";
import { validateSlugCode } from "../../utils/validation";
import {
  SidePanelTextGroup,
  SubText,
  SubTitle,
} from "../../organisms/projectSidePanel/ProjectSidePanel.style";
import { useNavigate } from "react-router-dom";
import {
  fetchCurrentPage,
  pageLoadingSelector,
  pageSelector,
  resetCurrentPage,
} from "../../store/features/page";

type SelectItems = { value: string; label: string };
interface ManageProjectProps {
  projectId?: string;
  pageTitle: string;
  submitButtonTitle?: string;
  isShowBrand?: boolean;
  isShowProjectType?: boolean;
  isShowUpdatedAt?: boolean;
  isCreatePage?: boolean;
  isShowPreviewPage?: boolean;
  onCreatePage?: (data: IProjectEditable) => void;
  onClickSubmit?: (data: IProjectEditable) => void;
  onClickDelete?: () => void;
  onClickCreate?: (data: IProjectEditable) => void;
}

export function ManageProject(props: ManageProjectProps) {
  const {
    projectId,
    pageTitle,
    submitButtonTitle,
    isCreatePage,
    isShowPreviewPage,
    onClickSubmit,
    onCreatePage,
    onClickDelete,
    onClickCreate,
    isShowBrand,
    isShowProjectType,
    isShowUpdatedAt,
  } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentProject: project } = useAppSelector(projectSelector);
  const { id: currentPageId } = useAppSelector(pageSelector);
  const isPageLoading = useAppSelector(pageLoadingSelector);
  const { brandSelectItems, brands } = useAppSelector(brandSelector);
  const { productTypeSelectItems } = useAppSelector(productTypeSelector);
  const { locationSelectItems } = useAppSelector(locationSelector);
  const { badges } = useAppSelector(badgeSelector);
  const { tags, newTag } = useAppSelector(tagSelector);
  const { zones } = useAppSelector(zoneSelector);
  const { unitTypes: projectUnitTypeList } = useAppSelector(projectSelector);

  const [editableProjectData, setEditableProjectData] =
    useState<IProjectEditable | null>(null);

  // Tags
  const [allTag, setAllTag] = useState<SelectItemProps[]>([]);
  const [tagSearchValue, setTagSearchValue] = useState<string>("");

  // Publish Status
  const [publishedData] = useState<SelectItems[]>([
    { value: "published", label: "Published" },
    { value: "draft", label: "Draft" },
    // { value: "scheduled", label: "Scheduled" },
  ]);

  // Modal
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isShowCreatePageModal, setIsShowCreatePageModal] =
    useState<boolean>(false);

  // error
  const [slugError, setSlugError] = useState<string>("");
  const [projectCodeError, setProjectCodeError] = useState<string>("");
  const [zoneError, setZoneError] = useState<string>("");
  const [brandError, setBrandError] = useState<string>("");
  const [displayedLocationError, setDisplayedLocationError] =
    useState<string>("");

  function onHandleValidateSlugCode(slugCode: string) {
    if (slugCode.length === 0) {
      return;
    }
    if (!validateSlugCode(slugCode.charAt(0))) {
      setSlugError(
        "Invalid slug. Slug should start with lowercase or numeric character only."
      );
      return;
    }
    if (!validateSlugCode(slugCode)) {
      setSlugError(
        "Invalid slug. Slug should be lowercase, numeric, or dash character."
      );
      return;
    }
  }

  // onKeydown handle
  async function onHandleCreateTag(
    e: React.KeyboardEvent<HTMLInputElement>,
    query: string
  ) {
    const isTagsContain = tags.some((item) => item.label === query);
    if (e.key.toLowerCase() === "enter" && query && !isTagsContain) {
      e.preventDefault();
      await dispatch(createNewTag({ label: query, type: "tag" }));
      dispatch(addNewTag());
      setTagSearchValue("");
    }
  }

  function onHandleChangeProjectData(path: string, value: any) {
    setEditableProjectData(
      onHandleChangeEditableData(editableProjectData, path, value)
    );
  }

  function onHandleChangeDataForSpecialSection(path: string, value: any) {
    if (isCreatePage) {
      setEditableProjectData(
        onHandleChangeEditableData(editableProjectData, path, value)
      );
    } else {
      if (onClickSubmit) {
        onClickSubmit(
          onHandleChangeEditableData(editableProjectData, path, value)
        );
      }
    }
  }

  function onHandleChangeImages(
    img: IMediaResponse | null,
    device: string | null,
    key: string,
    useFor?: string | null
  ) {
    if (editableProjectData) {
      const newData = onHandleChangeArrayImage(
        editableProjectData,
        img,
        device,
        key,
        useFor
      );
      if (isCreatePage) {
        setEditableProjectData(newData);
      } else {
        if (onClickSubmit) {
          onClickSubmit(newData);
        }
      }
    }
  }

  function onHandleChangeAttachment(
    useFor: string,
    key: string,
    data?: IEmbeddedMedia | null
  ) {
    if (data) {
      const newData = onHandleChangeArrayAttachment(
        editableProjectData,
        data,
        useFor,
        key
      );
      if (isCreatePage) {
        setEditableProjectData(newData);
      } else {
        if (onClickSubmit) {
          onClickSubmit(newData);
        }
      }
    }
  }

  async function onHandleDeleteAttachment(useFor: string) {
    const cloned = _.cloneDeep(editableProjectData);
    if (cloned) {
      const removeIndex = cloned.attachments.findIndex((data) => {
        return data?.useFor === useFor;
      });
      if (removeIndex >= 0) {
        cloned.attachments.splice(removeIndex, 1);
        if (onClickSubmit) {
          onClickSubmit(cloned);
        }
        setEditableProjectData(cloned);
      }
    }
  }

  function onHandleSearchTag(e: string) {
    setTagSearchValue(e);
  }

  async function onHandleUpdatePublishStatus(projectId: string, value: string) {
    await dispatch(
      updateProjectPublishStatus({
        projectId: projectId,
        payload: { publishStatus: value },
      })
    );
  }

  async function onHandleUpdateVisibilityStatus(
    projectId: string,
    value: string
  ) {
    await dispatch(
      updateProjectVisibilityStatus({
        projectId: projectId,
        payload: { visibilityStatus: value },
      })
    );
  }

  function checkIsValidData(data: IProjectEditable) {
    return (
      data?.zoneId &&
      data?.brandId &&
      data?.displayedLocationName &&
      data?.slugCode &&
      data?.projectCode
    );
  }

  function onHandleClickCreateProject() {
    if (onClickCreate && editableProjectData) {
      if (!editableProjectData?.zoneId) {
        setZoneError("Please select a zone.");
      }
      if (!editableProjectData?.brandId) {
        setBrandError("Please select a brand.");
      }
      if (!editableProjectData?.displayedLocationName) {
        setDisplayedLocationError("Please enter a location.");
      }
      if (!editableProjectData?.slugCode) {
        setSlugError("Please enter a slug code");
      }
      if (!editableProjectData?.projectCode) {
        setProjectCodeError("Please enter a project code");
      }
      if (checkIsValidData(editableProjectData))
        onClickCreate(editableProjectData);
    }
  }

  // API Handler
  async function loadData() {
    if (projectId) {
      dispatch(fetchProjectById(projectId));
    }
  }

  async function loadDataThatRelateToProjectLanguageCode() {
    if (editableProjectData || isCreatePage) {
      const projectLang = editableProjectData?.languageCode || "th";
      if (projectId) {
        Promise.all([
          dispatch(
            fetchProjectUnitType({
              id: projectId,
              filter: { lang: projectLang },
            })
          ),
          dispatch(fetchCurrentPage(`resource:${projectId}`)),
        ]);
      }
      Promise.all([
        dispatch(fetchLocationSelectItems({ languageCode: projectLang })),
        // dispatch(fetchBrandSelectItems({ languageCode: projectLang })),
        dispatch(fetchBrands({ lang: projectLang, limit: "all" })),
        dispatch(
          fetchProductTypeSelectItems({
            languageCode: projectLang,
            usedIn: "project",
          })
        ),

        dispatch(fetchAllBadge({ lang: projectLang })),
        dispatch(fetchAllTag({ lang: projectLang })),
        dispatch(fetchAllZone({ lang: projectLang })),

        dispatch(fetchAllFacilityType({ lang: projectLang })),
      ]);
    }
  }

  async function reset() {
    dispatch(resetProjectDetail());
    dispatch(resetCurrentPage());
  }

  // load data
  useEffect(() => {
    loadData();

    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editableProjectData || isCreatePage) {
      loadDataThatRelateToProjectLanguageCode();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableProjectData?.languageCode, projectId]);

  useEffect(() => {
    if (project) {
      setEditableProjectData({
        ...project,
        price: { ...project.price, unit: priceUnit, currency: currency },
      });
    } else {
      setEditableProjectData(initProject);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  // set project name from brand and project location
  useEffect(() => {
    const brandName = _.get(
      _.find(brands, {
        id: editableProjectData?.brandId,
      }),
      "name",
      ""
    );
    if (brandName) {
      onHandleChangeProjectData(
        "name",
        `${brandName} ${editableProjectData?.displayedLocationName}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editableProjectData?.brandId,
    editableProjectData?.displayedLocationName,
  ]);

  useEffect(() => {
    setAllTag(
      tags.map((item) => ({
        label: item.label,
        value: item.id,
      }))
    );
  }, [tags]);

  useEffect(() => {
    if (newTag && editableProjectData) {
      onHandleChangeProjectData("termIds.tagIds", [
        ...editableProjectData.termIds.tagIds,
        newTag.id,
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTag]);

  function onCheckLoadOptionData() {
    return (
      brandSelectItems &&
      productTypeSelectItems &&
      locationSelectItems &&
      badges &&
      tags &&
      zones
    );
  }

  return editableProjectData &&
    onCheckLoadOptionData() &&
    (!isPageLoading || isCreatePage) ? (
    <>
      <PageHeader
        title={pageTitle}
        backLink="/project"
        onClickBack={() => {}}
        rightAction={
          <>
            {onClickDelete && (
              <ButtonGroup>
                <Button
                  buttonStyle="outline-light"
                  onClick={() => setIsShowDeleteModal(true)}
                >
                  <FontAwesomeIcon icon={["far", "trash-can"]} />
                </Button>
              </ButtonGroup>
            )}
            {onClickCreate && (
              <Button
                onClick={
                  () => onHandleClickCreateProject()
                  // onClickCreate ? onClickCreate(editableProjectData) : undefined
                }
                disabled={Boolean(slugError)}
              >
                Create
              </Button>
            )}
          </>
        }
      />
      {/* Confirm modal */}
      <>
        {/* Delete project */}
        <ConfirmModal
          onClose={() => {
            setIsShowDeleteModal(false);
          }}
          title="Delete Project"
          opened={isShowDeleteModal}
          onClickPrimaryButton={onClickDelete}
        >
          <div>
            Are you sure you want to delete{" "}
            <b>‘{editableProjectData?.name || ""}’</b>?
          </div>
          <div style={{ marginTop: "16px" }}>
            Once you confirm, this project will be permanently deleted and also
            removed from all contents that use it.
          </div>
        </ConfirmModal>
        {/* Craete project page */}
        <ConfirmModal
          onClose={() => {
            setIsShowCreatePageModal(false);
          }}
          title="Create Project Page"
          primaryButtonStyle="primary"
          primaryButtonTitle="Create"
          opened={isShowCreatePageModal}
          onClickPrimaryButton={() => {
            setIsShowCreatePageModal(false);
            onCreatePage && onCreatePage(editableProjectData);
          }}
        >
          {isCreatePage ? (
            <div>
              Once you confirm, a project and a page for this project will be created.
            </div>
          ) : (
            <div>
              Once you confirm, a page for this project will be created.
            </div>
          )}
        </ConfirmModal>
      </>
      <FlexContainer>
        <ContentContainer>
          <ScrollWrapper>
            <Section
              headerButtonStyle="primary"
              headerButtonTitle={submitButtonTitle}
              isHeaderButtonDisabled={Boolean(slugError)}
              onClickHeaderButton={
                onClickSubmit
                  ? () => onClickSubmit(editableProjectData)
                  : undefined
              }
              title="General Information"
            >
              <ContentHolder>
                <ContentWrapper>
                  <Select
                    searchable
                    label="Brand"
                    value={editableProjectData?.brandId || ""}
                    placeholder="Select a brand"
                    data={brands.map((item) => ({
                      value: item.id,
                      label: `${item?.name || ""} (${
                        item?.productType?.name || ""
                      })`,
                    }))}
                    onChange={(e) => {
                      onHandleChangeProjectData("brandId", e);
                      setBrandError("");
                    }}
                    error={brandError}
                  />
                </ContentWrapper>

                <ContentWrapper>
                  <TextInput
                    label="Location name"
                    value={editableProjectData?.displayedLocationName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      onHandleChangeProjectData(
                        "displayedLocationName",
                        e.target.value
                      );
                      setDisplayedLocationError("");
                    }}
                    hintText={
                      !displayedLocationError
                        ? "Use for display in the project name"
                        : ""
                    }
                    error={displayedLocationError}
                  />
                </ContentWrapper>

                <ContentWrapper>
                  <TextInput
                    label="Project name"
                    value={editableProjectData?.name || ""}
                    disabled
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <TextInput
                    label="Project name (Alternative)"
                    value={editableProjectData?.altName || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onHandleChangeProjectData("altName", e.target.value)
                    }
                  />
                </ContentWrapper>

                <ContentWrapper>
                  <TextInput
                    label="Slug (Code)"
                    value={editableProjectData?.slugCode || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      onHandleChangeProjectData("slugCode", e.target.value);
                      setSlugError("");
                    }}
                    onBlur={() => {
                      if (editableProjectData.slugCode !== null) {
                        onHandleValidateSlugCode(editableProjectData?.slugCode);
                      }
                    }}
                    error={slugError}
                  />
                </ContentWrapper>

                <ContentWrapper>
                  <TextInput
                    label="Excerpt"
                    value={editableProjectData?.excerpt || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onHandleChangeProjectData("excerpt", e.target.value)
                    }
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <TextArea
                    label="Description"
                    value={editableProjectData?.description || ""}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      onHandleChangeProjectData("description", e.target.value)
                    }
                    placeholder={"Enter a description"}
                  />
                </ContentWrapper>
                {/* <ContentWrapper>
                  <Select
                    label="Project type"
                    value={editableProjectData.projectTypeId}
                    data={productTypeSelectItems}
                    onChange={(e) =>
                      onHandleChangeProjectData("projectTypeId", e)
                    }
                  />
                </ContentWrapper> */}
                <ContentWrapper>
                  <MultiSelect
                    searchable
                    label="Project status"
                    value={editableProjectData.termIds.badgeIds}
                    data={badges.map((item) => ({
                      label: item.label,
                      value: item.id,
                    }))}
                    onChange={(e) =>
                      onHandleChangeProjectData("termIds.badgeIds", e)
                    }
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <MultiSelect
                    searchable
                    creatable
                    searchValue={tagSearchValue}
                    onSearchChange={(e) => onHandleSearchTag(e)}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                      onHandleCreateTag(e, tagSearchValue)
                    }
                    label="Tag"
                    value={editableProjectData.termIds.tagIds}
                    data={allTag}
                    onChange={(e) =>
                      onHandleChangeProjectData("termIds.tagIds", e)
                    }
                  />
                </ContentWrapper>
                <ContentWrapper />
                <div style={{ marginBottom: 24 }}>
                  <ContentDivider title={"Internal Information"} />
                </div>
                <ContentWrapper>
                  <TextInput
                    label="Reservation code"
                    value={editableProjectData?.projectCode || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      onHandleChangeProjectData("projectCode", e.target.value);
                      setProjectCodeError("");
                    }}
                    onBlur={() => {
                      if (
                        editableProjectData.projectCode === null ||
                        editableProjectData.projectCode.length === 0
                      ) {
                        setProjectCodeError("Please enter a project code.");
                      }
                    }}
                    error={projectCodeError}
                  />
                </ContentWrapper>
                {/* Remove later */}
                <ContentWrapper>
                  <TextInput
                    label="Legacy project ID"
                    value={editableProjectData?.oxygenProjectId || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onHandleChangeProjectData(
                        "oxygenProjectId",
                        e.target.value
                      )
                    }
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <Select
                    label="Zone"
                    value={editableProjectData.zoneId || ""}
                    data={zones.map((item) => ({
                      label: item.label,
                      value: item.id,
                    }))}
                    placeholder="Select a zone"
                    onChange={(e) => {
                      onHandleChangeProjectData("zoneId", e);
                      setZoneError("");
                    }}
                    error={zoneError}
                  />
                </ContentWrapper>

                <ContentWrapper />
                <div style={{ marginBottom: 24 }}>
                  <ContentDivider title={"Location"} />
                </div>
                <ContentWrapper>
                  <MultiSelect
                    searchable
                    label="Location"
                    value={editableProjectData.locationIds}
                    data={locationSelectItems}
                    onChange={(e) =>
                      onHandleChangeProjectData("locationIds", e)
                    }
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <TextInput
                    label="Latitude"
                    value={_.get(
                      editableProjectData?.address?.coordinates,
                      "[1]",
                      ""
                    )}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onHandleChangeProjectData(
                        "address.coordinates[1]",
                        e.target.value
                      )
                    }
                    hintText={
                      "Valid latitude values are between -90 and 90 both inclusive."
                    }
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <TextInput
                    label="Longitude"
                    value={_.get(
                      editableProjectData?.address?.coordinates,
                      "[0]",
                      ""
                    )}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onHandleChangeProjectData(
                        "address.coordinates[0]",
                        e.target.value
                      )
                    }
                    hintText={
                      "Valid longitude values are between -180 and 180 both inclusive."
                    }
                  />
                </ContentWrapper>
                <ContentWrapper />
                <div style={{ marginBottom: 24 }}>
                  <ContentDivider title={"Price"} />
                </div>
                <ContentWrapper>
                  <TextInput
                    label="Min price (THB in millions)"
                    value={editableProjectData?.price?.minValue || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onHandleChangeProjectData(
                        "price.minValue",
                        e.target.value
                      )
                    }
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <TextInput
                    label="Max price (THB in millions)"
                    value={editableProjectData?.price?.maxValue || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onHandleChangeProjectData(
                        "price.maxValue",
                        e.target.value
                      )
                    }
                  />
                </ContentWrapper>
              </ContentHolder>
            </Section>
            <SectionUnitType
              previousUnitType={projectUnitTypeList}
              projectId={project?.id}
              onChangeData={(value) => {
                onHandleChangeDataForSpecialSection("unitTypeIds", value);
              }}
            />
            <SectionFacility
              facilitiesData={editableProjectData.facilities}
              onChangeData={async (value) => {
                onHandleChangeProjectData("facilities", value);
              }}
              onChangeDataAndSave={async (value) => {
                onHandleChangeDataForSpecialSection("facilities", value);
              }}
            />
            <SectionNearby
              languageCode={editableProjectData.languageCode}
              nearbysData={editableProjectData.nearbys}
              onChangeDataAndSave={async (path, value) => {
                onHandleChangeDataForSpecialSection(path, value);
              }}
            />
            <SectionGallery
              galleries={editableProjectData.galleries}
              title={"Gallery"}
              onChangeImagesData={(value) => {
                onHandleChangeDataForSpecialSection("galleries", value);
              }}
            />
            <SectionMedia
              mediaSection={
                <>
                  <MediaInputWrapper>
                    {mapImageInputProjectPage.slice(0, 2).map((item, index) => (
                      <ImageInput
                        {...item}
                        key={`${item.label}-${index + 10}`}
                        previousImageData={_.get(editableProjectData, item.key)}
                        onClickChooseImage={(img) => {
                          if (isCreatePage) {
                            onHandleChangeProjectData(
                              item.key,
                              onCastIUploadDataToEmbeddedImageData(img, null) ||
                                null
                            );
                          } else {
                            if (onClickSubmit) {
                              onClickSubmit(
                                onHandleChangeEditableData(
                                  editableProjectData,
                                  item.key,
                                  onCastIUploadDataToEmbeddedImageData(
                                    img,
                                    null
                                  )
                                )
                              );
                            }
                          }
                        }}
                        data={{
                          imgUrl: _.get(
                            editableProjectData,
                            `${item.key}.originalUrl`,
                            ""
                          ),
                          imgAlt: _.get(
                            editableProjectData,
                            `${item.key}.name`,
                            ""
                          ),
                        }}
                      />
                    ))}
                  </MediaInputWrapper>
                  <MediaInputWrapper>
                    {mapImageInputProjectPage.slice(2, 5).map((item, index) => (
                      <ImageInput
                        {...item}
                        key={`${item.label}-${index + 10}`}
                        previousImageData={_.find(editableProjectData.banners, {
                          deviceType: item.device,
                          useFor: item.usedFor,
                        })}
                        onClickChooseImage={(img) => {
                          onHandleChangeImages(
                            img,
                            item.device,
                            "banners",
                            item.usedFor
                          );
                        }}
                        data={{
                          imgUrl: _.get(
                            _.find(editableProjectData.banners, {
                              deviceType: item.device,
                              useFor: item.usedFor,
                            }),
                            "originalUrl",
                            ""
                          ),
                          imgAlt: _.get(
                            _.find(editableProjectData.banners, {
                              deviceType: item.device,
                              useFor: item.usedFor,
                            }),
                            "name",
                            ""
                          ),
                        }}
                      />
                    ))}
                  </MediaInputWrapper>
                  <MediaInputWrapper>
                    {mapImageInputProjectPage.slice(5).map((item, index) => (
                      <ImageInput
                        {...item}
                        key={`${item.label}-${index + 10}`}
                        previousImageData={_.find(
                          editableProjectData.thumbnails,
                          {
                            deviceType: item.device,
                            useFor: item.usedFor,
                          }
                        )}
                        onClickChooseImage={(img) => {
                          onHandleChangeImages(
                            img,
                            item.device,
                            "thumbnails",
                            item.usedFor
                          );
                        }}
                        data={{
                          imgUrl: _.get(
                            _.find(editableProjectData.thumbnails, {
                              deviceType: item.device,
                              useFor: item.usedFor,
                            }),
                            "originalUrl",
                            ""
                          ),
                          imgAlt: _.get(
                            _.find(editableProjectData.thumbnails, {
                              deviceType: item.device,
                              useFor: item.usedFor,
                            }),
                            "name",
                            ""
                          ),
                        }}
                      />
                    ))}
                  </MediaInputWrapper>
                </>
              }
            />
            <SectionAttachment
              title={"Attachments"}
              mapAttachmentInputData={mapAttachmentProjectInputData.map(
                (item) => {
                  return {
                    label: item.label,
                    data: _.find(editableProjectData.attachments, {
                      useFor: item.value,
                    }),
                    onClickChooseAttachment: (data) => {
                      onHandleChangeAttachment(
                        item.value,
                        "attachments",
                        onCastIUploadDataToEmbeddedImageData(data, null)
                      );
                    },
                    onClickDeleteAttachment: () => {
                      onHandleDeleteAttachment(item.value);
                    },
                  };
                }
              )}
            />
            <SeoSection
              onClickSave={
                onClickSubmit
                  ? () => onClickSubmit(editableProjectData)
                  : undefined
              }
              data={{
                ...editableProjectData,
                ogImage: editableProjectData?.openGraphImage,
              }}
              onChangeSeoTitle={(e: string) =>
                onHandleChangeProjectData("seoTitle", e)
              }
              onChangeSeoDescription={(e: string) =>
                onHandleChangeProjectData("seoDescription", e)
              }
              onChangeOgTitle={(e: string) =>
                onHandleChangeProjectData("ogTitle", e)
              }
              onChangeOgDescription={(e: string) =>
                onHandleChangeProjectData("ogDescription", e)
              }
              onChangeOgImage={(img) =>
                onHandleChangeProjectData(
                  "openGraphImage",
                  onCastIUploadDataToEmbeddedImageData(img, null)
                )
              }
            />

            {/* <SectionGallery
              galleries={editableProjectData.masterPlans}
              title={"Master Plan Gallery"}
              onChangeImagesData={(value) => {
                onHandleChangeDataForSpecialSection("masterPlans", value);
              }}
            /> */}
          </ScrollWrapper>
        </ContentContainer>
        <SidePanel
          title="Summary"
          sidePanelAction={
            <SidePanelSection>
              <Select
                disabled={!Boolean(isCreatePage)}
                label={"Language"}
                data={[
                  { label: "Thai", value: "th" },
                  { label: "English", value: "en" },
                ]}
                value={editableProjectData?.languageCode}
                onChange={(e) => {
                  onHandleChangeProjectData("languageCode", e);
                }}
              />
              <ContentDivider title={"Page builder"} />
              {isShowPreviewPage && (
                <>
                  {currentPageId ? (
                    <>
                      <Select
                        data={[
                          { value: "public", label: "Public" },
                          { value: "unlisted", label: "Unlisted" },
                        ]}
                        value={editableProjectData?.visibilityStatus}
                        onChange={(e) => {
                          if (project && e) {
                            onHandleUpdateVisibilityStatus(project?.id, e);
                          }
                          // onHandleChangeProjectData(
                          //   "visibilityStatus",
                          //   e || ""
                          // );
                        }}
                        label={"Visibility"}
                      />
                      <Select
                        data={publishedData}
                        value={editableProjectData?.publishStatus}
                        onChange={(e) => {
                          if (project && e) {
                            onHandleUpdatePublishStatus(project?.id, e);
                          }
                          // onHandleChangeProjectData("publishStatus", e || "");
                        }}
                        label={"Publish status"}
                      />
                      <SidePanelTextGroup>
                        <SubTitle>Published at</SubTitle>
                        <SubText>
                          {project?.publishedAt
                            ? formatFullDateTime(new Date(project?.publishedAt))
                            : ""}
                        </SubText>
                      </SidePanelTextGroup>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <Button
                          buttonStyle="outline-light"
                          onClick={() => {
                            navigate(`/page-builder/page/${currentPageId}`);
                          }}
                          rightIcon={
                            <FontAwesomeIcon
                              icon={["far", "arrow-right-arrow-left"]}
                            />
                          }
                        >
                          Switch to page builder
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <Button
                        leftIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
                        onClick={() => setIsShowCreatePageModal(true)}
                        buttonStyle="outline-light"
                      >
                        Create Page
                      </Button>
                    </>
                  )}
                </>
              )}
              {(isShowBrand || isShowProjectType) && <ContentDivider />}
              <>
                {isShowBrand && (
                  <SidePanelTextGroup>
                    <SubTitle>Brand</SubTitle>
                    <SubText>{project?.brand?.name}</SubText>
                  </SidePanelTextGroup>
                )}
                {isShowProjectType && (
                  <SidePanelTextGroup>
                    <SubTitle>Product type</SubTitle>
                    <SubText>{project?.brand?.productType?.name || ""}</SubText>
                  </SidePanelTextGroup>
                )}
              </>
              {isShowUpdatedAt && (
                <>
                  <ContentDivider />
                  <SidePanelTextGroup>
                    <SubTitle>Updated at</SubTitle>
                    <SubText>
                      {project?.updatedAt
                        ? formatFullDateTime(new Date(project?.updatedAt))
                        : ""}
                    </SubText>
                  </SidePanelTextGroup>
                </>
              )}
            </SidePanelSection>
          }
        />
      </FlexContainer>
    </>
  ) : (
    <div style={{ margin: "auto" }}>
      <Loader color="gray" />
    </div>
  );
}
