import { ComponentSpecProps } from "../../../types";

export const AutoFillHighlightTopicSection: ComponentSpecProps = {
  name: 'Section สำหรับแสดง facilities',
  componentType: 'AutoFillHighlightTopicSection',
  settingFields: {
    title: {
      displayName: 'หัวข้อหลัก',
      fieldName: 'title',
      type: 'string',
      defaultValue: 'Section สำหรับแสดง facilities',
    }
  },
  settings: {
    title: 'Section สำหรับแสดง facilities',
  },
  required: {
    title: true,
  },
}