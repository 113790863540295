export const onClickEditButton = (
  event: React.MouseEvent<HTMLElement>,
  callback: () => void
) => {
  //   @ts-ignore
  if (event.target?.className.includes("Button")) {
    return callback();
  }

  return;
};

export const onClickImageWrapper = (
  event: React.MouseEvent<HTMLElement>,
  callback: () => void
) => {
  //   @ts-ignore
  if (event.target?.className.includes("Button")) {
    return;
  }

  callback();
};
