import { Radio } from "../radio/Radio";
import { RadioChoiceContainer, RadioChoiceSubtitle, RadioChoiceTextContianer, RadioChoiceTitle, RadioContainer } from "./RadioChoice.style";

interface RadioChoiceProps {
  title?: string;
  subtitle?: string;
  checked?: boolean;
}

export function RadioChoice(props: RadioChoiceProps) {
  const { title = '', subtitle = '', checked = false } = props;
  return (
    <RadioChoiceContainer checked={checked}>
      <RadioContainer>
        <Radio checked={checked} size="xs"/>
      </RadioContainer>
      <RadioChoiceTextContianer>
        <RadioChoiceTitle checked={checked} className="radio-text">
          {title}
        </RadioChoiceTitle>
        <RadioChoiceSubtitle checked={checked} className="radio-text">
          {subtitle}
        </RadioChoiceSubtitle>
      </RadioChoiceTextContianer>
    </RadioChoiceContainer>
  )
}