import type { LoadingState, StatusState } from '../types';

export const getStateObject = (loadingState: LoadingState = { type: 'idle' }): StatusState => {
  switch (loadingState.type) {
    case 'loading':
      return {
        status: 'loading',
        error: null,
      };
    case 'success':
      return {
        status: 'success',
        error: null,
      };
    case 'error':
      return {
        status: 'error',
        error: loadingState.error || null,
      };
    default:
      return {
        status: 'idle',
        error: null,
      };
  }
};
