import styled from "@emotion/styled/macro";
// import { ColumnContainer } from '../../molecules/column/Column.style'

export const DataTableContainer = styled.div<{ disableBorderTop: boolean; disableBorderRadius:boolean }>`
  border-top: ${(props) =>
    props.disableBorderTop ? "0" : "1px solid var(--grey200)"};
  border-bottom: 1px solid var(--grey200);
  background: white;
  border-radius: ${(props) => props.disableBorderRadius ? "0" : "12px"};
`;

export const HeaderContainer = styled.div`
  display: flex;
  padding: 12px 24px;
  border-bottom: 1px solid #eaecf0;
`;

export const TableHeader = styled.div`
  border-bottom: 1px solid #eaecf0;
  background: #f9fafb;
`;

export const SearchInputContainer = styled.div`
  max-width: 400px;
  flex: 1;
  margin-left: auto;
`;

export const FilterButtonContainer = styled.div`
  margin-left: 16px;

  > button {
    height: 100%;
  }
`;

export const TableBody = styled.div`
  .row:hover {
    background: #f9fafb;
  }
  > :not(:last-child) {
    border-bottom: 1px solid #eaecf0;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px 24px;
  border-top: 1px solid #eaecf0;
`;

export const PaginationContainer = styled.div`
  /* overflow: hidden; */
`;

export const TableWrapper = styled.div`
  /* max-width: 100vw;
  overflow-y: auto; */
`;
