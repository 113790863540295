import _ from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { MediaItem, MediaItemData } from "../../atoms/mediaItem/MediaItem";
import { MediaList } from "../../molecules/mediaList/MediaList";
import { ConfirmModal } from "../../organisms/confirmModal/ConfirmModal";
import { EmptyMedia } from "../../organisms/emptyMedia/EmptyMedia";
import { ModalEditMedia } from "../../organisms/modal/modalEditMedia/ModalEditMedia";
import {
  deleteFolderItemById,
  deleteImageById,
  fetchAllImages,
  fetchFolderById,
  fetchImageList,
  mediaSelector,
  moveImage,
  updateImageById,
} from "../../store/features/media";
import { addToast } from "../../store/features/toast";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ManageMedia } from "../../templates/manageMedia/ManageMedia";
import { IMediaResponse } from "../../types";
import { onClickImageWrapper } from "../../utils/mediaItem";
import { SectionContentWrapper, TotalText } from "./MediaDetail.style";

export function MediaDetail() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {
    folderItems,
    allFolderItems,
    currentFolder,
    currentFolderId,
    deleteImageState,
    updateImageState,
    deleteFolderState,
    updateFolderState,
    moveImageState,
  } = useAppSelector(mediaSelector);

  const parentFolderMediaId = id === "uncategorized" ? "null" : id;
  const [searchParams] = useSearchParams();
  const filterSearch = {
    q: searchParams.get("q"),
    page: searchParams.get("page"),
  };

  const limit = 30;

  const [selectedImageIndex, setSelectedImageIndex] =
    useState<number | null>(null);
  const [imageData, setImageData] = useState<IMediaResponse[]>([]);
  const [selectedImage, setSelectedImage] =
    useState<IMediaResponse | null>(null);

  const [editImageModalOpened, setEditImageModalOpened] =
    useState<boolean>(false);
  const [editImageDeleteModalOpened, setEditImageDeleteModalOpened] =
    useState<boolean>(false);

  const [moveFolderModalOpened, setMoveFolderModalOpened] =
    useState<boolean>(false);

  const [isShowDeleteImageModal, setIsShowDeleteImageModal] =
    useState<boolean>(false);

  const [imageDropdownButtonKey, setImageDropdownButtonKey] =
    useState<string | null>(null);

  function onMappingFolderItemsToMediaItemData(
    FolderItems: IMediaResponse[]
  ): MediaItemData[] {
    return FolderItems.slice(0, limit).map((item) => {
      return {
        src: item.urls?.original || "",
        alt: item.name,
        key: item.key,
      };
    });
  }

  function mappingMediaItemData() {
    const reactEle: React.ReactElement[] = [];

    const mediaItems = onMappingFolderItemsToMediaItemData(folderItems).map(
      (item, index) => (
        <MediaItem
          onMouseLeave={() => {
            setImageDropdownButtonKey(null);
          }}
          isEditButton
          layout="A"
          key={`${item.alt}-${index + 100}`}
          data={item}
          ratio={1 / 1}
          isMenuItemActive={Boolean(item.key === imageDropdownButtonKey)}
          onClickButton={() => {
            if (imageDropdownButtonKey === folderItems[index].key) {
              setSelectedImage(null);
              setImageDropdownButtonKey(null);
            } else {
              const imageIndex = allFolderItems.findIndex((data) => {
                return data.key === item.key;
              });
              if (imageIndex >= 0) {
                setSelectedImage(allFolderItems[imageIndex]);
                setImageDropdownButtonKey(allFolderItems[imageIndex].key);
              }
            }
          }}
          onClickMedia={(e) => {
            onClickImageWrapper(e, () => {
              const imageIndex = allFolderItems.findIndex(
                (data) => data.key === item.key
              );
              if (imageIndex >= 0) {
                setSelectedImageIndex(imageIndex);
                setEditImageModalOpened(true);
              }
            });
          }}
          onEdit={() => {
            const imageIndex = allFolderItems.findIndex(
              (data) => data.key === item.key
            );
            if (imageIndex >= 0) {
              setSelectedImageIndex(imageIndex);
              setSelectedImage(allFolderItems[imageIndex]);
              setEditImageModalOpened(true);
            }
          }}
          onMove={() => {
            const imageIndex = allFolderItems.findIndex(
              (data) => data.key === item.key
            );
            if (imageIndex >= 0) {
              setSelectedImageIndex(imageIndex);
              setSelectedImage(allFolderItems[imageIndex]);
              setMoveFolderModalOpened(true);
            }
          }}
          onDelete={() => {
            const imageIndex = allFolderItems.findIndex(
              (data) => data.key === item.key
            );
            if (imageIndex >= 0) {
              setSelectedImageIndex(imageIndex);
              setSelectedImage(allFolderItems[imageIndex]);
              setIsShowDeleteImageModal(true);
            }
          }}
        />
      )
    );
    return reactEle.concat(mediaItems).slice(0, limit);
  }

  async function onHandleMove(
    data: IMediaResponse,
    destinationMediaFolderId: string
  ) {
    await dispatch(
      moveImage({
        data: {
          imageIds: [data?.id],
          destinationMediaFolderId:
            destinationMediaFolderId === "null"
              ? null
              : destinationMediaFolderId,
        },
      })
    );
  }

  useEffect(() => {
    const status = moveImageState.status;
    if (status === "success") {
      dispatch(
        fetchImageList({
          ...filterSearch,
          "parent-media-folder-id": currentFolderId,
          limit: `${limit}`,
        })
      );
      dispatch(
        fetchAllImages({
          ...filterSearch,
          "parent-media-folder-id": currentFolderId,
        })
      );
      dispatch(fetchFolderById(currentFolderId));
      setSelectedImageIndex(null);
      setSelectedImage(null);
      setMoveFolderModalOpened(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveImageState.status]);

  useEffect(() => {
    const status = moveImageState.status;
    if (status === "success")
      dispatch(addToast({ status, title: "Successfully moved" }));
    if (status === "error")
      dispatch(addToast({ status, title: "Move failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveImageState.status, dispatch, location.pathname]);

  function onHandleClickNext(index: number) {
    setSelectedImageIndex(index + 1);
  }

  function onHandleClickPrivious(index: number) {
    setSelectedImageIndex(index - 1);
  }

  async function onHandleDeleteImage(image: IMediaResponse) {
    setSelectedImage(image);
    await dispatch(deleteImageById(image.id));
    setEditImageModalOpened(false);
    setIsShowDeleteImageModal(false);
    setEditImageDeleteModalOpened(false);
    dispatch(fetchFolderById(currentFolderId));
  }

  useEffect(() => {
    if (deleteImageState.status === "success" && selectedImage) {
      dispatch(deleteFolderItemById(selectedImage?.id));
      dispatch(
        fetchImageList({
          ...filterSearch,
          "parent-media-folder-id": currentFolderId,
          limit: `${limit}`,
        })
      );
      dispatch(
        fetchAllImages({
          ...filterSearch,
          "parent-media-folder-id": currentFolderId,
        })
      );
      setSelectedImage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteImageState]);

  async function onHandleSaveMedia(imageId: string, index: number) {
    await dispatch(
      updateImageById({ id: imageId, data: { ...imageData[index] } })
    );
  }

  function onHandleChangeImageData(index: number, path: string, value: string) {
    const clonedData = _.cloneDeep(imageData);
    _.set(clonedData[index], path, value);
    setImageData(clonedData);
  }

  useEffect(() => {
    setImageData(allFolderItems);
  }, [allFolderItems]);

  useEffect(() => {
    const status = updateImageState.status;
    if (status === "success") dispatch(addToast({ status }));
    if (status === "error")
      dispatch(addToast({ status, title: "Update failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateImageState.status, dispatch]);

  useEffect(() => {
    const status = deleteImageState.status;
    if (status === "success")
      dispatch(addToast({ status, title: "Successfully deleted" }));
    if (status === "error")
      dispatch(addToast({ status, title: "Delete failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteImageState.status, dispatch]);

  useEffect(() => {
    const status = updateFolderState.status;
    if (status === "success") dispatch(addToast({ status }));
    if (status === "error")
      dispatch(addToast({ status, title: "Update failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateFolderState.status, dispatch]);

  useEffect(() => {
    const status = deleteFolderState.status;
    if (status === "success")
      dispatch(addToast({ status, title: "Successfully deleted" }));
    if (status === "error")
      dispatch(addToast({ status, title: "Delete failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteFolderState.status, dispatch]);

  return (
    <>
      <ConfirmModal
        onClose={() => setIsShowDeleteImageModal(false)}
        title={`Delete File`}
        primaryButtonTitle={"Delete"}
        opened={isShowDeleteImageModal}
        onClickPrimaryButton={() =>
          selectedImageIndex !== null &&
          onHandleDeleteImage(imageData[selectedImageIndex])
        }
      >
        <div>Are you sure you want to delete this file?</div>
        <div style={{ marginTop: "16px" }}>
          Once you confirm, this file will be deleted from the list but still be
          available in the contents that used it.
        </div>
      </ConfirmModal>
      {selectedImageIndex !== null && (
        <>
          <ModalEditMedia
            onCloseModal={() => {
              setEditImageModalOpened(false);
              setSelectedImageIndex(null);
            }}
            onClickNext={() => onHandleClickNext(selectedImageIndex)}
            onClickPrevious={() => onHandleClickPrivious(selectedImageIndex)}
            isDisableNextButton={
              selectedImageIndex + 1 > allFolderItems.length - 1
            }
            isDisablePreviousButton={selectedImageIndex - 1 < 0}
            isModalOpened={editImageModalOpened}
            isModalDeleteImageOpened={editImageDeleteModalOpened}
            onCloseDeleteImageModal={() => {
              setEditImageDeleteModalOpened(false);
            }}
            onOpenDeleteImageModal={() => {
              setEditImageDeleteModalOpened(true);
            }}
            onDeleteData={() =>
              onHandleDeleteImage(imageData[selectedImageIndex])
            }
            onSave={() =>
              onHandleSaveMedia(
                imageData[selectedImageIndex].id,
                selectedImageIndex
              )
            }
            onChange={(e, path) =>
              onHandleChangeImageData(selectedImageIndex, path, e)
            }
            data={imageData[selectedImageIndex]}
          />
        </>
      )}
      <ManageMedia
        pathToSearch={`/media/folder/${currentFolderId}`}
        pathToNavigateAfterAnAction={"/media"}
        breadcrumbLabel={"Media"}
        moveFolderModalOpened={moveFolderModalOpened}
        onMoveMedia={(destinationMediaFolderId) =>
          selectedImage && onHandleMove(selectedImage, destinationMediaFolderId)
        }
        onCloseMoveFolderModal={() => setMoveFolderModalOpened(false)}
        dataLoaderAfterCloseModalUpload={() => {
          dispatch(
            fetchImageList({
              ...filterSearch,
              "parent-media-folder-id": parentFolderMediaId,
              limit: `${limit}`,
            })
          );
          dispatch(
            fetchAllImages({
              ...filterSearch,
              "parent-media-folder-id": currentFolderId,
            })
          );
        }}
        fetchData={(id, filter) => {
          dispatch(
            fetchImageList({
              ...filter,
              "parent-media-folder-id": id,
              limit: `${limit}`,
            })
          );
          dispatch(
            fetchAllImages({
              ...filter,
              "parent-media-folder-id": id,
            })
          );
        }}
      >
        <>
          {currentFolder?.imagesCount ? (
            <SectionContentWrapper>
              <TotalText>Total {currentFolder?.imagesCount} items</TotalText>
              <MediaList
                column={6}
                row={3}
                columnWidth={"auto"}
                limit={limit}
                data={mappingMediaItemData()}
              />
            </SectionContentWrapper>
          ) : (
            <EmptyMedia
              dataLoaderAfterCloseUpload={() => {
                dispatch(
                  fetchImageList({
                    ...filterSearch,
                    "parent-media-folder-id":
                      id === "uncategorized" ? "null" : id,
                    limit: `${limit}`,
                  })
                );
                dispatch(
                  fetchAllImages({
                    ...filterSearch,
                    "parent-media-folder-id":
                      id === "uncategorized" ? "null" : id,
                  })
                );
              }}
              parentFolderId={id === "uncategorized" ? "null" : id}
            />
          )}
        </>
      </ManageMedia>
    </>
  );
}
