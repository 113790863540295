import { Container, Sepline, Title } from "./ContentDivider.style";

interface ContentDividerProps {
  title?: string;
  titlePosition?: "left" | "center" | "right";
}

export function ContentDivider(props: ContentDividerProps) {
  const { title, titlePosition = "left" } = props;
  if (titlePosition === "center") {
    return (
      <Container>
        <Sepline />
        {title && <Title titlePosition={titlePosition}>{title}</Title>}
        <Sepline />
      </Container>
    );
  }

  if (titlePosition === "right") {
    return (
      <Container>
        <Sepline />
        {title && <Title titlePosition={titlePosition}>{title}</Title>}
      </Container>
    );
  }
  return (
    <Container>
      {title && <Title titlePosition={titlePosition}>{title}</Title>}
      <Sepline />
    </Container>
  );
}
