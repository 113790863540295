import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { Button } from "../../atoms/button/Button";
import { Loader } from "../../atoms/loading/loader/Loader";
import { Select } from "../../atoms/select/Select";
import { TextInput } from "../../atoms/textInput/TextInput";
import {
  ContentHolder,
  ContentWrapper,
  ErrorInputIconWrapper,
} from "../../globalStyle/styles/ManagePage.style";
import { ConfirmModal } from "../../organisms/confirmModal/ConfirmModal";
import { Modal } from "../../organisms/modal/Modal";
import { PageHeader } from "../../organisms/pageHeader/PageHeader";
import { Section } from "../../organisms/section/Section";
import { addToast } from "../../store/features/toast";
import {
  fetchRoleList,
  fetchUserById,
  resetUserDetail,
  userActivateById,
  userChangePassword,
  userSelector,
  userSuspendById,
} from "../../store/features/user";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IEditableUser, initUser } from "../../types/user";
import { onHandleChangeEditableData } from "../../utils/dataService";
import {
  ButtonGroup,
  SuspendButtonWrapper,
  InformText,
  ChangePasswordModalWrapper,
  ContentContainer,
  ScrollWrapper,
} from "./ManageUserManagement.style";

interface ManageUserManagementProps {
  userId?: string;
  pageTitle: string;
  submitButtonTitle?: string;
  onClickSubmit: (IEditableUser: any) => void;
}

export function ManageUserManagement(props: ManageUserManagementProps) {
  const { userId, pageTitle, submitButtonTitle, onClickSubmit } = props;
  const dispatch = useAppDispatch();
  const {
    roles,
    currentUser,
    loadUserByIdState,
    changeUserPasswordState,
    suspendUserByIdState,
    activateUserByIdState,
    createUserState,
    updateUserByIdState,
  } = useAppSelector(userSelector);
  // const languageCode = lang;

  // user data
  const [userData, setUserData] = useState<IEditableUser | null>(null);
  const [passwordData, setPasswordData] = useState<string>("");
  const [confirmPasswordData, setConfirmPasswordData] = useState<string>("");

  // error state
  const [usernameError, setUsernameError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");

  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);

  const [isChangePasswordModalOpened, setIsChangePasswordModalOpened] =
    useState<boolean>(false);
  const [isSuspendUserModalOpened, setIsSuspendUserModalOpened] =
    useState<boolean>(false);
  const [isActiveUserModalOpened, setIsActiveUserModalOpened] =
    useState<boolean>(false);

  const inputPasswordProps = {
    type: "password",
    label: !Boolean(userId) ? "Password" : "New password",
    value: passwordData,
    rightSection: passwordError && (
      <ErrorInputIconWrapper>
        <FontAwesomeIcon icon={["far", "circle-exclamation"]} />
      </ErrorInputIconWrapper>
    ),
    onBlur: () =>
      !Boolean(userId)
        ? passwordData.length === 0 &&
          setPasswordError("Please enter a password")
        : onHandleValidatePassword(),
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      setPasswordData(e.target.value);
      setPasswordError("");
      setConfirmPasswordData("");
      setConfirmPasswordError("");
    },
    error: passwordError,
    hintText: !Boolean(passwordError)
      ? "Password must be at least 8 characters long and can contain letters, numbers, and special characters."
      : undefined,
  };

  const inputConfirmPasswordProps = {
    type: "password",
    label: !Boolean(userId) ? "Confirm password" : "Confirm new password",
    value: confirmPasswordData,
    rightSection: confirmPasswordError && (
      <ErrorInputIconWrapper>
        <FontAwesomeIcon icon={["far", "circle-exclamation"]} />
      </ErrorInputIconWrapper>
    ),
    onBlur: () => onHandleValidateConfirmPassword(),
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      setConfirmPasswordData(e.target.value);
      setConfirmPasswordError("");
    },
    error: confirmPasswordError,
  };

  function onHandleValidatePassword() {
    const validation = /[a-zA-Z0-9!@#$%^&*]\w{7,}$/g;
    if (passwordData.length === 0) {
      setPasswordError("Please enter a password");
      setIsValidPassword(false);
      return;
    }

    if (passwordData.length < 8) {
      setPasswordError(
        "Invalid Password. Password must be at least 8 characters long and can contain letters, numbers, and special characters."
      );
      setIsValidPassword(false);
      return;
    }

    if (!Boolean(_.isEqual(passwordData.match(validation), [passwordData]))) {
      setPasswordError(
        "Invalid Password. Password must be at least 8 characters long and can contain letters, numbers, and special characters."
      );
      setIsValidPassword(false);
      return;
    }

    if (confirmPasswordData.length) {
      onHandleValidateConfirmPassword();
    }
    setPasswordError("");
    return true;
  }

  function onHandleValidateConfirmPassword() {
    if (confirmPasswordData.length === 0) {
      setConfirmPasswordError("Please confirm your password.");
      setIsValidPassword(false);
      return;
    }

    if (!_.isEqual(passwordData, confirmPasswordData)) {
      setConfirmPasswordError("Your passwords don't match.");
      setIsValidPassword(false);
      return;
    }

    setConfirmPasswordError("");
    setIsValidPassword(true);
  }

  function onHandleValidateEmail() {
    const validation =
      /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/g;

    if (userData) {
      if (userData.email.length === 0) {
        setEmailError("Please enter an email.");
        return;
      }

      if (!userData.email.match(validation)) {
        setEmailError("Invalid email. Please enter a valid email.");
        return;
      }
    }
  }

  useEffect(() => {
    const errorCode =
      createUserState.error?.errorFields?.error ||
      updateUserByIdState.error?.errorFields?.error;

    // EMAIL_AND_USERNAME_EXISTS
    // EMAIL_EXISTS
    // USERNAME_EXISTS
    // ROLE_NOT_EXISTS
    if (errorCode === "EMAIL_AND_USERNAME_EXISTS") {
      setEmailError("An account using this email already exists.");
      setUsernameError(
        "This username is already taken. Please try another one."
      );
    }

    if (errorCode === "EMAIL_EXISTS") {
      setEmailError("An account using this email already exists.");
    }

    if (errorCode === "USERNAME_EXISTS") {
      setUsernameError(
        "This username is already taken. Please try another one."
      );
    }
  }, [createUserState.error, updateUserByIdState.error]);

  function onChangeUserData(path: string, value: any) {
    setUserData(onHandleChangeEditableData(userData, path, value));
  }

  function onHandleChangePassword() {
    if (isValidPassword) {
      dispatch(
        userChangePassword({
          id: currentUser?.id,
          data: { password: passwordData },
        })
      );
    }
  }

  useEffect(() => {
    const status = changeUserPasswordState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully changed" }));
      setIsChangePasswordModalOpened(false);
      setPasswordData("");
      setConfirmPasswordData("");
    }
    if (status === "error") {
      dispatch(addToast({ status, title: "Change password failed" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeUserPasswordState.status]);

  function onHandleSuspendUser() {
    dispatch(
      userSuspendById({
        id: currentUser?.id,
        data: { status: "suspended" },
      })
    );
  }

  useEffect(() => {
    const status = suspendUserByIdState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully suspended" }));
      setIsSuspendUserModalOpened(false);
    }
    if (status === "error") {
      dispatch(addToast({ status, title: "Suspend account failed" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suspendUserByIdState.status]);

  function onHandleActiveUser() {
    dispatch(
      userActivateById({
        id: currentUser?.id,
        data: { status: "active" },
      })
    );
  }

  useEffect(() => {
    const status = activateUserByIdState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully activated" }));
      setIsActiveUserModalOpened(false);
    }
    if (status === "error") {
      dispatch(addToast({ status, title: "Activate account failed" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activateUserByIdState.status]);

  useEffect(() => {
    dispatch(fetchRoleList({}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserById(userId));
    }

    return () => {
      dispatch(resetUserDetail());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (userId) {
      if (loadUserByIdState.status === "success") {
        setUserData(currentUser);
      }
    } else {
      if (roles.length) {
        setUserData({ ...initUser, roleId: roles[0].id || "" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadUserByIdState.status, roles]);

  return userData ? (
    <>
      <PageHeader
        title={pageTitle}
        backLink="/user-management"
        onClickBack={() => {}}
      />
      <>
        {/* Modal Change Password */}
        <Modal
          title={"Change password"}
          opened={isChangePasswordModalOpened}
          onClose={() => {
            setIsChangePasswordModalOpened(false);
            setPasswordData("");
            setConfirmPasswordData("");
            setIsValidPassword(false);
          }}
          primaryButtonTitle={"Change"}
          onClickPrimaryButton={() => {
            onHandleChangePassword();
          }}
          secondaryButtonTitle={"Cancel"}
          onClickSecondaryButton={() => {
            setIsChangePasswordModalOpened(false);
          }}
          isDisablePrimaryButton={!Boolean(isValidPassword)}
        >
          <ChangePasswordModalWrapper>
            <TextInput {...inputPasswordProps} />
            <TextInput {...inputConfirmPasswordProps} />
          </ChangePasswordModalWrapper>
        </Modal>
        {/* Modal Suspend User */}
        <ConfirmModal
          onClose={() => setIsSuspendUserModalOpened(false)}
          title="Suspend account"
          opened={isSuspendUserModalOpened}
          primaryButtonStyle={"danger"}
          primaryButtonTitle={"Suspend"}
          onClickPrimaryButton={() => onHandleSuspendUser()}
        >
          <div>
            The following account will be suspended:
            <div style={{ marginTop: "8px" }}>
              <b>{userData.name}</b> ({userData.email})
            </div>
          </div>
          <div style={{ marginTop: "8px" }}>
            Suspending the account will block access to the account and all data
            in the system.
          </div>
        </ConfirmModal>
        {/* Modal Activate User */}
        <ConfirmModal
          onClose={() => setIsActiveUserModalOpened(false)}
          title="Reactivate account"
          opened={isActiveUserModalOpened}
          primaryButtonStyle={"warning"}
          primaryButtonTitle={"Activate"}
          onClickPrimaryButton={() => onHandleActiveUser()}
        >
          <div>
            The following account will be activated:
            <div style={{ marginTop: "8px" }}>
              <b>{userData.name}</b> ({userData.email})
            </div>
          </div>
          <div style={{ marginTop: "8px" }}>
            By reactivating, a suspended account will restore access to the
            account.
          </div>
        </ConfirmModal>
      </>
      <ContentContainer>
        <ScrollWrapper>
          <Section
            footerButtonStyle="primary"
            footerButtonTitle={submitButtonTitle}
            isFooterButtonDisabled={!isValidPassword}
            footerActionComponent={
              Boolean(userId) ? (
                <ButtonGroup>
                  {currentUser?.status === "active" ? (
                    <Button
                      buttonStyle="text-link-danger"
                      onClick={() => setIsSuspendUserModalOpened(true)}
                    >
                      Suspend this account
                    </Button>
                  ) : (
                    <SuspendButtonWrapper>
                      <InformText>This account has been suspended.</InformText>
                      <Button
                        buttonStyle="text-link-warning"
                        onClick={() => setIsActiveUserModalOpened(true)}
                      >
                        Reactivate account
                      </Button>
                    </SuspendButtonWrapper>
                  )}

                  {submitButtonTitle && (
                    <Button
                      buttonStyle="primary"
                      onClick={() => {
                        onClickSubmit(userData);
                      }}
                    >
                      {submitButtonTitle}
                    </Button>
                  )}
                </ButtonGroup>
              ) : undefined
            }
            onClickFooterButton={() => {
              if (onHandleValidatePassword() && isValidPassword) {
                onClickSubmit({ ...userData, password: passwordData });
              }
            }}
          >
            <ContentHolder>
              <ContentWrapper>
                <TextInput
                  label="Username"
                  value={userData?.username || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    onChangeUserData("username", e.target.value);
                    setUsernameError("");
                  }}
                  error={usernameError}
                  onBlur={() => {
                    if (userData.username.length === 0) {
                      setUsernameError("Please enter a username.");
                    }
                  }}
                  rightSection={
                    usernameError && (
                      <ErrorInputIconWrapper>
                        <FontAwesomeIcon icon={["far", "circle-exclamation"]} />
                      </ErrorInputIconWrapper>
                    )
                  }
                />
              </ContentWrapper>
              <ContentWrapper>
                <TextInput
                  label="Name"
                  value={userData?.name || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChangeUserData("name", e.target.value)
                  }
                />
              </ContentWrapper>
              <ContentWrapper>
                <TextInput
                  isHasIcon
                  label="Email"
                  icon={<FontAwesomeIcon icon={["far", "envelope"]} />}
                  value={userData?.email || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    onChangeUserData("email", e.target.value);
                    setEmailError("");
                  }}
                  onBlur={() => {
                    onHandleValidateEmail();
                  }}
                  error={emailError}
                  rightSection={
                    emailError && (
                      <ErrorInputIconWrapper>
                        <FontAwesomeIcon icon={["far", "circle-exclamation"]} />
                      </ErrorInputIconWrapper>
                    )
                  }
                />
              </ContentWrapper>
              {!Boolean(userId) && (
                <>
                  <ContentWrapper>
                    <TextInput {...inputPasswordProps} />
                  </ContentWrapper>
                  <ContentWrapper>
                    <TextInput {...inputConfirmPasswordProps} />
                  </ContentWrapper>
                </>
              )}
              <ContentWrapper>
                <Select
                  label="Role"
                  value={userData?.roleId}
                  data={roles.map((item) => {
                    return { label: item.name, value: item.id };
                  })}
                  onChange={(e) => onChangeUserData("roleId", e)}
                />
              </ContentWrapper>
              {Boolean(userId) && (
                <div style={{ marginTop: 16 }}>
                  <Button
                    buttonStyle="text-link-primary"
                    onClick={() => setIsChangePasswordModalOpened(true)}
                  >
                    Change password
                  </Button>
                </div>
              )}
            </ContentHolder>
          </Section>
        </ScrollWrapper>
      </ContentContainer>
    </>
  ) : (
    <div style={{ margin: "auto" }}>
      <Loader color={"grey"} />
    </div>
  );
}
