import styled from '@emotion/styled'

export const HeaderCellContainer = styled.div`
  padding: 12px 0;
`

export const HeaderCellTitle = styled.div`
  color: #475467;
  font-weight: 500;
  font-size: 12px;
`