import { createAction } from "@reduxjs/toolkit";
import createAsyncThunk from "../../middleware/customCreateThunk";
import {
  loadAllFacilityType,
} from "../../../services/api";

export const fetchAllFacilityType = createAsyncThunk({
  api: loadAllFacilityType,
  EVENT_NAME: "Facility/loadAllType",
});

export const resetFacility = createAction("Facility/reset");
