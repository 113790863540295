import styled from '@emotion/styled'

export const RowContainer = styled.div<{height?:string, padding?:string}>`
  display: flex;
  align-items: center;
  transition: all .2s ease-in-out;
  cursor: pointer;

  padding: ${props => props.padding};
  height: ${props => props.height}
`