import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import qs from "qs";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Breadcrumb } from "../../atoms/breadcrumb/Breadcrumb";
import { Pagination } from "../../atoms/pagination/Pagination";
import { SearchInput } from "../../atoms/searchInput/SearchInput";
import { TextInput } from "../../atoms/textInput/TextInput";
import { DropdownButton } from "../../molecules/dropdownButton/DropdownButton";
import { FolderList } from "../../molecules/folderList/FolderList";
import { ConfirmModal } from "../../organisms/confirmModal/ConfirmModal";
import { DataTable } from "../../organisms/dataTable/DataTable";
import { Heading } from "../../organisms/heading/Heading";
import { Modal } from "../../organisms/modal/Modal";
import { ModalUpload } from "../../organisms/modal/modalUpload/ModalUpload";
import { Section } from "../../organisms/section/Section";
import { deleteFolderAPI } from "../../services/api";
import {
  setCurrentFolderId,
  mediaSelector,
  fetchFolderById,
  fetchAllFolder,
  updateFolder,
  resetMediaItemDetail,
} from "../../store/features/media";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IFolder, QueryParams } from "../../types";
import { onHandleChangeEditableData } from "../../utils/dataService";
import {
  MoveFolderWrapper,
  PaginateWrapper,
  InputWrapper,
  Container,
} from "./ManageMedia.style";
import { ButtonGroup } from "../../pages/projectDetail/ProjectDetail.style";
import { Loader } from "../../atoms/loading/loader/Loader";

interface ManageMediaProps {
  tableHeader?: React.ReactElement;
  additionalInfoElement?: React.ReactElement;
  children: React.ReactElement;
  pathToSearch: string;
  isAttachment?: boolean;
  supportingType?: string;
  pathToNavigateAfterAnAction: string;
  breadcrumbLabel: string;
  moveFolderModalOpened: boolean;
  onCloseMoveFolderModal: () => void;
  dataLoaderAfterCloseModalUpload: () => void;
  onMoveMedia: (destinationMediaFolderId: string) => void;
  fetchData: (id: string, filter: QueryParams) => void;
}

export function ManageMedia(props: ManageMediaProps) {
  const {
    children,
    isAttachment,
    supportingType,
    tableHeader,
    additionalInfoElement,
    pathToSearch,
    moveFolderModalOpened,
    onCloseMoveFolderModal,
    fetchData,
    onMoveMedia,
    pathToNavigateAfterAnAction,
    breadcrumbLabel,
    dataLoaderAfterCloseModalUpload,
  } = props;

  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { currentFolder, currentFolderId, paginationMediaItem, pagination } =
    useAppSelector(mediaSelector);
  const parentFolderMediaId = (id === "uncategorized" ? "null" : id) || "";
  const [searchParams, setSearchParams] = useSearchParams();

  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isShowRenameModal, setIsShowRenameModal] = useState<boolean>(false);

  const [folderData, setFolderData] = useState<IFolder | null>(null);

  const [moveFolderModalSearchValue, setMoveFolderModalSearchValue] = useState(
    searchParams.get("q")
  );
  const [selectedMoveFolder, setSelectedMoveFolder] = useState<string>("");
  const [isShowUncategorizedFolder, setIsShowUncategorizedFolder] =
    useState<boolean>(true);

  function onHandleMoveFolderModalSearch(
    e: React.KeyboardEvent<HTMLInputElement>
  ) {
    if (e.key === "Enter") {
      dispatch(fetchAllFolder({ q: moveFolderModalSearchValue }));
      if (
        !(
          "uncategorized media".includes(
            moveFolderModalSearchValue?.toLowerCase() || ""
          ) || moveFolderModalSearchValue === null
        )
      ) {
        setIsShowUncategorizedFolder(false);
      } else {
        setIsShowUncategorizedFolder(true);
      }
    }
  }

  async function onHandleMove(destinationMediaFolderId: string) {
    onMoveMedia(destinationMediaFolderId);
    dispatch(fetchFolderById(currentFolderId));
    // setSelectedFileIndex(null);
    // setSelectedFile(null);
    setSelectedMoveFolder("");
    // setMoveFolderModalOpened(false);
  }

  async function onChangeFolderName(e: string) {
    setFolderData(onHandleChangeEditableData(folderData, "name", e));
  }

  async function onHandleRenameFolder(data?: IFolder) {
    await dispatch(updateFolder({ id: data?.id, data: { ...data } }));
    dispatch(
      fetchAllFolder({
        q: searchParams.get("q"),
        page: searchParams.get("page"),
      })
    );
    dispatch(fetchFolderById(currentFolderId));
    setIsShowRenameModal(false);
  }

  async function onHandleDeleteFolder(folderId: string) {
    await deleteFolderAPI(folderId);
    navigate(pathToNavigateAfterAnAction);
  }

  useEffect(() => {
    dispatch(fetchFolderById(parentFolderMediaId));
    dispatch(setCurrentFolderId(parentFolderMediaId));

    return () => {
      dispatch(resetMediaItemDetail());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const parentFolderId = parentFolderMediaId;
    fetchData(parentFolderId, {
      q: searchParams.get("q") || "",
      page: searchParams.get("page") || "",
      limit: "30",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, dispatch]);

  useEffect(() => {
    setFolderData(currentFolder);
  }, [currentFolder]);

  return currentFolder ? (
    <Container>
      <>
        {
          <Modal
            onClose={() => {
              // setMoveFolderModalOpened(false);
              onCloseMoveFolderModal();
              // setSelectedFileIndex(null);
              // setSelectedFile(null);
              setSelectedMoveFolder("");
            }}
            opened={moveFolderModalOpened}
            title={"Move to ..."}
            size={605}
            primaryButtonTitle={"Move"}
            secondaryButtonTitle={"Cancel"}
            isDisablePrimaryButton={!Boolean(selectedMoveFolder)}
            onClickPrimaryButton={() => onHandleMove(selectedMoveFolder)}
            onClickSecondaryButton={() => {
              onCloseMoveFolderModal();
              setSelectedMoveFolder("");
            }}
          >
            <MoveFolderWrapper>
              <SearchInput
                onKeyDown={(e) => onHandleMoveFolderModalSearch(e)}
                value={moveFolderModalSearchValue || ""}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setMoveFolderModalSearchValue(event.target.value)
                }
              />
              <FolderList
                onClickUncategorizedFolder={() =>
                  setSelectedMoveFolder(
                    selectedMoveFolder === "null" ? "" : "null"
                  )
                }
                onClickFolder={(item) => {
                  setSelectedMoveFolder(
                    selectedMoveFolder === item.id ? "" : item.id
                  );
                }}
                selectedFolder={selectedMoveFolder}
                isShowUncategorizedFolder={isShowUncategorizedFolder}
              />
              <PaginateWrapper>
                <Pagination
                  total={pagination.lastPage}
                  page={pagination.currentPage}
                  // withControls={false}
                />
              </PaginateWrapper>
            </MoveFolderWrapper>
          </Modal>
        }
        {folderData && (
          <Modal
            onClose={() => {
              setFolderData(currentFolder);
              setIsShowRenameModal(false);
            }}
            opened={Boolean(isShowRenameModal)}
            title="Rename Folder"
            size={400}
            onClickPrimaryButton={() => onHandleRenameFolder(folderData)}
            onClickSecondaryButton={() => setIsShowRenameModal(false)}
            primaryButtonTitle={"Rename"}
            secondaryButtonTitle={"Cancel"}
          >
            <>
              <InputWrapper>
                <TextInput
                  data-autofocus
                  value={folderData?.name || ""}
                  onChange={(e) => onChangeFolderName(e.target.value)}
                />
              </InputWrapper>
            </>
          </Modal>
        )}
        {currentFolder && (
          <ConfirmModal
            primaryButtonTitle={"Delete"}
            onClose={() => {
              setIsShowDeleteModal(false);
            }}
            title="Delete Folder"
            opened={isShowDeleteModal}
            onClickPrimaryButton={() => {
              onHandleDeleteFolder(currentFolder?.id || "");
              setIsShowDeleteModal(false);
            }}
          >
            <div>
              Are you sure you want to delete{" "}
              <b>‘{currentFolder?.name || ""}’</b>?
            </div>
            <div style={{ marginTop: "16px" }}>
              Once you confirm, this folder and all files inside will be deleted
              from the list but files will be available in the contents that
              used it.
            </div>
          </ConfirmModal>
        )}
      </>
      <Breadcrumb
        breadcrumbStyle="grey"
        separator={<FontAwesomeIcon icon={["fas", "angle-right"]} />}
        data={[
          { value: pathToNavigateAfterAnAction, label: breadcrumbLabel },
          { value: null, label: currentFolder?.name || "" },
        ]}
        onClickBack={() => {
          navigate(pathToNavigateAfterAnAction);
        }}
      />
      <Heading
        backLink={pathToNavigateAfterAnAction}
        onClickBack={() => {}}
        title={currentFolder?.name || ""}
        actionComponent={
          <ButtonGroup>
            <ModalUpload
              supportingType={supportingType}
              loadData={() => {
                dataLoaderAfterCloseModalUpload();
              }}
              parentFolderId={parentFolderMediaId}
              isAttachment={isAttachment}
            />
            {id !== "uncategorized" && (
              <DropdownButton
                buttonProps={{
                  children: (
                    <FontAwesomeIcon icon={["far", "ellipsis-vertical"]} />
                  ),
                  buttonStyle: "outline-light",
                }}
                dropdownMenuProps={{
                  maxWidth: "240px",
                  padding: "16px",
                  data: [
                    {
                      icon: ["far", "pen"],
                      label: "Rename folder",
                      onClick: () => {
                        setIsShowRenameModal(true);
                      },
                    },
                    {
                      icon: ["far", "trash-can"],
                      label: "Delete folder",
                      onClick: () => {
                        setIsShowDeleteModal(true);
                      },
                    },
                  ],
                }}
              />
            )}
          </ButtonGroup>
        }
      />
      <Section>
        <DataTable
          header={tableHeader ? tableHeader : <></>}
          onSearchData={(value) => {
            searchParams.set("q", value);
            setSearchParams(searchParams);
          }}
          currentPage={parseInt(searchParams.get("page") as string, 10) || 1}
          totalPages={paginationMediaItem.lastPage}
          onChangePage={(page) =>
            navigate({
              pathname: pathToSearch,
              search: qs.stringify(
                {
                  q: searchParams.get("q") || undefined,
                  page,
                },
                { addQueryPrefix: true, skipNulls: true }
              ),
            })
          }
          additionalInfoElement={additionalInfoElement}
        >
          {children}
        </DataTable>
      </Section>
    </Container>
  ) : (
    <div style={{ margin: "auto" }}>
      <Loader color="gray" />
    </div>
  );
}
