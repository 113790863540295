import styled from "@emotion/styled/macro";

export const Container = styled.div``;

export const Title = styled.div``;

export const CloseButton = styled.div``;

export const ModalBody = styled.div<{ isHasFooter: boolean }>`
  padding: 24px;
  max-height: calc(90vh - 160px);
  overflow: auto;
  padding-top: 0;
  /* padding-bottom: ${(props) => props.isHasFooter && "0px"}; */
  padding-bottom:  ${(props) => props.isHasFooter ? "4px" : "24px"};
`;

export const ModalFooter = styled.div`
  padding: 24px;
`;

export const ModalButtonGroup = styled.div`
  gap: 12px;
  display: flex;
`;

export const ButtonWrapper = styled.div`
  flex: 1;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey600);
  margin-bottom: 24px;
  margin-top: 4px;
  padding: 0 24px;
`;
