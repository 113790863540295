import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { Container as PreviewArea } from '../../page-builder-components/organisms/previewArea/PreviewArea.style';
import { maxLayout } from '../../utils/breakpoints';
import { Content } from '../../page-builder-components/molecules/baseSidebar/BaseSidebar.style';

export const Container = styled.div`
  background-color: var(--grey100);
  overflow: hidden;

  ${Content} {
    padding-bottom: var(--space-xs);
  }

  @media ${maxLayout.sm} {
    ${PreviewArea} {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const LinkItem = styled(Link)`
  display: block;
  color: var(--grey1000);
  padding: var(--space-xs) var(--space-sm);
  transition: color 150ms ease;

  :hover {
    color: var(--primary600);
  }
`;

export const UserMenuItem = styled.p`
  cursor: pointer;
  display: block;
  color: var(--grey900);
  padding: var(--space-s) 0;
  transition: color 150ms ease;

  svg {
    position: relative;
    top: 0.5px;
  }

  :hover {
    color: var(--ci);
  }
`;

export const LoadingContainer = styled.div`
  margin-top: var(--space-xs);

  svg {
    margin-bottom: 16px;
  }
`;

export const UserCard = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: var(--space-base);
  padding-right: var(--space-base);
  padding-bottom: 12px;
  padding-left: var(--space-base);
  width: 100%;

  //background-color: var(--grey300);
  border-top: 1px solid var(--grey400);
  
  > div:first-child {
    margin-bottom: 12px;
  }
`;
