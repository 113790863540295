import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, useEffect, useState } from "react";
import { ImageInput } from "../../atoms/imageInput/ImageInput";
import { TextArea } from "../../atoms/textArea/TextArea";
import { ContentDivider } from "../../molecules/contentDivider/ContentDivider";
import { PageHeader } from "../../organisms/pageHeader/PageHeader";
import { Section } from "../../organisms/section/Section";
import { SectionMedia } from "../../organisms/section/sectionMedia/SectionMedia";
import { SeoSection } from "../../organisms/section/seoSection/SeoSection";
import { SidePanel } from "../../organisms/sidePanel/SidePanel";
import { mapImageInputBrandPage } from "../../types/mapMediaSection";
import { onCastIUploadDataToEmbeddedImageData } from "../../utils/castType";
import { formatIsoLanguage, formatFullDateTime } from "../../utils/format";

import { Button } from "../../atoms/button/Button";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  brandSelector,
  fetchBrandById,
  resetBrand,
  setTempBrand,
} from "../../store/features/brand";
import {
  fetchProductTypes,
  fetchProductTypeSelectItems,
  productTypeSelector,
} from "../../store/features/productType";
import {
  currency,
  IBrandEditable,
  IMediaResponse,
  initBrand,
  priceUnit,
} from "../../types";
import {
  onHandleChangeArrayImage,
  onHandleChangeEditableData,
} from "../../utils/dataService";

import {
  ContentContainer,
  ContentHolder,
  ContentWrapper,
  MediaInputWrapper,
  ScrollWrapper,
  SidePanelContainer,
  FlexContainer,
  SelectLanguageWrapper,
} from "../../globalStyle/styles/ManagePage.style";
import {
  SidePanelTextGroup,
  SubTitle,
  SubText,
} from "../../organisms/projectSidePanel/ProjectSidePanel.style";
import { Select } from "../../atoms/select/Select";
import { TextInput } from "../../atoms/textInput/TextInput";
import { ConfirmModal } from "../../organisms/confirmModal/ConfirmModal";
import { Loader } from "../../atoms/loading/loader/Loader";
import { SectionTheme } from "../../organisms/section/sectionTheme/SectionTheme";
import { SectionGallery } from "../../organisms/section/sectionGallery/SectionGallery";
import { useNavigate } from "react-router-dom";
import { ButtonGroup } from "../manageProject/ManageProject.style";
import {
  fetchCurrentPage,
  pageSelector,
  resetCurrentPage,
} from "../../store/features/page";

interface ManageBrandProps {
  brandId?: string;
  pageTitle: string;
  isCreatePage?: boolean;
  isShowUpdatedAt?: boolean;
  isShowPreviewButton?: boolean;
  submitButtonTitle?: string;
  onClickSubmit?: (data: IBrandEditable) => void;
  onClickPreview?: () => void;
  onClickDelete?: () => void;
  onClickCreate?: (data: IBrandEditable) => void;
}

export function ManageBrand(props: ManageBrandProps) {
  const {
    brandId,
    pageTitle,
    isShowUpdatedAt,
    isCreatePage,
    submitButtonTitle,
    onClickSubmit,
    onClickDelete,
    onClickCreate,
    isShowPreviewButton,
    onClickPreview,
  } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentBrand: brand } = useAppSelector(brandSelector);
  const { id: currentPageId } = useAppSelector(pageSelector);
  const { productTypeSelectItems } = useAppSelector(productTypeSelector);

  const [brandData, setBrandData] = useState<IBrandEditable | null>(null);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);

  const [isShowConfirmCreatePageModal, setIsShowConfirmCreatePageModal] =
    useState<boolean>(false);
  const [brandNameError, setBrandNameError] = useState<string>("");
  const [brandCodeError, setBrandCodeError] = useState<string>("");
  const [brandProductTypeError, setBrandProductTypeError] =
    useState<string>("");

  function onHandleChangeBrandData(path: string, value: any) {
    setBrandData(onHandleChangeEditableData(brandData, path, value));
    dispatch(setTempBrand(onHandleChangeEditableData(brandData, path, value)));
  }

  function checkIsValidData(data: IBrandEditable) {
    return data?.name && data?.slugCode && data?.productTypeId;
  }

  function setErrorData(data: IBrandEditable) {
    if (!data?.name) {
      setBrandNameError("Please enter a name.");
    }
    if (!data?.slugCode) {
      setBrandCodeError("Please enter a code.");
    }
    if (!data?.productTypeId) {
      setBrandProductTypeError("Please select a product type.");
    }
  }

  function onHandleChangeImages(
    img: IMediaResponse | null,
    device: string | null,
    key: string,
    useFor?: string | null
  ) {
    if (brandData) {
      const newData = onHandleChangeArrayImage(
        brandData,
        img,
        device,
        key,
        useFor
      );
      if (onClickSubmit) {
        onClickSubmit(newData);
      }
      setBrandData(newData);
    }
  }

  function loadData() {
    if (brandId) {
      dispatch(fetchBrandById(brandId));
      dispatch(fetchCurrentPage(`resource:${brandId}`));
    }
  }

  function loadDataSource(languageCode: string) {
    dispatch(
      fetchProductTypeSelectItems({
        languageCode: languageCode,
        usedIn: "project",
      })
    );
    dispatch(
      fetchProductTypes({
        languageCode: languageCode,
        usedIn: "project",
      })
    );
  }

  function reset() {
    dispatch(resetBrand());
    dispatch(resetCurrentPage());

    // dispatch(resetCreatePageState());
    // dispatch(resetTempBrand());
  }

  // load data
  useEffect(() => {
    loadData();

    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (brandData?.languageCode) {
      loadDataSource(brandData.languageCode);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandData?.languageCode]);

  useEffect(() => {
    if (brand) {
      setBrandData({
        ...brand,
        price: brand.price
          ? { ...brand.price, unit: priceUnit, currency: currency }
          : null,
        theme: { ...initBrand.theme, ...brand.theme },
      });
    } else {
      setBrandData(initBrand);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand]);

  return brandData ? (
    <>
      <PageHeader
        title={pageTitle}
        backLink="/brand"
        onClickBack={() => {}}
        rightAction={
          <ButtonGroup>
            {onClickDelete && (
              <Button
                buttonStyle="outline-light"
                onClick={() => setIsShowDeleteModal(true)}
              >
                <FontAwesomeIcon icon={["far", "trash-can"]} />
              </Button>
            )}
            {onClickCreate && (
              <Button
                onClick={() => {
                  if (onClickCreate) {
                    if (checkIsValidData(brandData)) {
                      onClickCreate(brandData);
                    } else {
                      setErrorData(brandData);
                    }
                  }
                }}
              >
                Create
              </Button>
            )}
          </ButtonGroup>
        }
      />
      <ConfirmModal
        onClose={() => {
          setIsShowDeleteModal(false);
        }}
        title="Delete Brand"
        opened={isShowDeleteModal}
        onClickPrimaryButton={onClickDelete}
      >
        <div>
          Are you sure you want to delete <b>‘{brandData.name || ""}’</b>?
        </div>
        <div style={{ marginTop: "16px" }}>
          Once you confirm, this brand will be permanently deleted and also
          removed from all contents that use it.
        </div>
      </ConfirmModal>
      <ConfirmModal
        onClose={() => {
          setIsShowConfirmCreatePageModal(false);
        }}
        title="Create Brand Page"
        primaryButtonStyle="primary"
        primaryButtonTitle="Create"
        opened={isShowConfirmCreatePageModal}
        onClickPrimaryButton={() => {
          setIsShowConfirmCreatePageModal(false);
          onClickPreview && onClickPreview();
        }}
      >
        {isCreatePage ? (
          <div>
            Once you confirm, it will create a brand and a page for this brand.
          </div>
        ) : (
          <div>Once you confirm, it will create a page for this brand.</div>
        )}
      </ConfirmModal>
      <FlexContainer>
        <ContentContainer>
          <ScrollWrapper>
            <Section
              headerButtonStyle="primary"
              headerButtonTitle={submitButtonTitle}
              onClickHeaderButton={
                onClickSubmit ? () => onClickSubmit(brandData) : undefined
              }
              title="General Information"
            >
              <ContentHolder>
                <ContentWrapper>
                  <TextInput
                    label="Name"
                    value={brandData?.name || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      onHandleChangeBrandData("name", e.target.value);
                      setBrandNameError("");
                    }}
                    error={brandNameError}
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <TextInput
                    label="Name (Alternative)"
                    value={brandData?.altName || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      onHandleChangeBrandData("altName", e.target.value);
                    }}
                  />
                </ContentWrapper>

                <ContentWrapper>
                  <TextInput
                    label="Slug (Code)"
                    value={brandData?.slugCode || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      onHandleChangeBrandData("slugCode", e.target.value);
                      setBrandCodeError("");
                    }}
                    error={brandCodeError}
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <TextArea
                    label="Description"
                    value={brandData?.description || ""}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      onHandleChangeBrandData("description", e.target.value)
                    }
                    placeholder={"Enter a description"}
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <Select
                    label="Product type"
                    value={brandData.productTypeId}
                    data={productTypeSelectItems}
                    onChange={(e) => {
                      onHandleChangeBrandData("productTypeId", e);
                      setBrandProductTypeError("");
                    }}
                    error={brandProductTypeError}
                  />
                </ContentWrapper>
                <ContentWrapper />
                <div style={{ marginBottom: 24 }}>
                  <ContentDivider title={"Price"} />
                </div>
                <ContentWrapper>
                  <TextInput
                    label="Min price"
                    value={brandData?.price?.minValue || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onHandleChangeBrandData("price.minValue", e.target.value)
                    }
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <TextInput
                    label="Max price"
                    value={brandData?.price?.maxValue || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onHandleChangeBrandData("price.maxValue", e.target.value)
                    }
                  />
                </ContentWrapper>
              </ContentHolder>
            </Section>
            <SectionMedia
              mediaSection={
                <>
                  <ImageInput
                    {...mapImageInputBrandPage[0]}
                    previousImageData={brandData.logo}
                    onClickChooseImage={(img) => {
                      onHandleChangeBrandData(
                        "logo",
                        onCastIUploadDataToEmbeddedImageData(img, null) || null
                      );
                      if (onClickSubmit) {
                        onClickSubmit(
                          onHandleChangeEditableData(
                            brandData,
                            "logo",
                            onCastIUploadDataToEmbeddedImageData(img, null) ||
                              null
                          )
                        );
                      }
                    }}
                    data={{
                      imgUrl: brandData.logo?.originalUrl || "",
                      imgAlt: brandData.logo?.name || "",
                    }}
                  />
                  <MediaInputWrapper>
                    {mapImageInputBrandPage.slice(1, 4).map((item, index) => (
                      <ImageInput
                        {...item}
                        key={`${item.label}-${index + 10}`}
                        previousImageData={_.find(brandData.banners, {
                          deviceType: item.device,
                          useFor: item.usedFor,
                        })}
                        onClickChooseImage={(img) => {
                          onHandleChangeImages(
                            img,
                            item.device || null,
                            "banners",
                            item.usedFor
                          );
                        }}
                        data={{
                          imgUrl: _.get(
                            _.find(brandData.banners, {
                              deviceType: item.device,
                            }),
                            "originalUrl",
                            ""
                          ),
                          imgAlt: _.get(
                            _.find(brandData.banners, {
                              deviceType: item.device,
                            }),
                            "name",
                            ""
                          ),
                        }}
                      />
                    ))}
                  </MediaInputWrapper>
                  <MediaInputWrapper>
                    {mapImageInputBrandPage.slice(4).map((item, index) => (
                      <ImageInput
                        {...item}
                        key={`${item.label}-${index + 10}`}
                        previousImageData={_.find(brandData.thumbnails, {
                          deviceType: item.device,
                          useFor: item.usedFor,
                        })}
                        onClickChooseImage={(img) => {
                          onHandleChangeImages(
                            img,
                            item.device || null,
                            "thumbnails",
                            item.usedFor
                          );
                        }}
                        data={{
                          imgUrl: _.get(
                            _.find(brandData.thumbnails, {
                              deviceType: item.device,
                            }),
                            "originalUrl",
                            ""
                          ),
                          imgAlt: _.get(
                            _.find(brandData.thumbnails, {
                              deviceType: item.device,
                            }),
                            "name",
                            ""
                          ),
                        }}
                      />
                    ))}
                  </MediaInputWrapper>
                </>
              }
            />
            <SectionTheme
              themeData={brandData.theme}
              onHandleChangeData={(path, data) => {
                onHandleChangeBrandData(path, data);
              }}
              onUpdateData={
                onClickSubmit ? () => onClickSubmit(brandData) : undefined
              }
            />
            <SectionGallery
              galleries={brandData.galleries}
              title={"Gallery"}
              onChangeImagesData={(value) => {
                onHandleChangeBrandData("galleries", value);
                if (onClickSubmit) {
                  onClickSubmit(
                    onHandleChangeEditableData(brandData, "galleries", value)
                  );
                }
              }}
            />
            <SeoSection
              onClickSave={
                onClickSubmit ? () => onClickSubmit(brandData) : undefined
              }
              data={{
                ...brandData,
                ogImage: brandData?.openGraphImage,
              }}
              onChangeSeoTitle={(e: string) =>
                onHandleChangeBrandData("seoTitle", e)
              }
              onChangeSeoDescription={(e: string) =>
                onHandleChangeBrandData("seoDescription", e)
              }
              onChangeOgTitle={(e: string) =>
                onHandleChangeBrandData("ogTitle", e)
              }
              onChangeOgDescription={(e: string) =>
                onHandleChangeBrandData("ogDescription", e)
              }
              onChangeOgImage={(img) =>
                onHandleChangeBrandData(
                  "openGraphImage",
                  onCastIUploadDataToEmbeddedImageData(img, null)
                )
              }
            />
          </ScrollWrapper>
        </ContentContainer>
        <SidePanel
          sidePanelAction={
            <SidePanelContainer>
              <SelectLanguageWrapper>
                {brand ? (
                  <Select
                    disabled
                    label={"Language"}
                    data={[
                      {
                        label: formatIsoLanguage(brand.languageCode),
                        value: brand.languageCode,
                      },
                    ]}
                    value={brand.languageCode}
                    onChange={(e) => {}}
                  />
                ) : (
                  <Select
                    label={"Language"}
                    data={[{ value: "th", label: "Thai" }]}
                    value={brandData.languageCode}
                    onChange={(e) => onHandleChangeBrandData("languageCode", e)}
                  />
                )}
              </SelectLanguageWrapper>
              {isShowPreviewButton && (
                <>
                  <ContentDivider title="Page Builder" />
                  <>
                    {brandData ? (
                      <>
                        {currentPageId ? (
                          <>
                            <Select
                              label={"Visibility"}
                              data={[
                                { value: "public", label: "Public" },
                                { value: "unlisted", label: "Unlisted" },
                              ]}
                              value={brandData.visibilityStatus}
                              onChange={(e) => {
                                onHandleChangeBrandData("visibilityStatus", e);
                              }}
                            />
                            <Select
                              label={"Publish status"}
                              data={[
                                { value: "published", label: "Published" },
                                { value: "draft", label: "Draft" },
                                { value: "scheduled", label: "Scheduled" },
                              ]}
                              value={brandData.publishStatus}
                              onChange={(e) => {
                                onHandleChangeBrandData("publishStatus", e);
                              }}
                            />
                            <SidePanelTextGroup>
                              <SubTitle>Published at</SubTitle>
                              <SubText>
                                {brand?.publishedAt || "5 Nov 2022 at 14:15"}
                              </SubText>
                            </SidePanelTextGroup>
                            <div
                              style={{ display: "flex", justifyContent: "end" }}
                            >
                              <Button
                                buttonStyle="outline-light"
                                onClick={() => {
                                  navigate(
                                    `/page-builder/page/${currentPageId}`
                                  );
                                }}
                                rightIcon={
                                  <FontAwesomeIcon
                                    icon={["far", "arrow-right-arrow-left"]}
                                  />
                                }
                              >
                                Switch to page builder
                              </Button>
                            </div>
                          </>
                        ) : (
                          <Button
                            buttonStyle="outline-light"
                            onClick={() => {
                              if (checkIsValidData(brandData)) {
                                setIsShowConfirmCreatePageModal(true);
                              } else {
                                setErrorData(brandData);
                              }
                            }}
                            leftIcon={
                              <FontAwesomeIcon icon={["far", "plus"]} />
                            }
                          >
                            Create Page
                          </Button>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </>
              )}
              {isShowUpdatedAt && brand && (
                <>
                  <ContentDivider />
                  <ContentWrapper>
                    <SidePanelTextGroup>
                      <SubTitle>Updated at</SubTitle>
                      <SubText>
                        {formatFullDateTime(new Date(brand.updatedAt))}
                      </SubText>
                    </SidePanelTextGroup>
                  </ContentWrapper>
                </>
              )}
            </SidePanelContainer>
          }
        />
      </FlexContainer>
    </>
  ) : (
    <div style={{ margin: "auto" }}>
      <Loader color="gray" />
    </div>
  );
}
