import _ from "lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import {
  createHouseCollection,
  houseCollectionSelector,
  resetHouseCollectionDetailData,
} from "../../store/features/houseCollection";
import { addToast } from "../../store/features/toast";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ManageHouseCollection } from "../../templates/manageHouseCollection/ManageHouseCollection";
import { lang } from "../../types";

export default function CreateHouseCollection() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const languageCode = lang;
  const { currentHouseCollection: houseCollection, createHouseCollectionState } = useAppSelector(
    houseCollectionSelector
  );

  useEffect(() => {
    dispatch(resetHouseCollectionDetailData());
  }, [dispatch])

  const onClickSave = async () => {
    const contentVariation = _.find(houseCollection?.contentVariations, { languageCode: languageCode }) || { description: '' };

    await dispatch(createHouseCollection({
      ..._.omit(houseCollection, "contentVariations"),
      contentVariation: {
        ...contentVariation,
        description: contentVariation?.description || ''
      },
    }));
  }

  useEffect(() => {
    const status = createHouseCollectionState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully created" }));
      navigate('/house-collection');
    }
    if (status === "error")
      dispatch(addToast({ status, title: "Create failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createHouseCollectionState.status, dispatch]);

  return (
    <ManageHouseCollection
      pageTitle="Create House Collection"
      onClickSubmit={onClickSave}
      submitButtonTitle="Create"
      isShowAddLanguageButton={false}
    />
  );
}
