import {
  createAction,
  createAsyncThunk as nativeCreateAsyncThunk,
} from "@reduxjs/toolkit";
// import createAsyncThunk from "../../middleware/customCreateThunk";
import { uploadImage } from "../../../services/api";
import { IUploadProgress } from "../../../types";
import axios, { AxiosProgressEvent, CancelTokenSource } from "axios";
import { generateUniqueId } from "../../../utils/key";
import { RootState } from "../..";
import { UploadState } from "../../../atoms/uploadItem/UploadItem";
import { uploadAttachment } from "../../../services/api/attachment";

export const uploadFile = nativeCreateAsyncThunk(
  "Upload/uploadFile",
  async (
    payload: {
      file: File;
      fileRetryId?: string;
      selectedFolder: string | null;
      isAttachment?: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    function onUploadProgress(e: AxiosProgressEvent, fileId: string) {
      if (e.total) {
        const percentCompleted = Math.round((e.loaded * 100) / e.total);
        dispatch(
          setUploadProgress({
            data: {
              parentMediaFolderId: payload.selectedFolder,
              uploadProgress: percentCompleted,
              fileSize: e.bytes,
              loaded: e.loaded,
              total: e.total,
            },
            fileId,
          })
        );
      }
    }

    const source = axios.CancelToken.source();
    const fileId = payload.fileRetryId
      ? payload.fileRetryId
      : generateUniqueId();
    dispatch(setUploadFiles({ file: payload.file, fileId: fileId, source }));
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("name", payload.file.name);
    formData.append("parentMediaFolderId", `${payload.selectedFolder}`);

    try {
      let response;
      if (payload.isAttachment) {
        response = await uploadAttachment({
          file: formData,
          fileId: fileId,
          onUploadProgress: onUploadProgress,
          cancelToken: source,
        });
      } else {
        response = await uploadImage({
          file: formData,
          fileId: fileId,
          onUploadProgress: onUploadProgress,
          cancelToken: source,
        });
      }
      dispatch(updateFileById({ fileId, uploadInfo: { state: "uploaded" } }));
      return { data: response?.data || response, fileId: fileId };
    } catch (error: any) {
      dispatch(
        updateFileById({
          fileId: fileId,
          uploadInfo: { state: "error" },
        })
      );
      const { response = {} } = error || {};
      const { status = 404, data = {} } = response || {};
      return rejectWithValue({ status, data });
    }
  }
);

export const retryUploadFile = nativeCreateAsyncThunk(
  "Upload/retryUpload",
  async (
    payload: { fileId: string, isAttachment?:boolean },
    { dispatch, rejectWithValue, getState }
  ) => {
    const state = getState() as RootState;
    const files = state.upload.files;

    try {
      const filesObjIndex = files.findIndex(
        (data: any) => data.fileId === payload.fileId
      );
      const file = files[filesObjIndex];
      dispatch(
        updateFileById({
          fileId: payload.fileId,
          uploadInfo: { state: "prepare" },
        })
      );
      await dispatch(
        uploadFile({
          file: file.file,
          fileRetryId: file.fileId,
          selectedFolder: file.uploadProgress.parentMediaFolderId,
          isAttachment:payload.isAttachment
        })
      );
      // dispatch(removeFileUploadById(file.fileId));
    } catch (error: any) {
      const status = error?.response?.status || 404;
      const data = error?.response?.data;
      return rejectWithValue({ status, data });
    }
  }
);

export const updateFileById = createAction<{
  fileId: string;
  uploadInfo: {
    state: UploadState;
  };
}>("Upload/updateFileUploadById");

export const removeFileUploadById = createAction<string>(
  "Upload/removeFileUploadById"
);

export const setUploadProgress = createAction<{
  data: IUploadProgress;
  fileId: string;
}>("Upload/setUploadProgress");

export const setUploadFiles = createAction<{
  file: File;
  fileId: string;
  source: CancelTokenSource;
}>("Upload/setUploadFiles");

export const setUploadingFolder = createAction<string>(
  "Upload/setUploadingFolder"
);

export const clearUploadingFolder = createAction<number>(
  "Upload/clearUploadingFolder"
);

export const resetUploadFileById = createAction<string>(
  "Upload/resetUploadFileById"
);

export const cancelUploadFile = createAction<string>("Upload/cancelUpload");

export const clearUploaded = createAction<{ index: number; fileId: string }>(
  "Upload/clearFinishedUpload"
);
export const resetUpload = createAction("Upload/reset");
