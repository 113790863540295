/* eslint-disable react/jsx-props-no-spreading */
import { ButtonHTMLAttributes } from "react";
import { createStyles, Button as CoreButton } from "@mantine/core";
import { ButtonProps as CoreButtonProps } from "@mantine/core/lib/Button/Button";
import { MantineTheme } from "@mantine/styles/lib/theme";
import { Link } from "react-router-dom";

export type ButtonStyles =
  | "primary"
  | "secondary"
  | "secondary-grey"
  | "outline-light"
  | "text-primary"
  | "text-link-primary"
  | "text-link-grey"
  | "text-link-danger"
  | "text-link-warning"
  | "danger"
  | "warning";

const useStyles = createStyles((theme: MantineTheme, props: ButtonProps) => {
  const {
    shape = "box",
    buttonStyle = "primary",
    fontSize = "md",
    fullWidth = false,
    height,
    width,
    position,
    padding,
    borderradius,
  } = props;
  const buttonStyleColor = {
    primary: {
      backgroundColor: "var(--primary600)",
      borderColor: "var(--primary600)",
      boxShadow: "box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05)",
      "&:hover": {
        backgroundColor: "var(--primary700)",
        borderColor: "var(--primary700)",
      },
      color: "white",
      variant: "filled",

      "&:disabled": {
        backgroundColor: "var(--primary200)",
        borderColor: "var(--primary200)",
        color: "white",
      },

      "&:focus": {
        backgroundColor: "var(--primary600)",
        borderColor: "var(--primary600)",
        color: "white",
        boxShadow:
          "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--primary100)",
      },
    },
    secondary: {
      backgroundColor: "var(--primary50)",
      borderColor: "var(--primary50)",
      color: "var(--primary700)",
      variant: "filled",

      "&:hover": {
        backgroundColor: "var(--primary100)",
        borderColor: "var(--primary100)",
        color: "var(--primary800)",
      },

      "&:disabled": {
        backgroundColor: "var(--primary25)",
        borderColor: "var(--primary25)",
        color: "var(--primary300)",
      },

      "&:focus": {
        backgroundColor: "var(--primary50)",
        borderColor: "var(--primary50)",
        color: "var(--primary700)",
        boxShadow:
          "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--primary100)",
      },
    },
    "secondary-grey": {
      backgroundColor: "var(--grey200)",
      borderColor: "var(--grey200)",
      color: "var(--grey600)",
      variant: "filled",

      "&:hover": {
        backgroundColor: "var(--grey300)",
        borderColor: "var(--grey300)",
        color: "var(--grey600)",
      },

      "&:disabled": {
        backgroundColor: "var(--grey50)",
        borderColor: "var(--grey50)",
        color: "var(--grey300)",
      },

      "&:focus": {
        backgroundColor: "var(--grey200)",
        borderColor: "var(--grey200)",
        color: "var(--grey600)",
        boxShadow:
          "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--grey100)",
      },
    },
    "outline-light": {
      backgroundColor: "var(--white)",
      borderColor: "var(--grey300)",
      color: "var(--grey700)",
      variant: "outline",

      "&:hover": {
        backgroundColor: "var(--grey50)",
        borderColor: "var(--grey300)",
      },

      "&:focus": {
        boxShadow:
          "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--grey100)",
      },
    },
    "text-primary": {
      variant: "subtle",
      color: theme.colors.ci,
      "&:hover": {
        backgroundColor: theme.colors.primary[1],
      },
    },
    "text-link-primary": {
      variant: "subtle",
      color: "var(--primary700)",
      padding: "0",
      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline",
      },
    },
    "text-link-grey": {
      variant: "subtle",
      color: "var(--grey600)",
      padding: "0",
      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline",
      },

      "&:disabled": {
        backgroundColor: "transparent",
        color: "var(--grey300)",
      },
    },
    "text-link-danger": {
      variant: "subtle",
      color: "var(--error700)",
      padding: "0",
      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline",
      },
    },
    "text-link-warning": {
      variant: "subtle",
      color: "var(--warning700)",
      padding: "0",
      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline",
      },
    },
    danger: {
      backgroundColor: "var(--error600)",
      borderColor: "var(--error600)",
      color: "white",
      variant: "filled",
      "&:hover": {
        backgroundColor: "var(--error500)",
        borderColor: "var(--error500)",
      },
      "&:disabled": {
        backgroundColor: "var(--error200)",
        borderColor: "var(--error200)",
        color: "white",
      },
    },
    warning: {
      backgroundColor: "var(--warning600)",
      borderColor: "var(--warning600)",
      color: "white",
      variant: "filled",
      "&:hover": {
        backgroundColor: "var(--warning500)",
        borderColor: "var(--warning500)",
      },
      "&:disabled": {
        backgroundColor: "var(--warning200)",
        borderColor: "var(--warning00)",
        color: "white",
      },
    },
  };
  const buttonStyleLabelText = {
    md: {
      fontSize: 16,
    },
    sm: {
      fontSize: 14,
    },
    xs: {
      fontSize: 12,
    },
  };

  return {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: position ? `${position}` : "center",
      height: height ? height : 40,
      position: "relative",
      // paddingLeft: theme.spacing.sm,
      // paddingRight: theme.spacing.sm,
      padding: padding ? padding : "0 16px",
      fontWeight: 600,
      // fontSize: theme.fontSizes.md,
      borderRadius: borderradius
        ? borderradius
        : shape === "pill"
        ? "50px"
        : "8px",
      transition: "background-color 150ms ease",
      width: fullWidth ? "100%" : width ? width : "fit-content",
      ...buttonStyleColor[buttonStyle],
    },
    inner: {
      width: fullWidth ? "100%" : width ? width : "fit-content",
      justifyContent: position ? `${position}` : "center",
    },
    label: {
      fontWeight: 600,
      ...buttonStyleLabelText[fontSize],
    },
  };
});

export interface ButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "color">,
    CoreButtonProps {
  children: JSX.Element | string | number;
  /* @default 'box' */
  shape?: "box" | "pill";
  buttonStyle?: ButtonStyles;
  fontSize?: "xs" | "sm" | "md";
  url?: string;
  isExternalUrl?: boolean;
  height?: string;
  width?: string;
  position?: "center" | "left" | "right";
  padding?: string;
  borderradius?: string;
}

/**
 * Documentation: {@link https://mantine.dev/core/button/}
 */

function ButtonComponent(props: ButtonProps) {
  const { classes } = useStyles(props);
  const { children } = props;

  // Filter some props out to fix html warning.
  const filteredProps = Object.keys(props)
    .filter((key) => !["buttonStyle", "url", "isExternalUrl"].includes(key))
    .reduce((obj: any, key) => {
      obj[key as keyof ButtonProps] = props[key as keyof ButtonProps];
      return obj;
    }, {});

  return (
    <CoreButton {...filteredProps} classNames={classes}>
      {children}
    </CoreButton>
  );
}
export function Button(props: ButtonProps) {
  const { url, isExternalUrl = false } = props;

  if (url) {
    if (isExternalUrl) {
      return (
        <a href={url} target="_blank" rel="noreferrer">
          <ButtonComponent {...props} />
        </a>
      );
    }
    return (
      <Link to={url}>
        <ButtonComponent {...props} />
      </Link>
    );
  }

  return <ButtonComponent {...props} />;
}
