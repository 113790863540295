import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import GlobalStyle from "./globalStyle/GlobalStyle";
import { PageLayout } from "./templates/pageLayout/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavigationSidebar } from "./organisms/navigationSidebar/NavigationSidebar";
import ListExample from "./pages/listExample/ListExample";
import SideFormExample from "./pages/sideFromExample/SideFormExample";
import { ProjectDetail } from "./pages/projectDetail/ProjectDetail";
import { BrandList } from "./pages/brandList/BrandList";
import { ProjectList } from "./pages/projectList/ProjectList";
import { CreateBrand } from "./pages/createBrand/CreateBrand";

import isoLanguages from "@cospired/i18n-iso-languages";
import { ProductTypeList } from "./pages/productTypeList/ProductTypeList";
import { CreateProductType } from "./pages/createProductType/CreateProductType";
import CreateProject from "./pages/createProject/CreateProject";
import { BrandDetail } from "./pages/brandDetail/BrandDetail";
import { ProductTypeDetail } from "./pages/productTypeDetail/ProductTypeDetail";
import { MediaList } from "./pages/mediaList/MediaList";
import { MediaDetail } from "./pages/mediaDetail/MediaDetail";
import HouseCollection from "./pages/houseCollection/HouseCollection";
import HouseCollectionDetail from "./pages/houseCollectionDetail/HouseCollectionDetail";
import CreateHouseCollection from "./pages/createHouseCollection/CreateHouseCollection";
import { UnitTypeList } from "./pages/unitTypeList/UnitTypeList";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { toastSelector } from "./store/features/toast/selectors";
import { Toast } from "./atoms/toast/Toast";
import {
  cleanNotifications,
  cleanNotificationsQueue,
} from "@mantine/notifications";
import { clearAllToast } from "./store/features/toast";
import { UnitTypeDetail } from "./pages/unitTypeDetail/UnitTypeDetail";
import { CreateUnitType } from "./pages/createUnitType/CreateUnitType";
import { AttachmentList } from "./pages/attachmentList/AttachmentList";
import { AttachmentDetail } from "./pages/attachmentDetail/AttachmentDetail";
import { UserDetail } from "./pages/userDetail/UserDetail";
import { UserList } from "./pages/userList/UserList";
import { CreateUser } from "./pages/createUser/CreateUser";
import { NewsAndActivitiesList } from "./pages/Post/newsActivities/NewsAndActivitiesList";
import { CollectionList } from "./pages/Post/collection/CollectionList";
import { LocationSuggestionList } from "./pages/Post/locationSuggestion/LocationSuggestionList";
import { PromotionList } from "./pages/Post/promotion/PromotionList";
import { Page } from "./pages/page/Page";
import { PageList } from "./pages/pageList/PageList";
import { CreateProjectType } from "./pages/createProjectType/CreateProjectType";
import { ProjectTypeDetail } from "./pages/projectTypeDetail/ProjectTypeDetail";
import { ProjectTypeList } from "./pages/projectTypeList/ProjectTypeList";
import { CreateNewAndActivities } from "./pages/Post/newsActivities/CreateNewAndActivities";
import { CreateCollection } from "./pages/Post/collection/CreateCollection";
import { CreateLocationSuggestion } from "./pages/Post/locationSuggestion/CreateLocationSuggestion";
import { CreatePromotion } from "./pages/Post/promotion/CreatePromotion";
import { NewsAndActivitiesDetail } from "./pages/Post/newsActivities/NewsAndActivitiesDetail";
import { CollectionDetail } from "./pages/Post/collection/CollectionDetail";
import { LocationSuggestionDetail } from "./pages/Post/locationSuggestion/LocationSuggestionDetail";
import { PromotionDetail } from "./pages/Post/promotion/PromotionDetail";

isoLanguages.registerLocale(
  require("@cospired/i18n-iso-languages/langs/en.json")
);

const NavigationSidebarComponent = () => {
  const mainMenuList = [
    // {
    //   title: "Dashboard",
    //   icon: <FontAwesomeIcon icon={["far", "pie-chart"]} />,
    //   isActive: false,
    //   url: "/",
    // },
    {
      title: "Post",
      icon: <FontAwesomeIcon icon={["far", "message-lines"]} />,
      isActive: false,
      subMenu: [
        {
          title: "Collection",
          isActive: false,
          url: "/collection",
        },
        {
          title: "Location Suggestion",
          isActive: false,
          url: "/location-suggestion",
        },
        {
          title: "News & Activities",
          isActive: false,
          url: "/news-activities",
        },
        {
          title: "Promotion",
          isActive: false,
          url: "/promotion",
        },
      ],
    },
    {
      title: "Page Builder",
      icon: <FontAwesomeIcon icon={["far", "table-layout"]} />,
      isActive: false,
      url: "/page-builder",
    },
    // {
    //   title: "Best Deal",
    //   icon: <FontAwesomeIcon icon={["far", "badge-percent"]} />,
    //   isActive: false,
    //   url: "/best-deal",
    // },
    {
      title: "Project",
      icon: <FontAwesomeIcon icon={["far", "layer-group"]} />,
      isActive: true,
      subMenu: [
        {
          title: "Project",
          isActive: true,
          url: "/project",
        },
        {
          title: "Project Type",
          isActive: false,
          url: "/project-type",
        },
        {
          title: "Brand",
          isActive: false,
          url: "/brand",
        },
      ],
    },
    {
      title: "Product",
      icon: <FontAwesomeIcon icon={["far", "home"]} />,
      isActive: false,
      subMenu: [
        {
          title: "Unit Type",
          isActive: false,
          url: "/unit-type",
        },
        {
          title: "Product Type",
          isActive: false,
          url: "/product-type",
        },
        {
          title: "House Collection",
          isActive: false,
          url: "/house-collection",
        },
      ],
    },
    {
      title: "File",
      icon: <FontAwesomeIcon icon={["far", "paperclip"]} />,
      isActive: false,
      subMenu: [
        {
          title: "Media",
          isActive: false,
          url: "/media",
        },
        {
          title: "Attachments",
          isActive: false,
          url: "/attachments",
        },
      ],
    },
    // {
    //   title: "Submission",
    //   icon: <FontAwesomeIcon icon={["far", "inbox"]} />,
    //   isActive: false,
    //   subMenu: [
    //     {
    //       title: "Visit Appointment",
    //       isActive: false,
    //       url: "/visit-appointment",
    //     },
    //     {
    //       title: "Buying Land",
    //       isActive: false,
    //       url: "/buying-land",
    //     },
    //     {
    //       title: "Subscribe",
    //       isActive: false,
    //       url: "/subscribe",
    //     },
    //     {
    //       title: "Email Notification",
    //       isActive: false,
    //       url: "/email-notification",
    //     },
    //     {
    //       title: "Loan Calculation",
    //       isActive: false,
    //       url: "/loan-calculation",
    //     },
    //   ],
    // },
    // {
    //   title: "Job",
    //   icon: <FontAwesomeIcon icon={["far", "bullhorn"]} />,
    //   isActive: false,
    //   subMenu: [
    //     {
    //       title: "Job Post",
    //       isActive: false,
    //       url: "/job-post",
    //     },
    //     {
    //       title: "Job Submission",
    //       isActive: false,
    //       url: "/job-submission",
    //     },
    //   ],
    // },
    // {
    //   title: "Membership",
    //   icon: <FontAwesomeIcon icon={["far", "credit-card"]} />,
    //   isActive: false,
    //   url: "/member",
    // },
    {
      title: "User Management",
      icon: <FontAwesomeIcon icon={["far", "user"]} />,
      isActive: false,
      url: "/user-management",
    },
  ];
  const userInformation = {
    title: "Olivia Rhye",
    subtitle: "olivia@untitledui.com",
  };

  return (
    <NavigationSidebar
      mainMenuList={mainMenuList}
      userInformation={userInformation}
      onLogout={() => {}}
    />
  );
};

export default function App() {
  const { toastList } = useAppSelector(toastSelector);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    cleanNotifications();
    cleanNotificationsQueue();
    return () => {
      dispatch(clearAllToast());
    };
  }, [location.pathname, dispatch]);

  return (
    <GlobalStyle>
      {Boolean(toastList && toastList.length) && (
        <>
          {toastList.map((item: any, index: number) => (
            <Toast
              status={item?.status}
              key={index}
              id={item.id}
              title={item.title}
              description={item.description}
            />
          ))}
        </>
      )}
      <PageLayout navigationSidebar={NavigationSidebarComponent} isShowNavigationSidebar={!location.pathname.includes('page-builder')}>
        <Routes>
          <Route path="/project" element={<ProjectList />} />
          <Route path="/project/:id" element={<ProjectDetail />} />
          <Route path="/project/create" element={<CreateProject />} />
          
          <Route path="/brand" element={<BrandList />} />
          <Route path="/brand/:id" element={<BrandDetail />} />
          <Route path="/brand/create" element={<CreateBrand />} />

          <Route path="/product-type" element={<ProductTypeList />} />
          <Route path="/product-type/create" element={<CreateProductType />} />
          <Route path="/product-type/:id" element={<ProductTypeDetail />} />

          <Route path="/project-type" element={<ProjectTypeList />} />
          <Route path="/project-type/create" element={<CreateProjectType />} />
          <Route path="/project-type/:id" element={<ProjectTypeDetail />} />

          <Route path="/media" element={<MediaList />} />
          <Route path="/media/folder/:id/" element={<MediaDetail />} />

          <Route path="/attachments" element={<AttachmentList />} />
          <Route
            path="/attachments/folder/:id"
            element={<AttachmentDetail />}
          />
          <Route path="/ex-list" element={<ListExample />} />
          <Route path="/ex-side-form" element={<SideFormExample />} />

          <Route path="/house-collection" element={<HouseCollection />} />
          <Route
            path="/house-collection/:id/"
            element={<HouseCollectionDetail />}
          />
          <Route
            path="/house-collection/create"
            element={<CreateHouseCollection />}
          />

          <Route path="/unit-type" element={<UnitTypeList />} />
          <Route path="/unit-type/create" element={<CreateUnitType />} />
          <Route path="/unit-type/:id" element={<UnitTypeDetail />} />

          <Route path="/user-management" element={<UserList />} />
          <Route path="/user-management/create" element={<CreateUser />} />
          <Route path="/user-management/:id" element={<UserDetail />} />

          <Route path="/news-activities" element={<NewsAndActivitiesList />} />
          <Route path="/collection" element={<CollectionList />} />
          <Route path="/location-suggestion" element={<LocationSuggestionList />} />
          <Route path="/promotion" element={<PromotionList />} />

          <Route path="/news-activities/create" element={<CreateNewAndActivities />} />
          <Route path="/collection/create" element={<CreateCollection />} />
          <Route path="/location-suggestion/create" element={<CreateLocationSuggestion />} />
          <Route path="/promotion/create" element={<CreatePromotion />} />

          <Route path="/news-activities/:id" element={<NewsAndActivitiesDetail />} />
          <Route path="/collection/:id" element={<CollectionDetail />} />
          <Route path="/location-suggestion/:id" element={<LocationSuggestionDetail />} />
          <Route path="/promotion/:id" element={<PromotionDetail />} />

          <Route path="/page-builder" element={<PageList />} />
          <Route path="/page-builder/page/:pageId" element={<Page />} />
        </Routes>
      </PageLayout>
    </GlobalStyle>
  );
}
