export function validateColor(value: string): boolean {
  const colorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{4})$/;
  return colorRegex.test(value);
}

export function validateSlugCode(value:string):boolean{
  const lowerCaseCharacterRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g;
  return lowerCaseCharacterRegex.test(value);
}

export function validateUsageArea(value:string):boolean{
  const regex = /^[0-9]+(?:[0-9.-])*$/g;
  return regex.test(value);
}