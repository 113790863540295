import _ from "lodash";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addToast } from "../../store/features/toast";
import {
  deleteUnitTypeById,
  unitTypeSelector,
  updateUnitTypeById,
} from "../../store/features/unitType";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ManageUnitType } from "../../templates/manageUnitType/ManageUnitType";
import { lang } from "../../types";
import { IEditableUnitType } from "../../types/unitType";

export function UnitTypeDetail() {
  const languageCode = lang;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: unitTypeId } = useParams();
  const { currentUnitType, updateUnitTypeState, deleteUnitTypeState } = useAppSelector(unitTypeSelector);

  async function onHandleClickSaveUnitType(data: IEditableUnitType) {
    if (unitTypeId) {
      dispatch(
        updateUnitTypeById({
          unitTypeId,
          data:{...data, isTemplate: data.forProjectId ? false : true},
        })
      );
    }
  }

  async function onHandleDeleteUnitType(id?: string) {
    if (id) {
      await dispatch(deleteUnitTypeById(id));
    }
  }

  useEffect(() => {
    const status = updateUnitTypeState.status;
    if (status === "success") dispatch(addToast({ status }));
    if (status === "error")
      dispatch(addToast({ status, title: "Update failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUnitTypeState.status, dispatch]);

  useEffect(() => {
    const status = deleteUnitTypeState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully deleted" }));
      navigate("/unit-type");
    }
    if (status === "error")
      dispatch(addToast({ status, title: "Delete failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUnitTypeState.status, dispatch]);

  return (
    <ManageUnitType
      pageTitle={
        _.find(currentUnitType?.contentVariations, {
          languageCode: languageCode,
        })?.name || ""
      }
      unitTypeId={unitTypeId}
      onClickDelete={() => onHandleDeleteUnitType(unitTypeId)}
      onClickSubmit={(data) => onHandleClickSaveUnitType(data)}
      submitButtonTitle={"Save"}
      isShowAddLanguageButton
      isShowUpdatedAt
    />
  );
}
