import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteProject,
  projectSelector,
  resetProjectDetail,
  updatedProjectById,
} from "../../store/features/project";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IProject, IProjectEditable } from "../../types";
import { ManageProject } from "../../templates/manageProject/ManageProject";
import { useEffect } from "react";
import { addToast } from "../../store/features/toast";
import {
  createPage,
  deletePage,
  pageDataSelector,
  resetCreatePageState,
  updatePage,
} from "../../store/features/page";

export function ProjectDetail() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    currentProject: project,
    updateProjectState,
    deleteProjectState,
  } = useAppSelector(projectSelector);
  const { id: projectId } = useParams();
  const { createPageState, newPage } = useAppSelector(pageDataSelector);

  async function onHandleUpdateProject(data: IProjectEditable) {
    if (projectId) {
      dispatch(
        updatedProjectById({
          projectId,
          payload: data,
        })
      );
    }
  }

  async function onHandleDeleteProject(projectId?: string) {
    if (projectId) {
      await dispatch(deleteProject(projectId));
      await dispatch(deletePage(`resource:${projectId}`))
    }
  }

  async function onCreatePage(data: IProject) {
    if (data?.name && data?.slugCode) {
      await dispatch(
        createPage({
          title: `${data.name} (${data.languageCode.toUpperCase()})`,
          path: `/${data.languageCode}/project/${
            data?.brand.productType?.code || ""
          }/${data?.brand.slugCode}/${data?.slugCode}`,
          category: "project",
          resourceId: data.id,
          resourceUrl: `/project/${data.id}`,
          slug: `${data?.slugCode}-${data.languageCode}`,
          languageCode: data.languageCode,
        })
      );
    }
  }

  async function onUpdatePage(data: IProject) {
    if (data?.name && data?.slugCode) {
      try{
        await dispatch(
          updatePage({
            pageIdOrPath: `resource:${data?.id}`,
            payload: {
              title: `${data?.name} (${data?.languageCode.toUpperCase()})`,
              path: `/${data?.languageCode}/project/${
                data?.brand?.productType?.code || ""
              }/${data?.brand.slugCode}/${data?.slugCode}`,
              slug: `${data?.slugCode}-${data?.languageCode}`,
              languageCode: data?.languageCode,
            },
          })
        );
      }
      catch (err) {
        console.log(err)
      }
    }
  }

  useEffect(() => {
    const status = updateProjectState.status;
    if (status === "success") {
      dispatch(addToast({ status }));
      if (project) {
        onUpdatePage(project);
      }
    }
    if (status === "error")
      dispatch(addToast({ status, title: "Update failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProjectState.status, dispatch]);

  useEffect(() => {
    const status = deleteProjectState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully deleted" }));
      navigate("/project");
    }
    if (status === "error")
      dispatch(addToast({ status, title: "Delete failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProjectState.status, dispatch, location.pathname]);

  useEffect(() => {
    const status = createPageState.status;
    if (status === "success") {
      navigate(`/page-builder/page/${newPage?.id}`);

      return () => {
        dispatch(resetCreatePageState());
        dispatch(resetProjectDetail());
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPageState.status]);

  return (
    <ManageProject
      pageTitle={project?.name || ""}
      projectId={projectId}
      onClickDelete={() => onHandleDeleteProject(projectId)}
      onClickSubmit={(data) => onHandleUpdateProject(data)}
      onCreatePage={(data) => {
        if (project) {
          onCreatePage(project);
        }
      }}
      submitButtonTitle={"Save"}
      isShowPreviewPage
      isShowBrand
      isShowProjectType
      isShowUpdatedAt
    />
  );
}
