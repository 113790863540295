import { createStyles, MantineTheme } from "@mantine/core";
import { RichTextEditor, RichTextEditorProps } from "@mantine/rte";
import { useState } from "react";
import { LabelText } from "../../globalStyle/GlobalStyle.style";

const useStyle = createStyles(
  (theme: MantineTheme, props: TextEditorProps & { focused: boolean }) => {
    const { isShowToolbar = true, focused } = props;
    return {
      toolbar: {
        display: isShowToolbar ? undefined : "none",

        borderTopLeftRadius: isShowToolbar ? "8px !important" : 0,
        borderTopRightRadius: isShowToolbar ? "8px !important" : 0,

        // border: "1px solid var(--grey300)",
      },
      root: {
        borderRadius: 8,
        ".ql-editor": {
          height: 270,
          overflow: "auto",
          padding: "8px 12px",
          fontSize: theme.fontSizes.lg,
          width: "100%",
          // border: "1px solid var(--grey300)",
          borderRadius: 8,
          // height: 250,
          borderTopLeftRadius: isShowToolbar ? 0 : 8,
          borderTopRightRadius: isShowToolbar ? 0 : 8,
          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
          // @ts-ignore
          color: theme.colors.grey[10],

          "> p": {
            marginBottom: 4,
          },

          ":disabled": {
            color: "var(--grey500)",
            backgroundColor: "var(--grey50)",
            cursor: "text",
            opacity: "unset",
          },
        },

        border: focused
          ? "1px solid var(--primary300)"
          : "1px solid var(--grey300)",
        boxShadow: focused
          ? "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--primary100)"
          : undefined,
      },
    };
  }
);

interface TextEditorProps extends RichTextEditorProps {
  isShowToolbar?: boolean;
  label?: string;
}

export function TextEditor(props: TextEditorProps) {
  const { onFocus, onBlur, label, isShowToolbar, ...rest } = props;
  const [focused, setFocused] = useState(false);
  const { classes } = useStyle({ ...props,isShowToolbar, focused });

  return (
    <div>
      {label && <LabelText>{label}</LabelText>}
      <RichTextEditor
        {...rest}
        classNames={classes}
        onFocus={(event: React.FocusEvent<HTMLDivElement>) => {
          setFocused(true);
          if (onFocus) {
            onFocus(event);
          }
        }}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          setFocused(false);
          if (onBlur) {
            onBlur(event);
          }
        }}
      />
    </div>
  );
}
