import _ from "lodash";
import { ISettingFieds, ThemeType } from "../types";
import {
  UnitTypeForProject,
  UnitTypeFilterByHouseCollection,
  IUnitType,
} from "../types/unitType";
import { onCastUnitTypeDataToUnitTypeDataForProject } from "./castType";
import { convertColorToSixDigit } from "./convertData";

export function mapConvertThemeDataTo6DigitHexstring(data: ThemeType | null) {
  if (data === null) {
    return null;
  }
  return {
    brandColors: data.brandColors.length
      ? data.brandColors.map((item) => convertColorToSixDigit(item || ""))
      : [],
      backgroundColors: data.backgroundColors.length
      ? data.backgroundColors.map((item) => convertColorToSixDigit(item || ""))
      : [],
      mainColor: convertColorToSixDigit(data.mainColor || ""),
  };
}

export function mapDefaultSettings(settingFields: ISettingFieds) {
  let settings: { [key: string]: any } = {};
  Object.entries(settingFields).forEach(([key, fieldData]) => {
    settings[key] = fieldData?.defaultValue;
  })
  return settings;
}

export function mappingUnitTypeForProjectListData(
  dataToMap: IUnitType[],
  data: string[],
  languageCode: string
) {
  let mappingData: UnitTypeForProject[] = [];
  data.forEach((item) => {
    const unitTypeData = _.find(dataToMap, { id: item });
    if (unitTypeData) {
      const newData = onCastUnitTypeDataToUnitTypeDataForProject(
        unitTypeData,
        languageCode
      );
      if (newData) {
        mappingData.push(newData);
      }
    }
  });

  return mappingData;
}

export function groupUnitTypeByHouseCollection(
  data: UnitTypeForProject[]
): UnitTypeFilterByHouseCollection[] {
  let filterByHouseCollection: UnitTypeFilterByHouseCollection[] = [];
  data.forEach((item) => {
    const houseCollectionIndex = filterByHouseCollection.findIndex(
      (value) => value.houseCollectionId === item.houseCollectionId
    );
    if (houseCollectionIndex >= 0) {
      filterByHouseCollection[houseCollectionIndex].unitTypes.push(item);
    } else {
      const newHouseCollection = {
        houseCollectionId: item.houseCollectionId || null,
        houseCollectionName:
          item.houseCollection?.name || null,
        unitTypes: [item],
      };
      filterByHouseCollection.push(newHouseCollection);
    }
  });
  return filterByHouseCollection;
}
