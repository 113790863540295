import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Avatar, Image } from "@mantine/core";
import { MenuItem, MenuItemProps } from "../../atoms/menuItem/MenuItem";
import {
  LogoImageContainer,
  LogoutIcon,
  MenuContainer,
  NavigationSidebarContainer,
  SubMenuContainer,
  UserContainer,
  UserImage,
  UserInformation,
  UserPrimaryInformation,
  UserSecondaryInformation,
} from "./NavigationSidebar.style";

interface NavigationSidebarProps {
  logoImgUrl?: string;
  mainMenuList: MenuItemProps[];
  subMenuList?: MenuItemProps[];
  userInformation?: { title: string; subtitle?: string; imgUrl?: string };
  onLogout?: () => void;
}

export function NavigationSidebar(props: NavigationSidebarProps) {
  const {
    mainMenuList = [],
    subMenuList = [],
    userInformation,
    onLogout,
  } = props;
  return (
    <NavigationSidebarContainer>
      <LogoImageContainer>
        <Image src="https://st-th-1.byteark.com/test-potattattooo/pf-test/Logo_wrap.png" />
      </LogoImageContainer>
      {/* <TextInput placeholder="Search" icon={<FontAwesomeIcon icon={['far', 'search']} />} /> */}
      <MenuContainer>
        {mainMenuList.map((menu, index) => (
          <MenuItem {...menu} key={`${menu.title}-${index + 1}-main-menu`} />
        ))}
      </MenuContainer>
      <SubMenuContainer>
        {subMenuList.map((menu, index) => (
          <MenuItem
            {...menu}
            key={`${menu.title}-${index + 10}-main-submenu`}
          />
        ))}
      </SubMenuContainer>
      {Boolean(userInformation) && (
        <UserContainer>
          <UserImage>
            <Avatar radius="xl" size={40} src={userInformation?.imgUrl || ""} />
          </UserImage>
          <UserInformation>
            <UserPrimaryInformation>
              {userInformation?.title}
            </UserPrimaryInformation>
            <UserSecondaryInformation>
              {userInformation?.subtitle}
            </UserSecondaryInformation>
          </UserInformation>
          {Boolean(onLogout) && (
            <LogoutIcon>
              <ActionIcon variant="transparent" size={32} onClick={onLogout}>
                <FontAwesomeIcon
                  icon={["far", "arrow-right-from-bracket"]}
                  size="lg"
                />
              </ActionIcon>
            </LogoutIcon>
          )}
        </UserContainer>
      )}
    </NavigationSidebarContainer>
  );
}
