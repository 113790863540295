import { createReducer } from "@reduxjs/toolkit";
import { addToast, clearAllToast } from "./actions";

interface InitialStateProps {
  toastList: {
    id: string;
    title?: string;
    description?: string;
    status: 'success' | 'error',
  }[],
}

const initialState: InitialStateProps = {
  toastList: []
};

export const toastReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addToast, (state, { payload }) => {
      const oldData = state.toastList || []
      state.toastList = [...oldData, payload];
    })
  builder
    .addCase(clearAllToast, (state) => {
      state.toastList = [];
    })
});
