import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../atoms/button/Button";
import { ContentDivider } from "../../molecules/contentDivider/ContentDivider";
import {
  Container,
  Title,
  SidePanelTopContainer,
  HeaderWrapper,
} from "./SidePanel.style";

interface SidePanelProps {
  title?: string;
  sidePanelAction: React.ReactElement;
  sidePanelFooterAction?: React.ReactElement;
  closeButton?: boolean;
  onClose?: () => void;
}
export function SidePanel(props: SidePanelProps) {
  const {
    title,
    sidePanelAction,
    sidePanelFooterAction,
    closeButton,
    onClose,
  } = props;
  return (
    <Container>
      <SidePanelTopContainer isHasFooter={Boolean(sidePanelFooterAction)}>
        {(title || (closeButton && onClose)) && (
          <HeaderWrapper>
            {title && <Title>{title}</Title>}
            {closeButton && onClose && (
              <Button buttonStyle="text-link-grey" onClick={() => onClose()}>
                <FontAwesomeIcon icon={["far", "x"]} />
              </Button>
            )}
          </HeaderWrapper>
        )}
        {sidePanelAction}
      </SidePanelTopContainer>
      {sidePanelFooterAction && (
        <>
          <ContentDivider />
          {sidePanelFooterAction}
        </>
      )}
    </Container>
  );
}
