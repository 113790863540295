import styled from "@emotion/styled/macro"
import { css } from '@emotion/react'
import { BottomAreaMarginProps } from './BaseSidebar';
import { maxLayout } from '../../../utils/breakpoints';
import { PageHeaderStyle } from '../../../globalStyle/GlobalStyle.style';

export const Container = styled.div<{ isHiding: boolean; isMobileHamburger: boolean }>`
  position: relative;
  flex: 0 0 330px;
  width: 330px;
  height: 100vh;
  background-color: #FFF;
  transition: all 250ms cubic-bezier(.66,1.02,.76,.99);
  overflow: visible;
  z-index: 5;
  border-right: 1px solid #EAECF0;

  @media ${maxLayout.md} {
    flex: 0 0 100vw;
    width: 100vw;

    ${(props) => (props.isMobileHamburger ? css`
      box-shadow: 2px 0 12px rgba(0, 0, 0, 0.2);
      transform: ${props.isHiding ? 'translateX(-100vw)' : 'translateX(0)'};
      pointer-events: ${props.isHiding ? 'none' : 'auto'};
      opacity: ${props.isHiding ? '0' : '1'};
    ` : '')};
  }
`;

export const Header = styled.header`
  border-bottom: 1px solid var(--grey300);
  padding: var(--space-md) var(--space-sm);

  @media ${maxLayout.md} {
    padding: var(--space-sm) var(--space-sm) var(--space-md) var(--space-sm);
  }
`;

export const Title = styled.h1`
  ${PageHeaderStyle};
  min-height: 39.2px;
  text-transform: capitalize;
`;

export const BackButton = styled.a<{ hasUrl: boolean; isMobileHamburger: boolean }>`
  display: block;
  cursor: ${(props) => (props.hasUrl ? 'pointer' : 'normal')};
  color: var(--grey600);
  margin-bottom: var(--space-xs);
  transition: color 150ms ease;
  width: fit-content;

  ${(props) => props.hasUrl && css`
    :hover {
      color: var(--primary600);
    }
  `};

  @media ${maxLayout.md} {
    margin-left: ${(props) => (props.isMobileHamburger ? 'auto' : '0')};
  }
`;

type ContentStyleProps = {
  headerHeight: number;
  bottomAreaMargin: BottomAreaMarginProps;
}

const calcHeight = (props: ContentStyleProps) => {
  const { headerHeight, bottomAreaMargin } = props;

  /* eslint-disable */
  switch (bottomAreaMargin) {
    case 'small':
      return headerHeight + 72;
    case 'large':
      return headerHeight + 162;
    default:
      return headerHeight;
  }
  /* eslint-enable */
};

export const Content = styled.div<ContentStyleProps>`
  padding-top: var(--space-sm);
  padding-bottom: var(--space-sm);
  height: calc(100% - ${(props) => `${calcHeight(props)}px`});
  max-height: calc(100% - ${(props) => `${calcHeight(props)}px`});
  overflow-y: auto;
  overflow-x: hidden;
`;

export const HamburgerButton = styled.button<{ isHiding: boolean; isMobileHamburger: boolean }>`
  display: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  position: fixed;
  top: 16px;
  left: 16px;
  z-index: 20;
  width: 28px;
  height: 28px;
  transform: rotate(0deg);

  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background-color: var(--grey700);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 250ms ease-in-out;

    :nth-of-type(1) {
      top: ${(props) => (!props.isHiding ? '-2px' : '0')};
      left: ${(props) => (!props.isHiding ? '1px' : '0')};
      transform-origin: left center;
      transform: ${(props) => (!props.isHiding ? 'rotate(45deg)' : 'none')};
    }

    :nth-of-type(2) {
      top: 8px;
      transform-origin: left center;
      ${(props) => (!props.isHiding ? css`width: 0; opacity: 0;` : '')}
    }

    :nth-of-type(3) {
      top: ${(props) => (!props.isHiding ? '17px' : '16px')};
      left: ${(props) => (!props.isHiding ? '1px' : '0')};
      transform-origin: left center;
      transform: ${(props) => (!props.isHiding ? 'rotate(-45deg)' : 'none')};
    }
  }

  @media ${maxLayout.md} {
    display: ${(props) => (props.isMobileHamburger ? 'block' : 'none')};
  }
`;
