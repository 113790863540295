import { MultiSelect } from "../../atoms/multiSelect/MultiSelect";
import { RadioChoice } from "../../atoms/radioChoice/RadioChoice";
import { Select } from "../../atoms/select/Select";
import { TextInput } from "../../atoms/textInput/TextInput";
import { SideFormGroup } from "../../molecules/sideFormGroup/SideFormGroup";
import { Heading } from "../../organisms/heading/Heading";
import { Section } from "../../organisms/section/Section";
import { SideFormPageContent, SideFormPageSectionContent } from "./SideFormExample.style";

export default function SideFormExample() {
  return (
    <SideFormPageContent>
      <Heading title="สร้างเพลย์ลิสต์ใหม่" subtitle="ลิสต์ของเสียงบทความ สำหรับให้ผู้ชมฟังอย่างต่อเนื่อง" />
      <Section onClickFooterButton={() => {}} footerButtonTitle="สร้างเพลย์ลิสต์">
        <SideFormPageSectionContent>
          <SideFormGroup title="ข้อมูลทั่วไป" subtitle="ข้อมูลที่ใช้แสดงผลและวิธีการเข้าถึง">
            <TextInput label="ชื่อเพลย์ลิสต์" />
          </SideFormGroup>
          <SideFormGroup title="วิธีการจัดเพลย์ลิสต์" subtitle="เลือกวิธีการจัดคอนเทนต์ในเพลย์ลิสต์">
            <RadioChoice title="Smart Playlist" subtitle="ให้ระบบจัดเพลย์ลิสต์ให้อัตโนมัติ ตามเงื่อนไขที่กำหนด" checked />
            <RadioChoice title="Manual Playlist" subtitle="เลือกเสียงบทความมาจัดเพลย์ลิสต์เอง (เร็วๆ นี้)" />
          </SideFormGroup>
          <SideFormGroup title="เงื่อนไขการจัดเพลย์ลิสต์อัตโนมัติ" subtitle="ระบบจะเลือกคอนเทนต์ตามเงื่อนไขนี้มาใส่ในเพลย์ลิสต์ให้โดยอัตโนมัติ">
            <Select label="เฉพาะรูปแบบเสียง" data={['โปรยข่าว']} />
            <MultiSelect label="เฉพาะคอนเทนต์ที่มาจาก" data={['ข่าวไทยพีบีเอส', 'ALTV']} value={['ข่าวไทยพีบีเอส']} />
          </SideFormGroup>
        </SideFormPageSectionContent>
      </Section>
    </SideFormPageContent>
  )
}