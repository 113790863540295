import styled from "@emotion/styled/macro";

export const MediaItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: calc(90vh - 385px);
  overflow-y: auto;
`;

export const PaginateWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonGroup = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const UploadText = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: var(--grey600);
`;

export const UploadTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ImageUploadModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* overflow: hidden; */
`;
