import styled from "@emotion/styled/macro";

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const AreaInputWrapper = styled.div`
  display: flex;
  gap: 16px;
  /* width: 70%; */
  align-items: flex-end;
`;

export const FloorPlanWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
`;

export const ButtonGroup = styled.div<{ gap?: string }>`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.gap ? props.gap : "0px")};

  svg {
    pointer-events: none;
  }
`;

export const FloorPlanContextWrapper = styled.div<{ gap?: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ActionGroupWrapper = styled.div<{ gap?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;

export const Sepline = styled.div`
  height: 24px;
  width: 1px;
  background-color: var(--grey300);
`;
