import { createReducer } from "@reduxjs/toolkit";
import {
  initialPostDetailState,
  initialPostsListState,
  PostDetailInitialStateProps,
  PostListInitialStateProps,
} from "../../../types/post";
import { formatErrorObject } from "../../../utils/errorFormatter";
import { getStateObject } from "../../../utils/stateManagement";
import {
  createPost,
  deletePostById,
  fetchPostById,
  fetchPostList,
  fetchPostOptions,
  resetPost,
  resetPostDetail,
  setEditPostData,
  updatePostById,
} from "./actions";

type InitialStateProps = PostListInitialStateProps &
  PostDetailInitialStateProps;

const initialState: InitialStateProps = {
  ...initialPostsListState,
  ...initialPostDetailState,
};

export const postReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchPostList.pending, (state) => {
      state.loadPostListState = getStateObject({ type: "loading" });
    })
    .addCase(fetchPostList.fulfilled, (state, { payload }) => {
      state.postList = payload?.data;
      state.loadPostListState = getStateObject({ type: "success" });
      state.pagination = {
        from: payload?.from,
        to: payload?.to,
        lastPage: payload?.lastPage,
        perPage: payload?.perPage,
        total: payload?.total,
        currentPage: payload?.currentPage,
      };
    })
    .addCase(fetchPostList.rejected, (state, { payload }) => {
      state.loadPostListState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Post List"),
      });
    })
    .addCase(fetchPostById.pending, (state) => {
      state.loadPostByIdState = getStateObject({ type: "loading" });
    })
    .addCase(fetchPostById.fulfilled, (state, { payload }) => {
      state.currentPost = payload;
      // state.postEditData = { ...payload };
      state.loadPostByIdState = getStateObject({ type: "success" });
    })
    .addCase(fetchPostById.rejected, (state, { payload }) => {
      state.loadPostByIdState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Post By Id"),
      });
    })

    .addCase(createPost.pending, (state) => {
      state.createPostState = getStateObject({ type: "loading" });
    })
    .addCase(createPost.fulfilled, (state, { payload }) => {
      state.newPost = payload
      state.createPostState = getStateObject({ type: "success" });
    })
    .addCase(createPost.rejected, (state, { payload }) => {
      state.createPostState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Create Post"),
      });
    })

    .addCase(updatePostById.pending, (state) => {
      state.updatePostState = getStateObject({ type: "loading" });
    })
    .addCase(updatePostById.fulfilled, (state, { payload }) => {
      state.currentPost = payload;
      state.postEditData = { ...payload };
      state.updatePostState = getStateObject({ type: "success" });
    })
    .addCase(updatePostById.rejected, (state, { payload }) => {
      state.updatePostState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Update Post"),
      });
    })

    .addCase(deletePostById.pending, (state) => {
      state.deletePostState = getStateObject({ type: "loading" });
    })
    .addCase(deletePostById.fulfilled, (state, { payload }) => {
      state.deletePostState = getStateObject({ type: "success" });
    })
    .addCase(deletePostById.rejected, (state, { payload }) => {
      state.deletePostState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Delete Post"),
      });
    })

    .addCase(fetchPostOptions.pending, (state) => {
      state.loadPostOptionsState = getStateObject({ type: "loading" });
    })
    .addCase(fetchPostOptions.fulfilled, (state, { payload }) => {
      state.postOptions = payload?.data
      state.loadPostOptionsState = getStateObject({ type: "success" });
    })
    .addCase(fetchPostOptions.rejected, (state, { payload }) => {
      state.loadPostOptionsState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Post Options"),
      });
    })

    .addCase(setEditPostData, (state, { payload }) => {
      state.postEditData = payload;
    })
    .addCase(resetPostDetail, (state) => {
      Object.assign(state, initialPostDetailState);
    })
    .addCase(resetPost, (state) => {
      Object.assign(state, initialState);
    });
});
