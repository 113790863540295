import styled from "@emotion/styled/macro";

export const ContentHolder = styled.div`
  padding: var(--space-sm) var(--space-md) var(--space-md);
`;

export const ContentWrapper = styled.div`
  padding-bottom: var(--space-md);
  max-width: 520px;

  &:last-of-type {
    padding-bottom: 0;
  }
`;