import { css } from '@emotion/react';

const Colors = css`
  --ci: #fe5000;
  --ciGradient: linear-gradient(180deg, #fe5000 0%, #dc0000 100%);
  --ciGradient2: linear-gradient(180deg, #ef4b00 0%, #fe5000 65.89%, #ffb93b 110%);

  --white: #ffffff;
  --black: #000000;

  --primary: #2970FF;

  --primary25: #F5F8FF;
  --primary50: #EFF4FF;
  --primary100: #D1E0FF;
  --primary200: #B2CCFF;
  --primary300: #84ADFF;
  --primary400: #528BFF;
  --primary500: #2970FF;
  --primary600: #155EEF;
  --primary700: #004EEB;
  --primary800: #0040C1;
  --primary900: #00359E;

  --grey25: #FCFCFD;
  --grey50: #F9FAFB;
  --grey100: #F2F4F7;
  --grey200: #EAECF0;
  --grey300: #D0D5DD;
  --grey400: #98A2B3;
  --grey500: #667085;
  --grey600: #475467;
  --grey700: #344054;
  --grey800: #1D2939;
  --grey900: #101828;

  --error25: #FFFBFA;
  --error50: #FEF3F2;
  --error100: #FEE4E2;
  --error200: #FECDCA;
  --error300: #FDA29B;
  --error400: #F97066;
  --error500: #F04438;
  --error600: #D92D20;
  --error700: #B42318;
  --error800: #912018;
  --error900: #7A271A;

  --warning25: #FFFCF5;
  --warning50: #FFFAEB;
  --warning100: #FEF0C7;
  --warning200: #FEDF89;
  --warning300: #FEC84B;
  --warning400: #FDB022;
  --warning500: #F79009;
  --warning600: #DC6803;
  --warning700: #B54708;
  --warning800: #93370D;
  --warning900: #7A2E0E;

  --success25: #F6FEF9;
  --success50: #ECFDF3;
  --success100: #D1FADF;
  --success200: #A6F4C5;
  --success300: #6CE9A6;
  --success400: #32D583;
  --success500: #12B76A;
  --success600: #039855;
  --success700: #027A48;
  --success800: #05603A;
  --success900: #054F31;

  --highlight25: #FCFAFF;
  --highlight50: #F9F5FF;
  --highlight100: #F4EBFF;
  --highlight200: #E9D7FE;
  --highlight300: #D6BBFB;
  --highlight400: #B692F6;
  --highlight500: #9E77ED;
  --highlight600: #7F56D9;
  --highlight700: #6941C6;
  --highlight800: #53389E;
  --highlight900: #42307D;
  
  --greyOptionalA: #181818;
  --greyOptionalB: #202020;

  --optionalA: #2f180f;
  --optionalB: #422e27;
  --optionalC: #694331;
  --optionalD: #b23800;
  --optionalE: #cb4000;
  --optionalF: #d94c0c;
  --optionalG: #ff6823;
  --optionalH: #fff0ea;
  --optionalI: #fff4ec;
  --optionalJ: #fffaf8;

  --red: #BF2600;
  --green: #006644;
  --yellow: #FF8B00;
  --navy: #42526E;
  --blue: #2F80ED;
`;

export default Colors;
