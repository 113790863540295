/* eslint-disable react/jsx-props-no-spreading */
import { createStyles, Pagination as CorePagination } from "@mantine/core";
import { PaginationProps } from "@mantine/core/lib/Pagination/Pagination";

const useStyles = createStyles(() => ({
  root: {
    gap: 0,
  },
  item: {
    padding: "6px 12px",
    fontWeight: 500,
    fontSize: 14,
    border: 0,
    borderRadius: 3,
    lineHeight: 20,
    // remove wrapper flex's gap
    marginLeft: -6,
    marginRight: -6,
    overflow: "hidden",
    color: "var(--grey800)",
    "&[data-active]": {
      backgroundColor: "var(--grey100)",
      borderRadius: 20,
      color: "var(--grey800)",
    },
  },
}));

/**
 * Documentation: {@link https://mantine.dev/core/pagination/}
 */
export function Pagination(props: PaginationProps) {
  const paginationProps = { ...props };
  const { classes } = useStyles();
  return (
    <CorePagination
      {...paginationProps}
      classNames={classes}
      position="center"
    />
  );
}
