import { InputHTMLAttributes, FocusEvent, useState } from "react";
import { Textarea as CoreTextarea, createStyles } from "@mantine/core";
import { TextareaProps } from "@mantine/core/lib/Textarea/Textarea";
import { InfoText } from "../textInput/TextInput.style";

const useStyles = createStyles(
  (
    theme,
    { floating, error }: { floating: boolean; error?: React.ReactNode }
  ) => ({
    root: {
      width: "100%",
    },
    label: {
      fontSize: theme.fontSizes.lg,
      marginBottom: theme.spacing.xs,
      color: "var(--grey700)",
    },
    required: {
      transition: "opacity 150ms ease",
      opacity: floating ? 1 : 0,
    },
    input: {
      minHeight: 150,
      height: 150,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: theme.fontSizes.lg,
      width: "100%",
      border: error ? "1px solid var(--error500)" : "1px solid #D0D5DD",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
      borderRadius: 8,
      // @ts-ignore
      color: theme.colors.grey[10],
      transition: error ? "border-color 100ms ease 0s" : undefined,

      ":disabled": {
        color: "var(--grey500)",
        backgroundColor: "var(--grey50)",
        cursor: "text",
        opacity: "unset",
      },

      ":focus": {
        border: error
          ? "1px solid var(--error300)"
          : "1px solid var(--primary300)",
        boxShadow: error
          ? "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--error100)"
          : "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--primary100)",
      },
    },
    icon: {
      fontSize: 18,
      width: 48,
      fontWeight: 700,
      color: "var(--grey500)",
    },

    error: {
      fontSize: 14,
      fontWeight: 400,
      color: "var(--error500)",
    },
  })
);

export interface TextAreaProps
  extends Omit<InputHTMLAttributes<HTMLTextAreaElement>, "size">,
    TextareaProps {
  hintText?: string;
}

export function TextArea(props: TextAreaProps) {
  const { label, placeholder, onFocus, onBlur, error, hintText, ...rest } =
    props;
  const [focused, setFocused] = useState(false);
  const { classes } = useStyles({ floating: focused, error });

  return (
    <div>
      <CoreTextarea
        {...rest}
        label={label}
        placeholder={placeholder}
        classNames={classes}
        error={error}
        onFocus={(event: FocusEvent<HTMLTextAreaElement>) => {
          setFocused(true);
          if (onFocus) {
            onFocus(event);
          }
        }}
        onBlur={(event: FocusEvent<HTMLTextAreaElement>) => {
          setFocused(false);
          if (onBlur) {
            onBlur(event);
          }
        }}
      />
      {hintText && <InfoText>{hintText}</InfoText>}
    </div>
  );
}
