import { createReducer } from "@reduxjs/toolkit";
import { CancelTokenSource } from "axios";
import { UploadState } from "../../../atoms/uploadItem/UploadItem";
import { IMediaResponse, IUploadProgress, StatusState } from "../../../types";
import {
  resetUpload,
  setUploadFiles,
  setUploadProgress,
  uploadFile,
  clearUploaded,
  setUploadingFolder,
  clearUploadingFolder,
  cancelUploadFile,
  removeFileUploadById,
  retryUploadFile,
  updateFileById,
} from "./actions";
import { getStateObject } from '../../../utils/stateManagement';
import { formatErrorObject } from '../../../utils/errorFormatter';

interface InitialStateProps {
  files: {
    file: File;
    name: string;
    uploadProgress: IUploadProgress;
    fileId: string;
    source: CancelTokenSource;
    state: UploadState;
  }[];
  uploadCompleted: IMediaResponse[];
  newFiles: { data: IMediaResponse; fileId: string }[];
  uploadingFolders: string[];
  uploadFileState: StatusState;
  retryUploadFileState: StatusState;
}

const initialState: InitialStateProps = {
  files: [],
  uploadCompleted: [],
  newFiles: [],
  uploadingFolders: [],
  uploadFileState: {
    error: null,
    status: "",
  },
  retryUploadFileState: {
    error: null,
    status: "",
  },
};

export const uploadReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(uploadFile.pending, (state) => {
      state.uploadFileState = getStateObject({ type: 'loading' });
    })
    .addCase(uploadFile.fulfilled, (state, { payload }) => {
      if (!(payload.data?.code === "ERR_CANCELED")) {
        state.uploadCompleted = [...state.uploadCompleted, payload?.data];
        state.newFiles = [
          ...state.newFiles,
          { data: payload?.data, fileId: payload?.fileId },
        ];
        const fileIndex = state.files.findIndex(
          (data) => data.fileId === payload?.fileId
        );
        if (fileIndex >= 0) {
          state.files[fileIndex] = {
            ...state.files[fileIndex],
            state: "completed",
          };
        }
      }
      state.uploadFileState = getStateObject({ type: 'success' });
    })
    .addCase(uploadFile.rejected, (state, { payload }) => {
      state.uploadFileState = getStateObject({
        type: 'error',
        error: formatErrorObject(payload, 'Upload File'),
      });
    })
    .addCase(setUploadProgress, (state, { payload }) => {
      const objIndex = state.files.findIndex(
        (data) => data.fileId === payload.fileId
      );
      if (objIndex >= 0) {
        state.files[objIndex].uploadProgress = payload?.data;
      }
    })
    .addCase(setUploadFiles, (state, { payload }) => {
      const objIndex = state.files.findIndex(
        (data) => data.fileId === payload.fileId
      );
      if (objIndex >= 0) {
        state.files[objIndex] = {
          state: "prepare",
          file: payload.file,
          fileId: payload.fileId,
          name: payload.file.name,
          source: payload.source,
          uploadProgress: {
            parentMediaFolderId: "",
            uploadProgress: 0,
            fileSize: 0,
            loaded: 0,
            total: 0,
          },
        };
      } else {
        state.files.push({
          state: "prepare",
          file: payload.file,
          fileId: payload.fileId,
          name: payload.file.name,
          source: payload.source,
          uploadProgress: {
            parentMediaFolderId: "",
            uploadProgress: 0,
            fileSize: 0,
            loaded: 0,
            total: 0,
          },
        });
      }
    })
    .addCase(retryUploadFile.pending, (state) => {
      state.retryUploadFileState = getStateObject({ type: 'loading' });
    })
    .addCase(retryUploadFile.fulfilled, (state) => {
      state.retryUploadFileState = getStateObject({ type: 'success' });
    })
    .addCase(retryUploadFile.rejected, (state, { payload }) => {
      state.retryUploadFileState = getStateObject({
        type: 'error',
        error: formatErrorObject(payload, 'Upload File'),
      });
    })
    .addCase(updateFileById, (state, { payload }) => {
      const filesObjIndex = state.files.findIndex(
        (data) => data.fileId === payload.fileId
      );
      if (filesObjIndex >= 0) {
        state.files[filesObjIndex] = {
          ...state.files[filesObjIndex],
          ...payload.uploadInfo,
        };
      }
    })
    .addCase(clearUploaded, (state, { payload }) => {
      const filesObjIndex = state.files.findIndex(
        (data) => data.fileId === payload.fileId
      );
      if (filesObjIndex >= 0) {
        state.files.splice(filesObjIndex, 1);
      }
      state.newFiles.splice(payload.index, 1);
    })
    .addCase(removeFileUploadById, (state, { payload }) => {
      const filesObjIndex = state.files.findIndex(
        (data) => data.fileId === payload
      );
      if (filesObjIndex) {
        state.files.splice(filesObjIndex, 1);
      }
    })
    .addCase(setUploadingFolder, (state, { payload }) => {
      state.uploadingFolders.push(payload);
    })
    .addCase(clearUploadingFolder, (state, { payload }) => {
      state.uploadingFolders.splice(payload, 1);
    })
    .addCase(cancelUploadFile, (state, { payload }) => {
      const filesObjIndex = state.files.findIndex(
        (data) => data.fileId === payload
      );
      if (filesObjIndex >= 0) {
        const { source } = state.files[filesObjIndex];
        source.cancel("Operation canceled by the user.");
        state.files.splice(filesObjIndex, 1);
      }
    })
    .addCase(resetUpload, (state) => {
      Object.assign(state, initialState);
    });
});
