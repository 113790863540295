import styled from "@emotion/styled/macro";

export const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  margin-right: 16px;
  color: #667085;
  font-size: var(--font-size-xl);
  display: flex;
  align-items: center;
`;

export const SubMenuItemContainer = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 12px 8px var(--space-xxl);
  background: ${(props) => (props.isActive ? "var(--grey50)" : "transparent")};
  color: ${(props) => (props.isActive ? "var(--grey900)" : "var(--grey700)")};
  font-weight: 600;
  font-size: var(--font-size-lg);
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 4px;

  &:hover {
    background: #f9fafb;
  }
`;

export const MainMenuContainer = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background: ${(props) => (props.isActive ? "var(--grey50)" : "transparent")};
  color: ${(props) => (props.isActive ? "var(--grey900)" : "var(--grey700)")};
  font-weight: 600;
  font-size: var(--font-size-lg);
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 4px;

  &:hover {
    background: #f9fafb;
  }
`;

export const SubMenuWrapper = styled.div<{ isActive?: boolean }>`
  max-height: ${(props) => (props.isActive ? "500px" : "0")};
  overflow: hidden;
  transition-property: max-height;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  will-change: transform;
  margin-bottom: 0px;
`;

export const IconWrapper = styled.div<{ isActive?: boolean }>`
  transform: ${(props) => (props.isActive ? "rotate(0deg)" : "rotate(180deg)")};
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
  will-change: transform;
`;
