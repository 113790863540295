import { ColumnContainer } from "./Column.style";

interface ColumnProps {
  children?: React.ReactElement | React.ReactElement[];
  width?: string;
  height?:string;
  maxWidth?: string;
  className?: string;
  horizontalAlign?: "left" | "center" | "right";
}

export function Column(props: ColumnProps) {
  const { horizontalAlign = "left" } = props;
  return (
    <ColumnContainer
      width={props.width}
      maxWidth={props.maxWidth}
      className={`${props.className ? props.className + " column" : "column"}`}
      horizontalALign={horizontalAlign}
      height={props.height}
    >
      {props.children}
    </ColumnContainer>
  );
}
