import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button } from "../../atoms/button/Button";
import { HorizontalList } from "../../atoms/horizontalList/HorizontalList";
import { MediaCard } from "../../atoms/mediaCard/MediaCard";
import { Pagination } from "../../atoms/pagination/Pagination";
import { PaginateWrapper } from "../../globalStyle/GlobalStyle.style";
import { fetchAllFolder, mediaSelector } from "../../store/features/media";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IFolder } from "../../types";
import { MediaCardWrapper } from "./FolderList.style";

interface FolderListProps {
  selectedFolder?: string;
  maxHeight?: string;
  search?: string;
  uncategorizedFolderName?: string;
  isShowUncategorizedFolder: boolean;
  isShowPagination?: boolean;
  onClickUncategorizedFolder: () => void;
  onClickFolder: (item: IFolder, index?: number) => void;
}

export function FolderList(props: FolderListProps) {
  const {
    uncategorizedFolderName = "Uncategorized media",
    selectedFolder,
    maxHeight = "calc(90vh - 385px)",
    isShowUncategorizedFolder,
    onClickUncategorizedFolder,
    isShowPagination,
    search,
    onClickFolder,
  } = props;
  const dispatch = useAppDispatch();
  const { folders, pagination } = useAppSelector(mediaSelector);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    if (!folders.length) {
      dispatch(
        fetchAllFolder({
          limit: 15,
          page: currentPage || pagination?.currentPage || 1,
          q: search,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      fetchAllFolder({
        limit: 15,
        page: currentPage || pagination?.currentPage || 1,
        q: search,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, currentPage, pagination?.currentPage]);

  return (
    <>
      <HorizontalList maxHeight={maxHeight} gap="8px">
        <>
          {isShowUncategorizedFolder && currentPage === 1 && (
            <MediaCardWrapper onClick={() => onClickUncategorizedFolder()}>
              <MediaCard
                isSelected={Boolean(selectedFolder === "null")}
                title={uncategorizedFolderName}
                icon={["far", "inbox"]}
                iconStyles="grey"
                cardAction={
                  <Button
                    height="fit-content"
                    buttonStyle="text-link-grey"
                    onClick={() => {}}
                  >
                    <FontAwesomeIcon icon={["far", "chevron-right"]} />
                  </Button>
                }
              />
            </MediaCardWrapper>
          )}
          {folders.map((item, index) => (
            <MediaCardWrapper
              key={`${item.name}-${index + 10}`}
              onClick={() => onClickFolder(item, index)}
            >
              <MediaCard
                isSelected={item.id === selectedFolder}
                title={item.name}
                icon={["far", "folder"]}
                cardAction={
                  <Button
                    height="fit-content"
                    buttonStyle="text-link-grey"
                    onClick={() => {}}
                  >
                    <FontAwesomeIcon icon={["far", "chevron-right"]} />
                  </Button>
                }
              />
            </MediaCardWrapper>
          ))}
        </>
      </HorizontalList>
      {isShowPagination ? (
        <PaginateWrapper>
          <Pagination
            total={pagination?.lastPage}
            page={currentPage || pagination?.currentPage}
            onChange={(e) => {
              setCurrentPage(e);
            }}
            // withControls={false}
          />
        </PaginateWrapper>
      ) : (
        <></>
      )}
    </>
  );
}
