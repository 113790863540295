import { useState, useEffect, ChangeEvent } from "react";
import { Modal } from "../../../organisms/modal/Modal";
import {
  mediaSelector,
  addNewFile,
  fetchAllFolder,
  resetMedia,
  fetchAttachmentList,
} from "../../../store/features/media";
import {
  uploadSelector,
  setUploadingFolder,
  uploadFile,
  retryUploadFile,
  cancelUploadFile,
  clearUploaded,
  resetUpload,
} from "../../../store/features/upload";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IMediaResponse } from "../../../types";
import { Breadcrumb } from "../../breadcrumb/Breadcrumb";
import { MediaItemData, MediaItem } from "../../mediaItem/MediaItem";
import { Pagination } from "../../pagination/Pagination";
import { SearchInput } from "../../searchInput/SearchInput";
import { UploadFileField } from "../../uploadFileField/UploadFileField";
import { UploadItem } from "../../uploadItem/UploadItem";
import {
  ImageUploadModal,
  MediaItemContainer,
  PaginateWrapper,
  UploadTextWrapper,
  UploadText,
} from "./AttachmentInputModal.style";
import { useSearchParams } from "react-router-dom";
import { FolderList } from "../../../molecules/folderList/FolderList";
import { HorizontalList } from "../../horizontalList/HorizontalList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface AttachmentInputModalProps {
  isModalOpened: boolean;
  onCloseModal?: () => void;
  onClickChooseFile?: (file: IMediaResponse | null) => void;
}

export function AttachmentInputModal(props: AttachmentInputModalProps) {
  const {
    isModalOpened,
    onCloseModal = () => {},
    onClickChooseFile = () => {},
  } = props;

  const dispatch = useAppDispatch();
  const defaultBreadcrumb = [{ value: null, label: "All attachments" }];
  const isAttachment = true;

  const [breadcrumbData, setBreadcrumbData] =
    useState<{ value: string | null; label: string }[]>(defaultBreadcrumb);

  // const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<IMediaResponse | null>(null);
  const [uploadFileCount, setUploadFileCount] = useState<number>(0);

  const { uploadCompleted, files, newFiles } = useAppSelector(uploadSelector);
  const { folderItems, paginationMediaItem } = useAppSelector(mediaSelector);

  const [searchParams] = useSearchParams({});
  const [searchValue, setSearchValue] = useState(searchParams.get("q"));
  const [searchFolder, setSearchFolder] = useState("");
  const [isShowUncategorizedFolder, setIsShowUncategorizedFolder] =
    useState<boolean>(true);

  function onHandleSearch(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      if (selectedFolder) {
        dispatch(
          fetchAttachmentList({
            q: searchValue,
            "parent-media-folder-id": selectedFolder,
            limit: 25,
          })
        );
      } else {
        // dispatch(fetchAllFolder({ q: searchValue }));
        setSearchFolder(searchValue || "");
      }
      if (
        !(
          "uncategorized attachments".includes(
            searchValue?.toLowerCase() || ""
          ) || searchValue === null
        )
      ) {
        setIsShowUncategorizedFolder(false);
      } else {
        setIsShowUncategorizedFolder(true);
      }
    }
  }

  function resetState() {
    setBreadcrumbData(defaultBreadcrumb);
    setSelectedFolder(null);
    setSelectedFile(null);
    setSearchValue(null);
    setSearchFolder("");
    setIsShowUncategorizedFolder(true);
  }

  function onHandleClickUncategorizedFolder() {
    setBreadcrumbData([
      breadcrumbData[0],
      { value: "null", label: "Uncategorized attachments" },
    ]);
    setSelectedFolder("null");
    setSearchValue(null);
    setIsShowUncategorizedFolder(true);
  }

  async function onFileUpload(filesList: FileList) {
    setUploadFileCount(Array.from(filesList).length);
    if (selectedFolder) dispatch(setUploadingFolder(selectedFolder));
    Array.from(filesList).forEach(async (item) => {
      await dispatch(
        uploadFile({
          file: item,
          selectedFolder,
          isAttachment,
        })
      );
    });
  }

  function onHandleUploadFailed(fileId: string) {
    dispatch(retryUploadFile({ fileId, isAttachment }));
  }

  function onHandleCancelUpload(fileId: string) {
    setUploadFileCount(uploadFileCount - 1);
    dispatch(cancelUploadFile(fileId));
  }

  function onMappingFolderItemsToMediaItemData(
    folderItems: IMediaResponse[]
  ): MediaItemData[] {
    return folderItems.slice(0, 25).map((item) => {
      return {
        src: item.urls?.original || "",
        alt: item.name,
        key: item.key,
      };
    });
  }

  function mappingMediaItemData() {
    const reactEle: React.ReactElement[] = [];
    if (files) {
      files.forEach((item, index) => {
        if (
          item &&
          `${item.uploadProgress.parentMediaFolderId}` === selectedFolder
        ) {
          reactEle.unshift(
            <UploadItem
              key={`${item.fileId || ""}-${index + 100}`}
              isAttachment
              layout="B"
              uploadState={item.state}
              onClickUploadAgain={() => onHandleUploadFailed(item.fileId)}
              onClickCancel={() => onHandleCancelUpload(item.fileId)}
              uploadProgress={item.uploadProgress.uploadProgress}
              uploadProgressFileSize={item.uploadProgress.loaded}
              totalFileSize={item.uploadProgress.total}
              imgRatio={1 / 1}
              imgData={{
                src: item.file ? URL.createObjectURL(item.file) : "",
                alt: item.name || "",
              }}
            />
          );
        }
      });
    }

    const mediaItems = onMappingFolderItemsToMediaItemData(folderItems).map(
      (item, index) => (
        <MediaItem
          layout="C"
          key={`${item?.key}-${item?.alt}-${index + 100}`}
          isSelected={Boolean(item.key === selectedFile?.key)}
          onClickMedia={() => {
            selectedFile?.key === folderItems[index].key
              ? setSelectedFile(null)
              : setSelectedFile(folderItems[index]);
          }}
          data={item}
          ratio={1 / 1}
        />
      )
    );
    return reactEle.concat(mediaItems).slice(0, 25);
  }

  useEffect(() => {
    if (selectedFolder) {
      dispatch(
        fetchAttachmentList({
          q: searchValue,
          "parent-media-folder-id": selectedFolder,
          limit: 25,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolder]);

  useEffect(() => {
    if (newFiles.length && selectedFolder) {
      newFiles.forEach((item, index) => {
        dispatch(addNewFile(item.data));
        dispatch(clearUploaded({ index, fileId: item.fileId }));
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFiles]);

  useEffect(() => {
    if (
      uploadCompleted.length === uploadFileCount &&
      uploadCompleted.length > 0
    ) {
      dispatch(resetUpload());
      setUploadFileCount(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadCompleted]);

  function reset() {
    dispatch(resetMedia());
    dispatch(resetUpload());
  }

  return (
    <Modal
      onClose={() => {
        onCloseModal();
        resetState();
        reset();
      }}
      opened={Boolean(isModalOpened)}
      title={selectedFolder ? "Choose an attachment" : "Choose a folder"}
      // description={
      //   selectedFolder
      //     ? undefined
      //     : "Choose a folder where your attachment is located"
      // }
      size={605}
      secondaryButtonTitle="Cancel"
      onClickSecondaryButton={() => {
        onCloseModal();
        resetState();
      }}
      onClickPrimaryButton={() => {
        onClickChooseFile(selectedFile);
        resetState();
      }}
      primaryButtonTitle={"Choose attachment"}
      isDisablePrimaryButton={!Boolean(selectedFile)}
    >
      <>
        <ImageUploadModal>
          <Breadcrumb
            separator={<FontAwesomeIcon icon={["fas", "angle-right"]} />}
            data={breadcrumbData}
            onClickBack={() => {
              resetState();
              dispatch(fetchAllFolder({ limit: 15 }));
            }}
          />
          <SearchInput
            onKeyDown={(e) => onHandleSearch(e)}
            value={searchValue || ""}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setSearchValue(event.target.value)
            }
          />
          {breadcrumbData.length <= 1 && (
            <FolderList
              uncategorizedFolderName={"Uncategorized attachments"}
              onClickUncategorizedFolder={() =>
                onHandleClickUncategorizedFolder()
              }
              onClickFolder={(item) => {
                setBreadcrumbData([
                  breadcrumbData[0],
                  { value: item.id, label: item.name },
                ]);
                setSelectedFolder(item.id || null);
                setSearchValue(null);
                setIsShowUncategorizedFolder(true);
              }}
              isShowUncategorizedFolder={isShowUncategorizedFolder}
              search={searchFolder || ""}
              isShowPagination
            />
          )}
          {selectedFolder && (
            <>
              <MediaItemContainer>
                <HorizontalList>
                  <>{mappingMediaItemData()}</>
                </HorizontalList>
              </MediaItemContainer>
              <PaginateWrapper>
                <Pagination
                  page={paginationMediaItem?.currentPage}
                  total={
                    // selectedFolder
                    paginationMediaItem?.lastPage || 1
                    // : pagination.lastPage || 1
                  }
                  onChange={(e) =>
                    dispatch(
                      fetchAttachmentList({
                        q: searchValue,
                        page: `${e}`,
                        "parent-media-folder-id": selectedFolder,
                        limit: 25,
                      })
                    )
                  }
                  // withControls={false}
                />
              </PaginateWrapper>
            </>
          )}
          {selectedFolder && (
            <>
              <UploadTextWrapper>
                <UploadText>
                  Dont't have attachment you're looking for?
                </UploadText>
                <UploadFileField
                  textFont="md"
                  multipleFile
                  onFileUpload={(fileList) => onFileUpload(fileList)}
                  text={"Upload attachment"}
                  acceptType={".pdf"}
                />
              </UploadTextWrapper>
            </>
          )}
        </ImageUploadModal>
      </>
    </Modal>
  );
}
