/* eslint-disable react-hooks/rules-of-hooks */
/* eslint array-callback-return: "off" */
import _ from "lodash";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  OtherMenu,
  OtherContainer,
  ContentContainer,
  DeleteButton,
  DeleteTitle,
  DeleteWrapper,
  FormGroup,
  ImageTitleLabel,
  SectionTitle,
  SectionWrapper,
  TextGroup,
  RemoveImageButton,
  ButtonGroup,
} from "./EditorSidebar.style";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setIsEnableSave,
  setSectionError,
  deleteSection,
  resetCurrentEdit,
  updateSettings,
  resetDeleteSectionState,
  updateCurrentSection,
  setCurrentEditType,
  updateSection,
} from "../../../store/features/editor";
import { ModalDelete } from "../modalDelete/ModalDelete";
import {
  ComponentSpecProps,
  EditableUserSettingTypes,
  PublishedSectionProps,
  SettingProps,
} from "../../../types";
import { Modal } from "../../atoms/modal/Modal";
import { ErrorMessage } from "../messages/ErrorMessage";
import { Toggle } from "../../atoms/toggle/Toggle";
import { setShouldSaveDraft } from "../../../store/features/page";
import { TextInput } from "../../../atoms/textInput/TextInput";
import { useDebouncedCallback } from "use-debounce";
import { ImageInput } from "../../../atoms/imageInput/ImageInput";
import { Select } from "../../../atoms/select/Select";
import { SelectItem } from "@mantine/core";
import { ColorSelector } from "../../../molecules/colorSelector/ColorSelector";
import { TextEditor } from "../../../atoms/textEditor/TextEditor";

export const SectionEditAppearance = () => {
  const dispatch = useAppDispatch();
  const [isDeleteModalShowing, setIsDeleteModalShowing] =
    useState<boolean>(false);
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [isShowDeleteImageModal, setIsShowDeleteImageModal] =
    useState<boolean>(false);
  const { currentEdit, deleteSectionState, error, backgroundColorOptions } =
    useAppSelector((state) => state.editor);
  const debounced = useDebouncedCallback(
    // function
    (value, fieldKey) => {
      handleSettingChange(value, fieldKey);
    },
    // delay in ms
    1000
  );

  useEffect(() => {
    if (deleteSectionState.isSuccess) {
      dispatch(resetCurrentEdit());
      dispatch(resetDeleteSectionState());
    }
  }, [deleteSectionState.isSuccess]);

  // ประกาศ temp เพื่อมาเก็บค่าสำหรับแก้ไข childComponentData(ถ้ามี) หรือ componentData
  const currentComponent = currentEdit.childComponent
    ? ({ ...currentEdit.childComponent } as PublishedSectionProps)
    : ({ ...currentEdit.component } as PublishedSectionProps);
  const componentSpec = currentEdit.componentSpec as ComponentSpecProps;

  // Set default values when the spec changes after the section has already been created.
  if (currentComponent) {
    Object.entries(componentSpec.settingFields).forEach(([key, fieldData]) => {
      if (currentComponent.settings && !(key in currentComponent.settings)) {
        currentComponent.settings = {
          ...currentComponent.settings,
          [key]: fieldData.defaultValue,
        };
      }
    });
  }

  const hasDataSources1 = "dataSources" in componentSpec;
  const hasDataSources2 = "dataSources2" in componentSpec;

  const onConfirmDelete = async () => {
    if (_.isNumber(currentEdit.selectedChildIndex)) {
      let newComponentData = _.cloneDeep(currentEdit.component);
      newComponentData?.childComponents?.splice(
        currentEdit.selectedChildIndex,
        1
      );
      await dispatch(
        updateSection({
          sectionId: newComponentData?.id,
          payload: newComponentData,
        })
      );
    } else {
      await dispatch(deleteSection(currentComponent?.id as string));
    }
    dispatch(setShouldSaveDraft(true));
    dispatch(resetCurrentEdit());
    dispatch(resetDeleteSectionState());
  };

  const renderOtherSettings = () => (
    <>
      {/* ไม่ต้องแสดง ตั้งค่าการมองเห็น ถ้าเป็น Child components */}
      {!currentEdit.childComponent && (
        <OtherContainer>
          <SectionWrapper>
            <SectionTitle>ตั้งค่าการมองเห็น</SectionTitle>
            <Toggle
              key="hidden-toggle"
              label="ซ่อน Section นี้"
              value={currentComponent?.isHidden as boolean}
              onChange={(toggleValue: boolean) => {
                // @ts-expect-error: for some reason
                dispatch(updateCurrentSection({ isHidden: toggleValue }));
                dispatch(setShouldSaveDraft(true));
              }}
            />
          </SectionWrapper>
        </OtherContainer>
      )}
      <OtherContainer>
        <SectionWrapper>
          <SectionTitle>ตั้งค่าอื่น ๆ</SectionTitle>
          {Boolean(hasDataSources1) && (
            <OtherMenu
              onClick={() => {
                dispatch(setCurrentEditType('data'));
              }}
            >
              เลือกคอนเทนต์หลัก
              <FontAwesomeIcon icon={['fas', 'chevron-right']} />
            </OtherMenu>
          )}
          {Boolean(hasDataSources2) && (
            <OtherMenu
              onClick={() => {
                dispatch(setCurrentEditType('data2'));
              }}
            >
              เลือกคอนเทนต์รอง
              <FontAwesomeIcon icon={['fas', 'chevron-right']} />
            </OtherMenu>
          )}
          <DeleteWrapper>
            <DeleteTitle>ต้องการลบ{currentEdit.selectedChildIndex !== null ? 'คอนเทนต์ย่อย' : ' Section'} นี้?</DeleteTitle>
            <DeleteButton
              type="button"
              onClick={() => setIsDeleteModalShowing(true)}
            >
              ลบ{currentEdit.selectedChildIndex !== null ? 'คอนเทนต์ย่อย' : ' Section'}
            </DeleteButton>
          </DeleteWrapper>
        </SectionWrapper>
        <ModalDelete
          title="ยืนยันการลบ"
          description={`คุณยืนยันที่จะลบ${currentEdit.selectedChildIndex !== null ? 'คอนเทนต์ย่อย' : ' Section'} ${currentComponent?.name} ใช่หรือไม่?`}
          isOpen={isDeleteModalShowing}
          onClose={() => setIsDeleteModalShowing(false)}
          onConfirm={onConfirmDelete}
        />
        {error && (
          <Modal
            title="มีข้อผิดพลาดเกิดขึ้น"
            isOpen={Boolean(error)}
            onClose={() => dispatch(setSectionError(null))}
          >
            <ErrorMessage
              title="ไม่สามารถบันทึกการแก้ไขได้ กรุณาลองใหม่อีกครั้ง"
              description={error.status ? `Error Code: ${error.status}` : ''}
            />
          </Modal>
        )}
      </OtherContainer>
    </>
  );

  const { required } = componentSpec;

  const checkIfShouldEnableSave = () => {
    let haveMissingValue = false;
    if (required) {
      haveMissingValue = Object.entries(currentComponent.settings).some(
        // @ts-ignore
        ([key, value]) => required[key] && !value
      );
    }
    dispatch(setIsEnableSave(!haveMissingValue));
    dispatch(setShouldSaveDraft(!haveMissingValue));
  };

  const handleSettingChange = (value: any, field: EditableUserSettingTypes) => {
    const object: SettingProps = {};
    object[field] = value;
    dispatch(updateSettings(object));
    dispatch(setShouldSaveDraft(true));
  };

  // const handleComponentSettingChange = (value: boolean, field: EditableComponentSettingTypes) => {
  //   const object: ComponentSettingsProps = {};
  //   // @ts-expect-error: Incompatible
  //   object[field] = value;
  //   dispatch(updateComponentSettings(object));
  //   dispatch(setShouldSaveDraft(true));
  // };

  // const onCancelDeleteImage = () => {
  //   setIsShowDeleteImageModal(false);
  // };

  const onConfirmDeleteImage = (fieldName: string) => {
    dispatch(updateSettings({ [fieldName]: "" }));
    dispatch(setShouldSaveDraft(true));
    setIsShowDeleteImageModal(false);
  };

  return (
    <>
      {/* <ModalDelete
        title="คุณยืนยันที่จะลบภาพหรือไม่"
        description="เมื่อลบแล้วจะไม่สามารถกู้คืนได้อีก"
        isOpen={isShowDeleteImageModal}
        onClose={onCancelDeleteImage}
        onConfirm={onConfirmDeleteImage} /> */}
      <ContentContainer>
        <SectionWrapper>
          <SectionTitle>ตั้งค่าการแสดงผล</SectionTitle>
          {/* ไม่ต้องแสดง Layout ถ้าเป็น Child components */}
          {!currentEdit.childComponent && (
            <TextGroup>
              <label>Layout</label>
              <span>{componentSpec.name}</span>
            </TextGroup>
          )}
          {componentSpec.settingFields &&
            Object.entries(componentSpec.settingFields).map(
              ([key, fieldData]) => {
                const fieldName = key as EditableUserSettingTypes;
                const displayName = fieldData?.displayName || "";
                const value = _.get(
                  currentComponent,
                  `settings[${key}]`,
                  fieldData.defaultValue
                );
                let isRequired = false;
                if (required) {
                  // @ts-expect-error: Incompatible
                  isRequired = required[key];
                }
                // Input's value cannot be null
                // const inputFile = useRef<HTMLInputElement | null>(null);
                const [isInputChanged, setIsInputChanged] =
                  useState<boolean>(false);

                // ถ้าเป็นฟิลด์ ให้แสดง UI สำหรับอัปโหลดภาพ
                if (fieldData.type === "image") {
                  return (
                    <FormGroup key={`input-${fieldName}`}>
                      <ImageTitleLabel>
                        {isRequired ? `${displayName}*` : displayName}
                        {Boolean(value) && (
                          <ButtonGroup>
                            <RemoveImageButton
                              onClick={() => onConfirmDeleteImage(fieldName)}
                            >
                              <FontAwesomeIcon icon={["far", "trash-alt"]} />{" "}
                              ลบภาพ
                            </RemoveImageButton>
                          </ButtonGroup>
                        )}
                      </ImageTitleLabel>
                      <ImageInput
                        data={{ imgUrl: value, imgAlt: "component-image" }}
                        onClickChooseImage={(imgData) =>
                          handleSettingChange(imgData?.urls.original, fieldName)
                        }
                      />
                    </FormGroup>
                  );
                }

                // if (key === 'backgroundColor') {
                //   const colorValue = value as string;
                //   return (
                //     <div key={`background-color-field-${colorValue}`}>
                //       <Toggle
                //         label={displayName}
                //         value={colorValue !== 'transparent'}
                //         onChange={(val: boolean) => {
                //           if (val) {
                //             dispatch(
                //               updateSettings({ backgroundColor: '#000000' })
                //             );
                //             dispatch(setShouldSaveDraft(true));
                //           } else {
                //             dispatch(
                //               updateSettings({ backgroundColor: 'transparent' })
                //             );
                //             dispatch(setShouldSaveDraft(true));
                //           }
                //         }}
                //       />
                //       {colorValue !== 'transparent' && (
                //         <FormGroup key="background-color-selector">
                //           <label htmlFor="background-color">
                //             กรุณาคลิกเพื่อเลือกสี
                //           </label>
                //           <input
                //             id="background-color"
                //             type="color"
                //             onBlur={() => dispatch(setShouldSaveDraft(true))}
                //             onChange={(e) => {
                //               dispatch(
                //                 updateSettings({
                //                   backgroundColor: e.target.value,
                //                 })
                //               );
                //             }}
                //             value={value as string}
                //           />
                //         </FormGroup>
                //       )}
                //     </div>
                //   );
                // }

                if (fieldData.type === "select") {
                  return (
                    <FormGroup key={`input-${fieldName}`}>
                      <Select
                        data={fieldData.options as SelectItem[]}
                        label={displayName}
                        value={value}
                        onChange={(selectValue) =>
                          handleSettingChange(selectValue, fieldName)
                        }
                      />
                    </FormGroup>
                  );
                }

                if (fieldData.type === "boolean") {
                  return (
                    <FormGroup key={`input-${fieldName}`}>
                      <Toggle
                        key={`toggle-${fieldName}-${value}`}
                        label={displayName}
                        value={value as boolean}
                        onChange={(val: boolean) =>
                          handleSettingChange(val, fieldName)

                          // dispatch(updateSettings({ [fieldName]: val }))
                        }
                      />
                    </FormGroup>
                  );
                }

                if (fieldData.type === "color-selector") {
                  return (
                    <FormGroup key={`input-${fieldName}`}>
                      <ColorSelector
                        data={
                          [
                            ...(fieldData.options as SelectItem[]),
                            ...backgroundColorOptions,
                          ] as SelectItem[]
                        }
                        label={displayName}
                        value={value}
                        onChange={(selectValue) =>
                          handleSettingChange(selectValue, fieldName)
                        }
                      />
                    </FormGroup>
                  );
                }

                if (fieldData.type === "text-editor") {
                  return (
                    <FormGroup key={`input-${fieldName}`}>
                      <label>
                        {displayName}
                        {Boolean(isRequired) && (
                          <span title="จำเป็นต้องกรอก">*</span>
                        )}
                      </label>
                      <TextEditor
                        isShowToolbar={false}
                        value={value}
                        onBlur={checkIfShouldEnableSave}
                        onChange={(e) => {
                          debounced(e, fieldName);
                        }}
                        onKeyUp={() => {
                          if (!isInputChanged) {
                            setIsInputChanged(true);
                          }
                        }}
                      />
                    </FormGroup>
                  );
                }

                // ถ้าไม่ใช่ฟิลด์ภาพ ให้แสดง Input ปกติ
                return (
                  <FormGroup key={`input-${fieldName}`}>
                    <label>
                      {displayName}
                      {Boolean(isRequired) && (
                        <span title="จำเป็นต้องกรอก">*</span>
                      )}
                    </label>
                    <TextInput
                      defaultValue={value}
                      onBlur={checkIfShouldEnableSave}
                      onChange={(e) => debounced(e.target.value, fieldName)}
                      onKeyUp={() => {
                        if (!isInputChanged) {
                          setIsInputChanged(true);
                        }
                      }}
                      error={
                        isInputChanged && isRequired && value?.length === 0
                          ? `จำเป็นต้องกรอก${displayName}`
                          : ""
                      }
                      required={isRequired}
                    />
                  </FormGroup>
                );
              }
            )}
          {/* { currentComponent.componentSettings && Object.entries(currentComponent.componentSettings).map(([key, value]) => {
            const fieldName = key as EditableComponentSettingTypes;
            const displayName = getComponentSettingDisplayName(fieldName);

            if (displayName === '') {
              return (<div key={`empty-div-${Math.random()}`} />);
            }

            if (key === 'backgroundColor') {
              const colorValue = value as string;
              return (
                <div key={`background-color-field-${colorValue}`}>
                  <Toggle
                    label={displayName}
                    value={colorValue !== 'transparent'}
                    onChange={(val: boolean) => {
                      if (val) {
                        dispatch(updateComponentSettings({ backgroundColor: '#000000' }));
                        dispatch(setShouldSaveDraft(true));
                      } else {
                        dispatch(updateComponentSettings({ backgroundColor: 'transparent' }));
                        dispatch(setShouldSaveDraft(true));
                      }
                    }} />
                  { colorValue !== 'transparent' && (
                    <FormGroup key="background-color-selector">
                      <label htmlFor="background-color">กรุณาคลิกเพื่อเลือกสี</label>
                      <input
                        id="background-color"
                        type="color"
                        onBlur={() => dispatch(setShouldSaveDraft(true))}
                        onChange={(e) => {
                          dispatch(updateComponentSettings({ backgroundColor: e.target.value }));
                        }}
                        value={value as string} />
                    </FormGroup>
                  ) }
                </div>
              );
            }

            if (key === 'backgroundColor') {
              const { backgroundImgUrl } = currentComponent.userSettings;

              const inputFile = useRef<HTMLInputElement | null>(null);
              const [uploadError, setUploadError] = useState<Error | null>(null);

              const onClickUploadButton = () => {
                if (inputFile.current) {
                  inputFile.current.click();
                }
              };

              const onChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
                const { files } = event.currentTarget;
                if (!files) {
                  return;
                }

                setIsImageUploading(true);
                try {
                  const response = await uploadImage(files[0]);
                  const imageUrl = response.data?.urls?.original;
                  if (imageUrl) {
                    dispatch(updateSettings({ backgroundImgUrl: imageUrl }));
                    dispatch(setShouldSaveDraft(true));
                  }
                  setIsImageUploading(false);
                } catch (err) {
                  setUploadError(err as Error);
                  setIsImageUploading(false);
                }
              };

              return (
                <div key={`background-image-field-${value}`}>
                  <FormGroup>
                    <label>
                      ภาพพื้นหลัง
                    </label>
                    <Select
                      options={[
                        { title: 'สีโปร่งใส', value: 'transparent' },
                        { title: 'สีส้มอ่อน', value: '#FFF0EA' },
                        { title: 'อัปโหลดรูปภาพพื้นหลัง', value: '#000000' },
                      ]}
                      defaultValue={value}
                      onSelect={(selectedColor) => {
                        dispatch(updateComponentSettings({ backgroundColor: selectedColor.value }));
                        dispatch(setShouldSaveDraft(true));
                      }} />
                  </FormGroup>
                  { value === '#000000' && (
                    <FormGroup>
                      <ImageTitleLabel>
                        { Boolean(backgroundImgUrl) && (
                          <ButtonGroup>
                            <ChangeImageButton onClick={onClickUploadButton} isImageUploading={isImageUploading}>
                              <FontAwesomeIcon icon={['far', 'repeat']} /> เปลี่ยนภาพ
                            </ChangeImageButton>
                            <RemoveImageButton onClick={onClickDeleteImage}>
                              <FontAwesomeIcon icon={['far', 'trash-alt']} /> ลบภาพ
                            </RemoveImageButton>
                          </ButtonGroup>
                        )}
                      </ImageTitleLabel>
                      <input
                        type="file"
                        ref={inputFile}
                        style={{ display: 'none' }}
                        accept="image/*"
                        onChange={onChangeFile} />
                      { backgroundImgUrl && !isImageUploading ? (
                        <ContentImage src={backgroundImgUrl} alt="" />
                      ) : (
                        <ContentEmptyImage onClick={onClickUploadButton} isImageUploading={isImageUploading}>
                          { isImageUploading && !uploadError && renderImageUploadingUI() }
                          { !isImageUploading && uploadError && renderImageErrorUI() }
                          { !isImageUploading && !uploadError && renderImageEmptyUI() }
                        </ContentEmptyImage>
                      ) }
                    </FormGroup>
                  ) }
                </div>
              );
            }

            if (key === 'limitItem' && currentComponentType !== 'ContentCardVerticalList') {
              return (<></>);
            }

            if (key === 'limitItem' && currentComponentType === 'ContentCardVerticalList') {
              return (
                <FormGroup key={`limit-item-${value}`}>
                  <label htmlFor="background-color">{displayName}</label>
                  <Input
                    id="limit-item"
                    type="number"
                    min={2}
                    step={2}
                    max={100}
                    onBlur={() => dispatch(setShouldSaveDraft(true))}
                    onChange={(e) => {
                      dispatch(updateComponentSettings({ limitItem: parseInt(e.target.value, 10) }));
                    }}
                    value={`${value}`} />
                </FormGroup>
              );
            }

            return (
              <Toggle
                key={`toggle-${fieldName}-${value}`}
                label={getComponentSettingDisplayName(fieldName)}
                value={value as boolean}
                onChange={(val: boolean) => handleComponentSettingChange(val, fieldName)} />
            );
          })} */}
        </SectionWrapper>
      </ContentContainer>
      {renderOtherSettings()}
    </>
  );
};
