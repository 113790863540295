import { createAction } from "@reduxjs/toolkit";
import createAsyncThunk from "../../middleware/customCreateThunk";
import {
  createUserAPI,
  loadRoleListAPI,
  loadUserByIdAPI,
  loadUserListAPI,
  updateUserAPI,
  userActivateByIdAPI,
  userChangePasswordAPI,
  userSuspendByIdAPI,
} from "../../../services/api/user";

export const fetchUserList = createAsyncThunk({
  api: loadUserListAPI,
  EVENT_NAME: "User/loadUserList",
});

export const fetchUserById = createAsyncThunk({
  api: loadUserByIdAPI,
  EVENT_NAME: "User/loadUserById",
});

export const updateUserById = createAsyncThunk({
  api: updateUserAPI,
  EVENT_NAME: "User/updateUserById",
});

export const createUser = createAsyncThunk({
  api: createUserAPI,
  EVENT_NAME: "User/createUser",
});

export const fetchRoleList = createAsyncThunk({
  api: loadRoleListAPI,
  EVENT_NAME: "User/loadRoleList",
});

export const userActivateById = createAsyncThunk({
  api: userActivateByIdAPI,
  EVENT_NAME: "User/activate",
});

export const userSuspendById = createAsyncThunk({
  api: userSuspendByIdAPI,
  EVENT_NAME: "User/suspend",
});

export const userChangePassword = createAsyncThunk({
  api: userChangePasswordAPI,
  EVENT_NAME: "User/changePassword",
});

export const resetRoleList = createAction("User/resetRoleList");
export const resetUserDetail = createAction("User/resetUserDetail");
export const resetUserList = createAction("User/resetUserList");
export const resetUser = createAction("User/reset");
