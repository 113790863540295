import { createReducer } from "@reduxjs/toolkit";
import { IMediaResponse, StatusState } from "../../../types";
import { IFolder } from "../../../types/media";
import {
  addNewFile,
  createFolder,
  deleteAttachmentById,
  deleteFolder,
  deleteFolderItemById,
  deleteImageById,
  fetchAllFolder,
  fetchAllImages,
  fetchAttachmentList,
  fetchFolderById,
  fetchFoldersForListPage,
  fetchImageList,
  fetchUncategorizedFolder,
  moveAttachment,
  moveImage,
  resetFolderDetailState,
  resetMedia,
  resetMediaItemDetail,
  setCurrentFolderId,
  updateAttachmentById,
  updateFolder,
  updateImageById,
} from "./actions";
import { getStateObject } from "../../../utils/stateManagement";
import { formatErrorObject } from "../../../utils/errorFormatter";

interface InitialStateProps {
  folders: IFolder[];
  foldersForListPage: IFolder[];
  uncategorizedFolder: IFolder | null;
  currentFolder: IFolder | null;
  currentFolderId: string;
  folderItems: IMediaResponse[];
  allFolderItems: IMediaResponse[];
  currentItem: IMediaResponse | null;
  loadFolderForPageListState: StatusState;
  loadFolderListState: StatusState;
  loadFolderAllItemState: StatusState;
  loadFolderItemState: StatusState;
  loadFolderByIdState: StatusState;
  loadUncategorizedFolderState: StatusState;
  deleteFolderState: StatusState;
  deleteImageState: StatusState;
  updateImageState: StatusState;
  moveImageState: StatusState;
  createFolderState: StatusState;
  updateFolderState: StatusState;
  moveAttachmentState: StatusState;
  updateAttachmentState: StatusState;
  deleteAttachmentState: StatusState;
  paginationListPage: {
    from: number;
    to: number;
    lastPage: number;
    perPage: number;
    total: number;
    currentPage: number;
  };
  paginationMediaItem: {
    from: number;
    to: number;
    lastPage: number;
    perPage: number;
    total: number;
    currentPage: number;
  };
  pagination: {
    from: number;
    to: number;
    lastPage: number;
    perPage: number;
    total: number;
    currentPage: number;
  };
}

const mediaItemListInitialState = {
  folderItems: [],
  allFolderItems: [],
  loadFolderAllItemState: getStateObject({ type: "idle" }),
  loadFolderItemState: getStateObject({ type: "idle" }),
  paginationMediaItem: {
    from: 1,
    to: 1,
    lastPage: 1,
    perPage: 1,
    total: 1,
    currentPage: 1,
  },
};

const mediaItemDetailInitialState = {
  currentItem: null,
  updateImageState: getStateObject({ type: "idle" }),
  deleteImageState: getStateObject({ type: "idle" }),
  moveImageState: getStateObject({ type: "idle" }),
  moveAttachmentState: getStateObject({ type: "idle" }),
  updateAttachmentState: getStateObject({ type: "idle" }),
  deleteAttachmentState: getStateObject({ type: "idle" }),
};

const folderListInitialState = {
  folders: [],
  foldersForListPage: [],
  loadFolderListState: getStateObject({ type: "idle" }),
  loadFolderForPageListState: getStateObject({ type: "idle" }),
  pagination: {
    from: 0,
    to: 0,
    lastPage: 0,
    perPage: 0,
    total: 0,
    currentPage: 0,
  },
  paginationListPage: {
    from: 0,
    to: 0,
    lastPage: 0,
    perPage: 0,
    total: 0,
    currentPage: 0,
  },
};

const folderDetailInitialState = {
  uncategorizedFolder: null,
  currentFolder: null,
  currentFolderId: "",
  loadFolderByIdState: getStateObject({ type: "idle" }),
  loadUncategorizedFolderState: getStateObject({ type: "idle" }),
  deleteFolderState: getStateObject({ type: "idle" }),
  createFolderState: getStateObject({ type: "idle" }),
  updateFolderState: getStateObject({ type: "idle" }),
};

const initialState: InitialStateProps = {
  ...mediaItemListInitialState,
  ...mediaItemDetailInitialState,
  ...folderListInitialState,
  ...folderDetailInitialState,
};

export const mediaReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAllFolder.pending, (state) => {
      state.loadFolderListState = getStateObject({ type: "loading" });
    })
    .addCase(fetchAllFolder.fulfilled, (state, { payload }) => {
      state.folders = payload?.data;
      state.pagination = {
        from: payload?.from,
        to: payload?.to,
        lastPage: payload?.lastPage,
        perPage: payload?.perPage,
        total: payload?.total,
        currentPage: payload?.currentPage,
      };
      state.loadFolderListState = getStateObject({ type: "success" });
    })
    .addCase(fetchAllFolder.rejected, (state, { payload }) => {
      state.folders = [];
      state.loadFolderListState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Media All Folder"),
      });
    })
    .addCase(fetchFoldersForListPage.pending, (state) => {
      state.loadFolderForPageListState = getStateObject({ type: "loading" });
    })
    .addCase(fetchFoldersForListPage.fulfilled, (state, { payload }) => {
      state.foldersForListPage = payload?.data;
      state.paginationListPage = {
        from: payload?.from,
        to: payload?.to,
        lastPage: payload?.lastPage,
        perPage: payload?.perPage,
        total: payload?.total,
        currentPage: payload?.currentPage,
      };
      state.loadFolderForPageListState = getStateObject({ type: "success" });
    })
    .addCase(fetchFoldersForListPage.rejected, (state, { payload }) => {
      state.foldersForListPage = [];
      state.loadFolderForPageListState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Media All Folder For List Page"),
      });
    })
    .addCase(fetchImageList.pending, (state) => {
      state.loadFolderItemState = getStateObject({ type: "loading" });
    })
    .addCase(fetchImageList.fulfilled, (state, { payload }) => {
      state.folderItems = payload?.data;
      state.paginationMediaItem = {
        from: payload?.from,
        to: payload?.to,
        lastPage: payload?.lastPage,
        perPage: payload?.perPage,
        total: payload?.total,
        currentPage: payload?.currentPage,
      };
      state.loadFolderItemState = getStateObject({ type: "success" });
    })
    .addCase(fetchImageList.rejected, (state, { payload }) => {
      state.allFolderItems = [];
      state.loadFolderItemState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Media All Folder"),
      });
    })
    .addCase(fetchAllImages.pending, (state) => {
      state.loadFolderAllItemState = getStateObject({ type: "loading" });
    })
    .addCase(fetchAllImages.fulfilled, (state, { payload }) => {
      state.allFolderItems = payload?.data;
      state.loadFolderAllItemState = getStateObject({ type: "success" });
    })
    .addCase(fetchAllImages.rejected, (state, { payload }) => {
      state.allFolderItems = [];
      state.loadFolderAllItemState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Media All Folder"),
      });
    })
    .addCase(fetchAttachmentList.pending, (state) => {
      state.loadFolderItemState = getStateObject({ type: "loading" });
    })
    .addCase(fetchAttachmentList.fulfilled, (state, { payload }) => {
      state.folderItems = payload?.data;
      state.paginationMediaItem = {
        from: payload?.from,
        to: payload?.to,
        lastPage: payload?.lastPage,
        perPage: payload?.perPage,
        total: payload?.total,
        currentPage: payload?.currentPage,
      };
      state.loadFolderItemState = getStateObject({ type: "success" });
    })
    .addCase(fetchAttachmentList.rejected, (state, { payload }) => {
      state.folderItems = [];
      state.loadFolderItemState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Media All Folder"),
      });
    })
    .addCase(fetchFolderById.pending, (state) => {
      state.loadFolderByIdState = getStateObject({ type: "loading" });
    })
    .addCase(fetchFolderById.fulfilled, (state, { payload }) => {
      state.currentFolder = payload;
      state.loadFolderByIdState = getStateObject({ type: "success" });
    })
    .addCase(fetchFolderById.rejected, (state, { payload }) => {
      state.currentFolder = null;
      state.loadFolderByIdState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Folder"),
      });
    })
    .addCase(fetchUncategorizedFolder.pending, (state) => {
      state.loadUncategorizedFolderState = getStateObject({ type: "loading" });
    })
    .addCase(fetchUncategorizedFolder.fulfilled, (state, { payload }) => {
      state.uncategorizedFolder = payload;
      state.loadUncategorizedFolderState = getStateObject({ type: "success" });
    })
    .addCase(fetchUncategorizedFolder.rejected, (state, { payload }) => {
      state.uncategorizedFolder = null;
      state.loadUncategorizedFolderState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Uncategorized Folder"),
      });
    })
    .addCase(createFolder.pending, (state) => {
      state.createFolderState = getStateObject({ type: "loading" });
    })
    .addCase(createFolder.fulfilled, (state, { payload }) => {
      state.currentFolder = payload;
      state.currentFolderId = payload?.id;
      state.createFolderState = getStateObject({ type: "success" });
    })
    .addCase(createFolder.rejected, (state, { payload }) => {
      state.createFolderState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Create New Folder"),
      });
    })
    .addCase(updateFolder.pending, (state) => {
      state.updateFolderState = getStateObject({ type: "loading" });
    })
    .addCase(updateFolder.fulfilled, (state, { payload }) => {
      state.updateFolderState = getStateObject({ type: "success" });
    })
    .addCase(updateFolder.rejected, (state, { payload }) => {
      state.updateFolderState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Update Folder"),
      });
    })
    .addCase(deleteFolder.pending, (state) => {
      state.deleteFolderState = getStateObject({ type: "loading" });
    })
    .addCase(deleteFolder.fulfilled, (state, { payload }) => {
      state.deleteFolderState = getStateObject({ type: "success" });
    })
    .addCase(deleteFolder.rejected, (state, { payload }) => {
      state.deleteFolderState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Delete Folder"),
      });
    })
    .addCase(deleteImageById.pending, (state) => {
      state.deleteImageState = getStateObject({ type: "loading" });
    })
    .addCase(deleteImageById.fulfilled, (state, { payload }) => {
      state.deleteImageState = getStateObject({ type: "success" });
    })
    .addCase(deleteImageById.rejected, (state, { payload }) => {
      state.deleteImageState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Delete Image"),
      });
    })
    .addCase(updateImageById.pending, (state) => {
      state.updateImageState = getStateObject({ type: "loading" });
    })
    .addCase(updateImageById.fulfilled, (state, { payload }) => {
      state.updateImageState = getStateObject({ type: "success" });
    })
    .addCase(updateImageById.rejected, (state, { payload }) => {
      state.updateImageState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Update Image"),
      });
    })
    .addCase(moveImage.pending, (state) => {
      state.moveImageState = getStateObject({ type: "loading" });
    })
    .addCase(moveImage.fulfilled, (state, { payload }) => {
      state.moveImageState = getStateObject({ type: "success" });
    })
    .addCase(moveImage.rejected, (state, { payload }) => {
      state.moveImageState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Move Image"),
      });
    })
    .addCase(updateAttachmentById.pending, (state) => {
      state.updateAttachmentState = getStateObject({ type: "loading" });
    })
    .addCase(updateAttachmentById.fulfilled, (state, { payload }) => {
      state.updateAttachmentState = getStateObject({ type: "success" });
    })
    .addCase(updateAttachmentById.rejected, (state, { payload }) => {
      state.updateAttachmentState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Update Attachment"),
      });
    })
    .addCase(deleteAttachmentById.pending, (state) => {
      state.deleteAttachmentState = getStateObject({ type: "loading" });
    })
    .addCase(deleteAttachmentById.fulfilled, (state, { payload }) => {
      state.deleteAttachmentState = getStateObject({ type: "success" });
    })
    .addCase(deleteAttachmentById.rejected, (state, { payload }) => {
      state.deleteAttachmentState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Delete Attachment"),
      });
    })
    .addCase(moveAttachment.pending, (state) => {
      state.moveAttachmentState = getStateObject({ type: "loading" });
    })
    .addCase(moveAttachment.fulfilled, (state, { payload }) => {
      state.moveAttachmentState = getStateObject({ type: "success" });
    })
    .addCase(moveAttachment.rejected, (state, { payload }) => {
      state.moveAttachmentState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Move Attachment"),
      });
    })
    .addCase(deleteFolderItemById, (state, { payload }) => {
      const objIndex = state.folderItems.findIndex(
        (item) => item.id === payload
      );
      if (objIndex >= 0) {
        state.folderItems.splice(objIndex, 1);
      }
    })
    .addCase(addNewFile, (state, { payload }) => {
      state.folderItems = [payload, ...state.folderItems];
    })
    .addCase(setCurrentFolderId, (state, { payload }) => {
      state.currentFolderId = payload;
    })
    .addCase(resetFolderDetailState, (state) => {
      Object.assign(state, folderDetailInitialState);
    })
    .addCase(resetMediaItemDetail, (state) => {
      Object.assign(state, mediaItemDetailInitialState);
      Object.assign(state, mediaItemListInitialState);
    })
    .addCase(resetMedia, (state) => {
      Object.assign(state, initialState);
    });
});
