import React from "react";
import { Heading } from "../../organisms/heading/Heading";
import { Section } from "../../organisms/section/Section";
import { Container } from "./ListPageLayout.style";

interface ListPageLayoutProps {
  pageTitle: string;
  actionComponent?: React.ReactElement;
  children: React.ReactElement;
}

export function ListPageLayout(props: ListPageLayoutProps) {
  const { pageTitle, actionComponent, children } = props;
  return (
    <Container>
      <Heading title={pageTitle} actionComponent={actionComponent} />
      <Section>{children}</Section>
    </Container>
  );
}
