import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MainText = styled.div`
  color: var(--grey700);
  font-size: 14px;
  font-weight: 500;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const SubText = styled.div`
  color: var(--grey600);
  font-size: 12px;
  font-weight: 500;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-right: 30%;

  .highlight-text {
    font-weight: 400;
  }
`;
