import _ from "lodash";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  brandSelector,
  deletedBrandById,
  resetBrandDetailState,
  updatedBrandById,
} from "../../store/features/brand";
import {
  createPage,
  deletePage,
  pageDataSelector,
  resetCreatePageState,
  updatePage,
} from "../../store/features/page";
import { productTypeSelector } from "../../store/features/productType";
import { addToast } from "../../store/features/toast";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ManageBrand } from "../../templates/manageBrand/ManageBrand";
import { IBrandEditable, initBrand } from "../../types";
import { mapConvertThemeDataTo6DigitHexstring } from "../../utils/mapData";

export function BrandDetail() {
  const { id: brandId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    currentBrand: brand,
    updateBrandState,
    deleteBrandState,
  } = useAppSelector(brandSelector);
  const { createPageState, newPage } = useAppSelector(pageDataSelector);
  const { productTypes } = useAppSelector(productTypeSelector);

  async function onHandleDeleteBrand(brandId?: string) {
    if (brandId) {
      await dispatch(deletedBrandById(brandId));
      await dispatch(deletePage(`resource:${brandId}`));
      navigate("/brand");
    }
  }

  async function onCreatePage() {
    if (brand?.name && brand?.slugCode) {
      await dispatch(
        createPage({
          title: `${brand.name} (${brand.languageCode.toUpperCase()})`,
          path: `/${brand.languageCode}/project/${
            brand?.productType?.code || ""
          }/${brand?.slugCode}`,
          category: "brand",
          resourceId: brand.id,
          resourceUrl: `/brand/${brand.id}`,
          slug: `${brand?.slugCode}-${brand.languageCode}`,
          languageCode: brand.languageCode,
        })
      );
    }
  }

  async function onHandleClickSaveBrand(brandData: IBrandEditable) {
    const productTypeCode = _.get(
      _.find(productTypes, { id: brandData?.productTypeId }),
      "code",
      ""
    );
    if (brandId && brandData) {
      await dispatch(
        updatedBrandById({
          brandId: brandId,
          data: {
            ...initBrand,
            ..._.omit(brandData, ["createdAt", "updatedAt"]),
            theme: mapConvertThemeDataTo6DigitHexstring(brandData.theme),
          },
        })
      );
      await dispatch(
        updatePage({
          pageIdOrPath: `resource:${brand?.id}`,
          payload: {
            title: `${
              brandData?.name || ""
            } (${brandData?.languageCode.toUpperCase()})`,
            path: productTypeCode
              ? `/${brandData?.languageCode}/project/${productTypeCode}/${brandData?.slugCode}`
              : undefined,
            slug: !_.isEqual(brand?.slugCode, brandData.slugCode)
              ? `${brandData?.slugCode}-${brandData?.languageCode}`
              : undefined,
          },
        })
      );
    }
  }

  useEffect(() => {
    const status = createPageState.status;
    if (status === "success") {
      navigate(`/page-builder/page/${newPage?.id}`);

      return () => {
        dispatch(resetCreatePageState());
        dispatch(resetBrandDetailState());
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPageState.status]);

  useEffect(() => {
    const status = updateBrandState.status;
    if (status === "success") {
      dispatch(addToast({ status }));

      return () => {
        dispatch(resetCreatePageState());
      };
    }
    if (status === "error")
      dispatch(addToast({ status, title: "Update failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateBrandState.status, dispatch]);

  useEffect(() => {
    const status = deleteBrandState.status;
    if (status === "success")
      dispatch(addToast({ status, title: "Successfully deleted" }));
    if (status === "error")
      dispatch(addToast({ status, title: "Delete failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteBrandState.status, dispatch]);

  return (
    <ManageBrand
      brandId={brandId}
      pageTitle={brand?.name || ""}
      submitButtonTitle={"Save"}
      isShowUpdatedAt
      isShowPreviewButton
      onClickPreview={() => {
        onCreatePage();
      }}
      onClickSubmit={(data) => onHandleClickSaveBrand(data)}
      onClickDelete={() => onHandleDeleteBrand(brandId)}
    />
  );
}
