import _ from 'lodash';
import type { IError } from '../types';

const findKeyErrorByName = (errorResponse: any, fieldName: string) => _.some(errorResponse, (valueObject, key) => key.startsWith(fieldName) && _.some(valueObject, (value) => value));

/**
 * A function that return error object that include status code and message.
 * @param {*} error
 * @param {*} itemType a type of item that use to show on error message
 * @param {*} customErrorMessage is set of custom error messages (Example data: { 404: 'Project not found', 422: 'Project name is already in used' })
 */
const formatErrorObject = (error: any, itemType: string, customErrorMessage: any = {}): IError => {
  const statusCode = _.get(error, 'response.status', null);
  const errorResponseData = _.get(error, 'data', {});

  const defaultError: { [key: number]: string } = {
    403: 'คุณไม่มีสิทธิ์เข้าถึงหน้านี้',
    404: `ไม่พบ ${itemType} นี้`,
    422: 'กรอกข้อมูลไม่ถูกต้อง กรุณาตรวจสอบข้อมูลอีกครั้ง',
    500: 'พบข้อผิดพลาด กรุณาลองอีกครั้งหรือติดต่อผู้ดูแลระบบ',
  };

  return {
    statusCode,
    message: customErrorMessage[statusCode] || defaultError[statusCode] || 'พบข้อผิดพลาดระหว่างโหลดข้อมูล',
    errorFields: errorResponseData,
  };
};

export {
  findKeyErrorByName,
  formatErrorObject,
};
