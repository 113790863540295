import _ from "lodash";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import {
  deleteHouseCollectionById,
  fetchHouseCollectionDetail,
  houseCollectionSelector,
  updateHouseCollectionById,
  resetHouseCollectionDetailData,
} from "../../store/features/houseCollection";
import { addToast } from "../../store/features/toast";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ManageHouseCollection } from "../../templates/manageHouseCollection/ManageHouseCollection";
import { lang } from "../../types";

export default function HouseCollectionDetail() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const languageCode = lang;
  const { id: houseCollectionId } = useParams();
  const {
    currentHouseCollection: houseCollection,
    updateHouseCollectionState,
    deleteHouseCollectionState,
  } = useAppSelector(houseCollectionSelector);

  useEffect(() => {
    dispatch(resetHouseCollectionDetailData());
    dispatch(fetchHouseCollectionDetail(houseCollectionId));

    return () => {
      dispatch(resetHouseCollectionDetailData());
    };
  }, [dispatch, houseCollectionId]);

  useEffect(() => {
    const status = updateHouseCollectionState.status;
    if (status === "success") dispatch(addToast({ status }));
    if (status === "error")
      dispatch(addToast({ status, title: "Update failed" }));
  }, [updateHouseCollectionState.status, dispatch]);

  useEffect(() => {
    const status = deleteHouseCollectionState.status;
    if (status === "success")
      dispatch(addToast({ status, title: "Successfully deleted" }));
    if (status === "error")
      dispatch(addToast({ status, title: "Delete failed" }));
  }, [deleteHouseCollectionState.status, dispatch]);

  const onClickSave = () => {
    const contentVariation = _.find(houseCollection?.contentVariations, {
      languageCode: languageCode,
    }) || { description: "" };
    dispatch(
      updateHouseCollectionById({
        houseCollectionId,
        data: {
          ...houseCollection,
          contentVariation: {
            ...contentVariation,
            description: contentVariation?.description || "",
          },
        },
      })
    );
  };

  const onClickDelete = () => {
    dispatch(deleteHouseCollectionById(houseCollectionId));
    navigate("/house-collection");
  };

  return (
    <>
      <ManageHouseCollection
        pageTitle={
          _.find(houseCollection?.contentVariations, {
            languageCode: languageCode,
          })?.name || ""
        }
        onClickSubmit={onClickSave}
        onClickDelete={onClickDelete}
      />
    </>
  );
}
