import { ChangeEvent, useEffect, useState } from "react";
import { Select } from "../../../../atoms/select/Select";
import { TextArea } from "../../../../atoms/textArea/TextArea";
import { TextInput } from "../../../../atoms/textInput/TextInput";
import { DistanceContainer } from "../../../../pages/projectDetail/ProjectDetail.style";
import { unitDistance } from "../../../../types";
import { initialPlace, PlaceDataType } from "../../../../types/nearby";
import { Modal } from "../../../modal/Modal";
import { NearbyModalWrapper } from "../SectionNearby.style";

interface ModalEditPlaceProps {
  placeData: PlaceDataType;
  isModalOpened: boolean;
  onClose: () => void;
  onUpdatePlace: (data: PlaceDataType) => void;
}

export function ModalEditPlace(props: ModalEditPlaceProps) {
  const { isModalOpened, onClose, onUpdatePlace, placeData } = props;
  const [tempPlaceData, setTempPlaceData] =
    useState<PlaceDataType>(initialPlace);

  useEffect(() => {
    setTempPlaceData(placeData);
  }, [placeData]);

  return (
    <Modal
      onClose={() => onClose()}
      opened={isModalOpened}
      title={"Edit Nearby"}
      size={550}
      primaryButtonTitle={"Save"}
      onClickPrimaryButton={() => {
        onUpdatePlace(tempPlaceData);
      }}
      secondaryButtonTitle="Cancel"
      onClickSecondaryButton={() => {
        onClose();
      }}
    >
      <>
        <NearbyModalWrapper>
            <DistanceContainer>
              <TextInput
                autoComplete={"off"}
                type="number"
                label={"Distance"}
                value={tempPlaceData.distance}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setTempPlaceData({
                    ...tempPlaceData,
                    distance: parseFloat(e.target.value),
                  });
                }}
              />
              <Select
                data={unitDistance}
                value={tempPlaceData.unit}
                onChange={(e: string) => {
                  setTempPlaceData({ ...tempPlaceData, unit: e });
                }}
              />
            </DistanceContainer>

            <TextArea
              label={"Place list (one per line)"}
              placeholder={"Enter places"}
              value={tempPlaceData?.names.join("\n")}
              height={150}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                setTempPlaceData({
                  ...tempPlaceData,
                  names: e.target.value.split("\n"),
                });
              }}
            />
        </NearbyModalWrapper>
      </>
    </Modal>
  );
}
