import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Title = styled.div<{ titlePosition: "left" | "center" | "right" }>`
  flex: 0 0 auto;
  font-weight: 500;
  font-size: var(--font-size-xl);
  color: var(--grey900);
  ${(props) => {
    if (props.titlePosition === "center") {
      return css`
        margin: 0 8px;
      `;
    } else if (props.titlePosition === "right") {
      return css`
        margin-left: 8px;
      `;
    } else {
      return css`
        margin-right: 8px;
      `;
    }
  }}
`;

export const Sepline = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--grey200);
`;
