import styled from "@emotion/styled/macro";

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
`;

export const ButtonGroup = styled.div<{gap?:string}>`
  display: flex;
  align-items: center;
  gap: ${props => props.gap ? props.gap : "0px"};

  svg {
    pointer-events: none;
  }
`;