import styled from "@emotion/styled";

export const Container = styled.div``;

export const TitleHolder = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
`;

export const ImageContainer = styled.div`
  flex: 0 0 96px;
  max-width: 96px;
  width: 100%;
  padding: 16px 0;
`;

export const StatusWrapper = styled.div``;

export const MainStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Dot = styled.div<{ color?: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

export const MainStatusText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey900);
`;

export const SubStatusText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey600);
`;
