import { PostListLayout } from "../../../templates/postListLayout/PostListLayout";

export function NewsAndActivitiesList() {
  return (
    <PostListLayout
      pageHeader="News & Activities"
      pathname="/news-activities"
      categoryKey="news-activities"
    />
  );
}
