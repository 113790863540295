import { ReactChild } from 'react';
import { Button } from '../../../atoms/button/Button';
import { Modal } from '../../atoms/modal/Modal';
import { Text, ButtonContainer, Container } from './ModalDelete.style';

interface DeleteModalProps {
  /**
   * Title of modal.
   */
  title: string;
  /**
   * Alert message.
   */
  description?: string;
  /**
   * Anything that add between description and button.
   */
  children?: ReactChild;
  /**
   * Display Modal if true
   * @default false
   */
  isOpen: boolean;
  /**
   * Is button disabled?
   */
  disabled?: boolean;
  /**
   * function that triggers on click modal background, cancel or close icon.
   */
  onClose?: () => void;
  /**
   * function that triggers on confirm delete.
   */
  onConfirm: () => void;
}

export const ModalDelete = (props: DeleteModalProps) => {
  const {
    title,
    description,
    children = <></>,
    isOpen,
    disabled,
    onClose,
    onConfirm,
  } = props;

  return (
    <Modal title={title} isOpen={isOpen} onClose={onClose}>
      <Container>
        {description ? <Text>{description}</Text> : ''}
        {children}
        <ButtonContainer>
          <Button
            type="submit"
            onClick={onConfirm}
            buttonStyle="danger"
            disabled={disabled}>
            ยืนยัน
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};
