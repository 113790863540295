import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../atoms/button/Button";
import {
  HeadingActionContainer,
  HeadingContainer,
  HeadingSubtitle,
  HeadingText,
  HeadingTitle,
  HeaderBackButton,
  HeadingWrapper,
} from "./Heading.style";

interface HeadingProps {
  title?: string;
  subtitle?: string;
  backLink?: string;
  onClickBack?: () => void;
  actionComponent?: React.ReactElement | React.ReactElement[];
}

export function Heading(props: HeadingProps) {
  const {
    title = "",
    subtitle = "",
    backLink,
    onClickBack,
    actionComponent: ActionComponent,
  } = props;
  return (
    <HeadingContainer>
      <HeadingWrapper>
        {backLink && onClickBack && (
          <HeaderBackButton onClick={() => onClickBack()} to={backLink}>
            <Button buttonStyle="outline-light">
              <FontAwesomeIcon icon={["far", "chevron-left"]} />
            </Button>
          </HeaderBackButton>
        )}
        <HeadingText>
          <HeadingTitle>{title}</HeadingTitle>
          <HeadingSubtitle>{subtitle}</HeadingSubtitle>
        </HeadingText>
      </HeadingWrapper>

      {Boolean(ActionComponent) && (
        <HeadingActionContainer>{ActionComponent}</HeadingActionContainer>
      )}
    </HeadingContainer>
  );
}
