import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../../../atoms/button/Button";
import { ImageInput } from "../../../atoms/imageInput/ImageInput";
import { Loader } from "../../../atoms/loading/loader/Loader";
import { Select } from "../../../atoms/select/Select";
import { TextInput } from "../../../atoms/textInput/TextInput";
import {
  ContentContainer,
  ContentHolder,
  ContentWrapper,
  FlexContainer,
  ScrollWrapper,
} from "../../../globalStyle/styles/ManagePage.style";
import { ContentDivider } from "../../../molecules/contentDivider/ContentDivider";
import { PageHeader } from "../../../organisms/pageHeader/PageHeader";
import {
  SidePanelTextGroup,
  SubText,
  SubTitle,
} from "../../../organisms/projectSidePanel/ProjectSidePanel.style";
import { Section } from "../../../organisms/section/Section";
import { SectionGallery } from "../../../organisms/section/sectionGallery/SectionGallery";
import { SectionMedia } from "../../../organisms/section/sectionMedia/SectionMedia";
import { SeoSection } from "../../../organisms/section/seoSection/SeoSection";
import { SidePanel } from "../../../organisms/sidePanel/SidePanel";
import {
  deletePostById,
  fetchPostOptions,
  postSelector,
  resetPostDetail,
  setEditPostData,
} from "../../../store/features/post";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { publishedData } from "../../../types";
import { mapImageInputPostPage } from "../../../types/mapMediaSection";
import {
  initialPostEditData,
  IPostEditable,
  IPostPromotionEditable,
} from "../../../types/post";
import { onCastIUploadDataToEmbeddedImageData } from "../../../utils/castType";
import {
  formatSelectItems,
  onHandleChangeArrayImage,
  onHandleChangeEditableData,
} from "../../../utils/dataService";
import { formatFullDateTime, formatIsoLanguage } from "../../../utils/format";
import { ButtonGroup } from "../../../templates/manageProject/ManageProject.style";
import { SidePanelSection } from "../../../templates/managePost/ManagePost.style";
import {
  HeaderText,
  HeaderWrapper,
  ImageContainer,
  PageBuilderPreviewContainer,
  VisibilityWrapper,
  ToolTipIconWrapper,
  CustomUrlModalWrapper,
  SupportingText,
  HeighlightText,
  InputDataWrapper,
  ChevronIconWrapper,
  ClearIconWrapper,
} from "./ManagePromotion.style";
import { Image } from "@mantine/core";
import { deletePage, pageSelector } from "../../../store/features/page";
import { CheckBoxItemA } from "../../../atoms/checkBoxItem/checkBoxItemA/CheckBoxItemA";
import { ConfirmModal } from "../../../organisms/confirmModal/ConfirmModal";
import { TextEditor } from "../../../atoms/textEditor/TextEditor";
import { LabelText } from "../../../globalStyle/GlobalStyle.style";
import PageBuilderPreviewImg from "../../../assets/images/page-builder-preview.png";
import { Modal } from "../../../organisms/modal/Modal";
import { Tooltip } from "../../../atoms/tooltip/Tooltip";
import { CopyToClipboard } from "../../../molecules/copyToClipboard/CopyToClipboard";
import { fetchBrands, resetBrand } from "../../../store/features/brand";
import { fetchProductTypes } from "../../../store/features/productType";
import {
  fetchProjectList,
  resetProject,
} from "../../../store/features/project";
import { getRuntimeConfig } from "../../../config";

const runtimeConfig = getRuntimeConfig();

interface ManagePostProps {
  pageTitle: string;
  isCreatePage?: boolean;
  submitButtonTitle?: string;
  customElements?: React.ReactElement;
  isShowPreviewPage?: boolean;
  isShowUpdatedAt?: boolean;
  isShowCreatedAt?: boolean;
  isShowUrl?: boolean;
  isShowPostType?: boolean;
  isShowSelectLanguage?: boolean;
  isShowVisibilityStatus?: boolean;
  isShowHighlightStatus?: boolean;
  onSubmit?: (data: IPostPromotionEditable) => void;
  onCreatePage?: (data: IPostPromotionEditable) => void;
}

export function ManagePromotion(props: ManagePostProps) {
  const {
    pageTitle,
    isCreatePage,
    onSubmit = () => {},
    submitButtonTitle,
    // customElements,
    isShowPreviewPage,
    isShowUpdatedAt,
    isShowCreatedAt,
    isShowUrl,
    isShowPostType,
    isShowSelectLanguage,
    isShowVisibilityStatus,
    isShowHighlightStatus,
    onCreatePage,
  } = props;
  const baseUrl = runtimeConfig.REACT_APP_PF_BASE_URL;
  const initialCustomizeUrl = {
    productType: null,
    brand: null,
    project: null,
    post: null,
  };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentPost, postEditData, postOptions, loadPostByIdState } =
    useAppSelector(postSelector);
  const { id: currentPageId } = useAppSelector(pageSelector);
  const { project, productType, brand } = useAppSelector((state) => state);
  const [searchParams, setSearchParams] = useSearchParams();
  // const [checkBoxValue, setCheckBoxValue] = useState<string[]>([]);
  const [isShowCreatePageModal, setIsShowCreatePageModal] =
    useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isShowCustomSlugModal, setIsShowCustomSlugModal] =
    useState<boolean>(false);
  const [customizeUrl, setCustomizeUrl] =
    useState<{
      productType: string | null;
      brand: string | null;
      project: string | null;
      post: string | null;
    }>(initialCustomizeUrl);
  const [basePromotionUrl, setBasePromotionUrl] = useState<string>("");

  function onChangePostData(value: any, key: string) {
    if (postEditData) {
      dispatch(
        setEditPostData(onHandleChangeEditableData(postEditData, key, value))
      );
    }
  }

  async function onDeletePost(id: string) {
    await dispatch(deletePostById(id));
    await dispatch(deletePage(`resource:${id}`));
    navigate("/promotion");
  }

  function onClearSelectedCustomizeUrl() {
    const initCustomizeUrl = {
      productType: null,
      brand: null,
      project: null,
    };
    if (!customizeUrl?.productType) {
      setCustomizeUrl({
        ...customizeUrl,
        ...initCustomizeUrl,
        productType: customizeUrl?.productType,
      });
    }
    if (!customizeUrl?.brand) {
      setCustomizeUrl({ ...customizeUrl, project: initCustomizeUrl?.project });
    }
  }

  function onCheckSlugCode(data: string) {
    if (baseUrl) {
      const getSlug = data.replace(`${basePromotionUrl}`, "");
      const splitData = getSlug.split("/");
      const currentProductTypeSlugCode = _.find(productType?.productTypes, {
        code: _.get(splitData, `[0]`, ""),
      });
      const currentBrandSlugCode = _.find(brand?.brands, {
        slugCode: _.get(splitData, `[1]`, ""),
      });
      const currentProjectSlugCode = _.find(project?.projects, {
        slugCode: _.get(splitData, `[2]`, ""),
      });
      if (!Boolean(currentProductTypeSlugCode)) {
        const postSlugCode = _.get(splitData, `[0]`, "") || "";
        setCustomizeUrl({ ...customizeUrl, post: postSlugCode });
      } else if (!Boolean(currentBrandSlugCode)) {
        const postSlugCode = _.get(splitData, `[1]`, "") || "";
        setCustomizeUrl({
          ...customizeUrl,
          post: postSlugCode,
          productType: currentProductTypeSlugCode?.id || null,
        });
      } else if (!Boolean(currentProjectSlugCode)) {
        const postSlugCode = _.get(splitData, `[2]`, "") || "";
        setCustomizeUrl({
          ...customizeUrl,
          post: postSlugCode,
          productType: currentProductTypeSlugCode?.id || null,
          brand: currentBrandSlugCode?.slugCode || null,
        });
      } else {
        const postSlugCode = _.get(splitData, `[3]`, "") || "";
        setCustomizeUrl({
          ...customizeUrl,
          post: postSlugCode,
          productType: currentProductTypeSlugCode?.id || null,
          brand: currentBrandSlugCode?.slugCode || null,
          project: currentProjectSlugCode?.slugCode || null,
        });
      }
    }
  }

  function onSubmitCustomizeUrl() {
    const slugCode = `${
      customizeUrl?.productType
        ? `${
            _.find(productType?.productTypes, { id: customizeUrl?.productType })
              ?.code || ""
          }`
        : ""
    }${customizeUrl?.brand ? `/${customizeUrl?.brand}` : ""}${
      customizeUrl?.project ? `/${customizeUrl?.project}` : ""
    }${customizeUrl?.productType ? "/" : ""}${
      customizeUrl?.post ? `${customizeUrl?.post}` : ""
    }`;
    const clonedData = _.cloneDeep(postEditData);
    if (clonedData) {
      _.set(clonedData, "slugCode", slugCode);

      // dispatch(setEditPostData(clonedData as IPostPromotionEditable));
      if (isCreatePage) {
        dispatch(setEditPostData(clonedData as IPostPromotionEditable));
      } else {
        onSubmit(clonedData as IPostPromotionEditable);
      }
      setCustomizeUrl(initialCustomizeUrl);
    }
  }

  useEffect(() => {
    if (isCreatePage) {
      searchParams.set("category-key", "promotion");
      searchParams.set("type", "page-builder");
      setSearchParams(searchParams);
      dispatch(
        setEditPostData({
          ...initialPostEditData,
          categoryKey: searchParams.get("category-key") || "",
          type: searchParams.get("type") || "",
        })
      );
    } else {
      if (currentPost) {
        dispatch(setEditPostData({ ...(currentPost as IPostEditable) }));
        onCheckSlugCode(currentPost?.slugCode);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPostByIdState]);

  useEffect(() => {
    if (postEditData?.languageCode) {
      setBasePromotionUrl(
        `${baseUrl}/${postEditData?.languageCode}/promotion/`
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postEditData?.languageCode]);

  useEffect(() => {
    dispatch(fetchPostOptions({}));

    dispatch(
      fetchProductTypes({
        languageCode: postEditData?.languageCode || "th",
      })
    );
    dispatch(
      fetchBrands({
        lang: postEditData?.languageCode || "th",
        limit: "all",
        // "product-type": customizeUrl?.productType,
      })
    );
    dispatch(
      fetchProjectList({
        lang: postEditData?.languageCode || "th",
        limit: "all",
        // brand: `code:${customizeUrl?.brand}`,
      })
    );

    return () => {
      dispatch(resetPostDetail());
      dispatch(resetProject());
      dispatch(resetBrand());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customizeUrl?.productType) {
      dispatch(
        fetchBrands({
          lang: postEditData?.languageCode || "th",
          limit: "all",
          "product-type": customizeUrl?.productType,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customizeUrl?.productType]);

  useEffect(() => {
    if (customizeUrl?.brand) {
      dispatch(
        fetchProjectList({
          lang: postEditData?.languageCode || "th",
          limit: "all",
          brand: `code:${customizeUrl?.brand}`,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customizeUrl?.brand]);

  useEffect(() => {
    if (postEditData) {
      onClearSelectedCustomizeUrl();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customizeUrl?.brand, customizeUrl?.productType, customizeUrl?.project]);

  useEffect(() => {
    if (postEditData?.slugCode) {
      onCheckSlugCode(postEditData?.slugCode);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postEditData?.slugCode]);

  // console.log(project?.projects);

  return postEditData ? (
    <>
      {/* Confirm modal */}
      <>
        {/* Delete project */}
        <ConfirmModal
          onClose={() => {
            setIsShowDeleteModal(false);
          }}
          title="Delete Post"
          opened={isShowDeleteModal}
          onClickPrimaryButton={() => onDeletePost(currentPost?.id)}
        >
          <div>
            Are you sure you want to delete
            <b>‘{currentPost?.title || ""}’</b>?
          </div>
          <div style={{ marginTop: "16px" }}>
            Once you confirm, this post will be permanently deleted.
          </div>
        </ConfirmModal>
        {/* Craete post page */}
        <ConfirmModal
          onClose={() => {
            setIsShowCreatePageModal(false);
          }}
          title="Create post page"
          primaryButtonStyle="primary"
          primaryButtonTitle="Create"
          opened={isShowCreatePageModal}
          onClickPrimaryButton={() => {
            setIsShowCreatePageModal(false);
            onCreatePage &&
              onCreatePage(postEditData as IPostPromotionEditable);
          }}
        >
          {isCreatePage ? (
            <div>
              Once you confirm, it will create a post and also create a page for
              this post.
            </div>
          ) : (
            <div>Once you confirm, it will create a page for this post.</div>
          )}
        </ConfirmModal>
        {/* Custom Slug */}
        <Modal
          opened={isShowCustomSlugModal}
          onClose={() => {
            setIsShowCustomSlugModal(false);
          }}
          title={"Customize the URL"}
          primaryButtonTitle={"Confirm"}
          onClickPrimaryButton={() => {
            onSubmitCustomizeUrl();
            setIsShowCustomSlugModal(false);
          }}
          secondaryButtonTitle={"Cancel"}
          onClickSecondaryButton={() => {
            setIsShowCustomSlugModal(false);
            setCustomizeUrl(initialCustomizeUrl);
          }}
          size={600}
        >
          <CustomUrlModalWrapper>
            <ContentWrapper paddingBottom="6px">
              <LabelText>Custom URL</LabelText>
            </ContentWrapper>
            <ContentWrapper paddingBottom="32px">
              <SupportingText>
                {basePromotionUrl}
                <HeighlightText>
                  {customizeUrl?.productType
                    ? `${
                        _.find(productType?.productTypes, {
                          id: customizeUrl?.productType,
                        })?.code || ""
                      }`
                    : ""}
                  {customizeUrl?.brand ? `/${customizeUrl?.brand}` : ""}
                  {customizeUrl?.project ? `/${customizeUrl?.project}` : ""}
                  {customizeUrl?.productType ? "/" : ""}
                  {customizeUrl?.post ? `${customizeUrl?.post}` : ""}
                </HeighlightText>
              </SupportingText>
            </ContentWrapper>
            <InputDataWrapper>
              <ContentDivider title="Options" />
              <Select
                searchable
                placeholder="Select a product type"
                label="Product type"
                data={
                  formatSelectItems(
                    productType?.productTypes,
                    "name",
                    postEditData?.languageCode || ""
                  ) || []
                }
                value={customizeUrl?.productType || ""}
                onChange={(e) =>
                  setCustomizeUrl({ ...customizeUrl, productType: e })
                }
                inputWidth={"450px"}
                rightSection={
                  customizeUrl?.productType ? (
                    <ClearIconWrapper
                      onClick={() => {
                        setCustomizeUrl({ ...customizeUrl, productType: null });
                        // onClearSelectedCustomizeUrl();
                      }}
                    >
                      <FontAwesomeIcon icon={["far", "close"]} />
                    </ClearIconWrapper>
                  ) : (
                    <ChevronIconWrapper>
                      <FontAwesomeIcon icon={["fas", "chevron-down"]} />
                    </ChevronIconWrapper>
                  )
                }
              />
              <Select
                disabled={!customizeUrl?.productType}
                searchable
                placeholder="Select a brand"
                label="Brand"
                data={brand?.brands?.map((item) => ({
                  value: item?.slugCode,
                  label: item?.name,
                  key: item?.id,
                }))}
                rightSection={
                  customizeUrl?.brand ? (
                    <ClearIconWrapper
                      onClick={() => {
                        setCustomizeUrl({ ...customizeUrl, brand: null });
                        // onClearSelectedCustomizeUrl();
                      }}
                    >
                      <FontAwesomeIcon icon={["far", "close"]} />
                    </ClearIconWrapper>
                  ) : (
                    <ChevronIconWrapper>
                      <FontAwesomeIcon icon={["fas", "chevron-down"]} />
                    </ChevronIconWrapper>
                  )
                }
                value={customizeUrl?.brand || ""}
                onChange={(e) => setCustomizeUrl({ ...customizeUrl, brand: e })}
                inputWidth={"450px"}
              />
              <Select
                disabled={
                  !Boolean(customizeUrl?.productType && customizeUrl?.brand)
                }
                searchable
                placeholder="Select a project"
                label="Project"
                data={project?.projects?.map((item) => ({
                  value: item?.slugCode,
                  label: item?.name,
                  key: item?.id,
                }))}
                value={customizeUrl?.project || ""}
                onChange={(e) =>
                  setCustomizeUrl({ ...customizeUrl, project: e })
                }
                inputWidth={"450px"}
                rightSection={
                  customizeUrl?.project ? (
                    <ClearIconWrapper
                      onClick={() => {
                        setCustomizeUrl({ ...customizeUrl, project: null });
                        // onClearSelectedCustomizeUrl();
                      }}
                    >
                      <FontAwesomeIcon icon={["far", "close"]} />
                    </ClearIconWrapper>
                  ) : (
                    <ChevronIconWrapper>
                      <FontAwesomeIcon icon={["fas", "chevron-down"]} />
                    </ChevronIconWrapper>
                  )
                }
              />
              <TextInput
                label="Slug (Code)"
                value={customizeUrl?.post || ""}
                onChange={(e) =>
                  setCustomizeUrl({ ...customizeUrl, post: e.target.value })
                }
                inputWidth={"450px"}
              />
            </InputDataWrapper>
          </CustomUrlModalWrapper>
        </Modal>
      </>
      <PageHeader
        backLink={`/promotion`}
        title={pageTitle}
        rightAction={
          <>
            {isCreatePage ? (
              <>
                <ButtonGroup>
                  <Button
                    buttonStyle="primary"
                    onClick={() =>
                      onSubmit(postEditData as IPostPromotionEditable)
                    }
                  >
                    Create
                  </Button>
                </ButtonGroup>
              </>
            ) : (
              <>
                <ButtonGroup>
                  <Button
                    buttonStyle="outline-light"
                    onClick={() => setIsShowDeleteModal(true)}
                  >
                    <FontAwesomeIcon icon={["far", "trash-can"]} />
                  </Button>
                </ButtonGroup>
              </>
            )}
          </>
        }
      />
      <FlexContainer>
        <ContentContainer>
          <ScrollWrapper>
            <Section
              headerButtonStyle="primary"
              headerButtonTitle={submitButtonTitle}
              onClickHeaderButton={() =>
                onSubmit(postEditData as IPostPromotionEditable)
              }
              title="General Information"
            >
              <ContentHolder>
                <ContentWrapper>
                  <TextInput
                    label="Title"
                    value={postEditData?.title || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChangePostData(e.target.value, "title");
                    }}
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <TextInput
                    label="Subtitle"
                    value={postEditData?.subtitle || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChangePostData(e.target.value, "subtitle");
                    }}
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <TextEditor
                    controls={[
                      [
                        "bold",
                        "italic",
                        "underline",
                        "link",
                        "unorderedList",
                        "orderedList",
                      ],
                    ]}
                    label={"Description"}
                    value={postEditData?.description || ""}
                    onChange={(e) => {
                      onChangePostData(e, "description");
                    }}
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <TextEditor
                    controls={[
                      [
                        "bold",
                        "italic",
                        "underline",
                        "link",
                        "unorderedList",
                        "orderedList",
                      ],
                    ]}
                    label={"Terms and conditions"}
                    value={postEditData?.termsConditions || ""}
                    onChange={(e) => {
                      onChangePostData(e, "termsConditions");
                    }}
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <LabelText>Page builder</LabelText>
                  <PageBuilderPreviewContainer>
                    {currentPageId ? (
                      <Link to={`/page-builder/${currentPageId}`}>
                        <HeaderWrapper>
                          <HeaderText>Open page builder screen</HeaderText>
                          <FontAwesomeIcon
                            icon={["far", "arrow-right-to-bracket"]}
                          />
                        </HeaderWrapper>
                      </Link>
                    ) : (
                      <HeaderWrapper>
                        <HeaderText>Open page builder screen</HeaderText>
                        <FontAwesomeIcon
                          icon={["far", "arrow-right-to-bracket"]}
                        />
                      </HeaderWrapper>
                    )}
                    <ImageContainer>
                      <Image
                        src={PageBuilderPreviewImg}
                        alt="preview-img-page-builder"
                      />
                    </ImageContainer>
                  </PageBuilderPreviewContainer>
                </ContentWrapper>
                {/* <ContentWrapper>{customElements}</ContentWrapper> */}
                <ContentWrapper>
                  <ContentDivider title="Permalink" />
                </ContentWrapper>
                <ContentWrapper>
                  <CopyToClipboard
                    label="URL"
                    value={`${basePromotionUrl}${postEditData?.slugCode}`}
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <Button
                    buttonStyle="text-link-primary"
                    onClick={() => {
                      setIsShowCustomSlugModal(true);
                      onCheckSlugCode(postEditData?.slugCode);
                    }}
                  >
                    Customize the URL
                  </Button>
                </ContentWrapper>
              </ContentHolder>
            </Section>
            <SectionMedia
              column={2}
              mediaSection={
                <>
                  {mapImageInputPostPage.map((item, index) => (
                    <ImageInput
                      {...item}
                      key={`${item.label}-${index}`}
                      previousImageData={
                        _.find(_.get(postEditData, `${item.key}`), {
                          deviceType: item.device,
                        }) || null
                      }
                      data={{
                        imgUrl: _.get(
                          _.find(_.get(postEditData, `${item.key}`, null), {
                            deviceType: item.device,
                          }),
                          "originalUrl",
                          ""
                        ),
                        imgAlt: _.get(
                          _.find(_.get(postEditData, `${item.key}`, null), {
                            deviceType: item.device,
                          }),
                          "name",
                          ""
                        ),
                      }}
                      onClickChooseImage={(img) => {
                        if (isCreatePage) {
                          dispatch(
                            setEditPostData(
                              onHandleChangeArrayImage(
                                postEditData,
                                img,
                                item.device,
                                item.key
                              )
                            )
                          );
                        } else {
                          onSubmit(
                            onHandleChangeArrayImage(
                              postEditData,
                              img,
                              item.device,
                              item.key
                            )
                          );
                        }
                      }}
                    />
                  ))}
                </>
              }
            />
            <SectionGallery
              galleries={postEditData?.galleries || []}
              onChangeImagesData={(images) => {
                onChangePostData(images, "galleries");
              }}
            />
            <SeoSection
              data={{ ...postEditData, ogImage: postEditData?.openGraphImage }}
              onChangeOgDescription={(e) => {
                onChangePostData(e, "ogDescription");
              }}
              onChangeOgTitle={(e) => {
                onChangePostData(e, "ogTitle");
              }}
              onChangeSeoTitle={(e) => {
                onChangePostData(e, "seoTitle");
              }}
              onChangeSeoDescription={(e) => {
                onChangePostData(e, "seoDescription");
              }}
              onChangeOgImage={(e) => {
                onChangePostData(
                  onCastIUploadDataToEmbeddedImageData(e, null),
                  "openGraphImage"
                );
              }}
              onClickSave={
                Boolean(isCreatePage)
                  ? undefined
                  : () => onSubmit(postEditData as IPostPromotionEditable)
              }
            />
          </ScrollWrapper>
        </ContentContainer>
        <SidePanel
          title="Summary"
          sidePanelAction={
            <SidePanelSection>
              {isShowPreviewPage && (
                <>
                  {currentPageId ? (
                    <>
                      <Select
                        data={publishedData}
                        value={postEditData?.publishStatus}
                        onChange={(e) => {
                          if (isCreatePage) {
                            onChangePostData(e, "publishStatus");
                          } else {
                            onSubmit(
                              onHandleChangeEditableData(
                                postEditData,
                                "publishStatus",
                                e
                              )
                            );
                          }
                        }}
                        label={"Publish status"}
                      />
                      {currentPost?.publishedAt && (
                        <SidePanelTextGroup>
                          <SubTitle>Published at</SubTitle>
                          <SubText>
                            {currentPost?.publishedAt
                              ? formatFullDateTime(
                                  new Date(currentPost?.publishedAt)
                                )
                              : ""}
                          </SubText>
                        </SidePanelTextGroup>
                      )}
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <Button
                          buttonStyle="outline-light"
                          onClick={() => {
                            navigate(`/page-builder/page/${currentPageId}`);
                          }}
                          rightIcon={
                            <FontAwesomeIcon
                              icon={["far", "arrow-right-arrow-left"]}
                            />
                          }
                        >
                          Switch to page builder
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <Button
                        leftIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
                        onClick={() => setIsShowCreatePageModal(true)}
                        buttonStyle="outline-light"
                      >
                        Create Page
                      </Button>
                    </>
                  )}
                </>
              )}
              {(isShowHighlightStatus || isShowVisibilityStatus) && (
                <ContentDivider />
              )}
              {isShowVisibilityStatus && (
                <>
                  <VisibilityWrapper>
                    <CheckBoxItemA
                      checked={Boolean(
                        postEditData?.visibilityStatus === "unlisted"
                      )}
                      primaryText="Unlist page"
                      onChecked={(e) => {
                        if (e === false) {
                          if (isCreatePage) {
                            onChangePostData("public", "visibilityStatus");
                          } else {
                            onSubmit(
                              onHandleChangeEditableData(
                                postEditData,
                                "visibilityStatus",
                                "public"
                              )
                            );
                          }
                        }
                        if (e === true) {
                          if (isCreatePage) {
                            onChangePostData("unlisted", "visibilityStatus");
                          } else {
                            onSubmit(
                              onHandleChangeEditableData(
                                postEditData,
                                "visibilityStatus",
                                "unlisted"
                              )
                            );
                          }
                        }
                      }}
                    />
                    <Tooltip label="Only people with the link can view">
                      <ToolTipIconWrapper>
                        <FontAwesomeIcon icon={["far", "question-circle"]} />
                      </ToolTipIconWrapper>
                    </Tooltip>
                  </VisibilityWrapper>
                </>
              )}
              {isShowHighlightStatus ? (
                <CheckBoxItemA
                  checked={Boolean(
                    postEditData?.termIds?.optionIds.find(
                      (item: string) =>
                        item === _.find(postOptions, { label: "highlight" })?.id
                    )
                  )}
                  primaryText="Highlight promotion"
                  onChecked={(e) => {
                    if (!e) {
                      if (isCreatePage) {
                        onChangePostData([], "termIds.optionIds");
                      } else {
                        onSubmit(
                          onHandleChangeEditableData(
                            postEditData,
                            "termIds.optionIds",
                            []
                          )
                        );
                      }
                    }
                    if (e) {
                      if (isCreatePage) {
                        onChangePostData(
                          [_.find(postOptions, { label: "highlight" })?.id],
                          "termIds.optionIds"
                        );
                      } else {
                        onSubmit(
                          onHandleChangeEditableData(
                            postEditData,
                            "termIds.optionIds",
                            [_.find(postOptions, { label: "highlight" })?.id]
                          )
                        );
                      }
                    }
                  }}
                />
              ) : (
                <></>
              )}
              {isShowSelectLanguage && isCreatePage && (
                <>
                  <ContentDivider />
                  <Select
                    disabled={!Boolean(isCreatePage)}
                    label={"Language"}
                    data={[
                      { label: "Thai", value: "th" },
                      { label: "English", value: "en" },
                    ]}
                    value={postEditData?.languageCode}
                    onChange={(e) => {
                      onChangePostData(e, "languageCode");
                    }}
                  />
                </>
              )}
              {(isShowPostType || isShowUrl) && <ContentDivider />}
              <>
                {isShowSelectLanguage && !isCreatePage && (
                  <>
                    <SidePanelTextGroup>
                      <SubTitle>Language</SubTitle>
                      <SubText>
                        {formatIsoLanguage(currentPost?.languageCode) || ""}
                      </SubText>
                    </SidePanelTextGroup>
                  </>
                )}
                {isShowPostType && (
                  <SidePanelTextGroup>
                    <SubTitle>Post type</SubTitle>
                    <SubText>{"Page builder"}</SubText>
                  </SidePanelTextGroup>
                )}
                {isShowUrl && (
                  <SidePanelTextGroup>
                    <SubTitle>URL</SubTitle>
                    <SubText>
                      {currentPost?.slugCode || postEditData?.slugCode || ""}
                    </SubText>
                  </SidePanelTextGroup>
                )}
              </>
              {isShowUpdatedAt && (
                <>
                  <ContentDivider />
                  {isShowUpdatedAt && (
                    <SidePanelTextGroup>
                      <SubTitle>Updated at</SubTitle>
                      <SubText>
                        {currentPost?.updatedAt
                          ? formatFullDateTime(new Date(currentPost?.updatedAt))
                          : ""}
                      </SubText>
                    </SidePanelTextGroup>
                  )}
                  {isShowCreatedAt && (
                    <SidePanelTextGroup>
                      <SubTitle>Created at</SubTitle>
                      <SubText>
                        {currentPost?.createdAt
                          ? formatFullDateTime(new Date(currentPost?.createdAt))
                          : ""}
                      </SubText>
                    </SidePanelTextGroup>
                  )}
                </>
              )}
            </SidePanelSection>
          }
        />
      </FlexContainer>
    </>
  ) : (
    <div style={{ margin: "auto" }}>
      <Loader color={"grey"} />
    </div>
  );
}
