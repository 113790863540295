import styled from "@emotion/styled";

export const PageBuilderPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 422px;
  max-width: 666px;
  height: 100%;
  widht: 100%;
  border-radius: 12px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: var(--grey600);

  padding: 12px 16px;
  background-color: var(--grey50);
  border: 1px dashed var(--grey200);
  border-radius: 12px 12px 0px 0px;

  cursor: pointer;
`;

export const HeaderText = styled.div``;

export const ImageContainer = styled.div`
  position: relative;
  background-color: var(--white);
  border: 1px dashed var(--grey200);
  border-radius: 0px 0px 12px 12px;
`;

export const VisibilityWrapper = styled.div`
  display: grid;
  grid-template-columns: 112px 16px;
  gap: 8px;
  align-items: center;
`;

export const ToolTipIconWrapper = styled.div`
  color: var(--grey500);

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CustomUrlModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SupportingText = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: var(--grey600);
`;

export const HeighlightText = styled.span`
  font-weight: 600;
  font-size: 16px;
  color: var(--grey600);
`;

export const InputDataWrapper = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.gap ? props.gap : "16px")};
`;

export const ClearIconWrapper = styled.div`
  font-size: 14px;
  color: var(--grey500);
  cursor: pointer;
`;
export const ChevronIconWrapper = styled.div`
  color: var(--grey500);
`;
