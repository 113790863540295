import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Button } from "../../../atoms/button/Button";
import { HeaderCell } from "../../../atoms/headerCell/HeaderCell";
import { Select } from "../../../atoms/select/Select";
// import { Select } from "@mantine/core";
import { TextCell } from "../../../atoms/textCell/TextCell";
import { TextInput } from "../../../atoms/textInput/TextInput";
import { Column } from "../../../molecules/column/Column";
import { Row } from "../../../molecules/row/Row";
import {
  fetchFunctionTypeList,
  unitTypeSelector,
} from "../../../store/features/unitType";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IFunction, initFunction } from "../../../types/unitType";
import { onHandleChangeEditableData } from "../../../utils/dataService";
import { onClickRow } from "../../../utils/dataTable";
import { ConfirmModal } from "../../confirmModal/ConfirmModal";
import { DataTable } from "../../dataTable/DataTable";
import { Modal } from "../../modal/Modal";
import { Section } from "../Section";
import {
  ActionWrapper,
  AmountWrapper,
  ButtonGroup,
  ModalWrapper,
} from "./SectionFunction.style";

interface SectionUnitVariantProps {
  functionsData: IFunction[];
  onChangeDataAndSave?: (value: IFunction[]) => void;
}

export function SectionFunction(props: SectionUnitVariantProps) {
  const { functionsData, onChangeDataAndSave = () => {} } = props;
  const dispatch = useAppDispatch();
  const { functionTypeList } = useAppSelector(unitTypeSelector);

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [selectedFunction, setSelectedFunction] =
    useState<IFunction | null>(null);
  const [selectedFunctionIndex, setSelectedFunctionIndex] =
    useState<number | null>(null);
  const [tempFunctionsData, setTempFunctionsData] = useState<IFunction[]>([]);

  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isReorder, setIsReorder] = useState<boolean>(false);

  function onHandleEditFunction(data: IFunction, index: number) {
    setSelectedFunction(data);
    setSelectedFunctionIndex(index);
    setIsModalOpened(true);
  }

  function onHandleDeleteFunction(index: number) {
    const clonedData = _.cloneDeep(functionsData);
    _.pullAt(clonedData, [index]);

    onChangeDataAndSave(clonedData);
    setSelectedFunctionIndex(null);
    setIsShowDeleteModal(false);
  }

  function onHandleCreateFunction(data: IFunction) {
    onChangeDataAndSave([
      ...functionsData,
      {
        ...data,
        name:
          data.name ||
          _.get(
            _.find(functionTypeList, {
              id: data.functionTypeId,
            }),
            "name",
            ""
          ),
      },
    ]);
    setSelectedFunction(initFunction);
    setIsModalOpened(false);
  }

  function onHandleUpdateFunction(data: IFunction, index: number | null) {
    const cloned = _.cloneDeep(functionsData);
    if (index !== null) {
      cloned[index] = {
        ...data,
        name:
          data.name ||
          _.get(
            _.find(functionTypeList, {
              id: data.functionTypeId,
            }),
            "name",
            ""
          ),
      };
      onChangeDataAndSave(cloned);
      setSelectedFunction(null);
      setSelectedFunctionIndex(null);
      setIsModalOpened(false);
    }
  }

  function onHandleChangeFunction(path: string, value: any) {
    setSelectedFunction(
      onHandleChangeEditableData(selectedFunction, path, value)
    );
  }

  function onMoveUpFunction(index: number) {
    const clonedData = _.cloneDeep(tempFunctionsData);
    clonedData.splice(index - 1, 0, clonedData[index]);
    clonedData.splice(index + 1, 1);
    // onHandleChangeProjectData(clonedData);
    // onChangeData(clonedData);
    setTempFunctionsData(clonedData);
  }

  function onMoveDownFunction(index: number) {
    const clonedData = _.cloneDeep(tempFunctionsData);
    const targetData = clonedData[index];
    clonedData.splice(index, 1);
    clonedData.splice(index + 1, 0, targetData);
    // onHandleChangeProjectData(clonedData);
    // onChangeData(clonedData);
    setTempFunctionsData(clonedData);
  }

  useEffect(() => {
    dispatch(fetchFunctionTypeList({}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTempFunctionsData(functionsData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [functionsData]);

  return (
    <>
      {selectedFunctionIndex !== null ? (
        <ConfirmModal
          onClose={() => {
            setIsShowDeleteModal(false);
            setSelectedFunctionIndex(null);
          }}
          title="Delete Function"
          opened={isShowDeleteModal}
          onClickPrimaryButton={() =>
            onHandleDeleteFunction(selectedFunctionIndex)
          }
        >
          <div>
            Are you sure you want to delete{" "}
            <b>
              ‘
              {functionsData[selectedFunctionIndex].name ||
                _.get(
                  _.find(functionTypeList, {
                    id: functionsData[selectedFunctionIndex].functionTypeId,
                  }),
                  "name",
                  ""
                )}
              ’
            </b>{" "}
            function?
          </div>
          <div style={{ marginTop: "16px" }}>
            Once you confirm, this function will be permanently deleted from
            this unit type.
          </div>
        </ConfirmModal>
      ) : (
        <></>
      )}
      <Section
        title={`Functions (${tempFunctionsData.length}/8)`}
        highlightTitleText={`(${tempFunctionsData.length}/8)`}
        headerActionComponent={
          <ButtonGroup gap={"12px"}>
            {isReorder ? (
              <>
                <Button
                  buttonStyle="outline-light"
                  onClick={() => {
                    setIsReorder(false);
                    setTempFunctionsData(functionsData);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  buttonStyle="primary"
                  onClick={() => {
                    setIsReorder(false);
                    onChangeDataAndSave(tempFunctionsData);
                  }}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button
                  buttonStyle="outline-light"
                  onClick={() => {
                    setIsReorder(true);
                  }}
                  leftIcon={
                    <FontAwesomeIcon icon={["far", "arrow-up-arrow-down"]} />
                  }
                  disabled={functionsData.length < 2}
                >
                  Reorder
                </Button>
                <Modal
                  size={400}
                  title={
                    Boolean(selectedFunctionIndex !== null)
                      ? "Edit Function"
                      : "Add Function"
                  }
                  opened={isModalOpened}
                  onClose={() => {
                    setIsModalOpened(false);
                    setSelectedFunction(null);
                    setSelectedFunctionIndex(null);
                  }}
                  primaryButtonTitle={
                    Boolean(selectedFunctionIndex !== null) ? "Save" : "Add"
                  }
                  isDisablePrimaryButton={!Boolean(selectedFunction?.functionTypeId)}
                  onClickPrimaryButton={() => {
                    selectedFunction &&
                      (Boolean(selectedFunctionIndex !== null)
                        ? onHandleUpdateFunction(
                            selectedFunction,
                            selectedFunctionIndex
                          )
                        : onHandleCreateFunction(selectedFunction));
                  }}
                  secondaryButtonTitle={"Cancel"}
                  onClickSecondaryButton={() => {
                    setIsModalOpened(false);
                    setSelectedFunction(null);
                    setSelectedFunctionIndex(null);
                  }}
                  button={
                    <Button
                      buttonStyle="outline-light"
                      leftIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
                      onClick={() => {
                        setIsModalOpened(true);
                        setSelectedFunction({
                          ...initFunction,
                          // functionTypeId: _.get(functionTypeList, "[0].id", ""),
                        });
                      }}
                      disabled={!Boolean(tempFunctionsData.length < 8)}
                    >
                      Add
                    </Button>
                  }
                >
                  {selectedFunction && (
                    <ModalWrapper>
                      <Select
                        searchable
                        maxDropdownHeight={150}
                        label={"Function type"}
                        data={functionTypeList.map((item) => {
                          return {
                            value: item.id,
                            label: item.name,
                            // icon: ["far", "shield-check"],
                          };
                        })}
                        value={selectedFunction.functionTypeId || ""}
                        onChange={(e) =>
                          onHandleChangeFunction("functionTypeId", e)
                        }
                        dropdownPosition="bottom"
                      />
                      <TextInput
                        label={"Name"}
                        value={selectedFunction?.name || ""}
                        onChange={(e) =>
                          onHandleChangeFunction("name", e.target.value)
                        }
                      />
                      <AmountWrapper>
                        <TextInput
                          type="number"
                          label={"Amount"}
                          value={selectedFunction?.amount || ""}
                          onChange={(e) =>
                            onHandleChangeFunction("amount", e.target.value)
                          }
                          autoComplete={"off"}
                        />
                      </AmountWrapper>
                    </ModalWrapper>
                  )}
                </Modal>
              </>
            )}
          </ButtonGroup>
        }
      >
        <>
          {Boolean(tempFunctionsData.length) && (
            <DataTable
              header={
                <Row>
                  <Column>
                    <HeaderCell title="Function Name" />
                  </Column>
                  <Column maxWidth="92px">
                    <HeaderCell title="Amount" />
                  </Column>
                  <Column maxWidth="133px" children={<></>} />
                </Row>
              }
            >
              <>
                {tempFunctionsData.length &&
                  tempFunctionsData &&
                  tempFunctionsData.map((item, index) => (
                    <Row
                      key={`${item.name}-${index + 500}-function`}
                      onClick={(e) => {
                        onClickRow(e, () => {
                          onHandleEditFunction(item, index);
                        });
                      }}
                    >
                      <Column>
                        <TextCell
                          icon={
                            <FontAwesomeIcon icon={["far", "shield-check"]} />
                          }
                          textLimitLine={1}
                          primaryTextLimitLine={1}
                          primaryText={item?.name}
                          text={_.get(
                            _.find(functionTypeList, {
                              id: item.functionTypeId,
                            }),
                            "name",
                            ""
                          )}
                        />
                      </Column>
                      <Column maxWidth="92px">
                        <TextCell text={item?.amount?.toString() || "-"} />
                      </Column>
                      <Column maxWidth="133px" horizontalAlign="right">
                        {isReorder ? (
                          <ButtonGroup>
                            <Button
                              buttonStyle="secondary-grey"
                              borderradius={"8px 0px 0px 8px"}
                              onClick={() => onMoveUpFunction(index)}
                              width="28px"
                              height="28px"
                              padding="0"
                              disabled={!Boolean(index - 1 >= 0)}
                            >
                              <FontAwesomeIcon icon={["far", "arrow-up"]} />
                            </Button>
                            <Button
                              buttonStyle="secondary-grey"
                              borderradius={"0px 8px 8px 0px"}
                              onClick={() => onMoveDownFunction(index)}
                              width="28px"
                              height="28px"
                              padding="0"
                              disabled={
                                !Boolean(index + 1 < functionsData.length)
                              }
                            >
                              <FontAwesomeIcon icon={["far", "arrow-down"]} />
                            </Button>
                          </ButtonGroup>
                        ) : (
                          <ActionWrapper>
                            <Button
                              buttonStyle="text-link-grey"
                              onClick={() => {
                                setSelectedFunctionIndex(index);
                                setIsShowDeleteModal(true);
                              }}
                              fontSize="sm"
                            >
                              Delete
                            </Button>
                            <Button
                              buttonStyle="text-link-primary"
                              onClick={() => onHandleEditFunction(item, index)}
                              fontSize="sm"
                            >
                              Edit
                            </Button>
                          </ActionWrapper>
                        )}
                      </Column>
                    </Row>
                  ))}
              </>
            </DataTable>
          )}
        </>
      </Section>
    </>
  );
}
