import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../atoms/button/Button";
import {
  PageHeaderActionContainer,
  PageHeaderBackButton,
  PageHeaderContainer,
  PageHeaderTitle,
} from "./PageHeader.style";

interface PageHeaderProps {
  title: string;
  backLink?: string;
  onClickBack?: () => void;
  leftAction?: React.ReactElement;
  rightAction?: React.ReactElement;
}

export function PageHeader(props: PageHeaderProps) {
  const {
    title = "",
    onClickBack,
    backLink,
    leftAction,
    rightAction,
  } = props;

  const isShowBackButton = Boolean((onClickBack || backLink) && !leftAction);

  return (
    <PageHeaderContainer>
      {
        Boolean(leftAction) && (
          <PageHeaderActionContainer marginDirection="right">
            {leftAction}
          </PageHeaderActionContainer>
        )
      }
      {(isShowBackButton) && (
        <PageHeaderBackButton onClick={onClickBack} to={backLink || ""}>
          <Button buttonStyle="outline-light">
            <FontAwesomeIcon icon={["far", "arrow-left"]} />
          </Button>
        </PageHeaderBackButton>
      )}
      <PageHeaderTitle>{title}</PageHeaderTitle>
      {rightAction && (
        <PageHeaderActionContainer>
          {rightAction}
        </PageHeaderActionContainer>
      )}
    </PageHeaderContainer>
  );
}
