import styled from "@emotion/styled/macro";

export const Container = styled.div`
  display: flex;
  position: relative;
  border-bottom: 1px solid var(--grey200);
  width: 100%;
  margin-bottom: 50px;
  justify-content: flex-start;
  gap: 24px;

  margin-bottom: 50px;
`;

export const TabItem = styled.div<{ isSelected: boolean }>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) =>
    props.isSelected ? "var(--primary700)" : "var(--grey500)"};
`;

export const LeftIcon = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
  font-size: 12px;
`;

export const RightIcon = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
  font-size: 12px;
`;

export const TabItemWrapper = styled.div<{ isSelected: boolean }>`
  border-bottom: 1px solid
    ${(props) => (props.isSelected ? "var(--primary700)" : "transparent")};
  padding-bottom: 8px;

  cursor: pointer;

  &:hover ${TabItem} {
    color: var(--primary700);
  }
`;
