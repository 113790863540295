import { Badge } from "../../badge/Badge";
import { Checkbox } from "../../checkbox/Checkbox";
import { CheckBoxItemProps } from "../CheckBoxItem";
import {
  Container,
  ContentWrapper,
  MainText,
  SubText,
} from "./CheckBoxItemA.style";

export function CheckBoxItemA(props: CheckBoxItemProps) {
  const {
    primaryText,
    badgeText,
    value,
    badgeColor = "grey",
    onClickSelect = () => {},
    highlightText = "",
    onChecked = () => {},
    ...rest
  } = props;

  let { text } = props;
  text = text?.replace(
    highlightText,
    `<span class="highlight-text">${highlightText}</span>`
  );
  return (
    <>
      <Checkbox
        {...rest}
        value={value}
        label={
          <>
            <Container>
              <ContentWrapper>
                {primaryText && <MainText>{primaryText}</MainText>}
                {badgeText && <Badge color={badgeColor}>{badgeText}</Badge>}
              </ContentWrapper>
              {text && <SubText dangerouslySetInnerHTML={{ __html: text }} />}
            </Container>
          </>
        }
        onChange={(e) => {
          onClickSelect(e.target.value);
          onChecked(e.currentTarget.checked);
        }}
      />
    </>
  );
}
