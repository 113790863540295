import {
  faBarsFilter,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../atoms/button/Button";
import { Pagination } from "../../atoms/pagination/Pagination";
import { SearchInput } from "../../atoms/searchInput/SearchInput";
import { TabMenu, TabMenuData } from "../../atoms/tabMenu/TabMenu";
import {
  TableBody,
  DataTableContainer,
  TableHeader,
  FooterContainer,
  PaginationContainer,
  HeaderContainer,
  SearchInputContainer,
  FilterButtonContainer,
  TableWrapper
} from "./DataTable.style";

interface DataTableProps {
  header: React.ReactElement;
  children: React.ReactElement | React.ReactElement[];
  disableBorderTop?: boolean;
  disableBorderRadius?: boolean;
  totalPages?: number;
  currentPage?: number;
  onChangePage?: (newPage: number) => void;
  tabList?: TabMenuData[];
  selectedTab?: string;
  onChangeTab?: (tab: string) => void;
  searchPlaceHolder?: string;
  onSearchData?: (value: string) => void;
  additionalInfoElement?: React.ReactElement;
  onClickFilterButton?: () => void;
}

export function DataTable(props: DataTableProps) {
  const [searchParams] = useSearchParams({});
  const [searchValue, setSearchValue] = useState(searchParams.get("q"));
  const {
    currentPage = 1,
    disableBorderTop = false,
    totalPages = 0,
    onChangePage = () => {},
    onChangeTab = () => {},
    selectedTab,
    tabList = [],
    searchPlaceHolder,
    onSearchData,
    onClickFilterButton,
    additionalInfoElement,
    disableBorderRadius=false,
  } = props;

  function onHandleSearch(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      if (onSearchData) {
        onSearchData(searchValue || "");
      }
    }
  }

  const isShowHeader = Boolean(
    (tabList && tabList.length) || onSearchData || onClickFilterButton
  );

  return (
    <DataTableContainer disableBorderRadius={disableBorderRadius} disableBorderTop={disableBorderTop}>
      {isShowHeader && (
        <HeaderContainer>
          {Boolean(tabList && tabList.length) && (
            <TabMenu
              data={tabList}
              onChangeTab={onChangeTab}
              selected={selectedTab || null}
            />
          )}
          {Boolean(onSearchData) && (
            <SearchInputContainer>
              <SearchInput
                placeholder={searchPlaceHolder}
                onKeyDown={(e) => onHandleSearch(e)}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setSearchValue(event.target.value)
                }
              />
            </SearchInputContainer>
          )}
          {Boolean(onClickFilterButton) && (
            <FilterButtonContainer>
              <Button
                buttonStyle="outline-light"
                type="button"
                leftIcon={<FontAwesomeIcon icon={faBarsFilter} />}
              >
                Filter
              </Button>
            </FilterButtonContainer>
          )}
        </HeaderContainer>
      )}
      {Boolean(additionalInfoElement) && additionalInfoElement}
      <TableWrapper>
        <TableHeader>{props.header}</TableHeader>
        <TableBody>{props.children}</TableBody>
      </TableWrapper>
      {totalPages >= 1 && (
        <FooterContainer>
          <PaginationContainer>
            <Pagination
              total={totalPages}
              // withControls={false}
              page={currentPage}
              onChange={onChangePage}
            />
          </PaginationContainer>
        </FooterContainer>
      )}
    </DataTableContainer>
  );
}
