import { ComponentSpecProps } from "../../../types";

export const HeroSection: ComponentSpecProps = {
  name: 'แบนเนอร์หลัก',
  componentType: 'HeroSection',
  settingFields: {
    isFullScreen: {
      displayName: 'แสดงแบบเต็มจอ',
      fieldName: 'isFullScreen',
      type: 'boolean',
      defaultValue: false,
    },
    backgroundImgUrl: {
      displayName: 'ภาพพื้นหลัง',
      fieldName: 'backgroundImgUrl',
      type: 'image',
      defaultValue: '',
    },
    headline: {
      displayName: 'headline',
      fieldName: 'headline',
      type: 'string',
      defaultValue: '',
    },
  },
  settings: {
    isFullScreen: false,
  },
  required: {
    backgroundImgUrl: true,
  },
}

export const HeroSectionWithTextBox: ComponentSpecProps = {
  name: 'แบนเนอร์หลักพร้อมกล่องข้อความ',
  componentType: 'HeroSectionWithTextBox',
  settingFields: {
    isFullScreen: {
      displayName: 'แสดงแบบเต็มจอ',
      fieldName: 'isFullScreen',
      type: 'boolean',
      defaultValue: false,
    },
    title: {
      displayName: 'หัวข้อหลักกล่องข้อความ',
      fieldName: 'title',
      type: 'string',
      defaultValue: 'หัวข้อหลักกล่องข้อความ',
    },
    subtitle: {
      displayName: 'หัวข้อรองกล่องข้อความ',
      fieldName: 'subtitle',
      type: 'string',
      defaultValue: '',
    },
    backgroundImgUrl: {
      displayName: 'ภาพพื้นหลัง',
      fieldName: 'backgroundImgUrl',
      type: 'image',
      defaultValue: '',
    },
    textBoxPosition: {
      displayName: 'ตำแหน่งของกล่องข้อความ',
      fieldName: 'textBoxPosition',
      type: 'select',
      defaultValue: 'left',
      options: [
        {
          value: 'left',
          label: 'ซ้าย',
        },
        {
          value: 'right',
          label: 'ขวา',
        },
      ],
    },
    textBoxBackgroundColor: {
      displayName: "สีพื้นหลังกล่องข้อความ",
      fieldName: 'textBoxBackgroundColor',
      type: 'color-selector',
      defaultValue: '#FFFFFF',
      options: [{
        value: "#FFFFFF",
        label: "White (#FFFFFF)",
        key: "white-color-FFFFFF"
      },
      {
        value: "#F8F8F8",
        label: "White smoke (#F8F8F8)",
        key: "lighr-grey-color-FFFFFF"
      }
    ]
    },
  },
  settings: {
    isFullScreen: false,
  },
  required: {
    backgroundImgUrl: true,
  },
}