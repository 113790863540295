import { createAction } from '@reduxjs/toolkit';
import { CurrentEditProps } from '../editor';
import createAsyncThunk from '../../middleware/customCreateThunk';
import {
  createSection as createSectionAPI,
  getDataSources,
  deleteSection as deleteSectionAPI,
  updateSection as updateSectionAPI, getParametersOptionsByContentType,
} from '../../../services/api/page-builder';
import {
  ChildComponentProps,
  CurrentEditTypes,
  ErrorTypes,
  JSONComponentDataSourceProps, PreviewLayoutType,
  PublishedSectionProps,
  SettingProps,
} from '../../../types';
import { SelectItem } from '@mantine/core';

export const setScrollPosition = createAction<number>('editor/setScrollPosition');
export const setCurrentEdit = createAction<CurrentEditProps>('editor/setCurrentEdit');
export const setCurrentEditType = createAction<CurrentEditTypes>('editor/setCurrentEditType');
export const setIsEnableSave = createAction<boolean>('editor/setIsEnableSave');
export const setCurrentEditChildComponent = createAction<ChildComponentProps>('editor/setCurrentEditChildComponent');
export const setSettings = createAction<SettingProps>('editor/setSettings');
export const updateSettings = createAction<SettingProps>('editor/updateSettings');
export const setDataSources = createAction<Array<JSONComponentDataSourceProps>>('editor/setDataSources');
export const updateCurrentSection = createAction<PublishedSectionProps>('editor/updateCurrentSection');
export const resetCurrentEdit = createAction('editor/resetCurrentEdit');
export const resetEditorState = createAction('editor/resetEditorState');
export const setSections = createAction<Array<PublishedSectionProps>>('editor/setSections');
export const setSectionError = createAction<ErrorTypes | null>('editor/setSectionError');
export const resetDeleteSectionState = createAction('editor/resetDeleteSectionState');
export const setPreviewLayout = createAction<PreviewLayoutType>('editor/setPreviewLayout');
export const setEditingDataSourceIndex = createAction<number>('editor/setEditingDataSourceIndex');

export const createSection = createAsyncThunk({
  api: createSectionAPI,
  EVENT_NAME: 'editor/createSection',
});

export const fetchDataSourceList = createAsyncThunk({
  api: () => getDataSources(),
  EVENT_NAME: 'editor/fetchDataSourceList',
});

export const updateSection = createAsyncThunk({
  api: updateSectionAPI,
  EVENT_NAME: 'editor/updateSectionAPI',
});

export const deleteSection = createAsyncThunk({
  api: deleteSectionAPI,
  EVENT_NAME: 'editor/deleteSection',
});

export const fetchContentCategories = createAsyncThunk({
  api: () => getParametersOptionsByContentType('program.clips', 'contentCategory'),
  EVENT_NAME: 'editor/fetchParametersOptions',
});

export const setBgColorOptions = createAction<Array<SelectItem>>('editor/setBgColorOptions')
