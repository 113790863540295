import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt';
import { faPen } from '@fortawesome/pro-solid-svg-icons/faPen';

import {
  Container,
  Content,
  DescriptionText,
  Header,
  HeaderButton, HeaderButtonContainer,
  HeaderText,
  Title,
  Wrapper,
} from './ContentCard.style';
import { getDataById, getParametersOptionsByContentTypeById } from '../../../services/api/page-builder';
import { DataSourceProps, SuggestionProps } from '../../../types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { SectionManageDataSource } from '../../organisms/sectionManageDataSource/SectionManageDataSource';
import { setEditingDataSourceIndex } from '../../../store/features/editor';

interface CardProps {
  data: DataSourceProps;
  title: string;
  children?: JSX.Element | string;
  onDelete: () => void;
  dataSourceIndex: number;
  isEnableDrag?: boolean;
}

export const ContentCard = (props: CardProps) => {
  const { data, title, children, onDelete, isEnableDrag, dataSourceIndex } = props;

  const { contentCategories, editingDataSourceIndex } = useAppSelector((state) => state.editor);
  const [dataSourceObject, setDataSourceObject] = useState<SuggestionProps | undefined>(undefined);
  const [formattedDescription, setFormattedDescription] = useState<string>('');

  const dispatch = useAppDispatch();
  const { query, sourceType, isFakeId } = data;

  const onToggleEdit = () => {
    dispatch(setEditingDataSourceIndex(editingDataSourceIndex === -1 ? dataSourceIndex : -1));
  };

  useEffect(() => {
    const formatDescription = async () => {
      if (query && query.tag) {
        setFormattedDescription(`แท็ก: ${typeof query.tag === 'string' ? query.tag : query.tag.join(', ')}`);
        return;
      }

      if (data?.sourceType?.includes('manual') && query) {
        if (query.title && query.url) {
          setFormattedDescription(`${query.title} (${query.url})`);
          return;
        }

        if (query.url) {
          setFormattedDescription(query.url);
          return;
        }
      }

      if (!isFakeId && data.id) {
        try {
          const response = await getDataById(sourceType, `${data.id}`);
          setDataSourceObject({
            value: response.data.data?.id,
            label: response.data.data?.title,
          });
          setFormattedDescription(response.data.data?.title || '');
        // eslint-disable-next-line
        } catch (error) {
        }
      }

      if (query) {
        let parameter = { name: '', value: '' };

        if (query.programId) {
          parameter = {
            name: 'programId',
            value: `${query.programId}`,
          };
        }

        if (parameter.name && parameter.value) {
          try {
            const response = await getParametersOptionsByContentTypeById(sourceType, parameter.name, parameter.value);
            setDataSourceObject({
              value: response.data?.value,
              label: response.data?.label,
            });
            const text = response.data?.label;
            setFormattedDescription(text ? `รายการ: ${text}` : '');
            // eslint-disable-next-line
          } catch (error) {
          }
        }
      }
    };

    formatDescription();
  }, []);

  return (
    <Container isEnableDrag={isEnableDrag}>
      <Header>
        <Wrapper>
          <HeaderText>
            <Title>{ title }</Title>
          </HeaderText>
          <HeaderButtonContainer>
            <HeaderButton hide={editingDataSourceIndex !== -1 && editingDataSourceIndex !== dataSourceIndex} onClick={onToggleEdit}>
              <FontAwesomeIcon icon={faPen} />
            </HeaderButton>
            <HeaderButton onClick={onDelete} isDeleteButton>
              <FontAwesomeIcon icon={faTrashAlt} />
            </HeaderButton>
          </HeaderButtonContainer>
        </Wrapper>
      </Header>
      <Content>
        { Boolean(formattedDescription) && (
          <DescriptionText>{ formattedDescription }</DescriptionText>
        ) }
        { children }
      </Content>
      { editingDataSourceIndex === dataSourceIndex && (
        <SectionManageDataSource
          isEmptyData={false}
          dataToEdit={data}
          defaultDataSourceObject={dataSourceObject}
          indexToEdit={dataSourceIndex}
          onCancelEdit={onToggleEdit} />
      ) }
    </Container>
  );
};
