import { useEffect, useRef, useState } from 'react';
import { TabsContainer, Input, Label, Option, Selected } from './Tabs.style';
import { OptionProps } from '../../../types';

interface TabsProps {
  options: Array<OptionProps>;
  onSelect: (option: OptionProps) => void;
  selectedTab: OptionProps;
}

export const Tabs = (props: TabsProps) => {
  const { options, onSelect, selectedTab } = props;
  const [selected, setSelected] = useState<OptionProps>(options[0]);
  const selectedRef = useRef(null);

  const moveSelected = (index: number) => {
    if (selectedRef && selectedRef.current) {
      const element = selectedRef.current as HTMLDivElement;
      element.style.transform = `translateX(${element.offsetWidth * index + 1}px)`;
    }
  };

  const onClickOption = (item: OptionProps, index: number) => {
    setSelected(item);
    moveSelected(index);
    onSelect(item);
  };

  useEffect(() => {
    const index = options.findIndex((item) => item === selectedTab);
    if (index !== undefined) {
      moveSelected(index);
    }
  }, [selectedTab]);

  return (
    <TabsContainer>
      <Selected ref={selectedRef} />
      { options.map((item, index) => (
        <Option
          onClick={() => onClickOption(item, index)}
          key={`option-${item.value}`}>
          <Input
            type="radio"
            name="radio-group"
            value={item.value} />
          <Label isActive={selected.value === item.value}>
            <span>{ item.title }</span>
          </Label>
        </Option>
      )) }
    </TabsContainer>
  );
};
