import { LeftContainer, RightContainer, SideFormGroupContainer, SideFormGroupSubtitle, SideFormGroupTitle } from "./SideFormGroup.style";

interface SideFormGroupProps {
  title: string;
  subtitle?: string;
  children: React.ReactElement | React.ReactElement[];
  childrenGap?: string;
}

export function SideFormGroup(props: SideFormGroupProps) {
  const { title = '', subtitle = '', children = <></>, childrenGap = '16px' } = props;
  return (
    <SideFormGroupContainer>
      <LeftContainer>
        <SideFormGroupTitle>
          {title}
        </SideFormGroupTitle>
        <SideFormGroupSubtitle>
          {subtitle}
        </SideFormGroupSubtitle>
      </LeftContainer>
      <RightContainer childrenGap={childrenGap}>
        {children}
      </RightContainer>
    </SideFormGroupContainer>
  )
}