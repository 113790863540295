import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../atoms/button/Button";
import { ImageInput } from "../../atoms/imageInput/ImageInput";
import { Select } from "../../atoms/select/Select";
import { TextArea } from "../../atoms/textArea/TextArea";
import { TextInput } from "../../atoms/textInput/TextInput";
import { ContentDivider } from "../../molecules/contentDivider/ContentDivider";
import { PageHeader } from "../../organisms/pageHeader/PageHeader";
import {
  ButtonWrapper,
  SidePanelTextGroup,
  SubText,
  SubTitle,
} from "../../organisms/projectSidePanel/ProjectSidePanel.style";
import { Section } from "../../organisms/section/Section";
import { SidePanel } from "../../organisms/sidePanel/SidePanel";
import {
  houseCollectionSelector,
  setCurrentHouseCollection,
} from "../../store/features/houseCollection";
import { useAppSelector } from "../../store/hooks";
import { IHouseCollectionType } from "../../types/houseCollection";
import { onHandleSingleDataContentVariations } from "../../utils/dataService";
import { formatFullDateTime, formatLanguageSelectItem } from "../../utils/format";
import { SidePanelContainer } from "../listPageWithLanguageSidebar/ListPageWithLanguageSidebar.style";
import {
  ScrollWrapper,
  ContentHolder,
  ContentWrapper,
  ContentContainer,
} from "../../pages/projectDetail/ProjectDetail.style";
import { IEmbeddedMedia, IMediaResponse, lang } from "../../types";
import { Loader } from "../../atoms/loading/loader/Loader";
import { onCastIUploadDataToEmbeddedImageData } from "../../utils/castType";
import { ConfirmModal } from "../../organisms/confirmModal/ConfirmModal";
interface ManageHouseCollectionProps {
  pageTitle: string;
  onClickSubmit?: () => void;
  onClickDelete?: () => void;
  isShowDeleteButton?: boolean;
  isShowAddLanguageButton?: boolean;
  submitButtonTitle?: string;
}

export function ManageHouseCollection(props: ManageHouseCollectionProps) {
  const {
    pageTitle = "",
    onClickSubmit = () => {},
    onClickDelete,
    isShowAddLanguageButton = true,
    submitButtonTitle = "Save",
  } = props;

  const languageCode = lang;

  const dispatch = useDispatch();
  const { currentHouseCollection: houseCollection } = useAppSelector(
    houseCollectionSelector
  );
  const [selectedLanguage, setSelectedLanguage] =
    useState<string>(languageCode);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  function onChangeHouseCollectionData(
    data: IHouseCollectionType,
    path: string,
    value: string | string[],
    contentVariationKey?: string,
    customLanguage: string = languageCode
  ) {
    const clonedHouseCollection = _.cloneDeep(data);

    if (clonedHouseCollection) {
      if (!contentVariationKey) {
        _.set(clonedHouseCollection, path, value);
      } else {
        const contentVariations = _.get(clonedHouseCollection, `${path}`, null);

        if (contentVariations) {
          const filtedIndex = _.findIndex(contentVariations, {
            languageCode: customLanguage ? customLanguage : selectedLanguage,
          });
          _.set(
            clonedHouseCollection,
            `${path}[${filtedIndex}].${contentVariationKey}`,
            value
          );
        } else {
          _.set(clonedHouseCollection, `${path}`, [
            {
              languageCode: customLanguage ? customLanguage : selectedLanguage,
              [`${contentVariationKey}`]: value,
            },
          ]);
        }
      }
      dispatch(setCurrentHouseCollection(clonedHouseCollection));
    }
  }

  function onChangeImage(imgObj: IMediaResponse | null) {
    let newImageData = null;
    const houseCollectionData = houseCollection || ({} as IHouseCollectionType);
    if (imgObj) {
      newImageData = onCastIUploadDataToEmbeddedImageData(
        imgObj,
        null
      ) as IEmbeddedMedia;
    }
    dispatch(
      setCurrentHouseCollection({ ...houseCollectionData, logo: newImageData })
    );
  }

  // console.log(formatLanguageSelectItem(['th']))

  return houseCollection ? (
    <>
      <ConfirmModal
        onClose={() => setIsShowDeleteModal(false)}
        title="Delete House Collection"
        opened={isShowDeleteModal}
        onClickPrimaryButton={onClickDelete}
      >
        <div>
          Are you sure you want to delete{" "}
          <b>
            ‘
            {
              _.find(houseCollection?.contentVariations, {
                languageCode: selectedLanguage,
              })?.name
            }
            ’
          </b>
          ?
        </div>
        <div style={{ marginTop: "16px" }}>
          Once you confirm, this collection will be permanently deleted and also
          removed from all contents that use it.
        </div>
      </ConfirmModal>
      <PageHeader
        title={pageTitle}
        backLink="/house-collection"
        rightAction={
          onClickDelete && (
            <Button
              buttonStyle="outline-light"
              onClick={() => setIsShowDeleteModal(true)}
            >
              <FontAwesomeIcon icon={["far", "trash-can"]} />
            </Button>
          )
        }
      />
      <div style={{ display: "flex", height: "100%" }}>
        <ContentContainer>
          <ScrollWrapper>
            <Section
              footerButtonStyle="primary"
              footerButtonTitle={submitButtonTitle}
              onClickFooterButton={onClickSubmit}
            >
              <ContentHolder>
                <ContentWrapper>
                  <TextInput
                    label="Name"
                    value={onHandleSingleDataContentVariations(
                      houseCollection?.contentVariations,
                      "name",
                      selectedLanguage
                    )}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onChangeHouseCollectionData(
                        houseCollection,
                        "contentVariations",
                        e.target.value,
                        "name"
                      )
                    }
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <TextArea
                    label="Description"
                    value={onHandleSingleDataContentVariations(
                      houseCollection?.contentVariations,
                      "description",
                      selectedLanguage
                    )}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      onChangeHouseCollectionData(
                        houseCollection,
                        "contentVariations",
                        e.target.value,
                        "description"
                      )
                    }
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <ImageInput
                    label={"Logo"}
                    previousImageData={houseCollection.logo}
                    data={{
                      imgUrl: houseCollection?.logo?.originalUrl || "",
                      imgAlt: "Random unsplash image",
                    }}
                    onClickChooseImage={(img) => onChangeImage(img)}
                    size={"320px"}
                    ratio={16 / 9}
                  />
                </ContentWrapper>
              </ContentHolder>
            </Section>
          </ScrollWrapper>
        </ContentContainer>
        <SidePanel
          sidePanelAction={
            <SidePanelContainer>
              <Select
                disabled={Boolean(
                  !(houseCollection?.availableLanguages?.length > 1)
                )}
                label={
                  Boolean(!(houseCollection?.availableLanguages?.length > 1))
                    ? "Language"
                    : "Switch Language"
                }
                data={houseCollection?.availableLanguages ? formatLanguageSelectItem(houseCollection?.availableLanguages) : [{label: "Thai", value: "th"}]}
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e || 'th')}
              />
              {isShowAddLanguageButton && (
                <ButtonWrapper>
                  <Button
                    buttonStyle="outline-light"
                    leftIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
                  >
                    Add Language
                  </Button>
                </ButtonWrapper>
              )}
              {Boolean(houseCollection.updatedAt) && (
                <>
                  <ContentDivider />
                  <ContentWrapper>
                    <SidePanelTextGroup>
                      <SubTitle>Updated at</SubTitle>
                      <SubText>
                        {formatFullDateTime(
                          new Date(houseCollection.updatedAt)
                        )}
                      </SubText>
                    </SidePanelTextGroup>
                  </ContentWrapper>
                </>
              )}
            </SidePanelContainer>
          }
        />
      </div>
    </>
  ) : (
    <div style={{ margin: "auto" }}>
      <Loader color="gray" />
    </div>
  );
}
