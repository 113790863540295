import styled from "@emotion/styled/macro";

export const ModalWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 450px;
`;
export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
`;

export const InfomationContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: var(--white);
  border-left: 1px solid var(--grey200);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  width: 100%;
  height: 100vh;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HeaderText = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: var(--grey900);
`;

export const InfoTextHolder = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MainText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--grey700);
`;

export const SubText = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: var(--grey600);
`;

export const DeleteMediaWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 400;
  color: var(--grey700);
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 16px;
`;

export const ImageWrapper = styled.div<{ isHorizontalImage: boolean }>`
  /* background-color: var(--grey25); */
  /* height: fit-content; */
  /* max-width: ${(props) => props.isHorizontalImage && "40%"}; */
  max-width: ${(props) => props.isHorizontalImage ? "80vh" : "50vh"};
  margin: auto;
  /* padding: ${(props) => props.isHorizontalImage ? "40px" : "64px"}; */

  /* max-width: 57%;
  margin: auto;
  padding: 40px; */
`;

export const NextPrevButtonGroup = styled.div`
  position: absolute;
  top: 16px;
  right: 24px;
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 8px;
`;

export const CircleButton = styled.div``;
