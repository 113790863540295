import styled from "@emotion/styled/macro";

export const Container = styled.div`
  display: flex;
  padding: 40px 40px 48px;
`;

export const ImageWrapper = styled.div`
  display: flex;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 24px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

export const MainText = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: var(--grey900);
`;

export const SubText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey600);
`;
