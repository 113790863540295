import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AspectRatio,
  Image,
  ImageProps,
  RingProgressProps,
} from "@mantine/core";
import { formatBytes } from "../../utils/format";
import {
  Container,
  ImageWrapper,
  ContentHolder,
  ContentWrapper,
  ProgressBarWrapper,
  ProgressBar,
  ProgressText,
  MainText,
  SubText,
  IconWrapper,
  LoadingProgress,
  AttachmentWrapper,
} from "./UploadItem.style";
import { UploadItemA } from "./uploadItemA/UploadItemA";

export type UploadState = "prepare" | "completed" | "error" | "uploaded";
type UploadLayout = "A" | "B";

export interface UploadItemProps {
  imgData: ImageProps;
  uploadState: UploadState;
  imgContainerWidth?: string;
  imgRatio?: number;
  layout?: UploadLayout;
  ringProgressProps?: RingProgressProps;
  isAttachment?: boolean;

  //  represented as a value between 0 and 1
  uploadProgress?: number;
  processingProgress?: number;
  //  the time it took to upload the file, in milliseconds
  uploadTime?: number;
  processingTime?: number;
  // size of the file that upladed
  totalFileSize?: number;
  uploadProgressFileSize?: number;

  onClickUploadAgain?: () => void;
  onClickCancel?: () => void;
}

export function UploadItem(props: UploadItemProps) {
  const {
    imgData,
    imgContainerWidth,
    uploadState,
    uploadProgress = 0,
    totalFileSize,
    isAttachment,
    uploadProgressFileSize,
    onClickUploadAgain = () => {},
    onClickCancel = () => {},
    layout = "A",
  } = props;
  const icon: IconProp[] = [
    ["fas", "circle-check"],
    ["far", "trash-can"],
  ];
  const isError = uploadState === "error";
  const loaded = formatBytes(uploadProgressFileSize || 0, 0);
  const total = formatBytes(totalFileSize || 0, 0);
  return (
    <>
      {layout === "B" && (
        <Container isError={isError} width={imgContainerWidth}>
          {isAttachment ? (
            <AttachmentWrapper uploadState={uploadState}>
              <FontAwesomeIcon icon={['far', 'file']}/>
            </AttachmentWrapper>
          ) : (
            <ImageWrapper>
              <AspectRatio ratio={1 / 1}>
                <Image {...imgData} />
              </AspectRatio>
            </ImageWrapper>
          )}
          <ContentHolder>
            <ContentWrapper>
              {!isError ? (
                <>
                  <MainText>{imgData.alt}</MainText>
                  {Boolean(uploadProgress === 100) ? (
                    <SubText>
                      {loaded[0]} {loaded[1]}
                    </SubText>
                  ) : (
                    <SubText>
                      {loaded[0]} {loaded[1]} / {total[0]} {total[1]}
                    </SubText>
                  )}
                </>
              ) : (
                <>
                  <MainText isError={isError}>
                    Upload failed, please try again
                  </MainText>
                  <SubText isError={isError}>{imgData.alt}</SubText>
                </>
              )}
              <IconWrapper
                uploadState={uploadState}
                onClick={() => onClickCancel()}
              >
                <FontAwesomeIcon
                  icon={icon[uploadState === "completed" ? 0 : 1]}
                />
              </IconWrapper>
            </ContentWrapper>
            {!isError ? (
              <ProgressBarWrapper>
                <ProgressBar>
                  <LoadingProgress width={uploadProgress} />
                </ProgressBar>
                <ProgressText>{uploadProgress}%</ProgressText>
              </ProgressBarWrapper>
            ) : (
              <ProgressText
                isError={isError}
                onClick={() => onClickUploadAgain()}
              >
                Try again
              </ProgressText>
            )}
          </ContentHolder>
        </Container>
      )}
      {layout === "A" && <UploadItemA {...props} />}
    </>
  );
}
