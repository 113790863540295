import qs from "qs";
import { axiosContentAPI } from "../axios";
import {
  BrandQueryParams,
  IBrandEditable,
  IProductTypeEditable,
  ICreateTerm,
  IProjectEditable,
  IUpdateBrandPayload,
  IUpdateProjectPayload,
  QueryParams,
  UpdateProductTypeParam,
  ProjectQueryUnitTypeParam,
} from "../types";
import {
  IHouseCollectionType,
  UpdateHouseCollectionTypeParam,
} from "../types/houseCollection";
import {
  FolderItemQueryParams,
  IMediaEditable,
  IUpdateFolder,
  IUpdateMedia,
  MoveImageFolderParams,
} from "../types/media";
import {
  IEditableUnitType,
  UnitTypeQueryParam,
  UpdateUnitTypeTypeParam,
} from "../types/unitType";
import { IUploadFileParams } from "../types/upload";

export const loadAllProject = (filter?: QueryParams) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosContentAPI.get(`/projects${query}`);
};

export const loadProjectById = (id: string) => {
  return axiosContentAPI.get(`/projects/${id}`);
};

export const updateProjectById = ({
  projectId,
  payload,
}: IUpdateProjectPayload) => {
  return axiosContentAPI.put(`/projects/${projectId}`, payload);
};

export const updateProjectVisibilityStatusAPI = ({
  projectId,
  payload,
}: {
  projectId: string;
  payload: { visibilityStatus: string };
}) => {
  return axiosContentAPI.put(`/projects/${projectId}/visibility-status`, payload);
};

export const updateProjectPublishStatusAPI = ({
  projectId,
  payload,
}: {
  projectId: string;
  payload: { publishStatus: string };
}) => {
  return axiosContentAPI.put(`/projects/${projectId}/publish-status`, payload);
};

export const createProject = (data: IProjectEditable) => {
  return axiosContentAPI.post(`/projects`, data);
};

export const deleteProjectById = (id: string) => {
  return axiosContentAPI.delete(`/projects/${id}`);
};

export const loadProjectUnitTypeAPI = ({
  id,
  filter,
}: ProjectQueryUnitTypeParam) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosContentAPI.get(`/projects/${id}/unit-types${query}`);
};

export const loadAllLocation = (filter: { limit: string }) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosContentAPI.get(`/locations${query}`);
};

export const loadAllBrand = (filter?: BrandQueryParams) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosContentAPI.get(`/brands${query}`);
};

export const loadBrandById = (brandId: string) => {
  return axiosContentAPI.get(`/brands/${brandId}`);
};

export const createBrand = (data: IBrandEditable) => {
  return axiosContentAPI.post(`/brands`, data);
};

export const updateBrandById = ({ brandId, data }: IUpdateBrandPayload) => {
  return axiosContentAPI.put(`/brands/${brandId}`, data);
};

export const deleteBrandById = (id: string) => {
  return axiosContentAPI.delete(`/brands/${id}`);
};

export const loadAllBadge = () => {
  return axiosContentAPI.get(`/terms?type=badge&limit=all`);
};

export const loadAllTag = () => {
  return axiosContentAPI.get(`/terms?type=tag&limit=all`);
};

export const createTerm = (data: ICreateTerm) => {
  return axiosContentAPI.post(`/terms`, data);
};

export const loadAllProductType = (filter?: QueryParams) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosContentAPI.get(`/types/products${query}`);
};

export const loadProductTypeById = (productTypeId: string) => {
  return axiosContentAPI.get(`/types/products/${productTypeId}`);
};

export const deleteProductTypeById = (productTypeId: string) => {
  return axiosContentAPI.delete(`/types/products/${productTypeId}`);
};

export const updateProductTypeById = ({
  productTypeId,
  data,
}: UpdateProductTypeParam) => {
  return axiosContentAPI.put(`/types/products/${productTypeId}`, data);
};

export const createProductType = (data: IProductTypeEditable) => {
  return axiosContentAPI.post(`/types/products`, data);
};

export const loadAllFacilityType = () => {
  return axiosContentAPI.get(`/types/facilities?used-in=facility`);
};

export const uploadImage = ({
  file,
  onUploadProgress,
  fileId,
  cancelToken,
}: IUploadFileParams) => {
  return axiosContentAPI.post(`/media/images`, file, {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: (e) => onUploadProgress(e, fileId),
    cancelToken: cancelToken.token,
  });
};

export const loadAllFolder = (filter?: QueryParams) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosContentAPI.get(`/media/folders${query}`);
};

export const loadImagesAPI = (filter?: FolderItemQueryParams) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosContentAPI.get(`/media/images${query}`);
};

export const loadFolderById = (id: string) => {
  return axiosContentAPI.get(`/media/folders/${id}`);
};

export const createFolderAPI = (data: IMediaEditable) => {
  return axiosContentAPI.post(`/media/folders`, data);
};

export const deleteFolderAPI = (id: string) => {
  return axiosContentAPI.delete(`/media/folders/${id}`);
};

export const updateFolderAPI = ({ id, data }: IUpdateFolder) => {
  return axiosContentAPI.put(`/media/folders/${id}`, data);
};

export const deleteImageAPI = (id: string) => {
  return axiosContentAPI.delete(`/media/images/${id}`);
};

export const updateImage = ({ id, data }: IUpdateMedia) => {
  return axiosContentAPI.put(`/media/images/${id}`, data);
};

export const moveImageAPI = ({ data }: MoveImageFolderParams) => {
  return axiosContentAPI.post(`/media/images/move`, data);
};

export const loadAllHouseCollectionAPI = (filter?: BrandQueryParams) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosContentAPI.get(`/units/collections${query}`);
};

export const loadHouseCollectionByIdAPI = (id: string) => {
  return axiosContentAPI.get(`/units/collections/${id}`);
};

export const createHouseCollectionAPI = (data: IHouseCollectionType) => {
  return axiosContentAPI.post(`/units/collections`, data);
};

export const updateHouseCollectionAPI = ({
  houseCollectionId,
  data,
}: UpdateHouseCollectionTypeParam) => {
  return axiosContentAPI.put(`/units/collections/${houseCollectionId}`, data);
};

export const deleteHouseCollectionAPI = (id: string) => {
  return axiosContentAPI.delete(`/units/collections/${id}`);
};

export const loadAllUnitTypeAPI = (filter?: UnitTypeQueryParam) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosContentAPI.get(`/units/types${query}`);
};

export const loadUnitTypeByIdAPI = (id: string) => {
  return axiosContentAPI.get(`/units/types/${id}`);
};

export const createUnitTypeAPI = (data: IEditableUnitType) => {
  return axiosContentAPI.post(`/units/types`, data);
};

export const updateUnitTypeAPI = ({
  unitTypeId,
  data,
}: UpdateUnitTypeTypeParam) => {
  return axiosContentAPI.put(`/units/types/${unitTypeId}`, data);
};

export const deleteUnitTypeAPI = (id: string) => {
  return axiosContentAPI.delete(`/units/types/${id}`);
};
