import { createReducer } from '@reduxjs/toolkit';
import {
  createSection,
  deleteSection,
  fetchDataSourceList,
  resetCurrentEdit,
  resetEditorState,
  setCurrentEditChildComponent,
  setCurrentEdit,
  setDataSources,
  setIsEnableSave,
  setSectionError,
  setSections,
  updateSection,
  setSettings,
  updateSettings,
  setScrollPosition,
  resetDeleteSectionState, fetchContentCategories, updateCurrentSection, setCurrentEditType, setPreviewLayout, setEditingDataSourceIndex, setBgColorOptions,
} from './actions';
import {
  ChildComponentProps,
  ChildComponentSpec,
  ComponentSpecProps,
  CurrentEditTypes,
  DataSourceProps,
  ErrorTypes, JSONComponentDataSourceProps, ParametersOptionsProps, PreviewLayoutType,
  PublishedSectionProps,
} from '../../../types';
import { SelectItem } from '@mantine/core';

export type CurrentEditProps = {
  component: PublishedSectionProps | null | undefined;
  componentSpec: ComponentSpecProps | ChildComponentSpec | null | undefined;
  childComponent: ChildComponentProps | null | undefined;
  selectedChildIndex: number | null;
  type: CurrentEditTypes;
}

export type AppearanceProps = {
  title: string;
  description: string;
  url: string;
  imgUrl: string;
  logoImgUrl: string;
  backgroundImgUrl: string;
  backButtonText: string;
  backButtonUrl: string;
}

interface InitialStateProps {
  sections: Array<PublishedSectionProps>;
  currentEdit: CurrentEditProps;
  isEnableSave: boolean;
  dataSources: Array<JSONComponentDataSourceProps>;
  createSectionState: {
    isLoading: boolean;
    error: ErrorTypes | null;
    isSuccess: boolean;
  };
  deleteSectionState: {
    isLoading: boolean;
    error: ErrorTypes | null;
    isSuccess: boolean;
  };
  saveSectionState: {
    isSaving: boolean;
    error: ErrorTypes | null;
    isSuccess: boolean;
  };
  error: ErrorTypes | null;
  dataSourceList: Array<DataSourceProps>;
  dataSourceAutoList: Array<DataSourceProps>;
  dataSourceIdList: Array<DataSourceProps>;
  dataSourceListError: ErrorTypes | null;
  scrollPosition: number;
  contentCategories: Array<ParametersOptionsProps>;
  previewLayout: PreviewLayoutType;
  editingDataSourceIndex: number;
  backgroundColorOptions: Array<SelectItem>
}

const initialState: InitialStateProps = {
  currentEdit: {
    component: null,
    componentSpec: null,
    childComponent: null,
    selectedChildIndex: null,
    type: null,
  },
  sections: [],
  isEnableSave: false,
  dataSources: [],
  // componentSettings: {
  //   layout: '',
  //   backgroundColor: 'transparent',
  //   isShowSubtitle: false,
  //   isShowDescription: false,
  //   isShowDate: false,
  //   isShowCategory: false,
  // },
  createSectionState: {
    isLoading: true,
    error: null,
    isSuccess: false,
  },
  deleteSectionState: {
    isLoading: false,
    error: null,
    isSuccess: false,
  },
  saveSectionState: {
    isSaving: false,
    error: null,
    isSuccess: false,
  },
  error: null,
  dataSourceList: [],
  dataSourceAutoList: [],
  dataSourceIdList: [],
  dataSourceListError: null,
  scrollPosition: 0,
  contentCategories: [],
  previewLayout: 'desktop',
  editingDataSourceIndex: -1,
  backgroundColorOptions: []
};

export const editorReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createSection.pending, (state) => {
      state.createSectionState.isLoading = true;
      state.createSectionState.isSuccess = false;
      state.createSectionState.error = null;
    })
    .addCase(createSection.fulfilled, (state) => {
      state.createSectionState.isLoading = false;
      state.createSectionState.isSuccess = true;
      state.createSectionState.error = null;
    })
    .addCase(createSection.rejected, (state, { payload }) => {
      state.createSectionState.isLoading = false;
      state.createSectionState.isSuccess = false;
      state.createSectionState.error = payload as ErrorTypes;
    })

    .addCase(fetchDataSourceList.pending, (state) => {
      state.dataSourceListError = null;
    })
    .addCase(fetchDataSourceList.fulfilled, (state, { payload }) => {
      state.dataSourceList = payload.data;
      state.dataSourceAutoList = payload.data?.filter((item: DataSourceProps) => item.mode === 'auto') || [];
      state.dataSourceIdList = payload.data?.filter((item: DataSourceProps) => item.mode === 'id') || [];
    })
    .addCase(fetchDataSourceList.rejected, (state, { payload }) => {
      state.dataSourceListError = payload as ErrorTypes;
    })

    .addCase(updateSection.pending, (state) => {
      state.saveSectionState.isSaving = true;
      state.saveSectionState.isSuccess = false;
      state.saveSectionState.error = null;
    })
    .addCase(updateSection.fulfilled, (state, { payload }) => {
      state.currentEdit.component = payload;
      state.saveSectionState.isSaving = false;
      state.saveSectionState.isSuccess = true;
      state.saveSectionState.error = null;
    })
    .addCase(updateSection.rejected, (state, { payload }) => {
      state.saveSectionState.isSaving = false;
      state.saveSectionState.isSuccess = false;
      state.saveSectionState.error = payload as ErrorTypes;
    })

    .addCase(deleteSection.pending, (state) => {
      state.deleteSectionState.isLoading = true;
      state.deleteSectionState.isSuccess = false;
      state.deleteSectionState.error = null;
    })
    .addCase(deleteSection.fulfilled, (state) => {
      state.deleteSectionState.isLoading = false;
      state.deleteSectionState.isSuccess = true;
      state.deleteSectionState.error = null;
    })
    .addCase(deleteSection.rejected, (state, { payload }) => {
      state.deleteSectionState.isLoading = false;
      state.deleteSectionState.isSuccess = false;
      state.deleteSectionState.error = payload as ErrorTypes;
    })

    .addCase(setCurrentEditChildComponent, (state, { payload }) => {
      const { component, selectedChildIndex } = state.currentEdit;
      if (component?.childComponents && selectedChildIndex !== null && component.childComponents[selectedChildIndex]) {
        component.childComponents[selectedChildIndex] = payload;
      }
      state.currentEdit.childComponent = payload;
    })
    .addCase(setCurrentEdit, (state, { payload }) => {
      state.currentEdit = payload;
    })
    .addCase(setCurrentEditType, (state, { payload }) => {
      state.currentEdit.type = payload;
    })
    .addCase(setIsEnableSave, (state, { payload }) => {
      state.isEnableSave = payload;
    })
    .addCase(setSettings, (state, { payload }) => {
      if (state.currentEdit.component?.settings) {
        state.currentEdit.component.settings = payload;
      }
    })
    .addCase(updateSettings, (state, { payload }) => {
      if (state.currentEdit.childComponent) {
        const newObject = {
          ...state.currentEdit.childComponent.settings,
          ...payload,
        };
        state.currentEdit.childComponent.settings = newObject;
        const { component, selectedChildIndex } = state.currentEdit;
        if (component?.childComponents && selectedChildIndex !== null && component.childComponents[selectedChildIndex]) {
          component.childComponents[selectedChildIndex].settings = newObject;
        }
        return;
      }

      if (state.currentEdit.component?.settings) {
        state.currentEdit.component.settings = {
          ...state.currentEdit.component.settings,
          ...payload,
        };
      }
    })
    .addCase(updateCurrentSection, (state, { payload }) => {
      if (state.currentEdit.childComponent) {
        const newObject = {
          ...state.currentEdit.childComponent,
          ...payload,
        };
        state.currentEdit.childComponent = newObject;
        const { component, selectedChildIndex } = state.currentEdit;
        if (component?.childComponents && selectedChildIndex !== null && component.childComponents[selectedChildIndex]) {
          component.childComponents[selectedChildIndex] = newObject;
        }
        return;
      }

      if (state.currentEdit.component) {
        state.currentEdit.component = {
          ...state.currentEdit.component,
          ...payload,
        };
      }
    })
    .addCase(setDataSources, (state, { payload }) => {
      state.dataSources = payload;
    })
    .addCase(resetCurrentEdit, (state) => {
      Object.assign(state.currentEdit, initialState.currentEdit);
    })
    .addCase(resetEditorState, (state) => {
      Object.assign(state, initialState);
    })
    .addCase(setSectionError, (state, { payload }) => {
      state.error = payload;
    })
    .addCase(setScrollPosition, (state, { payload }) => {
      state.scrollPosition = payload;
    })
    .addCase(resetDeleteSectionState, (state) => {
      state.deleteSectionState = {
        isLoading: false,
        error: null,
        isSuccess: false,
      };
    })
    .addCase(setSections, (state, { payload }) => {
      state.sections = payload;
    })
    .addCase(fetchContentCategories.fulfilled, (state, { payload }) => {
      state.contentCategories = payload.data;
    })
    .addCase(setPreviewLayout, (state, { payload }) => {
      state.previewLayout = payload;
    })
    .addCase(setEditingDataSourceIndex, (state, { payload }) => {
      state.editingDataSourceIndex = payload;
    })
    .addCase(setBgColorOptions, (state, { payload }) => {
      state.backgroundColorOptions = payload
    });
});

export default editorReducer;
