import styled from "@emotion/styled/macro";

export const Container = styled.div<{ isEnableDrag?: boolean }>`
  position: relative;
  background-color: var(--grey100);
  border-radius: var(--border-radius);
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: var(--space-xs);
  padding-left: 10px;
  cursor: ${(props) => (props.isEnableDrag ? 'move' : 'default')};
  min-height: 64px;
`;

export const Header = styled.div``;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.p``;

export const HeaderText = styled.div`
  width: calc(100% - 74px);
`;

export const HeaderButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const HeaderButton = styled.button<{ hide?: boolean; isDeleteButton?: boolean }>`
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  color: var(--grey500);
  opacity: ${(props) => (props.hide ? '0' : '1')};
  padding: 10px 12px;
  pointer-events: ${(props) => (props.hide ? 'none' : 'auto')};
  transition: color 150ms ease;

  :hover {
    color: ${(props) => (props.isDeleteButton ? 'var(--error500)' : 'var(--grey800)')};
  }
`;

export const Content = styled.div`
  font-size: 14px;
  margin-top: var(--space-xs);
  color: var(--grey800);
`;

export const DescriptionText = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  max-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
`;
