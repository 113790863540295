import { createReducer } from "@reduxjs/toolkit";
import {
  initialPagination,
  IPlaceType,
  PaginationType,
  StatusState,
} from "../../../types";
import { formatErrorObject } from "../../../utils/errorFormatter";
import { getStateObject } from "../../../utils/stateManagement";
import { fetchPlaceTypeList } from "./actions";

interface InitialStateProps {
  placeTypes: IPlaceType[];
  loadPlaceTypesListState: StatusState;
  pagination: PaginationType;
}

const initialState: InitialStateProps = {
  placeTypes: [],
  loadPlaceTypesListState: getStateObject({ type: "idle" }),
  pagination: initialPagination,
};

export const placeTypeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchPlaceTypeList.pending, (state) => {
      state.loadPlaceTypesListState = getStateObject({ type: "loading" });
    })
    .addCase(fetchPlaceTypeList.fulfilled, (state, { payload }) => {
      state.placeTypes = payload?.data;
      state.loadPlaceTypesListState = getStateObject({ type: "success" });
    })
    .addCase(fetchPlaceTypeList.rejected, (state, { payload }) => {
      state.loadPlaceTypesListState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Place Type List"),
      });
    });
});
