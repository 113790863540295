import { HTMLAttributes } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons/faEyeSlash';
import { Container, Content, Description, Header, HeaderButton, HeaderText, Title, Wrapper, AddAfterArea } from './Card.style';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  description?: string;
  button?: JSX.Element;
  children?: JSX.Element;
  isHidden?: boolean;
  onClickAddSection?: () => void;
  onMouseEnter?: () => void;
  onTouchEnd?: () => void;
}

export const Card = (props: CardProps) => {
  const { id, title, description, button, children, isHidden, onClickAddSection, onMouseEnter, onTouchEnd } = props;

  return (
    <Container onMouseEnter={onMouseEnter} onTouchEnd={onTouchEnd} id={id} title={title}>
      <Header>
        <Wrapper>
          <HeaderText>
            <Title className="card-title">
              { isHidden === true && (
                <FontAwesomeIcon title="Section นี้ไม่ถูกนำมาแสดงผล" icon={faEyeSlash} />
              ) }
              <div dangerouslySetInnerHTML={{ __html: title }}/>
            </Title>
            { description && (
              <Description>{ description }</Description>
            ) }
          </HeaderText>
          { button && (
            <HeaderButton>
              { button }
            </HeaderButton>
          ) }
        </Wrapper>
      </Header>
      <Content>
        { children }
      </Content>
      <AddAfterArea onClick={onClickAddSection} />
    </Container>
  );
};
