import { Breadcrumbs, createStyles } from "@mantine/core";
import { Button } from "../button/Button";
import { Text } from "./Breadcrumb.style";

const useStyles = createStyles((theme) => ({
  separator: {
    color: "var(--grey400)",
    widht: 4,
    height: 8,
  },
}));

type BreadcrumbData = {
  value: string | null;
  label: string;
};

export type BreadcrumbStyle = "primary" | "grey";

interface BreadcrumbProps {
  data: BreadcrumbData[];
  separator: React.ReactNode;
  breadcrumbStyle?: BreadcrumbStyle;
  onClickBack?: (index: number) => void;
}

export function Breadcrumb(props: BreadcrumbProps) {
  const { separator, data, onClickBack = () => {}, breadcrumbStyle="primary" } = props;
  const { classes } = useStyles();

  function onHandleBreadcrumbData() {
    return data.map((item, index) => {
      if (index === data.length - 1) {
        return (
          <Text breadcrumbStyle={breadcrumbStyle} key={`${item.label}-${index}`}>{item.label}</Text>
        );
      }
      return (
        <Button
          fontSize="sm"
          height={"fit-content"}
          key={`${item.label}-${index}`}
          buttonStyle="text-link-grey"
          onClick={() => onClickBack(index)}
        >
          {item.label}
        </Button>
      );
    });
  }

  return (
    <Breadcrumbs classNames={classes} separator={separator}>
      {onHandleBreadcrumbData()}
    </Breadcrumbs>
  );
}
