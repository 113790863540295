import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../atoms/button/Button";
import { ImageInput } from "../../atoms/imageInput/ImageInput";
import { Loader } from "../../atoms/loading/loader/Loader";
import { Select } from "../../atoms/select/Select";
import { TextArea } from "../../atoms/textArea/TextArea";
import { TextInput } from "../../atoms/textInput/TextInput";
import {
  ButtonWrapper,
  ContentContainer,
  ContentHolder,
  ContentWrapper,
  FlexContainer,
  MediaInputWrapper,
  ScrollWrapper,
  SidePanelContainer,
} from "../../globalStyle/styles/ManagePage.style";
import { ContentDivider } from "../../molecules/contentDivider/ContentDivider";
import { ConfirmModal } from "../../organisms/confirmModal/ConfirmModal";
import { PageHeader } from "../../organisms/pageHeader/PageHeader";
import {
  SidePanelTextGroup,
  SubText,
  SubTitle,
} from "../../organisms/projectSidePanel/ProjectSidePanel.style";
import { Section } from "../../organisms/section/Section";
import { SectionAttachment } from "../../organisms/section/sectionAttachments/SectionAttachments";
import { SectionFunction } from "../../organisms/section/sectionFunction/SectionFunction";
import { SectionMedia } from "../../organisms/section/sectionMedia/SectionMedia";
import { SectionUnitVariant } from "../../organisms/section/sectionUnitVariant/SectionUnitVariant";
import { SidePanel } from "../../organisms/sidePanel/SidePanel";
import {
  fetchHouseCollectionSelectItems,
  houseCollectionSelector,
} from "../../store/features/houseCollection";
import {
  fetchProductTypeSelectItems,
  productTypeSelector,
} from "../../store/features/productType";
import {
  fetchProjectSelectItems,
  projectSelector,
} from "../../store/features/project";
import {
  fetchUnitTypeDetail,
  resetUnitTypeDetailData,
  setDuplicateUnitTypeData,
  setEditUnitTypeData,
  unitTypeSelector,
} from "../../store/features/unitType";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IEmbeddedMedia, IMediaResponse, lang } from "../../types";
import { mapAttachmentUnitTypePage } from "../../types/mapAttachmentSection";
import { mapImageInputUnitTypePage } from "../../types/mapMediaSection";
import {
  IEditableUnitType,
  IFunction,
  initUnitType,
  ISubtype,
} from "../../types/unitType";
import { onCastIUploadDataToEmbeddedImageData } from "../../utils/castType";
import {
  onHandleChangeArrayAttachment,
  onHandleChangeArrayImage,
  onHandleChangeEditableData,
} from "../../utils/dataService";
import {
  formatFullDateTime,
  formatLanguageSelectItem,
} from "../../utils/format";
import { ButtonGroup } from "../manageProject/ManageProject.style";

interface ManageUnitTypeProps {
  unitTypeId?: string;
  pageTitle: string;
  submitButtonTitle?: string;
  isShowAddLanguageButton?: boolean;
  isShowUpdatedAt?: boolean;
  isShowBackButton?: boolean;
  customMaxHeightBody?: string;
  customMaxHeightContent?: string;
  isShowProjectIdSelection?: boolean;
  isDisableProjectSelection?: boolean;
  defaultForProjectId?: string;
  onClickSubmit?: (data: IEditableUnitType) => void;
  onClickDelete?: () => void;
  onClickCreate?: (data: IEditableUnitType) => void;
}

export function ManageUnitType(props: ManageUnitTypeProps) {
  const {
    unitTypeId,
    pageTitle,
    isShowBackButton = true,
    defaultForProjectId,
    isDisableProjectSelection,
    submitButtonTitle,
    customMaxHeightBody,
    customMaxHeightContent,
    isShowAddLanguageButton,
    isShowUpdatedAt,
    isShowProjectIdSelection,
    onClickDelete,
    onClickCreate,
    onClickSubmit,
  } = props;
  const navigate = useNavigate();
  const languageCode = lang;
  const dispatch = useAppDispatch();
  const {
    currentUnitType,
    duplicateUnitType,
    editUnitType: unitTypeData,
  } = useAppSelector(unitTypeSelector);
  const { selecteProjectItems } = useAppSelector(projectSelector);
  const { houseCollectionSelectItems } = useAppSelector(
    houseCollectionSelector
  );
  const { productTypeSelectItems } = useAppSelector(productTypeSelector);
  // const [unitTypeData, setUnitTypeData] =
  //   useState<IEditableUnitType | null>(null);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);

  const [selectedLanguage, setSelectedLanguage] =
    useState<string>(languageCode);

  function onHandleChangeUnitTypeData(path: string, value: any) {
    // setUnitTypeData(onHandleChangeEditableData(unitTypeData, path, value));
    dispatch(
      setEditUnitTypeData(onHandleChangeEditableData(unitTypeData, path, value))
    );
  }

  function onHandleChangeImages(
    img: IMediaResponse | null,
    device: string | null,
    key: string,
    useFor: string | null
  ) {
    if (unitTypeData) {
      const newData = onHandleChangeArrayImage(
        unitTypeData,
        img,
        device,
        key,
        useFor
      );
      if (onClickSubmit) {
        onClickSubmit(newData);
      }
      // setUnitTypeData(newData);
      dispatch(setEditUnitTypeData(newData));
    }
  }

  function onHandleChangeAttachment(
    useFor: string,
    key: string,
    data?: IEmbeddedMedia | null
  ) {
    if (data) {
      const newData = onHandleChangeArrayAttachment(
        unitTypeData,
        data,
        useFor,
        key
      );
      if (onClickSubmit) {
        onClickSubmit(newData);
      }
      // setUnitTypeData(newData);
      dispatch(setEditUnitTypeData(newData));
    }
  }

  function onHandleDeleteAttachment(useFor: string) {
    const cloned = _.cloneDeep(unitTypeData);
    if (cloned) {
      const removeIndex = cloned?.attachments.findIndex((data) => {
        return data?.useFor === useFor;
      });
      if (removeIndex >= 0) {
        cloned?.attachments.splice(removeIndex, 1);
        // setUnitTypeData(cloned);
        dispatch(setEditUnitTypeData(cloned));
      }
    }
  }

  function loadData() {
    if (unitTypeId) {
      dispatch(fetchUnitTypeDetail(unitTypeId));
    }

    dispatch(fetchProjectSelectItems({ languageCode: selectedLanguage }));
    dispatch(fetchProductTypeSelectItems({ languageCode: selectedLanguage, usedIn:"unit-type" }));

    dispatch(
      fetchHouseCollectionSelectItems({ languageCode: selectedLanguage })
    );
  }
  useEffect(() => {
    loadData();

    return () => {
      dispatch(resetUnitTypeDetailData());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUnitType) {
      const contentVariation = _.find(currentUnitType?.contentVariations, {
        languageCode: selectedLanguage,
      });
      if (contentVariation) {
        dispatch(
          setEditUnitTypeData({
            ..._.omit(currentUnitType, "contentVariations"),
            contentVariation: contentVariation,
          })
        );
      }
    } else {
      if (duplicateUnitType) {
        const contentVariation = _.find(duplicateUnitType?.contentVariations, {
          languageCode: selectedLanguage,
        });
        if (contentVariation) {
          dispatch(
            setEditUnitTypeData({
              ..._.omit(duplicateUnitType, "contentVariations"),
              contentVariation: contentVariation,
            })
          );
        }
      } else {
        dispatch(setEditUnitTypeData(initUnitType));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUnitType]);

  return unitTypeData ? (
    <>
      <PageHeader
        title={pageTitle}
        backLink={isShowBackButton ? "/unit-type" : undefined}
        onClickBack={() => {}}
        rightAction={
          <>
            {onClickDelete && (
              <ButtonGroup>
                <Button
                  buttonStyle="outline-light"
                  onClick={() => setIsShowDeleteModal(true)}
                >
                  <FontAwesomeIcon icon={["far", "trash-can"]} />
                </Button>
                <Button
                  buttonStyle="outline-light"
                  onClick={() => {
                    dispatch(
                      setDuplicateUnitTypeData({
                        ..._.omit(currentUnitType, ["createdAt", "updatedAt"]),
                      })
                    );
                    navigate("/unit-type/create");
                  }}
                  leftIcon={<FontAwesomeIcon icon={["far", "copy"]} />}
                >
                  Duplicate
                </Button>
              </ButtonGroup>
            )}
            {onClickCreate && (
              <Button
                onClick={() =>
                  onClickCreate ? onClickCreate(unitTypeData) : undefined
                }
              >
                Create
              </Button>
            )}
          </>
        }
      />
      <ConfirmModal
        onClose={() => {
          setIsShowDeleteModal(false);
        }}
        title="Delete Unit Type"
        opened={isShowDeleteModal}
        onClickPrimaryButton={onClickDelete}
      >
        <div>
          Are you sure you want to delete{" "}
          <b>‘{unitTypeData.contentVariation?.name || ""}’</b>?
        </div>
        <div style={{ marginTop: "16px" }}>
          Once you confirm, this unit type will be permanently deleted and also
          removed from all contents that use it.
        </div>
      </ConfirmModal>
      <FlexContainer customHeight={customMaxHeightBody}>
        <ContentContainer customHeight={customMaxHeightContent}>
          <ScrollWrapper>
            <Section
              headerButtonStyle="primary"
              headerButtonTitle={submitButtonTitle}
              onClickHeaderButton={
                onClickSubmit ? () => onClickSubmit(unitTypeData) : undefined
              }
              title="General Information"
            >
              <ContentHolder>
                <ContentWrapper>
                  <TextInput
                    label="Name"
                    value={unitTypeData?.contentVariation?.name || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onHandleChangeUnitTypeData(
                        "contentVariation.name",
                        e.target.value
                      )
                    }
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <TextArea
                    label="Description"
                    value={unitTypeData?.contentVariation?.description || ""}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      onHandleChangeUnitTypeData(
                        "contentVariation.description",
                        e.target.value
                      )
                    }
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <Select
                    label="Product Type"
                    value={unitTypeData?.productTypeId || ""}
                    onChange={(e: string) =>
                      onHandleChangeUnitTypeData("productTypeId", e)
                    }
                    data={productTypeSelectItems}
                    // dropdownPosition="bottom"
                  />
                </ContentWrapper>
                <ContentWrapper>
                  <Select
                    label="House collection"
                    value={unitTypeData?.houseCollectionId || ""}
                    onChange={(e: string) =>
                      onHandleChangeUnitTypeData("houseCollectionId", e)
                    }
                    data={houseCollectionSelectItems}
                    // dropdownPosition="bottom"
                  />
                </ContentWrapper>

                <ContentWrapper>
                  <TextInput
                    label="Note"
                    value={unitTypeData?.internalNote || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onHandleChangeUnitTypeData("internalNote", e.target.value)
                    }
                    hintText={"This is only an internal note. It'll not display on the main website."}
                  />
                </ContentWrapper>
              </ContentHolder>
            </Section>
            <SectionUnitVariant
              subtypesData={unitTypeData.contentVariation.subtypes}
              onChangeDataAndSave={(value: ISubtype[]) => {
                onHandleChangeUnitTypeData("contentVariation.subtypes", value);
                onClickSubmit &&
                  onClickSubmit(
                    onHandleChangeEditableData(
                      unitTypeData,
                      "contentVariation.subtypes",
                      value
                    )
                  );
              }}
            />
            <SectionFunction
              functionsData={unitTypeData.contentVariation.functions}
              onChangeDataAndSave={(value: IFunction[]) => {
                onHandleChangeUnitTypeData("contentVariation.functions", value);
                onClickSubmit &&
                  onClickSubmit(
                    onHandleChangeEditableData(
                      unitTypeData,
                      "contentVariation.functions",
                      value
                    )
                  );
              }}
            />
            <SectionAttachment
              title="Attachments"
              mapAttachmentInputData={mapAttachmentUnitTypePage.map((item) => {
                return {
                  label: item.label,
                  data: _.find(unitTypeData.attachments, {
                    useFor: item.value,
                  }),
                  onClickChooseAttachment: (data) => {
                    onHandleChangeAttachment(
                      item.value,
                      "attachments",
                      onCastIUploadDataToEmbeddedImageData(data, null)
                    );
                  },
                  onClickDeleteAttachment: () => {
                    onHandleDeleteAttachment(item.value);
                  },
                };
              })}
            />
            <SectionMedia
              mediaSection={
                <>
                  <MediaInputWrapper>
                    {mapImageInputUnitTypePage
                      .slice(0, 2)
                      .map((item, index) => (
                        <ImageInput
                          {...item}
                          key={`${item.label}-${index + 10}`}
                          onClickChooseImage={(img) => {
                            onHandleChangeImages(
                              img,
                              item.device,
                              item.key,
                              item.usedFor
                            );
                          }}
                          previousImageData={_.find(unitTypeData.banners, {
                            deviceType: item.device,
                            useFor: item.usedFor,
                          })}
                          data={{
                            imgUrl: _.get(
                              _.find(unitTypeData.banners, {
                                deviceType: item.device,
                                useFor: item.usedFor,
                              }),
                              "originalUrl",
                              ""
                            ),
                            imgAlt: _.get(
                              _.find(unitTypeData.banners, {
                                deviceType: item.device,
                                useFor: item.usedFor,
                              }),
                              "name",
                              ""
                            ),
                          }}
                        />
                      ))}
                  </MediaInputWrapper>
                  <ImageInput
                    {...mapImageInputUnitTypePage[2]}
                    onClickChooseImage={(img) => {
                      onHandleChangeUnitTypeData(
                        "thumbnail",
                        onCastIUploadDataToEmbeddedImageData(img, null) || null
                      );
                    }}
                    data={{
                      imgUrl: unitTypeData?.thumbnail?.originalUrl || "",
                      imgAlt: unitTypeData?.thumbnail?.name || "",
                    }}
                    previousImageData={unitTypeData?.thumbnail}
                  />
                </>
              }
            />
          </ScrollWrapper>
        </ContentContainer>
        <SidePanel
          sidePanelAction={
            <SidePanelContainer>
              {currentUnitType ? (
                <Select
                  disabled={Boolean(
                    !(currentUnitType?.availableLanguages?.length > 1)
                  )}
                  label={
                    Boolean(!(currentUnitType?.availableLanguages?.length > 1))
                      ? "Language"
                      : "Switch Language"
                  }
                  data={formatLanguageSelectItem(
                    currentUnitType?.availableLanguages
                  )}
                  value={selectedLanguage}
                  onChange={(e) => setSelectedLanguage(e || "th")}
                />
              ) : (
                <Select
                  disabled
                  label={"Language"}
                  data={[{ value: "th", label: "Thai" }]}
                  value={selectedLanguage}
                />
              )}
              {isShowAddLanguageButton && (
                <ButtonWrapper>
                  <Button
                    buttonStyle="outline-light"
                    leftIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
                  >
                    Add Language
                  </Button>
                </ButtonWrapper>
              )}
              {(isShowProjectIdSelection || currentUnitType?.forProjectId) && (
                <>
                  <ContentDivider />
                  <Select
                    disabled={
                      Boolean(currentUnitType?.forProjectId) ||
                      isDisableProjectSelection
                    }
                    label={"Project"}
                    data={[
                      { value: "", label: "Not specified" },
                      ...selecteProjectItems,
                    ]}
                    value={
                      unitTypeData.forProjectId ||
                      currentUnitType?.forProjectId ||
                      defaultForProjectId ||
                      ""
                    }
                    hintText={
                      "Select if you want to create a unit type for a specific project."
                    }
                    onChange={(e) => {
                      onHandleChangeUnitTypeData("forProjectId", e);
                    }}
                  />
                </>
              )}
              {isShowUpdatedAt && currentUnitType && (
                <>
                  <ContentDivider />
                  <ContentWrapper>
                    <SidePanelTextGroup>
                      <SubTitle>Updated at</SubTitle>
                      <SubText>
                        {formatFullDateTime(
                          new Date(currentUnitType?.updatedAt || "")
                        )}
                      </SubText>
                    </SidePanelTextGroup>
                  </ContentWrapper>
                </>
              )}
            </SidePanelContainer>
          }
        />
      </FlexContainer>
    </>
  ) : (
    <div style={{ margin: "auto" }}>
      <Loader color="gray" />
    </div>
  );
}
