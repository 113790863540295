import { ComponentSpecProps } from "../../../types";

export const AutoFillVerticalInformationSection: ComponentSpecProps = {
  name: 'Section สำหรับแสดงข้อมูลทั่วไป (แนวตั้ง)',
  componentType: 'AutoFillVerticalInformationSection',
  settingFields: {
    backgroundColor: {
      displayName: "สีพื้นหลัง",
      fieldName: 'backgroundColor',
      type: 'color-selector',
      defaultValue: '#FFFFFF',
      options: [
        {
          value: "#FFFFFF",
          label: "White (#FFFFFF)",
          key: "white-color-FFFFFF"
        },
        {
          value: "#F8F8F8",
          label: "White smoke (#F8F8F8)",
          key: "lighr-grey-color-FFFFFF"
        }
      ]
    },
  },
  settings: {},
  required: {},
}

export const AutoFillHorizontalInformationSection: ComponentSpecProps = {
  name: 'Section สำหรับแสดงข้อมูลทั่วไป (แนวนอน)',
  componentType: 'AutoFillHorizontalInformationSection',
  settingFields: {
    backgroundColor: {
      displayName: "สีพื้นหลัง",
      fieldName: 'backgroundColor',
      type: 'color-selector',
      defaultValue: '#FFFFFF',
      options: [
        {
          value: "#FFFFFF",
          label: "White (#FFFFFF)",
          key: "white-color-FFFFFF"
        },
        {
          value: "#F8F8F8",
          label: "White smoke (#F8F8F8)",
          key: "lighr-grey-color-FFFFFF"
        }
      ]
    },
  },
  settings: {},
  required: {},
}