import { createReducer } from "@reduxjs/toolkit";
import { IHouseCollectionType } from "../../../types/houseCollection";
import { SelectItemProps, StatusState } from "../../../types";
import {
  updateHouseCollectionById,
  fetchHouseCollectionList,
  deleteHouseCollectionById,
  setCurrentHouseCollection,
  fetchHouseCollectionDetail,
  createHouseCollection,
  resetHouseCollectionListData,
  resetHouseCollectionDetailData,
  setHouseCollectionSelectItem,
} from "./actions";
import { getStateObject } from "../../../utils/stateManagement";
import { formatErrorObject } from "../../../utils/errorFormatter";

interface InitialStateProps {
  houseCollectionList: IHouseCollectionType[];
  houseCollectionSelectItems: SelectItemProps[];
  currentHouseCollection: IHouseCollectionType | null;
  loadHouseCollectionDetailState: StatusState;
  loadHouseCollectionListState: StatusState;
  updateHouseCollectionState: StatusState;
  deleteHouseCollectionState: StatusState;
  createHouseCollectionState: StatusState;
  pagination: {
    from: number;
    to: number;
    lastPage: number;
    perPage: number;
    total: number;
    currentPage: number;
  };
}

const houseCollectionListInitialState = {
  houseCollectionList: [],
  houseCollectionSelectItems: [],
  loadHouseCollectionListState: getStateObject({ type: "idle" }),
  pagination: {
    from: 0,
    to: 0,
    lastPage: 0,
    perPage: 0,
    total: 0,
    currentPage: 0,
  },
};

const houseCollectionDetailInitialState = {
  currentHouseCollection: {
    logo: null,
  } as unknown as IHouseCollectionType,
  loadHouseCollectionDetailState: getStateObject({ type: "idle" }),
  updateHouseCollectionState: getStateObject({ type: "idle" }),
  deleteHouseCollectionState: getStateObject({ type: "idle" }),
  createHouseCollectionState: getStateObject({ type: "idle" }),
};

const initialState: InitialStateProps = {
  ...houseCollectionDetailInitialState,
  ...houseCollectionListInitialState,
};

export const houseCollectionReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchHouseCollectionList.pending, (state) => {
      state.loadHouseCollectionListState = getStateObject({ type: "loading" });
    })
    .addCase(fetchHouseCollectionList.fulfilled, (state, { payload }) => {
      state.loadHouseCollectionListState = getStateObject({ type: "success" });
      state.houseCollectionList = payload?.data;
      state.pagination = {
        from: payload?.from,
        to: payload?.to,
        lastPage: payload?.lastPage,
        perPage: payload?.perPage,
        total: payload?.total,
        currentPage: payload?.currentPage,
      };
    })
    .addCase(fetchHouseCollectionList.rejected, (state, { payload }) => {
      state.houseCollectionList = [];
      state.loadHouseCollectionListState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "House Collection List"),
      });
    })
    .addCase(updateHouseCollectionById.pending, (state) => {
      state.updateHouseCollectionState = getStateObject({ type: "loading" });
    })
    .addCase(updateHouseCollectionById.fulfilled, (state, { payload }) => {
      state.currentHouseCollection = payload;
      state.updateHouseCollectionState = getStateObject({ type: "success" });
    })
    .addCase(updateHouseCollectionById.rejected, (state, { payload }) => {
      state.updateHouseCollectionState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "House Collection List"),
      });
    })
    .addCase(deleteHouseCollectionById.pending, (state) => {
      state.deleteHouseCollectionState = getStateObject({ type: "loading" });
    })
    .addCase(deleteHouseCollectionById.fulfilled, (state) => {
      state.deleteHouseCollectionState = getStateObject({ type: "success" });
    })
    .addCase(deleteHouseCollectionById.rejected, (state, { payload }) => {
      state.deleteHouseCollectionState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "House Collection"),
      });
    })
    .addCase(fetchHouseCollectionDetail.pending, (state) => {
      state.loadHouseCollectionDetailState = getStateObject({
        type: "loading",
      });
    })
    .addCase(fetchHouseCollectionDetail.fulfilled, (state, { payload }) => {
      state.currentHouseCollection = payload;
      state.loadHouseCollectionDetailState = getStateObject({
        type: "success",
      });
    })
    .addCase(fetchHouseCollectionDetail.rejected, (state, { payload }) => {
      state.currentHouseCollection = {} as IHouseCollectionType;
      state.loadHouseCollectionDetailState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "House Collection Detail"),
      });
    })
    .addCase(setCurrentHouseCollection, (state, { payload }) => {
      state.currentHouseCollection = payload;
    })
    .addCase(createHouseCollection.pending, (state) => {
      state.createHouseCollectionState = getStateObject({ type: "loading" });
    })
    .addCase(createHouseCollection.fulfilled, (state) => {
      state.createHouseCollectionState = getStateObject({ type: "success" });
    })
    .addCase(createHouseCollection.rejected, (state, { payload }) => {
      state.createHouseCollectionState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "House Collection"),
      });
    })
    .addCase(setHouseCollectionSelectItem, (state, { payload }) => {
      state.houseCollectionSelectItems = payload;
    })
    .addCase(resetHouseCollectionListData, (state) => {
      Object.assign(state, houseCollectionListInitialState);
    })
    .addCase(resetHouseCollectionDetailData, (state) => {
      Object.assign(state, houseCollectionDetailInitialState);
    });
});
