import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image, AspectRatio } from "@mantine/core";
import { useState } from "react";
import {
  Container,
  ImageWrapper,
  Overlay,
  OverlayText,
  DimentionText,
  InputImageWrapper,
  ChooseImageText,
  IconWrapper,
  ImageSuggestionSizeText,
  DimentionWrapper,
} from "./ImageInput.style";

import { UploadText } from "./imageInputModal/ImageInputModal.style";
import { IEmbeddedMedia, IMediaResponse } from "../../types";
import { ImageInputModal } from "./imageInputModal/ImageInputModal";
import { fetchAllFolder } from "../../store/features/media";
import { useAppDispatch } from "../../store/hooks";
import { LabelText } from "../../globalStyle/GlobalStyle.style";

type ImageData = {
  imgUrl: string;
  imgAlt: string;
};

interface ImageInputProps {
  data?: ImageData;
  imgSize?: string;
  ratio?: number;
  label?: string;
  size?: string;
  previousImageData?:IEmbeddedMedia | null;
  onClickChooseImage?: (img: IMediaResponse | null) => void;
}

export function ImageInput(props: ImageInputProps) {
  const dispatch = useAppDispatch()
  const {
    ratio,
    imgSize,
    label,
    size,
    data,
    previousImageData,
    onClickChooseImage = () => {},
  } = props;
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  function onClickImageInput() {
    dispatch(fetchAllFolder({ limit: 15 }));
    setIsModalOpened(true);
  }

  return (
    <>
      <Container size={size}>
        {label && <LabelText>{label}</LabelText>}
        {data && data.imgUrl && data.imgAlt ? (
          <ImageWrapper onClick={() => onClickImageInput()}>
            {ratio ? (
              <AspectRatio ratio={ratio}>
                <Image src={data?.imgUrl} alt={data?.imgAlt} />
              </AspectRatio>
            ) : (
              <Image src={data?.imgUrl} alt={data?.imgAlt} />
            )}
            <Overlay isHasImage={Boolean(data)}>
              <OverlayText>Choose an image</OverlayText>
            </Overlay>
          </ImageWrapper>
        ) : ratio ? (
          <AspectRatio ratio={ratio}>
            <InputImageWrapper onClick={() => onClickImageInput()}>
              <IconWrapper>
                <FontAwesomeIcon icon={["far", "plus"]} />
              </IconWrapper>
              <ChooseImageText>Choose an image</ChooseImageText>
            </InputImageWrapper>
          </AspectRatio>
        ) : (
          <InputImageWrapper onClick={() => onClickImageInput()}>
            <IconWrapper>
              <FontAwesomeIcon icon={["far", "plus"]} />
            </IconWrapper>
            <UploadText>Choose an image</UploadText>
          </InputImageWrapper>
        )}
        {imgSize && (
          <DimentionWrapper>
            <DimentionText>Dimension: </DimentionText>
            <ImageSuggestionSizeText>{imgSize}</ImageSuggestionSizeText>
          </DimentionWrapper>
        )}
      </Container>
      <ImageInputModal
        isModalOpened={isModalOpened}
        onCloseModal={() => setIsModalOpened(false)}
        onClickChooseImage={(img) => {
          onClickChooseImage(img);
          setIsModalOpened(false);
        }}
        previousImageData={previousImageData}
      />
    </>
  );
}
