/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Badge as CoreBadge,
  BadgeProps as CoreBadgeProps,
  createStyles,
} from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

export type BadgeColorStyle = "red" | "green" | "blue" | "grey" | "yellow" | "warning"

const getTextColor = (theme: MantineTheme, color: BadgeColorStyle) => {
  switch (color) {
    case "red":
      return theme.colors.red;
    case "green":
      return theme.colors.green;
    case "blue":
      return theme.colors.blue;
    case "grey":
      return ["var(--grey700)", "var(--grey100)"];
    case "yellow":
      return ["#172B4D", "#FFF0B3"];
    case "warning":
      return ["#B54708", "#FFFAEB"];
    default:
      return theme.colors.grey;
  }
};
const useStyles = createStyles(
  (theme: MantineTheme, { color, padding }: BadgeProps) => ({
    badge: {
      backgroundColor: getTextColor(theme, color)[1],
      color: getTextColor(theme, color)[0],
      borderRadius: 16,
      fontWeight: 500,
      padding: padding ? padding : "2px 8px",
      fontSize: 12,
      width: "fit-content",
      textTransform: "capitalize",
    },
  })
);

export interface BadgeProps extends CoreBadgeProps {
  children: JSX.Element | string | number;
  color: BadgeColorStyle; // red | green | yellow | grey
  padding?: string;
}

/**
 * Documentation: {@link https://mantine.dev/core/badge/}
 */
export function Badge(props: BadgeProps) {
  const { classes } = useStyles(props);
  const { children } = props;

  return (
    <CoreBadge {...props} className={classes.badge}>
      {children}
    </CoreBadge>
  );
}
