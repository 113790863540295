import { useState, useEffect, ChangeEvent } from "react";
import { Modal } from "../../../organisms/modal/Modal";
import {
  mediaSelector,
  fetchImageList,
  addNewFile,
  fetchAllFolder,
  resetMedia,
} from "../../../store/features/media";
import {
  uploadSelector,
  setUploadingFolder,
  uploadFile,
  retryUploadFile,
  cancelUploadFile,
  clearUploaded,
  resetUpload,
} from "../../../store/features/upload";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IEmbeddedMedia, IMediaResponse } from "../../../types";
import { Breadcrumb } from "../../breadcrumb/Breadcrumb";
import { MediaItemData, MediaItem } from "../../mediaItem/MediaItem";
import { Pagination } from "../../pagination/Pagination";
import { SearchInput } from "../../searchInput/SearchInput";
import { UploadFileField } from "../../uploadFileField/UploadFileField";
import { UploadItem } from "../../uploadItem/UploadItem";
import { MediaList } from "../../../molecules/mediaList/MediaList";
import {
  ImageUploadModal,
  MediaItemContainer,
  PaginateWrapper,
  UploadTextWrapper,
  UploadText,
} from "./ImageInputModal.style";
import { useSearchParams } from "react-router-dom";
import { FolderList } from "../../../molecules/folderList/FolderList";
import { onCastEmbeddedImageDataToIMediaResponseData } from "../../../utils/castType";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ImageInputModalProps {
  isModalOpened: boolean;
  previousImageData?: IEmbeddedMedia | null;
  onCloseModal?: () => void;
  onClickChooseImage?: (img: IMediaResponse | null) => void;
}

export function ImageInputModal(props: ImageInputModalProps) {
  const {
    isModalOpened,
    previousImageData,
    onCloseModal = () => {},
    onClickChooseImage = () => {},
  } = props;

  const dispatch = useAppDispatch();
  const defaultBreadcrumb = [{ value: null, label: "All media" }];

  const [breadcrumbData, setBreadcrumbData] =
    useState<{ value: string | null; label: string }[]>(defaultBreadcrumb);

  // const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] =
    useState<IMediaResponse | null>(null);
  const [uploadFileCount, setUploadFileCount] = useState<number>(0);

  const { uploadCompleted, files, newFiles } = useAppSelector(uploadSelector);
  const { folderItems, paginationMediaItem } =
    useAppSelector(mediaSelector);

  const [searchParams] = useSearchParams({});
  const [searchValue, setSearchValue] = useState(searchParams.get("q"));
  const [searchFolder, setSearchFolder] = useState("");
  const [isShowUncategorizedFolder, setIsShowUncategorizedFolder] =
    useState<boolean>(true);

  useEffect(() => {
    const previousData =
      onCastEmbeddedImageDataToIMediaResponseData(previousImageData);
    if (previousData) {
      setSelectedImage(previousData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousImageData]);

  function onHandleSearch(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      if (selectedFolder) {
        dispatch(
          fetchImageList({
            q: searchValue,
            "parent-media-folder-id": selectedFolder,
            limit: 25,
          })
        );
      } else {
        // dispatch(fetchAllFolder({ q: searchValue, limit: 15 }));
        setSearchFolder(searchValue || "");
      }
      if (
        !(
          "uncategorized media".includes(searchValue?.toLowerCase() || "") ||
          searchValue === null
        )
      ) {
        setIsShowUncategorizedFolder(false);
      } else {
        setIsShowUncategorizedFolder(true);
      }
    }
  }

  function resetState() {
    setBreadcrumbData(defaultBreadcrumb);
    setSelectedFolder(null);
    setSelectedImage(null);
    setSearchValue(null);
    setSearchFolder('')
    setIsShowUncategorizedFolder(true);
  }

  function onHandleClickUncategorizedFolder() {
    setBreadcrumbData([
      breadcrumbData[0],
      { value: "null", label: "Uncategorized media" },
    ]);
    setSelectedFolder("null");
    setSearchValue(null);
    setIsShowUncategorizedFolder(true);
  }

  async function onFileUpload(filesList: FileList) {
    setUploadFileCount(Array.from(filesList).length);
    if (selectedFolder) dispatch(setUploadingFolder(selectedFolder));
    Array.from(filesList).forEach(async (item) => {
      await dispatch(
        uploadFile({
          file: item,
          selectedFolder,
        })
      );
    });
  }

  function onHandleUploadFailed(fileId: string) {
    dispatch(retryUploadFile({ fileId }));
  }

  function onHandleCancelUpload(fileId: string) {
    setUploadFileCount(uploadFileCount - 1);
    dispatch(cancelUploadFile(fileId));
  }

  function onMappingFolderItemsToMediaItemData(
    folderItems: IMediaResponse[]
  ): MediaItemData[] {
    return folderItems.slice(0, 25).map((item) => {
      return {
        src: item.urls?.original || "",
        alt: item.name,
        key: item.key,
      };
    });
  }

  function mappingMediaItemData() {
    const reactEle: React.ReactElement[] = [];
    if (files) {
      files.forEach((item, index) => {
        if (
          item &&
          `${item.uploadProgress.parentMediaFolderId}` === selectedFolder
        ) {
          reactEle.unshift(
            <UploadItem
              ringProgressProps={{
                size: 70,
                thickness: 6,
                roundCaps: true,
                sections: [
                  {
                    value: item.uploadProgress.uploadProgress,
                    color: "#155EEF",
                  },
                ],
              }}
              key={`${item.name || ""}-${index + 100}`}
              layout="A"
              uploadState={item.state}
              onClickUploadAgain={() => onHandleUploadFailed(item.fileId)}
              onClickCancel={() => onHandleCancelUpload(item.fileId)}
              uploadProgress={item.uploadProgress.uploadProgress}
              imgRatio={1 / 1}
              imgData={{
                src: item.file ? URL.createObjectURL(item.file) : "",
                alt: item.name || "",
              }}
            />
          );
        }
      });
    }

    const mediaItems = onMappingFolderItemsToMediaItemData(folderItems).map(
      (item, index) => (
        <MediaItem
          isShowSelecter
          layout="B"
          key={`${item?.key}-${item?.alt}-${index + 100}`}
          isSelected={Boolean(item.key === selectedImage?.key)}
          onClickMedia={() => {
            selectedImage?.key === folderItems[index].key
              ? setSelectedImage(null)
              : setSelectedImage(folderItems[index]);
          }}
          data={item}
          ratio={1 / 1}
        />
      )
    );
    return reactEle.concat(mediaItems).slice(0, 25);
  }

  useEffect(() => {
    if (selectedFolder) {
      dispatch(
        fetchImageList({
          q: searchValue,
          "parent-media-folder-id": selectedFolder,
          limit: 25,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolder]);

  useEffect(() => {
    if (newFiles.length && selectedFolder) {
      newFiles.forEach((item, index) => {
        dispatch(addNewFile(item.data));
        dispatch(clearUploaded({ index, fileId: item.fileId }));
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFiles]);

  useEffect(() => {
    if (
      uploadCompleted.length ===
        uploadFileCount -
          files.filter((item) => item.state === "error").length &&
      uploadCompleted.length > 0
    ) {
      dispatch(resetUpload());
      setUploadFileCount(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadCompleted]);

  function reset() {
    dispatch(resetMedia());
    dispatch(resetUpload());
  }

  return (
    <Modal
      onClose={() => {
        onCloseModal();
        reset();
        resetState();
        const previousData =
          onCastEmbeddedImageDataToIMediaResponseData(previousImageData);
        if (previousData) {
          setSelectedImage(previousData);
        }
      }}
      opened={Boolean(isModalOpened)}
      title="Choose an image"
      size={605}
      secondaryButtonTitle="Cancel"
      onClickSecondaryButton={() => {
        onCloseModal();
        resetState();
        const previousData =
          onCastEmbeddedImageDataToIMediaResponseData(previousImageData);
        if (previousData) {
          setSelectedImage(previousData);
        }
      }}
      onClickPrimaryButton={() => {
        onClickChooseImage(selectedImage !== null ? selectedImage : null);
        resetState();
      }}
      primaryButtonTitle={"Choose Media"}
      isDisablePrimaryButton={_.isEqual(
        selectedImage,
        onCastEmbeddedImageDataToIMediaResponseData(previousImageData) || null
      )}
    >
      <>
        <ImageUploadModal>
          <Breadcrumb
            separator={<FontAwesomeIcon icon={["fas", "angle-right"]} />}
            data={breadcrumbData}
            onClickBack={() => {
              resetState();
              dispatch(fetchAllFolder({ limit: 15 }));
            }}
          />
          <SearchInput
            onKeyDown={(e) => onHandleSearch(e)}
            value={searchValue || ""}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setSearchValue(event.target.value)
            }
          />
          {breadcrumbData.length <= 1 && (
            <FolderList
              onClickUncategorizedFolder={() =>
                onHandleClickUncategorizedFolder()
              }
              onClickFolder={(item) => {
                setBreadcrumbData([
                  breadcrumbData[0],
                  { value: item.id, label: item.name },
                ]);
                setSelectedFolder(item.id || null);
                setSearchValue(null);
                setIsShowUncategorizedFolder(true);
              }}
              isShowUncategorizedFolder={isShowUncategorizedFolder}
              isShowPagination
              search={searchFolder}
            />
          )}
          {selectedFolder && (
            <>
              <MediaItemContainer>
                <MediaList
                  limit={25}
                  column={5}
                  row={3}
                  columnWidth={"105px"}
                  data={mappingMediaItemData()}
                />
              </MediaItemContainer>
              <PaginateWrapper>
                <Pagination
                  page={
                    // selectedFolder
                    paginationMediaItem?.currentPage
                    // : pagination.currentPage
                  }
                  total={
                    // selectedFolder
                    paginationMediaItem?.lastPage || 1
                    // : pagination.lastPage
                  }
                  onChange={(e) =>
                    dispatch(
                      fetchImageList({
                        q: searchValue,
                        page: `${e}`,
                        "parent-media-folder-id": selectedFolder,
                        limit: 25,
                      })
                    )
                  }
                  // withControls={false}
                />
              </PaginateWrapper>
            </>
          )}
          {selectedFolder && (
            <>
              <UploadTextWrapper>
                <UploadText>Dont't have media you're looking for?</UploadText>
                <UploadFileField
                  textFont="md"
                  multipleFile
                  onFileUpload={(fileList) => onFileUpload(fileList)}
                  text={"Upload media"}
                  acceptType={"image/*"}
                />
              </UploadTextWrapper>
            </>
          )}
        </ImageUploadModal>
      </>
    </Modal>
  );
}
