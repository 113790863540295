// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { SortableEvent } from 'sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import {
  ContentCardContainer,
  ContentContainer,
  OtherContainer,
  OtherMenu,
  SectionTitle,
  SectionWrapper,
} from './EditorSidebar.style';
import { ContentCard } from '../../molecules/contentCard/ContentCard';
import { SectionManageDataSource } from '../sectionManageDataSource/SectionManageDataSource';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  setCurrentEditChildComponent, setCurrentEditType,
  setDataSources,
  setIsEnableSave,
} from '../../../store/features/editor';
import { ModalDelete } from '../modalDelete/ModalDelete';
import { ChildComponentProps, ComponentSpecProps, DataSourceProps, JSONComponentDataSourceProps, PublishedSectionProps } from '../../../types';
import { setShouldSaveDraft } from '../../../store/features/page';

interface SectionEditDataProps {
  data: Array<JSONComponentDataSourceProps>;
  title: string;
}

export const SectionEditData = (props: SectionEditDataProps) => {
  const { title, data: originalData } = props;

  const dispatch = useAppDispatch();
  const { currentEdit, dataSources, dataSourceIdList, dataSourceAutoList, editingDataSourceIndex } = useAppSelector((state) => state.editor);
  const [dataToDelete, setDataToDelete] = useState<JSONComponentDataSourceProps | null>(null);
  const [dataSourceList, setDataSourceList] = useState<Array<DataSourceProps>>([]);
  const [isReordering, setIsReordering] = useState<boolean>(false);

  const componentSpec = currentEdit.componentSpec as ComponentSpecProps;
  const hasAppearanceSettings = 'settings' in componentSpec;

  const onConfirmDelete = () => {
    if (dataToDelete) {
      const newData = [...dataSourceList].filter((item) => item.id !== dataToDelete.id);
      if (currentEdit.selectedChildIndex !== null && currentEdit.childComponent?.dataSources) {
        // ถ้ากำลังแก้ Child Component เช่น กรุ๊ปคอลัมน์พิเศษ ให้เซฟค่าที่ Child Component
        let newChild;
        if (currentEdit.type === 'data') {
          newChild = {
            ...currentEdit.childComponent,
            dataSources: newData,
          };
        } else {
          newChild = {
            ...currentEdit.childComponent,
            dataSources2: newData,
          };
        }
        dispatch(setCurrentEditChildComponent(newChild as PublishedSectionProps));
      } else {
        dispatch(setDataSources(newData));
      }
    }

    dispatch(setIsEnableSave(true));
    dispatch(setShouldSaveDraft(true));

    setDataToDelete(null);
  };

  const getDataWithId = (data: Array<DataSourceProps>) => (
    data.map((item) => {
      const isFakeId = (item.isFakeId === undefined && !item.id) || item.isFakeId; // IDs are now strings
      const id = isFakeId ? Math.random() : item.id;

      return {
        ...item,
        isFakeId,
        id,
      };
    })
  );

  useEffect(() => {
    if (isReordering || dataToDelete) {
      return;
    }

    const newData = currentEdit.childComponent
      ? currentEdit.childComponent.dataSources as Array<DataSourceProps>
      : dataSources as Array<DataSourceProps>;
    setDataSourceList(getDataWithId(newData));
  }, [currentEdit, dataToDelete, dataSources]);

  useEffect(() => {
    dispatch(setDataSources(originalData));
    const newData = currentEdit.childComponent
      ? currentEdit.childComponent.dataSources as Array<DataSourceProps>
      : originalData as Array<DataSourceProps>;
    setDataSourceList(getDataWithId(newData));

    return () => {
      setDataSourceList([]);
    };
  }, []);

  const onUpdateSort = (e: SortableEvent) => {
    // เซ็ตว่ากำลัง reorder จะได้ไม่โดน useEffect ด้านบนบันทึกข้อมูลทับ
    setIsReordering(true);
    const { oldIndex, newIndex } = e;
    if (oldIndex === undefined || newIndex === undefined) {
      return;
    }

    let clonedDataSourceList = [...dataSourceList];

    // ย้ายตำแหน่ง element ที่ถูกลากวาง
    const element = clonedDataSourceList[oldIndex];
    clonedDataSourceList.splice(oldIndex, 1);
    clonedDataSourceList.splice(newIndex, 0, element);

    // setDataSourceList(clonedDataSourceList);

    // เอา fake id ออกไปจาก Object ก่อนยิง API
    clonedDataSourceList = clonedDataSourceList.map(({ id, isFakeId, ...keepAttrs }) => {
      if (isFakeId) {
        return { ...keepAttrs };
      }
      return { ...keepAttrs, id };
    });

    if (currentEdit.selectedChildIndex !== null && currentEdit.childComponent?.dataSources) {
      // ถ้ากำลังแก้ Child Component เช่น กรุ๊ปคอลัมน์พิเศษ ให้เซฟค่าที่ Child Component
      let newChild: ChildComponentProps;
      if (currentEdit.type === 'data') {
        newChild = {
          ...currentEdit.childComponent,
          dataSources: clonedDataSourceList,
        };
      } else {
        newChild = {
          ...currentEdit.childComponent,
          dataSources2: clonedDataSourceList,
        };
      }
      dispatch(setCurrentEditChildComponent(newChild));
    } else {
      dispatch(setDataSources(clonedDataSourceList));
    }

    dispatch(setShouldSaveDraft(true));
    setIsReordering(false);
  };

  return (
    <>
      <ContentContainer>
        { dataSourceList && Boolean(dataSourceList.length) && (
          <>
            <SectionTitle>{title}</SectionTitle>
            <ContentCardContainer
              disabled={dataSourceList.length <= 1 || editingDataSourceIndex !== -1}
              list={dataSourceList}
              setList={setDataSourceList}
              onUpdate={onUpdateSort}>
              {
                dataSourceList.map((item: DataSourceProps, index: number) => {
                  const { id, query, sourceType } = item;

                  const getTitle = () => {
                    let foundSource = dataSourceAutoList.find((s) => s.sourceType === sourceType);

                    if (foundSource) {
                      const text = foundSource ? foundSource.displayName : 'แหล่งข้อมูล';
                      return `${text} ${query?.limit ? `(${query.limit})` : ''}`;
                    }

                    foundSource = dataSourceIdList.find((s) => s.sourceType === sourceType);
                    return foundSource ? foundSource.displayName : 'แหล่งข้อมูล';
                  };

                  return (
                    <ContentCard
                      data={item}
                      key={id || Math.random()}
                      dataSourceIndex={index}
                      title={getTitle()}
                      isEnableDrag={dataSourceList.length > 1 && editingDataSourceIndex === -1}
                      onDelete={() => setDataToDelete(item)} />
                  );
                })
              }
            </ContentCardContainer>
          </>
        ) }
        <SectionManageDataSource
          isEmptyData={!dataSourceList || !dataSourceList.length} />
        { dataToDelete && (
          <ModalDelete
            title="ยืนยันการลบ"
            description="คุณยืนยันที่จะลบคอนเทนต์นี้หรือไม่?"
            isOpen={Boolean(dataToDelete)}
            onConfirm={onConfirmDelete}
            onClose={() => setDataToDelete(null)} />
        ) }
      </ContentContainer>
      { Boolean(hasAppearanceSettings) && (
        <OtherContainer>
          <SectionWrapper>
            <SectionTitle>ตั้งค่าอื่น ๆ</SectionTitle>
            <OtherMenu onClick={() => { dispatch(setCurrentEditType('appearance')); }}>
              ตั้งค่าการแสดงผล
              <FontAwesomeIcon icon={['fas', 'chevron-right']} />
            </OtherMenu>
          </SectionWrapper>
        </OtherContainer>
      ) }
    </>
  );
};
