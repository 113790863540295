import { createReducer, combineReducers } from '@reduxjs/toolkit';
import { badgeReducer } from './badge';
import { brandReducer } from './brand';
import { facilityReducer } from './facility';
import { locationReducer } from './location';
import { mediaReducer } from './media';
import { productTypeReducer } from './productType';
import { projectReducer } from './project';
import { tagReducer } from './tag';
import { uploadReducer } from './upload';
import { houseCollectionReducer } from './houseCollection'
import { unitTypeReducer } from './unitType';
import { toastReducer } from './toast';
import { zoneReducer } from './zone';
import { userReducer } from './user';
import { postReducer } from './post';
import { pageReducer } from './page';
import { editorReducer } from './editor';
import { placeTypeReducer } from './placeType';

const combinedReducer = combineReducers({
    project: projectReducer,
    brand: brandReducer,
    productType: productTypeReducer,
    location: locationReducer,
    badge: badgeReducer,
    tag: tagReducer,
    facility: facilityReducer,
    upload: uploadReducer,
    media: mediaReducer,
    houseCollection: houseCollectionReducer,
    unitType: unitTypeReducer,
    toast: toastReducer,
    zone: zoneReducer,
    user: userReducer,
    post:postReducer,
    page: pageReducer,
    editor: editorReducer,
    placeType: placeTypeReducer,
});

const rootReducer = createReducer(combinedReducer(undefined, { type: '' }), (builder) => {
  builder
    .addDefaultCase(combinedReducer);
});

export default rootReducer;
