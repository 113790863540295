import styled from "@emotion/styled/macro";

export const Container = styled.div<{ maxHeight?: string; maxWidth?: string; gap?:string }>`
  display: flex;
  flex-direction: column;
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : "100%")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  width: 100%;
  gap: ${props => props.gap ? props.gap : "8px"};

  overflow: auto;
`;
