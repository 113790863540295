import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

export const Container = styled.div<{ isSidePanelActive: boolean }>`
  ${(props) =>
    props.isSidePanelActive
      ? css`
          display: grid;
          grid-template-columns: auto 350px;
          height: 100%;
          width: 100%;
        `
      : css`
          display: grid;
          grid-template-columns: auto;
        `}
`;

export const DataTableContainer = styled.div`
  max-height: 100vh;
  overflow: auto;
  padding: 0px 32px 32px;
`;

export const BadgeWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: flex-end;
`;
