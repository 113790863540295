import { Button, ButtonStyles } from "../../atoms/button/Button";
import { SectionContainer, SectionActionContainer, SectionHeaderContainer, SectionTitle, SectionFooterContainer, SectionTextContainer, SectionSubtitle } from "./Section.style";

interface SectionHeaderProps {
  title?: string;
  subtitle?: string;
  headerActionComponent?: React.ReactElement;
  headerButtonTitle?: string;
  headerButtonStyle?: ButtonStyles;
  isHeaderButtonDisabled?:boolean;
  highlightTitleText?:string;
  onClickHeaderButton?: () => void;
}

interface SectionFooterProps {
  footerActionComponent?: React.ReactElement;
  footerButtonTitle?: string;
  footerButtonStyle?: ButtonStyles;
  isFooterButtonDisabled?:boolean;
  onClickFooterButton?: () => void;
}

interface SectionProps extends SectionHeaderProps, SectionFooterProps {
  children?: React.ReactElement[] | React.ReactElement;
  isOverflowHidden?:boolean;
}

function SectionHeader (props: SectionHeaderProps) {
  const {
    subtitle = '',
    headerButtonTitle = '',
    headerButtonStyle = 'primary',
    onClickHeaderButton,
    isHeaderButtonDisabled,
    highlightTitleText,
    headerActionComponent: SectionHeaderAction } = props;
  let {title=''} = props
  title = highlightTitleText ? title?.replace(highlightTitleText, `<span class="highlight-text">${highlightTitleText}</span>`) : title
  const isShowHeaderButton = Boolean(headerButtonTitle && onClickHeaderButton)
  const isShowHeaderAction = Boolean(isShowHeaderButton || SectionHeaderAction)
  const isShowSectionHeader = Boolean(title || isShowHeaderAction )

  if (isShowSectionHeader) {
    return (
      <SectionHeaderContainer>
        <SectionTextContainer>
          <SectionTitle dangerouslySetInnerHTML={{ __html: title }} />
          <SectionSubtitle>{subtitle}</SectionSubtitle>
        </SectionTextContainer>
        {
          isShowHeaderAction && (
            <SectionActionContainer>
              {
                SectionHeaderAction ? (
                  <>
                  {SectionHeaderAction}
                  </>
                ) : isShowHeaderButton ? (
                  <Button onClick={onClickHeaderButton} buttonStyle={headerButtonStyle} disabled={isHeaderButtonDisabled}>
                    {headerButtonTitle}
                  </Button>
                ) : (
                  <></>
                )
              }
            </SectionActionContainer>
          )
        }
      </SectionHeaderContainer>
    )
  }
  return <></>
}

function SectionFooter (props: SectionFooterProps) {
  const {
    footerButtonTitle = '',
    footerButtonStyle = 'primary',
    onClickFooterButton,
    isFooterButtonDisabled,
    footerActionComponent: SectionFooterAction } = props;
  const isShowFooterButton = Boolean(footerButtonTitle && onClickFooterButton)
  const isShowFooterAction = Boolean(isShowFooterButton || SectionFooterAction)
  const isShowSectionHeader = Boolean(isShowFooterAction)

  if (isShowSectionHeader) {
    return (
      <SectionFooterContainer>
        {
          isShowFooterAction && (
            <SectionActionContainer>
              {
                SectionFooterAction ? (
                  <>
                  {SectionFooterAction}
                  </>
                ) : isShowFooterButton ? (
                  <Button onClick={onClickFooterButton} buttonStyle={footerButtonStyle} disabled={isFooterButtonDisabled}>
                    {footerButtonTitle}
                  </Button>
                ) : (
                  <></>
                )
              }
            </SectionActionContainer>
          )
        }
      </SectionFooterContainer>
    )
  }
  return <></>
}

export function Section (props: SectionProps) {
  const { isOverflowHidden, children = <></> } = props;

  return (
    <SectionContainer isOverflowHidden={isOverflowHidden}>
      <SectionHeader {...props} />
      { children }
      <SectionFooter {...props} />
    </SectionContainer>
  )
}