import { createAction } from "@reduxjs/toolkit";
import createAsyncThunk from "../../middleware/customCreateThunk";
import { loadAllZone } from "../../../services/api/zone";

export const fetchAllZone = createAsyncThunk({
  api: loadAllZone,
  EVENT_NAME: "Zone/loadAllZone",
});

export const resetZone = createAction("Zone/reset");
