import styled from "@emotion/styled/macro";

export const MediaCardWrapper = styled.div`
  cursor: pointer;
  padding: 0px 4px;

  &:first-of-type {
    padding-top: 4px;
  }

  &:last-of-type{
    padding-bottom: 4px;
  }
`;