import { ReactNode } from 'react';
import { MantineProvider } from '@mantine/core';
import { Global } from '@emotion/react';
import { config, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';

import { GlobalStyles } from './GlobalStyle.style';
import { NotificationsProvider } from '@mantine/notifications';

config.autoAddCss = false;
library.add(fas, far, fad);

interface GlobalStyleProps {
  /**
   * Children component
   */
  children: ReactNode;
}

const GlobalStyle = (props: GlobalStyleProps) => {
  const { children } = props;
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      withCSSVariables
      theme={{
        cursorType: 'pointer',
        breakpoints: {
          xs: 480,
          sm: 576,
          md: 768,
          lg: 992,
          xl: 1100
        },
        colors: {
          ci: ['#2970FF'],
          primary: ['#F5F8FF', '#EFF4FF', '#D1E0FF', '#B2CCFF', '#84ADFF', '#528BFF', '#2970FF', '#155EEF', '#004EEB', '#0040C1', '#00359E'] as any,
          grey: ['#FCFCFD', '#F9FAFB', '#F2F4F7', '#EAECF0', '#D0D5DD', '#98A2B3', '#667085', '#475467', '#344054', '#1D2939', '#101828'] as any,
          optional: ['#2f180f', '#422e27', '#694331', '#b23800', '#cb4000', '#d94c0c', '#ff6823', '#fff0ea', '#fff4ec', '#fffaf8'],
          red: ['#BF2600', '#FFEBE6'],
          green: ['#006644', '#E3FCEF'],
          blue: ['#2F80ED'],
          yellow: ['#FF8B00', '#FFFAE6'],
          navy: ['#42526E', '#DFE1E6'],
        },
        primaryColor: 'ci',
        spacing: {
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
          xl: 40,
        },
        fontFamily: 'IBM Plex Sans Thai',
        fontSizes: {
          xs: 8,
          sm: 12,
          md: 14,
          lg: 16,
          xl: 18,
        },
        headings: {
          fontFamily: 'IBM Plex Sans Thai',
          fontWeight: 600,
          sizes: {
            h1: {
              fontSize: 28,
              lineHeight: '160%',
            },
            h2: {
              fontSize: 26,
              lineHeight: '160%',
            },
            h3: {
              fontSize: 24,
              lineHeight: '160%',
            },
            h4: {
              fontSize: 20,
              lineHeight: '160%',
            },
            h5: {
              fontSize: 18,
              lineHeight: '160%',
            },
            h6: {
              fontSize: 18,
              lineHeight: '160%',
            },
          }
        }
      }}>
      <Global styles={GlobalStyles} />
      <NotificationsProvider position="bottom-right">
        {children}
      </NotificationsProvider>
    </MantineProvider>
  );
};

export default GlobalStyle;
