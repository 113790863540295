import styled from "@emotion/styled/macro";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 24px 24px;
`;

export const GroupInputWrapper = styled.div<{row?:number, column?:number}>`
  display: grid;
  grid-template-columns: repeat(${props => props.column ? props.column : 2}, 1fr);
  grid-template-rows: repeat(${props => props.row ? props.row : 2}, 1fr);
  gap: 24px;
  align-content: center;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

