/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Checkbox as CoreCheckbox,
  CheckboxProps,
  CheckboxProps as CoreCheckboxProps,
  createStyles,
} from "@mantine/core";
import { IconWrapper } from "./CheckBox.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = createStyles(() => ({
  root: {
    width: "100%",
    display: 'flex',
  },

  body: {
    width: "100%",
  },

  labelWrapper: {
    width: "100%",
  },

  input: {
    borderRadius: 4,
    border: "1px solid var(--grey300)",
    backgroundColor: "var(--white)",
    "&:checked": {
      border: "1px solid var(--primary600)",
      backgroundColor: "var(--primary50)",
    },

    "&:hover": {
      border: "1px solid var(--primary600)",
      backgroundColor: "var(--primary50)",
    },

    "&:disabled": {
      border: "1px solid var(--grey300) !important",
      backgroundColor: "var(--grey100) !important",
      cursor: "default",
    },
  },
}));

/**
 * Documentation: {@link https://mantine.dev/core/Checkbox/}
 */
export function Checkbox(props: CoreCheckboxProps) {
  const { classes } = useStyles();
  const { children, icon, ...rest } = props;

  const CheckboxIcon: CheckboxProps["icon"] = ({ indeterminate, className }) =>
    indeterminate ? (
      <IconWrapper className={className}>
        <FontAwesomeIcon icon={["fas", "minus"]} />
      </IconWrapper>
    ) : (
      <IconWrapper className={className}>
        <FontAwesomeIcon icon={["fas", "check"]} />
      </IconWrapper>
    );

  return (
    <CoreCheckbox {...rest} classNames={classes} icon={CheckboxIcon}>
      {children}
    </CoreCheckbox>
  );
}
