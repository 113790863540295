import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams, useSearchParams, useLocation } from "react-router-dom";
import { HeaderCell } from "../../atoms/headerCell/HeaderCell";
import { Skeleton } from "../../atoms/loading/skeleton/Skeleton";
import { TextCell } from "../../atoms/textCell/TextCell";
import { TextInput } from "../../atoms/textInput/TextInput";
import { Column } from "../../molecules/column/Column";
import { ContentDivider } from "../../molecules/contentDivider/ContentDivider";
import { CopyToClipboard } from "../../molecules/copyToClipboard/CopyToClipboard";
import { DropdownButton } from "../../molecules/dropdownButton/DropdownButton";

import { Row } from "../../molecules/row/Row";
import { ConfirmModal } from "../../organisms/confirmModal/ConfirmModal";
import { EmptyMedia } from "../../organisms/emptyMedia/EmptyMedia";
import { Modal } from "../../organisms/modal/Modal";
import {
  InfoTextHolder,
  MainText,
  SubText,
} from "../../organisms/modal/modalEditMedia/ModalEditMedia.style";
import {
  mediaSelector,
  fetchAttachmentList,
  moveAttachment,
  updateAttachmentById,
  fetchFolderById,
  deleteAttachmentById,
} from "../../store/features/media";
import { addToast } from "../../store/features/toast";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ManageMedia } from "../../templates/manageMedia/ManageMedia";
import { IMediaResponse } from "../../types";
import { onClickRow } from "../../utils/dataTable";
import { formatFullDateTime } from "../../utils/format";
import {
  TotalText,
  MainContentWrapper,
  IconWrapper,
  EditModalWrapper,
  FileInfoContainer,
} from "./AttachmentDetail.style";

export function AttachmentDetail() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const {
    folderItems,
    currentFolder,
    currentFolderId,
    loadFolderItemState,
    loadFolderByIdState,
    updateAttachmentState,
    moveAttachmentState,
    deleteAttachmentState,
  } = useAppSelector(mediaSelector);
  const parentFolderMediaId = id === "uncategorized" ? "null" : id;

  const [searchParams] = useSearchParams();
  const [selectedFile, setSelectedFile] = useState<IMediaResponse | null>(null);

  const [isShowModalEditFile, setIsShowModalEditFile] =
    useState<boolean>(false);
  const [isShowModalDeleteModal, setIsShowModalDeleteModal] =
    useState<boolean>(false);
  const [moveFolderModalOpened, setMoveFolderModalOpened] =
    useState<boolean>(false);

  function onHandleChangeSelectedFile(path: string, value: any) {
    if (selectedFile !== null) {
      const clonedData = _.cloneDeep(selectedFile);
      _.set(clonedData, path, value);
      setSelectedFile(clonedData);
    }
  }

  async function onHandleUpdateFile(fileId?: string) {
    await dispatch(
      updateAttachmentById({ id: fileId, data: { ...selectedFile } })
    );
    setIsShowModalEditFile(false);
    setSelectedFile(null);
    dispatch(
      fetchAttachmentList({
        q: searchParams.get("q"),
        page: searchParams.get("page"),
        "parent-media-folder-id": parentFolderMediaId,
      })
    );
  }

  async function onHandleDeleteFile(fileId?: string) {
    await dispatch(deleteAttachmentById(fileId));
  }

  async function onHandleMove(
    data: IMediaResponse,
    destinationMediaFolderId: string
  ) {
    await dispatch(
      moveAttachment({
        data: {
          attachmentIds: [data?.id],
          destinationMediaFolderId:
            destinationMediaFolderId === "null"
              ? null
              : destinationMediaFolderId,
        },
      })
    );
  }

  useEffect(() => {
    const status = deleteAttachmentState.status;
    if (status === "success") {
      setIsShowModalDeleteModal(false);
      setSelectedFile(null);
      dispatch(fetchFolderById(parentFolderMediaId));
      dispatch(
        fetchAttachmentList({
          q: searchParams.get("q"),
          page: searchParams.get("page"),
          "parent-media-folder-id": parentFolderMediaId,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAttachmentState.status]);

  useEffect(() => {
    const status = deleteAttachmentState.status;
    if (status === "success")
      dispatch(addToast({ status, title: "Successfully deleted" }));
    if (status === "error")
      dispatch(addToast({ status, title: "Delete failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAttachmentState.status, dispatch, location.pathname]);

  useEffect(() => {
    const status = updateAttachmentState.status;
    if (status === "success") dispatch(addToast({ status }));
    if (status === "error")
      dispatch(addToast({ status, title: "Update failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAttachmentState.status, dispatch, location.pathname]);

  useEffect(() => {
    const status = moveAttachmentState.status;
    if (status === "success") {
      setMoveFolderModalOpened(false);
      dispatch(
        fetchAttachmentList({
          q: searchParams.get("q"),
          page: searchParams.get("page"),
          "parent-media-folder-id": parentFolderMediaId,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveAttachmentState.status]);

  useEffect(() => {
    const status = moveAttachmentState.status;
    if (status === "success")
      dispatch(addToast({ status, title: "Successfully moved" }));
    if (status === "error")
      dispatch(addToast({ status, title: "Move failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveAttachmentState.status, dispatch, location.pathname]);

  return (
    <>
      {selectedFile && (
        <ConfirmModal
          primaryButtonTitle={"Delete"}
          onClose={() => {
            setIsShowModalDeleteModal(false);
          }}
          title="Delete File"
          opened={isShowModalDeleteModal}
          onClickPrimaryButton={() => {
            onHandleDeleteFile(selectedFile?.id || "");
          }}
        >
          <div>Are you sure you want to delete this file?</div>
          <div style={{ marginTop: "16px" }}>
            Once you confirm, this file will be deleted from the system but not
            removed from the contents that used it.
          </div>
        </ConfirmModal>
      )}
      <Modal
        size={600}
        title="Edit Attachment"
        opened={isShowModalEditFile}
        onClose={() => setIsShowModalEditFile(false)}
        primaryButtonTitle="Save"
        onClickPrimaryButton={() => onHandleUpdateFile(selectedFile?.id)}
        secondaryButtonTitle="Cancel"
        onClickSecondaryButton={() => {
          setIsShowModalEditFile(false);
        }}
      >
        <EditModalWrapper>
          <TextInput
            label={"Name"}
            value={selectedFile?.name || ""}
            onChange={(e) => onHandleChangeSelectedFile("name", e.target.value)}
          />
          <CopyToClipboard
            label={"URL"}
            value={selectedFile?.originalUrl || ""}
          />
        </EditModalWrapper>
        <div style={{ margin: "32px 0" }}>
          <ContentDivider />
        </div>
        <EditModalWrapper>
          <FileInfoContainer>
            <InfoTextHolder>
              <MainText>File Type</MainText>
              <SubText>{selectedFile?.mimeType || ""} </SubText>
            </InfoTextHolder>
            <InfoTextHolder>
              <MainText>Updated at</MainText>
              <SubText>
                {formatFullDateTime(new Date(selectedFile?.updatedAt || ""))}{" "}
              </SubText>
            </InfoTextHolder>
            <InfoTextHolder>
              <MainText>Uploaded at</MainText>
              <SubText>
                {formatFullDateTime(new Date(selectedFile?.createdAt || ""))}{" "}
              </SubText>
            </InfoTextHolder>
          </FileInfoContainer>
        </EditModalWrapper>
      </Modal>
      <ManageMedia
        isAttachment
        onCloseMoveFolderModal={() => setMoveFolderModalOpened(false)}
        moveFolderModalOpened={moveFolderModalOpened}
        onMoveMedia={(destinationMediaFolderId) =>
          selectedFile && onHandleMove(selectedFile, destinationMediaFolderId)
        }
        supportingType={"PDF"}
        dataLoaderAfterCloseModalUpload={() => {
          dispatch(
            fetchAttachmentList({
              q: searchParams.get("q"),
              page: searchParams.get("page"),
              "parent-media-folder-id": parentFolderMediaId,
            })
          );
        }}
        pathToSearch={`/attachments/folder/${currentFolderId}`}
        fetchData={(id, filter) =>
          dispatch(
            fetchAttachmentList({ ...filter, "parent-media-folder-id": id })
          )
        }
        pathToNavigateAfterAnAction={"/attachments"}
        breadcrumbLabel="Attachments"
        additionalInfoElement={
          <TotalText>Total {currentFolder?.attachmentsCount} items</TotalText>
        }
        tableHeader={
          <Row>
            <Column>
              <HeaderCell title="Name" />
            </Column>
            <Column maxWidth="300px">
              <HeaderCell title="Updated At" />
            </Column>
            <Column maxWidth="44px">
              <></>
            </Column>
          </Row>
        }
      >
        <>
          {loadFolderByIdState.status === "success" ? (
            currentFolder?.attachmentsCount ? (
              loadFolderItemState.status === "success" ? (
                folderItems.map((data, index) => (
                  <Row
                    key={data.id + index + 1}
                    onClick={(event: React.MouseEvent<HTMLElement>) =>
                      onClickRow(event, () => {
                        setIsShowModalEditFile(true);
                        setSelectedFile(data);
                      })
                    }
                  >
                    <Column>
                      <MainContentWrapper>
                        <IconWrapper>
                          <FontAwesomeIcon icon={["far", "file"]} />
                        </IconWrapper>
                        <TextCell primaryText={data?.name || ""} />
                      </MainContentWrapper>
                    </Column>
                    <Column maxWidth="300px">
                      <TextCell
                        text={formatFullDateTime(
                          new Date(data?.updatedAt || "")
                        )}
                      />
                    </Column>
                    <Column maxWidth="44px">
                      <DropdownButton
                        position={
                          folderItems.length > 4
                            ? index < folderItems.length - 3
                              ? "bottom"
                              : "top"
                            : "bottom"
                        }
                        buttonProps={{
                          children: (
                            <FontAwesomeIcon
                              icon={["far", "ellipsis-vertical"]}
                            />
                          ),
                          buttonStyle: "text-link-grey",
                          fontSize: "sm",
                          fullWidth: true,
                        }}
                        dropdownMenuProps={{
                          maxWidth: "240px",
                          padding: "16px",
                          data: [
                            {
                              icon: ["far", "pen"],
                              label: "Edit",
                              onClick: () => {
                                setIsShowModalEditFile(true);
                                setSelectedFile(data);
                              },
                            },
                            {
                              icon: ["far", "folder"],
                              label: "Move to ...",
                              onClick: () => {
                                setMoveFolderModalOpened(true);
                                setSelectedFile(data);
                              },
                            },
                            {
                              icon: ["far", "trash-can"],
                              label: "Delete",
                              onClick: () => {
                                setIsShowModalDeleteModal(true);
                                setSelectedFile(data);
                              },
                            },
                          ],
                        }}
                      />
                    </Column>
                  </Row>
                ))
              ) : (
                <></>
              )
            ) : (
              <EmptyMedia
                dataLoaderAfterCloseUpload={() => {
                  dispatch(
                    fetchAttachmentList({
                      "parent-media-folder-id": parentFolderMediaId,
                    })
                  );
                }}
                parentFolderId={parentFolderMediaId}
                supportingType={"PDF"}
                isAttachment
              />
            )
          ) : (
            <Skeleton height={150} radius={0} />
          )}
        </>
      </ManageMedia>
    </>
  );
}
