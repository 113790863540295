import { PostListLayout } from "../../../templates/postListLayout/PostListLayout";

export function CollectionList() {
  return (
    <PostListLayout
      pageHeader="Collection"
      pathname="/collection"
      categoryKey="collection"
    />
  );
}
