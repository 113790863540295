import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { HeaderCell } from "../../atoms/headerCell/HeaderCell";
import { TextCell } from "../../atoms/textCell/TextCell";
import { Column } from "../../molecules/column/Column";
import { Row } from "../../molecules/row/Row";
import { DataTable } from "../../organisms/dataTable/DataTable";
import { Heading } from "../../organisms/heading/Heading";
import { Section } from "../../organisms/section/Section";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  deleteFolder,
  fetchAllFolder,
  fetchFoldersForListPage,
  fetchUncategorizedFolder,
  mediaSelector,
  resetFolderDetailState,
  resetMedia,
  updateFolder,
} from "../../store/features/media";
import { formatFullDateTime } from "../../utils/format";
import {
  ButtonGroup,
  Container,
  IconWrapper,
  FlexWrapper,
  IconWrapperGrey,
  InputWrapper,
} from "./MediaListLayout.style";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ModalUploadWithFolderSelection } from "../../organisms/modal/modalUpload/ModalUploadWithFolderSelection";
import { onClickRow } from "../../utils/dataTable";
import { ModalNewFolder } from "../../organisms/modal/modalNewFolder/ModalNewFolder";
import { DropdownButton } from "../../molecules/dropdownButton/DropdownButton";
import { ConfirmModal } from "../../organisms/confirmModal/ConfirmModal";
import { IFolder } from "../../types";
import { Modal } from "../../organisms/modal/Modal";
import { TextInput } from "../../atoms/textInput/TextInput";
import { onHandleChangeEditableData } from "../../utils/dataService";
import qs from "qs";
import { addToast } from "../../store/features/toast";
import { Skeleton } from "../../atoms/loading/skeleton/Skeleton";

interface MediaListLayoutProps {
  pageTitle: string;
  path: string;
  isAttachment?: boolean;
  supportingType?: string;
}

export function MediaListLayout(props: MediaListLayoutProps) {
  const { pageTitle, path, isAttachment, supportingType } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const uncategorizedFileName = isAttachment
    ? "Uncategorized attachments"
    : "Uncategorized media";

  const {
    foldersForListPage,
    uncategorizedFolder,
    pagination,
    updateFolderState,
    deleteFolderState,
    loadFolderForPageListState,
  } = useAppSelector(mediaSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectFolder, setSelectFolder] = useState<IFolder | null>(null);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isShowRenameModal, setIsShowRenameModal] = useState<boolean>(false);

  const [isShowUncategorizedFolder, setIsShowUncategorizedFolder] =
    useState<boolean>(true);

  async function onHandleDeleteFolder(folderId: string) {
    await dispatch(deleteFolder(folderId));
    setSelectFolder(null);
  }

  async function onChangeFolderName(e: string) {
    setSelectFolder(onHandleChangeEditableData(selectFolder, "name", e));
  }

  async function onHandleRenameFolder(data: IFolder) {
    await dispatch(updateFolder({ id: data.id, data: { ...data } }));
    setIsShowRenameModal(false);
    setSelectFolder(null);
  }

  function loadData() {
    dispatch(
      fetchFoldersForListPage({
        q: searchParams.get("q"),
        page: searchParams.get("page"),
        limit: 15,
      })
    );

    dispatch(fetchUncategorizedFolder("null"));
  }

  useEffect(() => {
    if (!Boolean(searchParams.get("page"))) {
      searchParams.set("page", pagination?.currentPage.toString() || "1");
      setSearchParams(searchParams);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("page")]);

  useEffect(() => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    loadData();

    return () => {
      dispatch(resetFolderDetailState());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, dispatch]);

  useEffect(() => {
    const status = updateFolderState.status;
    if (status === "success") {
      dispatch(addToast({ status }));
      loadData();
    }
    if (status === "error")
      dispatch(addToast({ status, title: "Update failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateFolderState.status, dispatch, location.pathname]);

  useEffect(() => {
    const status = deleteFolderState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully deleted" }));
      loadData();
    }
    if (status === "error")
      dispatch(addToast({ status, title: "Delete failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteFolderState.status, dispatch, location.pathname]);

  return (
    <Container>
      <Heading
        title={pageTitle}
        actionComponent={
          <ButtonGroup>
            <ModalNewFolder pathToRedirect={path} />
            <ModalUploadWithFolderSelection
              modalUploadHeader={`Upload or Browse ${
                path.slice(0, 1).toUpperCase() + path.slice(1)
              }`}
              modalFolderDescription={`Choose a target folder you want to upload your ${path} into`}
              isAttachment={isAttachment}
              supportingType={supportingType}
            />
          </ButtonGroup>
        }
      />
      {selectFolder && (
        <>
          <ConfirmModal
            primaryButtonTitle={"Delete"}
            onClose={() => {
              setIsShowDeleteModal(false);
              setSelectFolder(null);
            }}
            title="Delete Folder"
            opened={isShowDeleteModal}
            onClickPrimaryButton={() => {
              onHandleDeleteFolder(selectFolder.id);
              setIsShowDeleteModal(false);
            }}
          >
            <div>
              Are you sure you want to delete <b>‘{selectFolder.name}’</b>?
            </div>
            <div style={{ marginTop: "16px" }}>
              Once you confirm, this folder and all files inside will be deleted
              from the list but the files will still be available in the contents that
              used it.
            </div>
          </ConfirmModal>
          <Modal
            onClose={() => {
              setIsShowRenameModal(false);
              setSelectFolder(null);
            }}
            opened={Boolean(isShowRenameModal)}
            title="Rename Folder"
            size={400}
            onClickPrimaryButton={() => onHandleRenameFolder(selectFolder)}
            onClickSecondaryButton={() => setIsShowRenameModal(false)}
            primaryButtonTitle={"Rename"}
            secondaryButtonTitle={"Cancel"}
          >
            <>
              <InputWrapper>
                <TextInput
                  data-autofocus
                  value={selectFolder.name}
                  onChange={(e) => onChangeFolderName(e.target.value)}
                />
              </InputWrapper>
            </>
          </Modal>
        </>
      )}
      <Section>
        <DataTable
          onSearchData={(value) => {
            searchParams.set("q", value);
            setSearchParams(searchParams);
            if (
              !(
                uncategorizedFileName
                  .toLowerCase()
                  .includes(searchParams.get("q")?.toLowerCase() || "") ||
                searchParams.get("q") === ""
              )
            ) {
              setIsShowUncategorizedFolder(false);
            } else {
              setIsShowUncategorizedFolder(true);
            }
          }}
          currentPage={parseInt(searchParams.get("page") as string, 10) || 1}
          totalPages={pagination.total && pagination.lastPage}
          onChangePage={(page) =>
            navigate({
              pathname: `/${path}`,
              search: qs.stringify(
                {
                  q: searchParams.get("q") || undefined,
                  page,
                },
                { addQueryPrefix: true, skipNulls: true }
              ),
            })
          }
          header={
            <Row>
              <Column>
                <HeaderCell title="Name" />
              </Column>
              <Column maxWidth="120px">
                <HeaderCell title="Amount" />
              </Column>
              <Column maxWidth="160px">
                <HeaderCell title="Updated At" />
              </Column>
              <Column maxWidth="44px">
                <></>
              </Column>
            </Row>
          }
        >
          <>
            {isShowUncategorizedFolder &&
              Boolean(searchParams.get("page") === "1") && (
                <Row
                  key={`uncategorized-${path}`}
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    onClickRow(event, () =>
                      navigate(`/${path}/folder/uncategorized`)
                    )
                  }
                  height={"78px"}
                >
                  <Column>
                    <FlexWrapper>
                      <IconWrapperGrey>
                        <FontAwesomeIcon icon={["far", "inbox"]} />
                      </IconWrapperGrey>
                      <TextCell primaryText={`Uncategorized ${path}`} />
                    </FlexWrapper>
                  </Column>
                  <Column maxWidth="120px">
                    <TextCell
                      text={
                        `${
                          isAttachment
                            ? uncategorizedFolder?.attachmentsCount
                            : uncategorizedFolder?.imagesCount
                        } item${
                          (isAttachment
                            ? uncategorizedFolder?.attachmentsCount
                            : uncategorizedFolder?.imagesCount) === 1
                            ? ""
                            : "s"
                        }` || ""
                      }
                    />
                  </Column>
                  <Column maxWidth="160px">
                    <TextCell
                      text={formatFullDateTime(
                        new Date(uncategorizedFolder?.updatedAt || "")
                      )}
                    />
                  </Column>
                  <Column maxWidth="44px">
                    <></>
                  </Column>
                </Row>
              )}
            {loadFolderForPageListState.status === "success" && foldersForListPage ? (
              foldersForListPage.map((item, index) => (
                <Row
                  key={`${item.name}-${index + 1000}`}
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    onClickRow(event, () =>
                      navigate(`/${path}/folder/${item.id}`)
                    )
                  }
                  height={"78px"}
                >
                  <Column>
                    <FlexWrapper>
                      <IconWrapper>
                        <FontAwesomeIcon icon={["far", "folder"]} />
                      </IconWrapper>
                      <TextCell primaryText={item.name} />
                    </FlexWrapper>
                  </Column>
                  <Column maxWidth="120px">
                    <TextCell
                      text={
                        `${
                          isAttachment
                            ? item?.attachmentsCount
                            : item?.imagesCount
                        } item${
                          (isAttachment
                            ? item?.attachmentsCount
                            : item?.imagesCount) === 1
                            ? ""
                            : "s"
                        }` || ""
                      }
                    />
                  </Column>
                  <Column maxWidth="160px">
                    <TextCell
                      text={formatFullDateTime(new Date(item.updatedAt || ""))}
                    />
                  </Column>
                  <Column maxWidth="44px">
                    <DropdownButton
                      position={
                        foldersForListPage.length > 6
                          ? index < foldersForListPage.length - 3
                            ? "bottom"
                            : "top"
                          : "bottom"
                      }
                      buttonProps={{
                        children: (
                          <FontAwesomeIcon
                            icon={["far", "ellipsis-vertical"]}
                          />
                        ),
                        buttonStyle: "text-link-grey",
                        fontSize: "sm",
                        fullWidth: true,
                      }}
                      dropdownMenuProps={{
                        maxWidth: "240px",
                        padding: "16px",
                        data: [
                          {
                            icon: ["far", "pen"],
                            label: "Rename folder",
                            onClick: () => {
                              setIsShowRenameModal(true);
                              setSelectFolder(item);
                            },
                          },
                          {
                            icon: ["far", "trash-can"],
                            label: "Delete folder",
                            onClick: () => {
                              setIsShowDeleteModal(true);
                              setSelectFolder(item);
                            },
                          },
                        ],
                      }}
                    />
                  </Column>
                </Row>
              ))
            ) : (
              <Skeleton height={"250px"} radius={0} />
            )}
          </>
        </DataTable>
      </Section>
    </Container>
  );
}
