import { initialPagination, ITerm, PaginationType } from ".";
import { getStateObject } from "../utils/stateManagement";
import { IEmbeddedMedia } from "./image";
import { StatusState } from "./state";

export interface IPost {
  id: string;
  languageCode: string;
  title: string;
  description: string;
  type: string;
  categoryKey: string;
  slugCode: string;
  defaultSlugCode: string;
  openGraphImage: IEmbeddedMedia | null;
  banners: IEmbeddedMedia[];
  thumbnails: IEmbeddedMedia[];
  galleries: IEmbeddedMedia[];
  postBodyContents: PostBodyContents[];
  availableLanguages: string[];
  publishStatus: string;
  visibilityStatus: string;
  seoTitle: string;
  seoDescription: string;
  termIds: { optionIds: string[] };
  terms: { options: ITerm[] };
  ogTitle: string;
  ogDescription: string;
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
}
export interface IPostPromotion extends IPost {
  subtitle: string;
  termsConditions: string;
  galleries: IEmbeddedMedia[];
  banners: IEmbeddedMedia[];
}

export interface IPostPromotionEditable
  extends Omit<
    IPostPromotion,
    "id" | "createdAt" | "updatedAt" | "publishedAt" | "terms"
  > {}

export type PostBodyContents = {
  type: string;
  contents: (string | IEmbeddedMedia)[];
};

export interface IPostEditable
  extends Omit<
    IPost,
    "id" | "createdAt" | "updatedAt" | "publishedAt" | "terms"
  > {}

export const initialPostEditData = {
  languageCode: "th",
  title: "",
  description: "",
  seoTitle: "",
  seoDescription: "",
  ogTitle: "",
  ogDescription: "",
  type: "",
  categoryKey: "",
  slugCode: "",
  defaultSlugCode: "",
  openGraphImage: null,
  termIds: { optionIds: [] },
  banners: [],
  thumbnails: [],
  galleries: [],
  postBodyContents: [],
  availableLanguages: [],
  publishStatus: "published",
  visibilityStatus: "public",
};

export interface PostListInitialStateProps {
  postList: IPost[];
  postOptions: ITerm[];
  loadPostListState: StatusState;
  pagination: PaginationType;
}

export const initialPostsListState = {
  postList: [],
  loadPostListState: getStateObject({ type: "idle" }),
  pagination: initialPagination,
  postOptions: [],
};

export interface PostDetailInitialStateProps {
  currentPost: { [key: string]: any } | null;
  postEditData: { [key: string]: any } | null;
  newPost: { [key: string]: any } | null;
  // currentPostPromotion: IPostPromotionEditable | null;
  loadPostByIdState: StatusState;
  createPostState: StatusState;
  updatePostState: StatusState;
  deletePostState: StatusState;
  loadPostOptionsState: StatusState;
}

export const initialPostDetailState = {
  currentPost: null,
  postEditData: null,
  newPost: null,
  loadPostByIdState: getStateObject({ type: "idle" }),
  createPostState: getStateObject({ type: "idle" }),
  updatePostState: getStateObject({ type: "idle" }),
  deletePostState: getStateObject({ type: "idle" }),
  loadPostOptionsState: getStateObject({ type: "idle" }),
};

export type PostQueryParams = {
  page?: string;
  q?: string;
  limit?: string;
  "category-key"?: string;
};
