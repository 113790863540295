import styled from "@emotion/styled/macro";

export const NearbyModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HeaderTable = styled.div`
  display: flex;
  width: 100%;
  height: 52px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: var(--grey100);
`;

export const HeaderText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--grey900);
`;

export const DropdownWrapper = styled.div`
  display: flex;
  width: 44px;
`;

export const ButtonGroup = styled.div<{ gap?: string }>`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.gap ? props.gap : "8px")};
`;

export const ContentWrapper = styled.div`
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const PlaceListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--grey50);
  padding: 16px;
  border-radius: 12px;
`;

export const HintText = styled.div`
  color: #475467;
`;

export const PlaceTypeWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
`;

export const DeleteButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 16px;
`;
