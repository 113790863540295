import { Modal, ModalProps } from "../modal/Modal";
import { ChildrenWrapper } from "./ConfirmModal.style";

export function ConfirmModal(props: ModalProps) {
  const {
    onClose = () => {},
    primaryButtonStyle = 'danger',
    primaryButtonTitle = 'Delete',
    secondaryButtonTitle = 'Cancel',
    isDisablePrimaryButton = false,
    opened = false,
    title = "",
    children = <></>,
    onClickPrimaryButton = () => {},
    onClickSecondaryButton = onClose
  } = props;
  return (
    <Modal
      onClose={onClose}
      opened={opened}
      title={title}
      primaryButtonTitle={primaryButtonTitle}
      primaryButtonStyle={primaryButtonStyle}
      secondaryButtonTitle={secondaryButtonTitle}
      isDisablePrimaryButton={isDisablePrimaryButton}
      onClickPrimaryButton={onClickPrimaryButton}
      onClickSecondaryButton={onClickSecondaryButton}
      size={400}
    >
      <ChildrenWrapper>
        {children}
      </ChildrenWrapper>
    </Modal>
  );
}
