import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  IconContainer,
  MenuItemContainer,
  SubMenuItemContainer,
  MainMenuContainer,
  SubMenuWrapper,
  IconWrapper,
} from "./MenuItem.style";

export interface MenuItemProps {
  title: string;
  icon?: React.ReactElement;
  isActive?: boolean;
  url?: string;
  subMenu?: MenuItemProps[];
  onClick?: () => void;
}

function MenuItemComponent(props: MenuItemProps) {
  const { icon, title = "", isActive = false } = props;
  return (
    <MainMenuContainer isActive={isActive} onClick={props.onClick}>
      <MenuItemContainer>
        {Boolean(icon) && <IconContainer>{icon}</IconContainer>}
        {title}
      </MenuItemContainer>
      {props.subMenu && (
        <IconWrapper isActive={isActive}>
          <FontAwesomeIcon icon={["far", "chevron-up"]} />
        </IconWrapper>
      )}
    </MainMenuContainer>
  );
}

function SubMenuItemComponent(props: MenuItemProps) {
  const { icon, title = "", isActive = false } = props;
  return (
    <SubMenuItemContainer isActive={isActive}>
      {Boolean(icon) && <IconContainer>{icon}</IconContainer>}
      {title}
    </SubMenuItemContainer>
  );
}

export function MenuItem(props: MenuItemProps) {
  const { pathname } = useLocation();

  const { url = "", subMenu } = props;
  const [mainMenuActive, setMainMenuActive] = useState<boolean>(false);
  const [subMenuActive, setSubMenuActive] = useState<boolean>(false);

  const [currentPath, setCurrentPath] = useState<string>(pathname);

  const getIfIsActive = (url: string) => {
    if (currentPath === "/" && url === "/") {
      return true;
    }

    if (currentPath === "/" && url !== "/") {
      return false;
    }
    if ("/" + currentPath.split("/")[1] === url && url !== "/") {

      return true;
    }

    if (currentPath === url && url !== "/") {
      return true;
    }

    return;
  };

  useEffect(() => {
    setCurrentPath(pathname);
  }, [pathname]);

  function subMenuElement() {
    if (subMenu) {
      return subMenu.map((menu, index) =>
        menu.url ? (
          <Link to={menu.url} key={`${menu.title}-${index + 100}-sub-menu`}>
            <SubMenuItemComponent
              {...menu}
              isActive={getIfIsActive(menu.url)}
            />
          </Link>
        ) : (
          <SubMenuItemComponent
            key={`${menu.title}-${index + 100}-sub-menu`}
            {...menu}
            isActive={subMenuActive}
            onClick={() => setSubMenuActive(true)}
          />
        )
      );
    }
  }

  if (url) {
    return (
      <Link to={url}>
        <MenuItemComponent {...props} isActive={getIfIsActive(url)} />
      </Link>
    );
  }
  return (
    <>
      <MenuItemComponent
        {...props}
        isActive={mainMenuActive}
        onClick={() => setMainMenuActive(!mainMenuActive)}
      />
      {props.subMenu && (
        <SubMenuWrapper isActive={mainMenuActive}>
          {subMenuElement()}
        </SubMenuWrapper>
      )}
    </>
  );
}
