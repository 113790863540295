import { BaseErrorMessage, ErrorMessageButton } from '../../molecules/baseErrorMessage/BaseErrorMessage';

interface EmptyMessageProps {
  title?: string;
  description?: string;
  button?: ErrorMessageButton;
}

export const EmptyMessage = (props: EmptyMessageProps) => {
  const {
    title = 'ไม่พบข้อมูล',
    description = '',
    button,
  } = props;

  return (
    <BaseErrorMessage
      icon="inbox"
      title={title}
      description={description}
      button={button} />
  );
};
