/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createAsyncThunk as nativeCreateAsyncThunk } from '@reduxjs/toolkit';
import createAsyncThunk from '../../middleware/customCreateThunk';
import {
  getPages,
  getPage,
  updatePage as updatePageAPI,
  getLatestPublishedRevision,
  getLatestDraftRevision, createNewDraft, updateDraft, publishDraft, createPageAPI, deletePageAPI,
} from '../../../services/api/page-builder';
import { PublishedSectionProps } from '../../../types';
import { setIsEnableSave } from '../editor';

export const fetchPages = createAsyncThunk({
  api: getPages,
  EVENT_NAME: 'page/fetchPages',
});

export const createPage = createAsyncThunk({
  api: createPageAPI,
  EVENT_NAME: 'page/createPage',
});

export const deletePage = createAsyncThunk({
  api: deletePageAPI,
  EVENT_NAME: 'page/deletePage',
});

export const updatePage = createAsyncThunk({
  api: updatePageAPI,
  EVENT_NAME: 'page/updatePage',
});

export const updateDraftRevision = createAsyncThunk({
  api: updateDraft,
  EVENT_NAME: 'page/updateDraftRevision',
});

export const fetchLatestPublishedRevision = createAsyncThunk({
  api: getLatestPublishedRevision,
  EVENT_NAME: 'page/fetchLatestPublishedRevision',
});

export const fetchLatestDraftRevision = createAsyncThunk({
  api: getLatestDraftRevision,
  EVENT_NAME: 'page/fetchLatestDraftRevision',
});

export const createNewDraftRevision = createAsyncThunk({
  api: createNewDraft,
  EVENT_NAME: 'page/createNewDraftRevision',
});

export const publishRevision = createAsyncThunk({
  api: publishDraft,
  EVENT_NAME: 'page/publishRevision',
});

export const fetchCurrentPage = nativeCreateAsyncThunk(
  'page/fetchCurrentPage',
  async (pageName: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await getPage(pageName);
      const { data } = response;
      if (!data.hasDraft) {
        await createNewDraft(data.id);
      }
      dispatch(fetchLatestDraftRevision(data.id));
      dispatch(setIsEnableSave(true));
      return response.data;
    } catch (error: any) {
      const { response = {} } = error;
      const { status = 404, data = {} } = response || {};
      return rejectWithValue({ status, data });
    }
  }
);

export const setShouldSaveDraft = createAction<boolean>('page/setShouldSaveDraft');
export const setIsDraftSaved = createAction<boolean>('page/setIsDraftSaved');
export const setCurrentPageSections = createAction<Array<PublishedSectionProps>>('page/setCurrentPageSections');
export const resetCreatePageState = createAction('page/resetCreatePageState')
export const resetCurrentPage = createAction('page/resetCurrentPage');
export const resetPageError = createAction('page/resetPageError');