import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { addToast } from "../../store/features/toast";
import { updateUserById, userSelector } from "../../store/features/user";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ManageUserManagement } from "../../templates/manageUserManagement/ManageUserManagement";
import { IEditableUser } from "../../types/user";

export function UserDetail() {
  const { id: userId } = useParams();

  const dispatch = useAppDispatch();
  const { currentUser, updateUserByIdState } = useAppSelector(userSelector);

  function onHandleUpdateUser(data: IEditableUser) {
    dispatch(updateUserById({ id: currentUser?.id, data: data }));
  }

  useEffect(() => {
    const status = updateUserByIdState.status;
    if (status === "success") {
      dispatch(addToast({ status }));
    }
    if (status === "error") {
      dispatch(addToast({ status, title: "Update failed" }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserByIdState.status]);

  return (
    <ManageUserManagement
      userId={userId}
      pageTitle={currentUser?.name || ""}
      submitButtonTitle="Save"
      onClickSubmit={(data) => onHandleUpdateUser(data)}
    />
  );
}
