import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createdProductType,
  productTypeSelector,
} from "../../store/features/productType";
import { addToast } from "../../store/features/toast";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ManageProductType } from "../../templates/manageProductType/ManageProductType";
import { IProductTypeEditable } from "../../types";

export function CreateProductType() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { createProductTypeState } = useAppSelector(productTypeSelector);

  function onHandleCreateProductType(data: IProductTypeEditable) {
    dispatch(createdProductType({ ...data, "used-in": "unit-type" }));
  }

  useEffect(() => {
    const status = createProductTypeState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully created" }));
      navigate(`/product-type`);
    }
    if (status === "error")
      dispatch(addToast({ status, title: "Create failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createProductTypeState.status, dispatch]);

  return (
    <ManageProductType
      dataUsedIn={"unit-type"}
      pageTitle={"Create Product Type"}
      onClickCreate={(data) => onHandleCreateProductType(data)}
    />
  );
}
