import styled from "@emotion/styled/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  border: none;
  border-radius: var(--border-radius);
  outline: none;
  padding: 12px var(--space-sm);
  transition: background-color 250ms ease;
  margin-bottom: 8px;

  :hover {
    background-color: var(--grey200);
  }
`;

export const Title = styled.p`
  font-size: 14px;
  color: var(--grey900);
  font-weight: 500;
`;

export const Icon = styled.div<{ isRotated: boolean }>`
  margin-left: auto;
  transform: ${(props) => (props.isRotated ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 350ms ease;
`;

export const Content = styled.div`
  overflow: hidden;
  transition: max-height 350ms ease;
`;
