import styled from "@emotion/styled";

export const ModalBodyWrapper = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CheckBoxWrapper = styled.div`
  height: 78px;
  display: flex;
  align-items: center;
`;

export const CreateUnitTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const MainText = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: var(--grey600);
`;
