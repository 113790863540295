import { keyframes } from '@emotion/react';
import styled from "@emotion/styled/macro"
import { maxLayout } from '../../../utils/breakpoints';

export const Toolbar = styled.div<{ isShow: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--space-md);
  width: 100%;
  opacity: ${(props) => (props.isShow ? 1 : 0)};
  pointer-events: ${(props) => (props.isShow ? 'all' : 'none')};
  padding: 24px 32px;
  background: white;
  border-bottom: 1px solid #EAECF0;

  @media screen and (max-width: 1019.98px) {
    display: block;
  }
`;

export const ResultAnimation = keyframes`
  0% { opacity: 0 }
  5% { opacity: 1 }
  80% { opacity: 1 }
  85% { opacity: 0 }
`;

export const ResultMessage = styled.span`
  opacity: 0;
  color: var(--grey700);
  font-size: 14px;
  margin-right: var(--space-sm);
  animation: ${ResultAnimation} 5s forwards;
`;

export const SaveDraftContainer = styled.div<{ isShow: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--space-sm);
  opacity: ${(props) => (props.isShow ? 1 : 0)};

  svg {
    position: relative;
    top: -2px;
  }

  span {
    position: relative;
    top: 3px;
  }

  > button {
    cursor: pointer;

    :disabled {
      cursor: not-allowed;
    }
  }

  @media screen and (max-width: 1019.98px) {
    justify-content: flex-start;
  }

  @media ${maxLayout.md} {
    justify-content: flex-end;
    margin-right: 0;
    margin-bottom: var(--space-xs);
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-flow: row nowrap;

  > button {
    font-weight: 700;
    padding: var(--space-xs) var(--space-md);

    :last-child {
      margin-left: var(--space-sm);
    }
  }
`;

export const PreviewLayoutButtonContainer = styled.div`
  margin-right: var(--space-md);

  @media ${maxLayout.md} {
    display: none;
  }
`;

export const PreviewLayoutButton = styled.button<{ isActive: boolean }>`
  background-color: ${(props) => (props.isActive ? 'var(--grey50)' : 'white')};
  border: 1px solid;
  border-color: var(--grey300);
  border-radius: 8px;
  color: ${(props) => (props.isActive ? 'var(--grey800)' : 'var(--grey500)')};
  cursor: pointer;
  font-size: 17px;
  padding: 12px 12px 4px 12px;
  min-width: 37px;
  transition: all 150ms;

  svg {
    margin: 0;
  }

  &:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }
  
  &:last-child {
    margin-right: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:hover {
    color: var(--grey800);
  }
`;

export const ResetDraftButton = styled.button`
  color: var(--grey800);
  background-color: transparent;
  border: none;
  box-shadow: none;
  margin-right: var(--space-sm);
  padding-left: 12px;
  margin-top: 5px;

  &:hover {
    color: var(--primary600);
  }

  @media ${maxLayout.md} {
    margin-right: 0;
  }
`;
