import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { LabelText } from "../../globalStyle/GlobalStyle.style";
import { IMediaResponse } from "../../types";
import { onClickRow } from "../../utils/dataTable";
import { formatBytes } from "../../utils/format";
import { Button } from "../button/Button";
import {
  Container,
  IconWrapper,
  TextWrapper,
  // SubTextWrapper,
  EmptyDataTextWrapper,
  FullFilledIconWrapper,
  ContextWrapper,
  ContentWrapper,
  ButtonGroup,
  FullFilledContainer,
} from "./AttachmentInput.style";
import { AttachmentInputModal } from "./attachmentInputModal/AttachmentInputModal";

function AttachmentInputEmptyState({
  onOpenModal,
}: {
  onOpenModal: () => void;
}) {
  return (
    <>
      <Container onClick={() => onOpenModal()}>
        <IconWrapper>
          <FontAwesomeIcon icon={["far", "plus"]} />
        </IconWrapper>
        <EmptyDataTextWrapper>Choose an attachment</EmptyDataTextWrapper>
      </Container>
    </>
  );
}

function AttachmentFulFillState({
  file,
  onDelete,
  onDownload,
  onOpenModal,
}: {
  file: IMediaResponse;
  onOpenModal: () => void;
  onDownload: () => void;
  onDelete: () => void;
}) {
  const fileSize = file?.fileSize
    ? formatBytes(file?.fileSize)
    : formatBytes(100000);
  return (
    <>
      <FullFilledContainer
        onClick={(e) => {
          onClickRow(e, () => onOpenModal());
        }}
      >
        <ContentWrapper>
          <FullFilledIconWrapper>
            <FontAwesomeIcon icon={["far", "file"]} />
          </FullFilledIconWrapper>
          <ContextWrapper>
            <TextWrapper>{file.name}</TextWrapper>
            {/* <SubTextWrapper>{`${fileSize[0]} ${fileSize[1]}`}</SubTextWrapper> */}
          </ContextWrapper>
        </ContentWrapper>
        <ButtonGroup id={"button-group"}>
          <Button
            onClick={() => onDownload()}
            fullWidth
            buttonStyle="text-link-grey"
          >
            <FontAwesomeIcon icon={["far", "download"]} />
          </Button>
          <Button
            onClick={() => onDelete()}
            fullWidth
            buttonStyle="text-link-grey"
          >
            <FontAwesomeIcon icon={["far", "trash-can"]} />
          </Button>
        </ButtonGroup>
      </FullFilledContainer>
    </>
  );
}

interface AttachmentInputProps {
  data?: IMediaResponse | null;
  label?: string;
  onClickChooseAttachment: (file: IMediaResponse) => void;
  onClickDeleteAttachment: () => void;
  onClickDownloadAttachment: () => void;
}

export function AttachmentInput(props: AttachmentInputProps) {
  const {
    data,
    label,
    onClickChooseAttachment,
    onClickDeleteAttachment,
    onClickDownloadAttachment,
  } = props;
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<IMediaResponse | null>(null);

  useEffect(() => {
    if (data) {
      setSelectedFile(data);
    }
  }, [data]);

  return (
    <div>
      <AttachmentInputModal
        isModalOpened={isModalOpened}
        onCloseModal={() => {
          setIsModalOpened(false);
        }}
        onClickChooseFile={(file) => {
          if (file) {
            onClickChooseAttachment(file);
          }
          setSelectedFile(file);
          setIsModalOpened(false);
        }}
      />
      {label && <LabelText>{label}</LabelText>}
      {selectedFile ? (
        <AttachmentFulFillState
          onOpenModal={() => setIsModalOpened(true)}
          file={selectedFile}
          onDelete={() => {
            onClickDeleteAttachment();
            setSelectedFile(null);
          }}
          onDownload={() => {
            onClickDownloadAttachment();
          }}
        />
      ) : (
        <AttachmentInputEmptyState onOpenModal={() => setIsModalOpened(true)} />
      )}
    </div>
  );
}
