import { css } from '@emotion/react';

import Colors from './Colors.style';
import FontSizes from './FontSizes.style';
import Spaces from './Spaces.style';

const Variables = css`
  :root {
    ${Colors}
    ${Spaces}
    ${FontSizes}

    --border-radius: 3px;
  }
`;

export default Variables;
