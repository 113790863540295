import { createAction } from "@reduxjs/toolkit";
import createAsyncThunk from "../../middleware/customCreateThunk";
import { loadPlaceTypeListAPI } from "../../../services/api/placeType";

export const fetchPlaceTypeList = createAsyncThunk({
  api: loadPlaceTypeListAPI,
  EVENT_NAME: "PlaceType/loadPlaceTypeList",
});

export const resetPlaceType = createAction("PlaceType/reset");
