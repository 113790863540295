import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchPostById, postSelector } from "../../../store/features/post";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ManagePost } from "../../../templates/managePost/ManagePost";

export function NewsAndActivitiesDetail() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { currentPost } = useAppSelector(postSelector)


  useEffect(() => {
    if (id) {
      dispatch(fetchPostById(id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ManagePost
        pageTitle={currentPost?.title || ""}
        isShowPostType
        isShowSelectLanguage
        isShowPreviewPage
        isShowCreatedAt
        isShowUpdatedAt
        isShowUrl
      />
    </>
  );
}
