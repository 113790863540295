import styled from "@emotion/styled/macro";

export const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const SuspendButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const InformText = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: var(--grey600);
`;

export const ChangePasswordModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContentContainer = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    max-width: 1100px;
    width: 100%;
    margin-bottom: 80px;
  }

  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 89px);

  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollWrapper = styled.div`
  /* overflow-y: scroll;
  overflow-x: hidden; */
  /* max-height: calc(100vh - 89px); */
  padding: 32px;

  /* -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  } */

  > section {
    :last-child {
      margin-bottom: 0px;
    }
    margin-bottom: 24px;
  }
`;