/* eslint-disable react/jsx-props-no-spreading */
import { createStyles, MantineTheme, Radio as CoreRadio } from '@mantine/core';
import { RadioProps } from '@mantine/core/lib/Radio/Radio';

const useStyles = createStyles((theme: MantineTheme, props: RadioProps) => ({
  radio: {
    '&:checked': {
      backgroundColor: props.checked ? theme.colors.ci : 'white',
    }
  },
}));

/**
 * Documentation: {@link https://mantine.dev/core/radio/}
 */
export function Radio(props: RadioProps) {
  const { classes } = useStyles(props);
  return (
    <CoreRadio
      {...props}
      classNames={classes}
    />
  );
}
