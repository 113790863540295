import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AspectRatio, Image as CoreImage } from "@mantine/core";
import { useState } from "react";
import { onClickEditButton } from "../../../utils/mediaItem";
import { Button } from "../../button/Button";
import { DropdownMenu } from "../../dropdownMenu/DropdownMenu";
import { MediaItemProps, useStyles } from "../MediaItem";
import { ImageWrapper, Container, Overlay } from "../MediaItem.style";
import { EditButtonWrapper, EditIcon, MenuContainer } from "./MediaItemA.style";

export function MediaItemA(props: MediaItemProps) {
  const {
    maxWidth,
    isSelected,
    ratio,
    data,
    iconSize,
    isEditButton,
    isMenuItemActive,
    onClickButton = () => {},
    onClickMedia = (e: React.MouseEvent<HTMLElement>) => {},
    onEdit = () => {},
    onMove = () => {},
    onDelete = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
  } = props;
  const { classes } = useStyles();

  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <>
      <Container
        maxWidth={maxWidth}
        isSelected={isSelected}
        onMouseEnter={(e) => {
          setIsHover(true);
          onMouseEnter(e);
        }}
        onMouseLeave={(e) => {
          setIsHover(false);
          onMouseLeave(e);
        }}
      >
        {ratio ? (
          <>
            <AspectRatio ratio={ratio}>
              <ImageWrapper onClick={onClickMedia}>
                <CoreImage {...data} fit={"cover"} classNames={classes} />
                <Overlay>
                  <FontAwesomeIcon icon={["far", "search"]} />
                  {isEditButton && (
                    <EditButtonWrapper>
                      <Button
                        buttonStyle="secondary"
                        onClick={(e) => {
                          onClickEditButton(e, () => {
                            onClickButton(data?.key || "");
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={["far", "ellipsis-vertical"]} />
                      </Button>
                    </EditButtonWrapper>
                  )}
                </Overlay>
              </ImageWrapper>
            </AspectRatio>
          </>
        ) : (
          <ImageWrapper onClick={onClickMedia}>
            <CoreImage {...data} fit={"fill"} classNames={classes} />
            <Overlay>
              <FontAwesomeIcon icon={["far", "search"]} />
              <EditIcon
                iconWidth={iconSize?.width}
                iconHeight={iconSize?.height}
              >
                <FontAwesomeIcon icon={["far", "pen"]} />
              </EditIcon>
            </Overlay>
          </ImageWrapper>
        )}
        {isEditButton && (
          <MenuContainer
            isActive={isMenuItemActive && isHover ? isMenuItemActive : false}
          >
            <DropdownMenu
              maxWidth={isMenuItemActive ? "150px" : undefined}
              data={[
                { icon: ["far", "pen"], label: "Edit", onClick: onEdit },
                {
                  icon: ["far", "folder"],
                  label: "Move to ...",
                  onClick: onMove,
                },
                {
                  icon: ["far", "trash-can"],
                  label: "Delete",
                  onClick: onDelete,
                },
              ]}
            />
          </MenuContainer>
        )}
      </Container>
    </>
  );
}
