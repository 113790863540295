import { css } from '@emotion/react';

const FontSizes = css`
  --font-size-xxxl: 32px;
  --font-size-xxl: 24px;
  --font-size-xl: 18px;
  --font-size-lg: 16px;
  --font-size-md: 14px;
  --font-size-sm: 12px;
  --font-size-xs: 8px;
`;

export default FontSizes;
