import styled from "@emotion/styled";

export const OptionItemGroup = styled.div`
  display: flex;
  gap: 8px;
`;

export const SelectOptionText = styled.div`
  /* font-weight: 500;
  font-size: 16px;
  color: var(--grey900); */

  /* display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis; */
  /* -webkit-line-clamp: 1; number of lines to show */
  /* line-clamp: 1;
  -webkit-box-orient: vertical; */
`;

export const ColorPreview = styled.div<{ color?: string }>`
  position: relative;
  background-color: ${(props) => props.color};
  width: 24px;
  height: 24px;
  border-radius: 6px;
  border: 1px solid var(--grey300);
  flex: 0 0 24px;
`;
