import styled from "@emotion/styled/macro";

export const SubTitle = styled.div`
  flex: 0 0 130px;
  font-size: var(--font-size-lg);
  font-weight: 500;
  color: var(--grey700);
`;

export const SubText = styled.div`
  font-size: var(--font-size-lg);
  font-weight: 400;
  color: var(--grey600);
  word-break: break-all;
`;

export const ButtonWrapper = styled.div`
  margin-left: auto;
`;

export const SidePanelSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SidePanelTextGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ScrollWrapper = styled.div`
  max-width: 350px;
  width: 100%;
  > div {
    overflow-y: scroll;
    max-height: calc(100vh - 89px);

    > div {
      > :last-child {
        padding-bottom: 24px;
      }
    }
  }
`;
