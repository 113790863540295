import styled from "@emotion/styled/macro"
import { css } from '@emotion/react'
import { PreviewLayoutType } from '../../../types';
import { maxLayout } from '../../../utils/breakpoints';

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  @media ${maxLayout.md} {
    transform: translateX(-100vw);
    min-width: 100vw;
    padding: 12px;
  }
`;

export const Canvas = styled.div<{ previewLayout: PreviewLayoutType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  width: ${(props) => (props.previewLayout === 'desktop' ? '100%' : '375px')};
  height: ${(props) => (props.previewLayout === 'desktop' ? '100%' : '812px')};
  margin: 0 auto;
`;

export const IFrame = styled.iframe`
  border: none;
  width: 100%;
  height: calc(100% - 36px);
`;

export const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-left: 24px;
`;

export const IFrameContainer = styled.div<{ previewLayout: PreviewLayoutType }>`
  position: relative;
  width: ${(props) => (props.previewLayout === 'desktop' ? '100%' : '375px')};
  height: ${(props) => (props.previewLayout === 'desktop' ? '100%' : '812px')};
  margin: ${(props) => (props.previewLayout === 'desktop' ? '0 auto' : '-48px auto 0')};

  iframe {
    position: relative;
    z-index: 2;
  }

  svg {
    position: absolute;
    z-index: 1;
    color: var(--grey700);
    top: 40%;
    left: ${(props) => (props.previewLayout === 'mobile' ? '44%' : '50%')};
    transform: translate(-50%, -50%);
    font-size: 60px;
  }

  ${(props) => (props.previewLayout === 'mobile' ? css`
    iframe {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    &::after {
      background: #141414;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
      display: block;
      width: 104%;
      height: 104%;
      position: absolute;
      top: -52px;
      left: -7px;
      content: ' ';
      border-radius: 28px;
    }
  ` : '')};

  @media ${maxLayout.xxl} {
    width: ${(props) => (props.previewLayout === 'desktop' ? '100%' : '375px')};
    height: ${(props) => (props.previewLayout === 'desktop' ? '100%' : 'calc(100% - 70px)')};
    margin: ${(props) => (props.previewLayout === 'desktop' ? '0 auto' : '38px auto 0')};
  }

  @media ${maxLayout.md} {
    height: 100%;
    margin: auto;
  }
`;

export const IPhoneHoles = styled.div`
  background: #1c1c1c;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  height: 24px;
  width: 200px;
`;

export const IPhoneClock = styled.p`
  position: absolute;
  top: 4px;
  left: 30px;
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, Oxygen-Sans, Ubuntu, Cantarell,
  "Helvetica Neue", sans-serif;
  font-weight: 500;
  margin-bottom: 0;
`;

export const Battery = styled.div`
  position: absolute;
  right: 16px;
  top: 8px;
  width: 22px;
  height: 12px;
  border: 1px solid #828282;
  border-radius: 3px;

  &::before {
    display: block;
    content: '';
    position: relative;
    top: 1px;
    right: -0.5px;
    width: 18.5px;
    height: 8px;
    border-radius: 2px;
    background-color: #1c1c1c;
  }

  &::after {
    display: block;
    content: '';
    position: relative;
    top: -6px;
    right: -21px;
    width: 1.5px;
    height: 6px;
    border-radius: 2px;
    background-color: #828282;
  }
`;

export const IPhoneHeader = styled.div<{ dark: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: -28px;
  width: 100.1%;
  background: ${(props) => (props.dark ? '#000' : '#FFF')};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 28px;
  z-index: 21;

  ${(props) => (props.dark ? css`
    ${IPhoneClock} {
      color: #FFF;
    }

    ${Battery} {
      &::before {
        background-color: #cfcfcf;
      }
    }
  ` : '')};
`;

export const HintText = styled.p`
  color: var(--grey500);
`;

export const HideNavbarArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 101%;
  height: 96px;
  background-color: var(--grey100);
  z-index: 3;
`;
