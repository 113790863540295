import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStyles, Image, Modal } from "@mantine/core";
import { useState, SyntheticEvent } from "react";
import { Button } from "../../../../atoms/button/Button";
import { IEmbeddedMedia } from "../../../../types";
import { ConfirmModal } from "../../../confirmModal/ConfirmModal";
import { ModalEditMediaProps } from "../ModalEditMedia";
import { ImageContainer, ImageWrapper } from "../ModalEditMedia.style";

import {
  ModalWrapper,
  NextButton,
  PrevButton,
} from "./ModalEditEmbeddedMedia.style";

const useStyles = createStyles(() => ({
  title: {
    fontSize: "18px",
    color: "var(--grey900)",
    fontWeight: 600,
  },
  close: {
    color: "var(--white)",

    "&:hover": {
      backgroundColor: "transparent",
      color: "var(--grey300)",
    },

    "> svg": {
      width: "24px",
      height: "24px",
    },
  },
  header: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 1,
    padding: 24,
    marginBottom: 0,
    marginRight: 0,
  },
  inner: {
    padding: 0,
  },
  modal: {
    overflow: "hidden",
    padding: "0 !important",
    background: "rgba(52, 64, 84, 0.85)",
    backdropFilter: " blur(8px)",
  },
}));

const useStyleImage = createStyles(() => ({
  imageWrapper: {
    // backgroundColor: "var(--grey25)",
  },
}));

interface ModalEditEmbeddedMediaProps
  extends Omit<ModalEditMediaProps, "data"> {
  data: IEmbeddedMedia;
}

export function ModalEditEmbeddedMedia(props: ModalEditEmbeddedMediaProps) {
  const {
    data,
    isModalOpened,
    isModalDeleteImageOpened,
    isDisableNextButton,
    isDisablePreviousButton,
    onDeleteData = () => {},
    onCloseModal = () => {},
    onClickNext = () => {},
    onClickPrevious = () => {},
    onOpenDeleteImageModal = () => {},
    onCloseDeleteImageModal = () => {},
  } = props;
  const { classes } = useStyles();
  const { classes: imageClasses } = useStyleImage();

  // const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isHorizontalImage, setIsHorizontalImage] = useState<boolean>(false);

  function onLoadImage(
    e: SyntheticEvent<HTMLImageElement> & { target: HTMLElement }
  ) {
    // if (e.target.offsetHeight - e.target.offsetWidth > 150) {
    if (e.target.offsetHeight > e.target.offsetWidth) {
      setIsHorizontalImage(true);
    } else {
      setIsHorizontalImage(false);
    }
  }

  return (
    <Modal
      onClose={() => onCloseModal()}
      opened={isModalOpened}
      fullScreen
      classNames={classes}
    >
      <ConfirmModal
        onClose={() => onCloseDeleteImageModal()}
        title={`Delete File`}
        primaryButtonTitle={"Delete"}
        opened={isModalDeleteImageOpened}
        onClickPrimaryButton={onDeleteData}
      >
        <div>
          Are you sure you want to delete this file?
          {/* <b>‘{data?.name || ""}’</b>? */}
        </div>
        <div style={{ marginTop: "16px" }}>
          Once you confirm, this file will be deleted from the list but still be
          available in the contents that used it.
        </div>
      </ConfirmModal>
      <ModalWrapper>
        <ImageContainer>
          <PrevButton>
            <Button
              buttonStyle="outline-light"
              shape="pill"
              onClick={() => onClickPrevious()}
              disabled={isDisablePreviousButton}
            >
              <FontAwesomeIcon icon={["far", "chevron-left"]} />
            </Button>
          </PrevButton>
          <NextButton>
            <Button
              buttonStyle="outline-light"
              shape="pill"
              onClick={() => onClickNext()}
              disabled={isDisableNextButton}
            >
              <FontAwesomeIcon icon={["far", "chevron-right"]} />
            </Button>
          </NextButton>
          <ImageWrapper isHorizontalImage={isHorizontalImage}>
            <Image
              classNames={imageClasses}
              onLoad={onLoadImage}
              src={data?.originalUrl || ""}
              alt={data?.name || ""}
            />
          </ImageWrapper>
        </ImageContainer>
      </ModalWrapper>
    </Modal>
  );
}
