import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  createPage,
  fetchCurrentPage,
  pageDataSelector,
  resetCreatePageState,
  resetCurrentPage,
  updatePage,
} from "../../../store/features/page";
import {
  fetchPostById,
  postSelector,
  resetPostDetail,
  updatePostById,
} from "../../../store/features/post";
import { addToast } from "../../../store/features/toast";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IPostPromotion, IPostPromotionEditable } from "../../../types/post";
import { ManagePromotion } from "./ManagePromotion";

export function PromotionDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentPost, updatePostState } = useAppSelector(postSelector);
  const { createPageState, newPage, currentPage } = useAppSelector(pageDataSelector);

  async function onUpdatePost(data: IPostPromotionEditable) {
    await dispatch(
      updatePostById({ id: currentPost?.id, payload: { ...data } })
    );
  }

  async function onUpdatePage(data: IPostPromotion) {
    if (data?.slugCode) {
      try {
        await dispatch(
          updatePage({
            pageIdOrPath: `resource:${data?.id}`,
            payload: {
              title: `${data?.title} (${data?.languageCode.toUpperCase()})`,
              path: `/${data?.languageCode}/promotion/${data?.slugCode}`,
              slug: `${data?.slugCode}-${data?.languageCode}`,
              languageCode: data?.languageCode,
            },
          })
        );
      } catch (err) {
        console.log(err);
      }
    }
  }

  async function onCreatePage(data: IPostPromotion) {
    if (data?.slugCode) {
      await dispatch(
        createPage({
          title: `${data?.title} (${data?.languageCode.toUpperCase()})`,
          path: `/${data?.languageCode}/promotion/${data?.slugCode}`,
          category: "post",
          resourceId: data?.id,
          resourceUrl: `/promotion/${data?.id}`,
          slug: `${data?.slugCode}-${data.languageCode}`,
          languageCode: data.languageCode,
        })
      );
    }
  }

  useEffect(() => {
    const status = updatePostState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully updated" }));
      if(currentPage?.id){
        onUpdatePage(currentPost as IPostPromotion);
      }
    }
    if (status === "error") {
      dispatch(addToast({ status, title: "Update failed" }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePostState]);

  useEffect(() => {
    const status = createPageState.status;
    if (status === "success") {
      if (newPage) {
        navigate(`/page-builder/page/${newPage?.id}`);
        return () => {
          dispatch(resetCreatePageState());
        };
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPageState]);

  useEffect(() => {
    dispatch(fetchPostById(id));
    dispatch(fetchCurrentPage(`resource:${id}`));

    return () => {
      dispatch(resetPostDetail());
      dispatch(resetCurrentPage());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ManagePromotion
        pageTitle={currentPost?.title || ""}
        isShowPostType
        isShowSelectLanguage
        isShowPreviewPage
        isShowCreatedAt
        isShowUpdatedAt
        isShowVisibilityStatus
        isShowHighlightStatus
        isShowUrl
        submitButtonTitle={"Save"}
        onSubmit={(data) => onUpdatePost(data)}
        onCreatePage={() => onCreatePage(currentPost as IPostPromotion)}
      />
    </>
  );
}
