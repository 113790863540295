import { Container } from "./HorizontalList.style";

interface HorizontalListProps {
  children: React.ReactElement;
  maxHeight?: string;
  maxWidth?: string;
  gap?: string;
}
export function HorizontalList(props: HorizontalListProps) {
  const { children, maxHeight, maxWidth, gap } = props;
  return (
    <Container gap={gap} maxHeight={maxHeight} maxWidth={maxWidth}>
      {children}
    </Container>
  );
}
