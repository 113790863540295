import styled from "@emotion/styled/macro";

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const IconContainer = styled.div`
  color: var(--grey400);
`;
