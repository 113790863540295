import { useEffect } from "react";
import { useState } from "react";
import { Button, ButtonStyles } from "../../../atoms/button/Button";
import { ColorInput } from "../../../atoms/colorInput/ColorInput";
import { EmptyState } from "../../../atoms/colorInput/ColorInput.style";
import { ColorSelector } from "../../../molecules/colorSelector/ColorSelector";
import { ColorPreview } from "../../../molecules/colorSelector/ColorSelector.style";
import { ContentDivider } from "../../../molecules/contentDivider/ContentDivider";
import { ThemeType } from "../../../types";
import { convertColorToSixDigit } from "../../../utils/convertData";
import { validateColor } from "../../../utils/validation";
import { Section } from "../Section";
import {
  GroupInputWrapper,
  ContentContainer,
} from "./SectionTheme.style";

interface SectionThemeProps {
  sectionTitle?: string;
  themeData: ThemeType | null;
  buttonStyle?: ButtonStyles;
  buttonTitle?: string;
  onCheckInvalidInput?: (isValid: boolean) => void;
  onHandleChangeData: (path: string, value: string | any[] | null) => void;
  onUpdateData?: (data?: ThemeType | null, isValid?: boolean) => void;
}

export function SectionTheme(props: SectionThemeProps) {
  const {
    onUpdateData,
    onHandleChangeData,
    onCheckInvalidInput = () => {},
    themeData,
    sectionTitle = "Theme",
    buttonStyle = "primary",
    buttonTitle = "Save",
  } = props;
  const [isError] = useState<boolean>(false);
  const [selectedMainColor, setSelectedMainColor] = useState<number>(-1);

  function onHandleChangeThemeData(path: string, value: string) {
    onHandleChangeData(path, value);
  }

  useEffect(() => {
    onCheckInvalidInput(isError);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (selectedMainColor >= 0) {
      onHandleChangeThemeData(
        `theme.mainColor`,
        themeData?.brandColors[selectedMainColor] || ""
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeData?.brandColors[selectedMainColor], selectedMainColor]);

  useEffect(() => {
    const colorIndex = themeData?.brandColors.findIndex(
      (item) => item === themeData?.mainColor
    );
    if (colorIndex !== undefined) {
      if (colorIndex >= 0) {
        setSelectedMainColor(colorIndex);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeData?.mainColor]);

  return (
    <>
      <Section
        title={sectionTitle}
        headerActionComponent={
          onUpdateData && (
            <Button
              onClick={() => onUpdateData(themeData, !isError)}
              buttonStyle={buttonStyle}
            >
              {buttonTitle}
            </Button>
          )
        }
      >
        <ContentContainer>
          <ContentDivider title={"Palette"} />
          <GroupInputWrapper>
            {Array.from(Array(4).keys()).map((item) => (
              <ColorInput
                key={`brand-color-${item + 1}`}
                label={`Color ${item + 1}`}
                value={themeData?.brandColors[item] || ""}
                onChange={(e) => {
                  onHandleChangeThemeData(
                    `theme.brandColors[${item}]`,
                    e || ""
                  );
                }}
                hintText=""
              />
            ))}
          </GroupInputWrapper>
          <ContentDivider title={"Primary"} />
          <GroupInputWrapper row={1}>
            <ColorSelector
              label={"Main color"}
              placeholder={"Select a color"}
              icon={
                Boolean(selectedMainColor >= 0) && (
                  <ColorPreview
                    color={themeData?.brandColors[selectedMainColor] || ""}
                  >
                    {!Boolean(themeData?.brandColors[selectedMainColor]) ||
                    (themeData?.brandColors[selectedMainColor] &&
                      !validateColor(
                        themeData?.brandColors[selectedMainColor]
                      )) ? (
                      <EmptyState></EmptyState>
                    ) : (
                      <></>
                    )}
                  </ColorPreview>
                )
              }
              data={
                themeData?.brandColors
                  .map((item, index) =>
                    item
                      ? {
                          value: item,
                          label: `Color ${index + 1} (${convertColorToSixDigit(
                            item
                          )})`,
                          key: `brand-color-option-${item}-${index + 1}`,
                        }
                      : ""
                  )
                  .filter(Boolean) || []
              }
              value={themeData?.brandColors[selectedMainColor]}
              onChange={(e) => {
                if (themeData) {
                  const colorIndex = themeData?.brandColors.findIndex(
                    (item) => item === e
                  );
                  if (colorIndex >= 0) {
                    setSelectedMainColor(colorIndex);
                  }
                }
              }}
            />
          </GroupInputWrapper>
          <ContentDivider title={"Background"} />
          <GroupInputWrapper>
            {Array.from(Array(3).keys()).map((item) => (
              <ColorInput
                key={`bg-color-${item + 1}`}
                label={`Background ${item + 1}`}
                value={themeData?.backgroundColors[item] || ""}
                onChange={(e) => {
                  onHandleChangeThemeData(
                    `theme.backgroundColors[${item}]`,
                    e || ""
                  );
                }}
                hintText=""
              />
            ))}
          </GroupInputWrapper>
        </ContentContainer>
      </Section>
    </>
  );
}
