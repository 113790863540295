import { forwardRef } from "react";
import { EmptyState } from "../../atoms/colorInput/ColorInput.style";
import { Select } from "../../atoms/select/Select";
import { validateColor } from "../../utils/validation";
import { SelectProps as CoreSelectProps } from "@mantine/core/lib/Select/Select";
import {
  ColorPreview,
  OptionItemGroup,
  SelectOptionText,
} from "./ColorSelector.style";

export function ColorSelector(props: CoreSelectProps) {
  const { data, value, icon, ...rest } = props;

  interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
    value: string;
    label: string;
    key: string;
  }

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ value, label, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <OptionItemGroup>
          <ColorPreview color={value}>
            {!Boolean(value) || (value && !validateColor(value)) ? (
              <EmptyState></EmptyState>
            ) : (
              <></>
            )}
          </ColorPreview>

          <div>
            <SelectOptionText>{label}</SelectOptionText>
          </div>
        </OptionItemGroup>
      </div>
    )
  );

  const ColorPreviewComponent = (color?: string | null) => {
    return <ColorPreview color={color ? color : undefined} />
  };

  return (
    <Select
      {...rest}
      data={data}
      itemComponent={SelectItem}
      icon={icon ? icon : ColorPreviewComponent(value)}
      value={value}
    />
  );
}
