// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading, react/no-unstable-nested-components */
import { useDebounce } from 'use-debounce';
import { components } from 'react-select';
import { CSSProperties, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDataSuggestionsByContentTypeAuto, getDataSuggestionsByContentTypeId } from '../../../services/api/page-builder';
import { DataSourceParameterValueProps, SuggestionProps } from '../../../types';
import { ClearButton, Container, LoadingPlaceholder } from './SuggestionSelector.style';
import { Select } from '../../../atoms/select/Select';

export interface SuggestionSelectorProps {
  label: string;
  sourceType: string;
  parameterName: string;
  value: DataSourceParameterValueProps | null;
  onSelect: (value: SuggestionProps) => void;
  method: 'auto' | 'id';
  isMultiple?: boolean;
  inputSuggestions?: object[];
  defaultValue?: SuggestionProps; // สำหรับแสดง Label ให้ช่อง Content ID ในเคสที่แก้ไข Data Source
}

export const SuggestionSelector = (props: SuggestionSelectorProps) => {
  const { label, sourceType, parameterName, onSelect, value, method, isMultiple = false, inputSuggestions = [], defaultValue } = props;
  const [isSuggestionLoading, setIsSuggestionLoading] = useState<boolean>(true);
  const [isFirstTimeSuggestionsLoaded, setIsFirstTimeSuggestionsLoaded] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<Array<SuggestionProps>>();
  const [suggestionError, setSuggestionError] = useState<any>(null);
  const [selectValue, setSelectValue] = useState<DataSourceParameterValueProps | null>('');

  const [searchValue, setSearchValue] = useState<string>('');
  const [debounceSearchValue] = useDebounce(searchValue, 200);

  const controlStyles: CSSProperties = { cursor: 'text', boxShadow: 'none' };
  const singleValueStyles: CSSProperties = { color: '#2e2e2e', position: 'relative', top: '1px' };
  const placeholderStyles: CSSProperties = { color: '#cbcbcb' };
  const themeColors = { primary25: '#eaeaea', primary: '#d94c0c' };

  // อย่าใช้ UseCallback ตรงนี้เพราะมันจะแสดงผลไม่ถูกต้องนะครับ บางอย่างก็ไม่ต้องตาม eslint ก็ได้ครับ
  const NoOptionsMessage = (passedProps: any) => {
    const getText = () => {
      if (suggestionError) {
        return 'ไม่สามารถโหลดข้อมูลได้ในขณะนี้';
      }
      if (isSuggestionLoading) {
        return 'กำลังโหลด...';
      }
      if (searchValue.length) {
        return 'ไม่พบคำค้นหาที่ต้องการ';
      }
      return 'ไม่พบแหล่งข้อมูลในขณะนี้';
    };

    return (
      <components.NoOptionsMessage {...passedProps}>
        <span>{ getText() }</span>
      </components.NoOptionsMessage>
    );
  };

  useEffect(() => {
    const setSuggestionsAndDefaultValue = (suggestionsArray: Array<SuggestionProps>): void => {
      setSuggestions(suggestionsArray);

      if (value && value !== selectValue?.value && suggestionsArray) {
        // ถ้าผู้ใช้กดแก้ไข Data Source
        // eslint-disable-next-line eqeqeq
        if (defaultValue && defaultValue.value == value) {
          setSelectValue({ label: defaultValue.label, value });
          return;
        }

        const found = suggestionsArray.find((item) => item.value === value);
        if (found) {
          setSelectValue(found);
        } else {
          // แท็กที่ผู้ใช้กรอกสำหรับ Data Source ที่ลิสต์ข้อมูลตามแท็ก (เช่น News - ลิสต์ข่าวล่าสุด ตามแท็ก) มักจะไม่มีใน Suggestion
          // เนื่องจากเป็นแท็กที่พิมพ์เอง เช่น สงครามรัสเซียยูเครน
          // (บัคตอนเปลี่ยน Data Source จึงขอปิดไว้ก่อน)
          // const newSuggestion = { label: value, value };
          // setSuggestions([...suggestionsArray, newSuggestion]);
          // setSelectValue(newSuggestion);

          // Set เป็น null ไปเลยเพราะถ้าเป็นข้อมูลที่ไม่มีใน Suggestions ก็จะไม่มี label ให้แสดง
          setSelectValue(null);
        }
      }
    };

    const setInputSuggestionsAndDefaultValue = (suggestionsArray: Array<SuggestionProps>): void => {
      setSuggestions(suggestionsArray);
      setSelectValue(suggestionsArray.find((item) => item.value === (value?.value || value)) || suggestionsArray[0]);
    };

    const loadSuggestions = async () => {
      let response;
      setIsSuggestionLoading(true);
      setSuggestions([]);

      try {
        if (method === 'auto') {
          response = await getDataSuggestionsByContentTypeAuto(sourceType, parameterName, debounceSearchValue);
        } else {
          response = await getDataSuggestionsByContentTypeId(sourceType, debounceSearchValue);
        }

        setSuggestionsAndDefaultValue(response.data?.data);
        setIsSuggestionLoading(false);
        setIsFirstTimeSuggestionsLoaded(true);
      } catch (error) {
        setIsSuggestionLoading(false);
        setSuggestionError(error);
        setSuggestions([]);
      }
    };

    if (!inputSuggestions?.length) {
      loadSuggestions();
    } else {
      setInputSuggestionsAndDefaultValue(inputSuggestions);
      setIsFirstTimeSuggestionsLoaded(true);
    }
  }, [sourceType, debounceSearchValue]);

  useEffect(() => {
    if (!value) {
      setSelectValue('');
    }
  }, [value]);

  console.log(selectValue);

  return (
    <Container>
      { !isFirstTimeSuggestionsLoaded ? (
        <LoadingPlaceholder />
      ) : (
        <>
          <Select
            isMulti={isMultiple}
            // styles={{
            //   control: (styles) => ({ ...styles, ...controlStyles }),
            //   option: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'pointer' }),
            //   singleValue: (styles) => ({ ...styles, ...singleValueStyles }),
            //   placeholder: (styles) => ({ ...styles, ...placeholderStyles }),
            //   menuPortal: (styles) => ({ ...styles, zIndex: 20 }),
            //   menuList: (styles) => ({ ...styles, zIndex: 20 }),
            //   menu: (styles) => ({ ...styles, zIndex: 20 }),
            // }}
            theme={(theme) => ({ ...theme, colors: { ...theme.colors, ...themeColors } })}
            data={suggestions}
            value={selectValue}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              NoOptionsMessage,
            }}
            placeholder={ method === 'auto' ? `เลือกหรือค้นหา${label}` : 'เลือกหรือค้นหาแหล่งข้อมูล'}
            onInputChange={(newValue: string) => setSearchValue(newValue)}
            onChange={(selected: SuggestionProps) => { onSelect(selected); setSelectValue(selected); }} />
          <ClearButton onClick={() => { onSelect(null); setSelectValue(null); }} hide={!selectValue} style={{ marginRight: 16 }}>
            <FontAwesomeIcon icon={['fas', 'times']} />
          </ClearButton>
        </>
      )}
    </Container>
  );
};
