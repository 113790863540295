import styled from "@emotion/styled/macro";

export const TotalText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey600);
  padding: 12px 16px;
`;

export const MainContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const IconWrapper = styled.div`
  background-color: var(--grey100);
  border-radius: 20px;
  color: var(--grey700);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
