import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { SelecterShape } from "./MediaItem";

export const SelectIcon = styled.div<{
  iconWidth?: string;
  iconHeight?: string;
  isSelected?: boolean;
  isShowSelecter?: boolean;
  shape?: SelecterShape;
}>`
  position: absolute;
  top: 8px;
  right: 8px;
  width: ${(props) => (props.iconWidth ? props.iconWidth : "20px")};
  height: ${(props) => (props.iconHeight ? props.iconHeight : "20px")};
  /* border-radius: 10px; */

  display: ${(props) => (props.isSelected ? "flex" : "none")};
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.isShowSelecter &&
    css`
      display: flex;
      color: var(--white);
      background-color: var(--white);
      border: 1px solid var(--grey300);
    `}

  ${(props) => {
    switch (props.shape) {
      case "radio":
        return css`
          border-radius: 10px;

          ${props.isSelected &&
          css`
            color: var(--white);
            background-color: var(--primary600);
            border: 1px solid var(--primary600);
          `}
        `;
      case "checkbox":
        return css`
          border-radius: 6px;
          ${props.isSelected &&
          css`
            color: var(--primary600);
            background-color: var(--primary50);
            border: 1px solid var(--primary600);
          `}
        `;
      default:
        return css`
          border-radius: 10px;

          ${props.isSelected &&
          css`
            color: var(--white);
            background-color: var(--primary600);
            border: 1px solid var(--primary600);
          `}
        `;
    }
  }}
`;

export const Overlay = styled.div<{ isDisable?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;

  ${(props) =>
    props.isDisable &&
    css`
      background-color: rgba(0, 0, 0, 0.3);
      opacity: 1;
    `}

  border: 1px solid var(--primary600);

  transition: 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    color: white;
    font-size: 36px;
    pointer-events: none;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--grey50);
`;

export const Container = styled.div<{
  maxWidth?: string;
  isSelected?: boolean;
  isDisable?: boolean;
  shape?: SelecterShape;
}>`
  position: relative;
  width: 100%;
  max-width: ${(props) => props.maxWidth && props.maxWidth};

  cursor: pointer;
  border: 1px solid var(--grey200);
  pointer-events: ${(props) => (props.isDisable ? "none" : "auto")};
  pointer: ${(props) => (props.isDisable ? "default" : "pointer")};

  &:hover ${Overlay} {
    opacity: 1;
  }

  &:hover ${SelectIcon} {
    background-color: var(--primary100);
    color: var(--primary100);
    border: 1px solid var(--primary600);

    ${(props) => {
      if (props.isSelected) {
        switch (props.shape) {
          case "radio":
            return css`
              color: var(--white);
              background-color: var(--primary600);
              border: 1px solid var(--primary600);
            `;
          case "checkbox":
            return css`
              color: var(--primary600);
              background-color: var(--primary50);
              border: 1px solid var(--primary600);
            `;
          default:
            return css`
              color: var(--white);
              background-color: var(--primary600);
              border: 1px solid var(--primary600);
            `;
        }
      }
    }}
  }

  ${(props) =>
    props.isSelected &&
    css`
      border: 1px solid var(--primary600);
      box-shadow: 0px 0px 0px 4px #d1e0ff;

      ${Overlay} {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.1);
      }
    `}
`;
