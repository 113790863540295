import styled from '@emotion/styled'

export const SectionContainer = styled.section<{isOverflowHidden?:boolean}>`
  background: var(--white);
  border: 1px solid #EAECF0;
  /* Shadow/sm */
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  height: fit-content;
  overflow: ${props => props.isOverflowHidden && "hidden"};
`

export const SectionHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 24px 16px 24px;
  border-bottom: 1px solid #EAECF0;
`

export const SectionFooterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-top: 1px solid #EAECF0;
`

export const SectionTextContainer = styled.div`
  flex: 1 1 100%;
`

export const SectionTitle = styled.div`
  color: var(--grey900);
  font-size: var(--font-size-xl);
  font-weight: 600;

  .highlight-text {
    color: var(--grey600);
    font-weight: 500;
  }
`

export const SectionSubtitle = styled.div`
  color: #475467;
  font-size: 14px;
  margin-top: 4px;
`

export const SectionActionContainer = styled.div`
  flex: 1 0 auto;
  /* padding-left: 16px; */
  display: flex;
  justify-content: flex-end;
`
