/* eslint-disable no-nested-ternary */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

import {
  Container,
  Overlay,
  Expand,
  Title,
  Description,
  OptionsWrapper,
  ComponentOptionTitle,
  ComponentOption,
  ComponentOptionWrapper,
  Image,
  OptionTitle,
  Option, PositionSelectWrapper, PositionSelectLabel, SelectWrapper, ExpandBackButton,
} from './AddSectionSidebar.style';
import { pageSelector, updateDraftRevision } from '../../../store/features/page';
import { ComponentOptionProps, ComponentSpecProps, SectionProps } from '../../../types';
// import { createSection } from '../../../services/apis';
import { ErrorMessage } from '../messages/ErrorMessage';
import { createSection } from '../../../services/api/page-builder';
import { BaseSidebar } from '../../molecules/baseSidebar/BaseSidebar';
import { Modal } from '../../atoms/modal/Modal';
import { Select } from '../../atoms/select/Select';
import { Button } from '../../../atoms/button/Button';
import { find } from 'lodash';
import { mapDefaultSettings } from '../../../utils/mapData';
import _ from 'lodash';

interface AddSectionSidebarProps {
  componentList: {
    sectionTitle: string;
    components: ComponentOptionProps[]
  }[];
  componentSpecList: Array<ComponentSpecProps>;
}

export const AddSectionSidebar = (props: AddSectionSidebarProps) => {
  const { componentList, componentSpecList } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const afterIndex = searchParams.get('after');

  const { id: pageId } = useAppSelector(pageSelector);
  const { previewLayout } = useAppSelector((state) => state.editor);
  const [error, setError] = useState<Error | null>(null);
  const { currentPage } = useAppSelector((state) => state.page);
  const { sections = [] } = currentPage;
  const [selectedComponent, setSelectedComponent] = useState<SectionProps | null>(null);
  const [insertPosition, setInsertPosition] = useState<'top' | 'bottom' | 'after'>(afterIndex !== null ? 'after' : 'bottom');
  const [insertPositionAfter, setInsertPositionAfter] = useState<string | null>(afterIndex !== null ? afterIndex : null);
  const [selected, setSelected] = useState<ComponentOptionProps | null>(null);

  const optionWrapperRef = useRef<HTMLDivElement>(null);
  const sectionSelectorRef = useRef<HTMLDivElement>(null);

  const allSectionOptions = sections.map((s, i) => ({
    title: s.settings?.title || s.name,
    value: `${i}`,
  }));

  const handleItemClicked = (item: ComponentOptionProps | null) => {
    setSelected(item);
  };

  const onSelect = (item: SectionProps) => {
    setSelectedComponent(item);
  };

  const onConfirmAdding = async () => {
    if (!selectedComponent) {
      return;
    }

    try {
      // หา component spec
      let currentComponentSpec = find(componentSpecList, { componentType: selectedComponent.componentType }) as ComponentSpecProps;
      // map ค่า default ให้ตัว component settings
      currentComponentSpec = {
        ...currentComponentSpec,
        settings: mapDefaultSettings(currentComponentSpec.settingFields),
      }

      // map ค่า default ให้ child component settings
      if (currentComponentSpec.childComponents) {
        const { childComponentDefaultSpec } = currentComponentSpec;
        // Add item ให้ child component 1 อันเพื่อเป็นค่าตั้งต้น
        currentComponentSpec.childComponents = childComponentDefaultSpec ? [childComponentDefaultSpec] : [];
        currentComponentSpec.childComponents.forEach((childComponent, index) => {
          _.set(currentComponentSpec, `childComponents[${index}].settings`, mapDefaultSettings(childComponent.settingFields))
        })
      }

      const response = await createSection({ pageId, payload: currentComponentSpec });
      const sectionIdsAfterCreate = response.data.sectionIds;
      const theNewSectionId = sectionIdsAfterCreate.pop();
      if (insertPosition === 'top') {
        await dispatch(updateDraftRevision({ pageId, payload: { sectionIds: [theNewSectionId, ...sectionIdsAfterCreate] } }));
        navigate(`/page-builder/page/${pageId}?success=1`);
      } else if (insertPosition === 'after' && insertPositionAfter !== null) {
        const orderedSectionIds = [...sectionIdsAfterCreate];
        orderedSectionIds.splice(parseInt(insertPositionAfter, 10) + 1, 0, theNewSectionId);
        await dispatch(updateDraftRevision({ pageId, payload: { sectionIds: orderedSectionIds } }));
        navigate(`/page-builder/page/${pageId}?success=1`);
      } else {
        await dispatch(updateDraftRevision({ pageId, payload: { sectionIds: [...sectionIdsAfterCreate, theNewSectionId] } }));
        navigate(`/page-builder/page/${pageId}?success=1`);
      }
    } catch (err) {
      console.log(err);
      setError(err as Error);
    }
  };

  useEffect(() => {
    if (optionWrapperRef.current && sectionSelectorRef.current) {
      // Where is the parent on page
      const parentRect = optionWrapperRef.current.getBoundingClientRect();
      const parentHeight = optionWrapperRef.current.clientHeight - 200;

      // Where is the child
      const childRect = sectionSelectorRef.current.getBoundingClientRect();
      // Is the child viewable?
      const isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentHeight);

      if (!isViewable) {
        optionWrapperRef.current.scrollTop += 200;
      }
    }
  }, [selectedComponent]);

  useEffect(() => () => {
    setInsertPosition('bottom');
    setInsertPositionAfter(null);
  }, []);

  if (error) {
    return (
      <Modal
        title="มีข้อผิดพลาดเกิดขึ้น"
        isOpen={Boolean(error)}
        onClose={() => setError(null)}>
        <ErrorMessage
          title="ไม่สามารถเพิ่ม Section ได้ กรุณาลองใหม่อีกครั้ง"
          description={error.message ? `Error Message: ${error.message}` : ''} />
      </Modal>
    );
  }

  return (
    <Container>
      <BaseSidebar title="เพิ่ม Section" backUrl={`/page-builder/page/${pageId}`} isMobileHamburger={false}>
        <ComponentOptionWrapper>
          {
            componentList.map((componentSection) => (
              <Fragment key={componentSection.sectionTitle}>
                {
                  componentSection.sectionTitle && (
                    <ComponentOptionTitle>{componentSection.sectionTitle}</ComponentOptionTitle>
                  )
                }
                {componentSection.components.map((item) => (
                  <ComponentOption
                    key={`base-item-${item.componentName}`}
                    isSelected={Boolean(selected && selected.componentName === item.componentName)}
                    onClick={() => handleItemClicked(item)}>
                    {item.componentName} ({item.componentAmount})
                  </ComponentOption>
                ))}
              </Fragment>
            ))
          }
        </ComponentOptionWrapper>
      </BaseSidebar>
      { selected && (
        <Expand previewLayout={previewLayout}>
          <Title>
            <ExpandBackButton onClick={() => setSelected(null)}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </ExpandBackButton>
            {selected.componentName}
          </Title>
          <Description>{selected.componentDescription || 'เลือก Component ด้านล่างเพื่อเพิ่ม Section'}</Description>
          <OptionsWrapper ref={optionWrapperRef}>
            {selected.componentOptions.map((item, index) => (
              <Fragment key={`option-${item.name}-${index + 1}`}>
                <Option
                  active={selectedComponent?.name === item.name}
                  onClick={() => {
                    if (!selectedComponent || selectedComponent?.name !== item.name) {
                      onSelect(item as SectionProps);
                    } else {
                      onConfirmAdding();
                    }
                  }}>
                  <OptionTitle>{item.name}:</OptionTitle>
                  <Image
                    src={previewLayout === 'desktop' ? selected.componentPreviews[index] : selected.componentPreviewsMobile[index]}
                    alt={item.name} />
                </Option>
                {selectedComponent?.name === item.name ? (
                  <PositionSelectWrapper ref={sectionSelectorRef}>
                    <SelectWrapper>
                      <PositionSelectLabel>ตำแหน่ง</PositionSelectLabel>
                      <Select
                        options={[
                          { title: 'ด้านล่างสุดของหน้า', value: 'bottom' },
                          { title: 'ด้านบนสุดของหน้า', value: 'top' },
                          { title: 'ต่อท้าย Section...', value: 'after' },
                        ]}
                        defaultValue={insertPosition}
                        onSelect={(option) => setInsertPosition(option.value)}
                      />
                    </SelectWrapper>
                    {insertPosition === 'after' ? (
                      <SelectWrapper>
                        {/* <PositionSelectLabel>ชื่อ Section</PositionSelectLabel> */}
                        <Select
                          options={allSectionOptions}
                          defaultValue={insertPositionAfter !== null ? insertPositionAfter : '0'}
                          onSelect={(option) => setInsertPositionAfter(option.value)}
                        />
                      </SelectWrapper>
                    ) : (<></>)}
                    <Button onClick={onConfirmAdding} fullWidth>
                      ยืนยันการเพิ่ม Section
                    </Button>
                  </PositionSelectWrapper>
                ) : (<></>)}
              </Fragment>
            ))}
          </OptionsWrapper>
        </Expand>
      ) }
      <Overlay onClick={() => navigate(`/page-builder/page/${pageId}`)} />
    </Container>
  );
};
