import styled from "@emotion/styled/macro";

export const TotalText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey600);
  padding: 12px 16px;
`;

export const MoveFolderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InputWrapper = styled.div`
  padding-top: 4px;
`;

export const PaginateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Container = styled.div`
  height: 100%;
  padding: 32px;
  height: 100vh;
  overflow: auto;
`;
