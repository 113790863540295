import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, createStyles, Image } from "@mantine/core";
import { SyntheticEvent, useState } from "react";
import { Button } from "../../../atoms/button/Button";
import { TextInput } from "../../../atoms/textInput/TextInput";
import { ContentDivider } from "../../../molecules/contentDivider/ContentDivider";
import { CopyToClipboard } from "../../../molecules/copyToClipboard/CopyToClipboard";
import { IMediaResponse } from "../../../types";
import { formatFullDateTime } from "../../../utils/format";
import { ConfirmModal } from "../../confirmModal/ConfirmModal";
import {
  ContentContainer,
  ImageContainer,
  ModalWrapper,
  HeaderText,
  ContentWrapper,
  InfoTextHolder,
  MainText,
  SubText,
  DeleteMediaWrapper,
  ButtonGroup,
  InfomationContainer,
  ImageWrapper,
  NextPrevButtonGroup,
} from "./ModalEditMedia.style";

const useStyles = createStyles(() => ({
  title: {
    fontSize: "18px",
    color: "var(--grey900)",
    fontWeight: 600,
  },
  close: {
    color: "var(--grey500)",

    "> svg": {
      width: "24px",
      height: "24px",
    },
  },
  header: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 1,
    padding: 24,
    marginBottom: 0,
    marginRight: 0,
  },
  inner: {
    padding: 0,
  },
  modal: {
    overflow: "hidden",
    padding: "0 !important",
    background: "rgba(52, 64, 84, 0.85)",
    backdropFilter: " blur(8px)",
  },
}));

const useStyleImage = createStyles(() => ({
  imageWrapper: {
    // backgroundColor: "var(--grey25)",
  },
}));

export interface ModalEditMediaProps {
  data: IMediaResponse;
  isModalOpened: boolean;
  isModalDeleteImageOpened: boolean;
  isDisableNextButton?: boolean;
  isDisablePreviousButton?: boolean;
  onClickNext?: () => void;
  onClickPrevious?: () => void;
  onChange?: (e: string, path: string) => void;
  onSave?: () => void;
  onOpenDeleteImageModal?: () => void;
  onCloseDeleteImageModal?: () => void;
  onCloseModal?: () => void;
  onDeleteData?: () => void;
}

export function ModalEditMedia(props: ModalEditMediaProps) {
  const {
    data,
    isModalOpened,
    isModalDeleteImageOpened,
    isDisableNextButton,
    isDisablePreviousButton,
    onDeleteData = () => {},
    onChange = () => {},
    onSave = () => {},
    onCloseModal = () => {},
    onClickNext = () => {},
    onClickPrevious = () => {},
    onOpenDeleteImageModal = () => {},
    onCloseDeleteImageModal = () => {},
  } = props;
  const { classes } = useStyles();
  const { classes: imageClasses } = useStyleImage();

  // const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isHorizontalImage, setIsHorizontalImage] = useState<boolean>(true);

  function onLoadImage(
    e: SyntheticEvent<HTMLImageElement> & { target: HTMLElement }
  ) {
    // if (e.target.offsetHeight - e.target.offsetWidth > 150) {
    if (e.target.offsetHeight > e.target.offsetWidth) {
      setIsHorizontalImage(false);
    } else {
      setIsHorizontalImage(true);
    }
  }

  return (
    <Modal
      onClose={() => onCloseModal()}
      opened={isModalOpened}
      fullScreen
      classNames={classes}
    >
      <>
      <ConfirmModal
        onClose={() => onCloseDeleteImageModal()}
        title={`Delete File`}
        primaryButtonTitle={"Delete"}
        opened={isModalDeleteImageOpened}
        onClickPrimaryButton={onDeleteData}
      >
        <div>
          Are you sure you want to delete this file?
        </div>
        <div style={{ marginTop: "16px" }}>
          Once you confirm, this file will be deleted from the list but still be
          available in the contents that used it.
        </div>
      </ConfirmModal>
      <ModalWrapper>
        <ImageContainer>
          <NextPrevButtonGroup>
            <Button
              buttonStyle="outline-light"
              shape="pill"
              onClick={() => onClickPrevious()}
              disabled={isDisablePreviousButton}
            >
              <FontAwesomeIcon icon={["far", "chevron-left"]} />
            </Button>
            <Button
              buttonStyle="outline-light"
              shape="pill"
              onClick={() => onClickNext()}
              disabled={isDisableNextButton}
            >
              <FontAwesomeIcon icon={["far", "chevron-right"]} />
            </Button>
          </NextPrevButtonGroup>
          <ImageWrapper isHorizontalImage={isHorizontalImage}>
            <Image
              classNames={imageClasses}
              onLoad={onLoadImage}
              src={data?.urls?.original || ""}
              alt={data?.name || ""}
            />
          </ImageWrapper>
        </ImageContainer>
        <InfomationContainer>
          <ContentContainer>
            <HeaderText>Media Detail</HeaderText>
            <ContentWrapper>
              <TextInput
                label="Name"
                onChange={(e) => onChange(e.target.value, "name")}
                value={data?.name || ""}
              />
              <CopyToClipboard label="URL" value={data?.urls?.original || ""} />
            </ContentWrapper>
            <ContentDivider />
            <ContentWrapper>
              <InfoTextHolder>
                <MainText>File Type</MainText>
                <SubText>{data?.mimeType || ""}</SubText>
              </InfoTextHolder>
              <InfoTextHolder>
                <MainText>Updated at</MainText>
                <SubText>
                  {formatFullDateTime(new Date(data?.updatedAt || ""))}
                </SubText>
              </InfoTextHolder>
              <InfoTextHolder>
                <MainText>Uploaded at</MainText>
                <SubText>
                  {formatFullDateTime(new Date(data?.createdAt || ""))}
                </SubText>
              </InfoTextHolder>
            </ContentWrapper>
            <ContentDivider />
            <DeleteMediaWrapper>
              No longer need this media?
              <Button
                buttonStyle="text-link-danger"
                onClick={() => onOpenDeleteImageModal()}
                fontSize="md"
                height="fit-content"
              >
                Delete
              </Button>
            </DeleteMediaWrapper>
          </ContentContainer>
          <div>
            <ContentDivider />
            <ButtonGroup>
              <Button
                buttonStyle="outline-light"
                onClick={() => {
                  onCloseModal();
                }}
                fontSize="md"
              >
                Cancel
              </Button>
              <Button
                buttonStyle="primary"
                onClick={() => onSave()}
                fontSize="md"
              >
                Save
              </Button>
            </ButtonGroup>
          </div>
        </InfomationContainer>
      </ModalWrapper>
      </>
    </Modal>
  );
}
