import React from "react";
import { RowContainer } from "./Row.style";

interface RowProps {
  children: React.ReactElement | React.ReactElement[];
  className?: string;
  padding?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  height?: string;
}

export function Row(props: RowProps) {
  const { padding, height, onClick = () => {} } = props;
  return (
    <RowContainer
      height={height}
      className={`${props.className ? props.className + " row" : "row"}`}
      onClick={onClick}
      padding={padding}
    >
      {props.children}
    </RowContainer>
  );
}
