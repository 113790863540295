import { DefaultProps, Loader as CoreLoader } from "@mantine/core";
import { LoaderProps as CoreLoaderProps } from "@mantine/core";

interface LoaderProps extends DefaultProps, CoreLoaderProps {
    isCenter?:boolean;
}

export function Loader(props: LoaderProps) {
  const { isCenter, ...rest } = props;
  return <CoreLoader {...rest} />;
}
