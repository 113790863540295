import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Variables from "./variables/Variables.style";
import { maxLayout } from "../utils/breakpoints";

export const GlobalStyles = css`
  @import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@300;400;500;600;700&display=swap");
  ${Variables}
  * {
    margin: 0;
    font-family: "IBM Plex Sans Thai", sans-serif;
    line-height: 160%;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: var(--grey900);
    text-decoration: none;

    &:visited {
      color: var(--grey900);
    }
    color: inherit;
  }

  html,
  body {
    overflow-x: hidden;
  }

  body {
    background-color: var(--lightBlue);
  }

  body * {
    /* width */
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      @media ${maxLayout.md} {
        height: 3px;
      }
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--grey300);
      border-radius: 3px;
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-family: "Noto Sans Thai", sans-serif;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      font-family: "Noto Sans Thai", sans-serif;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      font-family: "Noto Sans Thai", sans-serif;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      font-family: "Noto Sans Thai", sans-serif;
    }
  }
`;

export const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 1100px;
  width: 100%;

  @media screen and (max-width: 1100px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const PageHeaderStyle = css`
  font-size: 28px;
  font-weight: 500;

  @media ${maxLayout.md} {
    font-size: 24px;
  }
`;

export const SectionHeaderStyle = css`
  font-size: 18px;
  font-weight: 400;
`;

export const TitleStyle = css`
  font-size: 18px;
  font-weight: 500;
`;

export const SubTitleStyle = css`
  font-size: 16px;
  color: var(--grey700);
`;

export const BodyTextStyle = css`
  font-size: 16px;
`;

export const SmallTextStyle = css`
  font-size: 14px;
  color: var(--grey700);
`;

export const PaginateWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const LabelText = styled.div`
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 500;
  color: var(--grey700);
`;