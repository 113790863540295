import { createAction } from "@reduxjs/toolkit";
import createAsyncThunk from "../../middleware/customCreateThunk";
import {} from "../../../services/api/user";
import { createPostAPI, deletePostByIdAPI, loadPostById, loadPostList, loadPostOptions, updatePostByIdAPI } from "../../../services/api/post";
import { IPostEditable } from "../../../types/post";

export const fetchPostList = createAsyncThunk({
  api: loadPostList,
  EVENT_NAME: "Post/loadPostList",
});

export const fetchPostById = createAsyncThunk({
  api: loadPostById,
  EVENT_NAME: "Post/loadPostById",
});

export const updatePostById = createAsyncThunk({
  api: updatePostByIdAPI,
  EVENT_NAME: "Post/updatePostById",
});

export const deletePostById = createAsyncThunk({
  api: deletePostByIdAPI,
  EVENT_NAME: "Post/deletePostById",
});

export const createPost = createAsyncThunk({
  api: createPostAPI,
  EVENT_NAME: "Post/createPost",
});

export const fetchPostOptions = createAsyncThunk({
  api: loadPostOptions,
  EVENT_NAME: "Post/loadPostOption",
});


export const setEditPostData = createAction<IPostEditable | null>("Post/setEditPostData")

export const resetPost = createAction("Post/reset");
export const resetPostDetail = createAction("Post/resetPostDetail");
