import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

export const Container = styled.div<{ isSidePanelActive: boolean }>`
  ${(props) =>
    props.isSidePanelActive
      ? css`
          display: grid;
          grid-template-columns: auto 350px;
          height: 100%;
          width: 100%;
        `
      : css`
          display: grid;
          grid-template-columns: auto;
        `}
`;

export const TableContainer = styled.div`
  padding: 32px;
`;

export const SidePanelWrapper = styled.div<{ isActive: boolean }>`
  max-width: 350px;
  width: 100%;
  max-height: 100vh;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SidePanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  padding: 16px;
  justify-content: flex-end;
  gap: 12px;
`;
