import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { MenuPosition } from "./DropdownButton";

export const MenuButton = styled.div<{ horizontalAlign: string }>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.horizontalAlign};

  span,
  svg {
    pointer-events: none;
  }
`;

export const DropdownMenuContainer = styled.div<{
  isActive: boolean;
  position: MenuPosition;
  gap?: string;
  right?: string;
}>`
  position: absolute;
  ${(props) => {
    switch (props.position) {
      case "top":
        return css`
          bottom: ${props.gap ? props.gap : "48px"};
        `;
      case "bottom":
        return css`
          top: ${props.gap ? props.gap : "48px"};
        `;
      default:
        return css`
          top: ${props.gap ? props.gap : "48px"};
        `;
    }
  }}
  right: ${(props) => (props.right ? props.right : "0")};
  opacity: 0;
  pointer-events: none;

  ${(props) =>
    props.isActive &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}
`;
