import { createReducer } from "@reduxjs/toolkit";
import { StatusState } from "../../../types";
import {  IFacilityTypes } from "../../../types/facility";
import { fetchAllFacilityType, resetFacility } from "./actions";
import { getStateObject } from "../../../utils/stateManagement";
import { formatErrorObject } from "../../../utils/errorFormatter";

interface InitialStateProps {
  facilityTypes: IFacilityTypes[];
  loadFacilityTypeState: StatusState;
}

const initialState: InitialStateProps = {
  facilityTypes: [],
  loadFacilityTypeState: {
    error: null,
    status: "",
  },
};

export const facilityReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetFacility, (state) => {
      Object.assign(state, initialState);
    })
    .addCase(fetchAllFacilityType.pending, (state) => {
      state.loadFacilityTypeState = getStateObject({ type: "loading" });
    })
    .addCase(fetchAllFacilityType.fulfilled, (state, { payload }) => {
      state.facilityTypes = payload?.data;
      state.loadFacilityTypeState = getStateObject({ type: "success" });
    })
    .addCase(fetchAllFacilityType.rejected, (state, { payload }) => {
      state.facilityTypes = [];
      state.loadFacilityTypeState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Facility Type"),
      });
    });
});
