import {
  Container,
  Overlay,
  ImageWrapper,
  SelectIcon,
} from "../MediaItem.style";
import { Image as CoreImage, AspectRatio } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MediaItemProps, useStyles } from "../MediaItem";

export function MediaItemB(props: MediaItemProps) {
  const {
    ratio,
    maxWidth,
    data,
    iconSize,
    isSelected = false,
    onClickMedia,
    isDisable,
    isShowSelecter,
    selecterShape = "radio",
  } = props;
  const { classes } = useStyles();
  return (
    <Container
      maxWidth={maxWidth}
      isDisable={isDisable}
      isSelected={isSelected}
      onClick={onClickMedia}
      shape={selecterShape}
    >
      {ratio ? (
        <AspectRatio ratio={ratio}>
          <ImageWrapper>
            <CoreImage {...data} fit={"cover"} classNames={classes} />
            <Overlay isDisable={isDisable}></Overlay>
            <SelectIcon
              iconWidth={iconSize?.width}
              iconHeight={iconSize?.height}
              isSelected={isSelected}
              isShowSelecter={isShowSelecter}
              shape={selecterShape}
            >
              <FontAwesomeIcon icon={["far", "check"]} />
            </SelectIcon>
          </ImageWrapper>
        </AspectRatio>
      ) : (
        <ImageWrapper>
          <CoreImage {...data} fit={"fill"} classNames={classes} />
          <Overlay></Overlay>
          <SelectIcon
            iconWidth={iconSize?.width}
            iconHeight={iconSize?.height}
            isSelected={isSelected}
          >
            <FontAwesomeIcon icon={["far", "check"]} />
          </SelectIcon>
        </ImageWrapper>
      )}
    </Container>
  );
}
