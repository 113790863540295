import { createReducer } from "@reduxjs/toolkit";
import { IProductType, SelectItemProps, StatusState } from "../../../types";
import {
  createdProductType,
  deletedProductTypeById,
  fetchProductTypeById,
  fetchProductTypes,
  resetCurrentProductType,
  resetProductType,
  resetProductTypeDetailState,
  resetProductTypeListState,
  setCurrentProductType,
  setProductTypeSelectItems,
  updatedProductTypeById,
} from "./actions";
import { getStateObject } from "../../../utils/stateManagement";
import { formatErrorObject } from "../../../utils/errorFormatter";

interface InitialStateProps {
  productTypes: IProductType[];
  productTypeSelectItems: SelectItemProps[];
  currentProductType: IProductType | null;
  loadProductTypeListState: StatusState;
  loadProductTypeByIdState: StatusState;
  loadProductTypeSelectItemState: StatusState;
  createProductTypeState: StatusState;
  updateProductTypeState: StatusState;
  deleteProductTypeState: StatusState;
  pagination: {
    from: number;
    to: number;
    lastPage: number;
    perPage: number;
    total: number;
    currentPage: number
  };
}

const productTypeListInitialState = {
  productTypes: [],
  productTypeSelectItems: [],
  loadProductTypeListState: {
    error: null,
    status: "",
  },
  loadProductTypeSelectItemState: {
    error: null,
    status: "",
  },
  pagination: {
    from: 0,
    to: 0,
    lastPage: 0,
    perPage: 0,
    total: 0,
    currentPage: 0,
  },
};

const productTypeDetailInitialState = {
  currentProductType: null,
  loadProductTypeByIdState: {
    error: null,
    status: "",
  },
  createProductTypeState: {
    error: null,
    status: "",
  },
  updateProductTypeState: {
    error: null,
    status: "",
  },
  deleteProductTypeState: {
    error: null,
    status: "",
  },
};

const initialState: InitialStateProps = {
  ...productTypeListInitialState,
  ...productTypeDetailInitialState,
};

export const productTypeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchProductTypes.pending, (state) => {
      state.loadProductTypeListState = getStateObject({ type: "loading" });
    })
    .addCase(fetchProductTypes.fulfilled, (state, { payload }) => {
      state.productTypes = payload?.data;
      state.loadProductTypeListState = getStateObject({ type: "success" });
      state.pagination = {
        from: payload?.from,
        to: payload?.to,
        lastPage: payload?.lastPage,
        perPage: payload?.perPage,
        total: payload?.total,
        currentPage: payload?.currentPage
      };
    })
    .addCase(fetchProductTypes.rejected, (state, { payload }) => {
      state.loadProductTypeListState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Product Types"),
      });
    })
    .addCase(setProductTypeSelectItems, (state, { payload }) => {
      state.productTypeSelectItems = payload;
      state.loadProductTypeSelectItemState = getStateObject({
        type: "success",
      });
    })
    .addCase(fetchProductTypeById.pending, (state) => {
      state.loadProductTypeByIdState = getStateObject({ type: "loading" });
    })
    .addCase(fetchProductTypeById.fulfilled, (state, { payload }) => {
      state.currentProductType = payload;
      state.loadProductTypeByIdState = getStateObject({ type: "success" });
    })
    .addCase(fetchProductTypeById.rejected, (state, { payload }) => {
      state.currentProductType = null;
      state.loadProductTypeByIdState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Product Type"),
      });
    })
    .addCase(createdProductType.pending, (state) => {
      state.createProductTypeState = getStateObject({ type: "loading" });
    })
    .addCase(createdProductType.fulfilled, (state, { payload }) => {
      state.createProductTypeState = getStateObject({ type: "success" });
    })
    .addCase(createdProductType.rejected, (state, { payload }) => {
      state.createProductTypeState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Product Type"),
      });
    })
    .addCase(deletedProductTypeById.pending, (state) => {
      state.deleteProductTypeState = getStateObject({ type: "loading" });
    })
    .addCase(deletedProductTypeById.fulfilled, (state) => {
      state.currentProductType = null;
      state.deleteProductTypeState = getStateObject({ type: "success" });
    })
    .addCase(deletedProductTypeById.rejected, (state, { payload }) => {
      state.currentProductType = null;
      state.deleteProductTypeState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Product Type"),
      });
    })
    .addCase(updatedProductTypeById.pending, (state) => {
      state.updateProductTypeState = getStateObject({ type: "loading" });
    })
    .addCase(updatedProductTypeById.fulfilled, (state, { payload }) => {
      state.currentProductType = payload;
      state.updateProductTypeState = getStateObject({ type: "success" });
    })
    .addCase(updatedProductTypeById.rejected, (state, { payload }) => {
      state.updateProductTypeState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Product Type"),
      });
    })
    .addCase(setCurrentProductType, (state, {payload}) => {
      state.currentProductType = payload;
    })
    .addCase(resetCurrentProductType, (state) => {
      state.currentProductType = null;
    })
    .addCase(resetProductTypeListState, (state) => {
      Object.assign(state, productTypeListInitialState);
    })
    .addCase(resetProductTypeDetailState, (state) => {
      Object.assign(state, productTypeDetailInitialState);
    })
    .addCase(resetProductType, (state) => {
      Object.assign(state, initialState);
    });
});
