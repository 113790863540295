/* eslint-disable no-unused-vars */
import {
  forwardRef,
  Ref,
  useEffect,
  useState,
  useImperativeHandle,
  useRef,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Container,
  SelectButton,
  SelectOverlay,
  OptionsContainer,
  Option,
  SelectedValueLabel,
} from './Select.style';
import { OptionProps } from '../../../types/section';

export interface SelectRefObject {
  onVisible: () => void;
  scrollIntoSelectedView: () => any;
}

interface SelectProps {
  /**
   * Array of option
   */
  options: OptionProps[];
  /**
   * Default selected value
   * @default first option's value
   */
  defaultValue?: any;
  /**
   * Call when selected value changed
   */
  onSelect?: (selectedValue: any) => void;
  /**
   * Custom border radius
   * @default 3px
   */
  borderRadius?: string;
  isScrolling?: boolean;
}

export const Select = forwardRef(
  (props: SelectProps, ref: Ref<SelectRefObject>) => {
    const {
      options,
      defaultValue = options[0].value,
      onSelect,
      borderRadius,
      isScrolling = false,
    } = props;

    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState(defaultValue);
    const optionsContainer = useRef(null);
    const overlay = useRef(null);

    const displaySelectedOption = () => {
      let inputLabel: string | JSX.Element = '';

      options.forEach((option) => {
        if (option.value === value) inputLabel = option.title;
      });

      return inputLabel;
    };

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    useImperativeHandle(ref, () => ({ onVisible, scrollIntoSelectedView }));
    const onVisible = () => {
      setVisible(false);
    };

    const scrollIntoSelectedView = () => {
      if (optionsContainer.current) {
        const el = optionsContainer.current as HTMLElement;
        const parent = el.parentNode as HTMLElement;
        if (parent) {
          parent.scrollTop = el.offsetTop;
        }
      }
    };

    useEffect(() => {
      setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
      if (overlay.current) {
        const element = overlay.current as HTMLDivElement;
        const sidebarContent = document.getElementById('sidebar-content');

        if (sidebarContent) {
          element.addEventListener('wheel', (e: WheelEvent) => {
            e.preventDefault();
            sidebarContent.scrollTop += e.deltaY;
          });
        }
      }
    }, []);

    return (
      <Container>
        <SelectButton
          onClick={() => {
            setVisible(true);
            if (isScrolling) {
              scrollIntoSelectedView();
            }
          }}
          borderRadius={borderRadius}>
          <SelectedValueLabel>{displaySelectedOption()}</SelectedValueLabel>
          <FontAwesomeIcon icon={['fas', 'caret-down']} />
        </SelectButton>
        <SelectOverlay ref={overlay} isActive={visible} onClick={() => setVisible(false)} />
        <OptionsContainer isActive={visible} isScrolling={isScrolling}>
          {options.map((option) => (
            <Option
              ref={option.value === value ? optionsContainer : undefined}
              key={`${option.value}-${Math.round(Math.random() * 100000)}`}
              onClick={() => {
                if (onSelect) {
                  onSelect(option);
                }
                setValue(option.value);
                setVisible(false);
              }}
              isSelected={option.value === value}
              isVisible={visible}>
              {option.title}
            </Option>
          ))}
        </OptionsContainer>
      </Container>
    );
  }
);
