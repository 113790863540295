import {
  createdProject,
  projectSelector,
  resetProjectDetail,
} from "../../store/features/project";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IProjectEditable } from "../../types";
import { ManageProject } from "../../templates/manageProject/ManageProject";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { addToast } from "../../store/features/toast";
import {
  createPage,
  pageDataSelector,
  resetCreatePageState,
} from "../../store/features/page";

export default function CreateProject() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { createProjectState, newProject } = useAppSelector(projectSelector);
  const { newPage, createPageState } = useAppSelector(pageDataSelector);
  const [isClickCreatePage, setIsClickCreatePage] = useState<boolean>(false);

  function checkIsValidData(data: IProjectEditable) {
    return (
      data.brandId &&
      data.zoneId &&
      data.projectCode &&
      data.slugCode &&
      data.displayedLocationName
    );
  }

  // onClick Handler
  async function onHandleCreateProject(data: IProjectEditable) {
    if (checkIsValidData(data)) {
      await dispatch(createdProject(data));
    }
  }

  async function onCreatePage(data: IProjectEditable) {
    if (checkIsValidData(data)) {
      await onHandleCreateProject(data);
    }
  }

  useEffect(() => {
    const status = createProjectState.status;
    if (status === "success") {
      dispatch(addToast({ status, title: "Successfully created" }));
      if (!isClickCreatePage) {
        navigate(`/project`);
      } else {
        if (newProject) {
          dispatch(
            createPage({
              title: `${
                newProject?.name || ""
              } (${newProject?.languageCode.toUpperCase()})`,
              path: `/${newProject?.languageCode}/project/${newProject?.brand?.productType?.code}/${newProject?.brand?.slugCode}/${newProject?.slugCode}`,
              category: "project",
              slug: `${newProject?.slugCode} - ${newProject?.languageCode}`,
              resourceId: newProject?.id,
              resourceUrl: `/project/${newProject?.id}`,
              languageCode: newProject?.languageCode,
            })
          );
        }
      }

      return () => {
        dispatch(resetProjectDetail());
      };
    }
    if (status === "error")
      dispatch(addToast({ status, title: "Create failed" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createProjectState.status, dispatch]);

  useEffect(() => {
    const status = createPageState.status;
    if (status === "success") {
      if (isClickCreatePage) {
        navigate(`/page-builder/page/${newPage?.id}`);
      }

      return () => {
        dispatch(resetCreatePageState());
        dispatch(resetProjectDetail());
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPageState.status]);

  return (
    <ManageProject
      pageTitle={"Create Project"}
      onClickCreate={(data) => onHandleCreateProject(data)}
      isCreatePage
      onCreatePage={(data) => {
        onCreatePage(data);
        setIsClickCreatePage(true);
      }}
      isShowPreviewPage
    />
  );
}
