import { createReducer } from "@reduxjs/toolkit";
import {
  IProject,
  ProjectUnitTypeData,
  SelectItemProps,
  StatusState,
} from "../../../types";
import {
  createdProject,
  fetchProjectList,
  fetchProjectById,
  resetProject,
  resetProjectDetail,
  resetProjectList,
  updatedProjectById,
  setCurrentProject,
  deleteProject,
  setProjectSelectItems,
  fetchProjectUnitType,
  updateProjectVisibilityStatus,
  updateProjectPublishStatus,
} from "./actions";
import { getStateObject } from "../../../utils/stateManagement";
import { formatErrorObject } from "../../../utils/errorFormatter";

interface InitialStateProps {
  projects: IProject[];
  currentProject: IProject | null;
  newProject: IProject | null;
  unitTypes: ProjectUnitTypeData | null;
  selecteProjectItems: SelectItemProps[];
  loadProjectListState: StatusState;
  loadProjectByIdState: StatusState;
  loadProjectUnitTypeState: StatusState;
  createProjectState: StatusState;
  deleteProjectState: StatusState;
  updateProjectState: StatusState;
  pagination: {
    from: number;
    to: number;
    lastPage: number;
    perPage: number;
    total: number;
    current: number;
  };
}

const projectListInitialState = {
  projects: [],
  selecteProjectItems: [],
  loadProjectListState: {
    error: null,
    status: "",
  },
  pagination: {
    from: 0,
    to: 0,
    lastPage: 0,
    perPage: 0,
    total: 0,
    current: 0,
  },
};

const projectDetailInitialState = {
  currentProject: null,
  newProject: null,
  unitTypes: null,
  loadProjectUnitTypeState: getStateObject({ type: "idle" }),
  loadProjectByIdState: {
    error: null,
    status: "",
  },
  createProjectState: {
    error: null,
    status: "",
  },
  deleteProjectState: {
    error: null,
    status: "",
  },
  updateProjectState: {
    error: null,
    status: "",
  },
};

const initialState: InitialStateProps = {
  ...projectListInitialState,
  ...projectDetailInitialState,
};

export const projectReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchProjectById.pending, (state) => {
      state.loadProjectByIdState = getStateObject({ type: "loading" });
    })
    .addCase(fetchProjectById.fulfilled, (state, { payload }) => {
      state.currentProject = payload;
      state.loadProjectByIdState = getStateObject({ type: "success" });
    })
    .addCase(fetchProjectById.rejected, (state, { payload }) => {
      state.currentProject = null;
      state.loadProjectByIdState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Project"),
      });
    })
    .addCase(fetchProjectList.pending, (state) => {
      state.loadProjectListState = getStateObject({ type: "loading" });
    })
    .addCase(fetchProjectList.fulfilled, (state, { payload }) => {
      state.projects = payload?.data;
      state.loadProjectListState = getStateObject({ type: "success" });
      state.pagination = {
        from: payload?.from,
        to: payload?.to,
        lastPage: payload?.lastPage,
        perPage: payload?.perPage,
        total: payload?.total,
        current: payload?.current,
      };
    })
    .addCase(fetchProjectList.rejected, (state, { payload }) => {
      state.projects = [];
      state.loadProjectListState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Projects"),
      });
    })
    .addCase(updatedProjectById.pending, (state) => {
      state.updateProjectState = getStateObject({ type: "loading" });
    })
    .addCase(updatedProjectById.fulfilled, (state, { payload }) => {
      state.currentProject = payload;
      state.updateProjectState = getStateObject({ type: "success" });
    })
    .addCase(updatedProjectById.rejected, (state, { payload }) => {
      state.updateProjectState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Projects"),
      });
    })
    .addCase(updateProjectVisibilityStatus.pending, (state) => {
      state.updateProjectState = getStateObject({ type: "loading" });
    })
    .addCase(updateProjectVisibilityStatus.fulfilled, (state, { payload }) => {
      state.currentProject = payload;
      state.updateProjectState = getStateObject({ type: "success" });
    })
    .addCase(updateProjectVisibilityStatus.rejected, (state, { payload }) => {
      state.updateProjectState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Project Visibility"),
      });
    })
    .addCase(updateProjectPublishStatus.pending, (state) => {
      state.updateProjectState = getStateObject({ type: "loading" });
    })
    .addCase(updateProjectPublishStatus.fulfilled, (state, { payload }) => {
      state.currentProject = payload;
      state.updateProjectState = getStateObject({ type: "success" });
    })
    .addCase(updateProjectPublishStatus.rejected, (state, { payload }) => {
      state.updateProjectState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Project Publish Status"),
      });
    })
    .addCase(createdProject.pending, (state) => {
      state.createProjectState = getStateObject({ type: "loading" });
    })
    .addCase(createdProject.fulfilled, (state, { payload }) => {
      state.newProject = payload
      state.createProjectState = getStateObject({ type: "success" });
    })
    .addCase(createdProject.rejected, (state, { payload }) => {
      state.newProject = null
      state.createProjectState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Projects"),
      });
    })
    .addCase(deleteProject.pending, (state) => {
      state.deleteProjectState = getStateObject({ type: "loading" });
    })
    .addCase(deleteProject.fulfilled, (state, { payload }) => {
      state.deleteProjectState = getStateObject({ type: "success" });
    })
    .addCase(deleteProject.rejected, (state, { payload }) => {
      state.deleteProjectState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Projects"),
      });
    })
    .addCase(fetchProjectUnitType.pending, (state) => {
      state.loadProjectUnitTypeState = getStateObject({ type: "loading" });
    })
    .addCase(fetchProjectUnitType.fulfilled, (state, { payload }) => {
      state.unitTypes = payload;
      state.loadProjectUnitTypeState = getStateObject({ type: "success" });
    })
    .addCase(fetchProjectUnitType.rejected, (state, { payload }) => {
      state.loadProjectUnitTypeState = getStateObject({
        type: "error",
        error: formatErrorObject(payload, "Project Unit Types"),
      });
    })
    .addCase(setCurrentProject, (state, { payload }) => {
      state.currentProject = payload;
    })
    .addCase(setProjectSelectItems, (state, { payload }) => {
      state.selecteProjectItems = payload;
    })
    .addCase(resetProjectDetail, (state) => {
      Object.assign(state, projectDetailInitialState);
    })
    .addCase(resetProjectList, (state) => {
      Object.assign(state, projectListInitialState);
    })
    .addCase(resetProject, (state) => {
      Object.assign(state, initialState);
    });
});
