import { MediaListLayout } from "../../templates/mediaListLayout/MediaListLayout";

export function AttachmentList() {
  return (
    <MediaListLayout
      pageTitle="Attachments"
      path="attachments"
      isAttachment
      supportingType={"PDF"}
    />
  );
}
