import qs from "qs";
import { axiosContentAPI } from "../../axios";
import {
  FolderItemQueryParams,
  IUpdateMedia,
  IUploadFileParams,
  MoveAttachmentFolderParams,
} from "../../types";

export const loadAttachmentsAPI = (filter?: FolderItemQueryParams) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosContentAPI.get(`/media/attachments${query}`);
};

export const moveAttachmentAPI = ({ data }: MoveAttachmentFolderParams) => {
  return axiosContentAPI.post(`/media/attachments/move`, data);
};

export const updateAttachmentAPI = ({ id, data }: IUpdateMedia) => {
  return axiosContentAPI.put(`/media/attachments/${id}`, data);
};

export const deleteAttachmentAPI = (id:string) => {
  return axiosContentAPI.delete(`/media/attachments/${id}`);
};

export const uploadAttachment = ({
  file,
  onUploadProgress,
  fileId,
  cancelToken,
}: IUploadFileParams) => {
  return axiosContentAPI.post(`/media/attachments`, file, {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: (e) => onUploadProgress(e, fileId),
    cancelToken: cancelToken.token,
  });
};
