import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AspectRatio, Image } from "@mantine/core";
import _ from "lodash";
import qs from "qs";
import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Button } from "../../atoms/button/Button";
import { HeaderCell } from "../../atoms/headerCell/HeaderCell";
import { Skeleton } from "../../atoms/loading/skeleton/Skeleton";
import { TextCell } from "../../atoms/textCell/TextCell";
import { Column } from "../../molecules/column/Column";
import { Row } from "../../molecules/row/Row";
import { ConfirmModal } from "../../organisms/confirmModal/ConfirmModal";
import { DataTable } from "../../organisms/dataTable/DataTable";
import { Section } from "../../organisms/section/Section";
import {
  updateHouseCollectionById,
  fetchHouseCollectionList,
  houseCollectionSelector,
  deleteHouseCollectionById,
  setCurrentHouseCollection,
  resetHouseCollectionDetailData,
} from "../../store/features/houseCollection";
import { addToast } from "../../store/features/toast";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ListPageWithLanguageSidebar } from "../../templates/listPageWithLanguageSidebar/ListPageWithLanguageSidebar";
import { lang } from "../../types";
import { IHouseCollectionType } from "../../types/houseCollection";
import { onClickRow } from "../../utils/dataTable";
import { formatFullDateTime } from "../../utils/format";
import { ActionWrapper } from "../projectDetail/ProjectDetail.style";
import { HouseCollectionNumber, LogoContainer, TextWrapper } from "./HouseCollection.style";

export default function HouseCollection() {
  const languageCode = lang;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {
    houseCollectionList,
    pagination,
    currentHouseCollection,
    updateHouseCollectionState,
    deleteHouseCollectionState,
    loadHouseCollectionListState,
  } = useAppSelector(houseCollectionSelector);
  const [searchParams, setSearchParams] = useSearchParams({});
  const [isSidePanelActive, setIsSidePanelActive] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(
      fetchHouseCollectionList({
        q: searchParams.get("q"),
        page: searchParams.get("page"),
      })
    );
  }, [searchParams, dispatch, updateHouseCollectionState]);

  useEffect(() => {
    const status = deleteHouseCollectionState.status;
    if (status === "success")
      dispatch(addToast({ status, title: "Successfully deleted" }));
    if (status === "error")
      dispatch(addToast({ status, title: "Delete failed" }));
  }, [deleteHouseCollectionState.status, dispatch, location.pathname]);

  function onSaveHouseCollection(data: IHouseCollectionType | null) {
    if (data) {
      const contentVariation = data.contentVariations.find(
        (item) => item.languageCode === languageCode
      );
      if (contentVariation) {
        dispatch(
          updateHouseCollectionById({
            houseCollectionId: data.id,
            data: {
              ...data,
              contentVariation: contentVariation,
            },
          })
        );
        dispatch(
          fetchHouseCollectionList({
            q: searchParams.get("q"),
            page: searchParams.get("page"),
          })
        );
      }
    }
  }
  function onCloseSidebar() {
    setIsSidePanelActive(false);
    dispatch(resetHouseCollectionDetailData());
  }
  function onCloseDeleteModal() {
    setIsShowDeleteModal(false);
    dispatch(resetHouseCollectionDetailData());
  }
  function onOpenDeleteModal(houseCollection: IHouseCollectionType) {
    dispatch(setCurrentHouseCollection(houseCollection));
    setIsShowDeleteModal(true);
  }
  async function onDeleteHouseCollection() {
    await dispatch(deleteHouseCollectionById(currentHouseCollection?.id));
    await dispatch(
      fetchHouseCollectionList({
        q: searchParams.get("q"),
        page: searchParams.get("page"),
      })
    );
    onCloseDeleteModal();
  }

  return (
    <ListPageWithLanguageSidebar
      editData={currentHouseCollection as IHouseCollectionType}
      pageTitle="House Collection"
      isSidePanelActive={isSidePanelActive}
      onUpdateEditData={(editData) =>
        dispatch(setCurrentHouseCollection(editData as IHouseCollectionType))
      }
      headerActionComponent={
        <Link to={"/house-collection/create"}>
          <Button leftIcon={<FontAwesomeIcon icon={["fas", "plus"]} />}>
            Create House Collection
          </Button>
        </Link>
      }
      onClickCancel={onCloseSidebar}
      onClickSave={() => onSaveHouseCollection(currentHouseCollection)}
      saveState={updateHouseCollectionState}
    >
      <ConfirmModal
        onClose={onCloseDeleteModal}
        title="Delete House Collection"
        opened={isShowDeleteModal}
        onClickPrimaryButton={onDeleteHouseCollection}
      >
        <div>
          Are you sure you want to delete{" "}
          <b>
            ‘
            {
              _.find(currentHouseCollection?.contentVariations, {
                languageCode: languageCode,
              })?.name
            }
            ’
          </b>
          ?
        </div>
        <div style={{ marginTop: "16px" }}>
          Once you confirm, this collection will be permanently deleted and also
          removed from all contents that use it.
        </div>
      </ConfirmModal>
      <Section>
        <DataTable
          onSearchData={(value) => {
            searchParams.set("q", value);
            setSearchParams(searchParams);
          }}
          additionalInfoElement={
            <HouseCollectionNumber>
              Total {houseCollectionList.length} collections
            </HouseCollectionNumber>
          }
          currentPage={parseInt(searchParams.get("page") as string, 10) || 1}
          totalPages={pagination.total && pagination.lastPage}
          onChangePage={(page) =>
            navigate({
              pathname: "/house-collection",
              search: qs.stringify(
                {
                  q: searchParams.get("q") || undefined,
                  page,
                },
                { addQueryPrefix: true, skipNulls: true }
              ),
            })
          }
          header={
            <Row>
              <Column maxWidth="150px">
                <HeaderCell title="Logo" />
              </Column>
              <Column>
                <HeaderCell title="Name" />
              </Column>
              <Column maxWidth="160px">
                <HeaderCell title="Updated At" />
              </Column>
              <Column maxWidth="100px" children={<></>} />
            </Row>
          }
        >
          <>
            {loadHouseCollectionListState.status === "success" ? (
              Boolean(houseCollectionList && houseCollectionList.length) &&
              houseCollectionList.map((data, index) => (
                <Row
                  key={data.id + index + 1}
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    onClickRow(event, () =>
                      navigate(`/house-collection/${data.id}`)
                    )
                  }
                >
                  <Column maxWidth="150px">
                    <div
                      style={{
                        padding: "16px 8px",
                        width: "100%",
                      }}
                    >
                      <LogoContainer>
                        <AspectRatio ratio={16 / 9}>
                          {data.logo ? <Image
                            src={data.logo?.originalUrl}
                            alt={
                              _.find(data.contentVariations, {
                                languageCode: languageCode,
                              })?.name
                            }
                          /> : <TextWrapper>No image</TextWrapper>}
                        </AspectRatio>
                      </LogoContainer>
                    </div>
                  </Column>
                  <Column>
                    <TextCell
                      primaryText={
                        _.find(data.contentVariations, {
                          languageCode: languageCode,
                        })?.name
                      }
                    />
                  </Column>
                  <Column maxWidth="160px">
                    <TextCell
                      text={formatFullDateTime(new Date(data.updatedAt))}
                    />
                  </Column>
                  <Column maxWidth="100px">
                    <ActionWrapper>
                      <Button
                        buttonStyle="text-link-grey"
                        onClick={() => {
                          onOpenDeleteModal(data);
                        }}
                        fontSize="sm"
                      >
                        Delete
                      </Button>
                      <Button
                        buttonStyle="text-link-primary"
                        onClick={() => {
                          dispatch(setCurrentHouseCollection(data));
                          setIsSidePanelActive(true);
                        }}
                        fontSize="sm"
                      >
                        Edit
                      </Button>
                    </ActionWrapper>
                  </Column>
                </Row>
              ))
            ) : (
              <Skeleton height={"250px"} radius={0} />
            )}
          </>
        </DataTable>
      </Section>
    </ListPageWithLanguageSidebar>
  );
}
