import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { UploadState } from "./UploadItem";

export const Container = styled.div<{ width?: string; isError: boolean }>`
  position: relative;

  display: flex;
  gap: 16px;
  width: ${(props) => (props.width ? props.width : "100%")};
  border-radius: 12px;
  padding: var(--space-sm);
  ${(props) =>
    props.isError
      ? css`
          background-color: var(--error25);
          border: 1px solid var(--error300);
        `
      : css`
          background-color: var(--white);
          border: 1px solid var(--grey200);
        `}
`;

export const ImageWrapper = styled.div`
  flex: 0 0 64px;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--grey25);
  border: 1px solid var(--grey200);
`;

export const ContentHolder = styled.div`
  position: relative;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-right: 5%;
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ProgressBar = styled.div`
  width: 100%;
  background-color: var(--grey200);
  border-radius: 4px;
`;

export const LoadingProgress = styled.div<{ width: number; height?: string }>`
  width: ${(props) => props.width}%;
  max-width: 100%;
  min-width: 0%;
  height: ${(props) => (props.height ? props.height : "8px")};
  background-color: var(--primary600);
  border-radius: 4px;
  transition: 0.2s width cubic-bezier(0.25, 0.46, 0.45, 0.94);
`;

export const ProgressText = styled.div<{ isError?: boolean }>`
  flex: 1 1 48px;
  width: fit-content;
  text-align: end;
  font-size: 14px;
  font-weight: ${(props) => (props.isError ? "600" : "500")};
  color: ${(props) => (props.isError ? "var(--error700)" : "var(--grey700)")};
  cursor: ${(props) => props.isError && "pointer"};
`;

export const MainText = styled.div<{ isError?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => (props.isError ? "var(--error700)" : "var(--grey700)")};

  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const SubText = styled.div<{ isError?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => (props.isError ? "var(--error600)" : "var(--grey600)")};

  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const IconWrapper = styled.div<{
  uploadState: UploadState;
}>`
  position: absolute;
  right: 0;
  top: 0;

  width: 16px;
  height: 16px;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => {
    switch (props.uploadState) {
      case "uploaded":
        return `var(--grey600)`;
      case "error":
        return `var(--error600)`;
      case "completed":
        return `var(--primary600)`;
      default:
        return `var(--grey600)`;
    }
  }};

  cursor: ${(props) => props.uploadState !== "completed" && "pointer"};
`;

export const AttachmentWrapper = styled.div<{ uploadState: UploadState }>`
  display: flex;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;

  border-radius: 28px;
  ${(props) => {
    switch (props.uploadState) {
      case "uploaded":
        return css`
          background-color: var(--primary100);
          border: 4px solid var(--primary50);
          color: var(--primary600);
        `;
      case "error":
        return css`
          background-color: var(--error100);
          border: 4px solid var(--error50);
          color: var(--error600);
        `;
      case "completed":
        return css`
          background-color: var(--primary100);
          border: 4px solid var(--primary50);
          color: var(--primary600);
        `;
      default:
        return css`
          background-color: var(--primary100);
          border: 4px solid var(--primary50);
          color: var(--primary600);
        `;
    }
  }}
`;
